import { getUser } from "react-app-config"
import {
  setFirstVisit,
  setUsingStaticData,
} from "../../../contexts/application/action"
import { IUser } from "../../../api/api-client/api-types"
import { ApiController } from "../../../api/apiController"
import { UserModuleType } from "../../../contexts/application/constants"
import { convertApiToAppUser } from "../../../api/api-client/convert"
import { setCurrentUser } from "../../../contexts/users"
import { initializeGA } from "../../googleAnalytics/initializeGA"

// This method to use static data from "react-app-config", perhaps this could be removed in the future and use a different approach. That would mean to remove react-app-config from the project..
export const setCurrentUserFromLocalData = ({
  email,
  selectedModules,
  dispatch,
  dispatchUser,
}: {
  email?: string
  selectedModules: string[]
  dispatch: (value: any) => void
  dispatchUser: (value: any) => void
}) => {
  const userFromLocalData = getUser({ email })

  initializeGA("static", userFromLocalData as IUser, "NonDB User Platform")

  if (userFromLocalData) {
    const iUser = convertApiToAppUser(userFromLocalData)
    if (iUser) {
      if (iUser.modules) {
        iUser.modules = iUser.modules.filter((x) => selectedModules.includes(x))
      } else {
        iUser.modules = selectedModules
      }

      const api = ApiController.getInstance()
      api.setLocalUserEmail(iUser.email)

      const showWelcomeScreen = iUser.modules.includes(
        UserModuleType.WELCOME_SCREEN
      )

      const acceptedTermsAndConditions = showWelcomeScreen
        ? iUser.acceptedTermsAndConditions
        : undefined
      const activationDate = showWelcomeScreen
        ? iUser.activationDate
        : undefined

      dispatchUser(
        setCurrentUser({
          ...iUser,
          acceptedTermsAndConditions,
          activationDate,
          modules: iUser.modules,
        })
      )

      if (activationDate || acceptedTermsAndConditions) {
        dispatch(setFirstVisit(false))
      }
    }
  }

  dispatch(setUsingStaticData(true))
}
