import { AccountDetailDto } from "legado-generated-api-client"

export const avivaExampleData: AccountDetailDto = {
  providerCode: "aviva",
  accountName: "Aviva Insurance",
  accountNumber: "1234567",
  providerContactPhone: "01603622200",
  providerContactLink: "https://www.aviva.co.uk/help-and-support/contact-us/",
  keyInformation: {
    Bill: "£60.00 / Yearly",
    Renewal: "29/05/23",
  },
  secondaryInformation: {
    "Policy type:": "Buildings and contents",
    "Policy holder:": "Jason Kilbourn",
    "Policy start:": "29/05/2022",
  },
}

export const britishGasExampleData: AccountDetailDto = {
  providerCode: "british-gas",
  accountName: "British Gas",
  accountNumber: "1234567",
  providerContactLink:
    "https://www.britishgas.co.uk/help-and-support/contact-us",
  keyInformation: {
    Balance: "£67.00",
    "Payment due": "29/05/23",
    "Boiler replaced": "29/05/23",
  },
  secondaryInformation: {
    "Electricity used": "1128.kWh",
    "Gas used": "1012.8 kWh",
    Renewal: "27/12/2023",
    Tarrif: "Variable",
  },
}

export const netflixExampleData: AccountDetailDto = {
  providerCode: "netflix",
  accountName: "Netflix",
  accountNumber: "Family Plan",
  providerContactLink: "https://help.netflix.com/en/node/412",
  keyInformation: {
    "Plan cost": "£15.99/month",
    "Next bill due": "29/05/23",
  },
  secondaryInformation: {
    "Member since": "19/02/22",
    Renewal: "29/05/23",
    Email: "jane@email.com",
  },
}

export const dvlaExampleData: AccountDetailDto = {
  providerCode: "dvla",
  accountName: "DVLA",
  accountNumber: "ABC1234",
  providerContactLink: "https://www.gov.uk/contact-the-dvla",
  keyInformation: {
    Taxed: "27/12/2023",
    MOT: "27/12/2023",
  },
  secondaryInformation: {
    Make: "Ford",
    Colour: "Blue",
  },
}
