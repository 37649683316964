import { MouseEvent, useEffect, useRef } from "react"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { useKeyboardNavList } from "../../../hooks/useKeyboardNavList"
import { INavLink } from "../LeftNavigationBar"
import { TLeftBarNavigationData } from "./FoldersSharingNavigationItems"
import { InitialNavigationData } from "./utils"

export const NavigationLink = ({
  isSelected,
  link,
  isFocusedSubmenu = false,
  setExpandedLinks,
  expandedLinks,
  level,
}: {
  isSelected: boolean
  link: INavLink
  isFocusedSubmenu?: boolean
  setExpandedLinks: (links: TLeftBarNavigationData) => void
  expandedLinks: TLeftBarNavigationData
  level: number
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const lastItemActive =
    expandedLinks.level3.length > 0
      ? expandedLinks.level3
      : expandedLinks.level2.length > 0
      ? expandedLinks.level2
      : expandedLinks.level1.length > 0
      ? expandedLinks.level1
      : undefined

  const isActiveLink = lastItemActive ? lastItemActive === link.name : false

  const isLinkExpanded =
    level === 1
      ? expandedLinks.level1 === link.name
      : level === 2
      ? expandedLinks.level2 === link.name
      : true

  const handleClose = () => {
    if (isLinkExpanded) {
      if (link.onClick) link.onClick()
      buttonRef.current?.focus()
    }
    setExpandedLinks({
      level1: level === 1 ? link.name : "",
      level2: level === 2 ? link.name : "",
      level3: level === 3 ? link.name : "",
    })
  }

  const { activeIndex, handleKeyDown, setActiveIndex } = useKeyboardNavList({
    navItems: link.subLinks,
    closeMenu: handleClose,
  })

  const ChevronIcon = useGetIcon(
    isLinkExpanded ? "IconChevronDown" : "IconChevronRight"
  )
  const LinkIcon = useGetIcon(link.iconName)

  const hasChildren = link.subLinks && link.subLinks.length > 0

  const handleNavLinkClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (hasChildren) setActiveIndex(0)
    if (link.onClick) link.onClick()
    switch (level) {
      case 1:
        setExpandedLinks({
          ...InitialNavigationData,
          level1: isLinkExpanded ? "" : link.name,
        })
        break
      case 2:
        setExpandedLinks({
          ...expandedLinks,
          level2: isLinkExpanded ? "" : link.name,
          level3: "",
        })
        break
      case 3:
        setExpandedLinks({ ...expandedLinks, level3: link.name })
        break
    }
  }

  useEffect(() => {
    if (isSelected) {
      buttonRef.current?.focus()
    }
  }, [isSelected])

  return (
    <li
      role="none"
      className={`${
        link.name === "Notifications" ? "hide-on-desktop-only" : ""
      }`}
    >
      <button
        id={link.name.trim()}
        aria-haspopup={hasChildren}
        aria-expanded={hasChildren && isLinkExpanded}
        aria-controls={hasChildren ? `${link.name}-submenu` : undefined}
        disabled={link.disabled}
        onClick={handleNavLinkClick}
        ref={buttonRef}
        role="menuitem"
        tabIndex={isFocusedSubmenu && isSelected ? -1 : 0}
        type="button"
        data-href={link.href}
        className={`navigation-link ${isActiveLink ? "active-link" : ""} ${
          link.disabled ? "disabled" : ""
        }`}
        data-testid={"sidebar-navigation-link-" + link.name.trim() + "-button"}
      >
        {hasChildren && (
          <span className="icon-box chevron-icon">{ChevronIcon}</span>
        )}
        {link.iconName && (
          <span className="icon-box link-icon">{LinkIcon}</span>
        )}
        <span className="navigation-link-name truncate-2-lines">
          {link.name.trim()}
        </span>
      </button>
      {hasChildren && isLinkExpanded && (
        <>
          <ul
            className="navigation-list"
            aria-hidden={!isActiveLink}
            aria-labelledby={`${link.name}-submenu`}
            aria-orientation="vertical"
            role="menu"
            onKeyDown={handleKeyDown}
            data-testid="left-nav-menu-bar"
          >
            {link.subLinks &&
              link.subLinks.map((child, index) => (
                <NavigationLink
                  isSelected={activeIndex === index}
                  key={child.name}
                  link={{
                    ...child,
                    onClick: () => {
                      setExpandedLinks({
                        ...expandedLinks,
                        level2:
                          expandedLinks.level2 !== child.name ? child.name : "",
                      })
                      if (child.onClick) child.onClick()
                    },
                  }}
                  level={level + 1}
                  isFocusedSubmenu={activeIndex !== -1}
                  setExpandedLinks={setExpandedLinks}
                  expandedLinks={expandedLinks}
                />
              ))}
          </ul>
          {level === 1 && <hr className="menu-divider" />}
        </>
      )}
    </li>
  )
}
