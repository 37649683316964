import { OrganisationDto } from "legado-generated-api-client"

export const getAdvisersForOrganisationResponse: OrganisationDto[] = [
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe7321",
    type: "Intermediary",
    name: "Saige Howell",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2022-02-05T13:35:40.1844844"),
  },
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe7135",
    type: "Intermediary",
    name: "Joseph Pierce",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2022-01-04T13:35:40.1844844"),
  },
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe7138",
    type: "Intermediary",
    name: "Matt Damon",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2005-01-04T13:35:40.1844844"),
  },
  {
    organisationId: "b3b5eb4c-5b22-4266-5734-08d8ccfe7135",
    type: "Intermediary",
    name: "Angeline Roberts",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2022-01-04T13:35:40.1844844"),
  },
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe2235",
    type: "Intermediary",
    name: "Mathew Finnes",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2007-01-04T13:35:40.1844844"),
  },
]
