import { FunctionComponent } from "react"
import { NavLink } from "react-router-dom"
import { TLink } from "../../contexts/application/constants"
import { sendGaData } from "../../utils/gaEvents"
import { castToBool } from "../../utils/helpers"
import "./MenuBarSimpleItem.css"

type IMenuBarSimpleItem = {
  link: TLink
  basePath?: string
}
export const MenuBarSimpleItem: FunctionComponent<IMenuBarSimpleItem> = ({
  link,
  basePath,
}) => {
  const onAnalyticsClick = () => {
    if (link.href === "/analytics") {
      sendGaData({ name: "page_view", page_name: link.href })
    }
  }

  const isRunningInDock =
    castToBool(process.env.REACT_APP_RUNNING_IN_DOCK) ?? false
  return (
    <NavLink
      role="menuitem"
      className={({ isActive }) =>
        `menubarsimpleitem ${isActive ? "menubarsimpleitem--active" : ""}`
      }
      to={isRunningInDock ? `${basePath}${link.href}` : link.href}
      onClick={onAnalyticsClick}
    >
      <span className="menubarsimpleitem__text">{link.name}</span>
    </NavLink>
  )
}
