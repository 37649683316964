export const Hamburger = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="6" width="24" height="1.5" rx="0.75" fill="#FFFFFF" />
    <rect x="4" y="16" width="24" height="1.5" rx="0.75" fill="#FFFFFF" />
    <rect x="4" y="26" width="24" height="1.5" rx="0.75" fill="#FFFFFF" />
  </svg>
)

export default Hamburger
