import { Dispatch } from "react"
import { setDocumentLibraryClientFiles } from "../../contexts/application/action"
import { IFile } from "../../api/api-client/api-types"

export const renameFileWithinClientList = ({
  documentLibraryClientFiles,
  file,
  newName,
  dispatch,
}: {
  documentLibraryClientFiles: IFile[]
  file: IFile
  newName: string
  dispatch: Dispatch<any>
}): void => {
  if (documentLibraryClientFiles) {
    const newList = documentLibraryClientFiles.map((f) =>
      f.id === file.id ? { ...f, name: newName } : f
    )
    dispatch(setDocumentLibraryClientFiles(newList))
  }
}
