import { FC, useLayoutEffect, useRef, useState } from "react"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import "./Tooltip.css"

export enum TooltipMessageType {
  SingleLine = "singleLine",
  MultiLine = "multiLine",
  List = "list",
}

export interface TooltipProps {
  id: string // should match aria-describedby on the interactive child the tooltip is describing
  title?: string
  message: string | string[]
  tooltipMessageType?: TooltipMessageType
  visuallyHidden?: boolean
  open?: boolean
  children?: React.ReactNode
  iconName?: string
  icon?: React.ReactNode
  tabIndex?: number
}
/** TODO: refactor this component https://dev.azure.com/secure-the-file/Application/_workitems/edit/15348 */
export const Tooltip: FC<TooltipProps> = ({
  id,
  title,
  message,
  tooltipMessageType,
  visuallyHidden = false,
  children,
  open = false,
  iconName,
  icon,
  tabIndex,
}) => {
  const [translateTooltip, setTranslateTooltip] = useState<number>()
  const [toolTipOpen, setToolTipOpen] = useState(open)

  const InfoButton = useGetIcon("Info")
  const ActiveInfoButton = useGetIcon("InfoDark")
  const customIcon = useGetIcon(iconName)

  const openTooltip = () => {
    setToolTipOpen(true)
  }

  const closeTooltip = () => {
    setToolTipOpen(false)
  }

  const messageRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!messageRef.current || !toolTipOpen || translateTooltip) return
    const screenRight = document.body.getBoundingClientRect().right
    const toolTipRight = messageRef.current.getBoundingClientRect().right
    const toolTipLeft = messageRef.current.getBoundingClientRect().left
    if (toolTipLeft < 0) {
      setTranslateTooltip(0 - toolTipLeft + 20)
    }
    if (toolTipRight > screenRight) {
      setTranslateTooltip(screenRight - toolTipRight - 20)
    }
  }, [toolTipOpen, translateTooltip])

  const tooltipIcon = () => {
    switch (true) {
      case Boolean(customIcon):
        return customIcon
      case Boolean(icon):
        return icon
      case toolTipOpen:
        return ActiveInfoButton
      default:
        return InfoButton
    }
  }
  return (
    <div
      aria-hidden={!children}
      tabIndex={tabIndex}
      className={`tooltip ${toolTipOpen ? "open" : "closed"} ${
        visuallyHidden ? "tooltip-hidden" : ""
      }${true ? "direction-down" : ""}`}
      onTouchStart={openTooltip}
      onTouchEnd={closeTooltip}
      onFocus={openTooltip}
      onBlur={closeTooltip}
      onMouseDown={openTooltip}
      onMouseEnter={openTooltip}
      onMouseLeave={closeTooltip}
      onKeyDown={(e) => {
        if (e.key === "Escape") closeTooltip()
      }}
    >
      {children ?? (
        <div
          aria-describedby={id}
          className="infoButton"
          data-testid="tooltip-div"
        >
          {tooltipIcon()}
        </div>
      )}
      {toolTipOpen && (
        <p
          id={id}
          className={`tooltip-message ${
            visuallyHidden ? "visually-hidden" : ""
          }`}
          style={
            translateTooltip
              ? {
                  transform: `translateX(calc(-50% - 80px + ${
                    translateTooltip ?? 0
                  }px))`,
                }
              : undefined
          }
          ref={messageRef}
          role="tooltip"
        >
          {title && <div className="tooltip-title">{title}</div>}
          {tooltipMessageType === TooltipMessageType.List &&
            Array.isArray(message) && (
              <ul>
                {message.map((msg, index) => (
                  <li key={index}>
                    <p>{msg}</p>
                  </li>
                ))}
              </ul>
            )}
          {tooltipMessageType === TooltipMessageType.MultiLine &&
            Array.isArray(message) &&
            message.map((line, index) => <p key={index}>{line}</p>)}

          {tooltipMessageType !== TooltipMessageType.List &&
            tooltipMessageType !== TooltipMessageType.MultiLine && (
              <p>{message}</p>
            )}
        </p>
      )}
    </div>
  )
}
