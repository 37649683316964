import {
  IFolder,
  IContact,
  IContactInvite,
  IUser,
} from "../../api/api-client/api-types"
import {
  getContactOrgsFromLocalData,
  getFoldersFromLocalData,
  getUsersSharingWithMeFromLocalData,
} from "react-app-config"
import { useStandardUser } from "../../hooks/useStandardUser"
import { CreateContact, CreateContactProps } from "./CreateContact"
import { useCustomAppState } from "../../hooks/useCustomAppState"
import { petersEmail } from "../MyContactsInfoModal/data"
import { getDefaultFolders } from "../../utils/shares"

export const emptyContactUser = {
  firstName: "",
  surname: "",
  email: "",
  relationship: "",
  message: "",
  phoneNumber: "",
  countryCode: "+44",
} as IContactInvite

export const inviteJackHorner = {
  firstName: "Jack",
  surname: "Horner",
  email: "jack.horner@testclients.com",
  relationship: "Accountant",
  message: "NOTE TO JACK",
  phoneNumber: "075556665555",
} as IContactInvite

export const contactJackHorner = {
  contactId: "9a7cc79b-6ff4-40da-92e4-347e665b9717",
  firstName: "Jack",
  surname: "Horner",
  name: "Jack Horner",
  email: "jack.horner@testclients.com",
  relationship: "Accountant",
  isPending: false,
  contactType: "User",
  reference: null,
  created: "0001-01-01T00:00:00",
  message: "NOTE TO JACK",
  phoneNumber: "07555666555",
} as IContact

export const karimsEmail = "karim.mehdi@legadovault.onmicrosoft.com"
const karimsSharees = getUsersSharingWithMeFromLocalData(karimsEmail)
export const karimsFolders = getFoldersFromLocalData(karimsEmail) as IFolder[]
const karimsOrgContacts = getContactOrgsFromLocalData(karimsEmail)
export const jim = karimsOrgContacts.find((contact) =>
  contact.name?.includes("Jim")
) as unknown as IContact
export const karimsDefaultSharingOptions = getDefaultFolders(karimsFolders)
export const josie = karimsSharees.find(
  (user) => user.firstName === "Josie"
) as IUser
export const leo = karimsSharees.find(
  (user) => user.firstName === "Leo"
) as IUser
export const josieInvite = {
  firstName: josie?.firstName,
  surname: josie?.surname,
  email: josie?.email,
  message: "",
} as IContactInvite

export const KarimsCreateContact = (props: CreateContactProps) => {
  useStandardUser({ email: karimsEmail })
  useCustomAppState({ selectedSharingWithMeUserId: props.sharee?.userId })
  return <CreateContact {...props} />
}

export const PetersCreateContact = (props: CreateContactProps) => {
  useStandardUser({ email: petersEmail })
  useCustomAppState({ selectedSharingWithMeUserId: props.sharee?.userId })
  return <CreateContact {...props} />
}
