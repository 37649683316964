const ArrowLeft = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 12L3.75 12"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 5.25L3.75 12L10.5 18.75"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ArrowLeft
