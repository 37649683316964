export const TheFamilyElephantWelcomeLogo = () => (
  <svg
    width="324"
    height="111"
    viewBox="0 0 324 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M313.917 3.375H275.23C275.067 3.375 274.904 3.375 274.751 3.38459C272.824 3.49005 271.108 4.44883 269.996 5.88698C269.727 6.21297 269.507 6.56771 269.315 6.94163C268.864 7.83329 268.615 8.82083 268.615 9.87548V39.818C268.615 40.8726 268.864 41.8697 269.315 42.7422C269.507 43.1353 269.727 43.4805 269.996 43.816C271.108 45.2542 272.814 46.213 274.751 46.3184C274.904 46.3184 275.067 46.328 275.23 46.328H293.802C295.441 68.6482 275.278 78.6195 274.061 79.2043L274.003 79.2331L280.379 90.1439C295.048 84.3338 305.413 77.6319 311.453 70.048C317.311 62.6751 320.331 52.7134 320.504 40.1535V40.1343C320.504 40.0289 320.504 39.9234 320.504 39.818V9.88507C320.504 6.28967 317.551 3.375 313.898 3.375H313.917ZM306.448 34.1612C305 34.1612 303.821 32.9819 303.821 31.5342C303.821 30.0864 305 28.9071 306.448 28.9071C307.896 28.9071 309.075 30.0864 309.075 31.5342C309.075 32.9819 307.896 34.1612 306.448 34.1612Z"
      fill="#5AA4C2"
    />
    <path
      d="M101.127 12.8956C101.127 10.2302 102.613 8.6866 105.077 8.6866H122.114C124.531 8.6866 125.652 9.66455 125.652 11.6588V11.9656C125.652 14.0174 124.521 15.0433 122.114 15.0433H108.567V23.3558H118.318C120.782 23.3558 121.856 24.3817 121.856 26.2321V26.4335C121.856 28.4373 120.724 29.4057 118.318 29.4057H108.826V38.5907C108.826 40.9014 107.695 42.0807 105.489 42.0807H104.31C102.258 42.0807 101.127 40.537 101.127 37.8716V12.8956Z"
      fill="#5AA4C2"
    />
    <path
      d="M141.664 26.9608H142.488C142.488 26.7019 142.488 26.4431 142.44 26.2417C142.335 23.3175 140.542 21.7259 137.359 21.7259C133.917 21.7259 132.076 23.4709 131.204 25.3692C131.156 25.4747 126.534 25.8295 126.534 22.4929C126.534 21.3616 127.052 20.1823 128.078 19.2043C129.775 17.4594 133.006 16.2801 137.829 16.2801C145.787 16.2801 149.631 19.9234 149.631 26.6444V41.4191C149.631 41.7834 148.299 42.2436 146.908 42.2436C145.163 42.2436 143.783 41.5246 143.112 39.8275C142.958 39.3673 142.805 38.8017 142.699 38.1305C140.954 40.8534 137.771 42.445 134.022 42.445C128.788 42.445 125.604 39.5687 125.604 35.2638C125.604 29.6741 131.041 27.1046 141.664 26.9512V26.9608ZM141.108 30.6041C135.413 30.8055 132.383 31.8889 132.383 34.7077C132.383 36.6061 133.869 37.7854 136.285 37.7854C139.881 37.7854 142.44 35.5802 142.498 32.3971V30.6041H141.108Z"
      fill="#5AA4C2"
    />
    <path
      d="M195.039 26.2417V38.6578C195.039 41.2753 193.706 42.0998 191.856 42.0998H191.089C189.142 42.0998 187.8 41.3328 187.8 38.6578V27.3635C187.8 24.0749 186.362 22.3395 183.438 22.3395C181.386 22.3395 179.535 23.6243 178.509 25.4172V38.6578C178.509 41.2753 177.177 42.0998 175.326 42.0998H174.559C172.661 42.0998 171.271 41.3328 171.271 38.6578V27.5265C171.271 24.0845 169.833 22.3395 166.956 22.3395C164.904 22.3395 163.054 23.6243 162.028 25.4172V38.6578C162.028 41.2753 160.695 42.0998 158.845 42.0998H158.078C156.132 42.0998 154.789 41.3328 154.789 38.6578V17.4114C154.789 17.1526 156.074 16.5869 157.512 16.5869C159.305 16.5869 161.261 17.3539 161.827 20.6904C163.524 18.1785 165.93 16.2801 169.574 16.2801C173.735 16.2801 176.343 18.2839 177.579 21.4095C179.277 18.5332 181.836 16.2801 185.998 16.2801C192.364 16.2801 195.029 20.3357 195.029 26.2321L195.039 26.2417Z"
      fill="#5AA4C2"
    />
    <path
      d="M204.022 5.4076C206.534 5.4076 208.078 6.84576 208.078 9.1564C208.078 11.467 206.592 12.9052 204.022 12.9052C201.453 12.9052 199.967 11.467 199.967 9.1564C199.967 6.84576 201.51 5.4076 204.022 5.4076ZM200.379 20.0864C200.379 17.469 201.76 16.5965 203.61 16.5965H204.377C206.276 16.5965 207.608 17.421 207.608 20.0864V38.6674C207.608 41.2849 206.276 42.1094 204.377 42.1094H203.658C201.712 42.1094 200.37 41.3424 200.37 38.6674V20.0864H200.379Z"
      fill="#5AA4C2"
    />
    <path
      d="M213.514 38.6578V7.0471C213.514 6.84576 214.799 6.22255 216.237 6.22255C218.394 6.22255 220.753 7.35391 220.753 12.0231V38.6578C220.753 41.2753 219.42 42.0998 217.57 42.0998H216.803C214.904 42.0998 213.514 41.3328 213.514 38.6578Z"
      fill="#5AA4C2"
    />
    <path
      d="M230.916 49.7412L235.02 40.2973C233.888 40.096 233.121 39.7317 232.709 38.6578L224.09 18.6962C223.936 18.2839 225.835 16.5965 228.04 16.5389C229.785 16.5389 231.223 17.4114 232.143 20.1823L237.484 34.5543L242.306 20.2398C243.179 17.421 244.722 16.5485 246.362 16.5485C248.366 16.5485 250.417 18.2456 250.264 18.6003L238.874 48.2072C237.8 50.9301 236.362 51.7451 234.77 51.7451C232.671 51.7451 230.772 50.1056 230.926 49.7412H230.916Z"
      fill="#5AA4C2"
    />
    <path
      d="M18.7108 9.55908H6.80289C6.49609 9.55908 6.28516 9.40568 6.28516 9.20434V9.09887C6.28516 8.84 6.4865 8.6866 6.80289 8.6866H31.6447C31.9036 8.6866 32.1049 8.84 32.1049 9.09887V9.20434C32.1049 9.40568 31.9036 9.55908 31.6447 9.55908H19.6888V41.4766C19.6888 41.7834 19.5354 41.9848 19.2765 41.9848H19.1231C18.8643 41.9848 18.7108 41.7834 18.7108 41.4766V9.55908Z"
      fill="#013334"
    />
    <path
      d="M56.9946 28.4948V41.4766C56.9946 41.8314 56.8412 41.9848 56.5824 41.9848H56.429C56.2276 41.9848 56.0742 41.8314 56.0742 41.4766V28.5428C56.0742 22.3875 53.0445 18.3798 47.0426 18.3798C42.0666 18.3798 37.8096 22.0231 37.8096 27.258V41.4766C37.8096 41.8314 37.6562 41.9848 37.3973 41.9848H37.2439C37.0426 41.9848 36.8892 41.8314 36.8892 41.4766V7.50731C36.8892 7.40184 36.9946 7.35391 37.196 7.35391C37.5603 7.35391 37.8096 7.61278 37.8096 8.42773V24.1324C38.8834 20.1248 42.7377 17.5648 47.0426 17.5648C53.6581 17.5648 56.9946 21.8793 56.9946 28.4948Z"
      fill="#013334"
    />
    <path
      d="M74.2909 17.5648C80.5996 17.5648 85.0196 22.1861 85.0196 28.6003V28.7058C85.0196 29.118 84.8182 29.2715 84.4539 29.2715H63.4088V30.096C63.5143 36.5102 67.8767 41.4383 74.5977 41.4383C80.1874 41.4383 82.9582 38.2552 83.8882 35.6377C84.0416 35.6377 84.406 35.7911 84.406 36.2513C84.406 36.6061 83.9457 37.9483 82.5076 39.3865C81.0694 40.8247 78.5095 42.2628 74.6073 42.2628C67.2152 42.2628 62.498 36.8745 62.498 29.8947C62.498 22.9148 67.474 17.5744 74.3005 17.5744L74.2909 17.5648ZM74.2909 18.3319C68.4903 18.3319 64.08 22.7997 63.4664 28.4373H84.0992C84.0512 22.5888 79.9956 18.3319 74.3005 18.3319H74.2909Z"
      fill="#013334"
    />
    <path
      d="M67.033 89.1468V57.6415C67.033 57.0759 67.2919 56.7691 67.8575 56.7691H87.8192C88.126 56.7691 88.3369 56.9225 88.3369 57.1813V57.2868C88.3369 57.5457 88.1356 57.6415 87.8192 57.6415H68.0109V72.5217H82.4788C82.7856 72.5217 82.9966 72.6272 82.9966 72.8764V72.9244C82.9966 73.1832 82.7952 73.3366 82.4788 73.3366H68.0109V89.0893H88.5862C88.893 89.0893 89.0464 89.2427 89.0464 89.5015V89.607C89.0464 89.8659 88.893 89.9618 88.5862 89.9618H67.8575C67.2919 89.9618 67.033 89.655 67.033 89.1372V89.1468Z"
      fill="#013334"
    />
    <path
      d="M94.6936 89.5015V55.5802C94.6936 55.4747 94.799 55.4268 95.0004 55.4268C95.3551 55.4268 95.614 55.6856 95.614 56.5006V89.5015C95.614 89.8659 95.4606 90.0672 95.2017 90.0672H95.0963C94.8374 90.0672 94.684 89.8659 94.684 89.5015H94.6936Z"
      fill="#013334"
    />
    <path
      d="M113.38 65.6377C119.689 65.6377 124.109 70.259 124.109 76.6732V76.7786C124.109 77.1909 123.907 77.3443 123.543 77.3443H102.498V78.1689C102.603 84.583 106.966 89.5111 113.687 89.5111C119.277 89.5111 122.047 86.328 122.977 83.7106C123.131 83.7106 123.495 83.864 123.495 84.3242C123.495 84.6789 123.035 86.0212 121.597 87.4594C120.159 88.8975 117.599 90.3357 113.696 90.3357C106.304 90.3357 101.587 84.9474 101.587 77.9675C101.587 70.9876 106.563 65.6473 113.39 65.6473L113.38 65.6377ZM113.38 66.4047C107.579 66.4047 103.169 70.8726 102.556 76.5102H123.188C123.14 70.6617 119.085 66.4047 113.39 66.4047H113.38Z"
      fill="#013334"
    />
    <path
      d="M152.738 77.8045C152.738 85.1391 147.915 90.3261 141.041 90.3261C135.288 90.3261 131.597 86.8362 130.676 83.1449V99.003C130.676 99.3098 130.523 99.5207 130.264 99.5207H130.159C129.9 99.5207 129.746 99.3194 129.746 99.003V66.0596C129.746 66.0116 129.852 65.9062 130.053 65.9062C130.417 65.9062 130.619 66.165 130.619 66.98V72.9819C132.21 68.8208 135.806 65.6473 141.089 65.6473C147.656 65.6473 152.738 70.8342 152.738 77.8045ZM151.817 77.8045C151.817 71.3903 147.1 66.4143 141.041 66.4143C135.7 66.4143 132.114 69.7988 130.619 74.5255V79.8659C130.619 84.9953 134.78 89.5111 141.041 89.5111C147.301 89.5111 151.817 84.6406 151.817 77.8141V77.8045Z"
      fill="#013334"
    />
    <path
      d="M178.605 76.5677V89.5495C178.605 89.9042 178.452 90.0576 178.193 90.0576H178.04C177.838 90.0576 177.675 89.9042 177.675 89.5495V76.6157C177.675 70.4603 174.646 66.4527 168.644 66.4527C163.668 66.4527 159.411 70.096 159.411 75.3309V89.5495C159.411 89.9042 159.257 90.0576 158.998 90.0576H158.845C158.644 90.0576 158.49 89.9042 158.49 89.5495V55.5802C158.49 55.4747 158.596 55.4268 158.797 55.4268C159.152 55.4268 159.411 55.6856 159.411 56.5006V72.2053C160.485 68.1976 164.339 65.6377 168.644 65.6377C175.259 65.6377 178.596 69.9522 178.596 76.5677H178.605Z"
      fill="#013334"
    />
    <path
      d="M202.469 76.6732H202.987V74.8227C202.987 69.6358 199.756 66.4622 194.569 66.4622C190.005 66.4622 186.822 68.514 185.585 71.2369C185.432 71.2369 185.02 71.1315 185.02 70.6713C185.02 70.3644 185.585 69.3865 186.458 68.5715C187.944 67.0855 190.36 65.6473 194.617 65.6473C200.36 65.6473 203.907 69.1372 203.907 74.8323V89.9234C203.907 89.9714 203.754 90.0289 203.553 90.0289C203.198 90.0289 202.987 89.77 202.987 88.955V83.3079C201.913 87.2101 197.858 90.3357 192.364 90.3357C187.436 90.3357 184.051 87.9771 184.051 84.0749C184.051 78.8879 188.672 76.7403 202.469 76.6828V76.6732ZM202.987 77.4402H202.421C189.286 77.4881 184.972 79.444 184.972 84.0557C184.972 87.5457 188.001 89.5495 192.412 89.5495C198.212 89.5495 202.987 85.7048 202.987 80.1535V77.4306V77.4402Z"
      fill="#013334"
    />
    <path
      d="M231.261 76.5677V89.5495C231.261 89.9042 231.108 90.0576 230.849 90.0576H230.695C230.494 90.0576 230.341 89.9042 230.341 89.5495V76.6156C230.341 70.5083 227.311 66.4527 221.463 66.4527C217.052 66.4527 213.61 68.9646 212.076 73.0202V89.5495C212.076 89.9042 211.923 90.0576 211.664 90.0576H211.51C211.309 90.0576 211.156 89.9042 211.156 89.5495V66.05C211.156 66.002 211.261 65.8966 211.463 65.8966C211.817 65.8966 212.028 66.1554 212.028 66.9704V71.7451C213.514 68.2551 217.004 65.6377 221.52 65.6377C227.934 65.6377 231.271 69.9522 231.271 76.5677H231.261Z"
      fill="#013334"
    />
    <path
      d="M239.881 83.1928V66.8266H235.672C235.259 66.8266 235.106 66.6732 235.106 66.4718V66.3664C235.106 66.1075 235.259 66.0116 235.672 66.0116H239.881V60.422C239.881 60.0672 240.034 59.9042 240.293 59.9042H240.446C240.648 59.9042 240.801 60.0576 240.801 60.422V66.0116H248.701C249.114 66.0116 249.267 66.1171 249.267 66.3664V66.4718C249.267 66.6732 249.114 66.8266 248.701 66.8266H240.801V83.1449C240.801 87.8621 242.44 89.5591 245.47 89.5591C247.522 89.5591 248.96 88.4853 249.679 86.8937C249.785 86.8937 250.034 87.0471 250.034 87.4018C250.034 87.6607 249.833 88.2743 249.161 88.9455C248.442 89.6645 247.311 90.3261 245.47 90.3261C241.875 90.3261 239.881 88.4277 239.881 83.1928Z"
      fill="#013334"
    />
  </svg>
)

export default TheFamilyElephantWelcomeLogo
