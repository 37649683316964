import { TApplicationAction, IApplicationState } from "./constants"
import {
  SET_SELECTED_FILE,
  ADD_FILES_TO_FOLDER,
  CHANGE_FILE_IN_FOLDER,
  DELETE_FILE_IN_FOLDER,
  ADD_FILE_TO_SHARED_WITH_ME_FOLDER,
  ADD_FILES_TO_SHARED_WITH_ME_FOLDER,
  CHANGE_FILE_IN_SHARED_WITH_ME_FOLDER,
  SET_PENDING_FILES,
  ADD_PENDING_FILES,
  DELETE_FILE_IN_SHARED_WITH_ME_FOLDER,
  DELETE_FILE_IN_CLIENTS_FILE_LIST,
} from "./action"
import {
  fileInAllFoldersDoSomething,
  fileInFolderAddFileStart,
  fileInFolderChangeFile,
  fileInFolderRemoveFile,
} from "../../api/api-client/folder/editFilesToFolder"
import { addFilesToFolder } from "./fileReducerHelper"
import { INodeShare } from "../../api/api-client/api-types"

export const fileReducer = (
  applicationState: IApplicationState,
  action: TApplicationAction
) => {
  switch (action.type) {
    case SET_SELECTED_FILE:
      return {
        ...applicationState,
        selectedFileId: action.fileId,
      }

    case CHANGE_FILE_IN_FOLDER:
      if (action.file && action.folderId) {
        const newFile = action.file
        if (!newFile) {
          return
        }
        const folderId = action.folderId
        if (!folderId) {
          return
        }
        return {
          ...applicationState,
          folders: fileInAllFoldersDoSomething({
            folders: applicationState.folders,
            selectedfolderId: folderId,
            fn: fileInFolderChangeFile({ file: newFile }),
          }),
        }
      }
      break

    case DELETE_FILE_IN_FOLDER:
      if (action.file && action.folderId) {
        const file = action.file
        return {
          ...applicationState,
          folders: fileInAllFoldersDoSomething({
            folders: applicationState.folders,
            selectedfolderId: action.folderId,
            fn: fileInFolderRemoveFile({ file: file }),
          }),
        }
      }
      break

    case ADD_PENDING_FILES:
      if (action.files && action.files.length > 0) {
        const oldFiles = applicationState.pendingFiles || []
        return {
          ...applicationState,
          pendingFiles: [...oldFiles, ...action.files],
        }
      }
      break

    case SET_PENDING_FILES:
      const newFiles = action.files || []
      return {
        ...applicationState,
        pendingFiles: newFiles,
      }

    case ADD_FILES_TO_FOLDER:
      if (action.files) {
        const folders = applicationState.folders
        if (!folders) {
          break
        }
        const newFolders = addFilesToFolder({
          files: action.files,
          folders,
        })

        const oldNodeShares = applicationState.contactsShares || []
        const parentShares = oldNodeShares.filter(
          (s) => s.nodeId === action.files[0].parentId
        )

        const newNodeShares: INodeShare[] = []
        action.files.forEach((file) => {
          parentShares.forEach((share) => {
            newNodeShares.push({ ...share, nodeId: file.id })
          })
        })

        return {
          ...applicationState,
          folders: newFolders,
          contactShares: [...oldNodeShares, ...newNodeShares],
        }
      }
      break

    case ADD_FILES_TO_SHARED_WITH_ME_FOLDER:
      if (action.files) {
        if (!applicationState.sharedWithMeFolders) {
          break
        }
        const newFolders = addFilesToFolder({
          files: action.files,
          folders: applicationState.sharedWithMeFolders,
        })

        return {
          ...applicationState,
          sharedWithMeFolders: newFolders,
        }
      }
      break

    case ADD_FILE_TO_SHARED_WITH_ME_FOLDER:
      if (action.file && action.folderId) {
        const newFile = action.file
        const folderId = action.folderId
        if (!applicationState.sharedWithMeFolders) return
        const newFolders = fileInAllFoldersDoSomething({
          folders: applicationState.sharedWithMeFolders,
          selectedfolderId: folderId,
          fn: fileInFolderAddFileStart({ file: newFile }),
        })
        return {
          ...applicationState,
          sharedWithMeFolders: newFolders,
        }
      }
      break

    case CHANGE_FILE_IN_SHARED_WITH_ME_FOLDER:
      if (action.file && action.folderId) {
        const newFile = action.file
        if (!newFile) {
          return
        }
        const folderId = action.folderId
        if (!folderId) {
          return
        }
        if (!applicationState.sharedWithMeFolders) return
        return {
          ...applicationState,
          sharedWithMeFolders: fileInAllFoldersDoSomething({
            folders: applicationState.sharedWithMeFolders,
            selectedfolderId: folderId,
            fn: fileInFolderChangeFile({ file: newFile }),
          }),
        }
      }
      break

    case DELETE_FILE_IN_SHARED_WITH_ME_FOLDER:
      if (
        action.file &&
        action.folder &&
        applicationState.sharedWithMeFolders
      ) {
        return {
          ...applicationState,
          sharedWithMeFolders: fileInAllFoldersDoSomething({
            folders: applicationState.sharedWithMeFolders,
            selectedfolderId: action.folder.id,
            fn: fileInFolderRemoveFile({ file: action.file }),
          }),
        }
      }
      break
    case DELETE_FILE_IN_CLIENTS_FILE_LIST:
      if (action.fileId && applicationState.documentLibraryClientFiles) {
        return {
          ...applicationState,
          documentLibraryClientFiles:
            applicationState.documentLibraryClientFiles.filter(
              (file) => file.id !== action.fileId
            ),
        }
      }
      break

    default:
      return
  }
}
