import { useWidth } from "../component/ImageViewer/helper"
import { useState } from "react"

/**
 * Hook to handle the left nav menu expansion and shrinking
 * Has additional isDesktop property to check if the current width of the window is desktop
 * @returns {Object} expanded, setIsExpanded, isDesktop
 */
export const useLeftNavMenu = () => {
  const width = useWidth()
  const isDesktop = width >= 1230

  const [isLeftNavShowing, setIsLeftNavShowing] = useState(isDesktop)

  return {
    isLeftNavShowing,
    setIsLeftNavShowing,
    isDesktop,
  }
}
