import { useEffect } from "react"
import { ApiConnection } from "../api/api-client/common/ApiConnection"
import { ApiController } from "../api/apiController"
import { setApiConnection } from "../contexts/application/action"
import { useApplicationContext } from "../contexts/application/context"
import { usePlatformContext } from "../contexts/platform/platformContextProvider"

const api = ApiController.getInstance()

export const useRefreshTokenOnTabFocus = () => {
  const { dispatch } = useApplicationContext()
  const { loginType } = usePlatformContext()
  useEffect(() => {
    const tabSwitchTokenCheck = async () => {
      if (api.connected) {
        if (document.visibilityState === "visible") {
          const result = await api.doTokenRefresh(loginType)
          if (!result) {
            dispatch(setApiConnection({ failed: true } as ApiConnection))
          }
        }
      }
    }

    document.addEventListener("visibilitychange", tabSwitchTokenCheck)
    return () => {
      document.removeEventListener("visibilitychange", tabSwitchTokenCheck)
    }
  }, [dispatch, loginType])
}
