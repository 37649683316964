import { FC, FormEvent } from "react"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { HoverType } from "../../../utils/Types/shared"
import { pageString } from "../../../utils/helpers"
import { BUTTON_ICON_POSITION, BUTTON_SIZE } from "../Button"
import "./PseudoLink.css"

export interface PseudoLinkProps {
  hoverType?: HoverType
  className?: string
  icon?: string
  iconPosition?:
    | typeof BUTTON_ICON_POSITION.LEFT
    | typeof BUTTON_ICON_POSITION.RIGHT
  size?: typeof BUTTON_SIZE.REGULAR | typeof BUTTON_SIZE.SMALL
  onClick?: (event: FormEvent<HTMLButtonElement>) => void
  disabled?: boolean
  children?: React.ReactNode
}

export const PseudoLink: FC<PseudoLinkProps> = ({
  hoverType,
  className,
  icon,
  iconPosition = BUTTON_ICON_POSITION.RIGHT,
  size = BUTTON_SIZE.REGULAR,
  onClick,
  disabled,
  children,
}) => {
  const iconElement = useGetIcon(icon)
  return (
    <button
      data-testid={pageString() + "-" + className}
      className={`pseudo-link ${size} ${
        iconElement ? `pseudo-link-icon-${iconPosition}` : ""
      }${className ? ` ${className}` : ""} ${hoverType ?? ""}`}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }
      }}
      disabled={disabled}
      tabIndex={0}
    >
      <span className={`pseudo-link-text `}>{children}</span>
      {iconElement && (
        <span className="icon-box pseudo-link-icon">{iconElement}</span>
      )}
    </button>
  )
}
