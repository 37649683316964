const AccordianDown = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.45455 8C1.45455 4.38505 4.38505 1.45455 8 1.45455C11.615 1.45455 14.5455 4.38505 14.5455 8C14.5455 11.615 11.615 14.5455 8 14.5455C4.38505 14.5455 1.45455 11.615 1.45455 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM11.588 7.05954C11.872 6.77552 11.872 6.31504 11.588 6.03102C11.304 5.747 10.8435 5.747 10.5595 6.03102L8.08215 8.50835L5.60481 6.03102C5.3208 5.747 4.86031 5.747 4.57629 6.03102C4.29228 6.31504 4.29228 6.77552 4.57629 7.05954L7.42658 9.90982C7.51262 9.99599 7.61481 10.0643 7.72731 10.111C7.8398 10.1576 7.96038 10.1816 8.08215 10.1816C8.20392 10.1816 8.32449 10.1576 8.43699 10.111C8.54932 10.0644 8.65175 9.99581 8.73771 9.90982L11.588 7.05954Z"
      fill="#323240"
    />
  </svg>
)

export default AccordianDown
