export enum WorkflowStatuses {
  Pending = "Pending",
  InProgress = "InProgress",
  Completed = "Completed",
  Rejected = "Rejected",
  RequiredForUpload = "Required for upload",
  UploadedByUser = "Uploaded by User",
  VerifiedByCaseHandler = "Verified by Case Handler",
  RejectedByCaseHandler = "Rejected by Case Handler",
  RequiredForAction = "Required for action",
  CommentedByPr = "Commented by PR",
  RejectedByPr = "Rejected by PR",
  Actioned = "Actioned",
}
