import { createContext, FC, useReducer, Dispatch, useContext } from "react"
import {
  IOrganisationState,
  IOrganisationAction,
  organisationsReducer,
  initialState,
} from "./reducer"

export const OrganisationsContext = createContext(
  {} as {
    organisationsState: IOrganisationState
    dispatch: Dispatch<IOrganisationAction>
  }
)

export const OrganisationsProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [organisationsState, dispatch] = useReducer(
    organisationsReducer,
    initialState
  )

  const value = { organisationsState, dispatch }
  return (
    <OrganisationsContext.Provider value={value}>
      {children}
    </OrganisationsContext.Provider>
  )
}

export const useOrganisationContext = () => useContext(OrganisationsContext)

export * from "./actions"
