import { ApiController } from "../apiController"
import { BulkDistributionController } from "./BulkDistributionController"
import { DocumentLibraryController } from "./DocumentLibraryController"
import { OrganisationController } from "./OrganisationController"

export const getBulkDistributionController = (): BulkDistributionController => {
  const apiBase = ApiController.getInstance()
  const api = new BulkDistributionController(apiBase)
  return api
}

export const getOrganisationController = (): OrganisationController => {
  const apiBase = ApiController.getInstance()
  const api = new OrganisationController(apiBase)
  return api
}

export const getDocumentLibraryController = (): DocumentLibraryController => {
  const apiBase = ApiController.getInstance()
  const api = new DocumentLibraryController(apiBase)
  return api
}
