import { PAGE_PATH } from "../../contexts/application/constants"
import { INavLink } from "./LeftNavigationBar"

export const leftNavMenuItems: INavLink[] = [
  { name: "Home", href: PAGE_PATH.HomePage, iconName: "DashboardHome" },
  {
    name: "Notifications",
    href: PAGE_PATH.NotificationsPage,
    iconName: "OutlineBell",
  },
  { name: "Files", iconName: "Folder" },
  { name: "Sharing", iconName: "ThreeUsers" },
  { name: "Clients", href: PAGE_PATH.ClientsPage, iconName: "ThreeUsers" },
  {
    name: "Organisations",
    href: PAGE_PATH.Organisations,
    iconName: "ThreeUsers",
  },
  {
    name: "Activity Feed",
    href: PAGE_PATH.RecentActivityPage,
    iconName: "Activity",
  },
]
