/*Are suggestions to the user in the form of verbs, shown in the AlertMessage component.
Only intended to be used in the AlertMessage component.
The wording will be improved time to time by our UX team*/
export interface alertMessageErrorTranslation {
  errorMessage: string
  suggestion: string
}

const alertMessageErrorTranslations: alertMessageErrorTranslation[] = [
  {
    errorMessage: "Invalid Name",
    suggestion: "Check you've spelled your name correctly",
  },
  {
    errorMessage: "Email is required",
    suggestion: "enter an email",
  },
  {
    errorMessage: "Email is invalid",
    suggestion: "enter a valid email",
  },
  {
    errorMessage: "Password is required",
    suggestion: "enter a password",
  },
]

export default alertMessageErrorTranslations
