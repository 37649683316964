import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { LocalStorageKeys } from "../contexts/auth/localStorageKeys"
/**
 * When expanding this package look into the documentation for react-idle-timer
 * https://idletimer.dev/docs/features/idle-detection
 * DOM events to watch for activity on. default ones:
      [
        'mousemove',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove',
        'visibilitychange'
      ]
 * @returns boolean
 */

export default function useIsIdleStateTimer() {
  const [isIdle, setIsIdle] = useState<boolean>(false)
  const hasSignedInViaLoginPage =
    localStorage
      .getItem(LocalStorageKeys.HasUsedLoginPageToSignIn)
      ?.toLowerCase() === "true"
  const timeoutMinutes = +(process.env.LOGIN_TIMEOUT_MINUTES ?? 30)
  const minuteInMilliseconds = 60_000
  const timeout = timeoutMinutes * minuteInMilliseconds
  const onIdle = () => {
    setIsIdle(true)
  }
  const onActive = () => {
    setIsIdle(false)
  }

  useIdleTimer({
    onIdle,
    onActive,
    timeout: timeout,
    throttle: 500,
    disabled: !hasSignedInViaLoginPage,
  })

  return { isIdle, setIsIdle }
}
