import { createContext, useContext } from "react"
import { SingleSpaProps, DockSDK } from "@fnz-dock/core"

export type AwaitedSDKProps = Omit<SingleSpaProps, "sdk"> & { sdk: DockSDK }

// Create the Context
export const DockSDKContext = createContext<AwaitedSDKProps | undefined>(
  undefined
)

// Export the custom hook
export function useDockSDK() {
  const context = useContext<AwaitedSDKProps | undefined>(DockSDKContext)
  return context
}
