const WelcomePortability = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g mask="url(#mask0_3909_84262)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9161 14.9161C9.50265 14.3295 10.2982 14 11.1277 14H21.5533C22.2301 14 22.8886 14.2195 23.43 14.6255L28.8513 18.6915H42.4046C43.2341 18.6915 44.0297 19.0211 44.6162 19.6076C45.2028 20.1942 45.5323 20.9897 45.5323 21.8192V26.5108C45.5323 27.3745 44.8322 28.0746 43.9685 28.0746C43.1048 28.0746 42.4046 27.3745 42.4046 26.5108V21.8192H28.8513C28.1746 21.8192 27.5161 21.5997 26.9747 21.1937L21.5533 17.1277L11.1277 17.1277L11.1277 45.2769C11.1277 46.1406 10.4275 46.8408 9.56386 46.8408C8.70017 46.8408 8.00002 46.1406 8.00002 45.2769V17.1277C8.00002 16.2982 8.32954 15.5026 8.9161 14.9161Z"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1176 25.5373C15.6501 25.1535 16.2899 24.947 16.9464 24.947H48.0541C48.5497 24.947 49.0382 25.0647 49.4794 25.2906C49.9205 25.5164 50.3017 25.8438 50.5914 26.2459C50.8812 26.6479 51.0713 27.113 51.146 27.603C51.2208 28.0929 51.178 28.5936 51.0213 29.0637L45.4521 45.7715C45.2392 46.4101 44.6416 46.8408 43.9685 46.8408H9.56386C9.06117 46.8408 8.58913 46.5992 8.2952 46.1914C8.00127 45.7835 7.9213 45.2593 8.08026 44.7824L13.9792 27.0856C14.1868 26.4628 14.5851 25.9212 15.1176 25.5373ZM48.0541 28.0747H16.9464L11.7336 43.7131H42.8413L48.0541 28.0747Z"
        stroke="none"
      />
    </g>
    <rect
      width="22"
      height="22"
      className="fill-white stroke-white"
      x="29"
      y="25"
    />
    <path
      d="M44.875 34L31.125 34"
      className="second-color-stroke"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.75 28.375L31.125 34L36.75 39.625"
      className="second-color-stroke"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.125 46H51.875"
      className="second-color-stroke"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.25 51.625L51.875 46L46.25 40.375"
      className="second-color-stroke"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default WelcomePortability
