const Tick = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
      fill="var(--color-universal-secondary-b)"
    />
    <path d="M8 16L14 21L24 11" stroke="white" strokeWidth="4" />
  </svg>
)

export default Tick
