import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { IFile, IFolder } from "../../api/api-client/api-types"
import { ComponentWithClass } from "../../api/api-client/common/ComponentWithClass"
import { useApplicationContext } from "../../contexts/application/context"
import { Button, BUTTON_VARIANT } from "../atoms/Button"
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalSubHeader,
} from "../modules/Modal"
import "./DeleteNode.css"
import {
  deleteFile,
  deleteIFolder,
} from "./helpers"
import { useUserContext } from "../../contexts/users"
import { useToastContext } from "../../contexts/toasts"
import { loadUserData } from "../../contexts/users/loaduserData"

export interface DeleteNodeProps extends ComponentWithClass {
  folder?: IFolder
  file?: IFile
  onClose: () => void
  isShared?: boolean
}

export function getNodeCount(
  folders: IFolder[],
  childFileCount: number,
  childFolderCount: number
) {
  folders.forEach((mappedFolder: IFolder) => {
    if (!mappedFolder) return [0, 0]
    if (mappedFolder.childFolders) {
      ;[childFileCount, childFolderCount] = getNodeCount(
        mappedFolder.childFolders,
        childFileCount,
        childFolderCount
      )
    }
    childFolderCount++
    childFileCount += mappedFolder.contents ? mappedFolder.contents!.length : 0
  })
  return [childFileCount, childFolderCount]
}

export const DeleteNode: React.FC<DeleteNodeProps> = ({
  folder,
  file,
  onClose,
  isShared = false,
}) => {
  const {
    applicationState: {
      sharedWithMeFolders,
      folders,
      pinnedFoldersAndFiles,
      providerStatuses,
      flatFileList,
      documentLibraryClientFiles,
    },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: toastDispatch } = useToastContext()

  const navigate = useNavigate()

  const sections = [
    "PersonalAndFamily",
    "Finances",
    "Legal",
    "SavingsAndInvestments",
    "HomeAndProperty",
    "Vehicles",
    "Legacy",
  ]
  const isSection = sections.includes(folder?.folderType!)

  const handleDelete = async () => {
    if (folder) {
      await deleteIFolder(
        folder,
        isShared,
        onClose,
        dispatch,
        toastDispatch,
        pinnedFoldersAndFiles,
        currentUser,
        providerStatuses,
        navigate,
        isSection
      )
    }
    if (file) {
      await deleteFile(
        file,
        isShared,
        onClose,
        dispatch,
        toastDispatch,
        folders,
        sharedWithMeFolders,
        pinnedFoldersAndFiles,
        currentUser,
        flatFileList,
        documentLibraryClientFiles
      )
    }

    await loadUserData({ currentUser, dispatch, toastDispatch })
  }

  let res =
    folder && folder.childFolders
      ? getNodeCount(folder.childFolders, 0, 0)
      : [0, 0]
  let childFileCount = res[0]
  let childFolderCount = res[1]
  childFileCount += folder && folder.contents ? folder.contents!.length : 0

  return (
    <Modal
      onClose={onClose}
      name={folder ? "Delete folder and all contents" : "Delete file"}
      className="delete-node"
    >
      <ModalHeader hideHeaderBorderBottom>
        {isSection ? "Delete Section" : folder ? folder.name : "Delete file"}
      </ModalHeader>

      {folder && (
        <ModalSubHeader>Delete folder and all its contents</ModalSubHeader>
      )}

      <ModalContent className="node-content pb-4">
        <div className="node-name">
          {folder ? "" : file?.name + "." + file?.extension}
        </div>
        {folder && childFolderCount + childFileCount > 0 && (
          <div>
            Please note by deleting this {isSection ? "section" : "folder"} it
            will also delete
            <strong>
              {" all "}
              {childFileCount > 0 && " files (" + childFileCount + ") "}
            </strong>
            {childFileCount > 0 && childFolderCount > 0 && "and "}
            <strong>
              {" "}
              {childFolderCount > 0 && "subfolders (" + childFolderCount + ") "}
            </strong>
            it contains.
          </div>
        )}
      </ModalContent>
      <ModalFooter>
        <Button
          className="left-button mr-4"
          variant="tertiary"
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="right-button"
          variant={BUTTON_VARIANT.DANGER_PRIMARY}
          type="submit"
          onClick={() => handleDelete()}
        >
          Delete {childFolderCount + childFileCount > 0 && " all"}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
