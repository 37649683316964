export const AppIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.2" width="34" height="34" rx="8" fill="white" />
    <path
      d="M21.1753 20.5631C18.8008 20.5608 16.5242 19.6308 14.8451 17.9772C13.1661 16.3236 12.2218 14.0815 12.2195 11.7429C12.2234 10.8771 12.3551 10.0164 12.6106 9.18793C11.2976 9.92513 10.1909 10.9716 9.3896 12.2337C8.58829 13.4957 8.11746 14.9339 8.01929 16.4192C7.92112 17.9045 8.19869 19.3906 8.82712 20.7443C9.45555 22.098 10.4152 23.277 11.6201 24.1756C12.8249 25.0742 14.2374 25.6643 15.7308 25.8931C17.2242 26.1219 18.7519 25.9822 20.177 25.4865C21.6021 24.9908 22.88 24.1547 23.8963 23.0529C24.9126 21.9512 25.6354 20.6184 26 19.1739C24.5568 20.0754 22.8843 20.557 21.1753 20.5631Z"
      fill="#FFA4C2"
    />
    <path
      d="M21.1752 16.9393C22.1275 16.9375 23.0616 16.6821 23.8786 16.2001C24.6955 15.7181 25.3648 15.0274 25.8156 14.2013C25.2366 12.4552 24.1292 10.9252 22.642 9.81678C21.1548 8.70831 19.3589 8.07437 17.4953 8C16.9884 8.4841 16.5846 9.06279 16.3073 9.70229C16.03 10.3418 15.8849 11.0293 15.8804 11.7246C15.884 13.1065 16.443 14.4308 17.4352 15.408C18.4274 16.3851 19.7721 16.9357 21.1752 16.9393Z"
      fill="#FFA4C2"
    />
  </svg>
)

export default AppIcon
