const Union = () => (
  <svg
    width="18"
    height="4"
    viewBox="0 0 20 4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3.75C1.0335 3.75 0.25 2.9665 0.25 2C0.25 1.0335 1.0335 0.25 2 0.25C2.9665 0.25 3.75 1.0335 3.75 2C3.75 2.9665 2.9665 3.75 2 3.75ZM9 3.75C8.0335 3.75 7.25 2.9665 7.25 2C7.25 1.0335 8.0335 0.25 9 0.25C9.9665 0.25 10.75 1.0335 10.75 2C10.75 2.9665 9.9665 3.75 9 3.75ZM14.25 2C14.25 2.9665 15.0335 3.75 16 3.75C16.9665 3.75 17.75 2.9665 17.75 2C17.75 1.0335 16.9665 0.25 16 0.25C15.0335 0.25 14.25 1.0335 14.25 2Z"
      fill="#48475C"
    />
  </svg>
)
export default Union
