const ArrowRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
  >
    <path
      d="M0.75 8L17.25 8"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 14.75L17.25 8L10.5 1.25"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ArrowRight
