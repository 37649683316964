import { AxiosError } from "axios"
import { useEffect, useState } from "react"

import { getRegistrationDetails } from "../../api/lib/auth/auth"
import {
  setLoadingState,
  setShowFooter,
  setShowMenu,
} from "../../contexts/application/action"

export const useRegistrationDetails = ({
  activationCode,
  dispatch,
}: {
  activationCode: string | null
  dispatch: (action: any) => void
}) => {
  const [email, setEmail] = useState("")
  const [lastFourPhoneDigits, setLastFourPhoneDigits] = useState("")
  const [shouldShowContactUsError, setShouldShowContactUsError] =
    useState(false)

  useEffect(() => {
    dispatch(setLoadingState(true))

    const fetchUserData = async () => {
      try {
        const response = await getRegistrationDetails(activationCode ?? "")
        if (response) {
          setEmail(response.email)
          setLastFourPhoneDigits(response.endOfPhoneNumber)
        }
      } catch (error) {
        const axiosError = error as AxiosError
        if (axiosError.response?.status === 400) {
          if (
            axiosError.response?.data === "Expired" ||
            axiosError.response?.data === "User not found"
          ) {
            setShouldShowContactUsError(true)
          }
        }
      } finally {
        dispatch(setLoadingState(false))
      }
    }

    if (activationCode) {
      fetchUserData()
      dispatch(setShowMenu(false))
      dispatch(setShowFooter(false))
    }
  }, [activationCode, dispatch])
  return {
    email,
    lastFourPhoneDigits,
    shouldShowContactUsError,
  }
}
