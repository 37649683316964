const FolderIconsPropertyVehicles = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.9756 17.1494C45.1686 19.3426 45.1686 22.9112 42.9756 25.1044C40.7824 27.2975 37.2137 27.2974 35.0206 25.1044C32.8276 22.9111 32.8276 19.3425 35.0206 17.1494C37.0058 15.1642 40.9696 15.1434 42.9756 17.1494ZM40.3239 19.801C41.055 20.5321 41.055 21.7216 40.3239 22.4526C39.5928 23.1839 38.4032 23.1838 37.6722 22.4526C36.9412 21.7216 36.9412 20.5321 37.6722 19.801C38.4035 19.07 39.593 19.07 40.3239 19.801Z"
    />
    <path d="M29.7172 30.4077C28.9851 29.6754 27.7979 29.6754 27.0656 30.4077L19.1106 38.3628C18.3784 39.095 18.3784 40.2821 19.1106 41.0144C19.8427 41.7466 21.03 41.7468 21.7622 41.0144L29.7172 33.0594C30.4495 32.3271 30.4495 31.14 29.7172 30.4077Z" />
    <path d="M46.875 52.75H54.625C55.6605 52.75 56.5 53.5895 56.5 54.625C56.5 55.6605 55.6605 56.5 54.625 56.5H46.875C45.8395 56.5 45 55.6605 45 54.625C45 53.5895 45.8395 52.75 46.875 52.75Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.3525 33.3259L60.7991 31.7725C60.6254 31.5987 60.4414 31.4391 60.25 31.2913V11.375C60.25 5.20913 54.9836 0 48.75 0C43.9939 0 40.0192 3.00412 38.3575 7.03088C33.3934 6.44012 28.2151 8.04513 24.414 11.846C20.3996 15.8604 18.7693 21.5362 19.8481 27.0185L0.54925 46.3176C0.197625 46.6692 0 47.1461 0 47.6434V58.25C0 59.2855 0.8395 60.125 1.875 60.125H12.5C13.5355 60.125 14.375 59.2855 14.375 58.25V54.7499L17.7496 54.75C18.2469 54.75 18.7239 54.5525 19.0755 54.2009C19.4271 53.8492 19.6248 53.3724 19.6248 52.8751L19.6249 49.5H23.0882C23.5849 49.5 24.0613 49.303 24.4127 48.9521L33.1053 40.2766C34.5556 40.5621 36.0346 40.6575 37.5 40.5656V58.375C37.5 61.4766 40.0234 64 43.125 64H58.375C61.4766 64 64 61.4766 64 58.375V37.3032C64 35.8007 63.4149 34.3882 62.3525 33.3259ZM48.75 3.75C52.9509 3.75 56.5 7.24188 56.5 11.375V30.125H51.9824C54.4461 24.0312 53.2117 16.779 48.2789 11.8461C46.4662 10.0335 44.3401 8.72137 42.0737 7.9075C43.3177 5.44212 45.8424 3.75 48.75 3.75ZM32.9931 36.4046C32.3481 36.2345 31.6617 36.4193 31.1896 36.8902L22.3126 45.75H17.75C16.7145 45.75 15.8875 46.5894 15.8875 47.6249L15.8874 51.0124L12.5001 51.0122C12.0029 51.0122 11.5259 51.1974 11.1742 51.5489C10.8226 51.9005 10.625 52.3774 10.625 52.8747V56.375H3.75V48.42L23.2334 28.9366C23.7054 28.4646 23.8909 27.7772 23.7204 27.1319C22.5047 22.5311 23.7554 17.8081 27.0658 14.4977C31.7653 9.79816 40.8981 9.76863 45.6272 14.4977C50.7446 19.6152 50.7446 27.9419 45.6272 33.0594C42.317 36.3698 37.5939 37.6202 32.9931 36.4046ZM60.25 58.375C60.25 59.4089 59.4089 60.25 58.375 60.25H43.125C42.0911 60.25 41.25 59.4089 41.25 58.375V39.901C43.8965 39.1131 46.3155 37.6743 48.2789 35.7109C48.8604 35.1294 49.3894 34.5151 49.868 33.8749H56.8217C57.3225 33.8749 57.7934 34.0699 58.1475 34.424L59.7009 35.9774C60.055 36.3315 60.25 36.8024 60.25 37.3031V58.375Z"
    />
    <path d="M62.3525 33.3259L60.7991 31.7725C60.6254 31.5987 60.4414 31.4391 60.25 31.2913V11.375C60.25 5.20913 54.9836 0 48.75 0C44.3695 0 40.6519 2.54834 38.7937 6.09692L42.5068 7.15778C43.852 5.10554 46.1476 3.75 48.75 3.75C52.9509 3.75 56.5 7.24188 56.5 11.375V30.125H51.9855L49.8618 33.8749H56.8217C57.3225 33.8749 57.7934 34.0699 58.1475 34.424L59.7009 35.9774C60.055 36.3315 60.25 36.8024 60.25 37.3031V58.375C60.25 59.4089 59.4089 60.25 58.375 60.25H43.125C42.0911 60.25 41.25 59.4089 41.25 58.375V39.8982L37.5 40.5673V58.375C37.5 61.4766 40.0234 64 43.125 64H58.375C61.4766 64 64 61.4766 64 58.375V37.3032C64 35.8007 63.4149 34.3882 62.3525 33.3259Z" />
    <path d="M54.625 52.75H46.875C45.8395 52.75 45 53.5895 45 54.625C45 55.6605 45.8395 56.5 46.875 56.5H54.625C55.6605 56.5 56.5 55.6605 56.5 54.625C56.5 53.5895 55.6605 52.75 54.625 52.75Z" />
  </svg>
)

export default FolderIconsPropertyVehicles
