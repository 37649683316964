import { SET_SEARCH_LABEL, SET_SEARCH_EXPRESSION } from "./actions"
import { IAction } from "../constants"

export interface ISearchState {
  label: string
  searchExpression: string
}

export interface ISearchAction extends IAction {
  payload: string
}

export const initialState = {
  label: "",
  searchExpression: "",
}

export const searchReducer = (
  searchState: ISearchState,
  action: ISearchAction
) => {
  switch (action.type) {
    case SET_SEARCH_LABEL:
      return {
        ...searchState,
        label: action.payload,
      }
    case SET_SEARCH_EXPRESSION:
      return {
        ...searchState,
        searchExpression: action.payload,
      }
    default:
      return searchState
  }
}
