"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReminderApi = exports.ReminderApiFactory = exports.ReminderApiFp = exports.ReminderApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * ReminderApi - axios parameter creator
 * @export
 */
const ReminderApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Deletes a Reminder for the current User
         * @summary Delete Reminder
         * @param {string} [reminderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderDelete: (reminderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (reminderId !== undefined) {
                localVarQueryParameter["reminderId"] = reminderId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get all reminders by user
         * @summary Get Reminders
         * @param {string} [applicationId] The applicationId to identify all applications that are using the reminders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderGet: (applicationId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (applicationId !== undefined) {
                localVarQueryParameter["applicationId"] = applicationId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Creates a Reminder for the current User
         * @summary Create Reminder
         * @param {ApiReminderBody} [body] Remider Object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Updates a Reminder for the current User. Pass in a UpdateReminderDto object with the ID of the reminder to be updated along with the updated information.
         * @summary Update Reminder
         * @param {ApiReminderBody} [body] DTO for reminder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderPut: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Instantly send an email to a user reminding them about a file or file action needed
         * @summary Send an email reminder for a file
         * @param {string} [fileId]
         * @param {string} [targetUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderSendPost: (fileId, targetUserId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Reminder/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (fileId !== undefined) {
                localVarQueryParameter["fileId"] = fileId;
            }
            if (targetUserId !== undefined) {
                localVarQueryParameter["targetUserId"] = targetUserId;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.ReminderApiAxiosParamCreator = ReminderApiAxiosParamCreator;
/**
 * ReminderApi - functional programming interface
 * @export
 */
const ReminderApiFp = function (configuration) {
    return {
        /**
         * Deletes a Reminder for the current User
         * @summary Delete Reminder
         * @param {string} [reminderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderDelete(reminderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ReminderApiAxiosParamCreator)(configuration).apiReminderDelete(reminderId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get all reminders by user
         * @summary Get Reminders
         * @param {string} [applicationId] The applicationId to identify all applications that are using the reminders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderGet(applicationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ReminderApiAxiosParamCreator)(configuration).apiReminderGet(applicationId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Creates a Reminder for the current User
         * @summary Create Reminder
         * @param {ApiReminderBody} [body] Remider Object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ReminderApiAxiosParamCreator)(configuration).apiReminderPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Updates a Reminder for the current User. Pass in a UpdateReminderDto object with the ID of the reminder to be updated along with the updated information.
         * @summary Update Reminder
         * @param {ApiReminderBody} [body] DTO for reminder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderPut(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ReminderApiAxiosParamCreator)(configuration).apiReminderPut(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Instantly send an email to a user reminding them about a file or file action needed
         * @summary Send an email reminder for a file
         * @param {string} [fileId]
         * @param {string} [targetUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderSendPost(fileId, targetUserId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.ReminderApiAxiosParamCreator)(configuration).apiReminderSendPost(fileId, targetUserId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.ReminderApiFp = ReminderApiFp;
/**
 * ReminderApi - factory interface
 * @export
 */
const ReminderApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Deletes a Reminder for the current User
         * @summary Delete Reminder
         * @param {string} [reminderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderDelete(reminderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ReminderApiFp)(configuration)
                    .apiReminderDelete(reminderId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Get all reminders by user
         * @summary Get Reminders
         * @param {string} [applicationId] The applicationId to identify all applications that are using the reminders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderGet(applicationId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ReminderApiFp)(configuration)
                    .apiReminderGet(applicationId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Creates a Reminder for the current User
         * @summary Create Reminder
         * @param {ApiReminderBody} [body] Remider Object to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ReminderApiFp)(configuration)
                    .apiReminderPost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Updates a Reminder for the current User. Pass in a UpdateReminderDto object with the ID of the reminder to be updated along with the updated information.
         * @summary Update Reminder
         * @param {ApiReminderBody} [body] DTO for reminder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderPut(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ReminderApiFp)(configuration)
                    .apiReminderPut(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Instantly send an email to a user reminding them about a file or file action needed
         * @summary Send an email reminder for a file
         * @param {string} [fileId]
         * @param {string} [targetUserId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReminderSendPost(fileId, targetUserId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.ReminderApiFp)(configuration)
                    .apiReminderSendPost(fileId, targetUserId, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.ReminderApiFactory = ReminderApiFactory;
/**
 * ReminderApi - object-oriented interface
 * @export
 * @class ReminderApi
 * @extends {BaseAPI}
 */
class ReminderApi extends base_1.BaseAPI {
    /**
     * Deletes a Reminder for the current User
     * @summary Delete Reminder
     * @param {string} [reminderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    apiReminderDelete(reminderId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ReminderApiFp)(this.configuration)
                .apiReminderDelete(reminderId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get all reminders by user
     * @summary Get Reminders
     * @param {string} [applicationId] The applicationId to identify all applications that are using the reminders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    apiReminderGet(applicationId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ReminderApiFp)(this.configuration)
                .apiReminderGet(applicationId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Creates a Reminder for the current User
     * @summary Create Reminder
     * @param {ApiReminderBody} [body] Remider Object to create
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    apiReminderPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ReminderApiFp)(this.configuration)
                .apiReminderPost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Updates a Reminder for the current User. Pass in a UpdateReminderDto object with the ID of the reminder to be updated along with the updated information.
     * @summary Update Reminder
     * @param {ApiReminderBody} [body] DTO for reminder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    apiReminderPut(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ReminderApiFp)(this.configuration)
                .apiReminderPut(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Instantly send an email to a user reminding them about a file or file action needed
     * @summary Send an email reminder for a file
     * @param {string} [fileId]
     * @param {string} [targetUserId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReminderApi
     */
    apiReminderSendPost(fileId, targetUserId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.ReminderApiFp)(this.configuration)
                .apiReminderSendPost(fileId, targetUserId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.ReminderApi = ReminderApi;
