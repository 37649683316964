import shares = require("../staticData/getShares.json");
import { NodeShareDto } from "legado-generated-api-client/models";
import { getUserId } from "./getUser";

type TObj = { [key: string]: any };

const isNodeShare = (shares: any) => {
  if ((shares as NodeShareDto[])?.length) {
    return shares as NodeShareDto[];
  }
};

type TGetShares = ({
  contactId,
  userEmail,
}: {
  contactId: string;
  userEmail?: string;
}) => NodeShareDto[];
export const getNodesSharedToUserFromLocalData: TGetShares = ({
  contactId,
  userEmail,
}) => {
  const currentUserId = getUserId(userEmail);
  const sharesObj = shares as TObj;
  const currentUserShares = sharesObj[currentUserId];
  const nodeShares = isNodeShare(currentUserShares) || [];
  return nodeShares.filter((s) => s.contactId === contactId);
};
