"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocusignPublicApi = exports.DocusignPublicApiFactory = exports.DocusignPublicApiFp = exports.DocusignPublicApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * DocusignPublicApi - axios parameter creator
 * @export
 */
const DocusignPublicApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get Redirection for Platform Homepage based on the source (native, web or local). This defaults to web if native and local are not provided.  The event name (signing_complete) will be used to mark the document as signed if the code param is present and valid.
         * @param {string} [source] Origin of the call (native, web or local)
         * @param {string} [vaultFileId] The internal Id of the docusign file
         * @param {string} [event] The docusign event after the docusign action from the user. \&quot;signing_complete\&quot; to mark it as signed
         * @param {string} [code] The code used to mark this document as signed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignRedirectGet: (source, vaultFileId, event, code, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Docusign/redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (source !== undefined) {
                localVarQueryParameter["source"] = source;
            }
            if (vaultFileId !== undefined) {
                localVarQueryParameter["vaultFileId"] = vaultFileId;
            }
            if (event !== undefined) {
                localVarQueryParameter["event"] = event;
            }
            if (code !== undefined) {
                localVarQueryParameter["code"] = code;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DocusignPublicApiAxiosParamCreator = DocusignPublicApiAxiosParamCreator;
/**
 * DocusignPublicApi - functional programming interface
 * @export
 */
const DocusignPublicApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get Redirection for Platform Homepage based on the source (native, web or local). This defaults to web if native and local are not provided.  The event name (signing_complete) will be used to mark the document as signed if the code param is present and valid.
         * @param {string} [source] Origin of the call (native, web or local)
         * @param {string} [vaultFileId] The internal Id of the docusign file
         * @param {string} [event] The docusign event after the docusign action from the user. \&quot;signing_complete\&quot; to mark it as signed
         * @param {string} [code] The code used to mark this document as signed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignRedirectGet(source, vaultFileId, event, code, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocusignPublicApiAxiosParamCreator)(configuration).apiDocusignRedirectGet(source, vaultFileId, event, code, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.DocusignPublicApiFp = DocusignPublicApiFp;
/**
 * DocusignPublicApi - factory interface
 * @export
 */
const DocusignPublicApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get Redirection for Platform Homepage based on the source (native, web or local). This defaults to web if native and local are not provided.  The event name (signing_complete) will be used to mark the document as signed if the code param is present and valid.
         * @param {string} [source] Origin of the call (native, web or local)
         * @param {string} [vaultFileId] The internal Id of the docusign file
         * @param {string} [event] The docusign event after the docusign action from the user. \&quot;signing_complete\&quot; to mark it as signed
         * @param {string} [code] The code used to mark this document as signed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignRedirectGet(source, vaultFileId, event, code, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocusignPublicApiFp)(configuration)
                    .apiDocusignRedirectGet(source, vaultFileId, event, code, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.DocusignPublicApiFactory = DocusignPublicApiFactory;
/**
 * DocusignPublicApi - object-oriented interface
 * @export
 * @class DocusignPublicApi
 * @extends {BaseAPI}
 */
class DocusignPublicApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get Redirection for Platform Homepage based on the source (native, web or local). This defaults to web if native and local are not provided.  The event name (signing_complete) will be used to mark the document as signed if the code param is present and valid.
     * @param {string} [source] Origin of the call (native, web or local)
     * @param {string} [vaultFileId] The internal Id of the docusign file
     * @param {string} [event] The docusign event after the docusign action from the user. \&quot;signing_complete\&quot; to mark it as signed
     * @param {string} [code] The code used to mark this document as signed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocusignPublicApi
     */
    apiDocusignRedirectGet(source, vaultFileId, event, code, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocusignPublicApiFp)(this.configuration)
                .apiDocusignRedirectGet(source, vaultFileId, event, code, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.DocusignPublicApi = DocusignPublicApi;
