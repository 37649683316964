import classNames from "classnames"
import { FC, createRef } from "react"
import {
  InputFieldType,
  validateInput,
} from "../../../utils/forms/validateInputs"
import { BrowserDefaultSelect } from "../../atoms/BrowserDefaultSelect/BrowserDefaultSelect"
import { ErrorOnSubmit } from "../../atoms/TextInput/TextInputWithValidation"
import "./PhoneNumberInputWithCountryCodeDropDown.scss"
import { countryCodes } from "./countryCodes"

export interface PhoneNumberInputWithCountryCodeDropDownProps {
  countryCodeSelected: string
  setCountryCodeSelected: (newPhoneNumber: string) => void
  phoneNumber: string
  setPhoneNumber: (newPhoneNumber: string) => void
  onValidation?: (error?: string) => void
  isRequired?: boolean
  id?: string
  error?: ErrorOnSubmit
  shouldShowErrorIcon?: boolean
}

export const PhoneNumberInputWithCountryCodeDropDown: FC<
  PhoneNumberInputWithCountryCodeDropDownProps
> = ({
  countryCodeSelected,
  setCountryCodeSelected,
  phoneNumber,
  setPhoneNumber,
  onValidation,
  isRequired = false,
  id,
  error,
  shouldShowErrorIcon = false,
}) => {
  const validate = () => {
    const error = validateInput({
      type: InputFieldType.PHONE_NUMBER,
      value: phoneNumber,
    })

    if (onValidation) {
      onValidation(error)
    }
  }

  const inputRef = createRef<HTMLInputElement>()

  // An input of type number will still accept the key e since e is a mathematical constant
  // in order to prevent the e from appearing in the phone input this needs blocked
  // prevent default will then prevent propagation of the key down event over to the on change event
  const preventDefaultOnExponentialKey = (event: React.KeyboardEvent) => {
    if (event.key === "e") {
      event.preventDefault()
    }
  }

  const phoneInputClassNames = classNames("input-box", {
    error: error?.hasError,
  })

  return (
    <BrowserDefaultSelect
      className="country-code-and-phone-number-select"
      options={Object.keys(countryCodes).map((countryCode) => ({
        name: countryCode,
        value: countryCodes[countryCode as keyof typeof countryCodes],
      }))}
      label="Phone number"
      name="country-code"
      onChange={(value) => {
        setCountryCodeSelected(value)
      }}
      isRequired={isRequired}
      error={error}
      shouldShowErrorIcon={shouldShowErrorIcon}
    >
      <fieldset
        className={phoneInputClassNames}
        role="textbox"
        onClick={() => inputRef.current?.focus()}
      >
        {countryCodeSelected}
        <input
          aria-label="Phone number with country code input"
          type="number"
          onChange={(event) => {
            setPhoneNumber(event.target.value)
            validate()
          }}
          value={phoneNumber}
          ref={inputRef}
          id={id}
          onKeyDown={preventDefaultOnExponentialKey}
        />
      </fieldset>
    </BrowserDefaultSelect>
  )
}
