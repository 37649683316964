import { MaxFileNameLength } from "../../../utils/consts/consts"

export const alertMessageErrorsText = {
  generic: "Oops, something went wrong. Please try again later.",
  genericUpload: "We were unable to upload your files. Please try again.",
  genericPartialUpload:
    "Some files were unable to be uploaded, please try again",
  fileType:
    "One or more files could not be uploaded due to unsupported file formats.",
  fileName:
    "One or more files has an invalid file name. Valid characters within the name are: letters, numbers, dashes, underscores, parentheses and blank spaces.",
  fileNameLength: `One or more files has a name that is too long. Please make sure the file name is less than ${MaxFileNameLength} characters.`,
  mimeType:
    "One or more files could not be uploaded due to unsupported file MIME types.",
  noFiles:
    "No files have been added. Before reviewing an upload at least one supported file must be added.",
}
