const Tick = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    className="fill"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9429 8.0578C28.4635 8.57851 28.4635 9.42273 27.9428 9.94342L13.9428 23.9428C13.4221 24.4635 12.578 24.4635 12.0573 23.9428L5.05725 16.9434C4.53653 16.4228 4.53649 15.5785 5.05717 15.0578C5.57784 14.5371 6.42206 14.5371 6.94279 15.0577L13 21.1144L26.0572 8.05775C26.5779 7.53707 27.4222 7.53709 27.9429 8.0578Z"
      stroke="none"
    />
  </svg>
)

export default Tick
