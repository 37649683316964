const WelcomeShare = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.5 17.5L21.8517 26M37.7849 42.2107L21.8517 33.5371"
      strokeWidth="1.5"
      strokeLinejoin="round"
      className="reverse"
    />
    <path
      d="M12 37.2099C21 37 22.7647 35 22.7647 29.6049C23 23 17.2883 22.5 13 22.5L12 37.2099Z"
      className="custom-1"
    />
    <path
      d="M37 45C37 49.2883 40.4048 52.7647 44.6049 52.7647C48.805 52.7647 52.2099 49.2883 52.2099 45H37Z"
      className="custom-1"
    />
    <path
      d="M52 14.6049C52 18.805 48.5236 22.2099 44.2353 22.2099C39.947 22.2099 36.4706 18.805 36.4706 14.6049C36.4706 10.4048 39.947 7 44.2353 7C48.5236 7 52 10.4048 52 14.6049Z"
      className="custom-1"
    />
    <path d="M51.25 14.6049C51.25 18.3762 48.1242 21.4599 44.2353 21.4599V22.9599C48.9231 22.9599 52.75 19.2339 52.75 14.6049H51.25ZM44.2353 21.4599C40.3464 21.4599 37.2206 18.3762 37.2206 14.6049H35.7206C35.7206 19.2339 39.5475 22.9599 44.2353 22.9599V21.4599ZM37.2206 14.6049C37.2206 10.8337 40.3464 7.75 44.2353 7.75V6.25C39.5475 6.25 35.7206 9.97601 35.7206 14.6049H37.2206ZM44.2353 7.75C48.1242 7.75 51.25 10.8337 51.25 14.6049H52.75C52.75 9.97601 48.9231 6.25 44.2353 6.25V7.75Z" />
    <path d="M22.7794 29.8148C22.7794 33.5861 19.6536 36.6698 15.7647 36.6698V38.1698C20.4525 38.1698 24.2794 34.4437 24.2794 29.8148H22.7794ZM15.7647 36.6698C11.8758 36.6698 8.75 33.5861 8.75 29.8148H7.25C7.25 34.4437 11.0769 38.1698 15.7647 38.1698V36.6698ZM8.75 29.8148C8.75 26.0436 11.8758 22.9599 15.7647 22.9599V21.4599C11.0769 21.4599 7.25 25.1859 7.25 29.8148H8.75ZM15.7647 22.9599C19.6536 22.9599 22.7794 26.0436 22.7794 29.8148H24.2794C24.2794 25.1859 20.4525 21.4599 15.7647 21.4599V22.9599Z" />
    <path d="M51.25 45.0247C51.25 48.7959 48.1242 51.8796 44.2353 51.8796V53.3796C48.9231 53.3796 52.75 49.6536 52.75 45.0247H51.25ZM44.2353 51.8796C40.3464 51.8796 37.2206 48.7959 37.2206 45.0247H35.7206C35.7206 49.6536 39.5475 53.3796 44.2353 53.3796V51.8796ZM37.2206 45.0247C37.2206 41.2534 40.3464 38.1698 44.2353 38.1698V36.6698C39.5475 36.6698 35.7206 40.3958 35.7206 45.0247H37.2206ZM44.2353 38.1698C48.1242 38.1698 51.25 41.2534 51.25 45.0247H52.75C52.75 40.3958 48.9231 36.6698 44.2353 36.6698V38.1698Z" />
  </svg>
)

export default WelcomeShare
