"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlatformContext = void 0;
/**
 *   0 = Browser  1 = MobileApp  -1 = All
 * @export
 * @enum {string}
 */
var PlatformContext;
(function (PlatformContext) {
    PlatformContext[PlatformContext["NUMBER_0"] = 0] = "NUMBER_0";
    PlatformContext[PlatformContext["NUMBER_1"] = 1] = "NUMBER_1";
    PlatformContext[PlatformContext["NUMBER_MINUS_1"] = -1] = "NUMBER_MINUS_1";
})(PlatformContext || (exports.PlatformContext = PlatformContext = {}));
