import { TTheme } from "../../design-tokens/types"
import { ComponentVariablesTypes } from "./bridgeTokenTypes"
import { get3TierTheme } from "./get3TierTheme"

export const getComponentVariables = (theme: TTheme): string => {
  const themeTokens = get3TierTheme(theme.mode)
  const bridgeTokensT2ToT3 = themeTokens.tier2Tokens.bridgeTokensT2ToT3
  const componentVariables: ComponentVariablesTypes = {
    // each component should have its own name button, form, search etc
    universal: {
      colors: {
        componentColorActionBackgroundDisabled:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorActionBackgroundDisabledT2Token ?? "not set",
        componentColorActionBorderDisabled:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorActionBorderDisabledT2Token ?? "not set",
        componentColorActionTextDisabled:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorActionBackgroundDisabledT2Token ?? "not set",

        componentColorInteractionBackgroundPrimaryHover:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorInteractionBackgroundPrimaryHoverT2Token ??
          "not set",
        componentColorInteractionBackgroundSecondaryHover:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorInteractionBackgroundSecondaryHoverT2Token ??
          "not set",
        componentColorInteractionBackgroundRemoveHover:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorInteractionBackgroundRemoveHoverT2Token ??
          "not set",
        componentColorInteractionBorderHover:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorInteractionBorderHoverT2Token ?? "not set",

        // feedback color
        componentColorFeedbackSuccess:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackSuccessT2Token ?? "not set",
        componentColorFeedbackSuccessHover:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackSuccessHoverT2Token ?? "not set",
        componentColorFeedbackSuccessShade:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackSuccessShadeT2Token ?? "not set",
        componentColorFeedbackWarning:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackWarningT2Token ?? "not set",
        componentColorFeedbackWarningHover:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackWarningHoverT2Token ?? "not set",
        componentColorFeedbackWarningShade:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackWarningShadeT2Token ?? "not set",
        componentColorFeedbackDanger:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackDangerT2Token ?? "not set",
        componentColorFeedbackDangerHover:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackDangerHoverT2Token ?? "not set",
        componentColorFeedbackDangerShade:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackDangerShadeT2Token ?? "not set",
        componentColorFeedbackMessage:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackMessageT2Token ?? "not set",
        componentColorFeedbackMessageHover:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackMessageHoverT2Token ?? "not set",
        componentColorFeedbackMessageShade:
          bridgeTokensT2ToT3.universal?.colors
            ?.componentColorFeedbackMessageShadeT2Token ?? "not set",

        colorUniversalNaturalBlack:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalNaturalBlackT2Token ?? "not set",
        colorUniversalNaturalWhite:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalNaturalWhiteT2Token ?? "not set",
        colorUniversalNaturalGrey:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalNaturalGreyT2Token ?? "not set",
        colorUniversalSecondaryA:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalSecondaryAT2Token ?? "not set",
        colorUniversalSecondaryB:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalSecondaryBT2Token ?? "not set",
        colorUniversalSecondaryC:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalSecondaryCT2Token ?? "not set",
        colorUniversalSecondaryD:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalSecondaryDT2Token ?? "not set",
        colorUniversalSecondaryE:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalSecondaryET2Token ?? "not set",
        colorUniversalSecondaryF:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalSecondaryFT2Token ?? "not set",
        colorUniversalSecondaryG:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalSecondaryGT2Token ?? "not set",
        colorUniversalSecondaryH:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalSecondaryHT2Token ?? "not set",

        colorUniversalToneA:
          bridgeTokensT2ToT3.universal?.colors?.colorUniversalToneAT2Token ??
          "not set",
        colorUniversalToneA25:
          bridgeTokensT2ToT3.universal?.colors?.colorUniversalToneA25T2Token ??
          "not set",
        colorUniversalPrimaryBlack:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalPrimaryBlackT2Token ?? "not set",
        colorUniversalPrimaryGrey:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalPrimaryGreyT2Token ?? "not set",
        colorUniversalPrimaryBackground:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalPrimaryBackgroundT2Token ?? "not set",
        colorUniversalPrimaryBase:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorUniversalPrimaryBaseT2Token ?? "not set",
        colorDocumentHubComponentBackground:
          bridgeTokensT2ToT3.universal?.colors
            ?.colorDocumentHubComponentBackgroundT2Token,
        colorInputOutlineFocus:
          bridgeTokensT2ToT3.universal?.colors?.colorInputOutlineFocusT2Token ??
          "not set",
      },
    },
    accordion: {
      colors: {
        background: {
          accordionColorContextBackgroundSectionActive:
            bridgeTokensT2ToT3.accordion?.colors?.background
              ?.accordionColorContextBackgroundSectionActiveT2Token ??
            "not set",
        },
      },
    },
    alertMessage: {
      colors: {
        // background colors
        alertMessageColorFeedbackBackgroundMessage:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBackgroundMessageT2Token ?? "not set",
        alertMessageColorFeedbackBackgroundInfo:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBackgroundInfoT2Token ?? "not set",
        alertMessageColorFeedbackBackgroundSuccess:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBackgroundSuccessT2Token ?? "not set",
        alertMessageColorFeedbackBackgroundWarning:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBackgroundWarningT2Token ?? "not set",
        alertMessageColorFeedbackBackgroundError:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBackgroundErrorT2Token ?? "not set",
        // border colors
        alertMessageColorFeedbackBorderInfo:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBorderInfoT2Token ?? "not set",
        alertMessageColorFeedbackBorderSuccess:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBorderSuccessT2Token ?? "not set",
        alertMessageColorFeedbackBorderWarning:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBorderWarningT2Token ?? "not set",
        alertMessageColorFeedbackBorderError:
          bridgeTokensT2ToT3.alertMessage?.colors
            ?.alertMessageColorFeedbackBorderErrorT2Token ?? "not set",
      },
    },
    button: {
      colors: {
        background: {
          buttonColorActionBackgroundPrimary:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundPrimaryT2Token ?? "not set",
          buttonColorActionBackgroundPrimaryHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundPrimaryHoverT2Token ?? "not set",
          buttonColorActionBackgroundSecondary:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundSecondaryT2Token ?? "not set",
          buttonColorActionBackgroundSecondaryHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundSecondaryHoverT2Token ?? "not set",
          buttonColorActionBackgroundTertiary:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundTertiaryT2Token ?? "not set",
          buttonColorActionBackgroundTertiaryHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundTertiaryHoverT2Token ?? "not set",
          buttonColorActionBackgroundDanger:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundDangerT2Token ?? "not set",
          buttonColorActionBackgroundDangerHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundDangerHoverT2Token ?? "not set",
          buttonColorActionBackgroundRemove:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundRemoveT2Token ?? "not set",
          buttonColorActionBackgroundRemoveHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundRemoveHoverT2Token ?? "not set",
          buttonColorActionBackgroundDisabled:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionBackgroundDisabledT2Token ?? "not set",
        },
        text: {
          buttonColorActionTextPrimary:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextPrimaryT2Token ?? "not set",
          buttonColorActionTextPrimaryHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextPrimaryHoverT2Token ?? "not set",
          buttonColorActionTextSecondary:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextSecondaryT2Token ?? "not set",
          buttonColorActionTextSecondaryHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextSecondaryHoverT2Token ?? "not set",
          buttonColorActionTextTertiary:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextTertiaryT2Token ?? "not set",
          buttonColorActionTextTertiaryHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextTertiaryHoverT2Token ?? "not set",
          buttonColorActionTextDanger:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextDangerT2Token ?? "not set",
          buttonColorActionTextDangerHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextDangerHoverT2Token ?? "not set",
          buttonColorActionTextRemove:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextRemoveT2Token ?? "not set",
          buttonColorActionTextRemoveHover:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextRemoveHoverT2Token ?? "not set",
          buttonColorActionTextDisabled:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextDisabledT2Token ?? "not set",
          buttonColorActionTextLink:
            bridgeTokensT2ToT3.button?.colors
              ?.buttonColorActionTextLinkT2Token ?? "not set",
        },
      },
      radius: {
        buttonActionBorderRadius:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderRadiusButtonXT2Token ?? "not set",
      },
      borders: {
        buttonActionBorderPrimary:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderPrimaryT2Token ?? "not set",
        buttonActionBorderPrimaryHover:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderPrimaryHoverT2Token ?? "not set",
        buttonActionBorderSecondary:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderSecondaryT2Token ?? "not set",
        buttonActionBorderSecondaryHover:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderSecondaryHoverT2Token ?? "not set",
        buttonActionBorderTertiary:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderTertiaryT2Token ?? "not set",
        buttonActionBorderTertiaryHover:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderTertiaryHoverT2Token ?? "not set",
        buttonActionBorderDanger:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderDangerT2Token ?? "not set",
        buttonActionBorderDangerHover:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderDangerHoverT2Token ?? "not set",
        buttonActionBorderRemove:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderRemoveT2Token ?? "not set",
        buttonActionBorderRemoveHover:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionBorderRemoveHoverT2Token ?? "not set",
      },
      shadows: {
        buttonActionShadowPrimary:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionShadowPrimaryT2Token ?? "not set",
        buttonActionShadowPrimaryHover:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionShadowPrimaryHoverT2Token ?? "not set",
        buttonActionShadowSecondary:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionShadowSecondaryT2Token ?? "not set",
        buttonActionShadowSecondaryHover:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionShadowSecondaryHoverT2Token ?? "not set",
        buttonActionShadowTertiary:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionShadowTertiaryT2Token ?? "not set",
        buttonActionShadowTertiaryHover:
          bridgeTokensT2ToT3.button?.boxStyles
            ?.buttonActionShadowTertiaryHoverT2Token ?? "not set",
      },
    },
    breadcrumb: {
      colors: {
        text: {
          breadcrumbColorInteractionTextPrimary:
            bridgeTokensT2ToT3.breadcrumb?.colors
              ?.breadcrumbColorInteractionTextPrimaryT2Token ?? "not set",
          breadcrumbColorInteractionTextPrimaryHover:
            bridgeTokensT2ToT3.breadcrumb?.colors
              ?.breadcrumbColorInteractionTextPrimaryHoverT2Token ?? "not set",
        },
      },
    },
    card: {
      colors: {
        background: {
          cardColorContextBackgroundPrimary:
            bridgeTokensT2ToT3.universal?.colors
              ?.cardColorContextBackgroundPrimaryT2Token ?? "no set",
        },
        border: {
          cardColorContextBorderLighter:
            bridgeTokensT2ToT3.card?.colors?.border
              ?.cardColorContextBorderLighterT2Token ?? "not set",
          cardColorContextBorderDarker:
            bridgeTokensT2ToT3.card?.colors?.border
              ?.cardColorContextBorderDarkerT2Token,
        },
      },
    },
    contactCard: {
      fontSizes: {
        contactCardLinkFontSize:
          bridgeTokensT2ToT3.contactCard?.fontSizes
            ?.contactCardLinkFontSizeT2Token ?? "not set",
        contactCardNameFontSize:
          bridgeTokensT2ToT3.contactCard?.fontSizes
            ?.contactCardNameFontSizeT2Token ?? "not set",
      },
      lineHeights: {
        contactCardNameLineHeight:
          bridgeTokensT2ToT3.contactCard?.lineHeights
            ?.contactCardNameLineHeightT2Token ?? "not set",
        contactCardLinkLineHeight:
          bridgeTokensT2ToT3.contactCard?.lineHeights
            ?.contactCardLinkLineHeightT2Token ?? "not set",
      },
    },
    divider: {
      colors: {
        background: {
          dividerColorContextBackgroundPrimary:
            bridgeTokensT2ToT3.divider?.colors?.background
              ?.dividerColorContextBackgroundPrimaryT2Token ?? "not set",
          dividerColorContextBackgroundLighter:
            bridgeTokensT2ToT3.divider?.colors?.background
              ?.dividerColorContextBackgroundLighterT2Token ?? "not set",
        },
      },
    },
    documentTaggingPage: {
      colors: {
        headerBackgroundColor:
          bridgeTokensT2ToT3.documentTaggingPage?.colors
            ?.headerBackgroundColor ?? "not set",
        borderColor:
          bridgeTokensT2ToT3.documentTaggingPage?.colors?.borderColor ??
          "not set",
      },
      boxShadows: {
        header:
          bridgeTokensT2ToT3.documentTaggingPage?.boxShadows?.header ??
          "not set",
        document:
          bridgeTokensT2ToT3.documentTaggingPage?.boxShadows?.document ??
          "not set",
      },
    },
    stageDetails: {
      colors: {
        subHeaderBackgroundColor:
          bridgeTokensT2ToT3.stageDetails?.colors?.subHeaderBackgroundColor ??
          "not set",
        firstSectionBackgroundColor:
          bridgeTokensT2ToT3.stageDetails?.colors
            ?.firstSectionBackgroundColor ?? "not set",
        uploadButtonColor:
          bridgeTokensT2ToT3.stageDetails?.colors?.uploadFileColor ?? "not set",
        uploadButtonStroke:
          bridgeTokensT2ToT3.stageDetails?.colors?.uploadFileStroke ??
          "not set",
      },
    },
    dragAndDrop: {
      colors: {
        background: {
          dragAndDropInteractionColorBackground:
            bridgeTokensT2ToT3.dragAndDrop?.colors?.background
              ?.dragAndDropInteractionColorBackgroundT2Token ?? "not set",
        },
      },
      radius: {
        dragAndDropInteractionBorderRadius:
          bridgeTokensT2ToT3.dragAndDrop?.boxStyles
            ?.dragAndDropInteractionBorderRadiusT2Token ?? "not set",
      },
    },
    footer: {
      colors: {
        background: {
          footerColorContextBackgroundPrimary:
            bridgeTokensT2ToT3.footer
              ?.footerColorContextBackgroundPrimaryT2Token ?? "not set",
        },
      },
    },
    form: {
      colors: {
        background: {
          formColorInteractionBackgroundPrimary:
            bridgeTokensT2ToT3.form?.colors
              ?.formColorInteractionBackgroundPrimaryT2Token ?? "not set",
          formColorInteractionBackgroundPrimaryHover:
            bridgeTokensT2ToT3.form?.colors
              ?.formColorInteractionBackgroundPrimaryHoverT2Token ?? "not set",
        },
        text: {
          formLabelColorInteractionTextPrimary:
            bridgeTokensT2ToT3.form?.colors
              ?.formLabelColorInteractionTextPrimaryT2Token ?? "not set",
          formColorInteractionTextPrimary:
            bridgeTokensT2ToT3.form?.colors
              ?.formColorInteractionTextPrimaryT2Token ?? "not set",
          formColorInteractionTextPrimaryFocus:
            bridgeTokensT2ToT3.form?.colors
              ?.formColorInteractionTextPrimaryFocusT2Token ?? "not set",
        },
        border: {
          formColorInteractionBorderPrimary:
            bridgeTokensT2ToT3.form?.colors
              ?.formColorInteractionBorderPrimaryT2Token ?? "not set",
          formColorInteractionBorderPrimaryHover:
            bridgeTokensT2ToT3.form?.colors
              ?.formColorInteractionBorderPrimaryHoverT2Token ?? "not set",
          formColorInteractionBorderPrimaryFocus:
            bridgeTokensT2ToT3.form?.colors
              ?.formColorInteractionBorderPrimaryFocusT2Token ?? "not set",
        },
      },
      border: {
        radius: {
          formBorderInteractionBorderRadiusPrimary:
            bridgeTokensT2ToT3.form?.boxStyles
              ?.formColorInteractionBorderPrimaryFocusT2Token,
        },
      },
    },
    link: {
      colors: {
        text: {
          linkColorActionTextPrimary:
            bridgeTokensT2ToT3.link?.colors
              ?.linkColorActionTextPrimaryT2Token ?? "not set",
          linkColorActionTextPrimaryHover:
            bridgeTokensT2ToT3.link?.colors
              ?.linkColorActionTextPrimaryHoverT2Token ?? "not set",
          linkColorActionTextLine:
            bridgeTokensT2ToT3.link?.colors?.linkColorActionTextLineT2Token ??
            "not set",
        },
      },
    },
    literatureAndGuides: {
      colors: {
        background: {
          literatureAndGuidesColorBackgroundPrimary:
            bridgeTokensT2ToT3.literatureAndGuides?.colors
              ?.literatureAndGuidesColorBackgroundPrimaryT2Token,
        },
      },
    },
    lozenge: {
      colors: {
        background: {
          lozengeColorFeedbackBackgroundPrimary:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBackgroundPrimaryT2Token ?? "not set",
          lozengeColorFeedbackBackgroundEmphasized:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBackgroundEmphasizedT2Token ?? "not set",
          lozengeColorFeedbackBackgroundInfo:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBackgroundInfoT2Token ?? "not set",
          lozengeColorFeedbackBackgroundPending:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBackgroundPendingT2Token ?? "not set",
          lozengeColorFeedbackBackgroundPositive:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBackgroundPositiveT2Token ?? "not set",
          lozengeColorFeedbackBackgroundMultiple:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBackgroundMultipleT2Token ?? "not set",
          lozengeColorFeedbackBackgroundNegative:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBackgroundNegativeT2Token ?? "not set",
          lozengeColorFeedbackBackgroundRevoke:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBackgroundRevokeT2Token ?? "not set",
        },
        text: {
          lozengeColorFeedbackTextPrimary:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackTextPrimaryT2Token ?? "not set",
          lozengeColorFeedbackTexPending:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderPendingT2Token ?? "not set",
          lozengeColorFeedbackTexEmphasized:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackTexEmphasizedT2Token ?? "not set",
          lozengeColorFeedbackTexInfo:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackTexInfoT2Token ?? "not set",
          lozengeColorFeedbackTexPositive:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackTexPositiveT2Token ?? "not set",
          lozengeColorFeedbackTexMultiple:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackTexMultipleT2Token ?? "not set",
          lozengeColorFeedbackTextNegative:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackTextNegativeT2Token ?? "not set",
          lozengeColorFeedbackTexRevoke:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackTexRevokeT2Token ?? "not set",
        },
        border: {
          lozengeColorFeedbackBorderPrimary:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderPrimaryT2Token ?? "not set",
          lozengeColorFeedbackBorderEmphasized:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderEmphasizedT2Token ?? "not set",
          lozengeColorFeedbackBorderInfo:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderInfoT2Token ?? "not set",
          lozengeColorFeedbackBorderPending:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderPendingT2Token ?? "not set",
          lozengeColorFeedbackBorderPositive:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderPositiveT2Token ?? "not set",
          lozengeColorFeedbackBorderNegative:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderNegativeT2Token ?? "not set",
          lozengeColorFeedbackBorderMultiple:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderMultipleT2Token ?? "not set",
          lozengeColorFeedbackBorderRevoke:
            bridgeTokensT2ToT3.lozenge?.colors
              ?.lozengeColorFeedbackBorderRevokeT2Token ?? "not set",
        },
      },
    },
    modal: {
      colors: {
        background: {
          modalColorContextBackgroundPrimary:
            bridgeTokensT2ToT3.modal?.colors
              ?.modalColorContextBackgroundPrimaryT2Token ?? "not set",
        },
        scrollbar: {
          modalColorInteractionScrollbarForeground:
            bridgeTokensT2ToT3.modal?.colors
              ?.modalColorInteractionScrollbarForegroundT2Token ?? "not set",
          modalColorInteractionScrollbarBackground:
            bridgeTokensT2ToT3.modal?.colors
              ?.modalColorInteractionScrollbarBackgroundT2Token ?? "not set",
        },
      },
      spaces: {
        footerButtons: {
          justifyContent:
            bridgeTokensT2ToT3.modal?.footer
              ?.modalFooterButtonsJustifyContentT2Token ?? "space-between",
          flexDirection: {
            mobile:
              bridgeTokensT2ToT3.modal?.footer
                ?.modalFooterButtonsFlexDirectionMobileT2Token ?? "row",
            tablet:
              bridgeTokensT2ToT3.modal?.footer
                ?.modalFooterButtonsFlexDirectionTabletT2Token ?? "row",
            desktop:
              bridgeTokensT2ToT3.modal?.footer
                ?.modalFooterButtonsFlexDirectionDesktopT2Token ?? "row",
          },
        },
      },
    },
    navbar: {
      colors: {
        background: {
          navbarColorInteractionBackgroundPrimary:
            bridgeTokensT2ToT3.navbar?.colors
              ?.navbarColorInteractionBackgroundPrimaryT2Token ?? "not set",
          navbarColorInteractionBackgroundExpand:
            bridgeTokensT2ToT3.navbar?.colors
              ?.navbarColorInteractionBackgroundExpandT2Token ?? "not set",
          navbarColorInteractionBackgroundActive:
            bridgeTokensT2ToT3.navbar?.colors
              ?.navbarColorInteractionBackgroundActiveT2Token ?? "not set",
        },
        text: {
          searchColorInteractionTextPrimary:
            bridgeTokensT2ToT3.navbar?.colors
              ?.navbarColorInteractionTextPrimaryT2Token ?? "not set",
        },
        border: {
          navbarColorInteractionBorder:
            bridgeTokensT2ToT3.navbar?.colors
              ?.navbarColorInteractionBorderT2Token,
        },
      },
      boxStyles: {
        shadow: {
          navbarBoxShadowPrimary:
            bridgeTokensT2ToT3.navbar?.boxStyles?.navbarBoxShadowPrimaryT2Token,
        },
      },
    },
    notifications: {
      colors: {
        background: {
          newNotification:
            bridgeTokensT2ToT3.notifications?.colors
              ?.newNotificationColorBackgroundT2Token ?? "not set",
        },
      },
    },
    page: {
      colors: {
        background: {
          pageColorContextBackgroundPrimary:
            bridgeTokensT2ToT3.page?.colors
              ?.pageColorContextBackgroundPrimaryT2Token ?? "not set",
          pageColorContextBackgroundSecondary:
            bridgeTokensT2ToT3.page?.colors
              ?.pageColorContextBackgroundSecondaryT2Token ?? "not set",
        },
      },
    },
    pagination: {
      colors: {
        paginationColorInteractionChevron:
          bridgeTokensT2ToT3.pagination?.colors
            ?.paginationColorInteractionChevronT2Token ?? "not set",
        background: {
          paginationColorInteractionBackgroundPrimary:
            bridgeTokensT2ToT3.pagination?.colors
              ?.paginationColorInteractionBackgroundPrimaryT2Token ?? "not set",
          paginationColorInteractionBackgroundPrimaryFocus:
            bridgeTokensT2ToT3.pagination?.colors
              ?.paginationColorInteractionBackgroundPrimaryFocusT2Token ??
            "not set",
        },
        border: {
          paginationColorInteractionBorderPrimary:
            bridgeTokensT2ToT3.pagination?.colors
              ?.paginationColorInteractionBorderPrimaryT2Token ?? "not set",
        },
        text: {
          paginationColorInteractionTextPrimary:
            bridgeTokensT2ToT3.pagination?.colors
              ?.paginationColorInteractionTextPrimaryT2Token ?? "not set",
          paginationColorInteractionTextPrimaryFocus:
            bridgeTokensT2ToT3.pagination?.colors
              ?.paginationColorInteractionTextPrimaryFocusT2Token ?? "not set",
        },
      },
    },
    panel: {
      colors: {
        background: {
          panelColorBackgroundPrimary:
            bridgeTokensT2ToT3.panel?.colors
              ?.panelColorBackgroundPrimaryT2Token,
        },
      },
      radius: {
        panelBorderRadius:
          bridgeTokensT2ToT3.panel?.boxStyles
            ?.boxStyleActionBorderRadiusSmallT2Token,
      },
      shadows: {
        panelShadowPrimary:
          bridgeTokensT2ToT3.panel?.boxStyles?.actionShadowPrimaryT2Token,
      },
    },
    profileButton: {
      colors: {
        text: {
          profileButtonColorText:
            bridgeTokensT2ToT3.profileButton?.colors
              ?.profileButtonColorTextT2Token ?? "not set",
        },
        background: {
          profileButtonColorBackground:
            bridgeTokensT2ToT3.profileButton?.colors
              ?.profileButtonColorBackgroundT2Token ?? "not set",
        },
      },
      outlines: {
        outline: {
          profileButtonActionOutline:
            bridgeTokensT2ToT3.profileButton?.outlines
              ?.profileButtonOutlineT2Token ?? "not set",
        },
        outlineOffset: {
          profileButtonActionOutlineOffset:
            bridgeTokensT2ToT3.profileButton?.outlines
              ?.profileButtonOutlineOffsetT2Token ?? "not set",
        },
      },
    },
    results: {
      colors: {
        resultsColorInteractionChevron:
          bridgeTokensT2ToT3.results?.colors
            ?.resultsColorInteractionChevronT2Token ?? "not set",
        background: {
          resultsColorInteractionBackgroundPrimary:
            bridgeTokensT2ToT3.results?.colors
              ?.resultsColorInteractionBackgroundPrimaryT2Token ?? "not set",
          resultsColorInteractionBackgroundChevron:
            bridgeTokensT2ToT3.results?.colors
              ?.resultsColorInteractionBackgroundChevronT2Token ?? "not set",
        },
        border: {
          resultsColorInteractionBorderPrimary:
            bridgeTokensT2ToT3.results?.colors
              ?.resultsColorInteractionBorderPrimaryT2Token ?? "not set",
        },
      },
    },
    search: {
      colors: {
        background: {
          searchColorInteractionBackgroundPrimary:
            bridgeTokensT2ToT3.search?.colors
              ?.searchColorInteractionBackgroundPrimaryT2Token ?? "not set",
          searchColorInteractionBackgroundPrimaryHover:
            bridgeTokensT2ToT3.search?.colors
              ?.searchColorInteractionBackgroundPrimaryHoverT2Token ??
            "not set",
        },
        text: {
          searchColorInteractionTextPrimary:
            bridgeTokensT2ToT3.search?.colors
              ?.searchColorInteractionTextPrimaryT2Token ?? "not set",
          searchColorInteractionTextPrimaryFocus:
            bridgeTokensT2ToT3.search?.colors
              ?.searchColorInteractionTextPrimaryFocusT2Token ?? "not set",
        },
        border: {
          searchColorInteractionBorderPrimary:
            bridgeTokensT2ToT3.search?.colors
              ?.searchColorInteractionBorderPrimaryT2Token ?? "not set",
          // coop doesn't add border interactions for hover
          searchColorInteractionBorderPrimaryHover: "not set",

          searchColorInteractionBorderPrimaryFocus:
            bridgeTokensT2ToT3.search?.colors
              ?.searchColorInteractionBorderPrimaryFocusT2Token ?? "not set",
        },
      },
      boxStyles: {
        searchRadius: bridgeTokensT2ToT3.search?.boxStyles?.searchRadiusT2Token,
      },
    },
    select: {
      colors: {
        text: {
          selectColorInteractionTextTertiary:
            bridgeTokensT2ToT3.select?.colors
              ?.selectColorInteractionTextTertiaryT2Token ?? "not set",
        },
      },
    },
    tabBar: {
      colors: {
        tabBarColorInteractionTabLineFocus:
          bridgeTokensT2ToT3.tabBar?.colors
            ?.tabBarColorInteractionTabLineFocusT2Token ?? "not set",
        tabBarColorInteractionTabLineInactive:
          bridgeTokensT2ToT3.tabBar?.colors
            ?.tabBarColorInteractionTabLineInactiveT2Token ?? "not set",
        text: {
          tabBarColorInteractionTextFocus:
            bridgeTokensT2ToT3.tabBar?.colors
              ?.tabBarColorInteractionTextFocusT2Token ?? "not set",
          tabBarColorInteractionTextInactive:
            bridgeTokensT2ToT3.tabBar?.colors
              ?.tabBarColorInteractionTextInactiveT2Token ?? "not set",
        },
      },
    },
    tabbedFolder: {
      radius: {
        tabbedFolderBorderRadius:
          bridgeTokensT2ToT3.tabbedFolder?.boxStyles
            ?.boxStyleActionBorderRadiusSmallT2Token,
      },
      shadows: {
        tabbedFolderShadowPrimary:
          bridgeTokensT2ToT3.tabbedFolder?.boxStyles
            ?.actionShadowPrimaryT2Token,
      },
    },
    table: {
      colors: {
        background: {
          tableColorContextBackgroundLabel:
            bridgeTokensT2ToT3.table?.colors
              ?.tableColorContextBackgroundLabelT2Token ?? "not set",
        },
      },
    },
    tag: {
      colors: {
        background: {
          tagColorInteractionBackgroundPrimary:
            bridgeTokensT2ToT3.tag?.colors
              ?.tagColorInteractionBackgroundPrimaryT2Token ?? "not set",
          tagColorInteractionBackgroundPrimaryHover:
            bridgeTokensT2ToT3.tag?.colors
              ?.tagColorInteractionBackgroundPrimaryHoverT2Token ?? "not set",
          tagColorInteractionBackgroundPrimaryFocus:
            bridgeTokensT2ToT3.tag?.colors
              ?.tagColorInteractionBackgroundPrimaryFocusT2Token ?? "not set",
        },
        text: {
          tagColorInteractionText:
            bridgeTokensT2ToT3.tag?.colors?.tagColorInteractionTextT2Token ??
            "not set",
          tagColorInteractionTextHover:
            bridgeTokensT2ToT3.tag?.colors
              ?.tagColorInteractionTextHoverT2Token ?? "not set",
          tagColorInteractionTextFocus:
            bridgeTokensT2ToT3.tag?.colors
              ?.tagColorInteractionTextFocusT2Token ?? "not set",
        },
        border: {
          tagColorInteractionBorderPrimary:
            bridgeTokensT2ToT3.tag?.colors
              ?.tagColorInteractionBorderPrimaryT2Token ?? "not set",
          tagColorInteractionBorderPrimaryFocus:
            bridgeTokensT2ToT3.tag?.colors
              ?.tagColorInteractionBorderPrimaryFocusT2Token ?? "not set",
        },
      },
      radius: {
        borderRadius:
          bridgeTokensT2ToT3.tag?.boxStyles?.borderRadiusT2Token ?? "not set",
      },
    },
    toast: {
      colors: {
        border: {
          toastColorFeedbackBorderInfo:
            bridgeTokensT2ToT3.toast?.colors
              ?.toastColorFeedbackBorderInfoT2Token ?? "not set",
          toastColorFeedbackBorderSuccess:
            bridgeTokensT2ToT3.toast?.colors
              ?.toastColorFeedbackBorderSuccessT2Token ?? "not set",
        },
      },
    },
    toggle: {
      toggleColorInteractionInteractive: bridgeTokensT2ToT3,
    },
    topBar: {
      colors: {
        background: {
          topBarColorInteractionBackgroundPrimary:
            bridgeTokensT2ToT3.topBar?.colors
              ?.topBarColorInteractionBackgroundPrimaryT2Token ?? "not set",
        },
      },
    },
    thisIsATestVariable: {
      colors: {
        background: {
          accordionColorContextBackgroundSectionActive:
            bridgeTokensT2ToT3.thisIsATestVariable?.colors
              ?.thisIsATestVariableToken ?? "not set",
        },
      },
    },
  }

  return `
  --test-variable: ${componentVariables.thisIsATestVariable?.colors?.background.accordionColorContextBackgroundSectionActive};
  --accordion-context-color-background-section-active: ${componentVariables.accordion?.colors?.background.accordionColorContextBackgroundSectionActive};

  --alert-message-color-feedback-background-message: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBackgroundMessage};
  --alert-message-color-feedback-background-info: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBackgroundInfo};
  --alert-message-color-feedback-background-success: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBackgroundSuccess};
  --alert-message-color-feedback-background-warning: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBackgroundWarning};
  --alert-message-color-feedback-background-error: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBackgroundError};
  --alert-message-color-feedback-border-success: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBorderSuccess};
  --alert-message-color-feedback-border-info: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBorderInfo};
  --alert-message-color-feedback-border-warning: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBorderWarning};
  --alert-message-color-feedback-border-error: ${componentVariables.alertMessage?.colors?.alertMessageColorFeedbackBorderError};

  --breadcrumb-color-interaction-text-primary: ${componentVariables.breadcrumb?.colors?.text.breadcrumbColorInteractionTextPrimary};
  --breadcrumb-color-interaction-text-primary-hover: ${componentVariables.breadcrumb?.colors?.text.breadcrumbColorInteractionTextPrimaryHover};

  --button-action-border-radius: ${componentVariables.button?.radius?.buttonActionBorderRadius};
  --button-action-border-primary: ${componentVariables.button?.borders?.buttonActionBorderPrimary};
  --button-action-border-primary-hover: ${componentVariables.button?.borders?.buttonActionBorderPrimaryHover};
  --button-action-border-secondary: ${componentVariables.button?.borders?.buttonActionBorderSecondary};
  --button-action-border-secondary-hover: ${componentVariables.button?.borders?.buttonActionBorderSecondaryHover};
  --button-action-border-tertiary: ${componentVariables.button?.borders?.buttonActionBorderTertiary};
  --button-action-border-tertiary-hover: ${componentVariables.button?.borders?.buttonActionBorderTertiaryHover};
  --button-action-border-danger: ${componentVariables.button?.borders?.buttonActionBorderDanger};
  --button-action-border-danger-hover: ${componentVariables.button?.borders?.buttonActionBorderDangerHover};
  --button-action-border-remove: ${componentVariables.button?.borders?.buttonActionBorderRemove};
  --button-action-border-remove-hover: ${componentVariables.button?.borders?.buttonActionBorderRemoveHover};
  --button-action-shadow-primary: ${componentVariables.button?.shadows?.buttonActionShadowPrimary};
  --button-action-shadow-primary-hover: ${componentVariables.button?.shadows?.buttonActionShadowPrimaryHover};
  --button-action-shadow-secondary: ${componentVariables.button?.shadows?.buttonActionShadowSecondary};
  --button-action-shadow-secondary-hover: ${componentVariables.button?.shadows?.buttonActionShadowSecondaryHover};
  --button-action-shadow-tertiary: ${componentVariables.button?.shadows?.buttonActionShadowTertiary};
  --button-action-shadow-tertiary-hover: ${componentVariables.button?.shadows?.buttonActionShadowTertiaryHover};
  --button-color-action-background-primary: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundPrimary};
  --button-color-action-background-primary-hover: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundPrimaryHover};
  --button-color-action-background-secondary: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundSecondary};
  --button-color-action-background-secondary-hover: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundSecondaryHover};
  --button-color-action-background-tertiary: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundTertiary};
  --button-color-action-background-tertiary-hover: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundTertiaryHover};
  --button-color-action-background-danger: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundDanger};
  --button-color-action-background-danger-hover: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundDangerHover};
  --button-color-action-background-remove: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundRemove};
  --button-color-action-background-remove-hover: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundRemoveHover};
  --button-color-action-background-disabled: ${componentVariables.button?.colors?.background.buttonColorActionBackgroundDisabled};
  --button-color-action-text-link: ${componentVariables.button?.colors?.text.buttonColorActionTextLink};
  --button-color-action-text-primary: ${componentVariables.button?.colors?.text.buttonColorActionTextPrimary};
  --button-color-action-text-primary-hover: ${componentVariables.button?.colors?.text.buttonColorActionTextPrimaryHover};
  --button-color-action-text-secondary: ${componentVariables.button?.colors?.text.buttonColorActionTextSecondary};
  --button-color-action-text-secondary-hover: ${componentVariables.button?.colors?.text.buttonColorActionTextSecondaryHover};
  --button-color-action-text-tertiary: ${componentVariables.button?.colors?.text.buttonColorActionTextTertiary};
  --button-color-action-text-tertiary-hover: ${componentVariables.button?.colors?.text.buttonColorActionTextTertiaryHover};
  --button-color-action-text-danger: ${componentVariables.button?.colors?.text.buttonColorActionTextDanger};
  --button-color-action-text-danger-hover: ${componentVariables.button?.colors?.text.buttonColorActionTextDangerHover};
  --button-color-action-text-remove: ${componentVariables.button?.colors?.text.buttonColorActionTextRemove};
  --button-color-action-text-remove-hover: ${componentVariables.button?.colors?.text.buttonColorActionTextRemoveHover};
  --button-color-action-text-disabled: ${componentVariables.button?.colors?.text.buttonColorActionTextDisabled};

  --card-color-context-border-lighter: ${componentVariables.card?.colors?.border.cardColorContextBorderLighter};
  --card-color-context-border-darker: ${componentVariables.card?.colors?.border.cardColorContextBorderDarker};
  --card-color-context-background-primary: ${componentVariables.card?.colors?.background.cardColorContextBackgroundPrimary};

  --color-universal-natural-black: ${componentVariables.universal?.colors?.colorUniversalNaturalBlack};
  --color-universal-natural-white: ${componentVariables.universal?.colors?.colorUniversalNaturalWhite};
  --color-universal-natural-grey: ${componentVariables.universal?.colors?.colorUniversalNaturalGrey};
  --color-universal-secondary-a: ${componentVariables.universal?.colors?.colorUniversalSecondaryA};
  --color-universal-secondary-b: ${componentVariables.universal?.colors?.colorUniversalSecondaryB};
  --color-universal-secondary-c: ${componentVariables.universal?.colors?.colorUniversalSecondaryC};
  --color-universal-secondary-d: ${componentVariables.universal?.colors?.colorUniversalSecondaryD};
  --color-universal-secondary-e: ${componentVariables.universal?.colors?.colorUniversalSecondaryE};
  --color-universal-secondary-f: ${componentVariables.universal?.colors?.colorUniversalSecondaryF};
  --color-universal-secondary-g: ${componentVariables.universal?.colors?.colorUniversalSecondaryG};
  --color-universal-secondary-h: ${componentVariables.universal?.colors?.colorUniversalSecondaryH};
  --color-universal-tone-a: ${componentVariables.universal?.colors?.colorUniversalToneA};
  --color-universal-tone-a-25: ${componentVariables.universal?.colors?.colorUniversalToneA25};
  --color-universal-primary-base: ${componentVariables.universal?.colors?.colorUniversalPrimaryBase};
  --color-universal-primary-black: ${componentVariables.universal?.colors?.colorUniversalPrimaryBlack};
  --color-universal-primary-grey: ${componentVariables.universal?.colors?.colorUniversalPrimaryGrey};
  --color-universal-primary-background: ${componentVariables.universal?.colors?.colorUniversalPrimaryBackground};
  --color-input-outline-focus: ${componentVariables.universal?.colors?.colorInputOutlineFocus};

  --color-document-hub-component-background: ${componentVariables.universal?.colors?.colorDocumentHubComponentBackground};

  --component-color-action-background-disabled: ${componentVariables.universal?.colors?.componentColorActionBackgroundDisabled};
  --component-color-action-border-disabled: ${componentVariables.universal?.colors?.componentColorActionBorderDisabled};
  --component-color-action-text-disabled: ${componentVariables.button?.colors?.text.buttonColorActionTextDisabled};

  --component-color-interaction-background-primary-hover: ${componentVariables.universal?.colors?.componentColorInteractionBackgroundPrimaryHover};
  --component-color-interaction-background-secondary-hover: ${componentVariables.universal?.colors?.componentColorInteractionBackgroundSecondaryHover};
  --component-color-interaction-background-remove-hover: ${componentVariables.universal?.colors?.componentColorInteractionBackgroundRemoveHover};
  --component-color-interaction-border-hover: ${componentVariables.universal?.colors?.componentColorInteractionBorderHover};

  --component-color-feedback-success: ${componentVariables.universal?.colors?.componentColorFeedbackSuccess};
  --component-color-feedback-success-hover: ${componentVariables.universal?.colors?.componentColorFeedbackSuccessHover};
  --component-color-feedback-success-shade: ${componentVariables.universal?.colors?.componentColorFeedbackSuccessShade};
  --component-color-feedback-warning: ${componentVariables.universal?.colors?.componentColorFeedbackWarning};
  --component-color-feedback-warning-hover: ${componentVariables.universal?.colors?.componentColorFeedbackWarningHover};
  --component-color-feedback-warning-shade: ${componentVariables.universal?.colors?.componentColorFeedbackWarningShade};
  --component-color-feedback-danger: ${componentVariables.universal?.colors?.componentColorFeedbackDanger};
  --component-color-feedback-danger-hover: ${componentVariables.universal?.colors?.componentColorFeedbackDangerHover};
  --component-color-feedback-danger-shade: ${componentVariables.universal?.colors?.componentColorFeedbackDangerShade};
  --component-color-feedback-message: ${componentVariables.universal?.colors?.componentColorFeedbackMessage};
  --component-color-feedback-message-hover: ${componentVariables.universal?.colors?.componentColorFeedbackMessageHover};
  --component-color-feedback-message-shade: ${componentVariables.universal?.colors?.componentColorFeedbackMessageShade};

  --contact-card-link-font-size: ${componentVariables.contactCard?.fontSizes?.contactCardLinkFontSize};
  --contact-card-name-font-size: ${componentVariables.contactCard?.fontSizes?.contactCardNameFontSize};
  --contact-card-link-line-height: ${componentVariables.contactCard?.lineHeights?.contactCardLinkLineHeight};
  --contact-card-name-line-height: ${componentVariables.contactCard?.lineHeights?.contactCardNameLineHeight};

  --divider-color-context-background-primary: ${componentVariables.divider?.colors?.background.dividerColorContextBackgroundPrimary};
  --divider-color-context-background-lighter: ${componentVariables.divider?.colors?.background.dividerColorContextBackgroundLighter};

  --document-tagging-page-header-background-color: ${componentVariables.documentTaggingPage?.colors?.headerBackgroundColor};
  --document-tagging-page-header-box-shadow: ${componentVariables.documentTaggingPage?.boxShadows?.header};
  --document-tagging-page-border-color: ${componentVariables.documentTaggingPage?.colors?.borderColor};
  --document-tagging-page-document-box-shadow: ${componentVariables.documentTaggingPage?.boxShadows?.document};

  --dragAndDrop-interaction-color-background: ${componentVariables.dragAndDrop?.colors?.background.dragAndDropInteractionColorBackground};
  --dragAndDrop-interaction-border-radius: ${componentVariables.dragAndDrop?.radius?.dragAndDropInteractionBorderRadius};
  --footer-color-context-background-primary: ${componentVariables.footer?.colors?.background.footerColorContextBackgroundPrimary};

  --form-border-interaction-border-radius-primary: ${componentVariables.form?.border?.radius.formBorderInteractionBorderRadiusPrimary};
  --form-color-interaction-background-primary: ${componentVariables.form?.colors?.background.formColorInteractionBackgroundPrimary};
  --form-color-interaction-background-primary-hover: ${componentVariables.form?.colors?.background.formColorInteractionBackgroundPrimaryHover};
  --form-color-interaction-border-primary: ${componentVariables.form?.colors?.border.formColorInteractionBorderPrimary};
  --form-color-interaction-border-primary-hover: ${componentVariables.form?.colors?.border.formColorInteractionBorderPrimaryHover};
  --form-color-interaction-border-primary-focus: ${componentVariables.form?.colors?.border.formColorInteractionBorderPrimaryFocus};
  --form-color-interaction-text-primary: ${componentVariables.form?.colors?.text.formColorInteractionTextPrimary};
  --form-color-interaction-text-label-primary: ${componentVariables.form?.colors?.text.formLabelColorInteractionTextPrimary};
  --form-color-interaction-text-primary-focus: ${componentVariables.form?.colors?.text.formColorInteractionTextPrimaryFocus};

  --link-color-action-text-primary: ${componentVariables.link?.colors?.text.linkColorActionTextPrimary};
  --link-color-action-text-primary-hover: ${componentVariables.link?.colors?.text.linkColorActionTextPrimaryHover};
  --link-color-action-text-line: ${componentVariables.link?.colors?.text.linkColorActionTextLine};

  --literature-and-guides-color-background-primary: ${componentVariables.literatureAndGuides?.colors?.background.literatureAndGuidesColorBackgroundPrimary};

  --lozenge-color-feedback-background-primary: ${componentVariables.lozenge?.colors?.background.lozengeColorFeedbackBackgroundPrimary};
  --lozenge-color-feedback-background-emphasized: ${componentVariables.lozenge?.colors?.background.lozengeColorFeedbackBackgroundEmphasized};
  --lozenge-color-feedback-background-info: ${componentVariables.lozenge?.colors?.background.lozengeColorFeedbackBackgroundInfo};
  --lozenge-color-feedback-background-revoke: ${componentVariables.lozenge?.colors?.background.lozengeColorFeedbackBackgroundRevoke};
  --lozenge-color-feedback-background-multiple: ${componentVariables.lozenge?.colors?.background.lozengeColorFeedbackBackgroundMultiple};
  --lozenge-color-feedback-background-negative: ${componentVariables.lozenge?.colors?.background.lozengeColorFeedbackBackgroundNegative};
  --lozenge-color-feedback-background-pending: ${componentVariables.lozenge?.colors?.background.lozengeColorFeedbackBackgroundPending};
  --lozenge-color-feedback-background-positive: ${componentVariables.lozenge?.colors?.background.lozengeColorFeedbackBackgroundPositive};
  --lozenge-color-feedback-text-primary: ${componentVariables.lozenge?.colors?.text.lozengeColorFeedbackTextPrimary};
  --lozenge-color-feedback-text-emphasized: ${componentVariables.lozenge?.colors?.text.lozengeColorFeedbackTexEmphasized};
  --lozenge-color-feedback-text-info: ${componentVariables.lozenge?.colors?.text.lozengeColorFeedbackTexInfo};
  --lozenge-color-feedback-text-negative: ${componentVariables.lozenge?.colors?.text.lozengeColorFeedbackTextNegative};
  --lozenge-color-feedback-text-multiple: ${componentVariables.lozenge?.colors?.text.lozengeColorFeedbackTexMultiple};
  --lozenge-color-feedback-text-positive: ${componentVariables.lozenge?.colors?.text.lozengeColorFeedbackTexPositive};
  --lozenge-color-feedback-text-pending: ${componentVariables.lozenge?.colors?.text.lozengeColorFeedbackTexPending};
  --lozenge-color-feedback-text-revoke: ${componentVariables.lozenge?.colors?.text.lozengeColorFeedbackTexRevoke};
  --lozenge-color-feedback-border-primary: ${componentVariables.lozenge?.colors?.border.lozengeColorFeedbackBorderPrimary};
  --lozenge-color-feedback-border-emphasized: ${componentVariables.lozenge?.colors?.border.lozengeColorFeedbackBorderEmphasized};
  --lozenge-color-feedback-border-info: ${componentVariables.lozenge?.colors?.border.lozengeColorFeedbackBorderInfo};
  --lozenge-color-feedback-border-multiple: ${componentVariables.lozenge?.colors?.border.lozengeColorFeedbackBorderMultiple};
  --lozenge-color-feedback-border-negative: ${componentVariables.lozenge?.colors?.border.lozengeColorFeedbackBorderNegative};
  --lozenge-color-feedback-border-pending: ${componentVariables.lozenge?.colors?.border.lozengeColorFeedbackBorderPending};
  --lozenge-color-feedback-border-positive: ${componentVariables.lozenge?.colors?.border.lozengeColorFeedbackBorderPositive};
  --lozenge-color-feedback-border-revoke: ${componentVariables.lozenge?.colors?.border.lozengeColorFeedbackBorderRevoke};

  --modal-color-context-background-primary: ${componentVariables.modal?.colors?.background.modalColorContextBackgroundPrimary};
  --modal-color-interaction-scrollbar-foreground: ${componentVariables.modal?.colors?.scrollbar.modalColorInteractionScrollbarForeground};
  --modal-color-interaction-scrollbar-background: ${componentVariables.modal?.colors?.scrollbar.modalColorInteractionScrollbarBackground};
  --modal-flex-layout-justify-content: ${componentVariables.modal?.spaces?.footerButtons?.justifyContent};
  --modal-flex-layout-direction-mobile: ${componentVariables.modal?.spaces?.footerButtons?.flexDirection.mobile};
  --modal-flex-layout-direction-tablet: ${componentVariables.modal?.spaces?.footerButtons?.flexDirection.tablet};
  --modal-flex-layout-direction-desktop: ${componentVariables.modal?.spaces?.footerButtons?.flexDirection.desktop};

  --navbar-color-interaction-background-primary: ${componentVariables.navbar?.colors?.background.navbarColorInteractionBackgroundPrimary};
  --navbar-color-interaction-background-expand: ${componentVariables.navbar?.colors?.background.navbarColorInteractionBackgroundExpand};
  --navbar-color-interaction-background-active: ${componentVariables.navbar?.colors?.background.navbarColorInteractionBackgroundActive};
  --navbar-color-interaction-text: ${componentVariables.navbar?.colors?.text.searchColorInteractionTextPrimary};
  --navbar-color-interaction-border: ${componentVariables.navbar?.colors?.border.navbarColorInteractionBorder};
  --navbar-box-shadow-primary: ${componentVariables.navbar?.boxStyles?.shadow.navbarBoxShadowPrimary};
  --notification-color-background-new: ${componentVariables.notifications?.colors?.background.newNotification};

  --table-color-context-background-label: ${componentVariables.table?.colors?.background.tableColorContextBackgroundLabel};

  --pagination-color-interaction-background-primary: ${componentVariables.pagination?.colors?.background.paginationColorInteractionBackgroundPrimary};
  --pagination-color-interaction-background-primary-focus: ${componentVariables.pagination?.colors?.background.paginationColorInteractionBackgroundPrimaryFocus};
  --pagination-color-interaction-chevron: ${componentVariables.pagination?.colors?.paginationColorInteractionChevron};
  --pagination-color-interaction-border-primary: ${componentVariables.pagination?.colors?.border.paginationColorInteractionBorderPrimary};
  --pagination-color-interaction-text-primary: ${componentVariables.pagination?.colors?.text.paginationColorInteractionTextPrimary};
  --pagination-color-interaction-text-primary-focus: ${componentVariables.pagination?.colors?.text.paginationColorInteractionTextPrimaryFocus};

  --page-color-context-background-primary: ${componentVariables.page?.colors?.background.pageColorContextBackgroundPrimary};
  --page-color-context-background-secondary: ${componentVariables.page?.colors?.background.pageColorContextBackgroundSecondary};

  --panel-border-radius-primary: ${componentVariables.panel?.radius?.panelBorderRadius};
  --panel-color-background-primary: ${componentVariables.panel?.colors?.background.panelColorBackgroundPrimary};
  --panel-shadow-primary: ${componentVariables.panel?.shadows?.panelShadowPrimary};

  --profileButton-color-text: ${componentVariables.profileButton?.colors?.text.profileButtonColorText};
  --profileButton-color-action-background: ${componentVariables.profileButton?.colors?.background?.profileButtonColorBackground};
  --profileButton-action-outline: ${componentVariables.profileButton?.outlines?.outline?.profileButtonActionOutline};
  --profileButton-action-outline-offset: ${componentVariables.profileButton?.outlines?.outlineOffset?.profileButtonActionOutlineOffset};

  --results-color-interaction-chevron: ${componentVariables.results?.colors?.resultsColorInteractionChevron};
  --results-color-interaction-background-chevron: ${componentVariables.results?.colors?.background.resultsColorInteractionBackgroundChevron};
  --results-color-interaction-background-primary: ${componentVariables.results?.colors?.background.resultsColorInteractionBackgroundPrimary};
  --results-color-interaction-border-primary: ${componentVariables.results?.colors?.border.resultsColorInteractionBorderPrimary};

  --search-color-interaction-background-primary: ${componentVariables.search?.colors?.background.searchColorInteractionBackgroundPrimary};
  --search-color-interaction-background-primary-hover: ${componentVariables.search?.colors?.background.searchColorInteractionBackgroundPrimaryHover};
  --search-color-interaction-border-primary: ${componentVariables.search?.colors?.border.searchColorInteractionBorderPrimary};
  --search-color-interaction-border-primary-hover: ${componentVariables.search?.colors?.border.searchColorInteractionBorderPrimaryHover};
  --search-color-interaction-border-primary-focus: ${componentVariables.search?.colors?.border.searchColorInteractionBorderPrimaryFocus};
  --search-color-interaction-text-primary: ${componentVariables.search?.colors?.text.searchColorInteractionTextPrimary};
  --search-color-interaction-text-primary-focus: ${componentVariables.search?.colors?.text.searchColorInteractionTextPrimaryFocus};
  --search-border-radius: ${componentVariables.search?.boxStyles?.searchRadius};

  --select-color-interaction-text-tertiary: ${componentVariables.select?.colors?.text.selectColorInteractionTextTertiary};

  --stage-details-sub-header-background-color: ${componentVariables.stageDetails?.colors?.subHeaderBackgroundColor};
  --stage-details-first-section-background-color: ${componentVariables.stageDetails?.colors?.firstSectionBackgroundColor};
  --stage-details-upload-button-color: ${componentVariables.stageDetails?.colors?.uploadButtonColor};
  --stage-details-upload-button-stroke: ${componentVariables.stageDetails?.colors?.uploadButtonStroke};

  --success-icon-color-feedback-background-success: ${componentVariables.successIcon?.colors?.background.successIconColorFeedbackBackgroundSuccess};

  --tabBar-color-interaction-text-focus: ${componentVariables.tabBar?.colors?.text.tabBarColorInteractionTextFocus};
  --tabBar-color-interaction-text-inactive: ${componentVariables.tabBar?.colors?.text.tabBarColorInteractionTextInactive};
  --tabBar-color-interaction-tabLine-focus: ${componentVariables.tabBar?.colors?.tabBarColorInteractionTabLineFocus};
  --tabBar-color-interaction-tabLine-inactive: ${componentVariables.tabBar?.colors?.tabBarColorInteractionTabLineInactive};

  --tabbed-folder-border-radius-primary: ${componentVariables.tabbedFolder?.radius?.tabbedFolderBorderRadius};
  --tabbed-folder-shadow-primary: ${componentVariables.tabbedFolder?.shadows?.tabbedFolderShadowPrimary};

  --table-color-context-background-label: ${componentVariables.table?.colors?.background.tableColorContextBackgroundLabel};

  --tag-color-interaction-background-primary: ${componentVariables.tag?.colors?.background.tagColorInteractionBackgroundPrimary};
  --tag-color-interaction-background-primary-hover: ${componentVariables.tag?.colors?.background.tagColorInteractionBackgroundPrimaryHover};
  --tag-color-interaction-background-primary-focus: ${componentVariables.tag?.colors?.background.tagColorInteractionBackgroundPrimaryFocus};
  --tag-color-interaction-text-primary: ${componentVariables.tag?.colors?.text.tagColorInteractionText};
  --tag-color-interaction-text-primary-hover: ${componentVariables.tag?.colors?.text.tagColorInteractionTextHover};
  --tag-color-interaction-text-primary-focus: ${componentVariables.tag?.colors?.text.tagColorInteractionTextFocus};
  --tag-color-interaction-border-primary: ${componentVariables.tag?.colors?.border.tagColorInteractionBorderPrimary};
  --tag-color-interaction-border-focus: ${componentVariables.tag?.colors?.border.tagColorInteractionBorderPrimaryFocus};

  --topBar-color-interaction-background-primary: ${componentVariables.topBar?.colors?.background.topBarColorInteractionBackgroundPrimary};
  `
}

// TODO: add the rest of the variables for other themes after the design system is agreed upon

// currently doing =======

/// ===== OLD ====== ///
