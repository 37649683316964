//Removes all query parameters from the URL except for host, context, and theme parameters if they are defined
export function clearUrl(
  hostUrl: string | undefined,
  context: string | undefined,
  theme: string | undefined
) {
  let cleanedUrl = window.location.pathname
  /*
   * Adds the hostUrl as a query parameter to the url if hostUrl is defined
   * Example: /app?host=hostUrlString
   */
  if (hostUrl) cleanedUrl += "?host=" + hostUrl
  /*
   * If hostUrl was prefixed to composed URL, add an ampersand then append context as a query parameter
   * Example: /app?host=hostUrlString&context=contextString
   * Otherwise, just append context as a query parameter e.g. /app?context=contextString
   */
  if (context) cleanedUrl += (hostUrl ? "&" : "?") + "context=" + context
  /*
   * If hostUrl or context was prefixed to composed URL, add an ampersand then append theme as a query parameter
   * Example: /app?host=hostString&context=contextString&theme=themeString
   * Otherwise, just append theme as a query parameter e.g. /app?theme=3
   */
  if (theme) cleanedUrl += (hostUrl || context ? "&" : "?") + "theme=" + theme

  window.history.replaceState("", document.title, cleanedUrl)
}
