import { TTheme } from "../../design-tokens/types"
import { getIconSizing } from "../../design-tokens/getters"

export const getIconSizingVariables = (theme: TTheme) => {
  const iconSizing = getIconSizing(theme)

  return `
        --icon-sizing-xl: ${iconSizing["icon-xl"]?.value ?? "64px"};
        --icon-sizing-l: ${iconSizing["icon-l"]?.value ?? "32px"};
        --icon-sizing-m: ${iconSizing["icon-m"]?.value ?? "24px"};
        --icon-sizing-s: ${iconSizing["icon-s"]?.value ?? "20px"};
        --icon-sizing-xs: ${iconSizing["icon-xs"]?.value ?? "16px"};
    `
}
