const ArrowRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="ArrowRight"
  >
    <path d="M3.75 12L20.25 12" fill="none" />
    <path d="M13.5 18.75L20.25 12L13.5 5.25" fill="none" />
  </svg>
)
export default ArrowRight
