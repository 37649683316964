export const TFEFooterLogo = () => {
    
    return (
        <div className="svg">
            <svg width="168" height="67" viewBox="0 0 168 67" fill="none" className="svg" data-testid="tfe-brand-logo-svg" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.5035 21.7961C32.0849 21.5127 32.7231 21.3651 33.37 21.3646C34.135 21.3707 34.8852 21.5769 35.5458 21.9627C36.2064 22.3486 36.7545 22.9007 37.1356 23.5641V14.5234C37.1356 10.7151 35.6228 7.06273 32.9299 4.36982C30.237 1.67692 26.5846 0.164062 22.7763 0.164062C18.9679 0.164062 15.3156 1.67692 12.6227 4.36982C9.92975 7.06273 8.4169 10.7151 8.4169 14.5234V23.4982H9.94466C10.323 22.8248 10.8726 22.2632 11.5377 21.8704C12.2029 21.4776 12.96 21.2675 13.7324 21.2614C13.8378 21.2614 13.9432 21.2666 14.0485 21.274V14.5234C14.0485 12.2088 14.968 9.9889 16.6048 8.35218C18.2415 6.71547 20.4613 5.79597 22.776 5.79597C25.0907 5.79597 27.3105 6.71547 28.9473 8.35218C30.584 9.9889 31.5035 12.2088 31.5035 14.5234V21.7961Z" fill="black"/>
                <path d="M9.35041 39.8828C9.69805 39.8577 10.0236 39.7031 10.2626 39.4494C10.5017 39.1957 10.6367 38.8616 10.6411 38.5131C10.6428 38.3465 10.61 38.1814 10.5448 38.0282C10.4795 37.875 10.3832 37.737 10.2619 37.6228C10.1407 37.5087 9.99707 37.4209 9.84018 37.3651C9.6833 37.3092 9.51653 37.2864 9.35041 37.2982C9.00255 37.3229 8.67666 37.4774 8.43739 37.7312C8.19812 37.9849 8.06295 38.3192 8.05867 38.6679C8.057 38.8345 8.08988 38.9997 8.15523 39.1529C8.22058 39.3062 8.31698 39.4443 8.43835 39.5584C8.55972 39.6726 8.70342 39.7603 8.8604 39.8161C9.01738 39.872 9.18423 39.8946 9.35041 39.8828Z" fill="#5BA4C2"/>
                <path d="M22.7768 13.4797L0.000526524 26.629V30.3441L13.7757 36.6132C14.1962 36.1668 14.7704 35.8965 15.3824 35.8567C15.676 35.8355 15.9707 35.8755 16.248 35.9742C16.5253 36.0728 16.779 36.2279 16.9933 36.4297C17.2075 36.6315 17.3775 36.8756 17.4925 37.1465C17.6075 37.4174 17.665 37.7092 17.6614 38.0035C17.6537 38.6185 17.415 39.208 16.9927 39.6552C16.5704 40.1024 15.9954 40.3744 15.3819 40.4173C15.0884 40.4388 14.7936 40.3991 14.5162 40.3006C14.2388 40.2022 13.9849 40.0472 13.7706 39.8454C13.5563 39.6437 13.3862 39.3996 13.2712 39.1287C13.1562 38.8578 13.0987 38.5659 13.1024 38.2716C13.1023 38.0023 13.1485 37.7351 13.2388 37.4814L0 31.4552V33.5414L7.74417 37.0663C8.16389 36.6189 8.73824 36.3482 9.35042 36.3092C9.64392 36.2878 9.93868 36.3275 10.216 36.4259C10.4934 36.5243 10.7472 36.6793 10.9615 36.881C11.1758 37.0826 11.3459 37.3266 11.461 37.5975C11.576 37.8684 11.6336 38.1602 11.6299 38.4544C11.6223 39.0696 11.3837 39.6593 10.9614 40.1067C10.5391 40.5541 9.96409 40.8263 9.35042 40.8693C9.05696 40.8906 8.76226 40.8508 8.485 40.7523C8.20773 40.6539 7.95393 40.4989 7.73966 40.2972C7.52538 40.0956 7.35531 39.8517 7.24019 39.5809C7.12507 39.3101 7.06743 39.0184 7.0709 38.7241C7.07103 38.4548 7.11736 38.1876 7.20787 37.9339L0.000526524 34.6535V36.8871L22.7768 48.722L45.551 36.8871V26.629L22.7768 13.4797ZM22.7768 38.4202L2.94226 28.367L2.88325 28.3375L17.731 19.742H27.781L42.6714 28.3375L22.7768 38.4202ZM43.8894 34.6224L39.6659 36.9145V32.6911L43.8894 30.3989V34.6224Z" fill="#5BA4C2"/>
                <path d="M15.3825 39.4283C15.7301 39.4034 16.0558 39.2488 16.2947 38.9951C16.5337 38.7414 16.6686 38.4071 16.6726 38.0585C16.6741 37.8923 16.6412 37.7275 16.5759 37.5745C16.5105 37.4216 16.4142 37.2839 16.293 37.1701C16.1718 37.0562 16.0283 36.9687 15.8716 36.9131C15.7149 36.8575 15.5483 36.8349 15.3825 36.8469C15.0352 36.8714 14.7098 37.0255 14.4707 37.2786C14.2317 37.5317 14.0964 37.8653 14.0918 38.2134C14.09 38.38 14.1228 38.545 14.1881 38.6983C14.2533 38.8515 14.3496 38.9895 14.4709 39.1036C14.5922 39.2178 14.7358 39.3056 14.8927 39.3614C15.0496 39.4173 15.2163 39.4401 15.3825 39.4283Z" fill="#5BA4C2"/>
                <path d="M34.9346 54.8369C34.8748 55.1556 34.9391 55.485 35.1143 55.7578C35.2895 56.0306 35.5624 56.226 35.8771 56.3041C36.0356 56.3049 36.1926 56.2742 36.3391 56.214C36.4857 56.1537 36.6189 56.065 36.7309 55.953C36.843 55.8409 36.9318 55.7078 36.9921 55.5613C37.0524 55.4148 37.0831 55.2577 37.0825 55.0993C37.1422 54.7804 37.0778 54.4508 36.9025 54.1779C36.7272 53.905 36.4542 53.7094 36.1395 53.6311C35.9809 53.6303 35.8238 53.661 35.6772 53.7213C35.5306 53.7816 35.3975 53.8705 35.2854 53.9826C35.1734 54.0947 35.0847 54.228 35.0245 54.3746C34.9643 54.5213 34.9337 54.6784 34.9346 54.8369Z" fill="black"/>
                <path d="M29.9251 54.1192C29.8657 54.438 29.9302 54.7675 30.1054 55.0404C30.2807 55.3132 30.5535 55.5089 30.8681 55.5874C31.0267 55.5881 31.1839 55.5574 31.3306 55.4971C31.4773 55.4368 31.6105 55.348 31.7227 55.2359C31.8349 55.1238 31.9238 54.9906 31.9843 54.8439C32.0448 54.6973 32.0756 54.5402 32.0751 54.3816C32.1345 54.0628 32.0699 53.7334 31.8945 53.4607C31.7191 53.188 31.4462 52.9926 31.1315 52.9144C30.9731 52.9138 30.8161 52.9446 30.6696 53.0049C30.5231 53.0653 30.3899 53.154 30.2778 53.2659C30.1657 53.3779 30.0768 53.5109 30.0163 53.6574C29.9558 53.8038 29.9248 53.9608 29.9251 54.1192Z" fill="black"/>
                <path d="M31.2332 51.8899C31.4941 51.9291 31.7431 52.0256 31.9623 52.1725C32.1814 52.3195 32.3653 52.5131 32.5007 52.7396L45.5509 45.7767V42.9988L22.7757 54.8326L0.000514467 42.9988V52.9282L22.7768 66.078L45.5509 52.9282V50.6355L37.87 54.3759C37.9201 54.6472 37.932 54.9242 37.9053 55.1988C37.7778 56.5026 36.8232 57.4551 35.7764 57.3276C34.7297 57.2001 33.9853 56.0385 34.1122 54.7336C34.2392 53.4287 35.1922 52.4783 36.2411 52.6058C36.5015 52.6452 36.7499 52.7418 36.9685 52.8886C37.1871 53.0355 37.3705 53.2289 37.5054 53.455L45.5483 49.5387V46.8862L32.8611 53.6605C32.9126 53.9314 32.9248 54.2084 32.8974 54.4828C32.7683 55.7872 31.8159 56.7402 30.7686 56.6127C29.7212 56.4852 28.9774 55.3236 29.1033 54.0187C29.2292 52.7138 30.1848 51.7624 31.2332 51.8899ZM6.51246 53.5967L2.28952 51.3035V47.0806L6.51246 49.3733V53.5967Z" fill="black"/>
                <path d="M34.2924 26.2107L20.4751 33.8174L22.8685 35.029L36.543 27.5056L34.2924 26.2107ZM19.569 23.4618L10.2249 28.6219L12.6214 29.8368L21.7168 24.8589L19.569 23.4618ZM27.869 22.5135L13.645 30.3546L16.0373 31.5663L30.1169 23.8074L27.869 22.5135ZM31.1252 24.3874L17.1088 32.1094L19.5026 33.3211L33.3768 25.6823L31.1252 24.3874Z" fill="black"/>
                <path d="M53.5965 26.9509H50.4819C49.5379 26.9509 48.7745 26.4694 48.7745 25.5649C48.7745 24.6604 49.5379 24.1789 50.4819 24.1789H60.0041C60.9476 24.1789 61.711 24.6614 61.711 25.5649C61.711 26.4684 60.9476 26.9509 60.0041 26.9509H56.8901V37.2565C56.8901 37.4728 56.8475 37.687 56.7647 37.8868C56.6819 38.0866 56.5606 38.2682 56.4077 38.4211C56.2547 38.5741 56.0732 38.6954 55.8733 38.7782C55.6735 38.861 55.4593 38.9036 55.243 38.9036C55.0267 38.9036 54.8125 38.861 54.6127 38.7782C54.4129 38.6954 54.2313 38.5741 54.0783 38.4211C53.9254 38.2682 53.8041 38.0866 53.7213 37.8868C53.6385 37.687 53.5959 37.4728 53.5959 37.2565L53.5965 26.9509Z" fill="#5BA4C2"/>
                <path d="M63.5611 26.1077C63.5611 24.641 64.3245 24.179 65.4898 24.179H72.7419C73.706 24.179 74.4088 24.4603 74.4088 25.505C74.4088 26.5497 73.706 26.8305 72.7419 26.8305H66.8558V30.2052H71.8374C72.7014 30.2052 73.3841 30.4465 73.3841 31.4696C73.3841 32.4926 72.6992 32.7339 71.8374 32.7339H66.8558V37.254C66.8558 37.6909 66.6823 38.1099 66.3733 38.4188C66.0644 38.7278 65.6454 38.9013 65.2085 38.9013C64.7716 38.9013 64.3526 38.7278 64.0436 38.4188C63.7347 38.1099 63.5611 37.6909 63.5611 37.254V26.1077Z" fill="#5BA4C2"/>
                <path d="M76.2178 26.1077C76.2178 24.641 76.9817 24.179 78.1465 24.179H85.8807C86.8447 24.179 87.548 24.4603 87.548 25.505C87.548 26.5497 86.8447 26.8305 85.8807 26.8305H79.5126V29.9645H85.0968C85.9602 29.9645 86.6435 30.2052 86.6435 31.2288C86.6435 32.2524 85.9586 32.4932 85.0968 32.4932H79.5126V35.9881H86.0419C87.0059 35.9881 87.7092 36.2688 87.7092 37.3146C87.7092 38.3603 87.0059 38.6395 86.0419 38.6395H78.1465C76.9817 38.6395 76.2178 38.178 76.2178 36.7114V26.1077Z" fill="#5BA4C2"/>
                <path d="M56.6699 41.6471C60.4461 41.6471 62.5175 44.0177 62.5175 45.4243C62.54 45.625 62.517 45.8283 62.4501 46.0189C62.3833 46.2095 62.2745 46.3826 62.1316 46.5255C61.9888 46.6683 61.8156 46.7771 61.6249 46.8438C61.4343 46.9105 61.2311 46.9335 61.0303 46.911C59.1222 46.911 59.6849 44.2996 56.672 44.2996C54.6027 44.2996 52.8948 46.0865 52.8948 49.2211C52.8948 51.852 53.9394 54.142 56.7932 54.142C59.3846 54.142 59.3219 51.3705 60.9113 51.3705C61.7147 51.3705 62.5186 51.8325 62.5186 52.6348C62.5186 54.7236 59.9899 56.7929 56.7932 56.7929C52.2731 56.7929 49.6016 54.2021 49.6016 49.22C49.5985 45.143 52.0097 41.6471 56.6699 41.6471Z" fill="black"/>
                <path d="M69.1839 45.6857C72.7193 45.6857 74.61 48.0953 74.61 51.1893C74.61 54.1226 73.1834 56.7135 69.1839 56.7135C65.1843 56.7135 63.7603 54.1226 63.7603 51.1893C63.7624 48.0953 65.6505 45.6857 69.1839 45.6857ZM69.1839 54.5446C70.9318 54.5446 71.6746 52.9773 71.6746 51.1893C71.6746 49.2807 70.8918 47.8546 69.1839 47.8546C67.4759 47.8546 66.6926 49.2807 66.6926 51.1893C66.6947 52.9773 67.4385 54.5446 69.1839 54.5446Z" fill="black"/>
                <path d="M76.4992 47.1519C76.4992 46.2679 76.9011 45.6858 77.8252 45.6858C78.7492 45.6858 79.1512 46.2653 79.1512 47.1519V47.5333H79.1912C79.5835 46.954 80.114 46.4817 80.7348 46.1591C81.3556 45.8365 82.0471 45.6738 82.7467 45.6858C84.5141 45.6858 86.4428 46.5693 86.4428 49.5421V55.2464C86.4355 55.6305 86.2778 55.9964 86.0036 56.2655C85.7294 56.5345 85.3606 56.6853 84.9764 56.6853C84.5922 56.6853 84.2234 56.5345 83.9492 56.2655C83.675 55.9964 83.5173 55.6305 83.51 55.2464V50.1231C83.51 48.9378 82.9305 48.0944 81.6619 48.0944C81.0604 48.107 80.4883 48.3571 80.0706 48.7901C79.6528 49.2232 79.4234 49.8039 79.4325 50.4055V55.2464C79.4325 55.6354 79.2779 56.0084 79.0029 56.2835C78.7279 56.5585 78.3548 56.7131 77.9658 56.7131C77.5769 56.7131 77.2038 56.5585 76.9287 56.2835C76.6537 56.0084 76.4992 55.6354 76.4992 55.2464V47.1519Z" fill="black"/>
                <path d="M89.6985 47.8751H89.0353C88.2118 47.8751 87.8705 47.5532 87.8705 46.9111C87.8705 46.2689 88.2118 45.9459 89.0353 45.9459H89.6985V44.4798C89.6985 42.6317 91.2263 41.6471 92.9326 41.6471C93.9973 41.6471 94.8613 42.0686 94.8613 43.0537C94.867 43.3115 94.7713 43.5611 94.5947 43.7489C94.4181 43.9367 94.1748 44.0477 93.9172 44.0578C93.696 44.0578 93.4958 43.9978 93.274 43.9978C93.1846 43.9927 93.0952 44.0074 93.0121 44.0406C92.929 44.0739 92.8542 44.1249 92.793 44.1902C92.7317 44.2555 92.6856 44.3335 92.6577 44.4185C92.6299 44.5036 92.621 44.5938 92.6318 44.6826V45.947H93.5353C94.3993 45.947 94.8613 46.2104 94.8613 46.9121C94.8613 47.6138 94.3993 47.8762 93.5353 47.8762H92.6318V55.2463C92.6318 55.6353 92.4773 56.0083 92.2022 56.2834C91.9272 56.5584 91.5541 56.713 91.1652 56.713C90.7762 56.713 90.4031 56.5584 90.1281 56.2834C89.853 56.0083 89.6985 55.6353 89.6985 55.2463V47.8751Z" fill="black"/>
                <path d="M97.8352 41.6471C98.1373 41.6471 98.4327 41.7367 98.6839 41.9046C98.9352 42.0725 99.131 42.3111 99.2466 42.5902C99.3623 42.8694 99.3925 43.1766 99.3336 43.4729C99.2746 43.7693 99.1291 44.0415 98.9154 44.2552C98.7018 44.4688 98.4296 44.6143 98.1332 44.6733C97.8369 44.7322 97.5297 44.702 97.2505 44.5864C96.9714 44.4707 96.7327 44.2749 96.5649 44.0237C96.397 43.7724 96.3074 43.4771 96.3074 43.1749C96.3074 42.7697 96.4683 42.3811 96.7549 42.0946C97.0414 41.8081 97.43 41.6471 97.8352 41.6471ZM96.3674 47.1518C96.3674 46.7628 96.522 46.3898 96.797 46.1147C97.0721 45.8397 97.4451 45.6852 97.8341 45.6852C98.2231 45.6852 98.5961 45.8397 98.8712 46.1147C99.1462 46.3898 99.3007 46.7628 99.3007 47.1518V55.2463C99.3007 55.6353 99.1462 56.0083 98.8712 56.2834C98.5961 56.5584 98.2231 56.713 97.8341 56.713C97.4451 56.713 97.0721 56.5584 96.797 56.2834C96.522 56.0083 96.3674 55.6353 96.3674 55.2463V47.1518Z" fill="black"/>
                <path d="M111.737 55.3274C111.737 56.2308 111.315 56.7134 110.411 56.7134C110.227 56.7321 110.041 56.7086 109.868 56.6446C109.694 56.5806 109.538 56.4779 109.41 56.3443C109.282 56.2106 109.186 56.0495 109.13 55.8733C109.074 55.6971 109.058 55.5104 109.085 55.3274V54.8653H109.045C108.563 56.1107 107.378 56.7134 105.951 56.7134C103.501 56.7134 101.351 54.9254 101.351 51.1292C101.351 48.316 102.777 45.6851 105.871 45.6851C106.441 45.6544 107.01 45.7757 107.518 46.0368C108.026 46.2978 108.456 46.6891 108.764 47.1707H108.804V43.1938C108.811 42.8097 108.969 42.4438 109.243 42.1747C109.517 41.9056 109.886 41.7549 110.27 41.7549C110.654 41.7549 111.023 41.9056 111.298 42.1747C111.572 42.4438 111.729 42.8097 111.737 43.1938V55.3274ZM106.615 54.4233C108.322 54.4233 108.964 52.8956 108.964 51.2293C108.964 49.7231 108.402 47.9551 106.615 47.9746C104.746 47.9746 104.284 49.8638 104.284 51.2293C104.284 52.9567 105.047 54.3828 106.615 54.4233Z" fill="black"/>
                <path d="M116.559 51.752C116.538 52.1118 116.594 52.4721 116.723 52.8087C116.852 53.1452 117.052 53.4502 117.309 53.7032C117.566 53.9562 117.874 54.1513 118.212 54.2756C118.551 54.3998 118.912 54.4502 119.271 54.4234C121.481 54.4234 121.481 52.9773 122.666 52.9773C122.975 52.9785 123.272 53.0977 123.496 53.3106C123.72 53.5235 123.854 53.814 123.871 54.1226C123.871 55.8906 121.039 56.7135 119.271 56.7135C115.173 56.7135 113.626 53.9414 113.626 51.39C113.626 47.9747 115.696 45.6857 119.171 45.6857C122.545 45.6857 124.474 48.0758 124.474 50.4865C124.474 51.4907 124.173 51.7509 123.188 51.7509L116.559 51.752ZM121.702 50.0662C121.657 49.4387 121.378 48.8509 120.921 48.4192C120.463 47.9876 119.86 47.7436 119.231 47.7355C117.765 47.7355 116.881 48.6401 116.559 50.0662H121.702Z" fill="black"/>
                <path d="M126.364 47.1519C126.364 46.2679 126.766 45.6858 127.689 45.6858C128.613 45.6858 129.015 46.2653 129.015 47.1519V47.5333H129.055C129.447 46.954 129.978 46.4817 130.599 46.1591C131.22 45.8365 131.911 45.6738 132.611 45.6858C134.379 45.6858 136.307 46.5693 136.307 49.5421V55.2464C136.307 55.6354 136.153 56.0084 135.878 56.2835C135.603 56.5585 135.23 56.7131 134.841 56.7131C134.452 56.7131 134.079 56.5585 133.804 56.2835C133.529 56.0084 133.374 55.6354 133.374 55.2464V50.1231C133.374 48.9378 132.792 48.0944 131.526 48.0944C130.925 48.1068 130.353 48.3569 129.935 48.79C129.517 49.223 129.287 49.8038 129.296 50.4055V55.2464C129.289 55.6305 129.132 55.9964 128.857 56.2655C128.583 56.5345 128.214 56.6853 127.83 56.6853C127.446 56.6853 127.077 56.5345 126.803 56.2655C126.529 55.9964 126.371 55.6305 126.364 55.2464V47.1519Z" fill="black"/>
                <path d="M139.402 47.8751H138.9C138.076 47.8751 137.735 47.5532 137.735 46.911C137.735 46.2688 138.076 45.9459 138.9 45.9459H139.402V44.1584C139.402 43.7696 139.557 43.3966 139.832 43.1217C140.107 42.8467 140.48 42.6923 140.868 42.6923C141.257 42.6923 141.63 42.8467 141.905 43.1217C142.18 43.3966 142.335 43.7696 142.335 44.1584V45.9459H143.219C144.083 45.9459 144.545 46.2093 144.545 46.911C144.545 47.6127 144.083 47.8751 143.219 47.8751H142.335V53.7411C142.335 54.1826 142.576 54.4234 143.098 54.4234H143.519C143.654 54.4075 143.791 54.4217 143.92 54.465C144.049 54.5082 144.166 54.5795 144.264 54.6736C144.362 54.7678 144.438 54.8824 144.487 55.0094C144.535 55.1364 144.555 55.2725 144.545 55.408C144.545 56.2509 143.821 56.7134 142.415 56.7134C140.286 56.7134 139.402 55.8294 139.402 54.102L139.402 47.8751Z" fill="black"/>
                <path d="M147.7 41.6471C148.002 41.6471 148.297 41.7367 148.548 41.9046C148.8 42.0725 148.995 42.3111 149.111 42.5902C149.227 42.8694 149.257 43.1766 149.198 43.4729C149.139 43.7693 148.994 44.0415 148.78 44.2552C148.566 44.4688 148.294 44.6143 147.998 44.6733C147.701 44.7322 147.394 44.702 147.115 44.5864C146.836 44.4707 146.597 44.2749 146.429 44.0237C146.261 43.7724 146.172 43.4771 146.172 43.1749C146.172 42.7697 146.333 42.3811 146.619 42.0946C146.906 41.8081 147.294 41.6471 147.7 41.6471ZM146.233 47.1518C146.24 46.7677 146.398 46.4018 146.672 46.1327C146.946 45.8637 147.315 45.713 147.699 45.713C148.084 45.713 148.452 45.8637 148.727 46.1327C149.001 46.4018 149.159 46.7677 149.166 47.1518V55.2463C149.159 55.6304 149.001 55.9963 148.727 56.2654C148.452 56.5344 148.084 56.6852 147.699 56.6852C147.315 56.6852 146.946 56.5344 146.672 56.2654C146.398 55.9963 146.24 55.6304 146.233 55.2463V47.1518Z" fill="black"/>
                <path d="M161.4 53.6195C161.4 54.8449 161.701 55.1267 161.701 55.5887C161.688 55.7497 161.644 55.9066 161.57 56.0503C161.496 56.194 161.395 56.3217 161.272 56.4262C161.149 56.5307 161.006 56.6098 160.852 56.6591C160.698 56.7084 160.536 56.7269 160.375 56.7135C159.111 56.7135 158.69 55.9101 158.648 55.0066C158.148 55.5734 157.528 56.021 156.833 56.3164C156.138 56.6118 155.385 56.7475 154.63 56.7135C152.963 56.7135 151.316 55.5682 151.316 53.7207C151.316 51.2899 153.164 50.6067 155.413 50.3454L157.204 50.1446C157.907 50.0646 158.469 49.9039 158.469 49.0605C158.469 48.217 157.605 47.8546 156.62 47.8546C154.451 47.8546 154.391 49.4619 153.225 49.4619C153.069 49.4724 152.913 49.4513 152.765 49.4001C152.617 49.3488 152.481 49.2683 152.364 49.1633C152.248 49.0584 152.154 48.9312 152.088 48.7893C152.022 48.6474 151.985 48.4936 151.98 48.3371C151.98 47.1713 153.627 45.6857 156.641 45.6857C159.453 45.6857 161.402 46.5887 161.402 48.698L161.4 53.6195ZM158.467 51.5123C158.093 51.7735 157.657 51.9331 157.203 51.9753L156.138 52.1334C154.912 52.3135 154.25 52.695 154.25 53.579C154.25 54.2427 154.872 54.7848 155.877 54.7848C157.484 54.7848 158.468 53.7407 158.468 52.6549L158.467 51.5123Z" fill="black"/>
                <path d="M164.093 43.1943C164.093 42.8054 164.248 42.4324 164.523 42.1574C164.798 41.8824 165.171 41.7279 165.559 41.7279C165.948 41.7279 166.321 41.8824 166.596 42.1574C166.871 42.4324 167.026 42.8054 167.026 43.1943V55.2462C167.026 55.6351 166.871 56.0081 166.596 56.2831C166.321 56.5581 165.948 56.7126 165.559 56.7126C165.171 56.7126 164.798 56.5581 164.523 56.2831C164.248 56.0081 164.093 55.6351 164.093 55.2462V43.1943Z" fill="black"/>
            </svg>
        </div>
    )
}