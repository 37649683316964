import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"

import "./InfoBoxMessage.css"

export const InfoBoxMessage = ({ message }: { message: string }) => {
  const questionMarkCircleIcon = useGetIcon("QuestionMarkCircleTransparent")

  return (
    <div className="info-box-message flex">
      <div className="icon-info-container mr-1 flex">
        {questionMarkCircleIcon}
      </div>
      <div>{message}</div>
    </div>
  )
}
