export const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="103"
    height="26"
    viewBox="0 0 103 26"
    fill="none"
  >
    <path
      d="M96.343 6.47642C96.343 4.71235 97.8291 3.28013 99.6605 3.27314C98.5014 1.32044 96.3139 0 93.8067 0C90.0858 0 87.0698 2.89937 87.0698 6.47642C87.0698 10.0535 90.0858 12.9528 93.8067 12.9528C96.3175 12.9528 98.5014 11.6324 99.6641 9.67621C97.8255 9.67271 96.343 8.24049 96.343 6.47642Z"
      fill="white"
    />
    <path
      d="M99.6715 3.27304C99.6679 3.27304 99.6643 3.27304 99.6606 3.27304C100.22 4.21621 100.544 5.30959 100.544 6.47632C100.544 7.64305 100.22 8.73294 99.6606 9.6796C99.6643 9.6796 99.6679 9.6796 99.6715 9.6796C101.51 9.6796 103 8.24738 103 6.47632C103 4.70875 101.51 3.27304 99.6715 3.27304Z"
      fill="white"
    />
    <path
      d="M6.98391 12.7188C5.10531 12.7188 3.80445 13.634 3.2812 14.5702H3.10315V13.0297H0V25.6856H3.25213V18.4442C3.25213 16.8548 4.37857 15.6531 5.95559 15.6531C7.45629 15.6531 8.48462 16.6871 8.48462 18.2276V25.6856H11.7368V17.4102C11.7368 14.7169 9.93445 12.7188 6.98391 12.7188Z"
      fill="white"
    />
    <path
      d="M22.2633 20.2991C22.2633 21.8885 21.1369 23.1146 19.5599 23.1146C18.0592 23.1146 17.0054 22.0562 17.0054 20.5156V13.0297H13.7533V21.3086C13.7533 24.0019 15.581 26 18.5352 26C20.3884 26 21.6892 25.0848 22.2125 24.1486H22.4123V25.6891H25.5155V13.0297H22.2633V20.2991Z"
      fill="white"
    />
    <path
      d="M34.0475 15.5588C35.5736 15.5588 36.8018 16.4496 37.2015 17.7001H40.53C40.0285 14.7169 37.4014 12.7188 34.0221 12.7188C29.8942 12.7188 27.1399 15.6287 27.1399 19.3594C27.1399 23.0902 29.8942 26 34.0221 26C37.4014 26 40.0031 24.0508 40.5045 21.1165H37.1761C36.8018 22.3426 35.5736 23.16 34.0729 23.16C31.9218 23.16 30.4429 21.5497 30.4429 19.3594C30.4465 17.2425 31.8455 15.5588 34.0475 15.5588Z"
      fill="white"
    />
    <path
      d="M45.2608 21.8362V7.64331H42.0087V22.1262C42.0087 24.3653 43.3095 25.6858 45.6133 25.6858H46.8415V22.9436H46.3146C45.6351 22.9436 45.2608 22.5593 45.2608 21.8362Z"
      fill="white"
    />
    <path
      d="M54.2688 12.7188C50.4135 12.7188 47.6373 15.4365 47.6373 19.4328C47.6373 23.2089 50.3917 26 54.2688 26C57.6735 26 60.0245 24.3163 60.6749 21.7418H57.6481C57.1975 22.7758 55.973 23.4989 54.3705 23.4989C52.2666 23.4989 50.8931 22.1994 50.7914 20.2746H60.8021V19.2896C60.8021 15.7265 58.4984 12.7188 54.2688 12.7188ZM50.8422 18.0599C51.0421 16.4705 52.4447 15.22 54.2724 15.22C56.1728 15.22 57.55 16.3518 57.7026 18.0599H50.8422Z"
      fill="white"
    />
    <path
      d="M71.0381 20.2991C71.0381 21.8885 69.9117 23.1146 68.3347 23.1146C66.834 23.1146 65.7802 22.0562 65.7802 20.5156V13.0297H62.5281V21.3086C62.5281 24.0019 64.3558 26 67.31 26C69.1632 26 70.464 25.0848 70.9873 24.1486H71.1871V25.6891H74.2903V13.0297H71.0381V20.2991Z"
      fill="white"
    />
    <path
      d="M79.2722 16.495C79.2722 15.6776 80.1224 15.1222 81.4487 15.1222C82.8768 15.1222 83.6253 15.723 83.7779 16.6382H86.6558C86.4559 14.1126 84.3266 12.7153 81.4742 12.7153C78.222 12.7153 76.2926 14.448 76.2926 16.5893C76.2926 21.8815 83.8506 19.3315 83.8506 22.0282C83.8506 22.919 83.0003 23.5443 81.4996 23.5443C80.0243 23.5443 79.1232 22.9679 78.996 21.885H75.991C76.1145 24.3862 77.8914 26 81.4451 26C84.9734 26 87.001 24.2674 87.001 21.8606C87.0046 16.3762 79.2722 18.9018 79.2722 16.495Z"
      fill="white"
    />
  </svg>
);
