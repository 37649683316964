import classNames from "classnames"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import {
  InputFieldType,
  validateInput,
} from "../../../utils/forms/validateInputs"
import { ValidationErrorLabel } from "../ValidationErrorLabel"
import "./TextInput.css"

export type ErrorOnSubmit = {
  hasError: boolean
  message: string
}

export interface TextInputWithValidationProps {
  value?: string
  className?: string
  labelClassName?: string
  /**
   * Optional HTML `id` attribute to apply to the component.
   */
  id?: string
  /**
   * Toggles whether the component is disabled or not (preventing user interaction).
   */
  isDisabled?: boolean
  /**
   * Optional text label to display within the component.
   */
  label?: string
  sublabel?: string
  /**
   * Optional placeholder text to display within the component.
   */
  placeholder?: string
  /**
   * HTML `type` attribute to apply to the component.
   */
  type?: InputFieldType
  /**
   * HTML `name` attribute to apply to the component.
   */
  name: string
  /*
   * Determines if an X is displayed which removes current input on click
   */
  hasX?: boolean
  /*
   * Ensures that the validation error message is hooked up to the input for accessibility
   */
  ariaErrorMessageId?: string
  hasAutoFocus?: boolean
  displayError?: boolean
  email?: string
  alreadyAddedContactsEmails?: string[]
  errorMessagePosition?: "above" | "below"
  hidePassword?: boolean
  isRequired?: boolean
  minLength?: number
  maxLength?: number
  showThickErrorBorder?: boolean
  shouldShowWarningIcon?: boolean
  validateOnSubmit?: boolean
  showPasswordToggleButton?: boolean
  errorOnSubmit?: ErrorOnSubmit
  onChange?: (newValue: string) => void
  setDisabled?: Dispatch<SetStateAction<boolean>>
  autoComplete?: string
  handleSubmit?: () => void
}

export const TextInputWithValidation: React.FC<
  TextInputWithValidationProps
> = ({
  value,
  className = "",
  labelClassName = "",
  id = uuidv4(),
  isDisabled = false,
  label,
  sublabel,
  placeholder = "",
  type = InputFieldType.TEXT,
  name,
  hasX,
  hasAutoFocus,
  displayError = true,
  email,
  alreadyAddedContactsEmails = [],
  errorMessagePosition = "above",
  hidePassword = false,
  isRequired = false,
  maxLength,
  showThickErrorBorder = false,
  shouldShowWarningIcon = true,
  errorOnSubmit = { hasError: false, message: "" },
  validateOnSubmit = false,
  showPasswordToggleButton = false,
  ariaErrorMessageId,
  onChange,
  setDisabled,
  autoComplete,
  handleSubmit,
}) => {
  const hasLabel = !!(label && label.length)
  const crossIcon = useGetIcon("Cross")
  const hideIcon = useGetIcon("Hide")
  const [error, setError] = useState("")
  const [showXButton, setShowXButton] = useState(false)
  const [showPassword, setShowPassword] = useState(!hidePassword)

  useEffect(() => {
    if (errorOnSubmit.message.length > 0) {
      setError(errorOnSubmit.message)
    }
  }, [showPassword, showThickErrorBorder, errorOnSubmit.message])

  /**If the type prop is toggled between text and password (e.g. when a 'show password' checkbox is toggled),
    then this useEffect ensures the that the password text is toggled between being shown and hidden**/
  useEffect(() => {
    setShowPassword(!hidePassword)
  }, [hidePassword])

  const textInputParentClass = classNames("text-input", {
    "hide-arrows": type === InputFieldType.NUMBER,
    //The className key here dynamically applies the value of the prop called className
    [className]: className,
  })

  const outerWrapperClass = classNames("outer-wrapper", {
    //The "error" key here is converted to a string by classNames
    error:
      (errorOnSubmit.hasError && validateOnSubmit) ||
      (error.length > 0 && !validateOnSubmit) ||
      showThickErrorBorder,
  })

  const inputClass = classNames("input-field", {
    //The keys here are converted to a string by classNames
    disabled: isDisabled,
    error: showThickErrorBorder,
  })

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value)
    }

    const error = validateInput({
      type,
      value: e.target.value,
      currentUserEmail: email,
      alreadyAddedContactsEmails: alreadyAddedContactsEmails,
    })
    if (!validateOnSubmit) {
      setError(error)
    }
    //Display an X on the input field which removes that input's value on click
    setShowXButton(e.target.value.length > 0)
  }

  //Using enter to trigger optional onSubmit function prop
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit?.()
    }
  }

  const deleteTextContent = () => {
    setDisabled && setDisabled(false)
    if (onChange) {
      onChange("")
      setError("")
    }
    setShowXButton(false)
  }
  const showValidationErrorAbove =
    errorMessagePosition === "above" && displayError

  return (
    <div className={textInputParentClass}>
      <div className="text-input-labels-container">
        <div>
          {hasLabel && (
            <label
              className={`text-input-label ${labelClassName} ${
                isRequired && "required"
              }`}
              htmlFor={id}
            >
              {label}
            </label>
          )}
          {sublabel && (
            <label
              className="text-input-sublabel"
              id={"sublabel-" + id}
              data-testid="text-input-sublabel"
              htmlFor={id}
            >
              {sublabel}
            </label>
          )}
        </div>
      </div>
      {showValidationErrorAbove && (
        <ValidationErrorLabel
          id={ariaErrorMessageId ? ariaErrorMessageId : "input-error"}
          errorContent={
            errorOnSubmit.message.length > 0 ? errorOnSubmit.message : error
          }
          visible={
            validateOnSubmit
              ? errorOnSubmit.message.length > 0
              : error.trim().length > 0
          }
          shouldShowWarningIcon={shouldShowWarningIcon}
        />
      )}
      <div className={outerWrapperClass}>
        <div className={`inner-wrapper ${showThickErrorBorder && "error"}`}>
          <input
            disabled={isDisabled}
            value={value}
            data-testid={id}
            id={id}
            name={name}
            maxLength={maxLength}
            onChange={handleOnChange}
            className={inputClass}
            placeholder={placeholder}
            type={showPassword ? "text" : type}
            autoFocus={hasAutoFocus}
            autoComplete={autoComplete}
            aria-invalid={showValidationErrorAbove}
            aria-errormessage={ariaErrorMessageId}
            onKeyDown={(e) => handleKeyPress(e)}
          />
          {(type === "password" || type === "text") &&
            showPasswordToggleButton && (
              <button
                className="hide-icon"
                onClick={() => {
                  setShowPassword(!showPassword)
                }}
              >
                {hideIcon}
              </button>
            )}
          {showXButton && hasX && (
            <div className="crossIcon" onClick={deleteTextContent}>
              {crossIcon}
            </div>
          )}
        </div>
      </div>
      {errorMessagePosition === "below" && displayError && (
        <ValidationErrorLabel
          shouldShowWarningIcon={shouldShowWarningIcon}
          errorContent={
            errorOnSubmit.message.length > 0 ? errorOnSubmit.message : error
          }
          visible={
            validateOnSubmit
              ? errorOnSubmit.message.length > 0
              : error.trim().length > 0
          }
        />
      )}
    </div>
  )
}

TextInputWithValidation.displayName = "TextInputWithValidation"
