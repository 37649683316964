const FolderIconsPersonalFamily = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    xmlns="http://www.w3.org/2000/svg"
    className="reverse"
  >
    <path
      d="M20.4729 9.54769C21.1498 9.39728 21.659 8.83762 21.745 8.14962L21.875 7.10938H31.7188C32.6248 7.10938 33.3594 7.84391 33.3594 8.75V29.5312C33.3594 30.4373 32.6248 31.1719 31.7188 31.1719H19.1406V9.84375L20.4729 9.54769Z"
      className="custom-1"
      stroke="none"
    />
    <path d="M21.3281 15.8594H30.0781" strokeLinecap="round" />
    <path d="M21.3281 20.2344H30.0781" strokeLinecap="round" />
    <path d="M21.3281 24.6094H26.7969" strokeLinecap="round" />
    <path d="M12.8516 7.10938H3.82813C2.31797 7.10938 1.09375 8.3336 1.09375 9.84375V28.4375C1.09375 29.9477 2.31797 31.1719 3.82812 31.1719H30.8984C32.4086 31.1719 33.6328 29.9477 33.6328 28.4375V9.84375C33.6328 8.3336 32.4086 7.10938 30.8984 7.10938H21.875M12.8516 7.10938V5.46875C12.8516 4.56266 13.5861 3.82812 14.4922 3.82812H20.2344C21.1405 3.82812 21.875 4.56266 21.875 5.46875V7.10938M12.8516 7.10938V8.20312C12.8516 9.10922 13.5861 9.84375 14.4922 9.84375H20.2344C21.1405 9.84375 21.875 9.10922 21.875 8.20313V7.10938" />
    <path d="M9.19569 15.1402C8.20641 16.1295 8.23866 17.7431 9.2667 18.6921C10.2103 19.5631 11.6647 19.5631 12.6083 18.6921C13.6363 17.7431 13.6686 16.1295 12.6793 15.1402C11.7173 14.1783 10.1577 14.1783 9.19569 15.1402Z" />
    <path
      d="M5.46875 26.7969L5.56079 25.5083C5.663 24.0774 6.85366 22.9688 8.28822 22.9688H13.4879C14.8486 22.9688 16.0023 23.9693 16.1948 25.3164L16.4062 26.7969"
      strokeLinecap="round"
    />
  </svg>
)
export default FolderIconsPersonalFamily
