import React, { ReactNode, useState } from "react"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import Panel from "../Panel/Panel"
import SuccessIcon from "../../../design-tokens/icons/theme/coop/SuccessIcon"
import classNames from "classnames"
import "./Toast.css"

export enum ToastType {
  INFO = "info",
  SUCCESS = "success",
}

interface ToastProps {
  toastType: ToastType
  title: string
  message: string | ReactNode
}

const ToastHeader: React.FC<{
  toastType: ToastType
  title: string
  forceClose: (value: boolean) => void
}> = ({ toastType, title, forceClose }) => {
  const CrossIcon = useGetIcon("Cross")

  const onClose = () => {
    forceClose(true)
  }

  return (
    <div className="toast-header">
      <h1>
        {toastType === ToastType.SUCCESS && <SuccessIcon />}
        {title}
      </h1>
      <button
        className="close-button"
        aria-label="Close"
        onClick={() => {
          onClose()
        }}
        title="Close"
        type="button"
      >
        <span className=" icon-box">{CrossIcon}</span>
      </button>
    </div>
  )
}

export const Toast: React.FC<ToastProps> = ({ toastType, title, message }) => {
  const [showToast, setShowToast] = useState(true)

  const toastClass = classNames("toast", {
    "toast--info": toastType === ToastType.INFO,
    "toast--success": toastType === ToastType.SUCCESS,
    "fade-in-then-out": true,
  })

  const onForceClose = () => {
    setShowToast(false)
  }

  return (
    <>
      {showToast && (
        <Panel
          className={toastClass}
          role="status"
          aria-label={toastType}
          aria-describedby="alert-info"
        >
          <ToastHeader
            toastType={toastType}
            title={title}
            forceClose={onForceClose}
          />
          <p>{message}</p>
        </Panel>
      )}
    </>
  )
}
