import { TTheme } from "../../design-tokens/types"
import { getButtons } from "../../design-tokens/getters"
import { themesAvailableOn3TierSystem } from "../../design-system/utils/constants"

export const getButtonVariables = (theme: TTheme) => {
  const isThemeOn3TierSystem = themesAvailableOn3TierSystem.find(
    (themeItem) => themeItem === theme.mode
  )
  if (!isThemeOn3TierSystem) {
    const buttons = getButtons(theme)

    // not used in the codebase, not in CO-OP, not transferred - start

    ///////////////////////////////////////////////////////////////////////

    return `
            --button-color-action-background-primary: ${
              buttons.primary.base.background.value ?? "#A9F5DA"
            };
            --button-action-border-primary: ${
              buttons.primary.base.border.value ?? "1px solid transparent"
            };
            --button-action-shadow-primary: ${
              buttons.primary.base.shadow.value ??
              "1px 1px 0 rgba(0, 0, 0, 0.1)"
            };
           --button-color-action-text-primary: ${
             buttons.primary.base.color.value ?? "#323240"
           };
            --button-color-action-background-primary-hover: ${
              buttons.primary.hover.background.value ?? "#54D9AA"
            };
            --button-action-border-primary-hover: ${
              buttons.primary.hover.border.value ?? "1px solid transparent"
            };
            --button-action-shadow-primary-hover: ${
              buttons.primary.hover.shadow.value ??
              "1px 1px 0 rgba(0, 0, 0, 0.1)"
            };
            --button-color-action-text-primary-hover: ${
              buttons.primary.hover.color.value ?? "#323240"
            };
            --button-color-action-background-secondary: ${
              buttons.secondary.base.background.value ?? "#48475C"
            };
            --button-action-border-secondary: ${
              buttons.secondary.base.border.value ?? "1px solid transparent"
            };
            --button-action-shadow-secondary: ${
              buttons.secondary.base.shadow.value ??
              "1px 1px 0 rgba(0, 0, 0, 0.1)"
            };
            --button-color-action-text-secondary: ${
              buttons.secondary.base.color.value ?? "#FFFFFF"
            };
            --button-color-action-background-secondary-hover: ${
              buttons.secondary.hover.background.value ?? "#323240"
            };
            --button-action-border-secondary-hover: ${
              buttons.secondary.hover.border.value ?? "1px solid transparent"
            };
            --button-action-shadow-secondary-hover: ${
              buttons.secondary.hover.shadow.value ??
              "1px 1px 0 rgba(0, 0, 0, 0.1)"
            };
            --button-color-action-text-secondary-hover: ${
              buttons.secondary.hover.color.value ?? "#FFFFFF"
            };
            --button-color-action-background-tertiary: ${
              buttons.tertiary.base.background.value ?? "#FFFFFF"
            };
            --button-action-border-tertiary: ${
              buttons.tertiary.base.border.value ?? "1px solid #48475C"
            };
            --button-action-shadow-tertiary: ${
              buttons.tertiary.base.shadow.value ??
              "1px 1px 0 rgba(0, 0, 0, 0.1)"
            };
             --button-color-action-text-tertiary: ${
               buttons.tertiary.base.color.value ?? "#48475C"
             };
            --button-color-action-background-tertiary-hover: ${
              buttons.tertiary.hover.background.value ?? "#323240"
            };
            --button-action-border-tertiary-hover: ${
              buttons.tertiary.hover.border.value ?? "1px solid #48475C"
            };
            --button-action-shadow-tertiary-hover: ${
              buttons.tertiary.hover.shadow.value ??
              "1px 1px 0 rgba(0, 0, 0, 0.1)"
            };
            --button-color-action-text-tertiary-hover: ${
              buttons.tertiary.hover.color.value ?? "#323240"
            };
            --button-color-action-background-danger: ${
              buttons["danger-primary"].base.background.value ?? "#D82927"
            };
            --button-action-border-danger: ${
              buttons["danger-primary"].base.border.value ?? "1px solid #D82927"
            };
       
            --button-color-action-text-danger: ${
              buttons["danger-primary"].base.color.value ?? "#FFFFFF"
            };
            --button-color-action-background-danger-hover: ${
              buttons["danger-primary"].hover.background.value ?? "#B4201E"
            };
            --button-action-border-danger-hover: ${
              buttons["danger-primary"].hover.border.value ??
              "1px solid #B4201E"
            };
    
            --button-color-action-text-danger-hover: ${
              buttons["danger-primary"].hover.color.value ?? "#FFFFFF"
            };
            --button-color-action-background-remove: ${
              buttons["danger-remove"].base.background.value ?? "#FFFFFF"
            };
            --button-action-border-remove: ${
              buttons["danger-remove"].base.border.value ?? "1px solid #D82927"
            };
      
            --button-color-action-text-remove: ${
              buttons["danger-remove"].base.color.value ?? "#D82927"
            };
            --button-color-action-background-remove-hover: ${
              buttons["danger-remove"].hover.background.value ?? "#B4201E"
            };
            --button-action-border-remove-hover: ${
              buttons["danger-remove"].hover.border.value ?? "1px solid #B4201E"
            };

            --button-color-action-text-remove-hover: ${
              buttons["danger-remove"].hover.color.value ?? "#FFFFFF"
            };
    
        `
  }
}
