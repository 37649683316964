export const Tick = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10196_4006)">
      <path
        d="M20.25 6.75046L9.75 17.25L4.5 12.0005"
        stroke="#282828"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10196_4006">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
