import { FC, ReactElement } from "react"
import "./LinksCollection.css"

export type LinkProps = {
  id: string
  icon?: ReactElement | null
  displayName: string
  href?: string
  onClick?: () => void
  newNotificationValue?: number
}

export interface LinksCollectionProps {
  links: LinkProps[]
  children?: ReactElement
}

export const LinksCollection: FC<LinksCollectionProps> = ({
  links,
  children,
}) => {
  return (
    <div className="links-collection-container">
      {children}
      {links.map((link) => (
        <div
          key={link.id}
          className="link-container"
          role="link"
          aria-label={`Navigate to ${link.displayName}`}
        >
          {link.icon}
          <>
            {!!link.href ? (
              <a href={link.href} className="link" tabIndex={0}>
                {link.displayName}
              </a>
            ) : (
              <button onClick={link.onClick} className="link" tabIndex={0}>
                {link.displayName}
              </button>
            )}

            {link.newNotificationValue ? (
              <span className="notifications-new">
                {link.newNotificationValue}
              </span>
            ) : (
              <></>
            )}
          </>
        </div>
      ))}
    </div>
  )
}
