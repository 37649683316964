const ErrorWarning = () => (
  <svg 
    width="26" 
    height="26" 
    viewBox="0 0 26 26" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
  <path 
    fillRule="evenodd" 
    clipRule="evenodd" 
    d="M13.3045 3.15706L23.7104 21.033C23.829 21.2368 23.6933 21.5286 23.4058 21.5286H2.59417C2.30675 21.5286 2.171 21.2368 2.28963 21.033L12.6955 3.15706C12.8284 2.9286 13.1716 2.9286 13.3045 3.15706ZM11.3991 2.40243C12.1106 1.18014 13.8894 1.18015 14.6009 2.40244L25.0067 20.2784C25.7182 21.5007 24.8288 23.0286 23.4058 23.0286H2.59417C1.17115 23.0286 0.281767 21.5007 0.993277 20.2784L11.3991 2.40243ZM13 8.17143C13.5128 8.17143 14.1143 8.78117 14.1143 9.53333L13.9286 14.981C13.9286 15.7331 13.5128 16.3429 13 16.3429C12.4872 16.3429 12.0714 15.7331 12.0714 14.981L11.8857 9.53333C11.8857 8.78117 12.4872 8.17143 13 8.17143ZM13 19.3143C13.6154 19.3143 14.1143 18.8154 14.1143 18.2C14.1143 17.5846 13.6154 17.0857 13 17.0857C12.3846 17.0857 11.8857 17.5846 11.8857 18.2C11.8857 18.8154 12.3846 19.3143 13 19.3143Z" 
    fill="#CB0202"/>
  </svg>

)
export default ErrorWarning
