import { FC } from "react"

const IconChevronLeftDouble: FC<{ color?: string }> = ({ color }) => (
  <svg
    className="icon-chevron-left-double"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 28L4.24105 16.5653C4.16464 16.4911 4.10401 16.403 4.06265 16.306C4.02129 16.209 4 16.105 4 16C4 15.895 4.02129 15.791 4.06265 15.694C4.10401 15.597 4.16464 15.5089 4.24105 15.4347L16 4"
      stroke={color ?? "#48475C"}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 28L16.2411 16.5653C16.1646 16.4911 16.104 16.403 16.0627 16.306C16.0213 16.209 16 16.105 16 16C16 15.895 16.0213 15.791 16.0627 15.694C16.104 15.597 16.1646 15.5089 16.2411 15.4347L28 4"
      stroke={color ?? "#48475C"}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default IconChevronLeftDouble
