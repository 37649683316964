const UploadDrag = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 25.998H9C8.0073 25.9971 7.02615 25.785 6.12169 25.3758C5.21722 24.9667 4.41013 24.3698 3.75397 23.6249C3.09782 22.88 2.60761 22.004 2.31588 21.0551C2.02416 20.1063 1.93759 19.1062 2.06193 18.1213C2.18627 17.1364 2.51866 16.1892 3.03705 15.3426C3.55544 14.496 4.24798 13.7694 5.06868 13.2109C5.88939 12.6524 6.81949 12.2749 7.79726 12.1033C8.77503 11.9318 9.77811 11.9702 10.7399 12.216"
      fill="none"
    />
    <path
      d="M10 15.998C10.0002 14.4136 10.3768 12.8518 11.0989 11.4415C11.821 10.0312 12.868 8.81262 14.1534 7.88625C15.4388 6.95988 16.926 6.35222 18.4923 6.11333C20.0587 5.87445 21.6594 6.01118 23.1625 6.51227C24.6656 7.01335 26.0282 7.86445 27.1379 8.99541C28.2476 10.1264 29.0726 11.5048 29.5451 13.0172C30.0175 14.5296 30.1239 16.1326 29.8553 17.6941C29.5867 19.2557 28.9509 20.731 28.0003 21.9986"
      fill="none"
    />
    <path d="M14.7574 20.2407L19 15.998L23.2426 20.2407" fill="none" />
    <path d="M19 25.998V15.998" fill="none" />
  </svg>
)
export default UploadDrag
