export function checkForParamInUrlSearchParams(
  params: URLSearchParams,
  paramName: string
) {
  const value = params.get(paramName.toLowerCase())
  if (value) {
    return value
  }
  return undefined
}
