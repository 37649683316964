export enum DateTimeFormat {
  shortMonthAndYearWithSlashes = "MM/YY",
  shortWithSlashes = "DD/MM/YY",
  shortWithSpaces = "DD MM YY",
  shortWithSlashesAndTime = "DD/MM/YY HH:mm",
  shortWithSpacesAndTime = "DD MM YY HH:mm",
  longWithSlashes = "DD/MM/YYYY",
  longWithSpaces = "DD MM YYYY",
  longWithDashesYearMonthDay = "YYYY-MM-DD",
  longWithSlashesAndTime = "DD/MM/YYYY HH:mm",
  longWithSpacesAndTime = "DD MM YYYY HH:mm",
  longWithDashesAndTimeAndSeconds = "DD-MM-YYYY HH-mm-ss",
  timeWithDashes = "HH-mm-ss",
}
