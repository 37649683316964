const Tools = () => (
  <svg width="24" height="24" version="1.1">
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-19.000000, -122.000000)" className="rn-iconfill">
        <g transform="translate(19.536500, 122.000000)">
          <path d="M11.5448444,8.80546537 L9.27353655,6.55353761 L8.74968931,6.03625541 C9.08368258,5.18777338 9.13888516,4.2606854 8.91408407,3.38230676 C8.7205179,2.62597419 8.31935248,1.90575551 7.70981344,1.30292884 C6.32591849,-0.0700081169 4.25007606,-0.342261905 2.58862026,0.412270022 L5.56360348,3.36038961 L3.48776105,5.41785038 L0.442410294,2.46973079 C-0.382453647,4.11492154 -0.0384345626,6.17238231 1.34546039,7.54531926 C2.6589878,8.84824811 4.52763692,9.19051001 6.11872518,8.57599432 L6.6816655,9.13216991 L2.56907372,13.2237554 C2.27196633,13.4454478 2.27196633,13.96273 2.64335057,14.2583198 L4.35562737,15.9579613 C4.65273477,16.2535511 5.10230516,16.2535511 5.39941255,15.9579613 L9.15625732,11.5863433 L13.5894123,15.977408 C13.8669732,16.2535511 14.2813598,16.2535511 14.5589207,15.977408 L16.1500089,14.398336 C16.4979373,14.1221929 16.4979373,13.6399148 16.2203765,13.4337798 L11.5448444,8.80546537 Z" />
          <path d="M17.7614725,6.55742695 L16.4806422,5.23116207 C16.398966,5.14559659 16.2653142,5.14559659 16.183638,5.23116207 L16.0462736,5.37506764 L15.3594516,4.76055195 C15.3594516,4.76055195 15.4040022,4.37161797 15.1775365,4.0332454 C14.9473583,3.69487284 14.5798157,3.26315611 14.3162245,2.98701299 C14.0526334,2.7147592 13.0502444,1.66852679 11.728576,0.987892316 C10.4031951,0.299479167 9.46763209,1.42108547e-14 8.35386659,1.42108547e-14 L8.35386659,1.15513393 C8.35386659,1.15513393 9.41936892,1.80076434 10.0282274,2.31026786 C10.6333733,2.81977137 10.6519361,4.62442505 10.6519361,4.62442505 L9.59385884,5.7328869 L11.6914505,7.91480655 L12.8423415,6.50297619 C13.3212607,6.3668493 13.7259288,6.35518128 13.9635321,6.49130817 L14.4721517,7.00859037 L14.1157468,7.37807765 C14.0340706,7.46364313 14.0340706,7.59977002 14.1157468,7.6853355 L15.3965771,9.01160038 C15.4782532,9.09716585 15.6119051,9.09716585 15.6935812,9.01160038 L17.7614725,6.86468479 C17.8394361,6.77911932 17.8394361,6.63910308 17.7614725,6.55742695 Z" />
        </g>
      </g>
    </g>
  </svg>
)

export default Tools
