import { IFolder } from "../api-types"
export const findFolderByFileId = (
  fileId: string,
  folders: IFolder[]
): IFolder | undefined => {
  let found = folders.find((folder) =>
    folder.contents?.find((file) => file.id === fileId)
  )
  if (!found) {
    for (const folder of folders) {
      if (folder.childFolders && folder.childFolders.length > 0) {
        const foundChild = findFolderByFileId(fileId, folder.childFolders)
        if (foundChild) {
          found = foundChild
          break
        }
      }
    }
  }
  return found
}
