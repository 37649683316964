import { usePlatformContext } from "../../../contexts/platform/platformContextProvider"
import { Modal, ModalContent, ModalHeader } from "../../modules/Modal"

export const SessionUnavailableModal = () => {
  const { themeName } = usePlatformContext()

  // using includes instead of === to include other ABRDN brands e.g. ABRDN_LUDLOW
  const isAbrdnTheme = themeName?.includes("ABRDN") ?? false
  const messageText = isAbrdnTheme
    ? "Documents can only be accessed directly from the platform."
    : "Documents can not be accessed. Please close this tab and log back in to the platform"
  return (
    <Modal name={"Session unavailable"} hasCloseButton={false}>
      <ModalHeader>Session Unavailable</ModalHeader>
      <ModalContent>{messageText}</ModalContent>
    </Modal>
  )
}
