import { TTheme } from "../../design-tokens/types"
import { getShadow } from "../../design-tokens/getters"

export const getShadowVariables = (theme: TTheme) => {
  const shadows = getShadow(theme)

  return `
        --shadow-0: ${
          shadows.shadows["0"]?.value || "1px 1px 0 rgba(0, 0, 0, 0.1)"
        };
        --shadow-1: ${
          shadows.shadows["1"]?.value || "2px 2px 0 rgba(0, 0, 0, 0.2)"
        };
        --shadow-2: ${
          shadows.shadows["2"]?.value || "0 2px 5px rgba(22, 22, 22, 0.25)"
        };
        --outline-0: ${
          shadows.outlines["0"]?.value || "1px 1px 0 rgba(0, 0, 0, 0.1)"
        };
        --outline-1: ${
          shadows.outlines["1"]?.value || "0px 0px 0px 1px #749BFF"
        };
    `
}
