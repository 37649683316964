const FolderIconsPropertyVehicles = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    className="reverse"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25393 25.8164L11.5788 15.4916C11.6923 15.378 11.7504 15.221 11.7419 15.0606C11.683 13.9494 11.5681 9.44883 13.3984 7.38282C15.6163 4.87936 19.1406 4.25956 21.6016 4.92188C23.5156 5.19532 25.1562 6.01563 26.7969 8.47657C28.4195 10.9106 28.7048 14.4145 27.3881 17.401C27.3592 17.4665 27.3166 17.5255 27.2644 17.5744C25.2847 19.428 23.7321 20.7862 21.6016 21.0547H18.2567C18.1216 21.0547 17.9913 21.1047 17.8909 21.1951L12.7341 25.8362C12.6337 25.9266 12.5034 25.9766 12.3683 25.9766H10.1172C9.81516 25.9766 9.57031 26.2214 9.57031 26.5234V28.4375C9.57031 28.7395 9.32547 28.9844 9.02344 28.9844H7.10938C6.80734 28.9844 6.5625 29.2292 6.5625 29.5313V31.1719C6.5625 31.4739 6.31766 31.7188 6.01562 31.7188H1.64062C1.33859 31.7188 1.09375 31.4739 1.09375 31.1719V26.2031C1.09375 26.0581 1.15137 25.919 1.25393 25.8164Z"
      className="custom-1"
    />
    <path
      d="M21.6016 21.0547C20.4741 21.0547 18.8333 21.0547 18.2567 21.0547C18.1216 21.0547 17.9913 21.1047 17.8909 21.1951L12.7341 25.8362C12.6337 25.9266 12.5034 25.9766 12.3683 25.9766H10.1172C9.81516 25.9766 9.57031 26.2214 9.57031 26.5234V28.4375C9.57031 28.7395 9.32547 28.9844 9.02344 28.9844H7.10938C6.80734 28.9844 6.5625 29.2292 6.5625 29.5313V31.1719C6.5625 31.4739 6.31766 31.7188 6.01562 31.7188H1.64063C1.33859 31.7188 1.09375 31.4739 1.09375 31.1719V26.2031C1.09375 26.058 1.15137 25.9189 1.25393 25.8164L11.5788 15.4915C11.6923 15.378 11.7504 15.221 11.7419 15.0606C11.683 13.9494 11.5681 9.44883 13.3984 7.38281C15.6163 4.87936 19.1406 4.25956 21.6016 4.92188M21.6016 21.0547C21.6016 24.082 21.6016 28.3958 21.6016 31.1734C21.6016 32.6836 22.8258 33.9063 24.3359 33.9063H31.4453C32.9555 33.9063 34.1797 32.682 34.1797 31.1719V21.0005C34.1797 20.3309 33.9339 19.6845 33.489 19.1839L31.9922 17.5M21.6016 21.0547C23.7605 20.7827 25.326 19.3916 27.3438 17.5M31.9922 17.5V4.47654C31.9922 4.40942 31.9802 4.34351 31.953 4.28212C31.7055 3.72218 30.2557 0.820313 26.7969 0.820312C23.3958 0.820312 21.9372 3.19453 21.6541 3.7242C21.6173 3.79305 21.6016 3.86826 21.6016 3.94633C21.6016 4.15708 21.6016 4.60527 21.6016 4.92188M31.9922 17.5C31.9922 17.5 29.1591 17.5 27.3438 17.5M21.6016 4.92188C23.5156 5.19531 25.1562 6.01562 26.7969 8.47656C28.4375 10.9375 28.7109 14.4922 27.3438 17.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5859 17.2266L10.9375 21.6016M25.4297 29.8047C25.6484 29.8047 28.6198 29.8047 30.0781 29.8047M21.3281 9.29688C22.5362 9.29688 23.5156 10.2763 23.5156 11.4844C23.5156 12.6925 22.5362 13.6719 21.3281 13.6719C20.12 13.6719 19.1406 12.6925 19.1406 11.4844C19.1406 10.2763 20.12 9.29688 21.3281 9.29688Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FolderIconsPropertyVehicles
