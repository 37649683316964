export enum LogoVariant {
  WITH_NAME_GREY = "with-name-grey",
  WITH_NAME_DEFAULT = "with-name-default",
  ICON_ONLY_DEFAULT = "icon-only-default",
  ICON_ONLY_DARK = "icon-only-dark",
}

const FortCirrusFooterLogo = ({
  variant = LogoVariant.WITH_NAME_DEFAULT,
}: {
  variant?: LogoVariant
}) => {
  if (
    variant === LogoVariant.ICON_ONLY_DEFAULT ||
    variant === LogoVariant.ICON_ONLY_DARK
  ) {
    return (
      <div className="icon-box" data-testid="legado-brand-logo-svg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="26"
          viewBox="0 0 34 26"
          fill="none"
        >
          <path
            d="M0 24.9222V1.10644H12.2001V3.80947H3.32396V11.2976H12.2001V14.0371H3.32396V24.9222H0Z"
            fill="#C4C4C4"
          />
          <path
            d="M27.575 0.538086C28.4408 0.538086 29.261 0.595045 30.0356 0.708963C30.8103 0.822882 31.4938 0.9368 32.0862 1.05072C32.7697 1.21021 33.4076 1.38108 34 1.56335V4.46827C32.9747 4.19487 31.9153 3.97842 30.8217 3.81894C29.7281 3.63667 28.6572 3.57971 27.6092 3.64806C26.5611 3.69363 25.5586 3.88729 24.6017 4.22904C23.6676 4.5708 22.8474 5.10622 22.1411 5.83529C21.4348 6.54159 20.8652 7.47572 20.4323 8.63769C20.0222 9.77687 19.8171 11.2009 19.8171 12.9096C19.8171 14.6184 20.0222 16.0538 20.4323 17.2158C20.8652 18.3777 21.4348 19.3232 22.1411 20.0523C22.8474 20.7586 23.6676 21.2826 24.6017 21.6244C25.5586 21.9434 26.5611 22.1256 27.6092 22.1712C28.6572 22.2168 29.7281 22.1598 30.8217 22.0003C31.9153 21.8408 32.9747 21.613 34 21.3168V24.1192C33.4076 24.347 32.7697 24.5407 32.0862 24.7002C31.4938 24.8369 30.8103 24.9622 30.0356 25.0761C29.261 25.19 28.4408 25.247 27.575 25.247C26.8003 25.247 25.9801 25.1673 25.1144 25.0078C24.2486 24.8711 23.3942 24.6318 22.5512 24.2901C21.7082 23.9256 20.8994 23.4471 20.1247 22.8547C19.3729 22.2396 18.7007 21.4763 18.1084 20.565C17.516 19.6536 17.0489 18.5714 16.7072 17.3183C16.3654 16.0424 16.1945 14.5728 16.1945 12.9096C16.1945 10.4262 16.5705 8.38707 17.3223 6.79221C18.0742 5.17457 19.0083 3.91007 20.1247 2.99872C21.2639 2.06459 22.4942 1.42665 23.8157 1.08489C25.1599 0.720355 26.413 0.538086 27.575 0.538086Z"
            fill="#C4C4C4"
          />
        </svg>
      </div>
    )
  }
  return (
    <div className="icon-box" data-testid="legado-brand-logo-svg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="26"
        viewBox="0 0 34 26"
        fill="none"
      >
        <path
          d="M0 24.9222V1.10644H12.2001V3.80947H3.32396V11.2976H12.2001V14.0371H3.32396V24.9222H0Z"
          fill="#C4C4C4"
        />
        <path
          d="M27.575 0.538086C28.4408 0.538086 29.261 0.595045 30.0356 0.708963C30.8103 0.822882 31.4938 0.9368 32.0862 1.05072C32.7697 1.21021 33.4076 1.38108 34 1.56335V4.46827C32.9747 4.19487 31.9153 3.97842 30.8217 3.81894C29.7281 3.63667 28.6572 3.57971 27.6092 3.64806C26.5611 3.69363 25.5586 3.88729 24.6017 4.22904C23.6676 4.5708 22.8474 5.10622 22.1411 5.83529C21.4348 6.54159 20.8652 7.47572 20.4323 8.63769C20.0222 9.77687 19.8171 11.2009 19.8171 12.9096C19.8171 14.6184 20.0222 16.0538 20.4323 17.2158C20.8652 18.3777 21.4348 19.3232 22.1411 20.0523C22.8474 20.7586 23.6676 21.2826 24.6017 21.6244C25.5586 21.9434 26.5611 22.1256 27.6092 22.1712C28.6572 22.2168 29.7281 22.1598 30.8217 22.0003C31.9153 21.8408 32.9747 21.613 34 21.3168V24.1192C33.4076 24.347 32.7697 24.5407 32.0862 24.7002C31.4938 24.8369 30.8103 24.9622 30.0356 25.0761C29.261 25.19 28.4408 25.247 27.575 25.247C26.8003 25.247 25.9801 25.1673 25.1144 25.0078C24.2486 24.8711 23.3942 24.6318 22.5512 24.2901C21.7082 23.9256 20.8994 23.4471 20.1247 22.8547C19.3729 22.2396 18.7007 21.4763 18.1084 20.565C17.516 19.6536 17.0489 18.5714 16.7072 17.3183C16.3654 16.0424 16.1945 14.5728 16.1945 12.9096C16.1945 10.4262 16.5705 8.38707 17.3223 6.79221C18.0742 5.17457 19.0083 3.91007 20.1247 2.99872C21.2639 2.06459 22.4942 1.42665 23.8157 1.08489C25.1599 0.720355 26.413 0.538086 27.575 0.538086Z"
          fill="#C4C4C4"
        />
      </svg>
    </div>
  )
}

export default FortCirrusFooterLogo
