import { IFolder } from "../api-types"

export function flattenFolderTree(tree?: IFolder[]) {
  if (!tree) return []
  var flatList = [] as IFolder[]
  tree.forEach((t: IFolder) => {
    if (t.childFolders) {
      flatList = flatList.concat(flattenFolderTree(t.childFolders))
    }
    flatList.push(t)
  })
  return flatList
}
