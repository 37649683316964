import { ReactElement } from "react"

import ArrowDown from "./ArrowDown"
import ArrowLeft from "./ArrowLeft"
import ArrowRight from "./ArrowRight"
import ArrowSpendingDown from "./ArrowSpendingDown"
import ArrowSpendingUp from "./ArrowSpendingUp"
import ArrowUp from "./ArrowUp"
import Bin from "./Bin"
import CheckboxActive from "./CheckboxActive"
import CheckboxInactive from "./CheckboxInactive"
import ChevronDoubleLeft from "./ChevronDoubleLeft"
import ChevronDoubleRight from "./ChevronDoubleRight"
import IconChevronDown from "./IconChevronDown"
import IconChevronLeft from "./IconChevronLeft"
import IconChevronRight from "./IconChevronRight"
import IconChevronUp from "./IconChevronUp"
import Cross from "./Cross"
import Download from "./Download"
import ExternalLink from "./ExternalLink"
import Filter from "./Filter"
import Info from "./Info"
import Literature from "./Literature"
import Minus from "./Minus"
import Notification from "./Notification"
import NotificationNew from "./NotificationNew"
import NotificationsError from "./NotificationsError"
import NotificationsGuide from "./NotificationsGuide"
import NotificationsInfo from "./NotificationsInfo"
import NotificationsSuccess from "./NotificationsSuccess"
import NotificationsWarning from "./NotificationsWarning"
import Plus from "./Plus"
import RadioActive from "./RadioActive"
import RadioInactive from "./RadioInactive"
import Search from "./Search"
import Success from "./Success"
import Tick from "./Tick"
import Upload from "./Upload"

export const GetIcon = {
  ArrowDown: <ArrowDown />,
  ArrowLeft: <ArrowLeft />,
  ArrowRight: <ArrowRight />,
  ArrowSpendingDown: <ArrowSpendingDown />,
  ArrowSpendingUp: <ArrowSpendingUp />,
  ArrowUp: <ArrowUp />,
  Bin: <Bin />,
  CheckboxActive: <CheckboxActive />,
  CheckboxInactive: <CheckboxInactive />,
  ChevronDoubleLeft: <ChevronDoubleLeft />,
  ChevronDoubleRight: <ChevronDoubleRight />,
  IconChevronDown: <IconChevronDown />,
  IconChevronLeft: <IconChevronLeft />,
  IconChevronRight: <IconChevronRight />,
  IconChevronUp: <IconChevronUp />,
  Cross: <Cross />,
  Download: <Download />,
  ExternalLink: <ExternalLink />,
  Filter: <Filter />,
  Info: <Info />,
  Literature: <Literature />,
  Minus: <Minus />,
  NotificationNew: <NotificationNew />,
  NotificationsError: <NotificationsError />,
  NotificationsGuide: <NotificationsGuide />,
  NotificationsIcon: <Notification />,
  NotificationsInfo: <NotificationsInfo />,
  NotificationsSuccess: <NotificationsSuccess />,
  NotificationsWarning: <NotificationsWarning />,
  Plus: <Plus />,
  RadioActive: <RadioActive />,
  RadioInactive: <RadioInactive />,
  Search: <Search />,
  Success: <Success />,
  Tick: <Tick />,
  Upload: <Upload />,
} as { [key: string]: ReactElement }

export default GetIcon
