import { AxiosError } from "axios"
import { useState } from "react"

import { sendResetLink } from "../../../api/lib/auth/auth"
import CoopLogo from "../../../design-tokens/imgs/coop/CoopLogo"
import { LoginSteps } from "../../../pages/LoginPage/LoginPage"
import {
  InputFieldType,
  validateInput,
} from "../../../utils/forms/validateInputs"
import { Button } from "../../atoms/Button"
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner"
import { TextInputWithValidation } from "../../atoms/TextInput/TextInputWithValidation"
import AlertMessage, {
  MessageType,
} from "../../modules/AlertMessage/AlertMessage"
import { AlertMessageItem } from "../../modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"
import ForgotPasswordConfirmation from "./ForgotPasswordConfirmation"

import "./ForgotPassword.css"

const ForgotPassword = ({
  setLoginStep,
  labelTitle,
}: {
  setLoginStep?: (step: LoginSteps) => void
  labelTitle: string
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState<string>("")
  const [errors, setErrors] = useState<AlertMessageItem[]>([])
  const [shouldDisplayConfirmation, setShouldDisplayConfirmation] =
    useState(false)

  const handleSendResetLink = async (emailParam: string) => {
    setErrors([])
    setIsSubmitting(true)
    const emailErrorMessage = validateInput({
      type: InputFieldType.SIMPLE_EMAIL,
      value: emailParam,
    })

    if (emailErrorMessage) {
      setErrors([
        {
          id: 0,
          message: emailErrorMessage,
          href: "#",
        },
      ])
      setIsSubmitting(false)
    } else {
      try {
        await sendResetLink({ email: emailParam })
        setShouldDisplayConfirmation(true)
      } catch (error) {
        const axiosError = error as AxiosError
        if (
          axiosError.response?.status &&
          axiosError.response.status >= 500 &&
          axiosError.response.status < 600
        ) {
          setErrors([
            {
              id: 1,
              message:
                "Something went wrong on our end. Please try again later.",
              href: "#",
            },
          ])
        } else {
          setShouldDisplayConfirmation(true)
        }
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  const handleReturnClick = () => {
    setLoginStep && setLoginStep(LoginSteps.SignIn)
  }

  return (
    <>
      <div className="forgot-password-panel">
        <CoopLogo />
        {shouldDisplayConfirmation && !isSubmitting ? (
          <ForgotPasswordConfirmation
            email={email}
            setShouldDisplayConfirmation={setShouldDisplayConfirmation}
            setErrors={setErrors}
          />
        ) : (
          <>
            <div>
              {errors.length > 0 && (
                <AlertMessage
                  title="There's a problem"
                  messageType={MessageType.ERROR}
                  message="Check the form.  You must:"
                  alertItems={errors}
                />
              )}
              <h1 className="forgot-password-panel__header">{labelTitle}</h1>
            </div>
            <>
              <div className="forgot-password-panel__content">
                <span className="sub-text">
                  Enter your email and we'll send you a link to reset your
                  password.
                </span>
                <div className="forgot-password-input-container">
                  <TextInputWithValidation
                    id="forgot-password-email-field"
                    name="E-mail"
                    type={InputFieldType.EMAIL}
                    value={email}
                    onChange={(newEmail) => {
                      setEmail(newEmail)
                    }}
                    placeholder="jane@email.com"
                    label="Email"
                    displayError={errors.length > 0 && errors[0].id === 0}
                    shouldShowWarningIcon={false}
                    errorOnSubmit={{
                      message: errors.length > 0 ? errors[0].message : "",
                      hasError: errors.length > 0 && errors[0].id === 0,
                    }}
                    validateOnSubmit
                    handleSubmit={() => {
                      handleSendResetLink(email)
                    }}
                  />
                  <div
                    data-testid="forgot-password-btn-container"
                    className="forgot-password-btn-container"
                  >
                    <Button
                      onClick={() => {
                        handleSendResetLink(email)
                      }}
                      className="forgot-password-btn"
                      isDisabled={isSubmitting}
                    >
                      {!isSubmitting && "Send reset link"}
                      {isSubmitting && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span className="mr-1">
                            {"Sending reset link..."}
                          </span>
                          <LoadingSpinner
                            size="20px"
                            thickness="2px"
                            color="var(--color-universal-secondary-e)"
                          />
                        </div>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="forgot-password-panel__footer">
                <hr />
                <Button onClick={handleReturnClick}>Return to sign in</Button>
              </div>
            </>
          </>
        )}
      </div>
    </>
  )
}

export default ForgotPassword
