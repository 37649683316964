const QuestionMarkCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
  >
    <circle cx="15.5" cy="15.5" r="15.5" fill="#00729A" />
    <text
      x="50%"
      y="55%"
      textAnchor="middle"
      alignmentBaseline="middle"
      fill="white"
      fontSize="16px"
      fontFamily="Avenir Next"
      fontWeight="600"
      line-height="24px"
      style={{ wordWrap: "break-word" }}
    >
      ?
    </text>
  </svg>
)
export default QuestionMarkCircle
