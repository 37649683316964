import { useEffect, useState } from "react"
import { IFile } from "../api/api-client/api-types"
import { getThumbnailForFile } from "../api/api-client/api-handler"
import { useUserContext } from "../contexts/users"
import { setThumbnail, useThumbnailContext } from "../contexts/thumbnails"
import { UserModuleType } from "../contexts/application/constants"

export const useThumbnails = ({
  file,
  isFullSizeThumbnail = false,
  shouldRefresh = false,
}: {
  file?: IFile | null
  isFullSizeThumbnail?: boolean
  shouldRefresh?: boolean
}) => {
  const [base64ThumbnailString, setBase64ThumbnailString] = useState(
    file?.base64StringThumbnail
  )
  const {
    userState: { currentUser },
  } = useUserContext()

  const {
    thumbnailState: { thumbnails },
    dispatch,
  } = useThumbnailContext()

  // TODO: We need to refactor and remove this useEffect hook
  // there's a circular dependency between this useEffect and the thumbnails context
  // we try and cover it up with the if statements but this will come back to haunt us
  // Ideally we should consolidate the context and custom hook into one place https://dev.azure.com/secure-the-file/Application/_workitems/edit/15791
  useEffect(() => {
    const setOrUpdateThumbnail = (thumbnail: string, nodeId: string) => {
      setBase64ThumbnailString(thumbnail)
      dispatch(setThumbnail({ id: nodeId, thumbnail }))
    }

    if (!currentUser?.modules?.includes(UserModuleType.THUMBNAILS)) {
      return
    }

    if (!file || file === null) {
      return
    }

    if (!file.hasThumbnail || file.isThumbnailLoading) {
      return
    }

    if (base64ThumbnailString && base64ThumbnailString.length > 0) {
      return
    }

    const nodeId = file.id
    if (nodeId && nodeId.trim().length <= 0) return

    const existingThumbnailInState = thumbnails?.find((x) => x.id === file.id)

    if (existingThumbnailInState && !shouldRefresh) {
      setBase64ThumbnailString(existingThumbnailInState.thumbnail)
      return
    } else if (
      file.base64StringThumbnail &&
      file.base64StringThumbnail.length > 0
    ) {
      setBase64ThumbnailString(file.base64StringThumbnail)
      dispatch(
        setThumbnail({ id: file.id, thumbnail: file.base64StringThumbnail })
      )
      return
    } else {
      const getThumbnail = async () => {
        const response = await getThumbnailForFile({
          fileId: nodeId,
          isFullSizeThumbnail: isFullSizeThumbnail,
        })
        // We should not have an if statement here
        // some of the thumbnails will be empty but that's okay
        // because it stops is spamming the backend with failed thumbnail requests
        setOrUpdateThumbnail(response, nodeId)
      }
      getThumbnail()
    }
  }, [
    file,
    base64ThumbnailString,
    currentUser?.modules,
    isFullSizeThumbnail,
    shouldRefresh,
    dispatch,
    thumbnails,
  ])

  return {
    base64ThumbnailString,
  }
}
