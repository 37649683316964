import { FC } from "react"
const IconChevronLeft: FC<{ color?: string }> = ({ color }) => (
  <svg
    className="icon-chevron-left"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 28L10.2411 16.5653C10.1646 16.4911 10.104 16.403 10.0627 16.306C10.0213 16.209 10 16.105 10 16C10 15.895 10.0213 15.791 10.0627 15.694C10.104 15.597 10.1646 15.5089 10.2411 15.4347L22 4"
      fill="none"
      stroke={color ?? "#48475C"}
    />
  </svg>
)

export default IconChevronLeft
