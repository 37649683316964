import { ActivityLogEntryDto } from "legado-generated-api-client/models";
import { getUserId } from "./getUser";
const activities = require("../staticData/getRecentActivities.json");

type TObj = { [key: string]: any };

export const isActivities = (activities: any) => {
  if ((activities as ActivityLogEntryDto[])?.length) {
    return activities as ActivityLogEntryDto[];
  }
};

type TGetRecentChanges = (userEmail?: string) => ActivityLogEntryDto[];
export const getRecentChanges: TGetRecentChanges = (userEmail) => {
  const currentUserId = getUserId(userEmail)
  const activitiesObj = activities as TObj;
  const currentUserActivities = activitiesObj[currentUserId];

  return isActivities(currentUserActivities) || [];
};
