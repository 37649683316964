import { baseUrl, httpClient } from "../httpClient"

export const postSendEmailReminderForFile = async ({
  fileId,
  targetUserId,
}: {
  fileId: string
  targetUserId: string
}): Promise<string> => {
  const response = await httpClient(`${baseUrl}/api/Reminder/send`, {
    method: "POST",
    params: {
      fileId,
      targetUserId,
    },
  })

  return response.data
}
