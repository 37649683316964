import { createContext, FC, useReducer, Dispatch, useContext } from "react"
import {
  ISearchState,
  searchReducer,
  initialState,
  ISearchAction,
} from "./reducer"

export const SearchContext = createContext(
  {} as {
    searchState: ISearchState
    dispatch: Dispatch<ISearchAction>
  }
)

export const SearchProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [searchState, dispatch] = useReducer(searchReducer, initialState)

  const value = { searchState, dispatch }
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export const useSearchContext = () => useContext(SearchContext)

export * from "./actions"
