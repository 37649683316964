import { ThemeNames } from "../../contexts/application/constants"
import animation from "../tokens/lifestage/animation.json"
import breakpoints from "../tokens/lifestage/breakpoints.json"
import buttons from "../tokens/lifestage/buttons.json"
import colors from "../tokens/lifestage/colors.json"
import iconsizing from "../tokens/lifestage/iconsizing.json"
import overlay from "../tokens/lifestage/overlay.json"
import radius from "../tokens/lifestage/radius.json"
import shadows from "../tokens/lifestage/shadows.json"
import styling from "../tokens/lifestage/styling.json"
import typeface from "../tokens/lifestage/typeface.json"
import typography from "../tokens/lifestage/typography.json"
import { TTheme } from "../types"

const tokens = {
  mode: ThemeNames.LIFESTAGE,
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  styling,
  typeface,
  typography,
} as TTheme
export default tokens
