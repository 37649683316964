import { IFolder } from "../../api/api-client/api-types"

export const getFoldersWithSameLevel0Parent = (folders: IFolder[]) => {
  //For speed, I've created 2 of these functions but I plan to refactor it so this one does it all
  const foldersWithSameLevel0Parent: { [sectionName: string]: IFolder[] } = {}
  folders.forEach((folder) => {
    if (folder.childFolders && folder.childFolders?.length > 0) {
      foldersWithSameLevel0Parent[folder.name] = folder.childFolders
    } else if (folder.contents && folder.contents.length > 0) {
      foldersWithSameLevel0Parent[folder.name] = [folder]
    }
    if (folder.level > 0) {
      foldersWithSameLevel0Parent["no-section"] = [
        ...(foldersWithSameLevel0Parent["no-section"] ?? []),
        folder,
      ]
    }
  })
  return foldersWithSameLevel0Parent
}

export const hasEditOrReadOnlyFilesOrEditChildfolders = (folder: IFolder) => {
  if (folder.isEditable) {
    return true
  }
  if (folder.childFolders && folder.childFolders.find((f) => f.isEditable)) {
    return true
  }
  if (folder.contents && folder.contents.length > 0) {
    return true
  }
  return false
}

export const getFoldersForOneSectionOnly = (
  folders: IFolder[],
  selectedFolderId: String
) => {
  let folderId = selectedFolderId
  if (selectedFolderId === "") {
    if (folders[0]) {
      folderId = folders[0].id
    }
  }

  const folderFiltered = folders.filter((obj) => {
    return obj.id === folderId
  })

  const foldersWithSameLevel0Parent: { [sectionName: string]: IFolder[] } = {}

  if (folderFiltered.length > 0) {
    const folderToUse = folderFiltered[0]
    if (folderToUse.childFolders && folderToUse.childFolders?.length > 0) {
      foldersWithSameLevel0Parent[folderToUse.name] = folderToUse.childFolders
    } else if (folderToUse.contents && folderToUse.contents.length > 0) {
      foldersWithSameLevel0Parent[folderToUse.name] = [folderToUse]
    }
    if (folderToUse.level > 0) {
      foldersWithSameLevel0Parent["no-section"] = [
        ...(foldersWithSameLevel0Parent["no-section"] ?? []),
        folderToUse,
      ]
    }
  }
  return foldersWithSameLevel0Parent
}

export const isFolderInFolder = (folder: IFolder, folderToFindId: string) => {
  if (folder.childFolders && folder.childFolders.length > 0) {
    for (let i = 0; i < folder.childFolders.length; i++) {
      const childFolder = folder.childFolders[i]
      if (childFolder.id === folderToFindId) {
        return true
      } else {
        if (isFolderInFolder(childFolder, folderToFindId)) {
          return true
        }
      }
    }
  }

  return false
}

export const findIndexOfFirstFolderWithContents = (folders: IFolder[]) => {
  let index = folders.findIndex(
    (folder) => folder.contents && folder.contents?.length > 0
  )
  if (index === -1) {
    folders.forEach((folder) => {
      if (folder.childFolders && folder.childFolders.length > 0) {
        index = findIndexOfFirstFolderWithContents(folder.childFolders)
      }
    })
  }
  return index
}
