const Minus = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66669 16C3.66669 15.2636 4.26364 14.6667 5.00002 14.6667H27C27.7364 14.6667 28.3334 15.2636 28.3334 16C28.3334 16.7364 27.7364 17.3333 27 17.3333H5.00002C4.26364 17.3333 3.66669 16.7364 3.66669 16Z"
      stroke="none"
    />
  </svg>
)
export default Minus
