import { useEffect } from "react"

export const useKeyboard = (key: string, onClick: () => void) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === key) {
        event.preventDefault()
        onClick()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [key, onClick])
}
