import { useEffect, useState, useCallback } from "react"
import {
  resetPassword,
  setPasswordForUser,
} from "../../../../api/lib/auth/auth"
import { getPasswordErrorKey } from "../../utils/getPasswordErrorKey"
import { PasswordSetupSteps } from "../../../../contexts/application/constants"
import {
  PasswordSetupError,
  validatePasswordSetup,
} from "../../utils/validatePassword"
import { AxiosError } from "axios"

export const useRegisterOrResetPassword = ({
  password,
  confirmPassword,
  isAssistanceRequired,
  additionalAssistanceText,
  isTermsAndConditionsChecked,
  activationCode,
  passwordSetupStep,
  setIsTermsAndConditionsError,
  onRegister,
  onReset,
}: {
  password: string
  confirmPassword: string
  isAssistanceRequired: boolean
  additionalAssistanceText: string
  isTermsAndConditionsChecked: boolean
  activationCode: string
  passwordSetupStep: string
  setIsTermsAndConditionsError: (isTermsAndConditionsError: boolean) => void
  onRegister?: (details: {
    password: string
    additionalAssistanceText: string
  }) => void
  onReset?: (details: { password: string }) => void
}) => {
  const ADDITIONAL_ASSISTANCE_MAX_LENGTH = 500

  const [isValidating, setIsValidating] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorsPassword, setErrorsPassword] = useState<PasswordSetupError[]>([])
  const [errorConfirmPasswordMessage, setErrorConfirmPasswordMessage] =
    useState<string | undefined>(undefined)
  const [isRequiredAssistanceError, setIsRequiredAssistanceError] =
    useState<boolean>(false)
  const [isCharacterLimitError, setIsCharacterLimitError] =
    useState<boolean>(false)

  const [hasUnexpectedError, setHasUnexpectedError] = useState(false)
  const [hasUserLockedOutError, setHasUserLockedOutError] = useState(false)

  const validatePasswords = useCallback((): boolean => {
    const passwordErrorMessages = validatePasswordSetup(password)
    setErrorsPassword(passwordErrorMessages)

    setErrorConfirmPasswordMessage(
      password !== confirmPassword ? "Passwords must match" : undefined
    )

    return passwordErrorMessages.length === 0 && password === confirmPassword
  }, [
    password,
    confirmPassword,
    setErrorsPassword,
    setErrorConfirmPasswordMessage,
  ])

  const validateAdditionalAssistanceInput = useCallback((): boolean => {
    if (isAssistanceRequired) {
      setIsRequiredAssistanceError(additionalAssistanceText.length === 0)
      setIsCharacterLimitError(
        additionalAssistanceText.length > ADDITIONAL_ASSISTANCE_MAX_LENGTH
      )
      return (
        additionalAssistanceText.length > 0 &&
        additionalAssistanceText.length <= ADDITIONAL_ASSISTANCE_MAX_LENGTH
      )
    }

    setIsRequiredAssistanceError(false)
    setIsCharacterLimitError(false)
    return true
  }, [
    isAssistanceRequired,
    additionalAssistanceText,
    setIsRequiredAssistanceError,
    setIsCharacterLimitError,
  ])

  const validateTermsAndConditions = useCallback((): boolean => {
    if (isTermsAndConditionsChecked) {
      setIsTermsAndConditionsError(false)
      return true
    }
    setIsTermsAndConditionsError(true)
    return false
  }, [isTermsAndConditionsChecked, setIsTermsAndConditionsError])

  const handleClick = useCallback(
    async (action: "reset-password" | "create-account") => {
      setIsValidating(true)

      const arePasswordsValid = validatePasswords()
      const isAdditionalAssistanceValid =
        action === "create-account" && validateAdditionalAssistanceInput()
      const isTermsAndConditionsValid =
        action === "create-account" && validateTermsAndConditions()

      if (arePasswordsValid) {
        setIsSubmitting(true)
        try {
          if (action === "create-account") {
            if (!isAdditionalAssistanceValid || !isTermsAndConditionsValid) {
              setIsSubmitting(false)
              return
            }
            const response = await setPasswordForUser({
              activationCode,
              newPassword: password,
            })
            if (response.failed.length === 0) {
              //TODO: add 2fa skip here is smsCodeRequired is false https://dev.azure.com/secure-the-file/Application/_workitems/edit/15788
              onRegister &&
                onRegister({
                  password,
                  additionalAssistanceText,
                })
            } else {
              const errorsFromApi: PasswordSetupError[] = response.failed.map(
                (validationResult) => {
                  return {
                    passwordErrorKey: getPasswordErrorKey(
                      validationResult.passwordValidationRuleReference
                    ),
                    message: "",
                  }
                }
              )
              setErrorsPassword(errorsFromApi)
            }
          } else if (action === "reset-password") {
            const response = await resetPassword({
              activationCode,
              newPassword: password,
            })
            if (response.failed.length === 0) {
              onReset && onReset({ password })
            } else {
              const errorsFromApi: PasswordSetupError[] = response.failed.map(
                (validationResult) => {
                  return {
                    passwordErrorKey: getPasswordErrorKey(
                      validationResult.passwordValidationRuleReference
                    ),
                    message: "",
                  }
                }
              )
              setErrorsPassword(errorsFromApi)
            }
          }
        } catch (error) {
          if (
            (error as AxiosError).response?.status === 400 &&
            (error as AxiosError).response?.data === "User has been Locked out"
          ) {
            setHasUserLockedOutError(true)
          } else {
            setHasUnexpectedError(true)
          }
        } finally {
          setIsSubmitting(false)
        }
      } else {
        setIsSubmitting(false)
      }
    },
    [
      validatePasswords,
      validateAdditionalAssistanceInput,
      validateTermsAndConditions,
      activationCode,
      password,
      onRegister,
      additionalAssistanceText,
      onReset,
    ]
  )

  useEffect(() => {
    if (isSubmitting) {
      if (passwordSetupStep === PasswordSetupSteps.SetupPassword) {
        handleClick("create-account")
      }
      if (passwordSetupStep === PasswordSetupSteps.ResetPassword) {
        handleClick("reset-password")
      }
    }
  }, [
    isSubmitting,
    password,
    confirmPassword,
    isAssistanceRequired,
    additionalAssistanceText,
    isTermsAndConditionsChecked,
    activationCode,
    passwordSetupStep,
    onRegister,
    onReset,
    setIsSubmitting,
    setIsTermsAndConditionsError,
    handleClick,
  ])
  return {
    isSubmitting,
    setIsSubmitting,
    isValidating,
    errorsPassword,
    errorConfirmPasswordMessage,
    isRequiredAssistanceError,
    isCharacterLimitError,
    hasUnexpectedError,
    hasUserLockedOutError,
  }
}
