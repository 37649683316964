import { IFolder } from "../api-types"

const addFolderToChildFolders = ({
  newFolder,
  folder,
}: {
  newFolder: IFolder
  folder: IFolder
}): IFolder => {
  const newChildFolders = folder.childFolders || []
  if (folder.id === newFolder.parentId) {
    return {
      ...folder,
      childFolders: [...newChildFolders, newFolder],
    }
  } else {
    return {
      ...folder,
      childFolders: newChildFolders.map((f) =>
        addFolderToChildFolders({ newFolder, folder: f })
      ),
    }
  }
}

export const addFolder = ({
  newFolder,
  folders,
}: {
  newFolder: IFolder
  folders: IFolder[]
}) => {
  if (!newFolder.parentId) {
    return [...folders, newFolder]
  }
  return folders.map((f) => addFolderToChildFolders({ newFolder, folder: f }))
}
