const Logout = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeWidth="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66161 3.66161C3.68505 3.63817 3.71685 3.625 3.75 3.625H8.125C8.40114 3.625 8.625 3.40114 8.625 3.125C8.625 2.84886 8.40114 2.625 8.125 2.625H3.75C3.45163 2.625 3.16548 2.74353 2.9545 2.9545C2.74353 3.16548 2.625 3.45163 2.625 3.75V16.25C2.625 16.5484 2.74353 16.8345 2.9545 17.0455C3.16548 17.2565 3.45163 17.375 3.75 17.375H8.125C8.40114 17.375 8.625 17.1511 8.625 16.875C8.625 16.5989 8.40114 16.375 8.125 16.375H3.75C3.71685 16.375 3.68505 16.3618 3.66161 16.3384C3.63817 16.3149 3.625 16.2832 3.625 16.25V3.75C3.625 3.71685 3.63817 3.68505 3.66161 3.66161ZM13.2412 6.36515C13.4364 6.16991 13.753 6.16996 13.9483 6.36524L17.2287 9.64649C17.4239 9.84174 17.4239 10.1583 17.2287 10.3535L13.9483 13.6348C13.753 13.83 13.4364 13.8301 13.2412 13.6348C13.0459 13.4396 13.0458 13.123 13.2411 12.9277L15.6682 10.5H8.125C7.84886 10.5 7.625 10.2761 7.625 10C7.625 9.72386 7.84886 9.5 8.125 9.5H15.6682L13.2411 7.07226C13.0458 6.87697 13.0459 6.56039 13.2412 6.36515Z"
      fill="#3D3E46"
    />
  </svg>
)
export default Logout
