import { IFolder, INodeShare, IFile } from "../../api/api-client/api-types"
import { getFolder } from "../../api/api-client/api-handler"

export const setPermissionsOfParentForChild = ({
  node,
  folders,
  shares,
}: {
  node: IFolder | IFile
  folders: IFolder[]
  shares: INodeShare[]
}) => {
  const parent = getFolder({ folders, folderId: node.parentId! })

  if (!parent) {
    return
  }

  const replaceShares = shares.map((share) => {
    if (share.nodeId === node.id) {
      const parentShare = shares.find(
        (s) => s.nodeId === parent.id && s.contactId === share.contactId
      )
      if (parentShare) {
        return {
          ...share,
          canRead: parentShare.canRead,
          canEdit: parentShare.canEdit,
        }
      }
    }
    return share
  })

  const parentShares = shares.filter((s) => s.nodeId === parent.id)
  parentShares.forEach((parentShare) => {
    if (
      !replaceShares.find(
        (s) => s.nodeId === node.id && s.contactId === parentShare.contactId
      )
    ) {
      replaceShares.push({ ...parentShare, nodeId: node.id })
    }
  })
  return replaceShares
}

export const addSharesForChildFoldersAndFiles = ({
  folder,
  share,
  shares,
}: {
  folder: IFolder
  share: INodeShare
  shares: INodeShare[]
}) => {
  folder.contents?.forEach((file) => {
    if (
      !shares.find((s) => {
        if (s.nodeId === file.id && s.contactId === share.contactId) {
          return true
        }
        return false
      })
    ) {
      shares.push({
        ...share,
        nodeId: file.id,
      })
    }
  })
  folder.childFolders?.forEach((f) => {
    if (
      !shares.find((s) => {
        if (s.nodeId === f.id && s.contactId === share.contactId) {
          return true
        }
        return false
      })
    ) {
      shares.push({
        ...share,
        nodeId: f.id,
      })
    }
    addSharesForChildFoldersAndFiles({
      folder: f,
      share,
      shares,
    })
  })
}

/**
 * of the new/updated shares write for all children of the folder (file, folder) new node shares
 * loop through existing shares and replace with updated share, where one exists
 * add new shares
 */

export const addOrReplaceContactShares = ({
  oldContactsShares,
  updatedContactShares,
  folders,
}: {
  oldContactsShares: INodeShare[]
  updatedContactShares: INodeShare[]
  folders: IFolder[]
}) => {
  updatedContactShares.forEach((share) => {
    const folder = getFolder({ folders, folderId: share.nodeId })
    if (folder) {
      addSharesForChildFoldersAndFiles({
        folder,
        share,
        shares: updatedContactShares,
      })
    }
  })

  const newSharesReplace = oldContactsShares.map((share) => {
    const replace = updatedContactShares?.find((s) => {
      if (s.nodeId === share.nodeId && s.contactId === share.contactId) {
        return true
      }
      return false
    })
    if (replace) {
      return replace
    } else {
      return share
    }
  })

  updatedContactShares?.forEach((share) => {
    const dontAdd = oldContactsShares.find((s) => {
      if (s.nodeId === share.nodeId && s.contactId === share.contactId) {
        return true
      }
      return false
    })
    if (!dontAdd) {
      newSharesReplace.push(share)
    }
  })

  return newSharesReplace
}
