import {
  createContext,
  FC,
  Reducer,
  useReducer,
  Dispatch,
  useContext,
} from "react"
import { IApplicationState } from "./constants"

export const ApplicationContext = createContext(
  {} as {
    applicationState: IApplicationState
    dispatch: Dispatch<any>
  }
)

export const ApplicationProvider: FC<{
  initialApplicationData: IApplicationState
  reducer: Reducer<any, any>
  children: React.ReactNode
}> = ({ initialApplicationData, reducer, children }) => {
  const [applicationState, dispatch] = useReducer(
    reducer,
    initialApplicationData
  )

  const value = { applicationState, dispatch }
  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  )
}

export const useApplicationContext = () => useContext(ApplicationContext)
