import { FC, useEffect, useState } from "react"
import { Radio } from "../atoms/Radio"
import { neverReadonlyEdit } from "../../utils/shares"
import { Tooltip } from "../modules/Tooltip/Tooltip"
import classNames from "classnames"

export interface SelectAllPermissionsProps {
  className: string
  checkedOption?: string
  disabledOptions?: string[]
  removeOptions?: string[]
  groupName: string
  hideLabel?: boolean
  numberOfItems?: number
  onChange: (selectedValue: string) => void
  getTooltipMessage?: (option: string, isFile: boolean) => string | undefined
  isAddingContactThroughWorkflowDocument?: boolean
}

export const SelectAllPermissions: FC<SelectAllPermissionsProps> = ({
  className,
  checkedOption,
  disabledOptions,
  removeOptions,
  groupName,
  hideLabel,
  numberOfItems,
  onChange,
  getTooltipMessage = () => {
    return undefined
  },
  isAddingContactThroughWorkflowDocument,
}) => {
  const [allSelectedChecked, setAllSelectedChecked] = useState(checkedOption)

  useEffect(() => {
    setAllSelectedChecked(checkedOption)
  }, [checkedOption])

  const radioButtonGroupClassName = classNames(
    "radio-button-group",
    className,
    {
      hidden: isAddingContactThroughWorkflowDocument,
    }
  )

  return (
    <div
      className={radioButtonGroupClassName}
      role="group"
      aria-labelledby="select-all-sharing-legend"
    >
      <div
        id="select-all-sharing-legend"
        className="col-12 col-md-6 select-all-sharing-legend"
      >
        {numberOfItems ? `All (${numberOfItems})` : "Select All"}
      </div>
      <div className="col-12 col-md-6 radio-div">
        {neverReadonlyEdit.map((option) => {
          if (removeOptions?.includes(option)) {
            return <></>
          }
          const tooltipMessage = getTooltipMessage(option, false)
          return (
            <label key={option} className="select-all-radio">
              <span className={`text-3 ${hideLabel ? "visually-hidden" : ""}`}>
                {tooltipMessage && (
                  <Tooltip id={`${option}-tooltip`} message={tooltipMessage} />
                )}
                {option}
              </span>
              <Radio
                name={groupName}
                value={option}
                isChecked={option === allSelectedChecked}
                onChange={() => {
                  setAllSelectedChecked(option)
                  onChange(option)
                }}
                isDisabled={disabledOptions?.includes(option)}
              />
            </label>
          )
        })}
      </div>
    </div>
  )
}
