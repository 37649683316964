import { getContactsFromLocalData } from "react-app-config"
import { IContact } from "../../api/api-client/api-types"

export const modalWhoInfo =
  "Contacts are trusted professionals, friends, or family. In order to enable contacts to manage affairs through life and at the end of life. A shared document vault provides secure access to important documents and records."
export const SWMModalWhoInfo =
  "The shared page contains customers who have shared a document vault. This could be: trusted professionals, friends, or family. Access is chosen by the customer who has shared. This could be the ability to view what has been shared or edit their vault.  "

export const suggestedContacts = [
  "Financial Adviser",
  "Accountant",
  "Solicitor",
  "A Spouse or Partner",
  "The executor of a Will",
  "Grown children",
  "Power of attorney",
] as String[]

export const readOnlyPermissions = [
  "View files",
  "Download",
  "Pin to landing page",
] as String[]

export const editPermissions = [
  "Rename files and folders",
  "Delete files",
  "Create new folders and upload",
] as String[]

export const modalWhatInfo =
  "Access permissions will be up to the primary user.  Share single files or an entire document vault with contacts. Allow contacts to edit (e.g. upload, rename or delete) contents, or set files and folders to view only. Access can be revoked at any time.   "
export const modalHowInfo =
  "When creating a contact an email address will be required. This will allow an invitation to be sent to this document vault. The invitation email will contain a link for access. Through this link the contact will need to enter a mobile phone number on first use. To confirm the mobile number a one time code will be sent. For security future access will require confirmation of both email and the mobile number entered. "
export const karimsEmail = "karim.mehdi@legadovault.onmicrosoft.com"
export const karimsContacts = getContactsFromLocalData(
  karimsEmail
) as unknown as IContact[]
export const petersEmail = "peter.milner@legadovault.onmicrosoft.com"
export const petersContacts = getContactsFromLocalData(
  karimsEmail
) as unknown as IContact[]
