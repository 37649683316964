import { CaseDocument } from "../../api/lib/workflow/models/GetCaseDocumentResponse"
import { ActionType } from "../../contexts/application/constants"

export const containsDocumentActionType = ({
  documents,
  actionType,
}: {
  documents: CaseDocument[]
  actionType: ActionType
}): boolean => {
  return documents?.some((document) => document.actionType === actionType)
}
