import { TDate } from "../../component/ReviewSection/DateReview/DateReview"
import { TOrganisation } from "../../component/ReviewSection/OrganisationReview/OrganisationReview"
import { TTag } from "../../component/ReviewSection/TagReview/TagReview"
import { TLink } from "../../contexts/application/constants"

export const breadCrumbsLinksData = [
  { name: "Home", href: "home" },
  { name: "About Me", href: "about_me" },
  { name: "Digital Worlds", href: "digital_worlds" },
] as TLink[]

type TBase = {
  id: string
  parentId?: string | null
  ownerId?: string | null
  createdById?: string | null
  isOwner?: boolean
  name: string
  shared?: boolean | null
  created?: string | null
  updated?: string | null
  favourite?: boolean
  version?: number
  isEditable?: boolean
  isDeletable?: boolean
  dateShared?: string | null
}

export interface IFile extends TBase {
  documentId?: string
  binaryStr?: string | ArrayBuffer
  extension?: string | null
  fileType?: string | null
  fileName?: string
  mimeType?: string | null
  oldId?: string
  sizeInBytes?: number
  hasThumbnail: boolean
  hasPreview: boolean
  hasBeenReviewed?: boolean
  canBeReviewed?: boolean
  associatedProduct?: string | null
  isExternalDocument?: boolean
  isImportantDocument?: boolean
  createdBy?: string | null
  deleted?: boolean
  isDistributedFile?: boolean
  visibility?: string | null
  parentFolderAccountType?: string | null
  // requestId tells us that a document is external
  requestId?: string | null
  // The below values are frontend only
  beenViewed?: boolean
  preview?: string
  base64StringThumbnail?: string
  grandparentFolderName?: string | null
  grandparentFolderId?: string
  productName?: string | null
  folderName?: string | null
  isThumbnailLoading?: boolean
  // Commenting and versioning
  versionNumber?: number
  hasCommenting?: boolean
  lastCommentedByUserId?: string
  approved?: boolean
  // documentStatus relates to status retrieved on external documents - it's null for internal docs
  documentStatus?: string
  documentCategory?: string
  parentFolderProductName?: string
  isDocusignFile?: boolean
  // Can be NotApplicable, Pending, Signed, Rejected
  signedStatus?: string
  // Comments left when rejecting a document are attached to docusignActionComment
  docusignActionComment?: string
}

export interface CaseDocumentsFile extends IFile {
  created: string
  currentStatus?: string
}

export interface IFolder extends TBase {
  canUpload?: boolean
  guidanceText?: string
  level: number
  folderType?: string
  childFolders?: IFolder[]
  contents?: IFile[]
  isCurrentPlatform?: boolean
  platformName?: string
  displayorder?: number
  externalCode?: string
}

export interface IOrganisation {
  organisationId: string
  type: string
  name: string
  network: string | null
  isSingleAdviserCompany: boolean | null
  featurePlanId: string | null
  created: string
}

export interface IUser {
  acceptedTermsAndConditions?: string | null
  activationDate?: string | null
  countryCode?: string | null
  created: string
  email: string
  emailConfirmed?: boolean
  firstName?: string | null
  organisation?: IOrganisation | null
  organisationId?: string | null
  phoneNumber?: string | null
  phoneNumberConfirmed?: boolean
  roles?: string[]
  surname?: string | null
  userId: string
  userName?: string | null
  modules?: string[]
  lastLogin?: string | null
  platform?: string | null
}

export interface IContactInvite {
  firstName?: string
  surname?: string
  email: string
  message?: string
  sendCopy?: boolean
  countryCode?: string
  phoneNumber?: string
  relationship?: string
}

export interface IContact {
  contactId: string
  created?: string
  email: string | null
  firstName?: string | null
  surname?: string | null
  name?: string | null
  contactType: string
  relationship?: string | null
  isPending?: boolean
  phoneNumber?: string | null
  reference?: string | null
  nodeShares?: INodeShare[]
}

export interface INodeShare {
  nodeId: string
  contactId: string | null
  name: string
  contactType: string
  canRead: boolean
  canEdit: boolean
  sharedAfterDeath: boolean
  dateShared?: string
  isSharedSection?: boolean
}

export interface ReviewResponse {
  dates: TDate[]
  tags: TTag[]
  organizations: TOrganisation[]
}

export enum NotificationCategory {
  NEW = "",
  TODAY = "Today",
  SEVENDAYS = "Last 7 days",
  THIRTYDAYS = "Last 30 days",
  OLDERTHANTHIRTYDAYS = "More than 30 days",
}

export interface INotification {
  title: string
  body: string
  relatedNodeId?: string
  date: Date
  viewed?: boolean
  nodeOwnerId: string
  canAccessNode?: boolean
  caseData?: NotificationCaseData
  id: string
  userId?: string
  type?: string
}
export interface NotificationCaseData {
  caseId: number
  stageId: number
  requestId: number
  documentId: number
}

export interface IFeaturePlan {
  name: string
  planType: string
  enabled: boolean
}

export interface IFeatureSetResponse {
  featureDtos: IFeaturePlan[]
  theme: string
}

export interface IIndustry {
  id: string
  code: string
  name: string
  enabled: boolean
}

export interface IProvider {
  id: string
  code: string
  industries: string
  name: string
  enabled: boolean
  isScraped: boolean
}

export interface IProviderStatus {
  folderId?: string | null
  platformProviderId?: string | null
  platformReference?: string | null
  productType?: string | null
  productName?: string | null
  connectionStatus?: ConnectionStatus | null
  created?: Date
  isRead?: boolean
}

export enum ConnectionStatus {
  NEW = "New",
  UNSUCCESSFUL = "Unsuccessful",
  CONNECTED = "Connected",
  DISCONNECTED = "Disconnected",
  DISABLED = "Disabled",
  REMOVED = "Removed",
  CONNECTING = "Connecting",
}

export enum DistributionProjectStatus {
  New = "New",
  FilesUploaded = "FilesUploaded",
  ClientCriteriaInfoUploaded = "ClientCriteriaInfoUploaded",
  Scheduled = "Scheduled",
  WokenUp = "WokenUp",
  Cancelled = "Cancelled",
  Revoked = "Revoked",
  Draft = "Draft",
  Completed = "Completed",
  Failed = "Failed",
  PartlyFailed = "PartialFailure",
}
