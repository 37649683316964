import { InputFieldType, validateInput } from "./validateInputs"
import { getAlertErrorMessage } from "../../component/modules/AlertMessage/utils/alertMessageErrorTranslationsService"

export interface InputField {
  [key: string]: InputFieldAttributes
}

interface InputFieldAttributes {
  id: number
  name: "email" | "password"
  type: InputFieldType
  label: string
  minLength?: number
  maxLength?: number
}

export interface FormError {
  id: number
  fieldType: string
  message: string
}

const addNewError = (
  id: number,
  fieldType: string,
  errorMessage: string,
  errors: FormError[]
) => {
  const newErrors = [
    ...errors,
    {
      id: id,
      fieldType: fieldType,
      message: errorMessage,
    },
  ]
  return newErrors
}

const updateExistingErrorMessage = (
  id: number,
  errorMessage: string,
  errorsList: FormError[]
) => {
  const updatedErrorsArray = errorsList.map((error) => {
    if (error.id === id) {
      return { ...error, message: errorMessage }
    }
    return error
  })
  return updatedErrorsArray
}

const removeInputErrorMessage = (id: number, errors: FormError[]) => {
  const newErrors = errors.filter((error) => error.id !== id)
  return newErrors
}

const getUpdatedErrorList = (
  errors: FormError[],
  inputField: InputFieldAttributes,
  errorMessage: string
) => {
  if (errorMessage) {
    const errorExists = errors.some((error) => error.id === inputField.id)

    if (errorExists) {
      errors = updateExistingErrorMessage(inputField.id, errorMessage, errors)
    } else {
      errors = addNewError(
        inputField.id,
        inputField.type.toString(),
        errorMessage,
        errors
      )
    }
  } else {
    errors = removeInputErrorMessage(inputField.id as unknown as number, errors)
  }
  return errors
}

const checkFormValidity = (
  fields: InputField,
  data: { email: string; password: string }
): FormError[] => {
  let errors: FormError[] = []

  for (const field in fields) {
    const inputField = fields[field]

    const errorMessage = validateInput({
      type: inputField.type,
      value: data[inputField.name],
    })
    //Convert the default error messages to a friendlier wording
    const translatedErrorMessage = getAlertErrorMessage(errorMessage)
    errors = getUpdatedErrorList(errors, inputField, translatedErrorMessage)
  }
  return errors //Returning errors list if required immediately after calling this function as updated state is not immediately available
}

export default checkFormValidity
