import { UserDto } from "legado-generated-api-client/models";
const users = require("../staticData/getUser.json");

export interface IUserDto extends Omit<UserDto, "created"> {
  created?: Date | string;
}

type TGetUser = ({
  userId,
  email,
}: {
  userId?: string;
  email?: string;
}) => IUserDto | undefined;

const isIUserDto = (user: any) => {
  if ((user as IUserDto)?.email) {
    return user as IUserDto;
  }
};

export const getUserId = (email?: string) => {
  if (email) {
    const user = getUser({ email });
    if (user?.userId) {
      return user.userId;
    }
  }
  return "c50d8c09-cc98-431d-a34b-26efc90e5e3f";
};

export const getUser: TGetUser = ({ userId, email }) => {
  let currentUser = {} as IUserDto;
  const currentUserId =
    !userId && !email ? "c50d8c09-cc98-431d-a34b-26efc90e5e3f" : userId;
  Object.values(users).forEach((user) => {
    const u = isIUserDto(user);
    if (u && (currentUserId ? u.userId === currentUserId : u.email === email)) {
      currentUser = u;
    }
  });
  return currentUser;
};

export const getAllUsers: IUserDto[] = Object.values<IUserDto>(users).map(
  (user) => user
);
