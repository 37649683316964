export const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="151"
    height="60"
    fill="none"
    viewBox="0 0 151 60"
  >
    <path
      d="M5.5657 40.5235H0.728882V6.83075H24.0556V11.0037H5.5657V21.7926H23.1547V25.9419H5.5657V40.5235Z"
      fill="#161616"
    />
    <path
      d="M56.1607 40.5235H50.3281L32.3797 13.0902H32.19C32.2374 13.9437 32.2928 14.8842 32.356 15.9116C32.4192 16.9233 32.4666 17.9902 32.4982 19.1125C32.5457 20.2189 32.5773 21.3412 32.5931 22.4793V40.5235H28.1356V6.83075H33.9208L51.7981 35.0929H51.9641C51.9325 34.3975 51.8929 33.536 51.8455 32.5086C51.7981 31.4811 51.7507 30.3984 51.7033 29.2603C51.6717 28.1064 51.6558 27.0158 51.6558 25.9883V6.83075H56.1607V40.5235Z"
      fill="#161616"
    />
    <path
      d="M86.0124 40.5235H60.549V37.0618L79.6582 11.0511H61.1417V6.83075H85.5382V10.2924L66.4053 36.3031H86.0124V40.5235Z"
      fill="#161616"
    />
    <rect
      x="91.4341"
      y="0.183136"
      width="46.988"
      height="46.988"
      fill="#161616"
    />
    <rect
      x="96.8557"
      y="36.3277"
      width="36.1446"
      height="2.71084"
      fill="white"
    />
  </svg>
)

export default Logo
