import { IFolder } from "../api-types"

export function findAndDeleteFolder(folders: IFolder[], id: string) {
  folders.forEach((mappedFolder: IFolder) => {
    if (mappedFolder.childFolders) {
      findAndDeleteFolder(mappedFolder.childFolders, id)
      mappedFolder.childFolders = mappedFolder.childFolders.filter(
        (child) => child.id !== id
      )
    }
  })
}
