import { getFoldersForOneSectionOnly } from "../../component/FolderList/helpers"
import { IFolder, INodeShare } from "../../api/api-client/api-types"
import { findFoldersByIds } from "../../api/api-client/folder/findFoldersByIds"
import { flattenFolderTree } from "../../api/api-client/folder/flattenFolderTree"

//Requires all the user's folders and shares to be passed in
//Shared Section refers to the top level folder that is user is forced to share by an org
export default function getFoldersWithinSharedSection(
  allShares?: INodeShare[] | undefined,
  allFolders?: IFolder[],
  contactId?: string
): IFolder[] {
  if (
    !allShares ||
    allShares.length === 0 ||
    !allFolders ||
    allFolders.length === 0
  )
    return []
  const contactShares = allShares.filter((s) => s.contactId === contactId)
  const sharedSectionsShares = contactShares.filter((cs) => cs.isSharedSection)
  if (contactShares.length === 0 || sharedSectionsShares.length === 0) return []

  const sharedFolders = findFoldersByIds(
    allFolders,
    contactShares.map((cs) => {
      return cs.nodeId
    })
  )
  const sharedSectionFolders = sharedFolders.filter((sf) =>
    sharedSectionsShares.find((sss) => sss.nodeId === sf.id)
  )
  if (sharedSectionFolders.length === 0) return []

  const foldersInSharedSection = sharedSectionFolders as IFolder[]
  sharedSectionFolders.forEach((ssf) => {
    const ssfDescendantFolders = getFoldersForOneSectionOnly(
      sharedFolders,
      ssf.id
    )
    const foldersToAdd = ssfDescendantFolders[ssf.name]
    foldersToAdd &&
      foldersToAdd.length > 0 &&
      foldersInSharedSection.push(...foldersToAdd)
  })
  return flattenFolderTree(foldersInSharedSection)
}

export function hasEditableShares(
  allShares?: INodeShare[] | undefined,
  allFolders?: IFolder[],
  contactId?: string
): boolean {
  const nonEditableFolders = getFoldersWithinSharedSection(
    allShares,
    allFolders,
    contactId
  )
  return (
    flattenFolderTree(allFolders).length >
    flattenFolderTree(nonEditableFolders).length
  )
}
