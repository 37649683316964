const WelcomeOrganise = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    className="reverse"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="reverse"
      d="M53.5 47V31C53.5 30.7239 53.2761 30.5 53 30.5H37.4415C37.2263 30.5 37.0352 30.6377 36.9672 30.8419L35.425 35.4683C35.4012 35.5397 35.3724 35.6092 35.3388 35.6765C33.1391 40.0759 26.8609 40.0759 24.6612 35.6765C24.6276 35.6092 24.5987 35.5397 24.575 35.4683L23.0328 30.8419C22.9648 30.6377 22.7737 30.5 22.5585 30.5H7C6.72386 30.5 6.5 30.7239 6.5 31V47C6.5 47.2761 6.72386 47.5 7 47.5H53C53.2761 47.5 53.5 47.2761 53.5 47ZM7 29C5.89543 29 5 29.8954 5 31V47C5 48.1046 5.89543 49 7 49H53C54.1046 49 55 48.1046 55 47V31C55 29.8954 54.1046 29 53 29H37.4415C36.5807 29 35.8164 29.5509 35.5442 30.3675L34.002 34.994C34.0007 34.998 33.9991 35.0019 33.9972 35.0057C32.3502 38.2995 27.6498 38.2995 26.0028 35.0057C26.0009 35.0019 25.9993 34.998 25.998 34.994L24.4558 30.3675C24.1836 29.5509 23.4193 29 22.5585 29H7Z"
    />
    <path
      d="M12 26C12 24.3431 13.3431 23 15 23H45C46.6569 23 48 24.3431 48 26V29H36.7092C36.2645 29 35.8475 29.2161 35.591 29.5794C32.8644 33.4421 27.1356 33.4421 24.409 29.5794C24.1525 29.2161 23.7355 29 23.2908 29H12V26Z"
      className="custom-1"
      stroke="none"
    />
    <path d="M22 17C22 15.3431 23.3431 14 25 14H35C36.6569 14 38 15.3431 38 17V18H32.6667H30H27.3333H22V17Z" />
    <path
      d="M22 17.5V16C22 14.8954 22.8954 14 24 14H36C37.1046 14 38 14.8954 38 16V17.5"
      strokeWidth="1.5"
      className="custom-1"
    />
    <path
      d="M18 22.5V20C18 18.8954 18.8954 18 20 18H40C41.1046 18 42 18.8954 42 20V22.5"
      strokeWidth="1.5"
    />
    <path
      d="M12 29V25C12 23.8954 12.8954 23 14 23H46C47.1046 23 48 23.8954 48 25V29"
      strokeWidth="1.5"
    />
  </svg>
)

export default WelcomeOrganise
