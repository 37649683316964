class ViewSDKClient {
  constructor() {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve()
      } else {
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          resolve()
        })
      }
    })
    this.adobeDCView = undefined
  }
  ready() {
    return this.readyPromise
  }
  previewFile(divId, viewerConfig, url, fileName, setUpdatedBlob) {
    try {
      let token
      // eslint-disable-next-line no-restricted-globals
      switch (location.hostname) {
        case "adminde12.fnzc.co.uk":
          token = "fb66c600c2384730be73b8ead55df80e"
          break
        case "adminde42.fnzc.co.uk":
          token = "30b89990f0654935b91aa513f786be1a"
          break
        case "adminae53.fnzc.co.uk":
          token = "e6457d56620145869ff155f80a58d431"
          break
        case "client-app-dev.legado.io":
          token = "7cd421c578d74ae9a815455f65bc79ff"
          break
        case "client-app-qa.legado.io":
          token = "130f7ea816fe4228958ddcc5a9e573f6"
          break
        case "client-app-pp.legado.io":
          token = "da2004e16d744f1490fcc7af151b3d35"
          break
        case "client-app-demo.legado.io":
          token = "1e218b889749463bae65e06fab3209db"
          break
        case "embed.legado.io":
          token = "612fc75996c34db6bbcd615e75e42355"
          break
        case "quilter-dev.legado.io":
          token = "14221bb396084f04a8e38079233645a9"
          break
        case "quilter-test.legado.io":
          token = "b68216a9a17c4b15a3fcc5ab5025b20a"
          break
        case "documents.customerae51.platformtest.quilter.com":
          token = "4e8154989bc641c885bdf94379b71129"
          break
        case "documents.adminae51.platformtest.quilter.com":
          token = "996f63b52ab74ab4961dc30d6eeb1888"
          break
        case "documents.customer.platform.quilter.com":
          token = "2d95272f481c40ce9fd7b2b6426facd8"
          break
        case "documents.admin.platform.quilter.com":
          token = "bb9781b907a144febf90836db6dfd675"
          break
        case "coop-dev.legado.io":
          token = "d73ded75d9234127b7b92aac68f39964"
          break
        case "coop-qa.legado.io":
          token = "da4d2a753eab4004b80a402f75822cc1"
          break
        case "preprod.clsportal.coop.co.uk":
          token = "b7e1c48811f04e9cb510f946badcd137"
          break
        case "clsportal.coop.co.uk":
          token = "5cfd475757e641e381d2d35f2c7e3447"
          break
        case "abrdn-test.legado.io":
          token = "d90076d3d70846ea9d22e2c20a1ae5b3"
          break
        case "localhost":
          token = "34f228dcfc4740dbaf397a3a00767e6f"
          break
        default:
          token = ""
          break
      }

      const config = {
        clientId: token, ///enter client id here
      }
      if (divId) {
        config.divId = divId
      }
      this.adobeDCView = new window.AdobeDC.View(config)
      const previewFilePromise = this.adobeDCView.previewFile(
        {
          content: {
            location: {
              url: url,
            },
          },
          metaData: {
            fileName: fileName ?? "Preview",
            id: token,
          },
        },
        viewerConfig
      )

      // for more info: https://developer.adobe.com/document-services/docs/overview/pdf-embed-api/howtos/
      previewFilePromise.then((adobeViewer) => {
        const eventOptions = {
          // Pass events for the listener.
          listenOn: ["ANNOTATION_ADDED"],
        }

        if (viewerConfig.enableAnnotationAPIs) {
          adobeViewer.getAnnotationManager().then((annotationManager) => {
            annotationManager
              .setConfig({
                showToolbar: false,
                showCommentsPanel: true,
                downloadWithAnnotations: true,
                showToolsOnTextSelection: true,
                printWithAnnotations: false,
              })
              .then(() => console.log("Success"))
              .catch((error) => console.log(error))
            /**Listener added for a bug fix: https://dev.azure.com/secure-the-file/Application/_workitems/edit/15574/
             *
             * Documentation: https://opensource.adobe.com/pdfembed-sdk-docs/howtos_comments.html#commenting-feature-overview
             *
             * Required for changing the location of the comment icon (using boundingBox xmin, ymin, xmax, ymax)
             *  on the pdf, for comments added via the text input on the comments panel,
             * instead of comments added by highlighting text.
             * The logic also prevents replying to comments.
             */
            let boundingBoxCoordinatesChangeByValue = 0
            annotationManager.registerEventListener(function (event) {
              const hasRepliedToComment = event.data.motivation === "replying"
              if (
                !hasRepliedToComment &&
                event.data.target.selector.subtype.toLowerCase() === "highlight"
              ) {
                return
              }
              // Prevent replying to comments
              if (hasRepliedToComment) {
                const annotationData = {
                  "@context": event.data["@context"],
                  id: event.data.id,
                  type: event.data.type,
                  motivation: event.data.motivation,
                  bodyValue: "replying to comments is not allowed",
                  target: {
                    source: event.data.target.source,
                  },
                  creator: event.data.creator,
                  created: event.data.created,
                  modified: new Date().toISOString(),
                }

                annotationManager.updateAnnotation(annotationData)
              } else {
                const boundingBox = event.data.target.selector.boundingBox
                const updatedBoundingBox = boundingBox.map(
                  (value) => value - boundingBoxCoordinatesChangeByValue
                )

                // Extract and update updatedBoundingBox data from the event
                const annotationData = {
                  "@context": event.data["@context"],
                  id: event.data.id,
                  type: event.data.type,
                  motivation: event.data.motivation,
                  bodyValue: event.data.bodyValue,
                  target: {
                    source: event.data.target.source,
                    selector: {
                      ...event.data.target.selector,
                      boundingBox: updatedBoundingBox,
                    },
                  },
                  creator: event.data.creator,
                  created: event.data.created,
                  modified: new Date().toISOString(),
                }

                annotationManager.updateAnnotation(annotationData)
                boundingBoxCoordinatesChangeByValue += 10
              }
            }, eventOptions)
          })
        }
        this.registerSaveApiHandler(setUpdatedBlob)
      })

      return previewFilePromise
    } catch (error) {}
  }
  previewFileUsingFilePromise(divId, filePromise, fileName) {
    this.adobeDCView = new window.AdobeDC.View({
      clientId: "34f228dcfc4740dbaf397a3a00767e6f", //enter Client id here
      divId,
    })
    this.adobeDCView.previewFile(
      {
        content: {
          promise: filePromise,
        },
        metaData: {
          fileName: fileName,
        },
      },
      {}
    )
  }
  registerSaveApiHandler(setUpdatedBlob) {
    const saveApiHandler = (metaData, content, options) => {
      return new Promise((resolve) => {
        const response = {
          code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
          data: {
            metaData: Object.assign(metaData, {
              updatedAt: new Date().getTime(),
            }),
          },
        }
        if (setUpdatedBlob) {
          const newBlob = new Blob([content], {
            type: "application/pdf",
          })
          setUpdatedBlob(newBlob)
        }

        resolve(response)
      })
    }
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      saveApiHandler,
      {
        autoSaveFrequency: 0.1,
        enableFocusPolling: true,
        showSaveButton: false,
      }
    )
  }
  registerEventsHandler() {
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      (event) => {
        console.log(event)
      },
      {
        enablePDFAnalytics: true,
      }
    )
  }
}
export default ViewSDKClient
