"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocusignApi = exports.DocusignApiFactory = exports.DocusignApiFp = exports.DocusignApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * DocusignApi - axios parameter creator
 * @export
 */
const DocusignApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Sends Cancellation Request to the Docusign Envelope
         * @summary Cancel Docusign Request
         * @param {string} fileId Id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdCancelDelete: (fileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError("fileId", "Required parameter fileId was null or undefined when calling apiDocusignFileFileIdCancelDelete.");
            }
            const localVarPath = `/api/Docusign/file/{fileId}/cancel`.replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets the Docusign File, returning the information required for embedded signing on the front-end
         * @summary Get Docusign File
         * @param {string} fileId Id of the File
         * @param {string} [body] URL to redirect to after signing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdPost: (fileId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError("fileId", "Required parameter fileId was null or undefined when calling apiDocusignFileFileIdPost.");
            }
            const localVarPath = `/api/Docusign/file/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Changes the signed status of a docusign document to the provided status     Acceptable values are:      - \"2\" or \"Signed\" for a signed document,     - \"3\" or \"Rejected\" for a rejected document.
         * @summary Update Signed Status
         * @param {string} fileId Id of the File
         * @param {string} newStatus The Legado.Web.Core.Enums.SignedStatus to update to
         * @param {SignNewStatusBody} [body] From the body, the comment left when changing the status. Can be null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdSignNewStatusPost: (fileId, newStatus, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError("fileId", "Required parameter fileId was null or undefined when calling apiDocusignFileFileIdSignNewStatusPost.");
            }
            // verify required parameter 'newStatus' is not null or undefined
            if (newStatus === null || newStatus === undefined) {
                throw new base_1.RequiredError("newStatus", "Required parameter newStatus was null or undefined when calling apiDocusignFileFileIdSignNewStatusPost.");
            }
            const localVarPath = `/api/Docusign/file/{fileId}/sign/{newStatus}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"newStatus"}}`, encodeURIComponent(String(newStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload a file to be sent via Docusign
         * @summary Upload file to Docusign
         * @param {string} folderId Folder to upload the Docusign file to
         * @param {string} [users]
         * @param {string} [redirectUrl]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFolderFolderIdUploadPostForm: (folderId, users, redirectUrl, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new base_1.RequiredError("folderId", "Required parameter folderId was null or undefined when calling apiDocusignFolderFolderIdUploadPostForm.");
            }
            const localVarPath = `/api/Docusign/folder/{folderId}/upload`.replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (users !== undefined) {
                localVarFormParams.append("users", users);
            }
            if (redirectUrl !== undefined) {
                localVarFormParams.append("redirectUrl", redirectUrl);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DocusignApiAxiosParamCreator = DocusignApiAxiosParamCreator;
/**
 * DocusignApi - functional programming interface
 * @export
 */
const DocusignApiFp = function (configuration) {
    return {
        /**
         * Sends Cancellation Request to the Docusign Envelope
         * @summary Cancel Docusign Request
         * @param {string} fileId Id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdCancelDelete(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocusignApiAxiosParamCreator)(configuration).apiDocusignFileFileIdCancelDelete(fileId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets the Docusign File, returning the information required for embedded signing on the front-end
         * @summary Get Docusign File
         * @param {string} fileId Id of the File
         * @param {string} [body] URL to redirect to after signing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdPost(fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocusignApiAxiosParamCreator)(configuration).apiDocusignFileFileIdPost(fileId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Changes the signed status of a docusign document to the provided status     Acceptable values are:      - \"2\" or \"Signed\" for a signed document,     - \"3\" or \"Rejected\" for a rejected document.
         * @summary Update Signed Status
         * @param {string} fileId Id of the File
         * @param {string} newStatus The Legado.Web.Core.Enums.SignedStatus to update to
         * @param {SignNewStatusBody} [body] From the body, the comment left when changing the status. Can be null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdSignNewStatusPost(fileId, newStatus, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocusignApiAxiosParamCreator)(configuration).apiDocusignFileFileIdSignNewStatusPost(fileId, newStatus, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload a file to be sent via Docusign
         * @summary Upload file to Docusign
         * @param {string} folderId Folder to upload the Docusign file to
         * @param {string} [users]
         * @param {string} [redirectUrl]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFolderFolderIdUploadPostForm(folderId, users, redirectUrl, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocusignApiAxiosParamCreator)(configuration).apiDocusignFolderFolderIdUploadPostForm(folderId, users, redirectUrl, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.DocusignApiFp = DocusignApiFp;
/**
 * DocusignApi - factory interface
 * @export
 */
const DocusignApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Sends Cancellation Request to the Docusign Envelope
         * @summary Cancel Docusign Request
         * @param {string} fileId Id of the File
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdCancelDelete(fileId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocusignApiFp)(configuration)
                    .apiDocusignFileFileIdCancelDelete(fileId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets the Docusign File, returning the information required for embedded signing on the front-end
         * @summary Get Docusign File
         * @param {string} fileId Id of the File
         * @param {string} [body] URL to redirect to after signing
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdPost(fileId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocusignApiFp)(configuration)
                    .apiDocusignFileFileIdPost(fileId, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Changes the signed status of a docusign document to the provided status     Acceptable values are:      - \"2\" or \"Signed\" for a signed document,     - \"3\" or \"Rejected\" for a rejected document.
         * @summary Update Signed Status
         * @param {string} fileId Id of the File
         * @param {string} newStatus The Legado.Web.Core.Enums.SignedStatus to update to
         * @param {SignNewStatusBody} [body] From the body, the comment left when changing the status. Can be null.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFileFileIdSignNewStatusPost(fileId, newStatus, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocusignApiFp)(configuration)
                    .apiDocusignFileFileIdSignNewStatusPost(fileId, newStatus, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload a file to be sent via Docusign
         * @summary Upload file to Docusign
         * @param {string} folderId Folder to upload the Docusign file to
         * @param {string} [users]
         * @param {string} [redirectUrl]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocusignFolderFolderIdUploadPostForm(folderId, users, redirectUrl, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocusignApiFp)(configuration)
                    .apiDocusignFolderFolderIdUploadPostForm(folderId, users, redirectUrl, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.DocusignApiFactory = DocusignApiFactory;
/**
 * DocusignApi - object-oriented interface
 * @export
 * @class DocusignApi
 * @extends {BaseAPI}
 */
class DocusignApi extends base_1.BaseAPI {
    /**
     * Sends Cancellation Request to the Docusign Envelope
     * @summary Cancel Docusign Request
     * @param {string} fileId Id of the File
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocusignApi
     */
    apiDocusignFileFileIdCancelDelete(fileId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocusignApiFp)(this.configuration)
                .apiDocusignFileFileIdCancelDelete(fileId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets the Docusign File, returning the information required for embedded signing on the front-end
     * @summary Get Docusign File
     * @param {string} fileId Id of the File
     * @param {string} [body] URL to redirect to after signing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocusignApi
     */
    apiDocusignFileFileIdPost(fileId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocusignApiFp)(this.configuration)
                .apiDocusignFileFileIdPost(fileId, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Changes the signed status of a docusign document to the provided status     Acceptable values are:      - \"2\" or \"Signed\" for a signed document,     - \"3\" or \"Rejected\" for a rejected document.
     * @summary Update Signed Status
     * @param {string} fileId Id of the File
     * @param {string} newStatus The Legado.Web.Core.Enums.SignedStatus to update to
     * @param {SignNewStatusBody} [body] From the body, the comment left when changing the status. Can be null.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocusignApi
     */
    apiDocusignFileFileIdSignNewStatusPost(fileId, newStatus, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocusignApiFp)(this.configuration)
                .apiDocusignFileFileIdSignNewStatusPost(fileId, newStatus, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload a file to be sent via Docusign
     * @summary Upload file to Docusign
     * @param {string} folderId Folder to upload the Docusign file to
     * @param {string} [users]
     * @param {string} [redirectUrl]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocusignApi
     */
    apiDocusignFolderFolderIdUploadPostForm(folderId, users, redirectUrl, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocusignApiFp)(this.configuration)
                .apiDocusignFolderFolderIdUploadPostForm(folderId, users, redirectUrl, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.DocusignApi = DocusignApi;
