import { OrganisationStateItem } from "./reducer"

export const SET_ORGANISATION = "SET_ORGANISATION"

export const setOrganisation = (payload: OrganisationStateItem | undefined) => {
  return {
    type: SET_ORGANISATION,
    payload,
  }
}
