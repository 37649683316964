import { FC, useEffect } from "react"
import {
  setShowFooter,
  setShowMenu,
} from "../../../contexts/application/action"
import { useApplicationContext } from "../../../contexts/application/context"
import CoopLogo from "../../../design-tokens/imgs/coop/CoopLogo"

import { useLocation } from "react-router-dom"
import "./CoopUnauthorisedPage.scss"

export const enum UnauthorisedCoopPageVariant {
  UNAUTHORISED = "unauthorised",
  CASE_CLOSED = "case-closed",
}
export const CoopUnauthorisedPage: FC = () => {
  const { dispatch } = useApplicationContext()
  const location = useLocation()
  const { pageVariant } = location.state || {}

  useEffect(() => {
    dispatch(setShowMenu(false))
    dispatch(setShowFooter(false))
  }, [dispatch])

  switch (pageVariant) {
    case UnauthorisedCoopPageVariant.CASE_CLOSED:
      return (
        <div className="unauthorised-page">
          <CoopLogo />
          <h1>Case is closed</h1>
          <p>Your case has been closed by your case manager.</p>
          <p>
            If you have any questions regarding your probate case, please
            contact your case
            <br />
            manager directly or call <strong>0330 6060 9434</strong>.
          </p>
          <p className="phone-lines-info">
            {"(Phone lines are open 9am to 5pm Monday to Friday.)"}
          </p>
        </div>
      )
    default:
      return (
        <div className="unauthorised-page">
          <CoopLogo />
          <h1>Unauthorised</h1>
          <h5>You are not authorised to view this page</h5>
          <p>
            To sign back in to your account, please use the link in your email
            invitation.
          </p>
        </div>
      )
  }
}

export default CoopUnauthorisedPage
