const OutlineBell = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3866 26.1468C13.5356 26.6545 13.8451 27.1003 14.2687 27.4174C14.6922 27.7344 15.2071 27.9058 15.7362 27.9058C16.2653 27.9058 16.7802 27.7344 17.2037 27.4174C17.6273 27.1003 17.9368 26.6545 18.0858 26.1468"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M26.2932 22.6268C26.2932 22.6268 23 21 23 12.9468C23 12.9468 23 4 15.5 4C8 4 8 13 8 13C8 20.4319 5.17322 22.6268 5.17322 22.6268H26.2932Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
)
export default OutlineBell
