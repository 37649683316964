import ReactGA from "react-ga4"
import {
  TEventName,
  TGaEventParameter,
} from "../data/google-analytics/constants"
import {
  IFolder,
  IFile,
  IUser,
  ConnectionStatus,
} from "../api/api-client/api-types"
import { clientCustomFolder } from "../component/CreateSubfolder/helpers"
import { userRolesToGARoles } from "../data/google-analytics/helpers"
import { getGAPageName } from "./helpers"

export const GetGAFolderType = (folder?: IFolder) => {
  return folder?.folderType === clientCustomFolder
    ? "Custom"
    : folder?.folderType === "ConnectedAccountFolder"
    ? "Connected"
    : "Suggested"
}

export const sendFolderSelectGaData = (
  folder: IFolder,
  currentUser?: IUser
) => {
  sendGaData({
    name: "folder_select",
    folder_depth: folder.level,
    folder_name: folder.name,
    folder_type: GetGAFolderType(folder),
    is_node_owner: folder.isOwner ? "Y" : "N",
    currentUser,
  })
}

export const sendFileSelectGaData = (
  file: IFile,
  folder: IFolder,
  currentUser?: IUser
) => {
  sendGaData({
    name: "file_select",
    folder_depth: folder?.level,
    folder_name: folder?.name,
    folder_type: GetGAFolderType(folder),
    file_extension: file.extension ?? "",
    is_node_owner: file.isOwner ? "Y" : "N",
    currentUser,
  })
}

export const sendGaData = ({
  name,
  folder_type,
  folder_name,
  folder_depth,
  login_type_tmp,
  request_status,
  file_extension,
  is_node_owner,
  has_personal_note,
  currentUser,
  page_name,
}: {
  name: TEventName
  folder_type?: "Custom" | "Suggested" | "Connected"
  folder_name?: string
  folder_depth?: number
  login_type_tmp?: "SSO" | "static" | "developer"
  request_status?: "success" | "error"
  file_extension?: string
  is_node_owner?: "Y" | "N"
  has_personal_note?: "Y" | "N"
  currentUser?: IUser | undefined
  page_name?: string
}) => {
  const formattedExtension = file_extension?.toLowerCase()

  const corporate_platform = currentUser?.platform ?? "No Platform"
  const organisation_ID = currentUser?.organisationId ?? "No Organisation ID"
  const user_Role =
    userRolesToGARoles(currentUser?.roles?.join()) ?? "No User Role"

  switch (name) {
    case "page_view":
      const gAPageName = page_name && getGAPageName(page_name)
      ReactGA.send({ hitType: "pageview", page: gAPageName })
      break
    case "create_folder":
    case "create_folder_response":
      if (
        folder_type &&
        folder_name &&
        folder_depth !== undefined &&
        is_node_owner
      ) {
        const parametersCreateFolder = {
          folder_type,
          folder_name,
          folder_depth,
          request_status,
          is_node_owner,
          corporate_platform,
          organisation_ID,
          user_Role,
        } as TGaEventParameter

        ReactGA.event(name, parametersCreateFolder)
      }
      break

    case "upload_file":
    case "upload_file_response":
      if (file_extension && is_node_owner) {
        const parametersUploadFile = {
          folder_type,
          folder_name,
          folder_depth,
          file_extension: formattedExtension,
          request_status,
          is_node_owner,
          corporate_platform,
          organisation_ID,
          user_Role,
        } as TGaEventParameter
        ReactGA.event(name, parametersUploadFile)
      }
      break

    case "file_select":
    case "File_Pin":
    case "File_Pin_Response":
    case "File_Unpin":
    case "File_Unpin_Response":
    case "File_Delete":
    case "File_Delete_Response":
    case "File_Rename":
    case "File_Rename_Response":
    case "file_preview":
    case "file_download":
    case "file_download_response":
      if (is_node_owner && file_extension) {
        const parametersSelectFile = {
          folder_depth,
          folder_name,
          folder_type,
          file_extension: formattedExtension,
          is_node_owner,
          request_status,
          corporate_platform,
          organisation_ID,
          user_Role,
        } as TGaEventParameter
        ReactGA.event(name, parametersSelectFile)
      }
      break

    case "folder_select":
    case "Folder_Pin":
    case "Folder_Pin_Response":
    case "Folder_Unpin":
    case "Folder_Unpin_Response":
    case "Folder_Rename":
    case "Folder_Rename_Response":
    case "Folder_Delete":
    case "Folder_Delete_Response":
      if (
        folder_depth !== undefined &&
        folder_name &&
        folder_type &&
        is_node_owner
      ) {
        const parametersFolderDetails = {
          folder_depth,
          folder_name,
          folder_type,
          is_node_owner,
          request_status,
          corporate_platform,
          organisation_ID,
          user_Role,
        } as TGaEventParameter
        ReactGA.event(name, parametersFolderDetails)
      }
      break

    case "cm_create":
    case "cm_create_response":
      ReactGA.event(name, { has_personal_note, request_status })
      break

    case "cm_view_contact":
    case "cm_permissions_saved":
    case "cm_permissions_saved_response":
    case "cm_remove_contact":
    case "cm_remove_contact_response":
    case "welcome_continue":
    case "welcome_continue_response":
    case "welcome_view_tcs":
    case "Provider_Search_Page_Visited":
      ReactGA.event(name, { request_status })
      break

    default:
      break
  }
}
export const sendConnectivtyGaData = ({
  name,
  industry_name,
  provider_name,
  is_accepeted_terms,
  is_entered_login,
  folder_status,
  currentUser,
  newUser,
}: {
  name: TEventName
  industry_name?: string
  provider_name?: string
  is_accepeted_terms?: "Y" | "N"
  is_entered_login?: "Y" | "N"
  folder_status?: ConnectionStatus | "N/A"
  currentUser?: IUser | undefined
  newUser?: boolean
}) => {
  const corporate_platform = currentUser?.platform ?? "No Platform"
  const organisation_ID = currentUser?.organisationId ?? "No Organisation ID"
  const user_Role =
    userRolesToGARoles(currentUser?.roles?.join()) ?? "No User Role"

  const connectivityParams = {
    industry_name,
    provider_name,
    is_accepeted_terms,
    is_entered_login,
    folder_status,
    corporate_platform,
    organisation_ID,
    user_Role,
  } as TGaEventParameter
  const connectivityParamsB = {
    newUser,
  } as TGaEventParameter
  switch (name) {
    case "add_provider":
      ReactGA.event(name, connectivityParams)
      break
    case "connectivity_skip":
      ReactGA.event(name, connectivityParamsB)
      break
    default:
      break
  }
}
