const Guide = () => (
    <svg 
        width="26" 
        height="26" 
        viewBox="0 0 26 26" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
    <path 
        d="M13 23.0286C18.5386 23.0286 23.0286 18.5386 23.0286 13C23.0286 7.46138 18.5386 2.97144 13 2.97144C7.46138 2.97144 2.97144 7.46138 2.97144 13C2.97144 18.5386 7.46138 23.0286 13 23.0286Z" 
        stroke="#00A4EA" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    <path 
        d="M13 11.8857V18.2" 
        stroke="#00A4EA" 
        strokeWidth="1.5" 
        strokeLinecap="round"/>
    <path 
        d="M13 9.65713C13.6154 9.65713 14.1143 9.15825 14.1143 8.54284C14.1143 7.92744 13.6154 7.42856 13 7.42856C12.3846 7.42856 11.8857 7.92744 11.8857 8.54284C11.8857 9.15825 12.3846 9.65713 13 9.65713Z" 
        fill="#00A4EA"/>
    </svg>
  );
  export default Guide;