const CheckboxInactive = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
       data-testid="CheckboxInactive"
    >
      <g filter="url(#filter0_d_27_1288)">
        <rect x="1.5" y="1.5" width="29" height="29" rx="3" fill="none" />
        <rect x="2" y="2" width="28" height="28" rx="2.5" fill="none" />
      </g>
      <defs>
        <filter
          id="filter0_d_27_1288"
          x="1.5"
          y="1.5"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_27_1288"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_27_1288"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
export default CheckboxInactive
