import { TApplicationAction, IApplicationState } from "./constants"
import {
  ADD_OR_REPLACE_CONTACTS_SHARES,
  SET_SHARES_ALL_CONTACTS,
  ADD_CONTACT,
  REMOVE_CONTACT,
  SET_SELECTED_CONTACT,
  SET_CONTACT_LIST,
  SET_PARENT_SHARES,
} from "./action"
import {
  addOrReplaceContactShares,
  setPermissionsOfParentForChild,
} from "../../utils/shares"

export const contactReducer = (
  applicationState: IApplicationState,
  action: TApplicationAction
) => {
  switch (action.type) {
    case SET_PARENT_SHARES:
      if (action.folder) {
        const newShares = setPermissionsOfParentForChild({
          node: action.folder,
          folders: applicationState.folders,
          shares: applicationState.contactsShares!,
        })

        if (newShares) {
          return {
            ...applicationState,
            contactsShares: newShares,
          }
        }
      }
      break

    case ADD_OR_REPLACE_CONTACTS_SHARES:
      const newShares = addOrReplaceContactShares({
        oldContactsShares: applicationState.contactsShares || [],
        updatedContactShares: action.contactsShares || [],
        folders: applicationState.folders,
      })
      return {
        ...applicationState,
        contactsShares: newShares,
      }

    case SET_SHARES_ALL_CONTACTS:
      return {
        ...applicationState,
        contactsShares: action.contactsShares,
      }

    case SET_SELECTED_CONTACT:
      if (action.selectedContactId !== undefined) {
        return {
          ...applicationState,
          selectedContactId: action.selectedContactId,
        }
      }
      break

    case ADD_CONTACT:
      if (action.contact) {
        const contacts = applicationState.contacts || []
        const newAppState = {
          ...applicationState,
          selectedContactId: "",
          contacts: [...contacts, action.contact],
        }
        return newAppState
      }
      break

    case REMOVE_CONTACT:
      if (action.deleteContactId) {
        const contacts = applicationState.contacts || []
        var newContacts = contacts.filter(function (n) {
          return n.contactId !== action.deleteContactId
        })

        const newShares = applicationState.contactsShares?.filter(
          (s) => s.contactId !== action.deleteContactId
        )

        return {
          ...applicationState,
          contacts: newContacts,
          contactsShares: newShares,
          selectedContactId: "",
        }
      }
      break

    case SET_CONTACT_LIST:
      if (action.contacts) {
        return {
          ...applicationState,
          contacts: action.contacts,
        }
      }
      break

    default:
      return
  }
}
