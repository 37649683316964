/* istanbul ignore file */

import { HelmetProvider } from "react-helmet-async"
import { ProvidersWrapper } from "./contexts/main/ProviderWrap"
import "./css/behavior.css"
import "./css/legado-no-theming.css"
import { Routing } from "./routes/Routing"
import { GlobalStyle } from "./styled-components/GlobalStyle"

interface AppProps {
  basePath?: string
}

/**
 * Main application component.  basePath is only passed when running in the dock and tells our app which path it running on within the dock
 */
export const App = ({ basePath }: AppProps) => {
  return (
    <HelmetProvider>
      <ProvidersWrapper>
        <GlobalStyle>
          <Routing basePath={basePath} />
        </GlobalStyle>
      </ProvidersWrapper>
    </HelmetProvider>
  )
}

export default App
