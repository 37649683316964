import { FC } from "react"
const Elipsis: FC<{ color?: string }> = ({ color }) => (
  <svg
    className="icon-ellipsis"
    width="15"
    height="4"
    viewBox="0 0 15 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 0.982421C14.3284 0.982421 15 1.65399 15 2.48242C15 3.31085 14.3284 3.98242 13.5 3.98242C12.6716 3.98242 12 3.31085 12 2.48242C12 1.65399 12.6716 0.98242 13.5 0.982421ZM7.5 0.982421C8.32843 0.982421 9 1.65399 9 2.48242C9 3.31085 8.32843 3.98242 7.5 3.98242C6.67157 3.98242 6 3.31085 6 2.48242C6 1.65399 6.67157 0.982421 7.5 0.982421ZM3 2.48242C3 1.65399 2.32843 0.982421 1.5 0.982421C0.671573 0.982421 2.03558e-07 1.65399 1.31134e-07 2.48242C5.87108e-08 3.31085 0.671573 3.98242 1.5 3.98242C2.32843 3.98242 3 3.31085 3 2.48242Z"
      fill={color ?? "#48475C"}
    />
  </svg>
)

export default Elipsis
