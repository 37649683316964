  const Pencil = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      data-testid="Pencil"
      stroke="none"
    >
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0687 0.696096C12.7466 0.564332 12.4015 0.497704 12.0534 0.50006C11.7054 0.502417 11.3613 0.57371 11.0409 0.709824C10.7215 0.84554 10.4321 1.04302 10.1892 1.2909L1.68481 9.7953C1.62145 9.85867 1.57627 9.9379 1.55402 10.0247L0.51567 14.0754C0.471793 14.2466 0.521524 14.4282 0.646481 14.5532C0.771439 14.6781 0.953066 14.7278 1.12423 14.6839L5.17498 13.645C5.26176 13.6227 5.34097 13.5776 5.40431 13.5142L13.9087 5.00988C14.1566 4.76712 14.3541 4.47775 14.4899 4.1584C14.6261 3.83808 14.6974 3.49394 14.6998 3.14588C14.7021 2.79783 14.6355 2.45276 14.5036 2.13062C14.3718 1.80848 14.1774 1.51567 13.9318 1.26912L13.5776 1.62204L13.9304 1.26774C13.6837 1.02215 13.3909 0.827859 13.0687 0.696096ZM12.6902 1.62167C12.8897 1.70329 13.0712 1.82359 13.224 1.97562C13.3761 2.12842 13.4965 2.30982 13.5781 2.50935C13.6599 2.70917 13.7012 2.92321 13.6998 3.13911C13.6983 3.35501 13.6541 3.56847 13.5696 3.76717C13.4851 3.96586 13.3621 4.14584 13.2077 4.2967L13.2076 4.29667L13.2035 4.30083L4.79398 12.7103L1.69413 13.5054L2.48871 10.4056L10.8982 1.99613L10.8982 1.99615L10.9022 1.99207C11.0532 1.83764 11.2332 1.71464 11.432 1.63019C11.6307 1.54573 11.8443 1.5015 12.0602 1.50004C12.2762 1.49858 12.4903 1.53992 12.6902 1.62167ZM6.5 14C6.22386 14 6 14.2239 6 14.5C6 14.7761 6.22386 15 6.5 15H12C12.2761 15 12.5 14.7761 12.5 14.5C12.5 14.2239 12.2761 14 12 14H6.5Z"
      />
    </svg>
  )
  
  export default Pencil
  