const Folder = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    className="fill"
    data-testid="Folder"
  >
    <path
      stroke="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.82322 7.82322C4.87011 7.77634 4.9337 7.75 5 7.75H11.6667C11.7208 7.75 11.7734 7.76754 11.8167 7.8L15.2833 10.4C15.5863 10.6272 15.9547 10.75 16.3333 10.75H25C25.0663 10.75 25.1299 10.7763 25.1768 10.8232C25.2237 10.8701 25.25 10.9337 25.25 11V13.25H18.3028C17.9573 13.25 17.6195 13.3523 17.3321 13.5439L14.8359 15.208C14.7948 15.2354 14.7466 15.25 14.6972 15.25H8.67703C8.32728 15.25 7.98555 15.3548 7.69593 15.5509C7.40631 15.747 7.18209 16.0253 7.0522 16.3501L4.75 22.1056V8C4.75 7.9337 4.77634 7.87011 4.82322 7.82322ZM3.50709 26.5653C3.6389 26.6803 3.81132 26.75 4 26.75H26C26.3228 26.75 26.6094 26.5434 26.7115 26.2372L30.2728 15.5534C30.3605 15.2903 30.3844 15.0102 30.3426 14.7361C30.3007 14.462 30.1944 14.2017 30.0322 13.9768C29.8701 13.7518 29.6568 13.5686 29.41 13.4422C29.1632 13.3159 28.8899 13.25 28.6126 13.25H26.75V11C26.75 10.5359 26.5656 10.0908 26.2374 9.76256C25.9092 9.43437 25.4641 9.25 25 9.25H16.3333C16.2792 9.25 16.2266 9.23246 16.1833 9.2L12.7167 6.6C12.4137 6.37281 12.0453 6.25 11.6667 6.25H5C4.53587 6.25 4.09075 6.43438 3.76256 6.76256C3.43438 7.09075 3.25 7.53587 3.25 8V25.9976V26C3.25 26.1901 3.32074 26.3637 3.43733 26.4959C3.45914 26.5207 3.48246 26.5438 3.50709 26.5653ZM26 14.75H18.3028C18.2534 14.75 18.2052 14.7646 18.1641 14.792L15.668 16.4561C15.3805 16.6477 15.0427 16.75 14.6972 16.75H8.67703C8.62707 16.75 8.57825 16.765 8.53688 16.793C8.4955 16.821 8.46347 16.8608 8.44491 16.9072L5.10777 25.25H25.4594L28.8497 15.0791C28.8623 15.0415 28.8657 15.0015 28.8597 14.9623C28.8537 14.9231 28.8385 14.886 28.8154 14.8538C28.7922 14.8217 28.7618 14.7955 28.7265 14.7775C28.6912 14.7594 28.6522 14.75 28.6126 14.75H26Z"
    />
  </svg>
)
export default Folder
