import { TTheme } from '../types'
import animation from '../tokens/aviva/animation.json'
import breakpoints from "../tokens/aviva/breakpoints.json"
import buttons from "../tokens/aviva/buttons.json"
import colors from '../tokens/aviva/colors.json'
import iconsizing from '../tokens/aviva/iconsizing.json'
import overlay from '../tokens/aviva/overlay.json'
import radius from '../tokens/aviva/radius.json'
import shadows from '../tokens/aviva/shadows.json'
import typeface from '../tokens/aviva/typeface.json'
import typography from '../tokens/aviva/typography.json'

const tokens = {
  mode: 'AVIVA',
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  typeface,
  typography,
} as TTheme
export default tokens
