const FolderIconsFinances = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.57195 5.33081V58.6371C8.57546 61.5799 10.96 63.9644 13.9023 63.968H50.1508C53.0936 63.9644 55.4781 61.5799 55.4817 58.6371V5.33081C55.4781 2.38808 53.0936 0.00351318 50.1508 0H13.9023C10.96 0.00351318 8.57546 2.38808 8.57195 5.33081ZM10.704 5.33081C10.704 3.56412 12.1361 2.13206 13.9023 2.13206H50.1508C51.9171 2.13206 53.3492 3.56412 53.3492 5.33081V58.6371C53.3492 60.4038 51.9171 61.8355 50.1508 61.8355H13.9023C12.1361 61.8355 10.704 60.4038 10.704 58.6371V5.33081Z" />
    <path d="M14.9686 20.2565H49.0846C49.6735 20.2565 50.1509 19.7791 50.1509 19.1902V7.46283C50.1509 6.87393 49.6735 6.39702 49.0846 6.39702H14.9686C14.3797 6.39702 13.9023 6.87393 13.9023 7.46283V19.1902C13.9023 19.7791 14.3797 20.2565 14.9686 20.2565ZM16.0348 8.52908H48.0188V18.1244H16.0348V8.52908Z" />
    <path d="M36.2912 22.3887H27.7621C27.1732 22.3887 26.6959 22.866 26.6959 23.4549V31.984C26.6959 32.5729 27.1732 33.0503 27.7621 33.0503H36.2912C36.8797 33.0503 37.357 32.5729 37.357 31.984V23.4549C37.357 22.866 36.8801 22.3887 36.2912 22.3887ZM35.225 30.9178H28.8279V24.5212H35.225V30.9178Z" />
    <path d="M49.085 22.3887H40.5559C39.967 22.3887 39.4896 22.866 39.4896 23.4549V31.984C39.4896 32.5729 39.967 33.0503 40.5559 33.0503H49.085C49.6734 33.0503 50.1508 32.5729 50.1508 31.984V23.4549C50.1508 22.866 49.6734 22.3887 49.085 22.3887ZM48.0187 30.9178H41.6217V24.5212H48.0187V30.9178Z" />
    <path d="M14.9686 33.0503H23.4977C24.0866 33.0503 24.5639 32.5729 24.5639 31.984V23.4549C24.5639 22.866 24.0866 22.3887 23.4977 22.3887H14.9686C14.3797 22.3887 13.9023 22.866 13.9023 23.4549V31.984C13.9023 32.5729 14.3797 33.0503 14.9686 33.0503ZM16.0348 24.5212H22.4314V30.9178H16.0348V24.5212Z" />
    <path d="M36.2912 35.1824H27.7621C27.1732 35.1824 26.6959 35.6597 26.6959 36.2486V44.7777C26.6959 45.3666 27.1732 45.8435 27.7621 45.8435H36.2912C36.8797 45.8435 37.357 45.3666 37.357 44.7777V36.2486C37.357 35.6597 36.8801 35.1824 36.2912 35.1824ZM35.225 43.7114H28.8279V37.3148H35.225V43.7114Z" />
    <path d="M49.085 35.1824H40.5559C39.967 35.1824 39.4896 35.6597 39.4896 36.2486V57.5709C39.4896 58.1598 39.967 58.6372 40.5559 58.6372H49.085C49.6734 58.6372 50.1508 58.1598 50.1508 57.5709V36.2486C50.1508 35.6597 49.6734 35.1824 49.085 35.1824ZM48.0187 56.5051H41.6217V37.3148H48.0187V56.5051Z" />
    <path d="M14.9686 45.8435H23.4977C24.0866 45.8435 24.5639 45.3666 24.5639 44.7777V36.2486C24.5639 35.6597 24.0866 35.1824 23.4977 35.1824H14.9686C14.3797 35.1824 13.9023 35.6597 13.9023 36.2486V44.7777C13.9023 45.3666 14.3797 45.8435 14.9686 45.8435ZM16.0348 37.3148H22.4314V43.7114H16.0348V37.3148Z" />
    <path d="M36.2912 47.9759H27.7621C27.1732 47.9759 26.6959 48.4533 26.6959 49.0422V57.5709C26.6959 58.1598 27.1732 58.6371 27.7621 58.6371H36.2912C36.8797 58.6371 37.357 58.1598 37.357 57.5709V49.0422C37.357 48.4533 36.8801 47.9759 36.2912 47.9759ZM35.225 56.5051H28.8279V50.108H35.225V56.5051Z" />
    <path d="M14.9686 58.6371H23.4977C24.0866 58.6371 24.5639 58.1598 24.5639 57.5709V49.0422C24.5639 48.4533 24.0866 47.9759 23.4977 47.9759H14.9686C14.3797 47.9759 13.9023 48.4533 13.9023 49.0422V57.5709C13.9023 58.1598 14.3797 58.6371 14.9686 58.6371ZM16.0348 50.1084H22.4314V56.5051H16.0348V50.1084Z" />
  </svg>
)
export default FolderIconsFinances
