import { OrganisationsUsersDto } from "legado-generated-api-client"

export const getAllOrganisationClientsResponse: OrganisationsUsersDto = {
  activatedUserCount: 6,
  pendingUserCount: 4,
  filteredActivatedUserCount: 6,
  filteredPendingUserCount: 4,
  users: [
    {
      userId: "057d6bb2-dea0-4f7f-8b0f-6d9c5b3675f8",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser2@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser2@email.com",
      created: new Date("2023-10-05T16:04:14.5"),
      activationDate: null,
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("0001-01-01T00:00:00"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
    {
      userId: "0b7b545b-4849-44e6-80d2-52f705854215",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser3@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser3@email.com",
      created: new Date("2023-10-05T16:05:07.67"),
      activationDate: null,
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("0001-01-01T00:00:00"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
    {
      userId: "57607902-e99e-46ca-bfdf-d94a5e6b98b7",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser6@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser6@email.com",
      created: new Date("2023-10-05T16:07:23.33"),
      activationDate: new Date("2023-10-05T16:07:23.33"),
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("2023-10-05T16:07:23.33"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
    {
      userId: "a04444ee-3de2-4ccf-8e9c-5c99680def76",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser4@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser4@email.com",
      created: new Date("2023-10-05T16:06:16.66"),
      activationDate: new Date("2023-10-05T16:06:16.66"),
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("2023-10-05T16:06:16.66"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
    {
      userId: "c8a2b1a3-471a-4b77-b735-a454f291cb3a",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser5@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser5@email.com",
      created: new Date("2023-10-05T16:06:49.5566667"),
      activationDate: new Date("2023-10-05T16:06:49.5566667"),
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("2023-10-05T16:06:49.5566667"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },

    {
      userId: "057d6bb2-dea0-4f7f-8b0f-6d9c5b3675f8",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser10@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser2@email.com",
      created: new Date("2023-10-05T16:04:14.5"),
      activationDate: null,
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("0001-01-01T00:00:00"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
    {
      userId: "0b7b545b-4849-44e6-80d2-52f705854215",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser14@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser3@email.com",
      created: new Date("2023-10-05T16:05:07.67"),
      activationDate: null,
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("0001-01-01T00:00:00"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
    {
      userId: "57607902-e99e-46ca-bfdf-d94a5e6b98b7",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser20@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser6@email.com",
      created: new Date("2023-10-05T16:07:23.33"),
      activationDate: new Date("2023-10-05T16:07:23.33"),
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("2023-10-05T16:07:23.33"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
    {
      userId: "a04444ee-3de2-4ccf-8e9c-5c99680def76",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser4@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser25@email.com",
      created: new Date("2023-10-05T16:06:16.66"),
      activationDate: new Date("2023-10-05T16:06:16.66"),
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("2023-10-05T16:06:16.66"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
    {
      userId: "57607902-e99e-46ca-bfdf-d94a5e6b98b7",
      firstName: "TestUserName",
      surname: "TestUserSurname",
      email: "FortCirrusUser56@email.com",
      emailConfirmed: true,
      userName: "FortCirrusUser6@email.com",
      created: new Date("2023-10-05T16:07:23.33"),
      activationDate: new Date("2023-10-05T16:07:23.33"),
      acceptedTermsAndConditions: null,
      countryCode: null,
      phoneNumber: null,
      phoneNumberConfirmed: true,
      roles: [],
      organisation: undefined,
      organisationId: "00000000-0000-0000-0000-000000000000",
      lastLogin: new Date("2023-10-05T16:07:23.33"),
      modules: null,
      theme: null,
      platform: null,
      verificationCode: null,
    },
  ],
}
