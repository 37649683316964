const DragAndDrop = () => (
  <svg
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.32843 0.671573 3 1.5 3Z"
      fill="#282828"
    />
    <path
      d="M10.5 3C11.3284 3 12 2.32843 12 1.5C12 0.671573 11.3284 0 10.5 0C9.67157 0 9 0.671573 9 1.5C9 2.32843 9.67157 3 10.5 3Z"
      fill="#282828"
    />
    <path
      d="M1.5 11.5C2.32843 11.5 3 10.8284 3 10C3 9.17157 2.32843 8.5 1.5 8.5C0.671573 8.5 0 9.17157 0 10C0 10.8284 0.671573 11.5 1.5 11.5Z"
      fill="#282828"
    />
    <path
      d="M10.5 11.5C11.3284 11.5 12 10.8284 12 10C12 9.17157 11.3284 8.5 10.5 8.5C9.67157 8.5 9 9.17157 9 10C9 10.8284 9.67157 11.5 10.5 11.5Z"
      fill="#282828"
    />
    <path
      d="M1.5 20C2.32843 20 3 19.3284 3 18.5C3 17.6716 2.32843 17 1.5 17C0.671573 17 0 17.6716 0 18.5C0 19.3284 0.671573 20 1.5 20Z"
      fill="#282828"
    />
    <path
      d="M10.5 20C11.3284 20 12 19.3284 12 18.5C12 17.6716 11.3284 17 10.5 17C9.67157 17 9 17.6716 9 18.5C9 19.3284 9.67157 20 10.5 20Z"
      fill="#282828"
    />
  </svg>
)

export default DragAndDrop
