import { IContactInvite, IUser } from "../../api/api-client/api-types"
import {
  addContact,
  setLoadingState,
  addOrReplaceContactsShares,
  setClients,
} from "../../contexts/application/action"
import { addContactApi, TRadioOptions } from "../../utils/shares"
import { showSuccessOrErrorMessage } from "../modules/NotificationPopUp/NotificationPopUp"
import { sendGaData } from "../../utils/gaEvents"
import { ApiController } from "../../api/apiController"
import { Dispatch } from "react"
import { IToastAction } from "../../contexts/toasts/reducer"

export const addContactApiAndApp = async ({
  dispatch,
  toastDispatch,
  contact,
  sharingOptions,
  onComplete,
  onClose,
  selectedSharingWithMeUserId,
}: {
  dispatch: (value: any) => void
  toastDispatch: Dispatch<IToastAction>
  contact: IContactInvite
  sharingOptions: TRadioOptions[]
  onComplete: () => void
  onClose?: () => void
  selectedSharingWithMeUserId?: string
}) => {
  dispatch(setLoadingState(true))
  sendGaData({
    name: "cm_create",
    has_personal_note: contact.message !== "" ? "Y" : "N",
  })

  const newContactAndShares = await addContactApi({
    contact,
    sharingOptions,
    selectedSharingWithMeUserId,
  })

  sendGaData({
    name: "cm_create_response",
    has_personal_note: contact.message !== "" ? "Y" : "N",
    request_status: newContactAndShares ? "success" : "error",
  })

  dispatch(setLoadingState(false))

  showSuccessOrErrorMessage({
    hasError: !newContactAndShares,
    methodName: "addContact",
    toastDispatch,
  })

  if (!newContactAndShares) {
    if (onClose) {
      onClose()
    }
    return
  }

  dispatch(addContact(newContactAndShares.contact))
  dispatch(addOrReplaceContactsShares(newContactAndShares.nodeShares))
  onComplete()
}

export const addClientApiAndApp = async ({
  dispatch,
  toastDispatch,
  client,
  onComplete,
  onClose,
  clients,
}: {
  dispatch: (value: any) => void
  toastDispatch: Dispatch<IToastAction>
  client: IContactInvite
  onComplete: () => void
  onClose?: () => void
  clients?: IUser[]
}) => {
  dispatch(setLoadingState(true))

  const apiController = ApiController.getInstance()
  const newContactDto = await apiController.addClient(client)
  const newUser = newContactDto as unknown as IUser

  dispatch(setLoadingState(false))

  showSuccessOrErrorMessage({
    hasError: !newUser,
    methodName: "addContact",
    toastDispatch,
  })

  if (!newContactDto) {
    if (onClose) {
      onClose()
    }
    return
  }
  dispatch(setClients([...(clients ?? []), newUser]))
  onComplete()
}
