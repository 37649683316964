import { TTheme } from "../types"
import animation from "../tokens/fortCirrus/animation.json"
import breakpoints from "../tokens/fortCirrus/breakpoints.json"
import buttons from "../tokens/fortCirrus/buttons.json"
import colors from "../tokens/fortCirrus/colors.json"
import iconsizing from "../tokens/fortCirrus/iconsizing.json"
import overlay from "../tokens/fortCirrus/overlay.json"
import radius from "../tokens/fortCirrus/radius.json"
import shadows from "../tokens/fortCirrus/shadows.json"
import typeface from "../tokens/fortCirrus/typeface.json"
import typography from "../tokens/fortCirrus/typography.json"

const tokens = {
  mode: "FORT_CIRRUS",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  typeface,
  typography,
} as TTheme
export default tokens
