import { FC } from "react"
import { useGetIcon } from "../../styled-components/GetIconLibraryInTheme"
import { IContactInvite } from "../../api/api-client/api-types"
import { Button } from "../atoms/Button"
import { ModalFooter, ModalContent } from "../modules/Modal"
import "./CreateContact.scss"

export type ContactSentProps = {
  addingSharee?: boolean
  contact: IContactInvite
  onClose: () => void
  isClient?: boolean
}
export const ContactSent: FC<ContactSentProps> = ({
  contact,
  onClose,
  isClient = false,
}) => {
  const contactCheckMarkIcon = useGetIcon("NomineeAdded")

  const possessiveName = `${contact.firstName}'${
    contact.firstName?.charAt(contact.firstName.length - 1).toLowerCase() !==
    "s"
      ? "s"
      : ""
  }`

  return (
    <>
      <ModalContent>
        <div className="flex m-auto contact-sent-icon icon-box">
          {contactCheckMarkIcon}
        </div>
        <div>
          <h1 className="invite-sent-header text-center">
            {isClient ? "Client" : "Contact"} invite has been sent.{" "}
          </h1>
        </div>
        <div className="create-contact-text contact-sent-text text-center">
          A notification will be sent by email when <b>{contact.email} </b>
          accepts this invitation.
        </div>
        <div className="create-contact-text text-center follow-up">
          {`Note: To ensure that invitation doesn't go missing in ${possessiveName} email inbox. Please reach out to this ${
            isClient ? "client" : "contact"
          } to make sure the invitation is found`}
        </div>
      </ModalContent>
      <ModalFooter className="flex justify-content-center">
        <Button
          className="contact-invite-done-button"
          variant="primary"
          onClick={onClose}
        >
          Done
        </Button>
      </ModalFooter>
    </>
  )
}

export default ContactSent
