import React from "react"
import CoopLogo from "../../../design-tokens/imgs/coop/CoopLogo" //TODO: Replace this so it uses the current theme's logo so is reusable https://dev.azure.com/secure-the-file/Application/_workitems/edit/15325
import { FormError } from "../../../utils/forms/checkFormValidity"
import AlertMessage, {
  MessageType,
} from "../../modules/AlertMessage/AlertMessage"
import "./PanelHeader.css"

interface PanelHeaderProps {
  title: string
  paragraphText: React.ReactElement
  error?: string
  inputErrors: FormError[]
}

const PanelHeader: React.FC<PanelHeaderProps> = ({
  title,
  paragraphText,
  error = "",
  inputErrors,
}) => {
  return (
    <>
      <div className="sign-in-panel__logo">
        <CoopLogo />
      </div>
      {error !== "" || inputErrors.length ? (
        <AlertMessage
          messageType={MessageType.ERROR}
          title="There's a problem"
          message={error ? error : "Check the form. You must:"} //TODO: Check that the server error message renders correctly when we get API https://dev.azure.com/secure-the-file/Application/_workitems/edit/15328
          alertItems={inputErrors.map((error) => ({
            ...error,
            href: error.id.toString(),
          }))}
        />
      ) : null}
      {title !== "" && <h1 className="sign-in-panel__title">{title}</h1>}
      {<p className="sign-in-panel__paragraph">{paragraphText}</p>}
    </>
  )
}

export default PanelHeader
