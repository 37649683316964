const Literature = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0279 5.86371L4 23.9474H18.9072C19.4372 23.9474 19.9108 23.6166 20.0932 23.1191L26.1142 6.69801C26.4165 5.87366 25.8063 5 24.9283 5H11.2262C10.6825 5 10.1998 5.34791 10.0279 5.86371ZM12.2105 10.0526C12.2105 9.70383 12.4933 9.42107 12.8421 9.42107H21.6842V10.6842H12.8421C12.4933 10.6842 12.2105 10.4015 12.2105 10.0526ZM11.579 13.2105C11.2302 13.2105 10.9474 13.4933 10.9474 13.8421C10.9474 14.1909 11.2302 14.4737 11.579 14.4737H20.4211V13.2105H11.579Z"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      d="M27.9627 11.5297C28.0808 11.2015 27.9106 10.8397 27.5824 10.7215C27.2542 10.6034 26.8923 10.7737 26.7742 11.1019L27.9627 11.5297ZM22.2856 25.4347L21.6914 25.2207L22.2856 25.4347ZM4.00001 23.9474V24.5789H5.26317V23.9474H4.00001ZM7.15791 27.7368H19.9087V26.4737H7.15791V27.7368ZM22.8799 25.6486L27.9627 11.5297L26.7742 11.1019L21.6914 25.2207L22.8799 25.6486ZM19.9087 27.7368C21.2403 27.7368 22.4288 26.9015 22.8799 25.6486L21.6914 25.2207C21.4208 25.9725 20.7076 26.4737 19.9087 26.4737V27.7368ZM4.00001 24.5789C4.00001 26.323 5.41385 27.7368 7.15791 27.7368V26.4737C6.11147 26.4737 5.26317 25.6254 5.26317 24.5789H4.00001Z"
      fill="var(--color-universal-secondary-b)"
    />
  </svg>
)
export default Literature
