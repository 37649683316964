const WelcomeSecure = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    className="reverse"
  >
    <path
      d="M30 4V56.5L37 53.5L43.5 48.5L50 37L52 26.5L51.5 13L30 4Z"
      className="custom-1"
      stroke="none"
    />
    <path
      d="M8.25 27.5V12.9874L30 3.32074L51.75 12.9874V27.5C51.75 40.9962 42.4457 53.5907 30 56.7273C17.5543 53.5907 8.25 40.9962 8.25 27.5Z"
      strokeWidth="1.5"
    />
    <path
      d="M20 30L26.6667 37L40 23"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default WelcomeSecure
