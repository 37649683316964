export const WelcomeLogo = () => (
  <div className="svg">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="170"
      height="59"
      viewBox="0 0 170 59"
      fill="none"
    >
      <path
        d="M2.71866 25.9362V1.05871H15.4627V3.88225H6.19081V11.7042H15.4627V14.5659H6.19081V25.9362H2.71866Z"
        fill="#595C5E"
      />
      <path
        d="M29.5272 7.21219C30.4553 7.21219 31.4549 7.35498 32.5259 7.64058C33.6207 7.90237 34.6322 8.39026 35.5603 9.10424C36.4885 9.81823 37.262 10.794 37.8808 12.0316C38.5234 13.2691 38.8447 14.8518 38.8447 16.7796C38.8447 18.7073 38.5234 20.29 37.8808 21.5276C37.262 22.7413 36.4885 23.7052 35.5603 24.4192C34.6322 25.1094 33.6207 25.5854 32.5259 25.8472C31.4549 26.1328 30.4553 26.2756 29.5272 26.2756C28.6228 26.2756 27.6351 26.1328 26.5641 25.8472C25.4932 25.5854 24.4936 25.1094 23.5654 24.4192C22.6372 23.7052 21.8637 22.7413 21.245 21.5276C20.6262 20.29 20.3168 18.7073 20.3168 16.7796C20.3168 14.8518 20.6262 13.2691 21.245 12.0316C21.8637 10.794 22.6372 9.81823 23.5654 9.10424C24.4936 8.39026 25.4932 7.90237 26.5641 7.64058C27.6351 7.35498 28.6228 7.21219 29.5272 7.21219ZM29.5272 23.5624C30.3126 23.5624 31.0503 23.4315 31.7405 23.1697C32.4545 22.8841 33.0614 22.4557 33.5612 21.8846C34.0848 21.3134 34.5013 20.6113 34.8106 19.7783C35.12 18.9215 35.2747 17.9219 35.2747 16.7796C35.2747 15.6372 35.12 14.6376 34.8106 13.7808C34.5013 12.9241 34.0848 12.2101 33.5612 11.6389C33.0614 11.0677 32.4545 10.6393 31.7405 10.3537C31.0503 10.0681 30.3126 9.92533 29.5272 9.92533C28.7656 9.92533 28.0397 10.0681 27.3495 10.3537C26.6831 10.6393 26.0881 11.0677 25.5646 11.6389C25.0648 12.2101 24.6602 12.9241 24.3508 13.7808C24.0652 14.6376 23.9224 15.6372 23.9224 16.7796C23.9224 17.9219 24.0652 18.9215 24.3508 19.7783C24.6602 20.6113 25.0648 21.3134 25.5646 21.8846C26.0881 22.4557 26.6831 22.8841 27.3495 23.1697C28.0397 23.4315 28.7656 23.5624 29.5272 23.5624Z"
        fill="#595C5E"
      />
      <path
        d="M54.3944 10.4608C54.2278 10.4132 54.0493 10.3656 53.8589 10.318C53.6923 10.2704 53.5019 10.2347 53.2877 10.2109C53.0973 10.1633 52.9069 10.1395 52.7165 10.1395C51.6932 10.1395 50.8483 10.3775 50.1819 10.8535C49.5155 11.3057 48.9919 11.8888 48.6111 12.6028C48.2303 13.3167 47.9566 14.114 47.79 14.9946C47.6472 15.8752 47.5758 16.732 47.5758 17.565V25.8472H44.2201V7.64058H47.0761L47.4687 10.4608C47.8019 9.86583 48.1708 9.34224 48.5754 8.89005C48.98 8.41406 49.456 8.03327 50.0034 7.74767C50.5508 7.46208 51.1815 7.29548 51.8955 7.24789C52.6094 7.17649 53.4424 7.25979 54.3944 7.49778V10.4608Z"
        fill="#595C5E"
      />
      <path
        d="M63.7782 10.318V20.6351C63.7782 22.5628 64.5992 23.5267 66.2414 23.5267C66.9316 23.5267 67.6337 23.3482 68.3476 22.9912V25.5973C67.3719 26.0495 66.3366 26.2756 65.2418 26.2756C63.6711 26.2756 62.4454 25.8353 61.5648 24.9547C60.708 24.0503 60.2796 22.7294 60.2796 20.9921V10.318H57.031V7.56918H60.2796V3.17818L63.7782 2.3571V7.56918H68.5975V10.318H63.7782Z"
        fill="#595C5E"
      />
      <path
        d="M84.729 0.465039C85.6334 0.465039 86.4901 0.524538 87.2993 0.643535C88.1085 0.762532 88.8225 0.881529 89.4413 1.00053C90.1553 1.16712 90.8216 1.34562 91.4404 1.53601V4.57045C90.3694 4.28485 89.2628 4.05876 88.1204 3.89216C86.978 3.70177 85.8595 3.64227 84.7647 3.71367C83.6699 3.76126 82.6227 3.96356 81.6231 4.32055C80.6474 4.67754 79.7906 5.23683 79.0528 5.99841C78.315 6.7362 77.72 7.71198 77.2678 8.92575C76.8395 10.1157 76.6253 11.6032 76.6253 13.3881C76.6253 15.1731 76.8395 16.6725 77.2678 17.8862C77.72 19.1 78.315 20.0877 79.0528 20.8493C79.7906 21.5871 80.6474 22.1344 81.6231 22.4914C82.6227 22.8246 83.6699 23.015 84.7647 23.0626C85.8595 23.1102 86.978 23.0507 88.1204 22.8841C89.2628 22.7175 90.3694 22.4795 91.4404 22.1701V25.0975C90.8216 25.3355 90.1553 25.5378 89.4413 25.7044C88.8225 25.8472 88.1085 25.9781 87.2993 26.0971C86.4901 26.2161 85.6334 26.2756 84.729 26.2756C83.9198 26.2756 83.063 26.1923 82.1586 26.0257C81.2543 25.8829 80.3618 25.633 79.4812 25.276C78.6006 24.8952 77.7557 24.3954 76.9465 23.7766C76.1612 23.134 75.4591 22.3367 74.8403 21.3848C74.2215 20.4328 73.7336 19.3023 73.3766 17.9933C73.0196 16.6606 72.8411 15.1255 72.8411 13.3881C72.8411 10.794 73.2338 8.66395 74.0192 6.99799C74.8046 5.30823 75.7804 3.98736 76.9465 3.03538C78.1365 2.0596 79.4217 1.39322 80.8021 1.03623C82.2062 0.655435 83.5152 0.465039 84.729 0.465039Z"
        fill="#F04523"
      />
      <path
        d="M100.285 25.8472H96.9294V7.64058H100.285V25.8472Z"
        fill="#F04523"
      />
      <path
        d="M117.669 10.4608C117.502 10.4132 117.324 10.3656 117.133 10.318C116.967 10.2704 116.776 10.2347 116.562 10.2109C116.372 10.1633 116.181 10.1395 115.991 10.1395C114.967 10.1395 114.122 10.3775 113.456 10.8535C112.79 11.3057 112.266 11.8888 111.885 12.6028C111.505 13.3167 111.231 14.114 111.064 14.9946C110.921 15.8752 110.85 16.732 110.85 17.565V25.8472H107.494V7.64058H110.35L110.743 10.4608C111.076 9.86583 111.445 9.34224 111.85 8.89005C112.254 8.41406 112.73 8.03327 113.278 7.74767C113.825 7.46208 114.456 7.29548 115.17 7.24789C115.884 7.17649 116.717 7.25979 117.669 7.49778V10.4608Z"
        fill="#F04523"
      />
      <path
        d="M132.726 10.4608C132.56 10.4132 132.381 10.3656 132.191 10.318C132.024 10.2704 131.834 10.2347 131.62 10.2109C131.429 10.1633 131.239 10.1395 131.049 10.1395C130.025 10.1395 129.18 10.3775 128.514 10.8535C127.848 11.3057 127.324 11.8888 126.943 12.6028C126.562 13.3167 126.289 14.114 126.122 14.9946C125.979 15.8752 125.908 16.732 125.908 17.565V25.8472H122.552V7.64058H125.408L125.801 10.4608C126.134 9.86583 126.503 9.34224 126.907 8.89005C127.312 8.41406 127.788 8.03327 128.335 7.74767C128.883 7.46208 129.513 7.29548 130.227 7.24789C130.941 7.17649 131.774 7.25979 132.726 7.49778V10.4608Z"
        fill="#F04523"
      />
      <path
        d="M143.906 26.2756C141.526 26.2756 139.789 25.5735 138.694 24.1693C137.6 22.7651 137.052 20.766 137.052 18.1718V7.64058H140.444V17.0652C140.444 19.2071 140.717 20.8017 141.265 21.8489C141.812 22.896 142.823 23.4672 144.299 23.5624C145.251 23.5624 146.072 23.3839 146.762 23.0269C147.476 22.6461 148.059 22.1345 148.512 21.4919C148.964 20.8255 149.297 20.0401 149.511 19.1357C149.725 18.2075 149.832 17.1842 149.832 16.0656V7.64058H153.188V25.8472H150.368L149.832 22.9555C149.594 23.5029 149.261 23.9908 148.833 24.4192C148.404 24.8238 147.917 25.1689 147.369 25.4545C146.846 25.7163 146.286 25.9186 145.691 26.0614C145.096 26.2042 144.501 26.2756 143.906 26.2756Z"
        fill="#F04523"
      />
      <path
        d="M168.286 11.5675C167.596 11.0439 166.87 10.6393 166.109 10.3537C165.371 10.0681 164.621 9.92533 163.86 9.92533C163.003 9.92533 162.313 10.1038 161.789 10.4608C161.289 10.794 161.039 11.3771 161.039 12.2101C161.039 12.8765 161.254 13.4119 161.682 13.8165C162.134 14.1973 162.694 14.5424 163.36 14.8518C164.05 15.1374 164.776 15.423 165.538 15.7086C166.323 15.9704 167.049 16.3155 167.715 16.7439C168.382 17.1485 168.929 17.6721 169.357 18.3146C169.786 18.9572 170 19.8021 170 20.8493C170 21.7775 169.821 22.5866 169.464 23.2768C169.107 23.967 168.643 24.5382 168.072 24.9904C167.501 25.4188 166.835 25.7401 166.073 25.9543C165.335 26.1685 164.574 26.2756 163.788 26.2756C162.598 26.2756 161.432 26.0376 160.29 25.5616C159.147 25.0856 158.172 24.3954 157.362 23.491L158.433 21.0278C159.195 21.7894 160.052 22.4081 161.004 22.8841C161.98 23.3363 162.932 23.5624 163.86 23.5624C164.764 23.5624 165.502 23.3839 166.073 23.0269C166.668 22.6461 166.977 22.0274 167.001 21.1706C167.001 20.409 166.787 19.8259 166.359 19.4213C165.93 18.9929 165.383 18.6478 164.716 18.386C164.074 18.1242 163.36 17.8862 162.575 17.6721C161.813 17.4341 161.099 17.1128 160.433 16.7082C159.766 16.3036 159.207 15.7681 158.755 15.1017C158.326 14.4353 158.112 13.5309 158.112 12.3886C158.112 11.4604 158.267 10.675 158.576 10.0324C158.909 9.36604 159.35 8.83055 159.897 8.42596C160.445 7.99757 161.075 7.68818 161.789 7.49778C162.503 7.30738 163.253 7.21219 164.038 7.21219C165.014 7.21219 165.954 7.37878 166.858 7.71198C167.787 8.02137 168.632 8.46166 169.393 9.03285L168.286 11.5675Z"
        fill="#F04523"
      />
      <path
        d="M96.8341 0.289062H100.402V3.86499H96.8341V0.289062Z"
        fill="#F04523"
      />
      <path
        d="M53.8753 34.7632C27.3687 34.7632 7.64215 45.366 0 50.8702C7.26593 47.5444 24.9128 38.7622 53.8753 38.7622C87.2789 38.7622 96.3721 56.9798 134.744 56.9798C147.313 56.9798 167.736 48.8518 167.736 36.6516C165.585 43.3137 155.869 52.7337 134.744 52.7337C99.7787 52.7337 90.1927 34.7632 53.8753 34.7632Z"
        fill="#F04523"
      />
      <path
        d="M54.7165 44.6531C32.9735 44.6531 17.5807 50.8332 10.8053 55.5616L10.8981 55.5364C18.755 53.402 34.2853 49.1829 54.7165 49.1829C72.3267 49.1829 89.3255 56.0828 100.199 58.7047C94.0981 54.0209 74.2016 44.6531 54.7165 44.6531Z"
        fill="#595C5E"
      />
    </svg>
  </div>
)
