import { IFolder, IFile } from "../api-types"
import { getFolderNotClone } from "../api-handler"
import { findAndUpdateParentFolders } from "./findAndUpdateParentFolders"
const cloneDeep = require("clone-deep")

export const fileInFolderAddFileStart =
  ({ file }: { file: IFile }) =>
  ({ folder }: { folder: IFolder }) => {
    const contents = folder.contents || []
    if (!contents.find((content) => content.id === file.id)) {
      contents.unshift(file)
    }
    folder.contents = contents
    return folder
  }

export const fileInFolderChangeFile =
  ({ file, fileId }: { file: IFile; fileId?: string }) =>
  ({ folder }: { folder: IFolder }) => {
    const newContents = [] as IFile[]
    folder.contents?.forEach((content) => {
      if (fileId && content.id === fileId) {
        newContents.push(file)
        return
      }
      if (!fileId && content.id === file.id) {
        newContents.push(file)
        return
      }
      newContents.push(content)
    })
    folder.contents = newContents
    return folder
  }

export const fileInFolderRemoveFile =
  ({ file }: { file: IFile }) =>
  ({ folder }: { folder: IFolder }) => {
    folder.contents = folder.contents?.filter(
      (content) => content.id !== file.id
    )
    return folder
  }

export const fileInAllFoldersDoSomething = ({
  folders,
  selectedfolderId,
  fn,
}: {
  folders: IFolder[]
  selectedfolderId: string
  fn: ({ folder }: { folder: IFolder }) => void
}) => {
  const foldersCopy = cloneDeep(folders)
  const selectedFolder = getFolderNotClone({
    folders: foldersCopy,
    folderId: selectedfolderId,
  })

  if (selectedFolder) fn({ folder: selectedFolder })

  findAndUpdateParentFolders(foldersCopy, selectedfolderId)

  return foldersCopy
}
