const FnzNotification = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3866 27.1468C13.5356 27.6545 13.8451 28.1003 14.2687 28.4174C14.6922 28.7344 15.2071 28.9058 15.7362 28.9058C16.2653 28.9058 16.7802 28.7344 17.2037 28.4174C17.6273 28.1003 17.9368 27.6545 18.0858 27.1468"
        fill="none"
      />
      <path d="M15.7335 5.14659V2.50659" fill="none" />
      <path
        d="M15.7332 5.14681C18.0671 5.14681 20.3054 6.07395 21.9558 7.72427C23.6061 9.37459 24.5332 11.6129 24.5332 13.9468C24.5332 22.2141 26.2932 23.6268 26.2932 23.6268H5.17322C5.17322 23.6268 6.93322 21.3787 6.93322 13.9468C6.93322 11.6129 7.86036 9.37459 9.51068 7.72427C11.161 6.07395 13.3993 5.14681 15.7332 5.14681V5.14681Z"
        fill="none"
      />
    </svg>
  )
}
export default FnzNotification
