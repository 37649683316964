const ContactAdded = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z"
      strokeWidth="2"
      strokeMiterlimit="10"
      fill="none"
    />
    <path
      d="M3.8736 26.9988C5.10305 24.8708 6.87086 23.1037 8.99945 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4578 20 20.8722 20.6468 23.0007 21.8754C25.1293 23.1039 26.8971 24.871 28.1265 26.9991"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <rect
      x="16"
      y="14"
      width="16"
      height="16"
      rx="8"
      className="fill-white"
      stroke="none"
    />
    <path
      d="M17.0396 24.3978C16.7182 24.1076 16.2367 24.0842 15.8886 24.342V24.342C15.4283 24.6828 15.3938 25.3592 15.8169 25.7452L20.4817 30L30.2605 18.8446C30.6469 18.4039 30.543 17.7214 30.0431 17.4155V17.4155C29.6507 17.1754 29.1424 17.2528 28.8392 17.5987L20.2992 27.3406L17.0396 24.3978Z"
      className="fill"
    />
  </svg>
)
export default ContactAdded
