const FilledError = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.916 3.82493C12.5628 2.68694 14.1799 2.68694 14.8267 3.82493L24.2866 20.4681C24.9334 21.6061 24.1249 23.0286 22.8312 23.0286H3.91153C2.61787 23.0286 1.80934 21.6061 2.45617 20.4681L11.916 3.82493Z" 
            fill="#DF1F11"
            stroke="#DF1F11"/>
            
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M13.3721 8.91431C13.7824 8.91431 14.115 9.2469 14.115 9.65716V14.8572C14.115 15.2674 13.7824 15.6 13.3721 15.6C12.9618 15.6 12.6292 15.2674 12.6292 14.8572V9.65716C12.6292 9.2469 12.9618 8.91431 13.3721 8.91431Z" 
            fill="#FEFEFE"
            stroke="#FEFEFE"/>
        <path 
            d="M13.3721 19.3143C13.9875 19.3143 14.4864 18.8154 14.4864 18.2C14.4864 17.5846 13.9875 17.0857 13.3721 17.0857C12.7567 17.0857 12.2578 17.5846 12.2578 18.2C12.2578 18.8154 12.7567 19.3143 13.3721 19.3143Z" 
            fill="#FEFEFE"
            stroke="#FEFEFE"/>
    </svg>
)

export default FilledError