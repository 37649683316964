import { getAnimation } from "../../design-tokens/getters"
import { TTheme } from "../../design-tokens/types"

export const getAnimationVariables = (theme: TTheme) => {
  const animations = getAnimation(theme)
  return `
        --animation-timing-default: ${
          animations.timing.default?.value ?? "0.2s"
        };
    `
}
