export const countryCodes = {
  "UK (+44)": "+44",
  "USA (+1)": "+1",
  "DZ (+213)": "+213",
  "AD (+376)": "+376",
  "AO (+244)": "+244",
  "AI (+1264)": "+1264",
  "AG (+1268)": "+1268",
  "AR (+54)": "+54",
  "AM (+374)": "+374",
  "AW (+297)": "+297",
  "AU (+61)": "+61",
  "AY (+43)": "+43",
  "AZ (+994)": "+994",
  "BS (+1242)": "+1242",
  "BH (+973)": "+973",
  "BD (+880)": "+880",
  "BB (+1246)": "+1246",
  "BY (+375)": "+375",
  "BE (+32)": "+32",
  "BZ (+501)": "+501",
  "BJ (+229)": "+229",
  "BM (+1441)": "+1441",
  "BT (+975)": "+975",
  "BO (+591)": "+591",
  "BA (+387)": "+387",
  "BW (+267)": "+267",
  "BR (+55)": "+55",
  "BN (+673)": "+673",
  "BG (+359)": "+359",
  "BF (+226)": "+226",
  "BI (+257)": "+257",
  "KH (+855)": "+855",
  "CM (+237)": "+237",
  "CV (+238)": "+238",
  "KY (+1345)": "+1345",
  "CF (+236)": "+236",
  "CL (+56)": "+56",
  "CN (+86)": "+86",
  "CO (+57)": "+57",
  "KM (+269)": "+269",
  "CD (+242)": "+242",
  "CK (+682)": "+682",
  "CR (+506)": "+506",
  "HR (+385)": "+385",
  "CU (+53)": "+53",
  "CYN (+90)": "+90",
  "CYS (+357)": "+357",
  "CZ (+420)": "+420",
  "DK (+45)": "+45",
  "DJ (+253)": "+253",
  "DM (+767)": "+767",
  "DO (+1809)": "+1809",
  "EC (+593)": "+593",
  "EG (+20)": "+20",
  "SV (+503)": "+503",
  "GQ (+240)": "+240",
  "ER (+291)": "+291",
  "EE (+372)": "+372",
  "ET (+251)": "+251",
  "FK (+500)": "+500",
  "FO (+298)": "+298",
  "FJ (+679)": "+679",
  "FI (+358)": "+358",
  "FR (+33)": "+33",
  "GF (+594)": "+594",
  "PF (+689)": "+689",
  "GA (+241)": "+241",
  "GM (+220)": "+220",
  "GE (+7880)": "+7880",
  "DE (+49)": "+49",
  "GH (+233)": "+233",
  "GI (+350)": "+350",
  "GR (+30)": "+30",
  "GL (+299)": "+299",
  "GD (+1473)": "+1473",
  "GP (+590)": "+590",
  "GU (+671)": "+671",
  "GT (+502)": "+502",
  "GN (+224)": "+224",
  "GW (+245)": "+245",
  "GY (+592)": "+592",
  "HT (+509)": "+509",
  "HN (+504)": "+504",
  "HK (+852)": "+852",
  "HU (+36)": "+36",
  "IS (+354)": "+354",
  "IN (+91)": "+91",
  "ID (+62)": "+62",
  "IQ (+964)": "+964",
  "IR (+98)": "+98",
  "IE (+353)": "+353",
  "IL (+972)": "+972",
  "IT (+39)": "+39",
  "JM (+1876)": "+1876",
  "JP (+81)": "+81",
  "JO (+962)": "+962",
  "KZ (+7)": "+7",
  "KE (+254)": "+254",
  "KI (+686)": "+686",
  "KR (+82)": "+82",
  "KW (+965)": "+965",
  "KG (+996)": "+996",
  "LA (+856)": "+856",
  "LV (+371)": "+371",
  "LB (+961)": "+961",
  "LS (+266)": "+266",
  "LR (+231)": "+231",
  "LY (+218)": "+218",
  "LI (+417)": "+417",
  "LT (+370)": "+370",
  "LU (+352)": "+352",
  "MO (+853)": "+853",
  "MK (+389)": "+389",
  "MG (+261)": "+261",
  "MW (+265)": "+265",
  "MY (+60)": "+60",
  "MV (+960)": "+960",
  "ML (+223)": "+233",
  "MT (+356)": "+356",
  "MH (+692)": "+692",
  "MQ (+596)": "+596",
  "MR (+222)": "+222",
  "YT (+262)": "+262",
  "MX (+52)": "+52",
  "FM (+691)": "+691",
  "MD (+373)": "+373",
  "MC (+377)": "+377",
  "MN (+976)": "+976",
  "MS (+1664)": "+1664",
  "MA (+212)": "+212",
  "MZ (+258)": "+258",
  "MM (+95)": "+95",
  "NA (+264)": "+264",
  "NR (+674)": "+674",
  "NP (+977)": "+977",
  "NL (+31)": "+31",
  "NC (+687)": "+687",
  "NZ (+64)": "+64",
  "NI (+505)": "+505",
  "NE (+227)": "+227",
  "NG (+234)": "+234",
  "NU (+683)": "+683",
  "NF (+672)": "+672",
  "MP (+670)": "+670",
  "NO (+47)": "+47",
  "OM (+968)": "+968",
  "PK (+92)": "+92",
  "PW (+680)": "+680",
  "PA (+507)": "+507",
  "PG (+675)": "+675",
  "PY (+595)": "+595",
  "PE (+51)": "+51",
  "PH (+63)": "+63",
  "PL (+48)": "+48",
  "PT (+351)": "+351",
  "PR (+1787)": "+1787",
  "QA (+974)": "+974",
  "RE (+262)": "+262",
  "RO (+40)": "+40",
  "RU (+7)": "+7",
  "RW (+250)": "+250",
  "SM (+378)": "+378",
  "ST (+239)": "+239",
  "SA (+966)": "+966",
  "SN (+221)": "+221",
  "RS (+381)": "+381",
  "SC (+248)": "+248",
  "SL (+232)": "+232",
  "SG (+65)": "+65",
  "SK (+421)": "+421",
  "SI (+386)": "+386",
  "SB (+677)": "+677",
  "SO (+252)": "+252",
  "ZA (+27)": "+27",
  "ES (+34)": "+34",
  "LK (+94)": "+94",
  "SH (+290)": "+290",
  "KN+1869)": "+1869",
  "LC (+1758)": "+1758",
  "RW (+597)": "+597",
  "SD (+249)": "+249",
  "SZ (+268)": "+268",
  "SE (+46)": "+46",
  "CH (+41)": "+41",
  "SY (+963)": "+963",
  "TW (+886)": "+886",
  "TJ (+992)": "+992",
  "TH (+66)": "+66",
  "TG (+228)": "+228",
  "TO (+676)": "+676",
  "TT (+1868)": "+1868",
  "TN (+216)": "+216",
  "TR (+90)": "+90",
  "TM (+993)": "+993",
  "TC (+1649)": "+1649",
  "TV (+688)": "+688",
  "UG (+256)": "+256",
  "UA (+380)": "+380",
  "AE (+971)": "+971",
  "UY (+598)": "+598",
  "UZ (+998)": "+998",
  "VU (+678)": "+678",
  "VA (+379)": "+379",
  "VE (+58)": "+58",
  "VN (+84)": "+84",
  "WF (+681)": "+681",
  "YEN+969)": "+969",
  "YES+967)": "+967",
  "ZM (+260)": "+260",
  "ZW (+263)": "+263",
}
