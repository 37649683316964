import { TTheme } from '../types'
import animation from '../tokens/barclays/animation.json'
import breakpoints from "../tokens/barclays/breakpoints.json"
import buttons from "../tokens/barclays/buttons.json"
import colors from '../tokens/barclays/colors.json'
import iconsizing from '../tokens/barclays/iconsizing.json'
import overlay from '../tokens/barclays/overlay.json'
import radius from '../tokens/barclays/radius.json'
import shadows from '../tokens/barclays/shadows.json'
import typeface from '../tokens/barclays/typeface.json'
import typography from '../tokens/barclays/typography.json'

const tokens = {
    mode: 'BARCLAYS',
    animation,
    breakpoints,
    buttons,
    colors,
    iconsizing,
    overlay,
    radius,
    shadows,
    typeface,
    typography,
} as TTheme
export default tokens