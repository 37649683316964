import { FolderConnectionDto } from "legado-generated-api-client"
import { ConnectionStatus } from "../../../api/api-client/api-types"

//Get this from React-App-Config once the backend start providing good data
export const GetLocalFolderConnectionStatus = (): FolderConnectionDto[] => {
  const statuses: FolderConnectionDto[] = [
    {
      folderId: "5b5e90cb-af3b-41c3-bfc6-ab52dd3407a5",
      platformProviderId: "7b11ba8b-63e7-4f3d-bd4c-d20d0d030040",
      platformReference: "53082bcd-885a-4f07-8258-2629a9fbe673",
      productType: "affinity_water",
      productName: "Affinity Water",
      connectionStatus: ConnectionStatus.NEW,
      disabledOn: null,
    },
    {
      folderId: "ed230432-79b0-4804-8f63-e532e568e6d4",
      platformProviderId: "7b11ba8b-63e7-4f3d-bd4c-d20d0d030040",
      platformReference: "53082bcd-885a-4f07-8258-2629a9fbe673",
      productType: "british-gas",
      productName: "British Gas",
      connectionStatus: ConnectionStatus.UNSUCCESSFUL,
      disabledOn: null,
    },
    {
      folderId: "d377f99f-bade-4804-bf27-8b414bc6397b",
      platformProviderId: "7b11ba8b-63e7-4f3d-bd4c-d20d0d030040",
      platformReference: "53082bcd-885a-4f07-8258-2629a9fbe673",
      productType: "bt",
      productName: "BT",
      connectionStatus: ConnectionStatus.CONNECTED,
      disabledOn: null,
    },
    {
      folderId: "d163ab80-3bf1-470d-b277-4cf7bea36c4e",
      platformProviderId: "7b11ba8b-63e7-4f3d-bd4c-d20d0d030040",
      platformReference: "53082bcd-885a-4f07-8258-2629a9fbe673",
      productType: "ee-broadband",
      productName: "EE Broadband",
      connectionStatus: ConnectionStatus.DISCONNECTED,
      disabledOn: null,
    },
    {
      folderId: "4b58ce2b-166d-40d2-b319-5ab7a4034f72",
      platformProviderId: "7b11ba8b-63e7-4f3d-bd4c-d20d0d030040",
      platformReference: "53082bcd-885a-4f07-8258-2629a9fbe673",
      productType: "engie",
      productName: "Engie",
      connectionStatus: ConnectionStatus.DISABLED,
      disabledOn: null,
    },
    {
      folderId: "d9216e3d-b9fd-4d1d-99df-a3cbde8eebb5",
      platformProviderId: "7b11ba8b-63e7-4f3d-bd4c-d20d0d030040",
      platformReference: "53082bcd-885a-4f07-8258-2629a9fbe673",
      productType: "id_Mobile",
      productName: "iD Mobile",
      connectionStatus: ConnectionStatus.CONNECTED,
      disabledOn: null,
    },
  ]

  return statuses
}
