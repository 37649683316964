import { useRef } from "react"

export const useScrollToRefOnTrigger = ({ trigger }: { trigger: boolean }) => {
  const ref = useRef<HTMLDivElement>(null)

  if (trigger) {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    })
  }

  return { ref }
}
