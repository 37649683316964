"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureApi = exports.FeatureApiFactory = exports.FeatureApiFp = exports.FeatureApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * FeatureApi - axios parameter creator
 * @export
 */
const FeatureApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get the set of features for the user from a cross match between the user roles and the platform  Also returns the theme
         * @summary Get Feature Set for the platform
         * @param {boolean} [enabledOnly] Boolean to show only enabled Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFeatureGetAllFeaturesGet: (enabledOnly, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Feature/GetAllFeatures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (enabledOnly !== undefined) {
                localVarQueryParameter["enabledOnly"] = enabledOnly;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the set of features for the user from a cross match between the user roles and the platform  Also returns the theme
         * @summary Get Feature Set for the logged in user
         * @param {boolean} [enabledOnly] Boolean to show only enabled Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFeatureGetFeatureSetGet: (enabledOnly, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Feature/GetFeatureSet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (enabledOnly !== undefined) {
                localVarQueryParameter["enabledOnly"] = enabledOnly;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.FeatureApiAxiosParamCreator = FeatureApiAxiosParamCreator;
/**
 * FeatureApi - functional programming interface
 * @export
 */
const FeatureApiFp = function (configuration) {
    return {
        /**
         * Get the set of features for the user from a cross match between the user roles and the platform  Also returns the theme
         * @summary Get Feature Set for the platform
         * @param {boolean} [enabledOnly] Boolean to show only enabled Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFeatureGetAllFeaturesGet(enabledOnly, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.FeatureApiAxiosParamCreator)(configuration).apiFeatureGetAllFeaturesGet(enabledOnly, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get the set of features for the user from a cross match between the user roles and the platform  Also returns the theme
         * @summary Get Feature Set for the logged in user
         * @param {boolean} [enabledOnly] Boolean to show only enabled Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFeatureGetFeatureSetGet(enabledOnly, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.FeatureApiAxiosParamCreator)(configuration).apiFeatureGetFeatureSetGet(enabledOnly, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.FeatureApiFp = FeatureApiFp;
/**
 * FeatureApi - factory interface
 * @export
 */
const FeatureApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Get the set of features for the user from a cross match between the user roles and the platform  Also returns the theme
         * @summary Get Feature Set for the platform
         * @param {boolean} [enabledOnly] Boolean to show only enabled Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFeatureGetAllFeaturesGet(enabledOnly, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.FeatureApiFp)(configuration)
                    .apiFeatureGetAllFeaturesGet(enabledOnly, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Get the set of features for the user from a cross match between the user roles and the platform  Also returns the theme
         * @summary Get Feature Set for the logged in user
         * @param {boolean} [enabledOnly] Boolean to show only enabled Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFeatureGetFeatureSetGet(enabledOnly, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.FeatureApiFp)(configuration)
                    .apiFeatureGetFeatureSetGet(enabledOnly, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.FeatureApiFactory = FeatureApiFactory;
/**
 * FeatureApi - object-oriented interface
 * @export
 * @class FeatureApi
 * @extends {BaseAPI}
 */
class FeatureApi extends base_1.BaseAPI {
    /**
     * Get the set of features for the user from a cross match between the user roles and the platform  Also returns the theme
     * @summary Get Feature Set for the platform
     * @param {boolean} [enabledOnly] Boolean to show only enabled Features
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    apiFeatureGetAllFeaturesGet(enabledOnly, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.FeatureApiFp)(this.configuration)
                .apiFeatureGetAllFeaturesGet(enabledOnly, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get the set of features for the user from a cross match between the user roles and the platform  Also returns the theme
     * @summary Get Feature Set for the logged in user
     * @param {boolean} [enabledOnly] Boolean to show only enabled Features
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureApi
     */
    apiFeatureGetFeatureSetGet(enabledOnly, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.FeatureApiFp)(this.configuration)
                .apiFeatureGetFeatureSetGet(enabledOnly, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.FeatureApi = FeatureApi;
