import { Modal, ModalContent, ModalHeader } from "../../modules/Modal"

export const SessionTimeoutModal = () => {
  return (
    <Modal
      name={"Session Timed Out"}
      hasCloseButton={false}
      className="session-timeout-modal"
      backdropClassName="session-timeout-backdrop"
    >
      <ModalHeader>Session time-out</ModalHeader>

      <ModalContent>
        The session has timed out. Please close the tab and log back in again.
      </ModalContent>
    </Modal>
  )
}
