import { SET_DISPLAY_SUCCESS_OR_ERROR_MESSAGE } from "./actions"
import { IAction } from "../constants"
import { TDisplaySuccessOrErrorPopup } from "./constants"

export interface IToastState {
  displaySuccessOrErrorPopup?: TDisplaySuccessOrErrorPopup
}

export interface IToastAction extends IAction {
  displayMessage?: TDisplaySuccessOrErrorPopup
}

export const initialState = {
  displaySuccessOrErrorPopup: undefined,
}

export const userReducer = (toastState: IToastState, action: IToastAction) => {
  switch (action.type) {
    case SET_DISPLAY_SUCCESS_OR_ERROR_MESSAGE:
      return {
        ...toastState,
        displaySuccessOrErrorPopup: action.displayMessage,
      }
    default:
      return toastState
  }
}
