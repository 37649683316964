const Download = () => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4 9.6L12 16L5.6 9.6H9.6V0H14.4V9.6H18.4ZM12 16H0V22.4H24V16H12ZM22.4 19.2H19.2V17.6H22.4V19.2Z"
      fill="var(--color-universal-secondary-b)"
    />
  </svg>
)
export default Download
