import { useState } from "react"

import { PasswordSetupSteps } from "../../../contexts/application/constants"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { BUTTON_VARIANT, Button } from "../../atoms/Button"
import { ButtonChevronUpDownRightOrLeft } from "../../atoms/ButtonChevronUpDownRightLeft"
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner"
import { TextInputWithValidation } from "../../atoms/TextInput/TextInputWithValidation"
import AlertMessage, {
  MessageType,
} from "../../modules/AlertMessage/AlertMessage"
import { AlertMessageItem } from "../../modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"

import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../../contexts/toasts"
import { Max2FACodeLength } from "../../../utils/consts/consts"
import "./ConfirmIdentity.css"

export enum ConfirmIdentityErrorMessages {
  EMPTY = "make sure code sent has been entered",
  INVALID = "make sure the code entered is correct",
  LOCKED = "For security reasons you have been locked out after too many failed attempts. Please try again in 30 minutes.",
  UNEXPECTED = "Something went wrong. Try again.",
}

type ConfirmIdentityProps = {
  lastFourPhoneDigits: string
  onBackClick?: () => void
  onConfirmIdentity: (twoFactorCode: string) => void
  isSubmitting: boolean
  hasUnexpectedError: boolean
  validationError?: AlertMessageItem[]
  setValidationError: (error?: AlertMessageItem[]) => void
  hasUserLockedOutError: boolean
  onSendSmsCodeAgain?: () => Promise<void>
  resetPasswordStep?: PasswordSetupSteps
}

const ConfirmIdentity = ({
  lastFourPhoneDigits,
  onBackClick,
  onConfirmIdentity,
  isSubmitting,
  hasUnexpectedError,
  validationError,
  hasUserLockedOutError,
  setValidationError,
  onSendSmsCodeAgain,
  resetPasswordStep,
}: ConfirmIdentityProps) => {
  const { dispatch: toastDispatch } = useToastContext()
  const [twoFactorCode, setTwoFactorCode] = useState("")
  const [isSendingSmsCode, setIsSendingSmsCode] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)

  const InfoIcon = useGetIcon("Info")

  const onSubmitTwoFactorCode = async () => {
    if (twoFactorCode.length < 6) {
      setValidationError([
        {
          id: 1,
          message: ConfirmIdentityErrorMessages.EMPTY,
          href: "confirm-identity",
        },
      ])
    } else {
      onConfirmIdentity(twoFactorCode)
    }
  }

  const getValidationError = () => {
    if (validationError) {
      switch (validationError[0]?.id) {
        case 2:
          return "Incorrect code"
        case 1:
        default:
          return "Required"
      }
    } else {
      return "Required"
    }
  }

  const onSendAgainClick = async () => {
    setTwoFactorCode("")
    setValidationError(undefined)
    setIsSendingSmsCode(true)
    await onSendSmsCodeAgain?.()
    setIsSendingSmsCode(false)
    if (!hasUnexpectedError) {
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          title: "Resent successful",
          messageType: "SUCCESS",
          message: `The code has been sent again to the registered number ending: ${lastFourPhoneDigits}`,
        })
      )
    }
  }

  const SendAgainButtonLinkLabel = ({
    isSendingSmsCode,
  }: {
    isSendingSmsCode: boolean
  }) => {
    return isSendingSmsCode ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="mr-1 confirm-identity-no-code">Sending again...</span>
        <LoadingSpinner
          size="20px"
          thickness="2px"
          color="var(--link-color-action-text-primary)"
        />
      </div>
    ) : (
      "Send again"
    )
  }

  /* TODO: Ideally we should have a Button that behaves as a link, we have PseudoLink component but that not match UX design for COOP */
  const SendAgainButtonLink = ({
    isSendingSmsCode,
    isSubmitting,
  }: {
    isSendingSmsCode: boolean
    isSubmitting: boolean
  }) => {
    return (
      <button
        className="confirm-identity-no-code"
        title={isSendingSmsCode ? "Sending again..." : "Send again"}
        style={{ padding: "0" }}
        disabled={isSendingSmsCode || isSubmitting}
        onClick={onSendAgainClick}
      >
        <SendAgainButtonLinkLabel isSendingSmsCode={isSendingSmsCode} />
      </button>
    )
  }

  return (
    <>
      <div className="confirm-identity-body">
        <div className="confirm-identity-header">
          <h1>Confirm Identity</h1>
          {/* TODO: refactor these buttons - onclick added to allow the div to capture space bar selection, but this could be a single button component https://dev.azure.com/secure-the-file/Application/_workitems/edit/15786*/}
          <div
            className="confirm-identity-header__back-button"
            onClick={() => {
              onBackClick?.()
            }}
          >
            <ButtonChevronUpDownRightOrLeft
              direction="LEFT"
              title="Back"
              onClick={() => {
                onBackClick?.()
              }}
              size="m"
            />
            {/* TODO: Ideally we should have a Button that behaves as a link, we have PseudoLink component but that not match UX design for COOP */}
            <button
              title="Back"
              aria-label="Back"
              style={{ padding: "0" }}
              onClick={() => {
                onBackClick?.()
              }}
              tabIndex={-1}
            >
              Back
            </button>
          </div>
        </div>
        {hasUnexpectedError || hasUserLockedOutError ? (
          <div className="mb-30">
            <AlertMessage
              title="There's a problem"
              messageType={MessageType.ERROR}
              message={
                hasUnexpectedError
                  ? ConfirmIdentityErrorMessages.UNEXPECTED
                  : ConfirmIdentityErrorMessages.LOCKED
              }
            />
          </div>
        ) : null}
        {validationError && !hasUnexpectedError && !hasUserLockedOutError && (
          <div className="mb-30">
            <AlertMessage
              title="There's a problem"
              messageType={MessageType.ERROR}
              message="Check the form. You must:"
              alertItems={validationError}
            />
          </div>
        )}

        <div>
          <p>
            A one time 6 digit code has been sent to your phone number, ending:{" "}
            <b>{lastFourPhoneDigits}</b>
          </p>
          <p>
            Do not recognise the last 4 digits of this phone number? Please
            contact your case manager.
          </p>
          <TextInputWithValidation
            id="confirm-identity"
            name="confirm-identity"
            className="confirm-identity-input"
            label="Enter code"
            isRequired
            value={twoFactorCode}
            onChange={setTwoFactorCode}
            handleSubmit={onSubmitTwoFactorCode}
            displayError={validationError !== undefined}
            shouldShowWarningIcon={false}
            errorOnSubmit={{
              hasError: validationError !== undefined,
              message: getValidationError(),
            }}
            maxLength={Max2FACodeLength}
            validateOnSubmit
            autoComplete="off"
          />
          <div className="confirm-identity-expiry-text">
            {InfoIcon} This code will expire in 10 minutes.
          </div>
          {/* TODO: Button component should support isBusy or isLoading prop and we should add the spinner there.  https://dev.azure.com/secure-the-file/Application/_workitems/edit/14437 */}
          <Button
            className="confirm-identity-button"
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={() => {
              setValidationError(undefined)
              onSubmitTwoFactorCode()
            }}
            isDisabled={isSubmitting || isSendingSmsCode}
          >
            {!isSubmitting && "Verify and sign in"}
            {isSubmitting && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {resetPasswordStep === PasswordSetupSteps.ResetPassword ? (
                  <span className="mr-1">Verifying ... </span>
                ) : (
                  <span className="mr-1">Signing in...</span>
                )}

                <LoadingSpinner
                  size="20px"
                  thickness="2px"
                  color="var(--color-universal-secondary-e)"
                />
              </div>
            )}
          </Button>
        </div>
        <hr className="confirm-identity-divider" />
        <div className="confirm-identity-no-code-container">
          <span className="confirm-identity-no-code-text">
            Not received a code?
          </span>
          <SendAgainButtonLink
            isSendingSmsCode={isSendingSmsCode}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
    </>
  )
}

export default ConfirmIdentity
