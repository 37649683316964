export const DashboardHome = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 7.8175L10 3.61814L5.5 7.81751V16.5H8V11.5V11H8.5H11.5H12V11.5V16.5H14.5V7.8175ZM15.5 8.75069L16.1589 9.36555C16.3608 9.55395 16.6772 9.54302 16.8656 9.34113C17.054 9.13924 17.043 8.82285 16.8411 8.63444L10.4776 2.69603C10.2087 2.44506 9.79135 2.44506 9.52242 2.69603L3.15887 8.63444C2.95698 8.82285 2.94605 9.13924 3.13445 9.34113C3.32285 9.54302 3.63924 9.55395 3.84113 9.36555L4.5 8.75071V17V17.5H5H8.5H9V17V12H11V17V17.5H11.5H15H15.5V17V8.75069Z"
    />
  </svg>
)
