const ConnectivityWarning = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
  >
    <path
      d="M13.0003 23.0403C18.5389 23.0403 23.0288 18.5504 23.0288 13.0117C23.0288 7.4731 18.5389 2.98315 13.0003 2.98315C7.46162 2.98315 2.97168 7.4731 2.97168 13.0117C2.97168 18.5504 7.46162 23.0403 13.0003 23.0403Z"
      fill="#EEA63A"
    />
    <mask id="path-3-inside-1_5422_11382" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0022 8.18311C14.0022 7.63082 13.5545 7.18311 13.0022 7.18311C12.4499 7.18311 12.0022 7.63082 12.0022 8.18311V13.3831C12.0022 13.9354 12.4499 14.3831 13.0022 14.3831C13.5545 14.3831 14.0022 13.9354 14.0022 13.3831V8.18311ZM14.1143 16.726C14.1143 17.3414 13.6154 17.8402 13 17.8402C12.3846 17.8402 11.8857 17.3414 11.8857 16.726C11.8857 16.1106 12.3846 15.6117 13 15.6117C13.6154 15.6117 14.1143 16.1106 14.1143 16.726Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0022 8.18311C14.0022 7.63082 13.5545 7.18311 13.0022 7.18311C12.4499 7.18311 12.0022 7.63082 12.0022 8.18311V13.3831C12.0022 13.9354 12.4499 14.3831 13.0022 14.3831C13.5545 14.3831 14.0022 13.9354 14.0022 13.3831V8.18311ZM14.1143 16.726C14.1143 17.3414 13.6154 17.8402 13 17.8402C12.3846 17.8402 11.8857 17.3414 11.8857 16.726C11.8857 16.1106 12.3846 15.6117 13 15.6117C13.6154 15.6117 14.1143 16.1106 14.1143 16.726Z"
      fill="#3D3E46"
    />
    <path
      d="M13.0022 7.31294C13.4828 7.31294 13.8724 7.70253 13.8724 8.18311H14.132C14.132 7.55911 13.6262 7.05327 13.0022 7.05327V7.31294ZM12.132 8.18311C12.132 7.70253 12.5216 7.31294 13.0022 7.31294V7.05327C12.3782 7.05327 11.8724 7.55911 11.8724 8.18311H12.132ZM12.132 13.3831V8.18311H11.8724V13.3831H12.132ZM13.0022 14.2533C12.5216 14.2533 12.132 13.8637 12.132 13.3831H11.8724C11.8724 14.0071 12.3782 14.5129 13.0022 14.5129V14.2533ZM13.8724 13.3831C13.8724 13.8637 13.4828 14.2533 13.0022 14.2533V14.5129C13.6262 14.5129 14.132 14.0071 14.132 13.3831H13.8724ZM13.8724 8.18311V13.3831H14.132V8.18311H13.8724ZM13 17.9701C13.6871 17.9701 14.2442 17.4131 14.2442 16.726H13.9845C13.9845 17.2697 13.5437 17.7104 13 17.7104V17.9701ZM11.7559 16.726C11.7559 17.4131 12.3129 17.9701 13 17.9701V17.7104C12.4563 17.7104 12.0156 17.2697 12.0156 16.726H11.7559ZM13 15.4818C12.3129 15.4818 11.7559 16.0389 11.7559 16.726H12.0156C12.0156 16.1823 12.4563 15.7415 13 15.7415V15.4818ZM14.2442 16.726C14.2442 16.0389 13.6871 15.4818 13 15.4818V15.7415C13.5437 15.7415 13.9845 16.1823 13.9845 16.726H14.2442Z"
      fill="#3D3E46"
      mask="url(#path-3-inside-1_5422_11382)"
    />
  </svg>
)
export default ConnectivityWarning
