
export function GetFileType(extension : string | null | undefined){
  let notFound = "File";
  if (!extension){
    return notFound;
  }

  let typeDictionary : {[key: string] : string} = {
    "Text": "txt,rtf",
    "Document" : "pdf,txt,doc,docx,odc,odf,odg,odm,ods,odt,wpd,tex,msg,log,wps,tm,flo,fo,xfo,ppt,pptx,dat,iso,email,eml,emlx,wpd,pages,mdb,dbf,dbk,dbo,dbt,ott",
    "Spreadsheet" : "csv,xls,xlsx,ods,xlsm,xltx,xlt,xlsb,xltm,xlam,xml,numbers",
    "Video" : "mp4,mov,wmv,avi,flv,f4v,swf,mkv,mpeg-2,mpg,mv4,mkv,h264,3g2,3gp",
    "Audio" : "mp3,wav,aiff,aac,ogg,wma,flac,alac,wma,wpl,mid,midi",
    "Image" : "jpeg,jpg,png,gif,tiff,tif,psd,raw,indd,ico,svg"
  };

  let result = Object.keys(typeDictionary).find(key => typeDictionary[key].includes(extension.toLowerCase()));

  if (!result){
    return notFound;
  }
  return result;
}

export function GetFileTypeWithExtension(extension :  string | null | undefined){
  const fileType = GetFileType(extension)
  return fileType ? fileType + (extension? " (" + extension + ")" : "") : fileType
}
