import { baseUrl, httpClient } from "../httpClient"
import { GetDocuSignFileResponse } from "./models/GetDocuSignFileResponse"
import { PostFolderUploadResponse } from "./models/PostFolderUploadResponse"

export const postFolderUpload = async ({
  folderId,
  userIds,
  file,
  fileName,
  redirectUrl,
}: {
  folderId: string
  userIds: string[]
  file: Blob
  fileName: string
  redirectUrl: string
}): Promise<PostFolderUploadResponse> => {
  const formData = new FormData()
  formData.append("file", file, fileName)
  formData.append("users", userIds.join(","))
  formData.append("redirectUrl", redirectUrl)

  const url = new URL(`${baseUrl}/api/Docusign/folder/${folderId}/upload`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: formData,
  })

  return response.data
}

export const getDocuSignFile = async ({
  fileId,
  redirectUrl,
}: {
  fileId: string
  redirectUrl: string
}): Promise<GetDocuSignFileResponse> => {
  const url = new URL(`${baseUrl}/api/Docusign/file/${fileId}`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: `${baseUrl}${redirectUrl}`,
  })
  return response.data
}

export const deleteDocuSignFile = async ({
  fileId,
}: {
  fileId: string
}): Promise<string> => {
  const url = new URL(`${baseUrl}/api/Docusign/file/${fileId}/cancel`)

  const response = await httpClient(url.toString(), {
    method: "DELETE",
  })

  return response.data
}

export const postDocuSignFileSignedStatus = async ({
  fileId,
  status,
  comment,
}: {
  fileId: string
  status: string
  comment: string | null
}): Promise<string> => {
  const url = new URL(`${baseUrl}/api/Docusign/file/${fileId}/sign/${status}`)

  const response = await httpClient(url.toString(), {
    method: "POST",
    data: {
      comment,
    },
  })

  return response.data
}
