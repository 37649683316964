import { useState } from "react"
import { IFile } from "../../api/api-client/api-types"
import { BUTTON_VARIANT, Button } from "../atoms/Button"
import { Modal, ModalContent, ModalFooter, ModalHeader } from "../modules/Modal"
import "./DeleteCaseFile.css"
import { LoadingSpinner } from "../atoms/LoadingSpinner/LoadingSpinner"
import { useNavigate, useParams } from "react-router-dom"
import { deleteCaseFile } from "../../api/lib/workflow/workflow"
import AlertMessage, { MessageType } from "../modules/AlertMessage/AlertMessage"

export interface DeleteCaseFileProps {
  onClose: () => void
  file: IFile
}

export function DeleteCaseFile({ onClose, file }: DeleteCaseFileProps) {
  const [deleteError, setDeleteError] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const navigate = useNavigate()

  const {
    workflowId,
    workflowVersion,
    caseId,
    stageId,
    requestId,
    documentId,
  } = useParams()

  const onDeleteFile = async () => {
    setDeleteError(false)
    setIsDeleting(true)
    try {
      if (
        workflowId &&
        workflowVersion &&
        caseId &&
        stageId &&
        requestId &&
        documentId
      ) {
        const response = await deleteCaseFile(
          workflowId,
          workflowVersion,
          caseId,
          stageId,
          requestId,
          documentId
        )

        if (response) {
          onClose()
          // after successful deletion, navigate back to page prior to file details
          navigate(-1)
        } else {
          setDeleteError(true)
          setIsDeleting(false)
        }
      }
    } catch {
      setDeleteError(true)
      setIsDeleting(false)
    }
  }

  return (
    <Modal
      onClose={onClose}
      name="Delete case file"
      className="delete-case-file"
    >
      <ModalHeader hideHeaderBorderBottom>
        Are you sure you want to delete?
      </ModalHeader>
      <hr className="delete-case-file__divider" />
      {deleteError && (
        <AlertMessage
          messageType={MessageType.ERROR}
          title="There's a problem"
          message="This file cannot be deleted at the moment, please try again later."
        />
      )}
      <ModalContent className="pb-4">
        <div className="node-name">
          Filename:
          <span className="node-name__file_name">{file?.name}</span>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          className="right-button"
          variant={BUTTON_VARIANT.PRIMARY}
          type="submit"
          onClick={() => onDeleteFile()}
          isDisabled={isDeleting}
        >
          {isDeleting ? (
            <>
              {"Deleting..."}
              <LoadingSpinner
                thickness="2px"
                color="var(--color-universal-secondary-c)"
              />
            </>
          ) : (
            "Yes, Delete"
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
