const PointHand = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 13V4.5C16 3.83696 15.7366 3.20107 15.2678 2.73223C14.7989 2.26339 14.163 2 13.5 2C12.837 2 12.2011 2.26339 11.7322 2.73223C11.2634 3.20107 11 3.83696 11 4.5V19.6583L8.25906 14.9108C8.09506 14.6262 7.87658 14.3767 7.6161 14.1766C7.35563 13.9764 7.05827 13.8296 6.74101 13.7445C6.42376 13.6593 6.09284 13.6375 5.76716 13.6803C5.44148 13.7231 5.12743 13.8297 4.84296 13.9939C4.55848 14.1582 4.30917 14.3769 4.10925 14.6375C3.90934 14.8982 3.76276 15.1956 3.67787 15.513C3.59299 15.8303 3.57147 16.1612 3.61455 16.4869C3.65763 16.8125 3.76446 17.1265 3.92894 17.4108C8.00001 26 10.4772 29 16 29C17.3132 29 18.6136 28.7413 19.8268 28.2388C21.0401 27.7362 22.1425 26.9997 23.0711 26.0711C23.9997 25.1425 24.7363 24.0401 25.2388 22.8268C25.7413 21.6136 26 20.3132 26 19V14C26 13.337 25.7366 12.7011 25.2678 12.2322C24.7989 11.7634 24.163 11.5 23.5 11.5C22.837 11.5 22.2011 11.7634 21.7322 12.2322C21.2634 12.7011 21 13.337 21 14"
      fill="none"
    />
    <path
      d="M21 14V12.5C21 11.837 20.7366 11.2011 20.2678 10.7322C19.7989 10.2634 19.163 10 18.5 10C17.837 10 17.2011 10.2634 16.7322 10.7322C16.2634 11.2011 16 11.837 16 12.5V13"
      fill="none"
    />
  </svg>
)
export default PointHand
