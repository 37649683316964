import { FC, useEffect, useRef } from "react"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { NavMenuNavigation } from "../../NavMenuDropDown/constants"
import {
  isSelected,
  keyPressed,
  manipulateNavigationData,
} from "../../NavMenuDropDown/helpers"
import "./ChevronUpOrDown.css"

export const ChevronUpOrDown: FC<{
  expanded?: boolean
  setExpanded?: (expanded: boolean) => void
  size?: "s" | "l"
  isDisabled?: boolean
  controlsId?: string
  accessibilityLabelId?: string

  //keyboard navigation related
  thisRefId?: number
  maxNavigationId?: number | undefined
  level?: number
  navigationData?: NavMenuNavigation
  setNavigationData?: React.Dispatch<React.SetStateAction<NavMenuNavigation>>
  folderId?: string | undefined
  setExpandFolder?: ((expanded: string) => void) | undefined
  setTopParentIsOpen?: ((menuItemIsOpen: string) => void) | undefined
}> = ({
  expanded,
  setExpanded,
  size,
  isDisabled,
  controlsId,
  accessibilityLabelId = "",

  //keyboard navigation related
  thisRefId = -1,
  maxNavigationId,
  level = -1,
  navigationData,
  setNavigationData,
  folderId,
  setExpandFolder,
  setTopParentIsOpen,
}) => {
  const ChevronUp = useGetIcon("IconChevronUp")
  const ChevronDown = useGetIcon("IconChevronDown")

  const accessibilityId = `${accessibilityLabelId}-chevron`
  const buttonRef = useRef<HTMLButtonElement>(null)
  const onClickFn = () => {
    if (setExpanded && !isDisabled) {
      setExpanded(!expanded)
    }
  }

  useEffect(() => {
    if (navigationData && isSelected(level, thisRefId, navigationData)) {
      buttonRef.current?.focus()
    }
  }, [level, navigationData, thisRefId])

  return (
    <button
      ref={buttonRef}
      className={`click-me icon-box-chevron${
        size === "l" ? " icon-l" : size === "s" ? " icon-s" : ""
      }${isDisabled ? " disabled" : ""}`}
      title={expanded ? "Collapse" : "Expand"}
      onClick={onClickFn}
      id={accessibilityId}
      aria-controls={controlsId}
      aria-label={!expanded ? "Show" : "Hide"}
      aria-labelledby={`${accessibilityId ?? ""} ${accessibilityLabelId ?? ""}`}
      onFocus={() => {
        if (navigationData && setNavigationData) {
          manipulateNavigationData(
            navigationData,
            setNavigationData,
            level,
            thisRefId,
            maxNavigationId
          )
        }
      }}
      onKeyDown={(event) => {
        keyPressed(
          event,
          folderId,
          navigationData,
          setNavigationData,
          undefined,
          setExpandFolder,
          setTopParentIsOpen,
          setExpanded,
          expanded
        )
      }}
    >
      {expanded ? ChevronUp : ChevronDown}
    </button>
  )
}
