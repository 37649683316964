export const LogoutIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5218_8444)">
          <path
            d="M23.343 16.7805C23.5371 16.5903 23.6482 16.3311 23.6521 16.0594C23.6515 15.9231 23.6239 15.7883 23.5709 15.6627C23.5178 15.5372 23.4404 15.4234 23.343 15.328L13.3301 5.30471C13.2343 5.20816 13.1204 5.13153 12.9948 5.07923C12.8693 5.02693 12.7346 5 12.5986 5C12.4627 5 12.328 5.02693 12.2025 5.07923C12.0769 5.13153 11.963 5.20816 11.8672 5.30471C11.6754 5.49772 11.5677 5.75882 11.5677 6.03097C11.5677 6.30311 11.6754 6.56421 11.8672 6.75722L20.1084 14.9984L1.03014 14.9984C0.756932 14.9984 0.494911 15.1069 0.301722 15.3001C0.108533 15.4933 0 15.7553 0 16.0285C0 16.3017 0.108533 16.5637 0.301722 16.7569C0.494911 16.9501 0.756932 17.0586 1.03014 17.0586L20.129 17.0586L11.8879 25.2998C11.7191 25.4969 11.6309 25.7504 11.6409 26.0096C11.6509 26.2689 11.7584 26.5148 11.9419 26.6983C12.1253 26.8817 12.3713 26.9892 12.6305 26.9992C12.8898 27.0092 13.1433 26.9211 13.3404 26.7523L23.343 16.7805Z"
            fill="#282828"
          />
          <path
            d="M21.51 1C21.51 1.26522 21.6154 1.51957 21.8029 1.70711C21.9904 1.89464 22.2448 2 22.51 2L30 2L30 30L22.51 30C22.2448 30 21.9904 30.1054 21.8029 30.2929C21.6154 30.4804 21.51 30.7348 21.51 31C21.51 31.2652 21.6154 31.5196 21.8029 31.7071C21.9904 31.8946 22.2448 32 22.51 32L30 32C30.5304 32 31.0392 31.7893 31.4142 31.4142C31.7893 31.0391 32 30.5304 32 30L32 2C32 1.46957 31.7893 0.960859 31.4142 0.585787C31.0392 0.210714 30.5304 0 30 0H22.51C22.2448 0 21.9904 0.105356 21.8029 0.292892C21.6154 0.480429 21.51 0.734783 21.51 1Z"
            fill="#282828"
          />
        </g>
        <defs>
          <clipPath id="clip0_5218_8444">
            <rect
              width="32"
              height="32"
              fill="white"
              transform="matrix(0 -1 1 0 0 32)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
