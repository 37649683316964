import { PAGE_PATH, TLink } from "../../contexts/application/constants"

export const menuItemsDesktop = [
  { name: "Files", href: "/my-files" },
  { name: "Sharing", href: "/sharing" },
] as TLink[]

export const menuItemsBase = [
  {
    name: "Document home",
    href: PAGE_PATH.HomePage,
    iconName: "DashboardHome",
  },
  { name: "Files", href: PAGE_PATH.FolderPage, iconName: "Folder" },
  { name: "Sharing", href: PAGE_PATH.SharingPage, iconName: "Share" },
  {
    name: "Document Hub",
    href: PAGE_PATH.DocumentHubPage,
    iconName: "DashboardHome",
  },
  {
    name: "Analytics",
    href: PAGE_PATH.AnalyticsPage,
    iconName: "Folder",
  },
  {
    name: "Client Search",
    href: PAGE_PATH.CommunicationsSearchPage,
    iconName: "Share",
  },
] as TLink[]

export const menuItemsQuilterAdmin = [] as TLink[]
