import { FileNodeDto, FolderDto } from "legado-generated-api-client"
import { SharedVaultDto } from "legado-generated-api-client/models"
import { IFile, IFolder } from "../../api/api-client/api-types"

//this is the generic files folder for files without a shared folder
const genericFilesFolder: IFolder = {
  id: "generic-files",
  name: "Files",
  parentId: null,
  childFolders: [],
  contents: [],
  level: 1,
  isEditable: false,
  isDeletable: false,
  canUpload: false,
  folderType: "Files",
  isCurrentPlatform: true,
  isOwner: false,
  dateShared: null,
}

export function sharedFoldersToIFolders(
  sharedVault?: SharedVaultDto | undefined
) {
  if (!sharedVault) return []
  let folders = sharedVault.folders as FolderDto[] as IFolder[]
  const files = sharedVault.files as FileNodeDto[] as IFile[]
  const sections = sharedVault.sections as IFolder[]

  const folderTree: IFolder[] = sections ? (sections as IFolder[]) : []

  if (files) {
    for (var i = 0; i < files.length; i++) {
      const file = files[i]
      const sectionParent = folderTree.find((f) => f.id === file?.parentId)
      if (sectionParent) {
        if (!sectionParent.contents) {
          sectionParent.contents = []
        }
        if (!isDuplicateFile(sectionParent, file)) {
          sectionParent.contents?.push(file)
        }
      } else {
        const fileParent = folders.find((f) => f.id === file?.parentId)
        if (fileParent) {
          if (!fileParent.contents) {
            fileParent.contents = []
          }
          if (!isDuplicateFile(fileParent, file)) {
            fileParent.contents?.push(file)
          }
        } else {
          //generic files section for files without a shared folder
          if (!folderTree.find((f) => f.id === genericFilesFolder.id)) {
            folderTree.push(genericFilesFolder)
          }
          //add files to generic files folder
          genericFilesFolder.contents?.push(file)
        }
      }
    }
  }

  if (folders) {
    for (var i = 0; i < folders.length; i++) {
      const folder = folders[i]
      const sectionParent = folderTree.find((f) => f.id === folder?.parentId)
      if (sectionParent) {
        sectionParent.childFolders
          ? !isDuplicateFolder(sectionParent, folder) &&
            sectionParent.childFolders?.push(folder)
          : (sectionParent.childFolders = [folder])
      } else {
        const folderParent = folders.find((f) => f.id === folder?.parentId)
        if (folderParent) {
          folderParent.childFolders
            ? !isDuplicateFolder(folderParent, folder) &&
              folderParent.childFolders?.push(folder)
            : (folderParent.childFolders = [folder])
        } else {
          folderTree.push(folder)
        }
      }
    }
  }

  return folderTree
}

const isDuplicateFolder = (parent: IFolder, folder: IFolder) => {
  const res = parent.childFolders
    ? parent.childFolders.some((a) => a.id === folder.id)
    : false
  return res
}

const isDuplicateFile = (parent: IFolder, file: IFile) => {
  const res = parent.contents
    ? parent.contents.some((a) => a.id === file.id)
    : false
  return res
}
