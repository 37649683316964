import { TTheme } from "../types"
import animation from "../tokens/coop/animation.json"
import breakpoints from "../tokens/coop/breakpoints.json"
import colors from "../tokens/coop/colors.json"
import iconsizing from "../tokens/coop/iconsizing.json"
import overlay from "../tokens/coop/overlay.json"
import radius from "../tokens/coop/radius.json"
import shadows from "../tokens/coop/shadows.json"
import typeface from "../tokens/coop/typeface.json"
import typography from "../tokens/coop/typography.json"

const tokens = {
  mode: "CO_OP",
  animation,
  breakpoints,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  typeface,
  typography,
} as TTheme
export default tokens
