import React from "react"
import "./Panel.css"

interface PanelProps {
  className?: string
  role?: string
  children: React.ReactNode
}

const Panel: React.FC<PanelProps> = ({ className, role, children }) => {
  return (
    <div className={`panel ${className}`} role={role}>
      {children}
    </div>
  )
}

export default Panel
