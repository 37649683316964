import { useEffect } from "react"

// This page is used as part of the WPS Docusign flow
// Once a user has signed their document in a new tab that points to docusign.com
// then the browser redirects the user through our backend API
// and this component is then loaded to close the browser tab at the end of that flow
export function DocusignRedirectPage() {
  useEffect(() => {
    window.close()
  }, [])

  return null
}
