import { IApplicationState } from "./constants"
import { ApiConnection } from "../../api/api-client/common/ApiConnection"

const selectedTheme = "LEGADO"

export const emptyInitialData = {
  selectedTheme,
  folders: [],
  firstVisit: true,
  isLoading: true,
  selectedFolderId: "",
  selectedContactId: "",
  selectedSharingWithMeUserId: "",
  usersSharingWithMe: [],
  contacts: [],
  contactsShares: [],
  apiConnection: new ApiConnection(undefined, undefined),
  sharedWithMeFolders: [],
  modalOpen: false,
  showConnectivityBanner: true,
  usingStaticData: false,
  savedOpenFolderId: "",
  providerStatuses: [],
  menuOpened: false,
  globalFilterOptions: [],
  searchedTag: "",
  filesWithTags: [],
  industries: [],
  providers: [],
  projects: [],
  selectedProject: {},
  projectAnalytics: {},
  flatFileList: [],
  documentLibraryClientFiles: [],
  showMenu: true,
  analyticsRoute: false,
  // This was added as a workaround to fix the issue with flickering when starting the application.
  authLoading: true,
  showFooter: true,
} as IApplicationState
