import { ComponentVariablesTypes } from "../../tier3-component-specific-variables/bridgeTokenTypes"
import { definitionTokens } from "../tier1-definitions/definitionTokens"

export const applicationTokens = {
  colors: {
    /////////////////////////////////////////////////////////////////

    ////Universal colors

    /////////////////////////////////////////////////////////////////

    //TODO: colors which were not component specific on Figma,

    // are likely to disappear once all themes were migrated to the new design system

    colorUniversalNaturalBlack: definitionTokens.colors?.["#181818"],
    colorUniversalNaturalWhite: definitionTokens.colors?.["#FEFEFE"],
    colorUniversalNaturalGrey: definitionTokens.colors?.["#F2F2F2"],
    colorUniversalSecondaryA: definitionTokens.colors?.["#3C3C3C"],
    colorUniversalSecondaryB: definitionTokens.colors?.["#666666"],
    colorUniversalSecondaryC: definitionTokens.colors?.["#BCBCBC"],
    colorUniversalSecondaryD: definitionTokens.colors?.["#F1F0F1"],
    colorUniversalSecondaryE: definitionTokens.colors?.["#FFE3E2"],
    colorUniversalSecondaryF: definitionTokens.colors?.["#F9D1D0"],
    colorUniversalSecondaryG: definitionTokens.colors?.["E28A86"],
    colorUniversalSecondaryH: definitionTokens.colors?.["#C21D16"],
    colorUniversalToneA: definitionTokens.colors?.["#F2AC3C"],
    colorUniversalToneA25: definitionTokens.colors?.["#FFF8E6"],
    colorUniversalPrimaryBase: definitionTokens.colors?.["#C21D16"],
    colorUniversalPrimaryBlack: definitionTokens.colors?.["#181818"],
    colorUniversalPrimaryGrey: definitionTokens.colors?.["#F2F2F2"],
    colorUniversalPrimaryBackground: definitionTokens.colors?.["#FEFEFE"],

    /////////////////////////////////////////////////////////////////

    ////Action colors

    /////////////////////////////////////////////////////////////////

    // background color

    colorActionBackgroundPrimary: definitionTokens.colors?.["#C21D16"],
    colorActionBackgroundPrimaryHover: definitionTokens.colors?.["#97120D"],
    colorActionBackgroundSecondary: definitionTokens.colors?.["#FFFFFF"],
    colorActionBackgroundDanger: definitionTokens.colors?.["#D82927"],
    colorActionBackgroundDangerHover: definitionTokens.colors?.["#B4201E"],
    colorActionBackgroundDisabled: definitionTokens.colors?.["#E8E8E8"],

    //border color

    colorActionBorderPrimary: definitionTokens.colors?.["#C21D16"],
    colorActionBorderPrimaryHover: definitionTokens.colors?.["#97120D"],
    colorActionBorderSecondary: definitionTokens.colors?.["#C21D16"],
    colorActionBorderTertiary: definitionTokens.colors?.["#A1A1A1"],

    colorActionBorderDanger: definitionTokens.colors?.["#D82927"],
    colorActionBorderDangerHover: definitionTokens.colors?.["#B4201E"],
    colorActionBorderDisabled: definitionTokens.colors?.["#E8E8E8"],

    // outline color

    // text color

    colorActionTextPrimary: definitionTokens.colors?.["#FFFFFF"],
    colorActionTextSecondary: definitionTokens.colors?.["#C21D16"],
    colorActionTextTertiary: definitionTokens.colors?.["#D82927"],
    colorActionTextDisabled: definitionTokens.colors?.["#91939A"],

    /////////////////////////////////////////////////////////////////

    ////context colors

    /////////////////////////////////////////////////////////////////

    colorContextBackgroundPrimary: definitionTokens.colors?.["#F9F9F9"],

    /////////////////////////////////////////////////////////////////

    ////Feedback colors

    /////////////////////////////////////////////////////////////////

    colorFeedbackSuccess: definitionTokens.colors?.["#03A644"],
    colorFeedbackSuccessHover: definitionTokens.colors?.["#058D3B"],
    colorFeedbackSuccessShade: definitionTokens.colors?.["#CEF5DD"],
    colorFeedbackWarning: definitionTokens.colors?.["#EEA63A"],
    colorFeedbackWarningHover: definitionTokens.colors?.["#DE9527"],
    colorFeedbackWarningShade: definitionTokens.colors?.["#FBE9CE"],
    colorFeedbackDanger: definitionTokens.colors?.["#C62828"],
    colorFeedbackDangerHover: definitionTokens.colors?.["#B4201E"],
    colorFeedbackDangerShade: definitionTokens.colors?.["#FFD1CE"],
    colorFeedbackMessage: definitionTokens.colors?.["#195576"],
    colorFeedbackMessageHover: definitionTokens.colors?.["#8CAABA"],
    colorFeedbackMessageShade: definitionTokens.colors?.["#C5D4DD"],

    // Inline feedback

    colorFeedbackBackgroundInlineMessage: definitionTokens.colors?.["#E6F0F5"],
    colorFeedbackInlineBackgroundError: definitionTokens.colors?.["#FFEBED"],
    colorFeedbackInlineBackgroundSuccess: definitionTokens.colors?.["#E7FDEC"],
    colorFeedbackInlineBackgroundWarning: definitionTokens.colors?.["#FFF8E6"],

    // Background color

    colorFeedbackPrimary: definitionTokens.colors?.["#FAFAFA"],
    colorFeedbackPending: definitionTokens.colors?.["#F2FAF5"],
    colorFeedbackBackgroundInfo: definitionTokens.colors?.["#E5EDFF"],
    colorFeedbackBackgroundEmphasized: definitionTokens.colors?.["#FFD75D"],
    colorFeedbackBackgroundRevoke: definitionTokens.colors?.["#FFD1CE"],
    colorFeedbackBackgroundNegative: definitionTokens.colors?.["#A91719"],
    colorFeedbackBackgroundMultiple: definitionTokens.colors?.["#295D7F"],
    colorFeedbackBackgroundPositive: definitionTokens.colors?.["#CEF5DD"],

    // Text color

    colorFeedbackTextPrimary: definitionTokens.colors?.["#3C3C3C"],
    colorFeedbackTextSecondary: definitionTokens.colors?.["#FFFFFF"],
    colorFeedbackTextTertiary: definitionTokens.colors?.["#840B0D"],
    colorFeedbackTextQuaternary: definitionTokens.colors?.["#394A71"],
    colorFeedbackTextQuinary: definitionTokens.colors?.["#095628"],

    // Border color

    colorFeedbackBorderPrimary: definitionTokens.colors?.["#E8E8E8"],
    colorFeedbackBorderPositive: definitionTokens.colors?.["#A7CEB7"],
    colorFeedbackBorderInfoSecondary: definitionTokens.colors?.["#E5EDFF"],
    colorFeedbackBorderEmphasized: definitionTokens.colors?.["#FFD75D"],
    colorFeedbackBorderNegative: definitionTokens.colors?.["#A91719"],
    colorFeedbackBorderRevoke: definitionTokens.colors?.["#D82927"],
    colorFeedbackBorderMultiple: definitionTokens.colors?.["#295D7F"],

    colorFeedbackInlineBorderSuccess: definitionTokens.colors?.["#E7FDEC"],
    colorFeedbackInlineBorderError: definitionTokens.colors?.["#FFEBED"],
    colorFeedbackInlineBorderWarning: definitionTokens.colors?.["#FFF8E6"],

    /////////////////////////////////////////////////////////////////

    ////interaction colors

    /////////////////////////////////////////////////////////////////

    colorInteractionBackgroundPrimary: definitionTokens.colors?.["#FFFFFF"],
    colorInteractionBackgroundPrimaryHover:
      definitionTokens.colors?.["#FFF3F0"],
    colorInteractionBackgroundPrimaryFocus:
      definitionTokens.colors?.["#C21D16"],
    colorInteractionBackgroundPrimaryActive:
      definitionTokens.colors?.["#FFF3F0"],
    colorInteractionBackgroundSecondary: definitionTokens.colors?.["#FCFCFC"],
    colorInteractionBackgroundSecondaryHover:
      definitionTokens.colors?.["#FFD1CF"],
    colorInteractionBackgroundTertiary: definitionTokens.colors?.["#F0F0F0"],
    colorInteractionBackgroundTertiaryHover:
      definitionTokens.colors?.["#FFE1DF"],
    colorInteractionBackgroundQuaternary: definitionTokens.colors?.["#E2E2E2"],
    colorInteractionBackgroundRemoveHover: definitionTokens.colors?.["#FDE8E9"],

    // Chevron color

    colorInteractionChevronPrimary: definitionTokens.colors?.["#C21D16"],

    // text color

    colorInteractionTextPrimary: definitionTokens.colors?.["#3C3C3C"],
    colorInteractionTextPrimaryFocus: definitionTokens.colors?.["#FFFFFF"],
    colorInteractionTextSecondary: definitionTokens.colors?.["C21D16"],
    colorInteractionTextSecondaryFocus: definitionTokens.colors?.["#686868"],
    colorInteractionTextTertiary: definitionTokens.colors?.["#E56666"],
    colorInteractionTextQuaternary: definitionTokens.colors?.["#B44848"],
    colorInteractionTextQuinary: definitionTokens.colors?.["#C21D16"],
    colorInteractionTextSenary: definitionTokens.colors?.["#91939A"],

    // border color

    colorInteractionBorderPrimary: definitionTokens.colors?.["#C21D16"],
    colorInteractionBorderPrimaryHover: definitionTokens.colors?.["#D1D1D1"],
    colorInteractionBorderPrimaryFocus: definitionTokens.colors?.["#C21D16"],
    colorInteractionBorderSecondary: definitionTokens.colors?.["#E8E8E8"],
    colorInteractionBorderTertiary: definitionTokens.colors?.["#E2E2E2"],
    colorInteractionBorderQuaternary: definitionTokens.colors?.["#BFBFBF"],

    // Line color

    /////////////////////////////////////////////////////////////////

    ////additional colors

    /////////////////////////////////////////////////////////////////

    lightBackground: definitionTokens.colors?.["#F1F1F1"],
  },

  boxStyles: {
    //radius

    boxStyleActionBorderRadiusSmallX: definitionTokens.radius,
    boxStyleActionBorderRadiusSmall: definitionTokens.radius,
    boxStyleActionBorderRadiusLarge: definitionTokens.radius,
    boxStyleActionBorderRadius5px: "5px",
    //border

    boxStyleActionBorderPrimary: definitionTokens.borders?.border1pxSolid,

    // shadow

    actionShadowPrimary: "none",

    headerShadow: "0px 1px 0px 0px #a1a1a1",

    documentShadow: "0px 2px 10px 0px #00000026",
  },
}

// Bridge Tokens from Tier 2 to Tier 3
// This object is used for mapping theme-related variables from Tier 2 to Tier 3 and
// should be setup in each theme. This is so the T3 componentVariables object in componentVariables.ts
// can remain the same and be reused across the themes.
export const bridgeTokensT2ToT3: ComponentVariablesTypes = {
  universal: {
    colors: {
      // Action colors
      componentColorActionBackgroundDisabledT2Token:
        applicationTokens.colors.colorActionBackgroundDisabled,
      componentColorActionBorderDisabledT2Token:
        applicationTokens.colors.colorActionBorderDisabled,
      componentColorActionTextDisabledT2Token:
        applicationTokens.colors.colorActionTextDisabled,

      // Feedback colors
      componentColorFeedbackSuccessT2Token:
        applicationTokens.colors.colorFeedbackSuccess,
      componentColorFeedbackSuccessHoverT2Token:
        applicationTokens.colors.colorFeedbackSuccessHover,
      componentColorFeedbackSuccessShadeT2Token:
        applicationTokens.colors.colorFeedbackSuccessShade,
      componentColorFeedbackWarningT2Token:
        applicationTokens.colors.colorFeedbackWarning,
      componentColorFeedbackWarningHoverT2Token:
        applicationTokens.colors.colorFeedbackWarningHover,
      componentColorFeedbackWarningShadeT2Token:
        applicationTokens.colors.colorFeedbackWarningShade,
      componentColorFeedbackDangerT2Token:
        applicationTokens.colors.colorFeedbackDanger,
      componentColorFeedbackDangerHoverT2Token:
        applicationTokens.colors.colorFeedbackDangerHover,
      componentColorFeedbackDangerShadeT2Token:
        applicationTokens.colors.colorFeedbackDangerShade,
      componentColorFeedbackMessageT2Token:
        applicationTokens.colors.colorFeedbackMessage,
      componentColorFeedbackMessageHoverT2Token:
        applicationTokens.colors.colorFeedbackMessageHover,
      componentColorFeedbackMessageShadeT2Token:
        applicationTokens.colors.colorFeedbackMessageShade,

      // Interaction colors
      componentColorInteractionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimaryHover,
      componentColorInteractionBackgroundSecondaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundSecondaryHover,
      componentColorInteractionBackgroundRemoveHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundSecondaryHover,
      componentColorInteractionBorderHoverT2Token:
        applicationTokens.colors.colorInteractionBorderPrimary,

      // Brand colors
      colorUniversalNaturalBlackT2Token:
        applicationTokens.colors.colorUniversalNaturalBlack,
      colorUniversalNaturalWhiteT2Token:
        applicationTokens.colors.colorUniversalNaturalWhite,
      colorUniversalNaturalGreyT2Token:
        applicationTokens.colors.colorUniversalNaturalGrey,
      colorUniversalSecondaryAT2Token:
        applicationTokens.colors.colorUniversalSecondaryA,
      colorUniversalSecondaryBT2Token:
        applicationTokens.colors.colorUniversalSecondaryB,
      colorUniversalSecondaryCT2Token:
        applicationTokens.colors.colorUniversalSecondaryC,
      colorUniversalSecondaryDT2Token:
        applicationTokens.colors.colorUniversalSecondaryD,
      colorUniversalSecondaryET2Token:
        applicationTokens.colors.colorUniversalSecondaryE,
      colorUniversalSecondaryFT2Token:
        applicationTokens.colors.colorUniversalSecondaryF,
      colorUniversalSecondaryGT2Token:
        applicationTokens.colors.colorUniversalSecondaryG,
      colorUniversalSecondaryHT2Token:
        applicationTokens.colors.colorUniversalSecondaryH,
      colorUniversalToneAT2Token: applicationTokens.colors.colorUniversalToneA,
      colorUniversalToneA25T2Token:
        applicationTokens.colors.colorUniversalToneA25,
      colorUniversalPrimaryBlackT2Token:
        applicationTokens.colors.colorUniversalPrimaryBlack,
      colorUniversalPrimaryGreyT2Token:
        applicationTokens.colors.colorUniversalPrimaryGrey,
      colorUniversalPrimaryBackgroundT2Token:
        applicationTokens.colors.colorUniversalPrimaryBackground,
      colorUniversalPrimaryBaseT2Token:
        applicationTokens.colors.colorUniversalPrimaryBase,
      cardColorContextBackgroundPrimaryT2Token:
        applicationTokens.colors.colorContextBackgroundPrimary,
    },
  },
  alertMessage: {
    colors: {
      background: {
        alertMessageColorFeedbackBackgroundMessageT2Token:
          applicationTokens.colors?.colorFeedbackBackgroundInlineMessage,
        alertMessageColorFeedbackBackgroundSuccessT2Token:
          applicationTokens.colors?.colorFeedbackInlineBackgroundSuccess,
        alertMessageColorFeedbackBackgroundWarningT2Token:
          applicationTokens.colors?.colorFeedbackInlineBackgroundWarning,
        alertMessageColorFeedbackBackgroundErrorT2Token:
          applicationTokens.colors?.colorFeedbackInlineBackgroundError,
      },
      border: {
        alertMessageColorFeedbackBorderSuccessT2Token:
          applicationTokens.colors?.colorFeedbackInlineBorderSuccess,
        alertMessageColorFeedbackBorderWarningT2Token:
          applicationTokens.colors?.colorFeedbackInlineBorderWarning,
        alertMessageColorFeedbackBorderErrorT2Token:
          applicationTokens.colors?.colorFeedbackInlineBorderError,
      },
    },
  },
  accordion: {
    colors: {
      background: {
        accordionColorContextBackgroundSectionActiveT2Token:
          applicationTokens.colors.colorInteractionBackgroundPrimary,
      },
    },
  },
  button: {
    colors: {
      // Button background color
      buttonColorActionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorActionBackgroundPrimary,
      buttonColorActionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorActionBackgroundPrimaryHover,
      buttonColorActionBackgroundSecondaryT2Token:
        applicationTokens.colors.colorActionBackgroundSecondary,
      buttonColorActionBackgroundSecondaryHoverT2Token:
        applicationTokens.colors.colorActionBackgroundPrimary,
      buttonColorActionBackgroundTertiaryT2Token:
        applicationTokens.colors.colorActionBackgroundSecondary,
      buttonColorActionBackgroundTertiaryHoverT2Token:
        applicationTokens.colors.colorActionBackgroundPrimary,
      buttonColorActionBackgroundDangerT2Token:
        applicationTokens.colors.colorActionBackgroundDanger,
      buttonColorActionBackgroundDangerHoverT2Token:
        applicationTokens.colors.colorActionBackgroundDangerHover,
      buttonColorActionBackgroundRemoveT2Token:
        applicationTokens.colors.colorActionBackgroundSecondary,
      buttonColorActionBackgroundRemoveHoverT2Token:
        applicationTokens.colors.colorActionBackgroundDangerHover,
      buttonColorActionBackgroundDisabledT2Token:
        applicationTokens.colors.colorActionBackgroundDisabled,

      // Text color
      buttonColorActionTextPrimaryT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextPrimaryHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextSecondaryT2Token:
        applicationTokens.colors.colorActionTextSecondary,
      buttonColorActionTextSecondaryHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextTertiaryT2Token:
        applicationTokens.colors.colorActionTextSecondary,
      buttonColorActionTextTertiaryHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextDangerT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextDangerHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextRemoveT2Token:
        applicationTokens.colors.colorActionTextTertiary,
      buttonColorActionTextRemoveHoverT2Token:
        applicationTokens.colors.colorActionTextPrimary,
      buttonColorActionTextDisabledT2Token:
        applicationTokens.colors.colorActionTextDisabled,
    },
    boxStyles: {
      // Border radius
      buttonActionBorderRadiusButtonXT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusSmallX,
      // Border
      buttonActionBorderPrimaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderSecondary ?? "Not set"
        ),
      buttonActionBorderPrimaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderPrimaryHover ?? "Not set"
        ),
      buttonActionBorderSecondaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderSecondary ?? "Not set"
        ),
      buttonActionBorderSecondaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderPrimary ?? "Not set"
        ),
      buttonActionBorderTertiaryT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderTertiary ?? "Not set"
        ),
      buttonActionBorderTertiaryHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderPrimary ?? "Not set"
        ),
      buttonActionBorderDangerT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderDanger ?? "Not set"
        ),
      buttonActionBorderDangerHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderDangerHover ?? "Not set"
        ),
      buttonActionBorderRemoveT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderDanger ?? "Not set"
        ),
      buttonActionBorderRemoveHoverT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderPrimary?.concat(
          applicationTokens.colors.colorActionBorderDangerHover ?? "Not set"
        ),
      // Box shadow
      buttonActionShadowPrimaryT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowPrimaryHoverT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowSecondaryT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowSecondaryHoverT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowTertiaryT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
      buttonActionShadowTertiaryHoverT2Token:
        applicationTokens.boxStyles.actionShadowPrimary,
    },
  },
  breadcrumb: {
    colors: {
      breadcrumbColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorInteractionTextTertiary,
      breadcrumbColorInteractionTextPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionTextQuaternary,
    },
  },
  card: {
    colors: {
      border: {
        cardColorContextBorderLighterT2Token:
          applicationTokens.colors.colorInteractionBorderSecondary,
        cardColorContextBorderDarkerT2Token:
          applicationTokens.colors.colorInteractionBorderTertiary,
      },
    },
  },
  divider: {
    colors: {
      background: {
        dividerColorContextBackgroundPrimaryT2Token:
          applicationTokens.colors.colorInteractionBackgroundQuaternary,
        dividerColorContextBackgroundLighterT2Token:
          applicationTokens.colors.lightBackground,
      },
    },
  },
  dragAndDrop: {
    colors: {
      background: {
        dragAndDropInteractionColorBackgroundT2Token:
          applicationTokens.colors.colorInteractionBackgroundPrimaryHover,
      },
    },
    boxStyles: {
      dragAndDropInteractionBorderRadiusT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadius5px,
    },
  },
  stageDetails: {
    colors: {
      subHeaderBackgroundColor: undefined,
      firstSectionBackgroundColor: undefined,
      uploadFileColor: undefined,
      uploadFileStroke: undefined,
    },
  },
  tabBar: {
    colors: {
      tabBarColorInteractionTextFocusT2Token:
        applicationTokens.colors.colorInteractionTextQuinary,
      tabBarColorInteractionTextInactiveT2Token:
        applicationTokens.colors.colorInteractionTextPrimary,
      tabBarColorInteractionTabLineFocusT2Token:
        applicationTokens.colors.colorInteractionTextQuinary,
      tabBarColorInteractionTabLineInactiveT2Token:
        applicationTokens.colors.colorActionBorderDisabled,
    },
  },
  documentTaggingPage: {
    colors: {
      headerBackgroundColor: applicationTokens.colors.lightBackground,
      borderColor: applicationTokens.colors.colorInteractionBorderPrimary,
    },
    boxShadows: {
      header: applicationTokens.boxStyles.headerShadow,
      document: applicationTokens.boxStyles.documentShadow,
    },
  },
  tabbedFolder: {
    boxStyles: {
      boxStyleActionBorderRadiusSmallT2Token: "not set",
      actionShadowPrimaryT2Token: "not set",
    },
  },
  table: {
    colors: {
      tableColorContextBackgroundLabelT2Token:
        applicationTokens.colors.colorContextBackgroundPrimary,
    },
  },
  tag: {
    colors: {
      // Background color
      tagColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimary,
      tagColorInteractionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundSecondaryHover,
      tagColorInteractionBackgroundPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimaryFocus,

      // Text color
      tagColorInteractionTextT2Token:
        applicationTokens.colors.colorInteractionTextSecondary,
      tagColorInteractionTextHoverT2Token:
        applicationTokens.colors.colorInteractionTextQuinary,
      tagColorInteractionTextFocusT2Token:
        applicationTokens.colors.colorInteractionTextQuinary,

      // border color
      tagColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors.colorInteractionBorderQuaternary,
      tagColorInteractionBorderPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionBorderPrimaryFocus,
    },
    boxStyles: {
      // radius
      borderRadiusT2Token:
        applicationTokens.boxStyles.boxStyleActionBorderRadiusLarge,
    },
  },
  toggle: {
    colors: {
      toggleColorInteractionInteractiveT2Token:
        applicationTokens.colors.colorInteractionTextSecondaryFocus,
      toggleColorInteractionFocusT2Token:
        applicationTokens.colors.colorInteractionBorderPrimary,
    },
  },
  search: {
    colors: {
      // Background color
      searchColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimary,
      searchColorInteractionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundSecondaryHover,
      // Chevron color
      searchColorInteractionChevronT2Token: "not set",

      // Text color
      searchColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorActionTextDisabled,
      searchColorInteractionTextPrimaryFocusT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,

      // border color
      searchColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors.colorInteractionBorderPrimaryHover,
      searchColorInteractionBorderPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBorderPrimary,
      searchColorInteractionBorderPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionBorderPrimaryFocus,
    },
  },
  footer: {
    colors: {
      footerColorContextBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundSecondary,
    },
  },
  form: {
    colors: {
      // Background color
      formColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimary,
      formColorInteractionBackgroundPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBackgroundTertiaryHover,

      // Text color
      formColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorInteractionTextSenary,
      formLabelColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorInteractionTextPrimary,
      formColorInteractionTextPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionTextPrimary,

      // border color
      formColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors.colorInteractionBorderPrimaryHover,
      formColorInteractionBorderPrimaryHoverT2Token:
        applicationTokens.colors.colorInteractionBorderPrimaryFocus,
      formColorInteractionBorderPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionBorderPrimaryFocus,
    },
    boxStyles: {
      formColorInteractionBorderPrimaryFocusT2Token: "not set",
    },
  },
  lozenge: {
    colors: {
      // Background color
      lozengeColorFeedbackBackgroundPrimaryT2Token:
        applicationTokens.colors.colorFeedbackPrimary,
      lozengeColorFeedbackBackgroundPendingT2Token:
        applicationTokens.colors.colorFeedbackPending,
      lozengeColorFeedbackBackgroundPositiveT2Token:
        applicationTokens.colors.colorFeedbackBackgroundPositive,
      lozengeColorFeedbackBackgroundInfoT2Token:
        applicationTokens.colors.colorFeedbackBackgroundInfo,
      lozengeColorFeedbackBackgroundEmphasizedT2Token:
        applicationTokens.colors.colorFeedbackBackgroundEmphasized,
      lozengeColorFeedbackBackgroundNegativeT2Token:
        applicationTokens.colors.colorFeedbackBackgroundNegative,
      lozengeColorFeedbackBackgroundRevokeT2Token:
        applicationTokens.colors.colorFeedbackBackgroundRevoke,
      lozengeColorFeedbackBackgroundMultipleT2Token:
        applicationTokens.colors.colorFeedbackBackgroundMultiple,

      // Text color
      lozengeColorFeedbackTextPrimaryT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,
      lozengeColorFeedbackTextPendingT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,
      lozengeColorFeedbackTextNegativeT2Token:
        applicationTokens.colors.colorFeedbackTextSecondary,
      lozengeColorFeedbackTexRevokeT2Token:
        applicationTokens.colors.colorFeedbackTextTertiary,
      lozengeColorFeedbackTexInfoT2Token:
        applicationTokens.colors.colorFeedbackTextQuaternary,
      lozengeColorFeedbackTexPositiveT2Token:
        applicationTokens.colors.colorFeedbackTextQuinary,
      lozengeColorFeedbackTexEmphasizedT2Token:
        applicationTokens.colors.colorFeedbackTextPrimary,
      lozengeColorFeedbackTexMultipleT2Token:
        applicationTokens.colors.colorFeedbackTextSecondary,

      // Border color
      lozengeColorFeedbackBorderPrimaryT2Token:
        applicationTokens.colors.colorFeedbackBorderPrimary,
      lozengeColorFeedbackBorderPendingT2Token:
        applicationTokens.colors.colorFeedbackPending,
      lozengeColorFeedbackBorderPositiveT2Token:
        applicationTokens.colors.colorFeedbackBorderPositive,
      lozengeColorFeedbackBorderNegativeT2Token:
        applicationTokens.colors.colorFeedbackBorderNegative,
      lozengeColorFeedbackBorderRevokeT2Token:
        applicationTokens.colors.colorFeedbackBorderNegative,
      lozengeColorFeedbackBorderInfoT2Token:
        applicationTokens.colors.colorFeedbackBorderInfoSecondary,
      lozengeColorFeedbackBorderEmphasizedT2Token:
        applicationTokens.colors.colorFeedbackBorderEmphasized,
      lozengeColorFeedbackBorderMultipleT2Token:
        applicationTokens.colors.colorFeedbackBorderMultiple,
    },
  },
  link: {
    colors: {
      linkColorActionTextLineT2Token:
        applicationTokens.colors?.colorInteractionTextTertiary,
      linkColorActionTextPrimaryT2Token:
        applicationTokens.colors?.colorInteractionTextTertiary,
      linkColorActionTextPrimaryHoverT2Token:
        applicationTokens.colors?.colorInteractionTextQuaternary,
    },
  },
  modal: {
    colors: {
      modalColorContextBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimary,
    },
  },
  navbar: {
    colors: {
      navbarColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimary,
      navbarColorInteractionBackgroundExpandT2Token:
        applicationTokens.colors.colorInteractionBackgroundTertiary,
      navbarColorInteractionTextPrimaryT2Token:
        applicationTokens.colors.colorInteractionTextPrimary,
      navbarColorInteractionBackgroundActiveT2Token:
        applicationTokens.colors.colorInteractionBackgroundPrimaryActive,
    },
  },
  notifications: {
    colors: {
      newNotificationColorBackgroundT2Token:
        definitionTokens.colors?.["#FFD1CF"],
    },
  },
  page: {
    colors: {
      pageColorContextBackgroundPrimaryT2Token:
        applicationTokens.colors.colorContextBackgroundPrimary,
      pageColorContextBackgroundSecondaryT2Token: "not set",
    },
  },
  pagination: {
    colors: {
      // Background color
      paginationColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors?.colorInteractionBackgroundPrimary,
      paginationColorInteractionBackgroundPrimaryFocusT2Token:
        applicationTokens.colors?.colorInteractionBackgroundPrimaryFocus,

      // border color
      paginationColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors?.colorInteractionBorderSecondary,
      // Text color
      paginationColorInteractionTextPrimaryT2Token:
        applicationTokens.colors?.colorInteractionTextPrimary,
      paginationColorInteractionTextPrimaryFocusT2Token:
        applicationTokens.colors.colorInteractionTextPrimaryFocus,
      // Chevron color
      paginationColorInteractionChevronT2Token:
        applicationTokens.colors.colorInteractionChevronPrimary,
    },
  },
  panel: {
    colors: {
      panelColorBackgroundPrimaryT2Token: "not set",
    },
    boxStyles: {
      boxStyleActionBorderRadiusSmallT2Token: "not set",
      panelShadowPrimary: "not set",
      actionShadowPrimaryT2Token: "not set",
    },
  },
  profileButton: {
    colors: {
      profileButtonColorTextT2Token:
        applicationTokens.colors.colorUniversalSecondaryE,

      // Background color
      profileButtonColorBackgroundT2Token:
        applicationTokens.colors.colorUniversalPrimaryBase,
    },
    outlines: {
      profileButtonOutlineT2Token: `3px solid ##E8E8E8`,
      profileButtonOutlineOffsetT2Token: "0px",
    },
  },
  results: {
    colors: {
      resultsColorInteractionBackgroundPrimaryT2Token:
        applicationTokens.colors?.colorInteractionBackgroundPrimary,
      // Background color
      resultsColorInteractionBackgroundChevronT2Token:
        applicationTokens.colors?.colorInteractionBackgroundPrimary,
      // Chevron color
      resultsColorInteractionChevronT2Token:
        applicationTokens.colors?.colorInteractionChevronPrimary,
      // border color
      resultsColorInteractionBorderPrimaryT2Token:
        applicationTokens.colors?.colorInteractionBorderQuaternary,
    },
  },
}
