import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { debounce } from "lodash"
import {
  getFileById,
  getFoldersSharedByClient,
} from "../../api/api-client/api-handler"
import { IFile } from "../../api/api-client/api-types"
import { deleteDocuSignFile } from "../../api/lib/docuSign/docuSign"
import { DocusignSignedStatus } from "../../api/lib/docuSign/models/DocusignSignedStatus"
import { postSendEmailReminderForFile } from "../../api/lib/reminders/reminders"
import { CaseDocument } from "../../api/lib/workflow/models/GetCaseDocumentResponse"
import {
  CaseDocumentActionId,
  putWorkflowCaseDocumentAction,
} from "../../api/lib/workflow/workflow"
import {
  changeFileInFolder,
  setLoadingState,
  setSelectedFile,
  setSharedWithMeFolders,
} from "../../contexts/application/action"
import {
  ActionType,
  UserModuleType,
} from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../contexts/toasts"
import { useUserContext } from "../../contexts/users"
import { useDownloadFile } from "../../hooks/useDownloadFile"
import { getCompanyNameFromTheme } from "../../utils/companies/companies"
import { CoopCompanyName } from "../../utils/consts/consts"
import { openDocusignUrl } from "../../utils/docusign"
import { WorkflowStatuses } from "../../utils/enums/WorkflowStatuses"
import { setPinFile } from "../../utils/pinnedNodes/setPin"
import CreateContact from "../CreateContact/CreateContact"
import { DeleteCaseFile } from "../DeleteCaseFile/DeleteCaseFile"
import { DeleteNode } from "../DeleteNode"
import { FileRename } from "../FileRename"
import { RejectDocumentModal } from "../RejectDocumentModal/RejectDocumentModal"
import { SharingPermissionsNode } from "../SharingPermissions/SharingPermissionsNode"
import { BUTTON_SIZE, BUTTON_VARIANT, Button } from "../atoms/Button"
import { LoadingSpinner } from "../atoms/LoadingSpinner/LoadingSpinner"
import { Modal, ModalContent, ModalHeader } from "../modules/Modal"
import "./FileDetails.css"

const FileSharingButtonAndModal: FC<{
  file: IFile
  caseDocument?: CaseDocument
}> = ({ file, caseDocument }) => {
  const [isOpen, setModalOpen] = useState(false)
  const [isCreateContactOpen, setIsCreateContactOpen] = useState(false)

  return (
    <>
      <Button
        className="mr-3 mb-3 file-details"
        variant={BUTTON_VARIANT.SECONDARY}
        iconPosition="left"
        icon="ThreeUsers"
        onClick={() => setModalOpen(true)}
      >
        Sharing
      </Button>
      {!isCreateContactOpen && isOpen && (
        <SharingPermissionsNode
          file={file}
          onClose={() => setModalOpen(false)}
          setIsCreateContactOpen={() => {
            setModalOpen(false)
            setIsCreateContactOpen(true)
          }}
          // case documents should not have the option for edit in sharing permissions
          shouldHideEditOption={caseDocument !== undefined}
        />
      )}
      {!isOpen && isCreateContactOpen && (
        <CreateContact
          closeCreateContact={() => setIsCreateContactOpen(false)}
          shouldShowErrorsOnSubmitOnly={!!caseDocument}
          isAddingContactThroughWorkflowDocument={!!caseDocument}
          documentFilesToShare={caseDocument && [file]}
        />
      )}
    </>
  )
}

type CancelDocumentRequestModalProps = {
  onClose: () => void
  fileName: string
  cancelDocusignRequest: () => void
}

const CancelDocumentRequestModal: FC<CancelDocumentRequestModalProps> = ({
  onClose,
  fileName,
  cancelDocusignRequest,
}) => {
  return (
    <Modal name="Cancel Document" onClose={onClose}>
      <ModalHeader>Cancel document request</ModalHeader>
      <ModalContent>
        <div className="file-details-cancel-modal-inner">
          Are you sure you want to cancel a request for: {fileName}
        </div>
        <Button
          className="file-details-cancel-modal-button"
          variant={BUTTON_VARIANT.DANGER_PRIMARY}
          onClick={cancelDocusignRequest}
        >
          Cancel request
        </Button>
      </ModalContent>
    </Modal>
  )
}

export type FileDetailsActionsProps = {
  file: IFile
  caseDocument?: CaseDocument
  setCaseDocument?: Dispatch<SetStateAction<CaseDocument | undefined>>
  docuSignUrl?: string
  getDocusignUrl: () => Promise<string | undefined>
}
export const FileDetailsActions: FC<FileDetailsActionsProps> = ({
  file,
  caseDocument,
  setCaseDocument,
  getDocusignUrl,
}) => {
  const {
    applicationState: {
      pinnedFoldersAndFiles,
      folders,
      sharedWithMeFolders,
      selectedSharingWithMeUserId,
      selectedTheme,
    },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: toastDispatch } = useToastContext()

  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [isAcknowledging, setIsAcknowledging] = useState(false)
  const [isFetchingDocusignUrl, setIsFetchingDocusignUrl] = useState(false)

  const { workflowId, workflowVersion, caseId } = useParams()
  const isSharedFile = file.ownerId !== currentUser?.userId
  const isDocumentAndShareable = caseDocument && !caseDocument.isComplete
  const canEdit = isSharedFile
    ? currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_EDIT)
    : currentUser?.modules?.includes(UserModuleType.PERSONALISE_VAULT)

  const isUsingWorkflows = currentUser?.modules?.includes(
    UserModuleType.CASE_TRACKER
  )
  const actionType = caseDocument?.actionType
  const shouldShowDeleteButton =
    file.isDeletable &&
    !file.hasCommenting &&
    (canEdit ||
      (isUsingWorkflows &&
        actionType === ActionType.Upload &&
        caseDocument?.displayStatus?.toLocaleLowerCase() ===
          ActionType.Uploaded &&
        caseDocument?.createdById === currentUser?.userId))

  const isDocusignFile = file?.isDocusignFile
  const hasVaultDocumentUploadForSigning = currentUser?.modules?.includes(
    UserModuleType.VAULT_DOCUMENT_UPLOAD_FOR_SIGNING
  )

  const shouldShowRejectButtonWorkflows =
    isUsingWorkflows &&
    !hasVaultDocumentUploadForSigning &&
    !caseDocument?.isComplete &&
    caseDocument?.currentStatus === WorkflowStatuses.RequiredForAction &&
    (actionType === ActionType.CompleteAndUpload ||
      actionType === ActionType.ReviewAndSign ||
      actionType === ActionType.ReviewAndApprove ||
      actionType === ActionType.ReviewUploadAndSign ||
      actionType === ActionType.ReviewDownloadSignAndPost ||
      actionType === ActionType.CompleteAndSign)

  const shouldShowSignOrReject =
    !isUsingWorkflows &&
    file.isDocusignFile &&
    file.signedStatus === DocusignSignedStatus.PENDING &&
    hasVaultDocumentUploadForSigning &&
    currentUser?.roles?.includes("PrimaryUser")

  const shouldShowAcknowledgeButton =
    isUsingWorkflows &&
    !hasVaultDocumentUploadForSigning &&
    !caseDocument?.isComplete &&
    caseDocument?.currentStatus === WorkflowStatuses.RequiredForAction &&
    actionType === ActionType.ReviewAndApprove

  const [renameIsOpen, setRenameOpen] = useState(false)
  const [deleteNodeOpen, setDeleteNodeOpen] = useState(false)
  const [isDownloadingFile, setIsDownloading] = useState(false)
  const [changePinned, setChangePinned] = useState(false)
  const isPinned = !!pinnedFoldersAndFiles?.contents?.find(
    (x) => x.id === file.id
  )

  useDownloadFile({ file, isDownloadingFile, setIsDownloading })

  useEffect(() => {
    const onPin = async () => {
      if (changePinned) {
        const newIsPinned = !isPinned
        setChangePinned(false)
        setPinFile(
          dispatch,
          toastDispatch,
          newIsPinned,
          file,
          file.isOwner ? folders! : sharedWithMeFolders!,
          currentUser
        )
      }
    }
    onPin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePinned])

  const onAcknowledge = async () => {
    try {
      setIsAcknowledging(true)
      if (
        workflowId &&
        workflowVersion &&
        caseId &&
        caseDocument?.stageId &&
        caseDocument?.requestId &&
        caseDocument?.id &&
        setCaseDocument
      ) {
        const newCase = await putWorkflowCaseDocumentAction({
          workflowId,
          workflowVersion,
          caseId,
          stageId: caseDocument.stageId.toString(),
          requestId: caseDocument.requestId.toString(),
          documentId: caseDocument.id.toString(),
          actionId: CaseDocumentActionId.Acknowledge,
          userId: currentUser?.userId,
        })
        setCaseDocument(newCase)
        toastDispatch(
          setDisplaySuccessOrErrorMessage({
            title: "Acknowledged",
            messageType: "SUCCESS",
            message: `${CoopCompanyName} will be notified.`,
          })
        )
      }
    } catch (error) {
      console.error("error:", error)
    } finally {
      setIsAcknowledging(false)
    }
  }

  const sendDocusignReminder = async () => {
    try {
      await postSendEmailReminderForFile({
        fileId: file.id,
        targetUserId: selectedSharingWithMeUserId,
      })
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          messageType: "SUCCESS",
          message: "An email has been sent.",
        })
      )
    } catch (error) {
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          title: "Error",
          messageType: "ERROR",
          message: "Could not send an email reminder. Please try again later.",
        })
      )
    }
  }

  const cancelDocusignRequest = async () => {
    try {
      await deleteDocuSignFile({ fileId: file.id })

      dispatch(setLoadingState(true))
      const folderResponse = await getFoldersSharedByClient(
        selectedSharingWithMeUserId
      )
      dispatch(setSharedWithMeFolders(folderResponse))
      dispatch(setLoadingState(false))

      // This navigates the user back to the list of files and folders of a client
      // because there is no selected file anymore
      dispatch(setSelectedFile())
    } catch (error) {
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          title: "Error",
          messageType: "ERROR",
          message:
            "Could not cancel the docusign request. Please try again later",
        })
      )
    }
  }

  return (
    <>
      <div>
        {renameIsOpen && (
          <FileRename
            onClose={() => {
              setRenameOpen(false)
            }}
            file={file}
            isShared={isSharedFile}
            caseDocument={caseDocument}
            setCaseDocument={setCaseDocument}
          />
        )}
        {deleteNodeOpen &&
          (isUsingWorkflows ? (
            <DeleteCaseFile
              onClose={() => setDeleteNodeOpen(false)}
              file={file}
            />
          ) : (
            <DeleteNode
              onClose={() => {
                setDeleteNodeOpen(false)
              }}
              file={file}
              isShared={isSharedFile}
            />
          ))}

        <div
          data-testid="file-details-action-buttons-id"
          className="file-details-buttons"
          id="file-details-action-buttons-id"
        >
          {file.isEditable && canEdit && (
            <Button
              className="file-details mb-3"
              iconPosition="left"
              icon="Pencil"
              onClick={() => setRenameOpen(true)}
              variant={BUTTON_VARIANT.SECONDARY}
              size={BUTTON_SIZE.SMALL}
            >
              Rename
            </Button>
          )}

          <Button
            className="mr-3 mb-3 file-details"
            variant={BUTTON_VARIANT.SECONDARY}
            iconPosition="left"
            icon="FnzDownload"
            onClick={() => setIsDownloading(true)}
          >
            Download
          </Button>
          {hasVaultDocumentUploadForSigning &&
            isDocusignFile &&
            file.signedStatus === DocusignSignedStatus.PENDING &&
            currentUser?.roles?.includes("Adviser") && (
              <>
                <Button
                  className="mr-3 mb-3 file-details"
                  variant={BUTTON_VARIANT.SECONDARY}
                  onClick={sendDocusignReminder}
                >
                  Send reminder
                </Button>
                {showCancelModal && (
                  <CancelDocumentRequestModal
                    onClose={() => setShowCancelModal(false)}
                    fileName={file.fileName || ""}
                    cancelDocusignRequest={cancelDocusignRequest}
                  />
                )}
                <Button
                  className="mb-3 mr-3 file-details"
                  variant={BUTTON_VARIANT.DANGER_REMOVE}
                  onClick={() => setShowCancelModal(true)}
                >
                  Cancel
                </Button>
              </>
            )}
          {currentUser?.modules?.includes(UserModuleType.SHARING) &&
            (!isSharedFile || isDocumentAndShareable) && (
              <FileSharingButtonAndModal
                file={file}
                caseDocument={caseDocument}
              />
            )}
          {canEdit && currentUser?.modules?.includes(UserModuleType.VAULT) && (
            <Button
              className="mr-3 mb-3 file-details"
              variant={BUTTON_VARIANT.SECONDARY}
              iconPosition="left"
              icon={isPinned ? "PinRemove" : "Pin"}
              onClick={() => {
                setChangePinned(true)
              }}
            >
              {isPinned ? "Unpin" : "Pin"}
            </Button>
          )}
          {shouldShowDeleteButton && (
            <Button
              className="mb-3 mr-3 file-details"
              variant={BUTTON_VARIANT.DANGER_REMOVE}
              iconPosition="left"
              icon="FnzBin"
              onClick={() => setDeleteNodeOpen(true)}
            >
              Delete
            </Button>
          )}
          {shouldShowAcknowledgeButton && (
            /* TODO: Button component should support isBusy or isLoading prop and we should add the spinner there.  https://dev.azure.com/secure-the-file/Application/_workitems/edit/14437 */
            <Button
              className="mb-3 mr-3 file-details"
              variant={BUTTON_VARIANT.SECONDARY}
              onClick={onAcknowledge}
              title={isAcknowledging ? "Acknowledging..." : "Acknowledge"}
            >
              {!isAcknowledging && "Acknowledge"}
              {isAcknowledging && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span
                    className="mr-1"
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      width: "112px",
                    }}
                  >
                    Acknowledging
                  </span>
                  <LoadingSpinner
                    size="20px"
                    thickness="2px"
                    color="var(--color-universal-secondary-e)"
                  />
                </div>
              )}
            </Button>
          )}
          {(shouldShowRejectButtonWorkflows || shouldShowSignOrReject) && (
            <>
              {showRejectModal && (
                <RejectDocumentModal
                  setShowRejectModal={setShowRejectModal}
                  setCaseDocument={setCaseDocument}
                  caseDocument={caseDocument}
                  workflowId={workflowId}
                  workflowVersion={workflowVersion}
                  caseId={caseId}
                  currentUser={currentUser}
                  documentName={file.name}
                  fileId={file.id}
                  companyName={getCompanyNameFromTheme(selectedTheme)}
                  onSuccess={async () => {
                    if (file.id) {
                      try {
                        const newFile = await getFileById(file.id)
                        if (newFile.parentId) {
                          dispatch(
                            changeFileInFolder({
                              file: newFile,
                              folderId: newFile.parentId,
                            })
                          )
                        }
                      } catch (error) {
                        toastDispatch(
                          setDisplaySuccessOrErrorMessage({
                            title: "Error",
                            messageType: "ERROR",
                            message:
                              "An error has occurred while fetching your changed file from the server",
                          })
                        )
                      }
                    }
                  }}
                />
              )}
              <Button
                className="mb-3 mr-3 file-details"
                variant={BUTTON_VARIANT.DANGER_PRIMARY}
                onClick={() => setShowRejectModal(true)}
              >
                Reject
              </Button>
            </>
          )}
          {shouldShowSignOrReject && (
            <Button
              className="mb-3 mr-3 file-details"
              variant={BUTTON_VARIANT.PRIMARY}
              // debounce is used here to stop a user from repeatedly clicking and calling backend API
              onClick={debounce(async () => {
                setIsFetchingDocusignUrl(true)
                const url = await getDocusignUrl()
                setIsFetchingDocusignUrl(false)
                openDocusignUrl(url)
              }, 500)}
              isLoading={isFetchingDocusignUrl}
            >
              Sign and Complete
            </Button>
          )}
        </div>
      </div>
    </>
  )
}
