import {
  TAnimation,
  TFont,
  TTypeface,
  TColor,
  TShadows,
  TTypography,
  TWeight,
  TOverlays,
  TIconSizing,
  TBreakpoints,
} from "../../design-tokens/types"

export const defaultAnimation = {
  timing: {
    default: {
      value: "0.2s",
    },
  },
} as TAnimation

export const defaultBreakpoints = {
  page: {
    margin: {
      value: "105px",
    },
  },
} as TBreakpoints

export const defaultFont = {
  display: {
    value: "Roboto",
  },
  url: {
    value:
      "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap",
  },
  css: {
    value: "'Roboto', sans-serif",
  },
  size: {
    "font-size": {
      value: "16px",
    },
    "line-height": {
      value: "24px",
    },
  },
} as TFont

export const defaultTypography = {
  "base-font": defaultFont,
  "heading-font": defaultFont,
} as TTypography

export const defaultTypeface = {
  "display-l": {
    "font-size": {
      value: "3.1rem",
    },
    "line-height": {
      value: "3.6rem",
    },
  },
  display: {
    "font-size": {
      value: "2.8rem",
    },
    "line-height": {
      value: "3.3rem",
    },
  },
  xxxl: {
    "font-size": {
      value: "2.5rem",
    },
    "line-height": {
      value: "3rem",
    },
  },
  xxl: {
    "font-size": {
      value: "2rem",
    },
    "line-height": {
      value: "2.5rem",
    },
  },
  xl: {
    "font-size": {
      value: "1.7rem",
    },
    "line-height": {
      value: "2.2rem",
    },
  },
  l: {
    "font-size": {
      value: "1.5rem",
    },
    "line-height": {
      value: "2rem",
    },
  },
  m: {
    "font-size": {
      value: "1.1rem",
    },
    "line-height": {
      value: "1.625rem",
    },
  },
  base: {
    "font-size": {
      value: "1rem",
    },
    "line-height": {
      value: "1.375rem",
    },
  },
  s: {
    "font-size": {
      value: "0.875rem",
    },
    "line-height": {
      value: "1.375rem",
    },
  },
  xs: {
    "font-size": {
      value: "0.75rem",
    },
    "line-height": {
      value: "1.375rem",
    },
  },
  "error-message": {
    "font-size": {
      value: "0.875rem",
    },
    "line-height": {
      value: "1.4rem",
    },
    color: {
      value: "#DB1620",
    },
  },
  "hint-message": {
    "font-size": {
      value: "0.875rem",
    },
    "line-height": {
      value: "1.4rem",
    },
    color: {
      value: "#606D72",
    },
  },
} as TTypeface

export const defaultColors = {
  neutral: {
    black: {
      value: "#161616",
    },

    white: {
      value: "#FFFFFF",
    },

    grey: {
      value: "#F9F9F9",
    },
  },

  primary: {
    base: {
      value: "#A9F5DA",
    },

    black: {
      value: "#161616",
    },

    grey: {
      value: "#F9F9F9",
    },

    background: {
      value: "#FFFFFF",
    },
  },

  secondary: {
    a: {
      value: "#323240",
    },

    b: {
      value: "#48475C",
    },

    c: {
      value: "#ADAEB9",
    },

    d: {
      value: "#E8E8E8",
    },

    e: {
      value: "#F5F8FF",
    },

    f: {
      value: "#E5EDFF",
    },

    g: {
      value: "#B8CFB7",
    },

    h: {
      value: "#749BFF",
    },
  },

  actions: {
    link: {
      base: { value: "#48475C" },
      hover: {
        value: "#323240",
      },
    },

    disabled: {
      base: { value: "#E8E8E8" },
    },
  },

  hover: {
    base: { value: "#F5F8FF" },
    secondary: { value: "#DEE7FF" },
  },

  functional: {
    success: {
      base: {
        value: "#03A675",
      },
      hover: {
        value: "#04986C",
      },
    },

    warning: {
      base: {
        value: "#FFD964",
      },
      hover: {
        value: "#F6D261",
      },
    },

    danger: {
      base: {
        value: "#BD2624",
      },
      hover: {
        value: "#B4201E",
      },
    },
  },

  background: {
    navbar: {
      base: {
        value: "#FFFFFF",
      },
      expand: {
        value: "#F9F9F9",
      },
    },
    page: {
      value: "#EFEBE8",
    },
    divider: {
      base: {
        value: "#E0DBD8",
      },
      lighter: {
        value: "#E8E8E8",
      },
    },
    modal: {
      value: "#F6F4F2",
    },
  },

  outlines: {
    card: {
      lighter: {
        value: "#E8E8E8",
      },
      darker: {
        value: "#ADAEB9",
      },
    },
  },
} as TColor

export const defaultShadows = {
  shadows: {
    "0": {
      value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
    },
    "1": {
      value: "2px 2px 0 rgba(0, 0, 0, 0.2)",
    },
    "2": {
      value: "0 2px 5px rgba(22, 22, 22, 0.25)",
    },
  },
  outlines: {
    "0": {
      value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
    },
    "1": {
      value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
    },
  },
} as TShadows

export const defaultOverlays = {
  modal_background: {
    value: "rgba(50, 50, 64, 0.6)",
  },
  masthead_background: {
    value: "rgba(50, 50, 64, 0.5)",
  },
} as TOverlays

export const defaultFontWeights = {
  Bold: {
    value: 700,
  },
  Medium: {
    value: 500,
  },
  Regular: {
    value: 400,
  },
  Light: {
    value: 300,
  },
} as TWeight

export const defaultIconSizing = {
  "icon-xl": {
    value: "64px",
  },
  "icon-l": {
    value: "32px",
  },
  "icon-m": {
    value: "24px",
  },
  "icon-s": {
    value: "20px",
  },
  "icon-xs": {
    value: "16px",
  },
} as TIconSizing

export const defaultRadius = {
  s: {
    value: "5px",
  },
  m: {
    value: "20px",
  },
  l: {
    value: "50px",
  },
}

export const defaultButtons = {
  primary: {
    base: {
      background: {
        value: "#A9F5DA",
      },
      border: {
        value: "none",
      },
      shadow: {
        value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#323240",
      },
    },
    hover: {
      background: {
        value: "#54D9AA",
      },
      border: {
        value: "none",
      },
      shadow: {
        value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#323240",
      },
    },
  },
  secondary: {
    base: {
      background: {
        value: "#48475C",
      },
      border: {
        value: "none",
      },
      shadow: {
        value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#FFFFFF",
      },
    },
    hover: {
      background: {
        value: "#323240",
      },
      border: {
        value: "none",
      },
      shadow: {
        value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#FFFFFF",
      },
    },
  },
  tertiary: {
    base: {
      background: {
        value: "#FFFFFF",
      },
      border: {
        value: "1px solid #48475C",
      },
      shadow: {
        value: "1px 1px 0px rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#323240",
      },
    },
    hover: {
      background: {
        value: "#FFFFFF",
      },
      border: {
        value: "1px solid #48475C",
      },
      shadow: {
        value: "1px 1px 0px rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#323240",
      },
    },
  },
  "danger-remove": {
    base: {
      background: {
        value: "#48475C",
      },
      border: {
        value: "none",
      },
      shadow: {
        value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#FFFFFF",
      },
    },
    hover: {
      background: {
        value: "#323240",
      },
      border: {
        value: "none",
      },
      shadow: {
        value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#FFFFFF",
      },
    },
  },
  "danger-primary": {
    base: {
      background: {
        value: "#48475C",
      },
      border: {
        value: "none",
      },
      shadow: {
        value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#FFFFFF",
      },
    },
    hover: {
      background: {
        value: "#323240",
      },
      border: {
        value: "none",
      },
      shadow: {
        value: "1px 1px 0 rgba(0, 0, 0, 0.1)",
      },
      color: {
        value: "#FFFFFF",
      },
    },
  },
}
