import React, { useState, TextareaHTMLAttributes } from "react"
import { v4 as uuidv4 } from "uuid"
import { InputValidationProps } from "../../../api/api-client/common/InputValidationProps"
import "./TextArea.css"

export interface TextAreaWithValidationInputProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    InputValidationProps {
  /**
   * Toggles whether the component is disabled or not (preventing user interaction).
   */
  isDisabled?: boolean
  /**
   * Optional text footnote to display below the component.
   */
  footnote?: string
  /**
   * Optional text label to display within the component.
   */
  label?: string
  /**
   * Optional HTML `value` attribute to apply to the component.
   */
  value?: string

  /**
   * HTML `name` attribute to apply to the component.
   */
  name: string
  displayError?: boolean
  topNote?: string
}

export const TextAreaWithValidation: React.FC<
  TextAreaWithValidationInputProps
> = ({
  className = "",
  isDisabled = false,
  footnote,
  id = uuidv4(),
  label,
  name,
  onChange,
  placeholder = "",
  value,
  displayError,
  topNote,
}) => {
  const [inputValue, setInputValue] = useState(value || "")

  const hasLabel = !!(label && label.length)

  return (
    <div className={`text-area ${className}`} data-testid="textarea-container">
      {hasLabel && (
        <label className="label" data-testid="textarea-label" htmlFor={id}>
          {label}
        </label>
      )}
      {topNote && (
        <div className="text-area-top-note" data-testid="textarea-top-note">
          {topNote}
        </div>
      )}

      <div className={`input-wrapper ${displayError ? "error" : ""}`}>
        <textarea
          className="input-box"
          data-testid="textarea-input"
          disabled={isDisabled}
          id={id}
          name={name}
          onChange={(e) => {
            setInputValue(e.target.value)
            if (onChange) {
              onChange(e)
            }
          }}
          placeholder={placeholder}
          value={inputValue}
        />
      </div>
      {footnote && <small data-testid="textarea-footnote">{footnote}</small>}
    </div>
  )
}

TextAreaWithValidation.displayName = "TextAreaWithValidation"
