const AccordianDown = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.45455 8C1.45455 4.38505 4.38505 1.45455 8 1.45455C11.615 1.45455 14.5455 4.38505 14.5455 8C14.5455 11.615 11.615 14.5455 8 14.5455C4.38505 14.5455 1.45455 11.615 1.45455 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM4.57629 8.94011C4.29228 9.22413 4.29228 9.68461 4.57629 9.96863C4.86031 10.2526 5.3208 10.2526 5.60481 9.96863L8.08215 7.4913L10.5595 9.96863C10.8435 10.2526 11.304 10.2526 11.588 9.96863C11.872 9.68461 11.872 9.22413 11.588 8.94011L8.73771 6.08982C8.65167 6.00366 8.54948 5.9353 8.43699 5.88865C8.32449 5.84201 8.20391 5.818 8.08215 5.818C7.96038 5.818 7.8398 5.84201 7.7273 5.88865C7.61497 5.93523 7.51255 6.00383 7.42658 6.08982L4.57629 8.94011Z"
      fill="#323240"
    />
  </svg>
)

export default AccordianDown
