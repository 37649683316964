import { OrgAdviserDto } from "legado-generated-api-client"
import { getUserId } from "./getUser";
const contactOrgs = require("../staticData/getOrgContacts.json");

type TObj = { [key: string]: any };

const isOrgAdviserDto = (contactOrgs: any) => {
  if ((contactOrgs as OrgAdviserDto[])?.length) {
    return contactOrgs as OrgAdviserDto[];
  }
};

type TGetContactOrgs = (userEmail?: string) => OrgAdviserDto[];
export const getContactOrgsFromLocalData: TGetContactOrgs = (userEmail) => {
  const currentUserId = getUserId(userEmail);
  const contactORgsObj = contactOrgs as TObj;
  const currentUserContactOrgs = contactORgsObj[currentUserId];

  return isOrgAdviserDto(currentUserContactOrgs) || [];
};
