import { IFile } from "../../../../../api/api-client/api-types"
import { Thumbnail, ThumbnailSize } from "../../../../atoms/Thumbnail/Thumbnail"
import { UploadedDocument } from "../../../../modules/UploadFileToCaseModalWizard/UploadFileToCaseModalWizard"
import "./DocumentDetails.scss"

interface DocumentDetailsProps {
  file: IFile
  formData: UploadedDocument
  base64ThumbnailString: string
  setFileSelectedForPreview: (file: IFile) => void
}

export const DocumentDetails: React.FC<DocumentDetailsProps> = ({
  file,
  formData,
  base64ThumbnailString,
  setFileSelectedForPreview,
}) => {
  const PreviewLink = () => {
    return (
      /* TODO: Ideally we should have a Button that behaves as a link, we have PseudoLink component but that not match UX design for COOP */
      <button title="Preview" onClick={() => setFileSelectedForPreview(file)}>
        Preview
      </button>
    )
  }

  return (
    <div className="document-details">
      <div>
        <div className="document-details__meta-data">
          <Thumbnail
            fileExtension={formData.fileExtension}
            imageString={base64ThumbnailString}
            size={ThumbnailSize.MEDIUM1}
          />
          <div className="document-details__file-name">
            <small>Document name</small>
            <p className="document-details__file-name-text">{formData.name}</p>
            <div className="document-details__wizard-link show-for-mobile">
              <PreviewLink />
            </div>
          </div>
        </div>
      </div>
      <div className="document-details__wizard-link hide-for-mobile">
        <PreviewLink />
      </div>
    </div>
  )
}
