import { FC, useEffect } from "react"
import { IFile } from "../../api/api-client/api-types"
import { CaseDocument } from "../../api/lib/workflow/models/GetCaseDocumentResponse"
import {
  changeFileInFolder,
  changeFileInSharedWithMeFolder,
} from "../../contexts/application/action"
import { UserModuleType } from "../../contexts/application/constants"
import { useApplicationContext } from "../../contexts/application/context"
import { useUserContext } from "../../contexts/users"
import { DocusignFileStatusLozenge } from "../FolderList/components/DocusignFileStatusLozenge"
import {
  CaseDocumentStatusLozenge,
  DocumentStatus,
} from "../atoms/Lozenge/CaseDocumentStatusLozenge/CaseDocumentStatusLozenge"
import { GetFileType } from "../helpers/ResolveFunctions"

import { DocusignSignedStatus } from "../../api/lib/docuSign/models/DocusignSignedStatus"
import "./FileDetails.css"

export const FileDetails: FC<{ file: IFile; caseDocument?: CaseDocument }> = ({
  file,
  caseDocument,
}) => {
  const {
    applicationState: { contactsShares, selectedTheme },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const isSharedFile = file.ownerId !== currentUser?.userId
  const showSharedWith =
    !isSharedFile && currentUser?.modules?.includes(UserModuleType.SHARING)
  let contactsFileIsSharedWith =
    contactsShares?.filter(
      (share) => share.nodeId === file?.id && (share.canEdit || share.canRead)
    ) ?? []
  if (
    currentUser?.modules?.includes(
      UserModuleType.HIDE_ORGANISATION_FROM_SHARING
    )
  ) {
    contactsFileIsSharedWith = contactsFileIsSharedWith.filter((share) => {
      return share.contactType !== "Company"
    })
  }

  useEffect(() => {
    dispatch(
      file.isOwner
        ? changeFileInFolder({
            file: { ...file, beenViewed: true },
            folderId: file.parentId ?? "",
          })
        : changeFileInSharedWithMeFolder({
            file: { ...file, beenViewed: true },
            folderId: file.parentId ?? "",
          })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasVaultUploadForSigning = currentUser?.modules?.includes(
    UserModuleType.VAULT_DOCUMENT_UPLOAD_FOR_SIGNING
  )
  const isDocusignFile = file?.isDocusignFile
  const signatureStatus = file?.signedStatus ?? ""
  return (
    <div className="section-divider">
      {showSharedWith && !isSharedFile && (
        <>
          <p
            data-testid="file-details-shared-label"
            className="file-details-label"
          >
            Shared with
          </p>
          <p className="file-details-text" data-testid="file-details-text">
            {contactsFileIsSharedWith && contactsFileIsSharedWith.length > 0
              ? contactsFileIsSharedWith.map((n) => n.name).join(", ")
              : "None"}
          </p>
        </>
      )}
      {/* Bottom row, date, format */}
      <div className="file-details-bottom-row">
        <div className="file-details-bottom-column">
          <p className="file-details-label">Format</p>
          <p className="file-details-text" data-testid="file-details-text">
            {selectedTheme !== "CO_OP"
              ? GetFileType(file?.extension)
              : file?.extension?.toUpperCase()}
          </p>
          {caseDocument?.requestName && (
            <>
              <p className="file-details-label">Document category</p>
              <p className="file-details-text" data-testid="file-details-text">
                {caseDocument?.requestName}
              </p>
            </>
          )}
          {caseDocument?.displayStatus &&
            caseDocument?.displayStatus !== DocumentStatus.None && (
              <>
                <p className="file-details-label">Status</p>
                <CaseDocumentStatusLozenge
                  status={caseDocument?.displayStatus}
                />
              </>
            )}
          {file?.signedStatus === DocusignSignedStatus.REJECTED &&
            file?.docusignActionComment && (
              <>
                <p className="file-details-label">Reason for rejection</p>
                <p className="file-details-text">
                  {file?.docusignActionComment}
                </p>
              </>
            )}
          {hasVaultUploadForSigning && isDocusignFile && (
            <>
              <p className="file-details-label">Status</p>
              <p>
                <DocusignFileStatusLozenge label={signatureStatus} />
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
