import { ReactNode } from "react"
import { IFile } from "../../api/api-client/api-types"

export type TMessageTypes =
  | "SUCCESS"
  | "ERROR"
  | "WARNING"
  | "NOTIFICATION"
  | "PROGRESS"
  | "INFO"
  | "CSVERROR"

export interface IMessageBase {
  title?: string
  messageType?: TMessageTypes
  message?: string | ReactNode
  fileToReview?: IFile | null
}

export const SUCCESS_NOTIICATION_DISAPPEARS_AFTER_MS = 9000

export type TMessage = string | ReactNode

export interface TDisplaySuccessOrErrorPopup extends IMessageBase {
  messages?: IMessageBase[]
}
