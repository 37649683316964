const QuestionMarkCircleTransparent = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8253_22343)">
      <path
        d="M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87706C0.0069325 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0ZM16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91974 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05844 6.10051 6.1005C8.05845 4.14257 10.553 2.8092 13.2687 2.26901C15.9845 1.72881 18.7994 2.00606 21.3576 3.06569C23.9157 4.12531 26.1022 5.91973 27.6406 8.22202C29.1789 10.5243 30 13.2311 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30Z"
        fill="#739BFC"
      />
      <path
        d="M15.8896 20.32C15.6785 20.3166 15.4688 20.3556 15.273 20.4346C15.0772 20.5136 14.8992 20.631 14.7496 20.78C14.6 20.9213 14.4811 21.0919 14.4002 21.2811C14.3193 21.4704 14.2783 21.6742 14.2796 21.88C14.2752 22.0874 14.3197 22.293 14.4096 22.48C14.4947 22.6608 14.6134 22.8237 14.7596 22.96C14.9087 23.0994 15.0814 23.2111 15.2696 23.29C15.4665 23.37 15.6771 23.4108 15.8896 23.41C16.0966 23.4098 16.3015 23.368 16.4922 23.2873C16.6828 23.2066 16.8553 23.0885 16.9996 22.94C17.1502 22.7994 17.2698 22.629 17.3507 22.4396C17.4316 22.2502 17.4721 22.046 17.4696 21.84C17.4744 21.6366 17.4349 21.4347 17.3537 21.2482C17.2725 21.0617 17.1517 20.8951 16.9996 20.76C16.7008 20.4747 16.3027 20.3169 15.8896 20.32Z"
        fill="#739BFC"
      />
      <path
        d="M19.1102 9.71C18.7 9.39285 18.2359 9.15233 17.7402 9C17.2139 8.82966 16.6635 8.74524 16.1102 8.75C15.5238 8.73901 14.9395 8.82345 14.3802 9C13.8775 9.17591 13.4102 9.44004 13.0002 9.78C12.5883 10.1389 12.2488 10.5734 12.0002 11.06C11.7291 11.5948 11.5563 12.174 11.4902 12.77L13.9002 13C13.9149 12.7335 13.9688 12.4707 14.0602 12.22C14.1493 11.9708 14.2889 11.7427 14.4702 11.55C14.6525 11.3575 14.8697 11.2014 15.1102 11.09C15.3737 10.9704 15.661 10.9123 15.9502 10.92C16.2064 10.9029 16.4633 10.9413 16.7033 11.0328C16.9432 11.1243 17.1605 11.2666 17.3402 11.45C17.6696 11.8052 17.8457 12.2759 17.8302 12.76C17.8356 13.112 17.7421 13.4585 17.5602 13.76C17.377 14.0401 17.1585 14.2955 16.9102 14.52L16.0102 15.38C15.7811 15.5882 15.5703 15.8157 15.3802 16.06C15.0558 16.4625 14.8391 16.9408 14.7502 17.45C14.7042 17.7344 14.6808 18.0219 14.6802 18.31V19.07H17.0502V18.55C17.0465 18.302 17.0666 18.0542 17.1102 17.81C17.1485 17.6129 17.2195 17.4237 17.3202 17.25C17.4318 17.0609 17.5661 16.8863 17.7202 16.73C17.8902 16.55 18.1102 16.35 18.3702 16.12C18.6302 15.89 18.9602 15.6 19.2102 15.35C19.4453 15.1149 19.6564 14.857 19.8402 14.58C20.0154 14.3076 20.1501 14.0111 20.2402 13.7C20.338 13.3314 20.3851 12.9513 20.3802 12.57C20.3781 12.0018 20.2482 11.4413 20.0002 10.93C19.7921 10.4656 19.4889 10.05 19.1102 9.71Z"
        fill="#739BFC"
      />
    </g>
    <defs>
      <clipPath id="clip0_8253_22343">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default QuestionMarkCircleTransparent
