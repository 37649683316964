import { getUserId } from "./getUser"
import { DistributionProjectInfoDto } from "legado-generated-api-client"
const projectList = require("../staticData/getProject.json");

type TGetProjects = (userEmail?: string) => DistributionProjectInfoDto[];


export const getProjectFromLocalData: TGetProjects = (userEmail) => {
  const currentUserId = userEmail ? getUserId(userEmail)  : "8cc9407f-50aa-4f2b-ba84-f5807f9c57b3"
  return projectList[currentUserId]
};
