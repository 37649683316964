"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthApi = exports.AuthApiFactory = exports.AuthApiFp = exports.AuthApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * AuthApi - axios parameter creator
 * @export
 */
const AuthApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary authentication method for user login with password and 2FAs
         * @param {AuthLoginBody} [body] Set in the body of the request. Must contain username, password and 2FA code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Generates a new token for the user. This is used when the current token's Time to Live as run out. The calling system can request a refesh of the token.
         * @summary Refresh Token
         * @param {string} [code] A new one time code
         * @param {boolean} [isPrimaryUser] Only used for FNZ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRefreshPost: (code, isPrimaryUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (code !== undefined) {
                localVarQueryParameter["code"] = code;
            }
            if (isPrimaryUser !== undefined) {
                localVarQueryParameter["isPrimaryUser"] = isPrimaryUser;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get partial user details from activation code
         * @summary Get Partial User Details From Activation Code
         * @param {string} [actCode] The Activation Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRegistrationGet: (actCode, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (actCode !== undefined) {
                localVarQueryParameter["actCode"] = actCode;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Reset a password for a user
         * @summary Reset Password
         * @param {AuthResetpasswordBody} [body] The Authentication Code and New Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetpasswordPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/resetpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send email to user with reset password link
         * @param {AuthSendresetlinkBody} [body] Info required to reset password for user (email etc.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSendresetlinkPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/sendresetlink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Sets Password to User Account
         * @summary Set Password
         * @param {AuthSetpasswordBody} [body] The JSOn for Activation Code(ActivationCode), 2FA Code(TwoFactorCode), New Password(NewPassword) and any additional assistance the user might need(AdditionalAssistanceUserStatus)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSetpasswordPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/setpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Obtains a token from the SSO Provider using the Code provided
         * @summary Get Token from Code
         * @param {string} [code] The single use code
         * @param {boolean} [isPrimaryUser] Only required for FNZ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenPost: (code, isPrimaryUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (code !== undefined) {
                localVarQueryParameter["code"] = code;
            }
            if (isPrimaryUser !== undefined) {
                localVarQueryParameter["isPrimaryUser"] = isPrimaryUser;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * This method is not available in production. It allows for a developer to gain a bearer token directly from the Legado System using just the users email address and without the need for a full SSO Authority to be used. This is helpful for the initial trialing and development of the integration.
         * @summary Get Token from Email
         * @param {string} [email] Email of the user to generate the bearer token for
         * @param {number} [ttl] Time to live in minutes - How long the token is valid for
         * @param {boolean} [skipLogActivity] Set to true if you do not want to record this login activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenbyemailGet: (email, ttl, skipLogActivity, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/tokenbyemail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (email !== undefined) {
                localVarQueryParameter["email"] = email;
            }
            if (ttl !== undefined) {
                localVarQueryParameter["ttl"] = ttl;
            }
            if (skipLogActivity !== undefined) {
                localVarQueryParameter["skipLogActivity"] = skipLogActivity;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get Token from Microsoft's access token
         * @param {string} [body] Microsoft&#x27;s access token to generate the bearer token for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenbyssoPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/tokenbysso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Checks if validation passes for all the required validation rules provided.
         * @summary Password validation
         * @param {string} [body]
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthValidatepasswordPost: (body, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Auth/validatepassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (userId !== undefined) {
                localVarQueryParameter["userId"] = userId;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AuthApiAxiosParamCreator = AuthApiAxiosParamCreator;
/**
 * AuthApi - functional programming interface
 * @export
 */
const AuthApiFp = function (configuration) {
    return {
        /**
         *
         * @summary authentication method for user login with password and 2FAs
         * @param {AuthLoginBody} [body] Set in the body of the request. Must contain username, password and 2FA code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthLoginPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Generates a new token for the user. This is used when the current token's Time to Live as run out. The calling system can request a refesh of the token.
         * @summary Refresh Token
         * @param {string} [code] A new one time code
         * @param {boolean} [isPrimaryUser] Only used for FNZ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRefreshPost(code, isPrimaryUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthRefreshPost(code, isPrimaryUser, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get partial user details from activation code
         * @summary Get Partial User Details From Activation Code
         * @param {string} [actCode] The Activation Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRegistrationGet(actCode, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthRegistrationGet(actCode, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Reset a password for a user
         * @summary Reset Password
         * @param {AuthResetpasswordBody} [body] The Authentication Code and New Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetpasswordPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthResetpasswordPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Send email to user with reset password link
         * @param {AuthSendresetlinkBody} [body] Info required to reset password for user (email etc.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSendresetlinkPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthSendresetlinkPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Sets Password to User Account
         * @summary Set Password
         * @param {AuthSetpasswordBody} [body] The JSOn for Activation Code(ActivationCode), 2FA Code(TwoFactorCode), New Password(NewPassword) and any additional assistance the user might need(AdditionalAssistanceUserStatus)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSetpasswordPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthSetpasswordPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Obtains a token from the SSO Provider using the Code provided
         * @summary Get Token from Code
         * @param {string} [code] The single use code
         * @param {boolean} [isPrimaryUser] Only required for FNZ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenPost(code, isPrimaryUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthTokenPost(code, isPrimaryUser, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * This method is not available in production. It allows for a developer to gain a bearer token directly from the Legado System using just the users email address and without the need for a full SSO Authority to be used. This is helpful for the initial trialing and development of the integration.
         * @summary Get Token from Email
         * @param {string} [email] Email of the user to generate the bearer token for
         * @param {number} [ttl] Time to live in minutes - How long the token is valid for
         * @param {boolean} [skipLogActivity] Set to true if you do not want to record this login activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenbyemailGet(email, ttl, skipLogActivity, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthTokenbyemailGet(email, ttl, skipLogActivity, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Get Token from Microsoft's access token
         * @param {string} [body] Microsoft&#x27;s access token to generate the bearer token for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenbyssoPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthTokenbyssoPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Checks if validation passes for all the required validation rules provided.
         * @summary Password validation
         * @param {string} [body]
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthValidatepasswordPost(body, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.AuthApiAxiosParamCreator)(configuration).apiAuthValidatepasswordPost(body, userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.AuthApiFp = AuthApiFp;
/**
 * AuthApi - factory interface
 * @export
 */
const AuthApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary authentication method for user login with password and 2FAs
         * @param {AuthLoginBody} [body] Set in the body of the request. Must contain username, password and 2FA code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthLoginPost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Generates a new token for the user. This is used when the current token's Time to Live as run out. The calling system can request a refesh of the token.
         * @summary Refresh Token
         * @param {string} [code] A new one time code
         * @param {boolean} [isPrimaryUser] Only used for FNZ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRefreshPost(code, isPrimaryUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthRefreshPost(code, isPrimaryUser, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Get partial user details from activation code
         * @summary Get Partial User Details From Activation Code
         * @param {string} [actCode] The Activation Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRegistrationGet(actCode, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthRegistrationGet(actCode, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Reset a password for a user
         * @summary Reset Password
         * @param {AuthResetpasswordBody} [body] The Authentication Code and New Password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthResetpasswordPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthResetpasswordPost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Send email to user with reset password link
         * @param {AuthSendresetlinkBody} [body] Info required to reset password for user (email etc.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSendresetlinkPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthSendresetlinkPost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Sets Password to User Account
         * @summary Set Password
         * @param {AuthSetpasswordBody} [body] The JSOn for Activation Code(ActivationCode), 2FA Code(TwoFactorCode), New Password(NewPassword) and any additional assistance the user might need(AdditionalAssistanceUserStatus)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthSetpasswordPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthSetpasswordPost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Obtains a token from the SSO Provider using the Code provided
         * @summary Get Token from Code
         * @param {string} [code] The single use code
         * @param {boolean} [isPrimaryUser] Only required for FNZ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenPost(code, isPrimaryUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthTokenPost(code, isPrimaryUser, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * This method is not available in production. It allows for a developer to gain a bearer token directly from the Legado System using just the users email address and without the need for a full SSO Authority to be used. This is helpful for the initial trialing and development of the integration.
         * @summary Get Token from Email
         * @param {string} [email] Email of the user to generate the bearer token for
         * @param {number} [ttl] Time to live in minutes - How long the token is valid for
         * @param {boolean} [skipLogActivity] Set to true if you do not want to record this login activity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenbyemailGet(email, ttl, skipLogActivity, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthTokenbyemailGet(email, ttl, skipLogActivity, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         *
         * @summary Get Token from Microsoft's access token
         * @param {string} [body] Microsoft&#x27;s access token to generate the bearer token for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthTokenbyssoPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthTokenbyssoPost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Checks if validation passes for all the required validation rules provided.
         * @summary Password validation
         * @param {string} [body]
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthValidatepasswordPost(body, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.AuthApiFp)(configuration)
                    .apiAuthValidatepasswordPost(body, userId, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.AuthApiFactory = AuthApiFactory;
/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
class AuthApi extends base_1.BaseAPI {
    /**
     *
     * @summary authentication method for user login with password and 2FAs
     * @param {AuthLoginBody} [body] Set in the body of the request. Must contain username, password and 2FA code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthLoginPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthLoginPost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Generates a new token for the user. This is used when the current token's Time to Live as run out. The calling system can request a refesh of the token.
     * @summary Refresh Token
     * @param {string} [code] A new one time code
     * @param {boolean} [isPrimaryUser] Only used for FNZ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthRefreshPost(code, isPrimaryUser, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthRefreshPost(code, isPrimaryUser, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get partial user details from activation code
     * @summary Get Partial User Details From Activation Code
     * @param {string} [actCode] The Activation Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthRegistrationGet(actCode, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthRegistrationGet(actCode, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Reset a password for a user
     * @summary Reset Password
     * @param {AuthResetpasswordBody} [body] The Authentication Code and New Password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthResetpasswordPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthResetpasswordPost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Send email to user with reset password link
     * @param {AuthSendresetlinkBody} [body] Info required to reset password for user (email etc.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthSendresetlinkPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthSendresetlinkPost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Sets Password to User Account
     * @summary Set Password
     * @param {AuthSetpasswordBody} [body] The JSOn for Activation Code(ActivationCode), 2FA Code(TwoFactorCode), New Password(NewPassword) and any additional assistance the user might need(AdditionalAssistanceUserStatus)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthSetpasswordPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthSetpasswordPost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Obtains a token from the SSO Provider using the Code provided
     * @summary Get Token from Code
     * @param {string} [code] The single use code
     * @param {boolean} [isPrimaryUser] Only required for FNZ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthTokenPost(code, isPrimaryUser, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthTokenPost(code, isPrimaryUser, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * This method is not available in production. It allows for a developer to gain a bearer token directly from the Legado System using just the users email address and without the need for a full SSO Authority to be used. This is helpful for the initial trialing and development of the integration.
     * @summary Get Token from Email
     * @param {string} [email] Email of the user to generate the bearer token for
     * @param {number} [ttl] Time to live in minutes - How long the token is valid for
     * @param {boolean} [skipLogActivity] Set to true if you do not want to record this login activity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthTokenbyemailGet(email, ttl, skipLogActivity, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthTokenbyemailGet(email, ttl, skipLogActivity, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     *
     * @summary Get Token from Microsoft's access token
     * @param {string} [body] Microsoft&#x27;s access token to generate the bearer token for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthTokenbyssoPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthTokenbyssoPost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Checks if validation passes for all the required validation rules provided.
     * @summary Password validation
     * @param {string} [body]
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    apiAuthValidatepasswordPost(body, userId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.AuthApiFp)(this.configuration)
                .apiAuthValidatepasswordPost(body, userId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.AuthApi = AuthApi;
