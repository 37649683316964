import React from "react";
import { NavMenuNavigation, NAVMENU_KEYS } from "./constants";

export function manipulateNavigationData(
  navigationData:NavMenuNavigation,
  setNavigationData:React.Dispatch<React.SetStateAction<NavMenuNavigation>>,
  level:number,
  focusId?: number,
  maxFocusId?: number,
  isDisabled?: boolean,
  focusDirection?: number,
  setTopParentIsOpen?: ((menuItemIsOpen: string) => void) | undefined,
){
  let closeTopParent = false
  let currentNavigationData = JSON.parse(JSON.stringify(navigationData))
  currentNavigationData.firstLevel.IsSelected=false
  currentNavigationData.secondLevel.IsSelected=false
  currentNavigationData.thirdLevel.IsSelected=false
  switch(level){
    case 0:{
      currentNavigationData.firstLevel.IsSelected=true
      let levelFocusId = focusId
                                ? focusId < 1
                                  ? 1
                                  : focusId
                                : currentNavigationData.firstLevel.FocusId
      currentNavigationData.firstLevel.FocusId=levelFocusId
      currentNavigationData.firstLevel.MaxFocusId=maxFocusId??currentNavigationData.firstLevel.MaxFocusId
      break
    }
    case 1:{
      currentNavigationData.secondLevel.IsSelected=true
      let levelFocusId = focusId !== undefined
                                ? focusId > currentNavigationData.secondLevel.MaxFocusId
                                  ? 0
                                  : focusId
                                : currentNavigationData.secondLevel.FocusId
      if(isDisabled && focusDirection){
        let newLevelFocusId = levelFocusId + focusDirection
        if(newLevelFocusId<0){
          levelFocusId = 0
          closeTopParent = true
        }
        else if(newLevelFocusId>currentNavigationData.secondLevel.MaxFocusId){
          levelFocusId = 0
        }
        else{
          levelFocusId = newLevelFocusId
        }
      }
      currentNavigationData.secondLevel.FocusId=levelFocusId
      currentNavigationData.secondLevel.MaxFocusId=maxFocusId??currentNavigationData.secondLevel.MaxFocusId
      break
    }
    case 2:{
      currentNavigationData.thirdLevel.IsSelected=true
      currentNavigationData.thirdLevel.FocusId=focusId??currentNavigationData.thirdLevel.FocusId
      currentNavigationData.thirdLevel.MaxFocusId=maxFocusId??currentNavigationData.thirdLevel.MaxFocusId
      break
    }
  }

  setNavigationData(currentNavigationData)
  if(closeTopParent && setTopParentIsOpen){
    setTopParentIsOpen('')
    manipulateNavigationData(navigationData,setNavigationData,0)
  }
}

export function keyPressed(
  event:React.KeyboardEvent<HTMLDivElement> | React.KeyboardEvent<HTMLButtonElement>,
  folderId:string | undefined,
  navigationData?: NavMenuNavigation,
  setNavigationData?: React.Dispatch<React.SetStateAction<NavMenuNavigation>>,
  onClickFn?:(folderId: string) => void | undefined,
  setExpanded?: ((expanded: string) => void) | undefined,
  setTopParentIsOpen?: ((menuItemIsOpen: string) => void) | undefined,
  expandOnClick?: (expanded: boolean)=>void,
  isExpanded?: boolean,
){
  if(!setNavigationData) return
  switch(event.key){
    case NAVMENU_KEYS.SPACE:
    case NAVMENU_KEYS.ENTER:{
      event.preventDefault()
      if(expandOnClick && isExpanded!==undefined && navigationData){
        expandOnClick(!isExpanded)
        manipulateNavigationData(navigationData,setNavigationData,1,0)
      }
      else if(setExpanded && folderId){
        setExpanded(folderId)
      }
      if(onClickFn && folderId){
        onClickFn(folderId)
      }
      if(navigationData && navigationData.secondLevel.FocusId<=navigationData.secondLevel.MaxFocusId && setExpanded && folderId){
        setExpanded(folderId)
        manipulateNavigationData(navigationData,setNavigationData,2,0)
      }
      break
    }
    case NAVMENU_KEYS.TAB:{
      if((navigationData?.firstLevel.IsSelected && navigationData.firstLevel.FocusId<navigationData.firstLevel.MaxFocusId)
      ||(navigationData?.secondLevel.IsSelected && navigationData.secondLevel.FocusId<navigationData.firstLevel.MaxFocusId)){
        event.preventDefault()
      }
      let closeTopParent = false
      if(navigationData?.firstLevel.IsSelected && navigationData.firstLevel.FocusId<navigationData.firstLevel.MaxFocusId){
        manipulateNavigationData(navigationData,setNavigationData,0,navigationData.firstLevel.FocusId+1)
      }
      else if(navigationData?.secondLevel.IsSelected){
        if(navigationData.secondLevel.FocusId<navigationData.secondLevel.MaxFocusId){
          manipulateNavigationData(navigationData,setNavigationData,1,navigationData.secondLevel.FocusId+1)
        }
        else{
          manipulateNavigationData(navigationData,setNavigationData,0,navigationData.firstLevel.FocusId+1)
          closeTopParent = true
        }
      }
      else if(navigationData?.thirdLevel.IsSelected){
        if(navigationData.thirdLevel.FocusId<navigationData.thirdLevel.MaxFocusId){
          manipulateNavigationData(navigationData,setNavigationData,2,navigationData.thirdLevel.FocusId+1)
        }
        else{
          if(navigationData.secondLevel.FocusId>=navigationData.secondLevel.MaxFocusId){
            closeTopParent = true
          }
          else{
            manipulateNavigationData(navigationData,setNavigationData,1,navigationData.secondLevel.FocusId+1)
            if(setExpanded){
              setExpanded('')
            }
          }
        }
      }
      if(setTopParentIsOpen && closeTopParent){
        setTopParentIsOpen('')
        if(setExpanded){
          setExpanded('')
        }
      }
      break
    }
    case NAVMENU_KEYS.ARROW_DOWN:{
      event.preventDefault()
      if(navigationData?.secondLevel.IsSelected){
        if (navigationData.secondLevel.FocusId<navigationData.secondLevel.MaxFocusId){
          manipulateNavigationData(navigationData,setNavigationData,1,navigationData.secondLevel.FocusId+1)
        }
        else{
          manipulateNavigationData(navigationData,setNavigationData,1,0)
        }
      }
      else if(navigationData?.thirdLevel.IsSelected){
        if(navigationData.thirdLevel.FocusId<navigationData.thirdLevel.MaxFocusId){
          manipulateNavigationData(navigationData,setNavigationData,2,navigationData.thirdLevel.FocusId+1)
        }
        else{
          manipulateNavigationData(navigationData,setNavigationData,2,0)
        }
      }
      break
    }
    case NAVMENU_KEYS.ARROW_UP:{
      if(navigationData?.secondLevel.IsSelected && navigationData.secondLevel.FocusId>0){
        manipulateNavigationData(navigationData,setNavigationData,1,navigationData.secondLevel.FocusId-1)
      }
      else if(navigationData?.secondLevel.IsSelected && navigationData.secondLevel.FocusId<=0){
        let triggerDataManipulation = false
        if(setTopParentIsOpen){
          setTopParentIsOpen('')
          triggerDataManipulation = true
        }
        if(expandOnClick){
          expandOnClick(false)
          triggerDataManipulation = true
        }
        if(triggerDataManipulation){
          manipulateNavigationData(navigationData,setNavigationData,0)
        }
      }
      else if(navigationData?.thirdLevel.IsSelected){
        if(navigationData.thirdLevel.FocusId>0){
          manipulateNavigationData(navigationData,setNavigationData,2,navigationData.thirdLevel.FocusId-1)
        }
        else{
          manipulateNavigationData(navigationData,setNavigationData,1,navigationData.secondLevel.FocusId)
          if(setExpanded){
            setExpanded('')
          }
        }
      }
      break
    }
    case NAVMENU_KEYS.ARROW_LEFT:{
      if(navigationData?.firstLevel.IsSelected && navigationData.firstLevel.FocusId>0){
        manipulateNavigationData(navigationData,setNavigationData,0,navigationData.firstLevel.FocusId-1)
      }
      break
    }
    case NAVMENU_KEYS.ARROW_RIGHT:{
      if(navigationData?.firstLevel.IsSelected && navigationData.firstLevel.FocusId<navigationData.firstLevel.MaxFocusId){
        manipulateNavigationData(navigationData,setNavigationData,0,navigationData.firstLevel.FocusId+1)
      }
      break
    }
    case NAVMENU_KEYS.ESCAPE:{
      if(navigationData?.secondLevel.IsSelected && setTopParentIsOpen){
        setTopParentIsOpen('')
        manipulateNavigationData(navigationData,setNavigationData,0)
      }
      else if(navigationData?.thirdLevel.IsSelected && setExpanded){
        setExpanded('')
        manipulateNavigationData(navigationData,setNavigationData,1)
      }
    }
  }
}

export function isSelected(level:number, focusId:number, navigationData: NavMenuNavigation){
  switch(level){
    case 0:{
      return navigationData.firstLevel.IsSelected && navigationData.firstLevel.FocusId===focusId
    }
    case 1:{
      return navigationData.secondLevel.IsSelected && navigationData.secondLevel.FocusId===focusId
    }
    case 2:{
      return navigationData.thirdLevel.IsSelected && navigationData.thirdLevel.FocusId===focusId
    }
  }
  return false
}
