import { SET_CURRENT_USER } from "./actions"
import { IUser } from "../../api/api-client/api-types"
import { IAction } from "../constants"

export interface IUserState {
  currentUser?: IUser
}

export interface IUserAction extends IAction {
  currentUser?: IUser
}

export const initialState = {
  currentUser: undefined,
}

export const userReducer = (userState: IUserState, action: IUserAction) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...userState,
        currentUser: action.currentUser,
      }
    default:
      return userState
  }
}
