const IconChevronUp = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 22L15.4347 10.2411C15.5089 10.1646 15.597 10.104 15.694 10.0627C15.791 10.0213 15.895 10 16 10C16.105 10 16.209 10.0213 16.306 10.0627C16.403 10.104 16.4911 10.1646 16.5653 10.2411L28 22"
      stroke="var(--color-universal-secondary-b)"
    />
  </svg>
)
export default IconChevronUp
