import { createContext, FC, useReducer, Dispatch, useContext } from "react"
import {
  thumbnailReducer,
  initialState,
  IThumbnailState,
  IThumbnailAction,
} from "./reducer"

export const ThumbnailContext = createContext(
  {} as {
    thumbnailState: IThumbnailState
    dispatch: Dispatch<IThumbnailAction>
  }
)

export const ThumbnailProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [thumbnailState, dispatch] = useReducer(thumbnailReducer, initialState)

  const value = { thumbnailState, dispatch }
  return (
    <ThumbnailContext.Provider value={value}>
      {children}
    </ThumbnailContext.Provider>
  )
}

export const useThumbnailContext = () => useContext(ThumbnailContext)

export * from "./actions"
