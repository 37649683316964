"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimePeriod = void 0;
/**
 *   0 = Daily  1 = Weekly  2 = Monthly  3 = Yearly
 * @export
 * @enum {string}
 */
var TimePeriod;
(function (TimePeriod) {
    TimePeriod[TimePeriod["NUMBER_0"] = 0] = "NUMBER_0";
    TimePeriod[TimePeriod["NUMBER_1"] = 1] = "NUMBER_1";
    TimePeriod[TimePeriod["NUMBER_2"] = 2] = "NUMBER_2";
    TimePeriod[TimePeriod["NUMBER_3"] = 3] = "NUMBER_3";
})(TimePeriod || (exports.TimePeriod = TimePeriod = {}));
