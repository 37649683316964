import { FC } from "react"
import "./LogoWithTextItems.css"
import { CoopWelcomeText } from "./coopWelcomeText"

export interface LogoWithTextItemsProps {
  logo: JSX.Element
  headerText: string
  contentText: string[]
}

export const LogoWithTextItems: FC<LogoWithTextItemsProps> = ({
  logo,
  headerText,
  contentText,
}) => {
  return (
    <div className="logo-with-text">
      <div className="logo-container">{logo}</div>
      <h1>{headerText}</h1>
      <div className="content-text">
        {contentText.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
      </div>
    </div>
  )
}

interface WelcomeTextComponentProps {
  className: string
}

export const WelcomeTextComponent: React.FC<WelcomeTextComponentProps> = ({
  className,
}) => {
  return (
    <div className={className}>
      <h1>Welcome</h1>
      {CoopWelcomeText.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
    </div>
  )
}
