import { IFolder } from "../api-types"

export const replaceFolder = ({
  folders,
  newFolder,
}: {
  folders: IFolder[]
  newFolder: IFolder
}): IFolder[] => {
  if (folders.find((f) => f.id === newFolder.id)) {
    return folders.map((f) => (f.id === newFolder.id ? newFolder : f))
  }
  return folders.map((f) => {
    if (f.childFolders && f.childFolders.length > 0) {
      return {
        ...f,
        childFolders: replaceFolder({ folders: f.childFolders, newFolder }),
      }
    }
    return f
  })
}

export const updateFolder = ({
  folders,
  newFolder,
}: {
  folders: IFolder[]
  newFolder: IFolder
}): IFolder[] => {
  var oldFolder = folders.find((f) => f.id === newFolder.id)
  if (oldFolder) {
    if (oldFolder.childFolders)
      newFolder.childFolders = [...oldFolder.childFolders]
    if (oldFolder.contents) newFolder.contents = [...oldFolder.contents]

    return folders.map((f) => (f.id === newFolder.id ? newFolder : f))
  }
  return folders.map((f) => {
    if (f.childFolders && f.childFolders.length > 0) {
      return {
        ...f,
        childFolders: updateFolder({ folders: f.childFolders, newFolder }),
      }
    }
    return f
  })
}
