export const Logo = () => (
  <svg
    width="207"
    height="41"
    viewBox="0 0 207 41"
    fillRule="evenodd"
    clipRule="evenodd"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#D3292E"
      stroke="none"
      d="M193.103 0.0756989C181.473 6.76048 169.089 11.7072 155.259 11.1056C145.583 10.6243 136.642 8.40494 127.309 6.78276C121.153 5.70429 114.997 7.20168 110.205 11.4042L107.81 13.5656H107.667C114.265 6.1232 123.058 3.23983 132.391 4.14005L137.521 4.79961L150.374 7.02787C161.366 8.81048 173.974 8.46733 184.141 4.08657C187.197 2.91236 190.177 1.549 193.063 0.00439453L193.103 0.0756989Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M206.42 15.1835L205.929 17.3449C205.537 17.2869 205.247 16.8056 204.854 16.6853C203.779 16.1461 202.36 15.7272 201.138 16.2664C200.897 16.4481 200.816 16.7725 200.942 17.0463L201.388 17.4652C203.391 18.8022 207.103 18.8022 206.96 22.6259C206.714 24.1856 205.443 24.9655 204.372 25.4468C201.829 26.1064 198.849 25.6875 196.311 25.2062C196.164 24.8452 196.36 24.4263 196.36 24.0074L196.458 22.5679C197.877 23.9494 199.978 24.7962 201.736 23.7088C202.004 23.4185 202.097 23.0078 201.981 22.6303L201.49 22.149C199.635 21.1285 196.944 20.9502 196.164 18.1872C195.967 17.1666 196.36 16.5071 196.846 15.8475C198.898 14.2253 201.49 14.5863 203.931 14.769L206.42 15.1835Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M195.133 15.0048L191.564 25.5088H186.144L186.34 24.7869C185.849 23.285 185.51 21.547 184.922 20.1031C184.141 21.8456 183.552 23.704 182.918 25.5044L177.565 25.5623C177.745 25.2092 177.78 24.8 177.663 24.4215L175.218 16.4978L174.326 15.0583C176.231 15.0583 178.038 14.938 179.849 15.0583L182.049 22.6344C182.878 21.2529 183.173 19.3945 183.806 17.8926C184.028 17.2405 183.975 16.5262 183.659 15.914L183.075 15.0226H188.401C189.231 17.545 189.74 20.1833 190.699 22.5987L190.895 22.3001L192.506 17.496C192.678 16.6225 192.445 15.718 191.872 15.036C192.956 14.9365 194.047 14.926 195.133 15.0048Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M88.8048 15.6645C88.6085 16.4443 88.4122 17.2242 88.2695 18.0665C86.882 17.0371 85.5348 16.324 83.8707 16.5647C83.5965 16.5925 83.366 16.7824 83.2863 17.046C83.09 17.5273 83.5807 17.8259 83.8261 18.0086C85.682 19.2074 88.3721 19.1495 89.3491 22.0195C89.6435 23.1603 89.2019 23.9982 88.5684 24.8404C85.7846 27.1801 81.774 26.222 78.553 25.6783C78.553 24.7201 78.6511 23.9358 78.7002 22.9776C80.1188 24.3591 82.171 25.0766 83.9287 24.3146C84.2722 24.0739 84.5087 23.499 84.2722 23.1737C83.0989 21.9749 81.4884 21.8367 80.1679 21.0123C79.2399 20.4793 78.6095 19.5487 78.4593 18.4899C78.3946 17.3966 78.9405 16.3572 79.8779 15.7892C82.5948 14.586 85.9229 15.2455 88.8048 15.6645Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M10.6712 15.7269C10.5307 16.205 10.4145 16.6899 10.3232 17.1797L10.1894 18.071C8.85105 16.9302 7.06656 16.2884 5.40253 16.8098C5.12879 17.0197 5.04594 17.3959 5.20624 17.7012C5.80404 18.686 9.60499 19.0381 11.0683 21.4847C11.3314 22.1517 11.3995 22.8796 11.2646 23.5838C10.2385 26.4047 7.59745 26.2265 5.40253 26.3468C3.74708 26.3127 2.10082 26.0914 0.495205 25.6872C0.640627 24.8546 0.706355 24.01 0.691498 23.1648C2.02986 24.2433 3.57344 25.0856 5.33561 24.6043C5.7958 24.5 6.16702 24.1614 6.31262 23.713C6.32743 23.3044 6.09776 22.9261 5.7282 22.7504C3.82327 21.5516 0.205227 21.4892 0.450593 17.768C0.990399 15.4863 3.12732 15.3659 4.80027 15.1877C6.75428 15.0673 8.76182 15.5442 10.6712 15.7269Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M24.0593 15.7851L23.5686 18.0668C22.3953 16.9259 20.8919 16.3243 19.3661 16.8056C18.5363 17.1666 17.9475 18.2451 17.8984 19.328C17.8493 20.9502 17.6039 22.8709 18.7906 23.9494C19.9149 25.1482 21.967 24.9076 23.1894 24.0697L24.1664 23.2275C24.2155 23.9494 24.2155 24.7293 24.2155 25.3889C21.0391 26.6501 16.3503 27.1715 13.7584 23.887C12.8481 22.4842 12.5651 20.7655 12.9777 19.1453C13.8699 16.2619 16.3994 15.3038 18.6969 15.1834C20.584 15.0052 22.2525 15.5444 24.0593 15.7851Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M68.5732 15.2456C68.426 16.2661 67.9888 17.1084 67.8906 18.129C66.9185 17.3802 65.7434 16.9408 64.518 16.8678L64.3707 16.9881L64.4198 25.0901C64.5025 25.3828 64.6177 25.6653 64.7633 25.9323H58.9504L59.2939 24.9118C59.343 22.0908 59.4411 19.7511 59.343 16.9881C57.8254 16.9288 56.3307 17.3722 55.0914 18.2493C55.3883 17.3024 55.6326 16.3398 55.8231 15.3659C60.1192 15.4239 64.2235 15.7269 68.5732 15.2456Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M53.1508 15.4864L54.5695 15.3081C54.2825 16.2298 54.0706 17.1732 53.936 18.1291C52.8609 17.4071 51.7054 16.8679 50.367 16.9882C50.2689 19.7513 50.318 22.4519 50.318 24.9119L50.7106 25.9904H44.9734C45.7051 24.2479 45.2188 21.9082 45.4196 19.8671L45.3214 16.9882C43.8268 16.9858 42.3648 17.4245 41.119 18.2494L41.7524 15.366C42.4841 15.366 43.3629 15.4864 44.05 15.5443L53.1508 15.4864Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M128.385 25.8701H122.96C123.156 25.3887 123.156 24.8497 122.96 24.3683C122.469 23.1071 122.326 21.7256 121.737 20.5268C121.006 22.2069 120.466 24.0697 119.832 25.8078L119.685 25.9281H114.457C114.849 25.0368 114.309 24.1856 114.162 23.3478C113.087 20.7674 112.891 17.6434 111.231 15.4241L116.656 15.3661L116.852 15.6068C117.584 18.0668 118.075 20.5892 118.905 22.9288L119.15 22.6303C119.69 20.7674 120.618 19.1497 120.765 17.1086C120.667 16.4637 120.352 15.8712 119.873 15.4285L125.15 15.3706L125.422 15.6647L127.425 22.9288L127.573 22.8709L129.263 18.0712C129.45 17.1102 129.2 16.1161 128.581 15.3572C129.638 15.3572 130.977 15.3126 132.003 15.3572L128.385 25.8701Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M139.087 16.6272V24.3726C139.16 24.8728 139.31 25.3585 139.533 25.8121C137.677 25.9324 135.674 25.8121 133.769 25.8745C134.016 25.3449 134.167 24.7762 134.215 24.1943L134.117 16.4534L133.725 15.3125H139.475L139.087 16.6272Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M75.952 15.3662C75.6533 15.898 75.4994 16.4989 75.5059 17.1087C75.5059 19.8718 75.3096 22.8131 75.6531 25.3934L75.8985 25.9326L70.0989 25.9906C70.3384 25.4586 70.4891 24.891 70.5451 24.3104V16.6274C70.4498 16.2099 70.2998 15.8069 70.0989 15.4286C71.9459 15.3038 74.0471 15.4241 75.952 15.3662Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M97.1606 15.4242C96.5271 16.5027 96.768 18.0981 96.7145 19.4975L96.9108 19.5287L100.529 19.5064C100.578 18.4279 100.774 16.8637 100.382 15.9055L100.078 15.3975H105.954C105.681 15.8987 105.515 16.4511 105.467 17.0196L105.566 24.7651C105.62 25.1674 105.754 25.5551 105.958 25.9059H100.159C100.939 24.7071 100.502 22.9201 100.605 21.3603L100.556 21.24H96.7948C96.8439 22.7997 96.5004 24.618 97.1874 25.9371H91.3878C91.7349 25.2469 91.9036 24.4809 91.8785 23.7089L91.7804 16.5071L91.3878 15.4287H97.1874L97.1606 15.4242Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M173.684 19.0874C174.039 20.6172 173.757 22.2255 172.903 23.5439C170.458 26.5431 166.501 26.3069 163.276 25.7053C161.518 25.1661 159.417 24.0252 158.975 21.5652C158.779 20.0054 158.975 18.2629 159.952 17.0641C161.175 15.6826 162.803 15.2815 164.275 14.8359C164.656 14.7958 165.039 14.7809 165.422 14.7913C168.549 14.8359 172.461 14.9517 173.684 19.0874ZM165.765 16.2664C164.873 16.4447 164.346 17.5276 164.155 18.4947C164.106 20.3575 163.762 22.1579 164.788 23.7177C165.333 24.4398 166.293 24.7097 167.135 24.3773C167.686 24.1024 168.107 23.6223 168.308 23.0403C168.805 21.2098 168.788 19.2778 168.259 17.4563C167.842 16.4925 166.777 15.9843 165.765 16.2664Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M39.8921 18.3654C40.6223 20.0509 40.5317 21.9788 39.6468 23.5885C36.97 27.1894 31.1437 26.7571 28.8953 25.6875C27.5257 25.0859 26.0133 23.945 25.5717 22.1446C25.2376 20.7296 25.4112 19.2423 26.0624 17.9421C28.1145 15.0008 31.5363 14.8804 34.7127 15.179C36.6444 15.4865 38.7188 16.2664 39.8921 18.3654ZM31.6344 16.9839C30.9458 17.588 30.5684 18.4714 30.6084 19.386C30.5637 21.1285 30.2158 23.5885 31.9779 24.609C32.702 24.9233 33.5385 24.8317 34.1773 24.3684C34.6551 23.851 34.966 23.2019 35.0696 22.5055C35.1677 20.5848 35.364 18.664 34.3379 17.1042C33.6168 16.3409 32.4184 16.2895 31.6344 16.9884V16.9839Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M155.312 16.6271C156.584 17.5184 157.07 19.2075 157.07 20.95C156.96 21.8768 156.625 22.7627 156.093 23.5303C154.822 25.1525 153.064 25.6293 151.257 25.7585C148.326 25.9368 145.395 25.8165 142.411 25.8789C142.946 25.1569 142.754 24.1988 142.803 23.2985L142.754 16.1681L142.362 15.2768C146.756 15.6645 151.547 14.283 155.312 16.6271ZM147.835 16.7475V24.1319L147.983 24.2522C149.321 24.3102 150.865 24.3726 151.552 22.7504C151.998 21.4134 152.087 19.3902 151.454 18.129C150.668 16.5647 149.201 16.6271 147.831 16.7341L147.835 16.7475Z"
    />
    <path
      fill="#000000"
      stroke="none"
      d="M97.9414 28.3211C93.1054 33.3614 87.4843 36.9044 81.1806 37.9249C71.8121 39.3644 62.8897 36.3651 53.8156 35.046C43.8492 33.3035 32.7542 32.7643 23.0332 35.5273C18.1984 36.8034 13.5154 38.5964 9.0652 40.8751C8.91798 40.8172 8.96259 40.8751 8.96259 40.7414L12.188 39.0613C23.1358 33.5976 34.6636 29.8764 47.6145 30.6563C52.2936 30.9189 56.9481 31.5146 61.5424 32.4389L74.4442 35.1396C81.2832 36.9222 89.0056 35.8615 94.4304 30.9995L97.6558 28.2988H97.9503L97.9414 28.3211Z"
    />
  </svg>
)

export default Logo
