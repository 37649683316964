import { Lozenge } from "../../atoms/Lozenge/Lozenge"
import "./DocusignFileStatusLozenge.css"

enum DocusignFileStatus {
  Pending = "Pending",
  Signed = "Signed",
  Rejected = "Rejected",
}

export const DocusignFileStatusLozenge = ({ label }: { label: string }) => {
  const status = label as DocusignFileStatus
  switch (status) {
    case DocusignFileStatus.Signed:
      return <Lozenge className="green-lozenge" label={label} />

    case DocusignFileStatus.Pending:
      return <Lozenge className="grey-lozenge" label={label} />

    case DocusignFileStatus.Rejected:
      return <Lozenge className="red-lozenge" label={label} />

    default:
      return null
  }
}
