const ChevronDoubleRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 4L27.7589 15.4347C27.8354 15.5089 27.896 15.597 27.9373 15.694C27.9787 15.791 28 15.895 28 16C28 16.105 27.9787 16.209 27.9373 16.306C27.896 16.403 27.8354 16.4911 27.7589 16.5653L16 28"
      stroke="var(--color-universal-secondary-b)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4L15.7589 15.4347C15.8354 15.5089 15.896 15.597 15.9373 15.694C15.9787 15.791 16 15.895 16 16C16 16.105 15.9787 16.209 15.9373 16.306C15.896 16.403 15.8354 16.4911 15.7589 16.5653L4 28"
      stroke="var(--color-universal-secondary-b)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ChevronDoubleRight
