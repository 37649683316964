export const TFEFooterLogoSmall =  () => {
    return (
        <>
    <svg 
    className="logoBottomRight"
    width="27" 
    height="46" 
    viewBox="0 0 36 52" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
        <path d="M24.2334 17.39C24.6807 17.172 25.1716 17.0585 25.6692 17.0581C26.2577 17.0628 26.8347 17.2214 27.3429 17.5182C27.8511 17.815 28.2727 18.2397 28.5659 18.75V11.7957C28.5659 8.86617 27.4021 6.05666 25.3307 3.9852C23.2592 1.91374 20.4497 0.75 17.5202 0.75C14.5907 0.75 11.7812 1.91374 9.70974 3.9852C7.63827 6.05666 6.47454 8.86617 6.47454 11.7957V18.6994H7.64974C7.94079 18.1813 8.36353 17.7494 8.87519 17.4472C9.38684 17.145 9.96923 16.9834 10.5634 16.9787C10.6445 16.9787 10.7255 16.9828 10.8066 16.9884V11.7957C10.8066 10.0152 11.5139 8.30757 12.7729 7.04855C14.0319 5.78954 15.7395 5.08223 17.52 5.08223C19.3005 5.08223 21.0081 5.78954 22.2671 7.04855C23.5261 8.30757 24.2334 10.0152 24.2334 11.7957V17.39Z" fill="black"/>
        <path d="M7.19262 31.3029C7.46004 31.2836 7.71046 31.1646 7.89433 30.9695C8.0782 30.7744 8.1821 30.5173 8.18547 30.2492C8.1868 30.1211 8.16157 29.9941 8.11137 29.8763C8.06117 29.7584 7.98709 29.6522 7.8938 29.5644C7.80052 29.4766 7.69005 29.4091 7.56937 29.3661C7.44869 29.3232 7.32041 29.3057 7.19262 29.3147C6.92504 29.3337 6.67436 29.4526 6.4903 29.6478C6.30625 29.8429 6.20227 30.1001 6.19897 30.3684C6.1977 30.4965 6.22299 30.6236 6.27326 30.7414C6.32352 30.8593 6.39768 30.9655 6.49104 31.0533C6.5844 31.1411 6.69494 31.2086 6.81569 31.2516C6.93645 31.2945 7.06479 31.312 7.19262 31.3029Z" fill="#5BA4C2"/>
        <path d="M17.5206 10.9928L0.000405019 21.1077V23.9654L10.5967 28.7878C10.9202 28.4444 11.3619 28.2365 11.8326 28.2059C12.0584 28.1896 12.2852 28.2204 12.4985 28.2962C12.7117 28.3721 12.907 28.4914 13.0717 28.6467C13.2365 28.8019 13.3673 28.9896 13.4558 29.198C13.5442 29.4064 13.5885 29.6309 13.5857 29.8572C13.5798 30.3303 13.3961 30.7838 13.0713 31.1278C12.7465 31.4718 12.3042 31.681 11.8322 31.7141C11.6064 31.7306 11.3797 31.7 11.1663 31.6243C10.9529 31.5485 10.7576 31.4293 10.5928 31.2741C10.4279 31.1189 10.2971 30.9312 10.2086 30.7228C10.1201 30.5144 10.0759 30.2899 10.0788 30.0635C10.0787 29.8564 10.1142 29.6508 10.1837 29.4556L0 24.8201V26.4248L5.95705 29.1363C6.27991 28.7922 6.72172 28.584 7.19263 28.554C7.4184 28.5375 7.64514 28.568 7.85847 28.6437C8.07181 28.7194 8.26709 28.8386 8.43194 28.9938C8.5968 29.1489 8.72763 29.3366 8.81613 29.5449C8.90464 29.7533 8.9489 29.9778 8.94611 30.2041C8.94025 30.6773 8.75669 31.131 8.43186 31.4751C8.10703 31.8192 7.66469 32.0286 7.19263 32.0617C6.96689 32.0781 6.7402 32.0475 6.52692 31.9717C6.31364 31.896 6.11841 31.7768 5.95358 31.6217C5.78876 31.4666 5.65793 31.2789 5.56938 31.0706C5.48083 30.8623 5.43648 30.6379 5.43915 30.4116C5.43926 30.2044 5.47489 29.9988 5.54451 29.8037L0.000405019 27.2803V28.9985L17.5206 38.1023L35.0392 28.9985V21.1077L17.5206 10.9928ZM17.5206 30.1778L2.26327 22.4446L2.21789 22.4219L13.6392 15.8099H21.37L32.8242 22.4219L17.5206 30.1778ZM33.7611 27.2564L30.5122 29.0196V25.7708L33.7611 24.0076V27.2564Z" fill="#5BA4C2"/>
        <path d="M11.8327 30.9532C12.1001 30.9341 12.3506 30.8152 12.5344 30.62C12.7183 30.4249 12.822 30.1677 12.8251 29.8996C12.8263 29.7717 12.8009 29.6449 12.7507 29.5273C12.7004 29.4097 12.6263 29.3037 12.5331 29.2162C12.4398 29.1286 12.3294 29.0613 12.2089 29.0185C12.0884 28.9757 11.9602 28.9584 11.8327 28.9675C11.5655 28.9864 11.3152 29.1049 11.1313 29.2996C10.9475 29.4943 10.8434 29.751 10.8398 30.0187C10.8385 30.1468 10.8637 30.2738 10.9139 30.3917C10.9641 30.5096 11.0382 30.6157 11.1315 30.7035C11.2248 30.7913 11.3352 30.8588 11.4559 30.9018C11.5766 30.9448 11.7049 30.9623 11.8327 30.9532Z" fill="#5BA4C2"/>
        <path d="M26.8728 42.8061C26.8268 43.0512 26.8762 43.3046 27.011 43.5144C27.1458 43.7242 27.3557 43.8746 27.5978 43.9346C27.7197 43.9352 27.8405 43.9117 27.9532 43.8653C28.0659 43.819 28.1684 43.7507 28.2546 43.6645C28.3408 43.5784 28.4091 43.476 28.4555 43.3633C28.5019 43.2505 28.5255 43.1298 28.525 43.0079C28.5709 42.7626 28.5214 42.5091 28.3865 42.2991C28.2517 42.0892 28.0417 41.9387 27.7996 41.8785C27.6776 41.8779 27.5568 41.9015 27.444 41.9479C27.3313 41.9943 27.2288 42.0626 27.1426 42.1489C27.0564 42.2351 26.9882 42.3376 26.9419 42.4504C26.8956 42.5632 26.8721 42.6841 26.8728 42.8061Z" fill="black"/>
        <path d="M23.0193 42.254C22.9736 42.4992 23.0232 42.7526 23.158 42.9625C23.2928 43.1724 23.5027 43.323 23.7447 43.3834C23.8667 43.3839 23.9876 43.3603 24.1005 43.3139C24.2133 43.2675 24.3158 43.1992 24.4021 43.1129C24.4884 43.0267 24.5568 42.9242 24.6033 42.8114C24.6498 42.6987 24.6736 42.5778 24.6731 42.4558C24.7188 42.2106 24.6691 41.9572 24.5342 41.7474C24.3993 41.5377 24.1894 41.3873 23.9473 41.3272C23.8255 41.3268 23.7047 41.3504 23.592 41.3968C23.4793 41.4432 23.3769 41.5115 23.2906 41.5976C23.2044 41.6837 23.136 41.7861 23.0895 41.8987C23.0429 42.0113 23.0191 42.1321 23.0193 42.254Z" fill="black"/>
        <path d="M24.0255 40.5391C24.2262 40.5692 24.4178 40.6435 24.5864 40.7565C24.755 40.8695 24.8964 41.0185 25.0006 41.1927L35.0392 35.8366V33.6998L17.5198 42.8027L0.000395744 33.6998V41.3378L17.5206 51.453L35.0392 41.3378V39.5742L29.1308 42.4514C29.1693 42.6601 29.1784 42.8732 29.1579 43.0844C29.0599 44.0874 28.3256 44.82 27.5203 44.722C26.7151 44.6239 26.1425 43.7303 26.2402 42.7266C26.3379 41.7228 27.0709 40.9917 27.8778 41.0898C28.0781 41.1201 28.2691 41.1944 28.4373 41.3074C28.6055 41.4203 28.7465 41.5691 28.8503 41.743L35.0372 38.7305V36.6901L25.2777 41.9011C25.3174 42.1095 25.3268 42.3226 25.3057 42.5337C25.2064 43.537 24.4737 44.2701 23.6681 44.1721C22.8625 44.074 22.2903 43.1804 22.3871 42.1767C22.484 41.1729 23.2191 40.441 24.0255 40.5391ZM5.00959 41.8521L1.76117 40.088V36.8396L5.00959 38.6032V41.8521Z" fill="black"/>
        <path d="M26.3788 20.7859L15.7501 26.6372L17.5911 27.5692L28.11 21.782L26.3788 20.7859ZM15.0531 18.6713L7.86533 22.6407L9.70877 23.5752L16.7053 19.746L15.0531 18.6713ZM21.4377 17.9419L10.4962 23.9735L12.3364 24.9056L23.1668 18.9372L21.4377 17.9419ZM23.9425 19.3833L13.1606 25.3234L15.002 26.2554L25.6745 20.3794L23.9425 19.3833Z" fill="black"/>
    </svg>

    </>)

}