import { createContext, FC, useReducer, Dispatch, useContext } from "react"
import { IUserState, userReducer, initialState, IUserAction } from "./reducer"

export const UserContext = createContext(
  {} as {
    userState: IUserState
    dispatch: Dispatch<IUserAction>
  }
)

export const UserProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [userState, dispatch] = useReducer(userReducer, initialState)

  const value = { userState, dispatch }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserContext = () => useContext(UserContext)

export * from "./actions"
