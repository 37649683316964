import { getUserId, IUserDto } from "./getUser";

const usersSharingWithMe = require("../staticData/getSharedWithMeUsers.json");

type TObj = { [key: string]: any };

const isUserDtos = (users: any) => {
  if ((users as IUserDto[])?.length) {
    return users as IUserDto[];
  }
};

type TGetUsersSharingWithMe = (userEmail?: string) => IUserDto[];
export const getUsersSharingWithMeFromLocalData: TGetUsersSharingWithMe = (
  userEmail
) => {
  const currentUserId = getUserId(userEmail);
  const usersSharingWithMeObj = usersSharingWithMe as TObj;
  const userSharingWithMe = usersSharingWithMeObj[currentUserId];
  return isUserDtos(userSharingWithMe) || [];
};
