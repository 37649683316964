import { INotification } from "../../api/api-client/api-types"

//Mock data until connected to api
let today = new Date()
const dateOne = new Date(new Date().setDate(today.getDate() - 7))
const dateTwo = new Date(new Date().setDate(today.getDate() - 31))
const dateThree = new Date(new Date().setDate(today.getDate() - 29))
const dateFour = new Date(new Date().setDate(today.getDate() - 2))
const dateFive = new Date(new Date().setDate(today.getDate() - 17))
const dateSix = today
const dateSeven = new Date(2022, 7, 10, 9, 30)
const dateEight = new Date(2022, 7, 9, 9, 30)
const dateNine = new Date(2022, 7, 8, 9, 30)

export const notificationsData = [
  {
    title: "Contact uploads",
    date: dateOne,
    body: "has uploaded multiple (6) files today.",
    viewed: false,
  },
  {
    title: "Upload failed",
    date: dateTwo,
    body: "has failed upload due to detected malware",
    viewed: true,
  },
  {
    title: "Expiration date reminder",
    date: dateThree,
    body: "is due to expire on 28/03/2022",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateFour,
    body: "has uploaded multiple (2) files today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateFive,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateSix,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateSeven,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateEight,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: dateNine,
    body: "has uploaded a file today.",
    viewed: true,
  },
  {
    title: "Contact uploads",
    date: new Date(1, 1, 1, 1, 1),
    body: "last notification",
    viewed: true,
  },
] as INotification[]
