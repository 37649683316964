export const getTagsResponse = { 
        "dates": [
         {
           "name": "ExpiryDate",
           "category": "DateTime",
           "content": "28/01/2023",
           "confidence": 0.894047200679779
         },
         {
           "name": "DateFrom",
           "category": "DateTime",
           "content": "17/07/2020",
           "confidence": 0.894047200679779
         }
       ],
       "tags": [
         {
           "name": "text",
           "type": "string",
           "confidence": 0.894047200679779
         },
         {
         "name": "letter",
           "type": "string",
           "confidence": 0.894047200679779
         }
       ],
       "organizations": [
         {
           "text": "HM Revenue & Customs",
           "category": "Organization",
           "confidence": 0.894047200679779
         },
         {
         "text": "PT",
           "category": "Organization",
           "confidence": 0.894047200679779
         }
       ] 
}