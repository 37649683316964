import { AxiosRequestConfig } from "axios"

export const webSocketConfig: AxiosRequestConfig = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`,
  },
}

export const createWebHook = async ({
  websocketUrl,
  websocketName,
  attempt = 0,
  onmessage,
}: {
  websocketUrl: string
  websocketName?: string
  attempt?: number
  onmessage: (data: any) => void
}) => {
  // eslint-disable-next-line no-throw-literal
  if (attempt >= 5) throw "Number of attempts exceeded on User Webhook"

  const ws = new WebSocket(websocketUrl)
  ws.onopen = () => {
    console.log(
      `WebSocket${websocketName ? ` ${websocketName}` : ""} connected`
    )
    attempt = 0
  }

  ws.onmessage = async (event) => {
    let data = JSON.parse(event.data as string)
    onmessage({ data, ws })
  }

  ws.onerror = async (e) => {
    attempt++
    console.error(
      `Error with WebSocket connection, retry number: ${attempt}`,
      500
    )
    ws.close()
    setTimeout(() => {
      createWebHook({ websocketUrl, attempt, onmessage })
    }, 1000)
  }
  return ws
}
