import { SharedVaultDto } from "legado-generated-api-client"
import { getUserId } from "./getUser";
import folders from "../staticData/getFoldersSharedWithMe.json"

type TObj = { [key: string]: any };

export const getFoldersSharedWithMeFromLocalData = (userEmail :string) => {
  const userId = getUserId(userEmail);
  const res = folders as TObj
  let foldersSharedWithUser = res[userId]  
  return foldersSharedWithUser as SharedVaultDto[]
};
