import { getBreakpoints } from "../../design-tokens/getters"
import { TTheme } from "../../design-tokens/types"

export const getBreakpointVariables = (theme: TTheme) => {
  const breakpoints = getBreakpoints(theme)

  if (breakpoints.responsive) {
    if (breakpoints.responsive.length > 0) {
      // This theme has responsive margins defined so let's loop over them to produce rules

      let css = `
        --page-margin: ${breakpoints.page.margin?.value ?? "105px"};
        --page-max-width: ${breakpoints.page["max-width"]?.value ?? "1230px"};
        `

      breakpoints.responsive.map((bp) => {
        css += `
          @media (max-width: ${bp["max-width"].value}) {
              --page-margin: ${bp.margin.value};
          }
          `
      })
      return css
    }
  } else {
    return `
    --page-margin: ${breakpoints.page.margin?.value ?? "105px"};
    --page-max-width: ${breakpoints.page["max-width"]?.value ?? "1230px"};

    @media (max-width: 1230px) {
        --page-margin: 30px;
    }

    @media (max-width: 768px) {
        --page-margin: 20px;
    }
`
  }
}
