import React from "react"
import { PasswordSetupSteps } from "../../../../contexts/application/constants"
import ErrorIcon from "../../../../design-tokens/icons/theme/coop/ErrorIcon"
import SuccessIcon from "../../../../design-tokens/icons/theme/coop/SuccessIcon"
import { PasswordErrorKey } from "../../utils/validatePassword"

type PasswordRequirementsListProps = {
  isValidating: boolean
  errorsPassword: Array<{ passwordErrorKey: PasswordErrorKey }>
  passwordSetupStep: PasswordSetupSteps
}

export const PasswordRequirementsList: React.FC<
  PasswordRequirementsListProps
> = ({ isValidating, errorsPassword, passwordSetupStep }) => {
  const getValidationIcon = (
    passwordErrorKey: PasswordErrorKey,
    passwordErrorKeyTwo?: PasswordErrorKey
  ) => {
    if (isValidating) {
      const error = errorsPassword.find(
        (error) =>
          error.passwordErrorKey === passwordErrorKey ||
          error.passwordErrorKey === passwordErrorKeyTwo
      )
      return error ? <ErrorIcon /> : <SuccessIcon />
    }
    return null
  }

  const listItemClassName = (
    passwordErrorKey: PasswordErrorKey,
    passwordErrorKeyTwo?: PasswordErrorKey
  ) => {
    let status = "default"

    if (isValidating) {
      const isErrorPresent = errorsPassword.some(
        (error) =>
          error.passwordErrorKey === passwordErrorKey ||
          error.passwordErrorKey === passwordErrorKeyTwo
      )

      status = isErrorPresent ? "error" : "success"
    }

    return `requirement-item-list-style--${status}`
  }

  return (
    <ul>
      <li className={listItemClassName(PasswordErrorKey.TOO_SHORT)}>
        <div>{getValidationIcon(PasswordErrorKey.TOO_SHORT)}</div>
        <span>be at least 8 characters long</span>
      </li>
      <li
        className={listItemClassName(
          PasswordErrorKey.NO_UPPERCASE,
          PasswordErrorKey.NO_LOWERCASE
        )}
      >
        <div>
          {getValidationIcon(
            PasswordErrorKey.NO_UPPERCASE,
            PasswordErrorKey.NO_LOWERCASE
          )}
        </div>
        <span>include upper and lower case letters</span>
      </li>
      <li className={listItemClassName(PasswordErrorKey.NO_NUMBER)}>
        <div>{getValidationIcon(PasswordErrorKey.NO_NUMBER)}</div>
        <span>include numbers</span>
      </li>
      <li
        className={listItemClassName(PasswordErrorKey.NO_SPACE_AT_START_OR_END)}
      >
        <div>
          {getValidationIcon(PasswordErrorKey.NO_SPACE_AT_START_OR_END)}
        </div>
        <span>not begin or end in a space</span>
      </li>
      <li className={listItemClassName(PasswordErrorKey.COMMON_WORD)}>
        <div>{getValidationIcon(PasswordErrorKey.COMMON_WORD)}</div>
        <span>not allow common words</span>
      </li>
      <li className={listItemClassName(PasswordErrorKey.RECURRING_VALUES)}>
        <div>{getValidationIcon(PasswordErrorKey.RECURRING_VALUES)}</div>
        <span>not allow 3 or more recurring values (e.g. Password111)</span>
      </li>
      <li className={listItemClassName(PasswordErrorKey.SEQUENCES)}>
        <div>{getValidationIcon(PasswordErrorKey.SEQUENCES)}</div>
        <span>not allow sequences (e.g. abc, 123)</span>
      </li>
      <li className={listItemClassName(PasswordErrorKey.PREVIOUSLY_BREACHED)}>
        <div>{getValidationIcon(PasswordErrorKey.PREVIOUSLY_BREACHED)}</div>
        <span>not appear in a list of commonly used passwords</span>
      </li>
      <li className={listItemClassName(PasswordErrorKey.KEYBOARD_PATTERNS)}>
        <div>{getValidationIcon(PasswordErrorKey.KEYBOARD_PATTERNS)}</div>
        <span>not allow keyboard patterns</span>
      </li>
      {passwordSetupStep === PasswordSetupSteps.ResetPassword && (
        <li className={listItemClassName(PasswordErrorKey.PREVIOUSLY_USED)}>
          <div>{getValidationIcon(PasswordErrorKey.PREVIOUSLY_USED)}</div>
          <span>not have already been used in the last 12 months</span>
        </li>
      )}
    </ul>
  )
}
