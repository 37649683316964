"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PubSubApi = exports.PubSubApiFactory = exports.PubSubApiFp = exports.PubSubApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * PubSubApi - axios parameter creator
 * @export
 */
const PubSubApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Add current user to a WebSocket group
         * @summary Add User To Web Socket Group
         * @param {string} [groupName] Group Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubAddUserToWebSocketGroupPut: (groupName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/PubSub/AddUserToWebSocketGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (groupName !== undefined) {
                localVarQueryParameter["groupName"] = groupName;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets a websocket connection for the current user
         * @summary Get Web Socket Connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubGetWebSocketConnectionGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/PubSub/GetWebSocketConnection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets a websocket connection for the supplied user
         * @summary Join other Web Socket Connection
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubGetWebSocketConnectionUserIdGet: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new base_1.RequiredError("userId", "Required parameter userId was null or undefined when calling apiPubSubGetWebSocketConnectionUserIdGet.");
            }
            const localVarPath = `/api/PubSub/GetWebSocketConnection/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Remove current user to a WebSocket group
         * @summary Remove User From Web Socket Group
         * @param {string} [groupName] Group Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubRemoveUserFromWebSocketGroupDelete: (groupName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/PubSub/RemoveUserFromWebSocketGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (groupName !== undefined) {
                localVarQueryParameter["groupName"] = groupName;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Send Pubsub message to user
         * @summary Send Message To User
         * @param {string} [body] Message content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubSendMessageToUserPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/PubSub/SendMessageToUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.PubSubApiAxiosParamCreator = PubSubApiAxiosParamCreator;
/**
 * PubSubApi - functional programming interface
 * @export
 */
const PubSubApiFp = function (configuration) {
    return {
        /**
         * Add current user to a WebSocket group
         * @summary Add User To Web Socket Group
         * @param {string} [groupName] Group Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubAddUserToWebSocketGroupPut(groupName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.PubSubApiAxiosParamCreator)(configuration).apiPubSubAddUserToWebSocketGroupPut(groupName, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets a websocket connection for the current user
         * @summary Get Web Socket Connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubGetWebSocketConnectionGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.PubSubApiAxiosParamCreator)(configuration).apiPubSubGetWebSocketConnectionGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Gets a websocket connection for the supplied user
         * @summary Join other Web Socket Connection
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubGetWebSocketConnectionUserIdGet(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.PubSubApiAxiosParamCreator)(configuration).apiPubSubGetWebSocketConnectionUserIdGet(userId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Remove current user to a WebSocket group
         * @summary Remove User From Web Socket Group
         * @param {string} [groupName] Group Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubRemoveUserFromWebSocketGroupDelete(groupName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.PubSubApiAxiosParamCreator)(configuration).apiPubSubRemoveUserFromWebSocketGroupDelete(groupName, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Send Pubsub message to user
         * @summary Send Message To User
         * @param {string} [body] Message content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubSendMessageToUserPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.PubSubApiAxiosParamCreator)(configuration).apiPubSubSendMessageToUserPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.PubSubApiFp = PubSubApiFp;
/**
 * PubSubApi - factory interface
 * @export
 */
const PubSubApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Add current user to a WebSocket group
         * @summary Add User To Web Socket Group
         * @param {string} [groupName] Group Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubAddUserToWebSocketGroupPut(groupName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.PubSubApiFp)(configuration)
                    .apiPubSubAddUserToWebSocketGroupPut(groupName, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets a websocket connection for the current user
         * @summary Get Web Socket Connection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubGetWebSocketConnectionGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.PubSubApiFp)(configuration)
                    .apiPubSubGetWebSocketConnectionGet(options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Gets a websocket connection for the supplied user
         * @summary Join other Web Socket Connection
         * @param {string} userId User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubGetWebSocketConnectionUserIdGet(userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.PubSubApiFp)(configuration)
                    .apiPubSubGetWebSocketConnectionUserIdGet(userId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Remove current user to a WebSocket group
         * @summary Remove User From Web Socket Group
         * @param {string} [groupName] Group Name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubRemoveUserFromWebSocketGroupDelete(groupName, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.PubSubApiFp)(configuration)
                    .apiPubSubRemoveUserFromWebSocketGroupDelete(groupName, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Send Pubsub message to user
         * @summary Send Message To User
         * @param {string} [body] Message content
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPubSubSendMessageToUserPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.PubSubApiFp)(configuration)
                    .apiPubSubSendMessageToUserPost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.PubSubApiFactory = PubSubApiFactory;
/**
 * PubSubApi - object-oriented interface
 * @export
 * @class PubSubApi
 * @extends {BaseAPI}
 */
class PubSubApi extends base_1.BaseAPI {
    /**
     * Add current user to a WebSocket group
     * @summary Add User To Web Socket Group
     * @param {string} [groupName] Group Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    apiPubSubAddUserToWebSocketGroupPut(groupName, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.PubSubApiFp)(this.configuration)
                .apiPubSubAddUserToWebSocketGroupPut(groupName, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets a websocket connection for the current user
     * @summary Get Web Socket Connection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    apiPubSubGetWebSocketConnectionGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.PubSubApiFp)(this.configuration)
                .apiPubSubGetWebSocketConnectionGet(options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Gets a websocket connection for the supplied user
     * @summary Join other Web Socket Connection
     * @param {string} userId User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    apiPubSubGetWebSocketConnectionUserIdGet(userId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.PubSubApiFp)(this.configuration)
                .apiPubSubGetWebSocketConnectionUserIdGet(userId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Remove current user to a WebSocket group
     * @summary Remove User From Web Socket Group
     * @param {string} [groupName] Group Name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    apiPubSubRemoveUserFromWebSocketGroupDelete(groupName, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.PubSubApiFp)(this.configuration)
                .apiPubSubRemoveUserFromWebSocketGroupDelete(groupName, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Send Pubsub message to user
     * @summary Send Message To User
     * @param {string} [body] Message content
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PubSubApi
     */
    apiPubSubSendMessageToUserPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.PubSubApiFp)(this.configuration)
                .apiPubSubSendMessageToUserPost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.PubSubApi = PubSubApi;
