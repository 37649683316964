"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordValidationRules = void 0;
/**
 *   1 = NoCommonWords (Password has no common words)  2 = NoRecurringValues (Password has no recurring values like 111, aaa e.t.c)  3 = IncludesUppercase (Password has upper cases)  4 = IncludesLowercase (Password has lower case)  5 = IncludesNumbers (Password has digits)  6 = NoSequences (Password has no sequence like 123, abc e.t.c)  7 = NoKeyboardPatterns (Password has no keyboard patterns)  8 = NotBreached (Password has not been breached)  9 = NotUsed (Password has not been used in the last 12 months)
 * @export
 * @enum {string}
 */
var PasswordValidationRules;
(function (PasswordValidationRules) {
    PasswordValidationRules[PasswordValidationRules["NUMBER_1"] = 1] = "NUMBER_1";
    PasswordValidationRules[PasswordValidationRules["NUMBER_2"] = 2] = "NUMBER_2";
    PasswordValidationRules[PasswordValidationRules["NUMBER_3"] = 3] = "NUMBER_3";
    PasswordValidationRules[PasswordValidationRules["NUMBER_4"] = 4] = "NUMBER_4";
    PasswordValidationRules[PasswordValidationRules["NUMBER_5"] = 5] = "NUMBER_5";
    PasswordValidationRules[PasswordValidationRules["NUMBER_6"] = 6] = "NUMBER_6";
    PasswordValidationRules[PasswordValidationRules["NUMBER_7"] = 7] = "NUMBER_7";
    PasswordValidationRules[PasswordValidationRules["NUMBER_8"] = 8] = "NUMBER_8";
    PasswordValidationRules[PasswordValidationRules["NUMBER_9"] = 9] = "NUMBER_9";
})(PasswordValidationRules || (exports.PasswordValidationRules = PasswordValidationRules = {}));
