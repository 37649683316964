import { TTheme } from "../types";
import animation from "../tokens/oneX/animation.json";
import breakpoints from "../tokens/oneX/breakpoints.json"
import buttons from "../tokens/oneX/buttons.json";
import colors from "../tokens/oneX/colors.json";
import iconsizing from "../tokens/oneX/iconsizing.json";
import overlay from "../tokens/oneX/overlay.json";
import radius from "../tokens/oneX/radius.json";
import shadows from "../tokens/oneX/shadows.json";
import typeface from "../tokens/oneX/typeface.json";
import typography from "../tokens/oneX/typography.json";

const tokens = {
  mode: "ONE_X",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  typeface,
  typography,
} as TTheme;
export default tokens;
