"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentLibraryApi = exports.DocumentLibraryApiFactory = exports.DocumentLibraryApiFp = exports.DocumentLibraryApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * DocumentLibraryApi - axios parameter creator
 * @export
 */
const DocumentLibraryApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Returns a list of files for a specific customer
         * @summary Get file list for a specific customer
         * @param {string} personHierarchyId The ID of the customer to retrieve the file list from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryCustomerPersonHierarchyIdGet: (personHierarchyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'personHierarchyId' is not null or undefined
            if (personHierarchyId === null || personHierarchyId === undefined) {
                throw new base_1.RequiredError('personHierarchyId', 'Required parameter personHierarchyId was null or undefined when calling apiDocumentLibraryCustomerPersonHierarchyIdGet.');
            }
            const localVarPath = `/api/DocumentLibrary/customer/{personHierarchyId}`
                .replace(`{${"personHierarchyId"}}`, encodeURIComponent(String(personHierarchyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get the list of document categories for the current user
         * @summary Get the list of document categories for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryDocumentcategoriesGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/DocumentLibrary/documentcategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Changes the file visibility attribute
         * @summary Change file visibility
         * @param {string} fileId Id of the users file to change visibility
         * @param {number} visibility New visibility of file. Visibility options are as follow: 0 &#x3D; AdminOnly, 1 &#x3D; AdviserOnly, 2 &#x3D; ClientOnly, 3 &#x3D; ClientAndAdviser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryFileFileIdVisibilityVisibilityPatch: (fileId, visibility, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new base_1.RequiredError('fileId', 'Required parameter fileId was null or undefined when calling apiDocumentLibraryFileFileIdVisibilityVisibilityPatch.');
            }
            // verify required parameter 'visibility' is not null or undefined
            if (visibility === null || visibility === undefined) {
                throw new base_1.RequiredError('visibility', 'Required parameter visibility was null or undefined when calling apiDocumentLibraryFileFileIdVisibilityVisibilityPatch.');
            }
            const localVarPath = `/api/DocumentLibrary/file/{fileId}/visibility/{visibility}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"visibility"}}`, encodeURIComponent(String(visibility)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Upload a single or multiple files to client's vault based on the data provided in the
         * @summary Upload files to client vault
         * @param {string} headAccount The head-account reference
         * @param {string} [subAccount] (Optional) The sub-account reference (if required)
         * @param {string} [visibility] Visibility of uploaded file (AdminOnly, AdviserOnly, ClientOnly, ClientAndAdviser)
         * @param {string} [documentCategory] (Optional)Document Category Metadata for institutional specific file organisation
         * @param {boolean} [sendNotification] (Optional) Flag indicating whether to send notification (defaults to false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryHeadaccountHeadAccountFileuploadPost: (headAccount, subAccount, visibility, documentCategory, sendNotification, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'headAccount' is not null or undefined
            if (headAccount === null || headAccount === undefined) {
                throw new base_1.RequiredError('headAccount', 'Required parameter headAccount was null or undefined when calling apiDocumentLibraryHeadaccountHeadAccountFileuploadPost.');
            }
            const localVarPath = `/api/DocumentLibrary/headaccount/{headAccount}/fileupload`
                .replace(`{${"headAccount"}}`, encodeURIComponent(String(headAccount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (subAccount !== undefined) {
                localVarQueryParameter['subAccount'] = subAccount;
            }
            if (visibility !== undefined) {
                localVarQueryParameter['visibility'] = visibility;
            }
            if (documentCategory !== undefined) {
                localVarQueryParameter['documentCategory'] = documentCategory;
            }
            if (sendNotification !== undefined) {
                localVarQueryParameter['sendNotification'] = sendNotification;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetches the status of the document requests using a list of request IDs
         * @summary Polls the document request statuses based on provided request IDs
         * @param {string} [requestIds] Comma-separated list of request IDs to check status for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryPolldocumentstatusGet: (requestIds, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/DocumentLibrary/polldocumentstatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (requestIds !== undefined) {
                localVarQueryParameter['requestIds'] = requestIds;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DocumentLibraryApiAxiosParamCreator = DocumentLibraryApiAxiosParamCreator;
/**
 * DocumentLibraryApi - functional programming interface
 * @export
 */
const DocumentLibraryApiFp = function (configuration) {
    return {
        /**
         * Returns a list of files for a specific customer
         * @summary Get file list for a specific customer
         * @param {string} personHierarchyId The ID of the customer to retrieve the file list from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryCustomerPersonHierarchyIdGet(personHierarchyId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocumentLibraryApiAxiosParamCreator)(configuration).apiDocumentLibraryCustomerPersonHierarchyIdGet(personHierarchyId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Get the list of document categories for the current user
         * @summary Get the list of document categories for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryDocumentcategoriesGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocumentLibraryApiAxiosParamCreator)(configuration).apiDocumentLibraryDocumentcategoriesGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Changes the file visibility attribute
         * @summary Change file visibility
         * @param {string} fileId Id of the users file to change visibility
         * @param {number} visibility New visibility of file. Visibility options are as follow: 0 &#x3D; AdminOnly, 1 &#x3D; AdviserOnly, 2 &#x3D; ClientOnly, 3 &#x3D; ClientAndAdviser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryFileFileIdVisibilityVisibilityPatch(fileId, visibility, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocumentLibraryApiAxiosParamCreator)(configuration).apiDocumentLibraryFileFileIdVisibilityVisibilityPatch(fileId, visibility, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Upload a single or multiple files to client's vault based on the data provided in the
         * @summary Upload files to client vault
         * @param {string} headAccount The head-account reference
         * @param {string} [subAccount] (Optional) The sub-account reference (if required)
         * @param {string} [visibility] Visibility of uploaded file (AdminOnly, AdviserOnly, ClientOnly, ClientAndAdviser)
         * @param {string} [documentCategory] (Optional)Document Category Metadata for institutional specific file organisation
         * @param {boolean} [sendNotification] (Optional) Flag indicating whether to send notification (defaults to false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, documentCategory, sendNotification, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocumentLibraryApiAxiosParamCreator)(configuration).apiDocumentLibraryHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, documentCategory, sendNotification, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Fetches the status of the document requests using a list of request IDs
         * @summary Polls the document request statuses based on provided request IDs
         * @param {string} [requestIds] Comma-separated list of request IDs to check status for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryPolldocumentstatusGet(requestIds, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.DocumentLibraryApiAxiosParamCreator)(configuration).apiDocumentLibraryPolldocumentstatusGet(requestIds, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.DocumentLibraryApiFp = DocumentLibraryApiFp;
/**
 * DocumentLibraryApi - factory interface
 * @export
 */
const DocumentLibraryApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Returns a list of files for a specific customer
         * @summary Get file list for a specific customer
         * @param {string} personHierarchyId The ID of the customer to retrieve the file list from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryCustomerPersonHierarchyIdGet(personHierarchyId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocumentLibraryApiFp)(configuration).apiDocumentLibraryCustomerPersonHierarchyIdGet(personHierarchyId, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Get the list of document categories for the current user
         * @summary Get the list of document categories for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryDocumentcategoriesGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocumentLibraryApiFp)(configuration).apiDocumentLibraryDocumentcategoriesGet(options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Changes the file visibility attribute
         * @summary Change file visibility
         * @param {string} fileId Id of the users file to change visibility
         * @param {number} visibility New visibility of file. Visibility options are as follow: 0 &#x3D; AdminOnly, 1 &#x3D; AdviserOnly, 2 &#x3D; ClientOnly, 3 &#x3D; ClientAndAdviser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryFileFileIdVisibilityVisibilityPatch(fileId, visibility, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocumentLibraryApiFp)(configuration).apiDocumentLibraryFileFileIdVisibilityVisibilityPatch(fileId, visibility, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Upload a single or multiple files to client's vault based on the data provided in the
         * @summary Upload files to client vault
         * @param {string} headAccount The head-account reference
         * @param {string} [subAccount] (Optional) The sub-account reference (if required)
         * @param {string} [visibility] Visibility of uploaded file (AdminOnly, AdviserOnly, ClientOnly, ClientAndAdviser)
         * @param {string} [documentCategory] (Optional)Document Category Metadata for institutional specific file organisation
         * @param {boolean} [sendNotification] (Optional) Flag indicating whether to send notification (defaults to false)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, documentCategory, sendNotification, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocumentLibraryApiFp)(configuration).apiDocumentLibraryHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, documentCategory, sendNotification, options).then((request) => request(axios, basePath));
            });
        },
        /**
         * Fetches the status of the document requests using a list of request IDs
         * @summary Polls the document request statuses based on provided request IDs
         * @param {string} [requestIds] Comma-separated list of request IDs to check status for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocumentLibraryPolldocumentstatusGet(requestIds, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.DocumentLibraryApiFp)(configuration).apiDocumentLibraryPolldocumentstatusGet(requestIds, options).then((request) => request(axios, basePath));
            });
        },
    };
};
exports.DocumentLibraryApiFactory = DocumentLibraryApiFactory;
/**
 * DocumentLibraryApi - object-oriented interface
 * @export
 * @class DocumentLibraryApi
 * @extends {BaseAPI}
 */
class DocumentLibraryApi extends base_1.BaseAPI {
    /**
     * Returns a list of files for a specific customer
     * @summary Get file list for a specific customer
     * @param {string} personHierarchyId The ID of the customer to retrieve the file list from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLibraryApi
     */
    apiDocumentLibraryCustomerPersonHierarchyIdGet(personHierarchyId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocumentLibraryApiFp)(this.configuration).apiDocumentLibraryCustomerPersonHierarchyIdGet(personHierarchyId, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Get the list of document categories for the current user
     * @summary Get the list of document categories for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLibraryApi
     */
    apiDocumentLibraryDocumentcategoriesGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocumentLibraryApiFp)(this.configuration).apiDocumentLibraryDocumentcategoriesGet(options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Changes the file visibility attribute
     * @summary Change file visibility
     * @param {string} fileId Id of the users file to change visibility
     * @param {number} visibility New visibility of file. Visibility options are as follow: 0 &#x3D; AdminOnly, 1 &#x3D; AdviserOnly, 2 &#x3D; ClientOnly, 3 &#x3D; ClientAndAdviser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLibraryApi
     */
    apiDocumentLibraryFileFileIdVisibilityVisibilityPatch(fileId, visibility, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocumentLibraryApiFp)(this.configuration).apiDocumentLibraryFileFileIdVisibilityVisibilityPatch(fileId, visibility, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Upload a single or multiple files to client's vault based on the data provided in the
     * @summary Upload files to client vault
     * @param {string} headAccount The head-account reference
     * @param {string} [subAccount] (Optional) The sub-account reference (if required)
     * @param {string} [visibility] Visibility of uploaded file (AdminOnly, AdviserOnly, ClientOnly, ClientAndAdviser)
     * @param {string} [documentCategory] (Optional)Document Category Metadata for institutional specific file organisation
     * @param {boolean} [sendNotification] (Optional) Flag indicating whether to send notification (defaults to false)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLibraryApi
     */
    apiDocumentLibraryHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, documentCategory, sendNotification, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocumentLibraryApiFp)(this.configuration).apiDocumentLibraryHeadaccountHeadAccountFileuploadPost(headAccount, subAccount, visibility, documentCategory, sendNotification, options).then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Fetches the status of the document requests using a list of request IDs
     * @summary Polls the document request statuses based on provided request IDs
     * @param {string} [requestIds] Comma-separated list of request IDs to check status for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentLibraryApi
     */
    apiDocumentLibraryPolldocumentstatusGet(requestIds, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.DocumentLibraryApiFp)(this.configuration).apiDocumentLibraryPolldocumentstatusGet(requestIds, options).then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.DocumentLibraryApi = DocumentLibraryApi;
