import { IFolder } from "../../api/api-client/api-types"
import { getStringSortPriority } from "../../utils/helpers"

export const sortSections = (folders: IFolder[]) => {
  const sortedSections = folders.sort((a, b) => {
    return (a.displayorder ?? 1) - (b.displayorder ?? 1)
  })
  return sortedSections
}

export function sortChildFoldersForPlatform(foldersToSort: IFolder[]) {
  foldersToSort.forEach((folder) => {
    if (folder.childFolders) {
      folder.childFolders = sortFoldersForPlatform(folder.childFolders)
    }
  })

  return foldersToSort
}

export function sortFoldersForPlatform(folders: IFolder[]) {
  const currentPlatformFolders = getFilteredFoldersResult(folders, true, true)
  const otherPlatformFolders = getFilteredFoldersResult(folders, false, true)
  const nonPlatformFolders = getFilteredFoldersResult(folders, false, false)

  const providerFolders = getConnectedFolders(folders)

  providerFolders.sort((n1, n2) => getStringSortPriority(n1.name, n2.name))
  currentPlatformFolders.sort((n1, n2) =>
    getStringSortPriority(n1.name, n2.name)
  )
  otherPlatformFolders.sort((n1, n2) => getStringSortPriority(n1.name, n2.name))
  nonPlatformFolders.sort((n1, n2) => getStringSortPriority(n1.name, n2.name))

  const sortedChildFolders = [
    ...providerFolders,
    ...currentPlatformFolders,
    ...otherPlatformFolders,
    ...nonPlatformFolders,
  ]

  return sortedChildFolders
}

function getFilteredFoldersResult(
  folders: IFolder[],
  isCurrentPlatformFolder: boolean,
  isConnectedFolder: boolean
) {
  const filteredFolders = folders.filter(
    (folder) =>
      (isCurrentPlatformFolder
        ? folder.isCurrentPlatform
        : !folder.isCurrentPlatform) &&
      (isConnectedFolder
        ? folder.folderType === "ConnectedFolder" ||
          folder.folderType === "PlatformFolder"
        : folder.folderType !== "ConnectedFolder" &&
          folder.folderType !== "PlatformFolder")
  )

  return filteredFolders.filter(
    (folder) => folder.folderType !== "ConnectedAccountFolder"
  )
}

function getConnectedFolders(folders: IFolder[]) {
  return folders.filter(
    (folder) => folder.folderType === "ConnectedAccountFolder"
  )
}
