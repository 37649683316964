import { FC } from "react"
import { Lozenge } from "../Lozenge"
import "./CaseDocumentStatusLozenge.css"

export enum DocumentStatus {
  //green
  Completed = "Completed",
  CompletedSigned = "CompletedSigned",
  Uploaded = "Uploaded",
  UploadedSigned = "UploadedSigned",
  Verified = "Verified",
  Signed = "Signed",
  Commented = "Commented",
  Approved = "Approved",
  Posted = "Posted",

  //yellow
  Complete = "Complete",
  Signature = "Signature",
  Comment = "Comment",
  Approve = "Approve",
  Print = "Print",
  PrintReturn = "PrintReturn",
  CompleteSign = "CompleteSign",

  //red
  Rejected = "Rejected",

  //blue
  InReview = "InReview",

  //none
  None = "None",
  Upload = "Upload",
}

const statusLabel = {
  //green
  Completed: "Completed",
  CompletedSigned: "Completed and Signed",
  Uploaded: "Uploaded",
  UploadedSigned: "Signed",
  Verified: "Verified",
  Signed: "Signed",
  Commented: "Commented",
  Approved: "Approved",
  Posted: "Posted",

  //yellow
  Complete: "To be completed",
  Signature: "Signature",
  Comment: "Comment",
  Approve: "Approve",
  Print: "Print",
  PrintReturn: "Print and Return",
  CompleteSign: "Complete and Sign",

  //red
  Rejected: "Rejected",

  //blue
  InReview: "In-review",
}

interface CaseDocumentStatusLozengeProps {
  status?: string | null
}

export const CaseDocumentStatusLozenge: FC<CaseDocumentStatusLozengeProps> = ({
  status,
}) => {
  switch (status) {
    case DocumentStatus.Completed:
    case DocumentStatus.CompletedSigned:
    case DocumentStatus.Uploaded:
    case DocumentStatus.UploadedSigned:
    case DocumentStatus.Verified:
    case DocumentStatus.Signed:
    case DocumentStatus.Commented:
    case DocumentStatus.Approved:
    case DocumentStatus.Posted:
      return (
        <Lozenge
          className="green-background-lozenge"
          label={statusLabel[status]}
        />
      )

    case DocumentStatus.Complete:
    case DocumentStatus.Signature:
    case DocumentStatus.Comment:
    case DocumentStatus.Approve:
    case DocumentStatus.PrintReturn:
    case DocumentStatus.Print:
    case DocumentStatus.CompleteSign:
      return (
        <Lozenge
          className="yellow-background-lozenge"
          label={statusLabel[status]}
        />
      )

    case DocumentStatus.Rejected:
      return (
        <Lozenge
          className="red-background-lozenge"
          label={statusLabel[status]}
        />
      )

    case DocumentStatus.InReview:
      return (
        <Lozenge
          className="grey-background-lozenge"
          label={statusLabel[status]}
        />
      )

    case DocumentStatus.None:
    case DocumentStatus.Upload:
    default:
      return null
  }
}
