import { ChangeEvent } from "react"
import classNames from "classnames"
import "./CheckBox.css"

export type CheckBoxProps = {
  className?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  isChecked: boolean
  isDisabled?: boolean
  id?: string
  ariaLabel?: string
  shouldEnableFocusOutline?: boolean
}

const CheckBox: React.FC<CheckBoxProps> = ({
  className,
  onChange,
  isChecked,
  isDisabled = false,
  id = "checkbox",
  ariaLabel,
  shouldEnableFocusOutline = false,
}) => {
  const checkBoxClass = classNames("checkbox", className, {
    disabled: isDisabled,
    "focus-enabled": shouldEnableFocusOutline,
  })

  return (
    <div className={checkBoxClass}>
      <input
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={(event) => {
          if (!isDisabled) {
            onChange(event)
          }
        }}
        aria-disabled={isDisabled}
        aria-checked={isChecked}
        disabled={isDisabled}
        aria-label={ariaLabel ?? ""}
      />
    </div>
  )
}

export default CheckBox
