import { ReactElement } from "react"

import FolderIconsFinances from "../oneX/FolderIconsFinances"
import FolderIconsHomeProperty from "../oneX/FolderIconsHomeProperty"
import FolderIconsLegacyPlanning from "./FolderIconsLegacyPlanning"
import FolderIconsLegal from "./FolderIconsLegal"
import FolderIconsPersonalFamily from "./FolderIconsPersonalFamily"
import FolderIconsPropertyVehicles from "./FolderIconsPropertyVehicles"
import FolderIconsSavingsInvestments from "./FolderIconsSavingsInvestments"
import FolderIconsVehicles from "../oneX/FolderIconsVehicles"
import Logo from "./Logo"
import WelcomeConnect from "./WelcomeConnect"
import WelcomeOrganise from "./WelcomeOrganise"
import WelcomePortability from "./WelcomePortability"
import WelcomeSecure from "./WelcomeSecure"
import WelcomeShare from "./WelcomeShare"
import WelcomeStore from "./WelcomeStore"

export const GetIcon = {
  FolderIconsFinances: <FolderIconsFinances />,
  FolderIconsHomeProperty: <FolderIconsHomeProperty />,
  FolderIconsLegacyPlanning: <FolderIconsLegacyPlanning />,
  FolderIconsLegal: <FolderIconsLegal />,
  FolderIconsPersonalFamily: <FolderIconsPersonalFamily />,
  FolderIconsPropertyVehicles: <FolderIconsPropertyVehicles />,
  FolderIconsSavingsInvestments: <FolderIconsSavingsInvestments />,
  FolderIconsVehicles: <FolderIconsVehicles />,
  Logo: <Logo />,
  WelcomeConnect: <WelcomeConnect />,
  WelcomeLogo: <Logo />,
  WelcomeOrganise: <WelcomeOrganise />,
  WelcomePortability: <WelcomePortability />,
  WelcomeSecure: <WelcomeSecure />,
  WelcomeShare: <WelcomeShare />,
  WelcomeStore: <WelcomeStore />,
} as { [key: string]: ReactElement }

export default GetIcon
