// This function is used to get the path of the current route
// give it a path and it will return the path populated with the object params
// for example getPath('/cases/:caseId') will return '/cases/123'
// This is useful for getting the current path in the application by combining it with PAGE_PATH
export function replaceParamsInPath({
  path,
  pathParameters,
}: {
  path: string
  pathParameters: { [key: string]: string }
}): string {
  if (!path.length) {
    throw new Error("Path is required")
  }

  const keys = Object.keys(pathParameters)
  const pathParams = path.match(/:[a-zA-Z0-9]+/g)

  if (!pathParams) {
    return path
  }

  const pathParamsWithoutColon = pathParams?.map((param) => param.slice(1))
  let missingPathParams: string[] = []
  pathParamsWithoutColon.forEach((key) => {
    if (!pathParameters[key]) {
      missingPathParams.push(key)
    }
  })

  if (missingPathParams.length > 0) {
    throw new Error(
      `The following path parameters are missing: ${missingPathParams.join(
        ","
      )}`
    )
  }

  let newPath = path
  keys.forEach((key) => {
    newPath = newPath.replace(`:${key}`, pathParameters[key])
  })

  return newPath
}
