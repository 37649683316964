import { UserDto } from "legado-generated-api-client"
import { NavigateFunction } from "react-router-dom"

import { PAGE_PATH, UserModuleType } from "../../contexts/application/constants"
import { LocalStorageKeys } from "../../contexts/auth/localStorageKeys"

/**
 * This function is one of the first things called after the user logs in via LoginPage, ResetPasswordPage or RegistrationPage
 *
 */
export const redirectAfterLoggedInBasedOnSharedWithMeFeature = ({
  user,
  navigate,
}: {
  user: UserDto
  navigate: NavigateFunction
}) => {
  /**
   * This flag is needed for useIsIdleStateTimer used for a session timeout.
   * The flag is set to true when the user has used the login page to sign in.
   */
  localStorage.setItem(LocalStorageKeys.HasUsedLoginPageToSignIn, "true")
  if (user.modules?.includes(UserModuleType.SHARED_WITH_ME_VIEW)) {
    navigate(PAGE_PATH.CaseContactSharedWithMePage)
  } else {
    navigate(PAGE_PATH.Cases)
  }
}
