import { ReactElement } from "react"
import { WelcomeLogoLegado } from "./WelcomeLogo"
import Pencil from "./Pencil"
import { LegadoLogo } from "./LegadoLogo"
import ErrorIcon from "./ErrorIcon"

export const GetIcon = {
  WelcomeLogoLegado: <WelcomeLogoLegado />,
  Pencil: <Pencil />,
  LegadoLogo: <LegadoLogo />,
  ErrorIcon: <ErrorIcon />,
} as { [key: string]: ReactElement }

export default GetIcon
