const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 35 35"
    fill="none"
  >
    <path
      d="M32 18C32 25.732 25.732 32 18 32C10.268 32 4 25.732 4 18C4 10.268 10.268 4 18 4C25.732 4 32 10.268 32 18Z"
      fill="#E20F14"
    />
    <path
      d="M25 11L11 25M25 25L11 11"
      stroke="#FEFEFE"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ErrorIcon
