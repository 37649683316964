import React, { FC } from 'react'
import '../modules/Modal/Modal.css'

export const InvisibleBackdrop: FC<{
  className?: string
  onClick?: () => void
  children?: React.ReactNode
}> = ({ className, onClick, children }) => {
  return (
    <div
      className={`cover-screen invisible-backdrop${
        className ? ` ${className}` : ''
      }`}
      onClick={(e) => {
        e.stopPropagation()
        if (onClick) {
          onClick()
        }
      }}
    >
      {children}
    </div>
  )
}
