import { FC } from "react"

import { IFile } from "../../../api/api-client/api-types"
import { useThumbnails } from "../../../hooks/useThumbnails"
import { infoBoxMessageText } from "../../atoms/InfoBoxMessage/infoBoxMessageText"
import { InfoBoxMessage } from "../../atoms/InfoBoxMessage/InfoBoxMessage"
import { AlertMessageItem } from "../../modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"
import {
  Option,
  UploadedDocument,
} from "../../modules/UploadFileToCaseModalWizard/UploadFileToCaseModalWizard"
import { DocumentDetails } from "./DocumentDetails/Details/DocumentDetails"
import { DocumentDetailsForm } from "./DocumentDetails/Form/DocumentDetailsForm"

import "./UploadedFileDetails.css"

interface UploadedFileDetailsProps {
  file: IFile
  requestDropdownOptions: Option[]
  formData: UploadedDocument[]
  formErrors: AlertMessageItem[] | undefined
  uploadedViaCategory: boolean
  setFormData: (data: UploadedDocument[]) => void
  setFileSelectedForPreview: (file: IFile) => void
  setUnsavedChanges?: (unsavedChanges: boolean) => void
}

export const UploadedFileDetails: FC<UploadedFileDetailsProps> = ({
  file,
  requestDropdownOptions,
  formData,
  formErrors,
  uploadedViaCategory,
  setFormData,
  setFileSelectedForPreview,
  setUnsavedChanges,
}) => {
  const { base64ThumbnailString } = useThumbnails({
    file: file,
    isFullSizeThumbnail: false,
  })

  const formDataToDisplay: UploadedDocument = formData.find(
    (x) => x.fileId === file.id
  ) ?? {
    fileId: file.id,
    name: file.name,
    category: { stageId: "", requestId: "", name: "" },
    base64ThumbnailString: file.base64StringThumbnail ?? "",
    fileExtension: file.extension ?? "",
  }

  return (
    <>
      <hr />
      <InfoBoxMessage message={infoBoxMessageText.documentName} />
      <div className="uploaded-file-details">
        <DocumentDetails
          file={file}
          formData={formDataToDisplay}
          base64ThumbnailString={
            base64ThumbnailString ? base64ThumbnailString : ""
          }
          setFileSelectedForPreview={setFileSelectedForPreview}
        />
        <DocumentDetailsForm
          file={file}
          requestDropdownOptions={requestDropdownOptions}
          formData={formData}
          formErrors={formErrors}
          base64ThumbnailString={
            base64ThumbnailString ? base64ThumbnailString : ""
          }
          setFormData={setFormData}
          uploadedViaCategory={uploadedViaCategory}
          setUnsavedChanges={setUnsavedChanges}
        />
      </div>
    </>
  )
}
