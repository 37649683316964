import { TTheme } from "../../design-tokens/types"
import { getRadius } from "../../design-tokens/getters"

export const getRadiusVariables = (theme: TTheme) => {
  const radius = getRadius(theme)

  return `
          --border-radius-s: ${radius.s?.value ?? "5px"};
          --dragAndDrop-interaction-border-radius: ${radius.s?.value ?? "5px"};
          --border-radius-m: ${radius.m?.value ?? "20px"};
          --border-radius-l: ${radius.l?.value ?? "50px"};
          --tag-interaction-border-radius: ${radius.b?.value ?? "100px"};
          --button-action-border-radius: ${radius.b?.value ?? "50px"};
          --search-border-radius: ${radius.b?.value ?? "50px"};
          --lozenge-radius: ${radius.lozenge?.value ?? "4px"};
          --form-radius: ${radius.form?.value ?? "3px"};
          --pagination-radius: ${radius.pagination?.value ?? "3px"};
          --profile-button-radius: ${radius.profileButton?.value ?? "100px"};

      `
}
