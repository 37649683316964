import { Dispatch } from "react"
import { clientCustomFolder } from "../../component/CreateSubfolder/helpers"
import {
  setLoadingState,
  updatePinnedFolderOrFile,
} from "../../contexts/application/action"
import { setDisplaySuccessOrErrorMessage } from "../../contexts/toasts"
import { IFile, IFolder, IUser } from "../../api/api-client/api-types"
import { findFolderByFileId } from "../../api/api-client/file/findFolderByFileId"
import { notificationMessages } from "../../data/notificationMessages"
import { ApiController } from "../../api/apiController"
import { GetGAFolderType, sendGaData } from "../gaEvents"
import { IToastAction } from "../../contexts/toasts/reducer"

export const setPinFile = async (
  dispatch: Dispatch<any>,
  toastDispatch: Dispatch<IToastAction>,
  pin: boolean,
  file: IFile,
  folders: IFolder[],
  currentUser: IUser | undefined
) => {
  dispatch(setLoadingState(true))
  const parentFolder = findFolderByFileId(file.id, folders)
  sendGaData({
    name: pin ? "File_Pin" : "File_Unpin",
    folder_type:
      parentFolder?.folderType !== clientCustomFolder ? "Suggested" : "Custom",
    folder_name: parentFolder?.name,
    folder_depth: parentFolder?.level,
    is_node_owner: file?.isOwner ? "Y" : "N",
    file_extension: file.extension ?? "",
  })
  const api = ApiController.getInstance()
  const res = await api.pinFile(file.id, pin)
  if (res) {
    dispatch(
      updatePinnedFolderOrFile({ pinnedFolderOrFile: file, isPinned: pin })
    )

    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: pin
          ? notificationMessages.pinFile.SUCCESS
          : notificationMessages.unpinFile.SUCCESS,
        messageType: "SUCCESS",
      })
    )
    sendGaData({
      name: pin ? "File_Pin_Response" : "File_Unpin_Response",
      folder_type: GetGAFolderType(parentFolder),
      folder_name: parentFolder?.name,
      folder_depth: parentFolder?.level,
      is_node_owner: file.isOwner ? "Y" : "N",
      file_extension: file.extension!,
      request_status: "success",
      currentUser,
    })
  } else {
    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: pin
          ? notificationMessages.pinFile.ERROR
          : notificationMessages.unpinFile.ERROR,
        messageType: "ERROR",
      })
    )
    sendGaData({
      name: pin ? "File_Pin_Response" : "File_Unpin_Response",
      folder_type: GetGAFolderType(parentFolder),
      folder_name: parentFolder!.name,
      folder_depth: parentFolder!.level,
      is_node_owner: parentFolder!.isOwner ? "Y" : "N",
      file_extension: file.extension!,
      request_status: "error",
      currentUser,
    })
    return false
  }

  dispatch(setLoadingState(false))
  return true
}

export const setPinFolder = async (
  dispatch: Dispatch<any>,
  toastDispatch: Dispatch<IToastAction>,
  pin: boolean,
  folder: IFolder,
  currentUser: IUser | undefined
) => {
  dispatch(setLoadingState(true))
  sendGaData({
    name: pin ? "Folder_Pin" : "Folder_Unpin",
    folder_type: GetGAFolderType(folder),
    folder_name: folder.name,
    folder_depth: folder.level,
    is_node_owner: folder.isOwner ? "Y" : "N",
  })
  const api = ApiController.getInstance()
  var res = await api.pinFolder(folder.id, pin)
  if (res) {
    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: pin
          ? notificationMessages.pinFolder.SUCCESS
          : notificationMessages.unpinFolder.SUCCESS,
        messageType: "SUCCESS",
      })
    )
    dispatch(
      updatePinnedFolderOrFile({
        pinnedFolderOrFile: folder,
        isPinned: pin,
      })
    )
    sendGaData({
      name: pin ? "Folder_Pin_Response" : "Folder_Unpin_Response",
      folder_type: GetGAFolderType(folder),
      folder_name: folder.name,
      folder_depth: folder.level,
      is_node_owner: folder.isOwner ? "Y" : "N",
      request_status: "success",
      currentUser,
    })
  } else {
    toastDispatch(
      setDisplaySuccessOrErrorMessage({
        message: pin
          ? notificationMessages.pinFolder.SUCCESS
          : notificationMessages.unpinFolder.SUCCESS,
        messageType: "ERROR",
      })
    )
    sendGaData({
      name: pin ? "Folder_Pin_Response" : "Folder_Unpin_Response",
      folder_type: GetGAFolderType(folder),
      folder_name: folder.name,
      folder_depth: folder.level,
      is_node_owner: folder.isOwner ? "Y" : "N",
      request_status: "error",
      currentUser,
    })
  }

  dispatch(setLoadingState(false))
  return true
}
