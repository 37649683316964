const RadioActive = () => {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      className="fill"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_32_791)">
        <path
          d="M32 16C32 7.71573 25.2843 1 17 1C8.71573 1 2 7.71573 2 16C2 24.2843 8.71573 31 17 31C25.2843 31 32 24.2843 32 16Z"
          fill="none"
        />
        <path
          d="M17 1.5C25.0081 1.5 31.5 7.99187 31.5 16C31.5 24.0081 25.0081 30.5 17 30.5C8.99187 30.5 2.5 24.0081 2.5 16C2.5 7.99187 8.99187 1.5 17 1.5Z"
          fill="none"
        />
      </g>
      <path d="M17 25C21.9706 25 26 20.9706 26 16C26 11.0294 21.9706 7 17 7C12.0294 7 8 11.0294 8 16C8 20.9706 12.0294 25 17 25Z" />
      <defs>
        <filter
          id="filter0_d_32_791"
          x="0"
          y="1"
          width="34"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_32_791"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_32_791"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
export default RadioActive
