import { TTheme } from "../../types"
import animation from "../../tokens/abrdn/ludlow/animation.json"
import breakpoints from "../../tokens/abrdn/ludlow/breakpoints.json"
import buttons from "../../tokens/abrdn/ludlow/buttons.json"
import colors from "../../tokens/abrdn/ludlow/colors.json"
import iconsizing from "../../tokens/abrdn/ludlow/iconsizing.json"
import overlay from "../../tokens/abrdn/ludlow/overlay.json"
import radius from "../../tokens/abrdn/ludlow/radius.json"
import shadows from "../../tokens/abrdn/ludlow/shadows.json"
import styling from "../../tokens/abrdn/ludlow/styling.json"
import typeface from "../../tokens/abrdn/ludlow/typeface.json"
import typography from "../../tokens/abrdn/ludlow/typography.json"

const tokens = {
  mode: "ABRDN_LUDLOW",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  styling,
  typeface,
  typography,
} as TTheme
export default tokens
