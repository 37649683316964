const CheckboxActive = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="2"
      width="28"
      height="28"
      rx="2.5"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      d="M10 17.5L14.0625 21.5625L23 11"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="2"
      y="2"
      width="28"
      height="28"
      rx="2.5"
      stroke="var(--color-universal-secondary-b)"
    />
  </svg>
)
export default CheckboxActive
