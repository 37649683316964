const NAVMENU_DROPDOWN_LEVEL = {
    BASE: 0,
    PRIMARY: 1
} as const

export {
    NAVMENU_DROPDOWN_LEVEL
}

export const NAVMENU_KEYS = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ENTER: 'Enter',
  SPACE: ' ',
  TAB: 'Tab',
  ESCAPE: 'Escape'
}

export type NavMenuNavigation = {
  firstLevel:
    {
      IsSelected: boolean,
      FocusId: number,
      MaxFocusId: number,
    }
  ,
  secondLevel:
  {
    IsSelected: boolean,
    FocusId: number,
    MaxFocusId: number,
  },
  thirdLevel:
  {
    IsSelected: boolean,
    FocusId: number,
    MaxFocusId: number,
  }
}

export const NAVMENU_NAVIGATION_INITIALSTATE = {
  firstLevel:
  {
    IsSelected: false,
    FocusId: 0,
    MaxFocusId: 2,
  }
  ,
  secondLevel:
  {
    IsSelected: false,
    FocusId: 0,
    MaxFocusId: 0,
  },
  thirdLevel:
  {
    IsSelected: false,
    FocusId: 0,
    MaxFocusId: 0,
  }
}
