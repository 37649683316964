const Notification = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 24C8.31715 24.5772 8.97569 25.0841 9.87704 25.4446C10.7784 25.8051 11.874 26 13 26C14.1259 26 15.2216 25.8051 16.123 25.4446C17.0243 25.0841 17.6829 24.5772 18 24"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      d="M1.48687 19.4992L1.48642 19.4994L1.48605 19.4997L1.4855 19.5L1.48519 19.5002L1 19.7903V20.3575V21V22H2H24H25V21V20.3575V19.8474L24.5878 19.5485L24.5875 19.5483L24.5873 19.5481L24.587 19.5479L24.5839 19.5456L24.5645 19.5313C24.5465 19.5178 24.5184 19.4966 24.4817 19.4683C24.4083 19.4117 24.3009 19.327 24.171 19.2188C23.9097 19.0013 23.5646 18.6953 23.2231 18.3378C22.8784 17.9769 22.5583 17.5853 22.3295 17.1983C22.0952 16.8023 22 16.4844 22 16.2512V8.27139C22 6.02402 20.5033 4.19291 18.7802 2.98251C17.0434 1.7625 14.8466 1.00048 13.0003 1C11.1284 0.999517 8.9287 1.81602 7.20326 3.05443C5.49902 4.27761 4 6.10708 4 8.27139V16.2512C4 16.99 3.50036 17.7688 2.79887 18.4586C2.46842 18.7835 2.13413 19.0482 1.88108 19.232C1.75543 19.3233 1.65206 19.393 1.58221 19.4386C1.54734 19.4613 1.52099 19.478 1.50458 19.4882L1.48767 19.4987L1.48687 19.4992Z"
      stroke="var(--color-universal-secondary-b)"
      strokeWidth="2"
    />
  </svg>
)
export default Notification
