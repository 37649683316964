import { ReactElement } from "react"
import { Comment } from "./Comment"
import DragAndDrop from "./DragAndDrop"
import ErrorIcon from "./ErrorIcon"
import Hamburger from "./Hamburger"
import Info from "./Info"
import { LogoutIcon } from "./LogoutIcon"
import { NotificationsIcon } from "./NotificationsIcon"
import QuestionMarkCircleTransparent from "./QuestionMarkCircleTransparent"
import SuccessIcon from "./SuccessIcon"
import { Tick } from "./Tick"

export const GetIcon = {
  ErrorIcon: <ErrorIcon />,
  Hamburger: <Hamburger />,
  SuccessIcon: <SuccessIcon />,
  QuestionMarkCircleTransparent: <QuestionMarkCircleTransparent />,
  Verified: <SuccessIcon />,
  NotificationsIcon: <NotificationsIcon />,
  Logout: <LogoutIcon />,
  DragAndDrop: <DragAndDrop />,
  Info: <Info />,
  // For COOP we need to use different icon because UX decided to use different icons
  WhiteBell: <Hamburger />,
  WhiteBellActive: <SuccessIcon />,
  Comment: <Comment />,
  Tick: <Tick />,
} as { [key: string]: ReactElement }

export default GetIcon
