/* istanbul ignore file */
import { SET_ORGANISATION } from "./actions"
import { IAction } from "../constants"

export type OrganisationStateItem = {
  id: string
  name: string
}

export interface IOrganisationState {
  organisation?: OrganisationStateItem
}

export interface IOrganisationAction extends IAction {
  payload?: OrganisationStateItem
}

export const initialState = {
  organisation: undefined,
}

export const organisationsReducer = (
  organisationsState: IOrganisationState,
  action: IOrganisationAction
) => {
  switch (action.type) {
    case SET_ORGANISATION:
      return {
        ...organisationsState,
        organisation: action.payload,
      }
    default:
      return organisationsState
  }
}
