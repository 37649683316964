import animation from "../../design-tokens/tokens/schofield-sweeney/animation.json"
import breakpoints from "../../design-tokens/tokens/schofield-sweeney/breakpoints.json"
import buttons from "../../design-tokens/tokens/schofield-sweeney/buttons.json"
import colors from "../../design-tokens/tokens/schofield-sweeney/colors.json"
import iconsizing from "../../design-tokens/tokens/schofield-sweeney/iconsizing.json"
import overlay from "../../design-tokens/tokens/schofield-sweeney/overlay.json"
import radius from "../../design-tokens/tokens/schofield-sweeney/radius.json"
import shadows from "../../design-tokens/tokens/schofield-sweeney/shadows.json"
import typeface from "../../design-tokens/tokens/schofield-sweeney/typeface.json"
import typography from "../../design-tokens/tokens/schofield-sweeney/typography.json"
import { TTheme } from "../types"

const tokens = {
  mode: "SCHOFIELD_SWEENEY",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  typeface,
  typography,
} as TTheme
export default tokens
