import { Navigate } from "react-router-dom"
import { PAGE_PATH } from "../contexts/application/constants"
import { SessionUnavailableModal } from "../component/atoms/SessionTimedOut/SessionUnavailable"
import { LoadingFallBack } from "../component/organism/LoadingFallBack"

const RequireAuth = ({
  loginType,
  showLoginPage,
  isLoading,
}: {
  loginType: string
  showLoginPage: boolean
  isLoading?: boolean
}) => {
  if (loginType === "") {
    return <LoadingFallBack />
  }

  if (showLoginPage) {
    return <Navigate to={PAGE_PATH.LoginPage} replace />
  } else {
    if (isLoading) {
      return <LoadingFallBack />
    }
    return <SessionUnavailableModal />
  }
}

export default RequireAuth
