const FolderIconsPersonalFamily = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.3335 11.4357H22.0607V10.0415C22.0607 7.86993 23.8284 6.10228 26 6.10228H38C40.1715 6.10228 41.9392 7.86993 41.9392 10.0415V11.4357H56.6665C60.3103 11.4357 63.2727 14.3978 63.2727 18.0415V51.375C63.2727 55.0187 60.3103 57.9812 56.6665 57.9812H7.3335C3.68975 57.9812 0.72728 55.0187 0.72728 51.375V18.0415C0.72728 14.3978 3.68969 11.4357 7.3335 11.4357ZM56.6665 13.9812H41.9392V15.375C41.9392 17.5466 40.1715 19.3142 38 19.3142H26C23.8284 19.3142 22.0607 17.5466 22.0607 15.375V13.9812H7.3335C5.09458 13.9812 3.27273 15.8023 3.27273 18.0415V51.375C3.27273 53.614 5.09444 55.4357 7.3335 55.4357H56.6665C58.9056 55.4357 60.7273 53.614 60.7273 51.375V18.0415C60.7273 15.8023 58.9054 13.9812 56.6665 13.9812ZM24.6062 12.695L24.6062 12.7L24.6062 12.7085L24.6062 12.717V15.375C24.6062 16.1448 25.2301 16.7688 26 16.7688H38C38.7698 16.7688 39.3937 16.1448 39.3937 15.375V10.0415C39.3937 9.27252 38.7701 8.64773 38 8.64773H26C25.2298 8.64773 24.6062 9.27252 24.6062 10.0415L24.6062 12.695Z"
    />
    <path d="M48.1818 47.2658H39.3335C38.3901 47.2658 37.6244 46.5001 37.6244 45.5567C37.6244 44.6134 38.3901 43.8476 39.3335 43.8476H48.1818C49.1251 43.8476 49.8909 44.6134 49.8909 45.5567C49.8909 46.5001 49.1251 47.2658 48.1818 47.2658Z" />
    <path d="M54 38.9022H39.3335C38.3901 38.9022 37.6244 38.1364 37.6244 37.1931C37.6244 36.2498 38.3901 35.484 39.3335 35.484H54C54.9433 35.484 55.7091 36.2498 55.7091 37.1931C55.7091 38.1364 54.9433 38.9022 54 38.9022Z" />
    <path d="M54 30.5386H39.3335C38.3901 30.5386 37.6244 29.7728 37.6244 28.8295C37.6244 27.8861 38.3901 27.1204 39.3335 27.1204H54C54.9433 27.1204 55.7091 27.8861 55.7091 28.8295C55.7091 29.7728 54.9433 30.5386 54 30.5386Z" />
    <path d="M30 50.4175C29.0567 50.4175 28.2909 49.6518 28.2909 48.7084V47.3749C28.2909 45.3769 26.6646 43.7505 24.6665 43.7505H15.3335C13.3354 43.7505 11.7091 45.3769 11.7091 47.3749V48.7084C11.7091 49.6518 10.9434 50.4175 10 50.4175C9.05668 50.4175 8.29092 49.6518 8.29092 48.7084V47.3749C8.29092 43.4902 11.4488 40.3323 15.3335 40.3323H24.6665C28.5513 40.3323 31.7091 43.4902 31.7091 47.3749V48.7084C31.7091 49.6518 30.9434 50.4175 30 50.4175Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 37.0841C16.4858 37.0841 13.6244 34.2221 13.6244 30.7085C13.6244 27.1943 16.4859 24.3324 20 24.3324C23.5141 24.3324 26.3756 27.1943 26.3756 30.7085C26.3756 34.2221 23.5141 37.0841 20 37.0841ZM20 27.7506C18.37 27.7506 17.0426 29.0781 17.0426 30.7085C17.0426 32.3384 18.3701 33.6659 20 33.6659C21.6299 33.6659 22.9574 32.3384 22.9574 30.7085C22.9574 29.0781 21.6299 27.7506 20 27.7506Z"
    />
  </svg>
);
export default FolderIconsPersonalFamily;
