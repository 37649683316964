import dragula from "dragula"
import "dragula/dist/dragula.css"
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"

import { IFile } from "../../../../../../api/api-client/api-types"
import {
  deleteFile,
  postUploadAndStitchPages,
  postUploadFileToFolder,
} from "../../../../../../api/lib/node/node"
import { CaseDocument } from "../../../../../../api/lib/workflow/models/GetCaseDocumentResponse"
import {
  CaseDocumentActionId,
  patchUpdateCaseDocument,
  putDocumentToWorkflowCase,
  putWorkflowCaseDocumentAction,
} from "../../../../../../api/lib/workflow/workflow"
import { useGetIcon } from "../../../../../../styled-components/GetIconLibraryInTheme"
import { MaxFileNameLength } from "../../../../../../utils/consts/consts"
import {
  InputFieldType,
  validateInput,
} from "../../../../../../utils/forms/validateInputs"
import { BrowserDefaultSelect } from "../../../../../atoms/BrowserDefaultSelect/BrowserDefaultSelect"
import {
  BUTTON_SIZE,
  BUTTON_VARIANT,
  Button,
} from "../../../../../atoms/Button"
import { InfoBoxMessage } from "../../../../../atoms/InfoBoxMessage/InfoBoxMessage"
import { LoadingSpinner } from "../../../../../atoms/LoadingSpinner/LoadingSpinner"
import { TextInputWithValidation } from "../../../../../atoms/TextInput/TextInputWithValidation"
import AlertMessage, {
  MessageType,
} from "../../../../AlertMessage/AlertMessage"
import { AlertMessageItem } from "../../../../AlertMessage/AlertMessageItemList/AlertMessageItemList"
import { ModalContent, ModalHeader } from "../../../../Modal"
import {
  Option,
  UploadWizardSteps,
} from "../../../../UploadFileToCaseModalWizard/UploadFileToCaseModalWizard"
import {
  getAllAcceptedFileTypesFromExtensions,
  updateDefaultNameForRecentlyCreatedImages,
} from "../../../utils"
import { DisplayDocument } from "../DisplayDocument/DisplayDocument"
import { moveElement } from "./utils/utils"

import { infoBoxMessageText } from "../../../../../atoms/InfoBoxMessage/infoBoxMessageText"
import { alertMessageErrorsText } from "../../../../AlertMessage/alertMessageText"
import "./DocumentNameAndCategory.css"

interface DocumentNameAndCategoryProps {
  workflowId: string
  workflowVersion: string
  caseId?: string | null
  files: IFile[]
  setFiles: Dispatch<SetStateAction<IFile[]>>
  folderId?: string | null
  options: Option[]
  setCurrentStep: Dispatch<SetStateAction<UploadWizardSteps>>
  uploadedDocuments: CaseDocument[]
  setUploadedDocuments: Dispatch<SetStateAction<CaseDocument[]>>
  uploadedViaCategory: boolean
  requestNameFromCategory?: string
  requestIdFromCategory?: number
  stageIdFromCategory?: number
  setUnsavedChanges?: (unsavedChanges: boolean) => void
  onUploadComplete?: () => Promise<void>
  setNewlyUploadedDocuments?: Dispatch<SetStateAction<CaseDocument[]>>
}

const errorIdEnterDocumentId = 0
const errorIdUploadImages = 1
const errorOnlySupportedCharacters = 3

export const DocumentNameAndCategory: FC<DocumentNameAndCategoryProps> = ({
  workflowId,
  workflowVersion,
  caseId,
  files,
  setFiles,
  folderId,
  options,
  setCurrentStep,
  uploadedDocuments,
  setUploadedDocuments,
  uploadedViaCategory = false,
  requestIdFromCategory,
  requestNameFromCategory,
  stageIdFromCategory,
  setUnsavedChanges,
  onUploadComplete,
  setNewlyUploadedDocuments,
}) => {
  const [documentName, setDocumentName] = useState<string>("")
  const [selectedCategory, setSelectedCategory] = useState<string>(
    options[0].value
  )
  const [isStitchingDocument, setIsStichingDocument] = useState(false)
  const [deletingFileId, setDeletingFileId] = useState("")
  const [isUploadingDocument, setIsUploadingDocument] = useState(false)

  const [displayNoDocumentNameError, setDisplayNoDocumentNameError] =
    useState(false)
  const [errors, setErrors] = useState<AlertMessageItem[]>([])
  const [hasApiError, setHasApiError] = useState(false)

  const questionMarkCircleIcon = useGetIcon("QuestionMarkCircleTransparent")

  const [orderedPagesIds, setOrderedPages] = useState<string[]>(
    files.map((file) => file.id)
  )

  const [hasValidFileName, setHasValidFileName] = useState(true)
  const [hasTooLongFileName, setHasTooLongFileName] = useState(false)

  const validateOnUploadAndStichDocument = () => {
    setDisplayNoDocumentNameError(false)
    const currentErrors: AlertMessageItem[] = []

    if (documentName === "") {
      setDisplayNoDocumentNameError(true)
      currentErrors.push({
        id: errorIdEnterDocumentId,
        message: "enter a document name before proceeding to the next step",
        href: "add-page",
      })
    }

    if (files.length === 0) {
      currentErrors.push({
        id: errorIdUploadImages,
        message:
          "upload images of your document before proceeding to the next step",
        href: "add-page",
      })
    }

    const documentNameInputErrors = validateInput({
      type: InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT,
      value: documentName,
    })

    if (documentNameInputErrors.length > 0) {
      currentErrors.push({
        id: errorOnlySupportedCharacters,
        message:
          "use only supported characters for a document name: letters, numbers, dashes, underscores, parentheses and blank spaces",
        href: "add-page",
      })
    }

    setErrors(currentErrors)
    return currentErrors.length === 0
  }

  const removeFile = async (fileId: string) => {
    setHasValidFileName(true)
    setErrors([])
    setHasApiError(false)
    setHasTooLongFileName(false)
    try {
      setDeletingFileId(fileId)
      const response = await deleteFile({ fileId })

      if (response) {
        const newFiles = files.filter((file) => file.id !== fileId)
        setFiles(newFiles)
        const newOrderedPagesIds = orderedPagesIds.filter(
          (pageId) => pageId !== fileId
        )
        setOrderedPages(newOrderedPagesIds)
      } else {
        setHasApiError(true)
      }
    } catch (err) {
      setHasApiError(true)
    } finally {
      setDeletingFileId("")
    }
  }

  const setupDragula = useCallback((componentInstance: any) => {
    if (componentInstance) {
      const drake = dragula([componentInstance], {
        direction: "vertical",
      })

      drake.on("drop", (el, target) => {
        const filesNode = Array.from(target.children)
        setOrderedPages(filesNode.map((fileNode) => fileNode.id))
      })
    }
  }, [])

  const handleAddNewPage = async (event: ChangeEvent<HTMLInputElement>) => {
    setErrors([])
    setHasValidFileName(true)
    setHasTooLongFileName(false)
    setHasApiError(false)
    const filesList = event.target.files
    if (filesList) {
      try {
        if (folderId) {
          setIsUploadingDocument(true)

          // update default file name for images captured, probably, on mobile/tablet devices
          const updatedFile = updateDefaultNameForRecentlyCreatedImages(
            filesList[0]
          )

          if (updatedFile.name.length > MaxFileNameLength) {
            setHasTooLongFileName(true)
            setIsUploadingDocument(false)
            // Clear the input value to allow the same file to be uploaded again
            event.target.value = ""
            return
          }
          const fileNameErrors = validateInput({
            type: InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT,
            value: updatedFile.name,
          })

          if (fileNameErrors.length > 0) {
            setHasValidFileName(false)
            setIsUploadingDocument(false)
            // Clear the input value to allow the same file to be uploaded again
            event.target.value = ""
            return
          }

          const formData = new FormData()
          formData.append("file", updatedFile, updatedFile.name)
          const responseFile = await postUploadFileToFolder({
            folderId,
            formData,
          })
          setFiles([...files, ...responseFile])
          setOrderedPages([...orderedPagesIds, responseFile[0].id])
        } else {
          setHasApiError(true)
        }
      } catch (e) {
        setHasApiError(true)
      } finally {
        setIsUploadingDocument(false)
      }
    }
    // Clear the input value to allow the same file to be uploaded again
    event.target.value = ""
  }

  const handleUploadAndStitchDocument = async () => {
    setHasValidFileName(true)
    setErrors([])
    setHasTooLongFileName(false)
    setHasApiError(false)

    if (documentName !== "") {
      if (folderId && caseId) {
        setIsStichingDocument(true)
        try {
          const file = await postUploadAndStitchPages({
            targetFolderId: folderId,
            sourceFileIds: orderedPagesIds,
          })

          let category = options.find(
            (option) => option.value === selectedCategory
          )

          if (
            uploadedViaCategory &&
            stageIdFromCategory &&
            requestIdFromCategory &&
            requestNameFromCategory
          ) {
            category = {
              stageId: stageIdFromCategory.toString(),
              requestId: requestIdFromCategory.toString(),
              name: requestNameFromCategory,
              value: requestNameFromCategory,
            }
          }

          if (category && category.stageId && category.requestId) {
            const document = await putDocumentToWorkflowCase(
              workflowId,
              workflowVersion,
              caseId,
              category?.stageId,
              category?.requestId,
              file.id
            )

            const patchResponse = await patchUpdateCaseDocument({
              workflowId,
              workflowVersion,
              caseId,
              stageId: document.stageId.toString(),
              requestId: document.requestId.toString(),
              documentId: document.id.toString(),
              uploadedViaCategory: false,
              documentName,
              fileName: documentName,
              targetRequestId: category.requestId,
            })

            await putWorkflowCaseDocumentAction({
              workflowId,
              workflowVersion,
              caseId,
              stageId: patchResponse.stageId.toString(),
              requestId: patchResponse.requestId.toString(),
              documentId: patchResponse.id.toString(),
              actionId: CaseDocumentActionId.Upload,
            })

            setUploadedDocuments([
              ...uploadedDocuments,
              {
                ...patchResponse,
                name: patchResponse.name,
                file: {
                  ...patchResponse.file,
                  id: patchResponse.file?.id ?? file.id,
                  fileName: documentName,
                  name: documentName,
                  hasPreview: patchResponse.file?.hasPreview ?? false,
                  hasThumbnail: patchResponse.file?.hasThumbnail ?? false,
                },
              },
            ])
            onUploadComplete && (await onUploadComplete())
            setUnsavedChanges?.(false)
            setNewlyUploadedDocuments?.((previousUploadedDocuments) => [
              ...previousUploadedDocuments,
              patchResponse,
            ])
            setCurrentStep(UploadWizardSteps.ViewUploadedDocuments)
          } else {
            setHasApiError(true)
          }
        } catch (e) {
          setHasApiError(true)
        } finally {
          setIsStichingDocument(false)
        }
      } else {
        setHasApiError(true)
      }
    }
  }

  const getDocumentNameErrorMessage = () => {
    if (displayNoDocumentNameError) {
      return {
        hasError: true,
        message: "Required",
      }
    }

    if (errors.find((error) => error.id === errorOnlySupportedCharacters)) {
      return {
        hasError: true,
        message: "",
      }
    }
    return undefined
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement as HTMLElement
      const isActiveElementForPageStitching =
        activeElement.classList.contains("display-document")

      if (!isActiveElementForPageStitching) return

      // direction 1 is down, -1 is up
      switch (event.key) {
        case "ArrowUp":
          moveElement(activeElement, -1, setOrderedPages)
          break
        case "ArrowDown":
          moveElement(activeElement, 1, setOrderedPages)
          break
        default:
          break
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [orderedPagesIds])

  return (
    <>
      <ModalHeader>
        Add document name {!uploadedViaCategory ? "and choose category" : ""}
      </ModalHeader>
      <ModalContent>
        {errors.length > 0 && (
          <AlertMessage
            className="document-name-and-category-error"
            messageType={MessageType.ERROR}
            title="There's a problem"
            message={"Check the form. You must:"}
            alertItems={errors}
          />
        )}
        {hasApiError && (
          <AlertMessage
            className="document-name-and-category-error"
            messageType={MessageType.ERROR}
            title="There's a problem"
            message={alertMessageErrorsText.generic}
          />
        )}
        {!hasValidFileName && (
          <>
            <AlertMessage
              className="document-name-and-category-error"
              messageType={MessageType.ERROR}
              title="There's a problem"
              message={alertMessageErrorsText.fileName}
            />
          </>
        )}
        {hasTooLongFileName && (
          <>
            <AlertMessage
              className="document-name-and-category-error"
              messageType={MessageType.ERROR}
              title="There's a problem"
              message={alertMessageErrorsText.fileNameLength}
            />
          </>
        )}
        <div className="document-name-and-category-file-text">
          Update the order of pages
        </div>
        <div ref={setupDragula}>
          {files.map((file) => (
            <DisplayDocument
              key={file.id}
              file={file}
              removeFile={removeFile}
              shouldDragAndDrop
              isRemovingFile={deletingFileId === file.id}
            />
          ))}
          {isUploadingDocument && (
            <div className="flex justify-content-center">
              <LoadingSpinner size="24px" />
            </div>
          )}
        </div>
        <input
          className="document-name-and-category-file-upload"
          type="file"
          id="add-page"
          accept={getAllAcceptedFileTypesFromExtensions([
            ".pdf",
            ".jpeg",
            ".jpg",
            ".png",
          ])}
          onChange={handleAddNewPage}
        />
        <label
          className="document-name-and-category-add-page"
          htmlFor="add-page"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key.toLowerCase() === "enter") {
              setHasValidFileName(true)
              setErrors([])
              setHasApiError(false)
              document.getElementById("add-page")?.click()
            }
          }}
        >
          Add another page
        </label>
        <hr />
        <div className="upload-multi-page-step-two">
          <TextInputWithValidation
            id="document-name"
            value={documentName}
            label="Document name"
            isRequired
            type={InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT}
            name={"document-name"}
            maxLength={MaxFileNameLength}
            validateOnSubmit
            errorOnSubmit={getDocumentNameErrorMessage()}
            onChange={(newValue: string) => setDocumentName(newValue)}
            shouldShowWarningIcon={false}
          />
          <div className="mb-5 mt-2">
            <InfoBoxMessage message={infoBoxMessageText.documentName} />
          </div>

          {!uploadedViaCategory && (
            <BrowserDefaultSelect
              label="Document category"
              name="document-category"
              isRequired
              options={options}
              onChange={(newValue: string) => setSelectedCategory(newValue)}
            />
          )}
        </div>
        <div className="document-name-and-category-text">
          <div className="icon">{questionMarkCircleIcon}</div>
          <div>
            When ready tap{" "}
            <span className="inner">"Combine and create document".</span> This
            will combine multiple images into one document.
          </div>
        </div>
        <Button
          variant={BUTTON_VARIANT.PRIMARY}
          size={BUTTON_SIZE.LARGE}
          onClick={() => {
            const isValid = validateOnUploadAndStichDocument()
            if (isValid) {
              handleUploadAndStitchDocument()
            }
          }}
        >
          {!isStitchingDocument && <span>Combine and create</span>}
          {isStitchingDocument && (
            <div className="document-name-and-category-processing-file">
              <span className="mr-1">Processing file...</span>
              <LoadingSpinner
                size="20px"
                thickness="2px"
                color="var(--color-universal-secondary-e)"
              />
            </div>
          )}
        </Button>
      </ModalContent>
    </>
  )
}
