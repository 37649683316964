import "./LoaderCenteredOnTop.css"
import { useApplicationContext } from "../../../contexts/application/context"
import { setUploadingState } from "../../../contexts/application/action"
import { FC, useEffect } from "react"
import { IFile } from "../../../api/api-client/api-types"
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner"

export interface LoaderCenteredOnTopProps {
  file: IFile
}

export const LoaderInCenterOfElement: FC<LoaderCenteredOnTopProps> = ({
  file,
}) => {
  const { dispatch } = useApplicationContext()
  useEffect(() => {
    if (!file.created) {
      dispatch(setUploadingState(true))
    } else {
      dispatch(setUploadingState(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.created])

  return (
    <div className="loader-in-file-card">
      <LoadingSpinner
        color="var(--color-universal-secondary-c)"
        size="50px"
        thickness="5px"
      />
    </div>
  )
}

export const LoaderCenteredOnTop = () => {
  return (
    <div className="loader-over-page">
      <LoadingSpinner
        color="var(--color-universal-secondary-c)"
        size="50px"
        thickness="5px"
      />
    </div>
  )
}
