import { FC } from "react"
import "./Radio.css"

export interface RadioProps {
  ariaLabelledBy?: string
  className?: string
  /**
   * Toggles whether or not the component is marked as checked.
   */
  isChecked?: boolean
  /**
   * Toggles whether the component is disabled or not (preventing user interaction).
   */
  isDisabled?: boolean
  /**
   * Label to display to the right of the component.
   */
  label?: string
  /**
   * HTML `name` attribute associated with the component.
   */
  name: string
  /**
   * Optional handler to be invoked when the value of the component changes.
   */
  onChange?: (selectedOption: string) => void
  /**
   * Optional handler to be invoked when the blur event is emitted.
   */
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * Optional HTML `value` attribute associated with the component.
   */
  value?: string
  /**
   * Optional click handler, not to be used to handle change events
   */
  onClick?: () => void
  /**
   * Optional id for the radio button
   */
  id?: string
}

export const Radio: FC<RadioProps> = (props) => {
  const {
    ariaLabelledBy,
    isChecked,
    isDisabled = false,
    name,
    onChange,
    onBlur,
    onClick,
    value,
    className,
    id,
  } = props
  return (
    <div className={className ? className : ""}>
      <div className="radio-container">
        <input
          id={id}
          className="radio-input"
          checked={isChecked}
          type="radio"
          name={name}
          value={value}
          onChange={(newValue) => {
            if (onChange) {
              onChange(newValue.target.value)
            }
          }}
          onBlur={onBlur}
          disabled={isDisabled}
          onClick={onClick}
          aria-labelledby={ariaLabelledBy}
        />
      </div>
    </div>
  )
}

Radio.displayName = "Radio"
