import {
  IFolder,
  IFile,
  INodeShare,
  IContact,
  IContactInvite,
} from "../../api/api-client/api-types"

import {
  postNodeSharesForContact,
  getNodesSharedWithContact,
  getOrgContacts,
  getUserContacts,
} from "../../api/api-client/api-handler"

import {
  setLoadingState,
  addOrReplaceContactsShares,
  setSharesAllContacts,
} from "../../contexts/application/action"
import {
  convertAppToApiNodeShares,
  convertContactDtoToIContact,
} from "../../api/api-client/convert"
import { showSuccessOrErrorMessage } from "../../component/modules/NotificationPopUp"
import { ApiController } from "../../api/apiController"
import { sendGaData } from "../gaEvents"
import getFoldersWithinSharedSection from "./getFoldersWithinSharedSection"
import { flattenFolderTree } from "../../api/api-client/folder/flattenFolderTree"
import { UserRoles } from "../../contexts/application/constants"
import { Dispatch } from "react"
import { IToastAction } from "../../contexts/toasts/reducer"

export const neverReadonlyEdit = ["Never", "Read only", "Edit"]

export const editFolderTooltip =
  "This contact will be able to manage the contents of any folder chosen. This includes the ability to edit the names of files and to delete. Along with the ability to upload files. "
export const editFileTooltip =
  "Contact will have the ability to edit the name of file and remove the file."
export const readOnlyFileTooltip =
  "Contact will only have the ability to view and download a copy of shared files."
export const readOnlyFolderTooltip =
  "This contact will only be able to view the contents of any folder chosen. "

export type TRadioOptions = {
  group: string
  label: string
  labelDescription?: string
  option: string
  nodeId: string
  contactId: string
  disabledOptions?: string[]
  removedOptions?: string[]
}

export type contactFolderAccessPermission = {
  [contactId: string]: TRadioOptions[]
}

export const SharePermsToOption = (share?: INodeShare) => {
  if (!share) {
    return "Read only"
  }
  if (share.canEdit) {
    return "Edit"
  }
  if (share.canRead) {
    return "Read only"
  }

  return "Never"
}

export const OptionToSharePerms = (option: string, share: INodeShare) => {
  if (option === "Edit") {
    share.canEdit = true
    share.canRead = false
  } else if (option === "Read only") {
    share.canEdit = false
    share.canRead = true
  } else {
    share.canEdit = false
    share.canRead = false
  }
  return share
}

//there are only 2 contact types in the api
//User
//Company
export const RelationshipToContactType = (relationship: string) => {
  switch (relationship) {
    case UserRoles.Adviser:
    case UserRoles.CorporateAdmin:
    case UserRoles.Admin:
    case UserRoles.LegadoAdmin:
    case UserRoles.PowerAdviser: {
      return "Company"
    }
    default: {
      return "User"
    }
  }
}

export const getDefaultFolders = (folders: IFolder[]) => {
  const defaultRadioOptions: TRadioOptions[] = []
  folders.forEach((folder) => {
    folder.childFolders?.forEach((child) => {
      const editDisabled =
        child.folderType === "ConnectedFolder" ||
        child.folderType === "PlatformFolder"
      defaultRadioOptions.push({
        group: folder.name,
        label: child.name,
        option: "Never",
        nodeId: child.id,
        contactId: "",
        ...(editDisabled && { disabledOptions: ["Edit"] }),
      })
    })
  })
  return defaultRadioOptions
}

export const getDocumentOptionsForSharing = (
  nodes: IFolder[] | IFile[],
  isAddingContactThroughWorkflowDocument?: boolean
) => {
  // if contact is being added through workflow document, the group name should be "My document" and the option should be "Read only"
  // this is because the user is sharing a single document,
  // set to read only as the user already decided to share the document
  const groupName = isAddingContactThroughWorkflowDocument
    ? "My document"
    : "My documents"
  const option = isAddingContactThroughWorkflowDocument ? "Read only" : "Never"
  return nodes.map((node) => {
    return {
      group: groupName,
      label: node.name,
      option: option,
      nodeId: node.id,
      contactId: "",
      removedOptions: ["Edit"],
    } as TRadioOptions
  })
}

const optionToNodeShare = ({
  option,
  contact,
}: {
  option: TRadioOptions
  contact: IContact
}) => {
  const newNodeShare = {
    nodeId: option.nodeId,
    name: contact.name!,
    contactType: contact.contactType || "User",
    contactId: contact.contactId,
    canRead: false,
    canEdit: false,
    sharedAfterDeath: false,
  } as INodeShare

  return OptionToSharePerms(option.option, newNodeShare)
}

export const sharesOptionsToINodeShare = ({
  contactShares,
  sharingOptions,
  contact,
}: {
  contactShares?: INodeShare[]
  sharingOptions: TRadioOptions[]
  contact: IContact
}) => {
  if (!contactShares) {
    return sharingOptions.map((o) => optionToNodeShare({ option: o, contact }))
  }

  const newShares = [] as INodeShare[]
  sharingOptions.forEach((o) => {
    const contactShareOld = contactShares?.find((s) => {
      if (s.nodeId === o.nodeId && s.contactId === o.contactId) {
        return true
      }
      return false
    })
    if (!contactShareOld && o.option === "Never") {
      return
    }

    const newINodeShare = optionToNodeShare({ option: o, contact })

    if (
      contactShareOld &&
      contactShareOld.canRead === newINodeShare.canRead &&
      contactShareOld.canEdit === newINodeShare.canEdit
    ) {
      return
    }

    newShares.push(newINodeShare)
  })

  return newShares
}

export type TSaveContactAccessToFoldersAndFilesPermissions = {
  contactShares?: INodeShare[]
  sharingOptions: TRadioOptions[]
  contact: IContact
  dispatch: (value: any) => void
  toastDispatch: (value: any) => void
  closeModal?: () => void
}

export const saveContactAccessToFoldersAndFilesPermissions = async ({
  contactShares,
  sharingOptions,
  contact,
  dispatch,
  toastDispatch,
  closeModal,
}: TSaveContactAccessToFoldersAndFilesPermissions) => {
  const newContactShares = sharesOptionsToINodeShare({
    contactShares,
    sharingOptions,
    contact,
  })

  if (!newContactShares) {
    return []
  }

  dispatch(setLoadingState(true))

  sendGaData({ name: "cm_permissions_saved" })

  const response = await postNodeSharesForContact(
    contact.contactId!,
    newContactShares
  )

  sendGaData({
    name: "cm_permissions_saved_response",
    request_status: response ? "success" : "error",
  })

  showSuccessOrErrorMessage({
    hasError: !response,
    methodName: "postNodeSharesForNode",
    toastDispatch,
  })

  //TO DO: Handle date shared!
  if (response) {
    dispatch(addOrReplaceContactsShares(newContactShares))
  }

  dispatch(setLoadingState(false))

  if (closeModal) {
    closeModal()
  }

  return response ? newContactShares : []
}

export const getFolderOptions = ({
  folders,
  contactShares,
  contactId,
}: {
  folders: IFolder[]
  contactShares?: INodeShare[]
  contactId?: string
}) => {
  const defaultFolderOptions = getDefaultFolders(folders)
  const foldersInSharedSection = getFoldersWithinSharedSection(
    contactShares,
    folders,
    contactId
  )
  const connectedFolders = flattenFolderTree(folders).filter(
    (f) =>
      f.folderType === "ConnectedFolder" || f.folderType === "PlatformFolder"
  )

  const folderOptions = defaultFolderOptions.map((folderOption) => {
    const folderContactShare = contactShares?.find(
      (share) => share.nodeId === folderOption.nodeId
    )
    if (folderContactShare) {
      const disabledOptions = foldersInSharedSection.find(
        (f) => f.id === folderOption.nodeId
      )
        ? ["Never", "Read only", "Edit"]
        : connectedFolders.find((f) => f.id === folderOption.nodeId)
        ? ["Edit"]
        : []
      const selectedOption = SharePermsToOption(folderContactShare)
      return {
        ...folderOption,
        option: selectedOption,
        contactId: folderContactShare.contactId,
        disabledOptions,
      } as TRadioOptions
    }

    return folderOption
  })

  return folderOptions
}

export const getSelectedContactShares = ({
  contactsShares,
  selectedContactId,
}: {
  contactsShares?: INodeShare[]
  selectedContactId: string
}) => {
  if (!contactsShares) {
    return
  }
  return contactsShares.filter((share) => {
    if (share.contactId === selectedContactId) {
      return true
    }
    return false
  })
}

export const getContactShares = ({
  shares,
  contact,
}: {
  shares: INodeShare[]
  contact: IContact
}) => {
  return shares.filter((s) => {
    if (s.contactId === contact.contactId) {
      return true
    }
    return false
  })
}

export const getSharingGroups = (sharingOptions: TRadioOptions[]) => {
  const groups = {} as { [groupname: string]: TRadioOptions[] }
  sharingOptions.forEach((o) => {
    groups[o.group] = groups[o.group] || []
    groups[o.group].push(o)
  })
  return groups
}

export const userSharesToShareOptions = ({
  userShares,
  contacts,
  node,
  folders,
}: {
  userShares: INodeShare[]
  contacts: IContact[]
  node: IFolder | IFile
  folders: IFolder[]
}) => {
  return contacts.map((contact) => {
    const userShare = userShares.find((s) => {
      if (s.nodeId === node.id && s.contactId === contact.contactId) {
        return true
      }
      return false
    })

    const foldersInSharedSection = getFoldersWithinSharedSection(
      userShares,
      folders,
      contact.contactId
    )
    const connectedFolders = flattenFolderTree(folders).filter(
      (f) =>
        f.folderType === "ConnectedFolder" || f.folderType === "PlatformFolder"
    )
    const disabledOptions = foldersInSharedSection.find(
      (f) => f.id === node.id || f.id === node.parentId
    )
      ? ["Never", "Read only", "Edit"]
      : connectedFolders.find((f) => f.id === node.id)
      ? ["Edit"]
      : []

    return {
      group: "Share with",
      label: contact.name,
      labelDescription: contact.relationship,
      option: userShare ? SharePermsToOption(userShare) : "Never",
      nodeId: node.id,
      contactId: contact.contactId,
      disabledOptions,
    } as TRadioOptions
  })
}

export const postPermissionsandClose = async ({
  nodeId,
  newShares,
  dispatch,
  toastDispatch,
  onClose,
}: {
  nodeId: string
  newShares: INodeShare[]
  dispatch: (value: any) => void
  toastDispatch: (value: any) => void
  onClose?: () => void
}) => {
  dispatch(setLoadingState(true))

  const api = ApiController.getInstance()

  const newSharesDto = convertAppToApiNodeShares(newShares)

  const response = await api.postNodeSharesForNode(nodeId, newSharesDto)

  if (response) {
    dispatch(addOrReplaceContactsShares(newShares))
  }

  showSuccessOrErrorMessage({
    hasError: !response,
    methodName: "postNodeSharesForNode",
    toastDispatch,
  })
  dispatch(setLoadingState(false))
  if (onClose) {
    onClose()
  }
}

/*
call api function addContact,
if successful, convert the response ContactDto to IContact

convert sharingOptions to INodeShare and NodeShareDto
call postNodeSharesForContact with NodeShareDto
if success return IContact and INodeShare[]

*/

export const addContactApi = async ({
  contact,
  sharingOptions,
  selectedSharingWithMeUserId,
}: {
  contact: IContactInvite
  sharingOptions: TRadioOptions[]
  selectedSharingWithMeUserId?: string
}) => {
  const apiController = ApiController.getInstance()

  const newContactDto = await apiController.addContact(
    contact,
    selectedSharingWithMeUserId
  )

  if (newContactDto && newContactDto.contactId) {
    const newContactConverted = convertContactDtoToIContact(newContactDto)

    if (newContactConverted) {
      const newContactShares = sharesOptionsToINodeShare({
        contactShares: [],
        sharingOptions,
        contact: newContactConverted,
      })

      if (newContactShares.length === 0) {
        return {
          contact: newContactConverted,
          nodeShares: [],
        }
      }

      if (newContactShares.length > 0) {
        const response = await postNodeSharesForContact(
          newContactConverted.contactId,
          newContactShares
        )
        if (response) {
          return {
            contact: newContactConverted,
            nodeShares: newContactShares,
          }
        }
      }
    }
  }
}

export const getSharedFoldersAndFiles = async (
  dispatch: (value: any) => void,
  toastDispatch: Dispatch<IToastAction>
) => {
  const userOrgs = await getOrgContacts()
  const userContacts = await getUserContacts()

  const allContacts = [...userOrgs, ...userContacts]

  if (allContacts && allContacts.length > 0) {
    try {
      const sharesPromises = allContacts.map((contact) => {
        return getNodesSharedWithContact({ contactId: contact.contactId! })
      })
      const allShares = (await Promise.all(sharesPromises))
        .flat()
        .filter((s) => s !== undefined) as INodeShare[]
      dispatch(setSharesAllContacts(allShares))
    } catch (error) {
      showSuccessOrErrorMessage({
        hasError: true,
        methodName: "getNodeShares",
        toastDispatch,
      })
      console.error(error)
    }
  }
}

export const getAllSelectedValue = (sharingOptions: TRadioOptions[]) => {
  const firstOptionToCompare = sharingOptions[0]
  const allSelected = sharingOptions.every(
    (option) =>
      firstOptionToCompare.option === option.option ||
      option.disabledOptions?.includes(firstOptionToCompare.option)
  )
  if (allSelected) return firstOptionToCompare
}

export const getDisabledSelectAlls = (
  availableOptions: string[],
  sharingOptions: TRadioOptions[]
) => {
  const disabledSelectAlls = availableOptions.filter((option) =>
    sharingOptions.every((sharingOption) =>
      sharingOption.disabledOptions?.includes(option)
    )
  )
  return disabledSelectAlls
}

export const getTooltipMessage = (option: string, isFile: boolean) => {
  if (option === "Edit") return isFile ? editFileTooltip : editFolderTooltip
  else if (option === "Read only")
    return isFile ? readOnlyFileTooltip : readOnlyFolderTooltip
}
