import { FC } from "react"
import {
  getSharingGroups,
  getTooltipMessage,
  neverReadonlyEdit,
  TRadioOptions,
} from "../../utils/shares"
import { getAllSelectedValue, getDisabledSelectAlls } from "../../utils/shares"
import { SelectAllPermissions } from "./SelectAllPermissions"
import { SharingPermissions } from "./SharingPermissions"

import "./SharingPermissions.css"

export type SharingPermissionsContactProps = {
  sharingOptions: TRadioOptions[]
  setSharingOptions: (sharingOptions: TRadioOptions[]) => void
  showImportedFolderWarning?: () => void
  isAddingContactThroughWorkflowDocument?: boolean
}

export const SharingPermissionsContact: FC<SharingPermissionsContactProps> = ({
  sharingOptions,
  setSharingOptions,
  showImportedFolderWarning,
  isAddingContactThroughWorkflowDocument = false,
}) => {
  const groups = getSharingGroups(sharingOptions)
  const hasOnlyOneGroup = Object.keys(groups).length === 1

  const onSelectAll = (selectedOption: string) => {
    setSharingOptions(
      sharingOptions.map((sharingOption) => ({
        ...sharingOption,
        ...(!sharingOption.disabledOptions?.includes(selectedOption) && {
          option: selectedOption,
        }),
      }))
    )
  }

  const updateOptions = (updatedOptions: TRadioOptions[]) => {
    const newOptions = sharingOptions.map((option) => {
      const newOption = updatedOptions.find(
        (updatedOption) => updatedOption.nodeId === option.nodeId
      )
      return newOption ?? option
    })
    setSharingOptions(newOptions)
  }

  const allSelected = getAllSelectedValue(sharingOptions)
  const disabledSelectAlls = getDisabledSelectAlls(
    neverReadonlyEdit,
    sharingOptions
  )

  return (
    <div>
      {!hasOnlyOneGroup && (
        <SelectAllPermissions
          className="select-all-group"
          checkedOption={allSelected?.option}
          disabledOptions={disabledSelectAlls}
          groupName="contact-select-all"
          onChange={onSelectAll}
          getTooltipMessage={getTooltipMessage}
          isAddingContactThroughWorkflowDocument={
            isAddingContactThroughWorkflowDocument
          }
        />
      )}
      {Object.keys(groups).map((groupname) => {
        const groupShares = groups[groupname]
        return (
          <SharingPermissions
            key={groupname}
            sharingOptions={groupShares}
            setSharingOptions={updateOptions}
            showImportedFolderWarning={showImportedFolderWarning}
            isAddingContactThroughWorkflowDocument={
              isAddingContactThroughWorkflowDocument
            }
          />
        )
      })}
    </div>
  )
}
