import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"

import { getPreviewDocumentPath } from "../../../api/api-client/api-handler"
import { IFile } from "../../../api/api-client/api-types"
import { ApiController } from "../../../api/apiController"
import { CaseDocument } from "../../../api/lib/workflow/models/GetCaseDocumentResponse"
import {
  CaseDocumentActionId,
  patchUpdateCaseDocument,
  putDocumentToWorkflowCase,
  putWorkflowCaseDocumentAction,
} from "../../../api/lib/workflow/workflow"
import { useUserContext } from "../../../contexts/users"
import {
  InputFieldType,
  validateInput,
} from "../../../utils/forms/validateInputs"
import ViewSDKClient from "../../PdfReader/ViewSDKClient"
import { BUTTON_VARIANT, Button } from "../../atoms/Button"
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner"
import { UploadedFileDetails } from "../../organism/UploadedFileDetails/UploadedFileDetails"
import AlertMessage, { MessageType } from "../AlertMessage/AlertMessage"
import { AlertMessageItem } from "../AlertMessage/AlertMessageItemList/AlertMessageItemList"
import { alertMessageErrorsText } from "../AlertMessage/alertMessageText"
import { ModalContent, ModalHeader } from "../Modal"
import {
  Option,
  UploadWizardSteps,
  UploadedDocument,
} from "../UploadFileToCaseModalWizard/UploadFileToCaseModalWizard"
import "./AddDocumentDetailsForUploadedFile.css"

interface AddDocumentDetailsForUploadedFileProps {
  requestDropdownOptions: Option[]
  uploadedViaCategory: boolean
  workflowId: string
  workflowVersion: string
  caseId: string
  setCurrentStep: (step: UploadWizardSteps) => void
  documentsToUpload: CaseDocument[]
  setDocumentsToUpload: (documents: CaseDocument[]) => void
  uploadedDocuments: CaseDocument[]
  setUploadedDocuments: Dispatch<SetStateAction<CaseDocument[]>>
  stageIdFromCategory?: number
  requestIdFromCategory?: number
  requestNameFromCategory?: string
  updatePageFunction?: () => Promise<void>
  setUnsavedChanges?: (unsavedChanges: boolean) => void
  setNewlyUploadedDocuments?: Dispatch<SetStateAction<CaseDocument[]>>
}

export interface FormData {
  [key: string]: {
    id: string
    name: string
    category: { stageId: string; requestId: string }
    base64ThumbnailString: string
    fileExtension: string
  }
}

export enum DocumentDetailsErrorMessages {
  EMPTY_NAME = "make sure a document name has been entered",
  EMPTY_CATEGORY = "choose a document category",
}

export const AddDocumentDetailsForUploadedFile: FC<
  AddDocumentDetailsForUploadedFileProps
> = ({
  documentsToUpload,
  uploadedDocuments,
  requestDropdownOptions,
  uploadedViaCategory,
  workflowId,
  workflowVersion,
  caseId,
  setDocumentsToUpload,
  setUploadedDocuments,
  setCurrentStep,
  stageIdFromCategory,
  requestIdFromCategory,
  requestNameFromCategory,
  updatePageFunction,
  setUnsavedChanges,
  setNewlyUploadedDocuments,
}) => {
  const {
    userState: { currentUser },
  } = useUserContext()

  const getInitialFormData = () => {
    const initialFormData: UploadedDocument[] = []

    if (documentsToUpload.length > 0) {
      documentsToUpload.forEach((document: CaseDocument) => {
        initialFormData.push({
          fileId: document.file?.id ?? null,
          name: document.file?.name ?? "",
          category: {
            stageId:
              uploadedViaCategory &&
              stageIdFromCategory !== null &&
              stageIdFromCategory !== undefined // !== null because 0 is falsey
                ? stageIdFromCategory.toString()
                : "",
            requestId:
              uploadedViaCategory &&
              requestIdFromCategory !== null &&
              requestIdFromCategory !== undefined // !== null because 0 is falsey
                ? requestIdFromCategory.toString()
                : "",
            name:
              uploadedViaCategory && requestNameFromCategory
                ? requestNameFromCategory
                : "",
          },
          base64ThumbnailString: "",
          fileExtension: document.file?.extension
            ? document.file.extension
            : "",
        })
      })
    }

    return initialFormData
  }

  const [formData, setFormData] = useState<UploadedDocument[]>(
    getInitialFormData()
  )
  const [formErrors, setFormErrors] = useState<AlertMessageItem[] | undefined>(
    undefined
  )
  const [unknowApiError, setUnknownApiError] = useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [fileSelectedForPreview, setfileSelectedForPreview] = useState<IFile>()
  const [previewLoading, setPreviewLoading] = useState<boolean>(false)
  const [filePreview, setFilePreview] = useState<string>()

  const checkValidity = () => {
    setFormErrors([])
    let newErrors: AlertMessageItem[] = []

    formData.forEach((file) => {
      //Validate document name
      const documentNameInputErrors = validateInput({
        type: InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT,
        value: file.name,
      })
      if (documentNameInputErrors.length > 0 || file.name.length === 0) {
        newErrors.push({
          id: newErrors.length + 1,
          message:
            file.name.length === 0
              ? DocumentDetailsErrorMessages.EMPTY_NAME
              : "enter a valid document name",
          href: `${file.name
            .split(".")
            .slice(0, -1)
            .join(".")
            .replace(/\s+/g, "-")}-name`,
        })
      }

      //Validate document category
      if (
        file.category.name === "" &&
        (!requestIdFromCategory || requestIdFromCategory === 0)
      ) {
        newErrors.push({
          id: newErrors.length + 1,
          message: DocumentDetailsErrorMessages.EMPTY_CATEGORY,
          href: `${file.name
            .split(".")
            .slice(0, -1)
            .join(".")
            .replace(/\s+/g, "-")}-category`,
        })
      }
    })
    setFormErrors(newErrors)
    return newErrors
  }

  const updateDetails = async (
    stageId: string,
    requestId: string,
    documentId: string,
    documentName: string,
    fileName: string,
    newTargetRequestId: string
  ) => {
    try {
      //Attach document onto a request within a stage
      const newDocument = await patchUpdateCaseDocument({
        workflowId,
        workflowVersion,
        caseId,
        stageId,
        requestId,
        documentId,
        uploadedViaCategory: true,
        documentName,
        fileName,
        targetRequestId: newTargetRequestId,
      })

      return newDocument
    } catch (error) {
      setUnknownApiError(true)
    }
  }

  const attachFileToDocumentRequest = async (
    stageId: string,
    requestId: string,
    fileId: string,
    documentName: string,
    targetRequestId: string,
    documentAlreadyAttachedToCase?: CaseDocument
  ) => {
    try {
      // only attach document if it has not already been attached
      if (!documentAlreadyAttachedToCase) {
        //Attach document onto a request within a stage
        const response = await putDocumentToWorkflowCase(
          workflowId,
          workflowVersion,
          caseId,
          stageId,
          requestId,
          fileId
        )

        // change document status to uploaded
        try {
          if (
            response &&
            currentUser?.userId &&
            response.stageId &&
            response.requestId
          ) {
            await putWorkflowCaseDocumentAction({
              workflowId,
              workflowVersion,
              caseId,
              stageId: response.stageId.toString(),
              requestId: response.requestId.toString(),
              documentId: response.id.toString(),
              actionId: CaseDocumentActionId.Upload,
            })
          } else {
            console.log(
              "current user not found, or document not set to a request"
            )
          }
        } catch (error) {
          setUnknownApiError(true)
        }

        // update name and request
        const updatedDocument = await updateDetails(
          stageId,
          requestId,
          response.id.toString(),
          documentName,
          response.file?.fileName ?? response.fileName ?? "",
          requestId
        )

        return updatedDocument
      }
      // otherwise update the document
      else {
        // only update name and request
        const updatedDocument = await updateDetails(
          stageId,
          requestId,
          documentAlreadyAttachedToCase.id.toString(),
          documentName,
          documentAlreadyAttachedToCase.file?.fileName ??
            documentAlreadyAttachedToCase.fileName ??
            "",
          targetRequestId
        )
        return updatedDocument
      }
    } catch (error) {
      setUnknownApiError(true)
    }
  }

  const onSubmit = async () => {
    if (isSubmitting) return
    const errors = checkValidity()
    if (errors && errors.length > 0) {
      return
    } else {
      // On clicking change request only a single document will be shown, so we need to check if the document has already been uploaded
      const alreadyUploadedDocuments = uploadedDocuments.filter(
        (uploadedDocument) =>
          documentsToUpload.find(
            (document) => document.file?.id === uploadedDocument.file?.id
          )
      )

      if (alreadyUploadedDocuments.length === 0) {
        const uploadedDocuments: CaseDocument[] = []
        for (let i = 0; i < formData.length; i++) {
          setIsSubmitting(true)
          const uploadedDocument = await attachFileToDocumentRequest(
            formData[i].category.stageId,
            formData[i].category.requestId,
            formData[i].fileId!,
            formData[i].name,
            formData[i].category.requestId
          )
          if (uploadedDocument) {
            uploadedDocuments.push(uploadedDocument)
          }
        }

        //update with previously uploaded documents
        uploadedDocuments.forEach((uploadedDocument) => {
          const index = uploadedDocuments.findIndex(
            (uploadedFile) =>
              uploadedFile.file?.id === uploadedDocument.file?.id
          )
          if (index === -1) {
            uploadedDocuments.push(uploadedDocument)
          }
        })

        setUploadedDocuments(uploadedDocuments)

        if (setNewlyUploadedDocuments) {
          setNewlyUploadedDocuments(
            (previousUploadedDocuments: CaseDocument[]) => [
              ...previousUploadedDocuments,
              ...uploadedDocuments,
            ]
          )
        }
      } else {
        // Since the document has already been uploaded, this means it must be a change
        // In this case only one document should be present in the modal
        const newDocumentData = await attachFileToDocumentRequest(
          alreadyUploadedDocuments[0].stageId.toString(),
          alreadyUploadedDocuments[0].requestId.toString(),
          formData[0].fileId!,
          formData[0].name,
          formData[0].category.requestId,
          alreadyUploadedDocuments[0]
        )

        if (newDocumentData) {
          //update with previously uploaded documents
          setUploadedDocuments((prevUploadedDocuments: CaseDocument[]) => {
            const newState = prevUploadedDocuments.map(
              (uploadedDocument: CaseDocument) => {
                if (
                  uploadedDocument.file?.id &&
                  uploadedDocument.file?.id === newDocumentData.file?.id
                ) {
                  return newDocumentData
                }
                return uploadedDocument
              }
            )

            //update newly uploaded documents
            if (setNewlyUploadedDocuments) {
              setNewlyUploadedDocuments(
                (previousUploadedDocuments: CaseDocument[]) => {
                  const newUploadedDocuments = previousUploadedDocuments.map(
                    (uploadedDocument: CaseDocument) => {
                      if (
                        uploadedDocument.file?.id &&
                        uploadedDocument.file?.id === newDocumentData.file?.id
                      ) {
                        return newDocumentData
                      }
                      return uploadedDocument
                    }
                  )
                  return newUploadedDocuments
                }
              )
            }

            return newState
          })
        }
      }

      setUnsavedChanges?.(false)

      if (updatePageFunction) {
        await updatePageFunction()
      }

      setCurrentStep(UploadWizardSteps.ViewUploadedDocuments)
      setIsSubmitting(false)
      setDocumentsToUpload([])
    }
  }

  useEffect(() => {
    const getSrc = async (file: IFile) => {
      if (file.id && file.mimeType) {
        const api = ApiController.getInstance()
        const url = await api.getImageDataAsUrl(file.id, file.mimeType)
        if (url) {
          setFilePreview(url)
        }
      }
      setPreviewLoading(false)
    }

    const getPreview = async () => {
      if (!fileSelectedForPreview || !fileSelectedForPreview.id) return

      // id in this context is the file id, not the document id
      const base64Preview = await getPreviewDocumentPath(
        fileSelectedForPreview,
        true
      )
      if (base64Preview && base64Preview !== "") {
        setFilePreview(base64Preview)
      }
      setPreviewLoading(false)
    }

    if (fileSelectedForPreview) {
      setPreviewLoading(true)
      if (
        fileSelectedForPreview.mimeType &&
        fileSelectedForPreview.mimeType === "application/pdf"
      ) {
        getSrc(fileSelectedForPreview)
      } else {
        getPreview()
      }
    }

    return () => {
      setPreviewLoading(false)
      setFilePreview(undefined)
    }
  }, [fileSelectedForPreview])

  const loadPDF = (url: string) => {
    const viewSDKClient = new ViewSDKClient()
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        "pdf-preview",
        {
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: true,
          showDownloadPDF: false,
          showPrintPDF: false,
          showFullScreen: true,
        },
        url
      )
    })
  }

  if (filePreview) {
    loadPDF(filePreview)
  }

  const previewHeading = `Preview: ${fileSelectedForPreview?.name}`
  const headingForFile = `Add document name ${
    !uploadedViaCategory ? "and choose category " : ""
  }(${documentsToUpload.length})`

  return (
    <div className="add-document-details">
      <ModalHeader hideHeaderBorderBottom>
        {fileSelectedForPreview ? previewHeading : headingForFile}
      </ModalHeader>
      {!fileSelectedForPreview ? (
        <ModalContent>
          {formErrors && formErrors.length > 0 && (
            <AlertMessage
              messageType={MessageType.ERROR}
              title="There's a problem"
              message={
                unknowApiError
                  ? alertMessageErrorsText.genericPartialUpload
                  : "Check the form. You must:"
              }
              alertItems={formErrors}
            />
          )}
          {documentsToUpload.map((document: CaseDocument) => (
            <div key={document.file?.name}>
              <UploadedFileDetails
                file={document.file!}
                requestDropdownOptions={requestDropdownOptions}
                formData={formData}
                uploadedViaCategory={uploadedViaCategory}
                setFormData={setFormData}
                formErrors={formErrors}
                setFileSelectedForPreview={setfileSelectedForPreview}
                setUnsavedChanges={setUnsavedChanges}
              />
            </div>
          ))}
          {/* TODO: Button component should support isBusy or isLoading prop and we should add the spinner there.  https://dev.azure.com/secure-the-file/Application/_workitems/edit/14437 */}
          <Button
            className="add-document-details__submit-button"
            variant={BUTTON_VARIANT.PRIMARY}
            onClick={onSubmit}
          >
            {!isSubmitting &&
              `Add details to document${
                documentsToUpload.length > 1 ? "s" : ""
              }`}
            {isSubmitting && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="mr-1">Updating...</span>
                <LoadingSpinner
                  size="20px"
                  thickness="2px"
                  color="var(--color-universal-secondary-e)"
                />
              </div>
            )}
          </Button>
        </ModalContent>
      ) : (
        <>
          <hr />
          <ModalContent>
            {previewLoading && (
              <div className="pdf-div-loading">
                <LoadingSpinner
                  color="var(--color-universal-secondary-c)"
                  size="50px"
                  thickness="5px"
                />
              </div>
            )}
            {!previewLoading && filePreview && (
              <div
                id="pdf-preview"
                style={{ height: "669px" }}
                className="pdf-div"
              />
            )}
            {!previewLoading && !filePreview && (
              <p className="pdf-div-error">
                There is no preview available for this document.
              </p>
            )}
            <Button
              variant="secondary"
              onClick={() => setfileSelectedForPreview(undefined)}
            >
              Back to upload overview
            </Button>
          </ModalContent>
        </>
      )}
    </div>
  )
}
