import { IFolder } from "../api-types"
import { getFolder } from "../api-handler"

export const findAndUpdateParentFolders = (
  folders: IFolder[],
  selectedFolderId: string
) => {
  const foundFolder = getFolder({ folders, folderId: selectedFolderId })
  if (foundFolder) {
    const updatedAt = new Date().toISOString()
    foundFolder.updated = updatedAt
    if (foundFolder.parentId) {
      findAndUpdateParentFolders(folders, foundFolder.parentId)
    }
  }
}
