import { useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useApplicationContext } from "../../../contexts/application/context"
import { IFolder } from "../../../api/api-client/api-types"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { useKeyboardNavList } from "../../../hooks/useKeyboardNavList"
import { NavigationLink } from "./NavigationLink"
import {
  setSelectedFolder,
  setSelectedFile,
  setSelectedContact,
  setSelectedUserSharingWithMe,
} from "../../../contexts/application/action"
import { getTopNavMenuItems } from "./helpers"
import { useUserContext } from "../../../contexts/users"
import { sendFolderSelectGaData } from "../../../utils/gaEvents"
import {
  PAGE_PATH,
  UserModuleType,
} from "../../../contexts/application/constants"
import { INavLink } from "../LeftNavigationBar"
import { LeftNavMobileMenu } from "../../MenuBar/LeftNavMobileMenu"
import {
  InitialNavigationData,
  renderThemeLogo,
  themesWithoutLogoText,
} from "./utils"
import "../LeftNavigationBar.css"

export type TLeftBarNavigationData = {
  level1: string
  level2: string
  level3: string
}

export const FoldersSharingNavigationItems = ({
  isDesktop,
  setIsMenuBarExpanded,
  isMenuBarExpanded,
  setExpandedLinks,
  expandedLinks,
}: {
  setIsMenuBarExpanded: (isExpanded: boolean) => void
  isMenuBarExpanded: boolean
  isDesktop: boolean
  setExpandedLinks: (links: TLeftBarNavigationData) => void
  expandedLinks: TLeftBarNavigationData
}) => {
  const {
    applicationState: { folders, usersSharingWithMe, selectedTheme },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const crossIcon = useGetIcon("Cross")
  const hamburgerIcon = useGetIcon("Hamburger")

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const myFilesSubLinks: INavLink[] = useMemo(() => {
    const onFolderClick = (folder: IFolder) => {
      dispatch(setSelectedFile(undefined))
      dispatch(setSelectedFolder(folder.id))
      sendFolderSelectGaData(folder)
      if (!isDesktop) setIsMenuBarExpanded(false)

      setExpandedLinks({ ...expandedLinks, level3: folder.name })

      navigate(PAGE_PATH.FolderPage)
    }

    return folders.map(
      (folder) =>
        ({
          name: folder.name,
          subLinks: [
            ...(folder.childFolders?.map((childFolder) => ({
              name: childFolder.name,
              onClick: () => {
                setExpandedLinks({
                  ...expandedLinks,
                  level3: childFolder.name,
                })
                onFolderClick(childFolder)
              },
            })) ?? []),
            {
              name: "View section",
              onClick: () => {
                onFolderClick(folder)
              },
            },
          ],
          onClick: () => {
            setExpandedLinks({
              ...expandedLinks,
              level2: folder.name,
              level3: "",
            })
          },
        } as INavLink)
    )
  }, [
    dispatch,
    expandedLinks,
    folders,
    isDesktop,
    navigate,
    setExpandedLinks,
    setIsMenuBarExpanded,
  ])

  const sharingSubLinks: INavLink[] = useMemo(() => {
    const sharingOptions = []
    const onLinkClick = (href: string) => {
      setExpandedLinks({ ...expandedLinks, level2: href })
      if (!isDesktop) setIsMenuBarExpanded(false)
      dispatch(setSelectedContact(""))
      dispatch(setSelectedUserSharingWithMe(""))
      navigate(href)
    }
    if (currentUser?.modules?.includes(UserModuleType.SHARING)) {
      sharingOptions.push({
        name: "Contacts",
        onClick: () => onLinkClick(PAGE_PATH.SharingPage),
      })
    }
    if (
      currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_LIST) &&
      usersSharingWithMe &&
      usersSharingWithMe.length > 0
    )
      sharingOptions.push({
        name: "Shared",
        onClick: () => onLinkClick(PAGE_PATH.SharedWithMePage),
      })
    return sharingOptions
  }, [
    currentUser?.modules,
    dispatch,
    expandedLinks,
    isDesktop,
    navigate,
    setExpandedLinks,
    setIsMenuBarExpanded,
    usersSharingWithMe,
  ])

  const menuItems = getTopNavMenuItems({
    expandedLinks,
    myFilesSubLinks,
    navigate,
    pathname,
    setExpandedLinks,
    setIsMenuBarExpanded,
    sharingSubLinks,
    user: currentUser,
    isMobileOrTablet: !isDesktop,
  })

  const { activeIndex, handleKeyDown } = useKeyboardNavList({
    navItems: menuItems,
  })

  const shouldDisplayLogoText =
    selectedTheme && themesWithoutLogoText.includes(selectedTheme)
  return (
    <>
      {!isDesktop ? (
        <LeftNavMobileMenu
          onMenuClick={() => setIsMenuBarExpanded(false)}
          isExpanded={isMenuBarExpanded}
        />
      ) : (
        <div className="logo-wrapper svg-no-rules">
          {selectedTheme && renderThemeLogo(selectedTheme)}
          {shouldDisplayLogoText ? (
            ""
          ) : (
            <p className="logo-text">Personal Document Vault</p>
          )}
          <button
            className="hidden hide-on-desktop-only menu-expand-button"
            type="button"
            onClick={() => {
              setExpandedLinks(InitialNavigationData)
              setIsMenuBarExpanded(!isMenuBarExpanded)
            }}
          >
            {isMenuBarExpanded ? crossIcon : hamburgerIcon}
          </button>
        </div>
      )}
      <nav
        aria-label="Document Vault"
        data-testid="left-nav-menu-container-temp"
      >
        <ul
          className="navigation-list"
          aria-orientation="vertical"
          role="menubar"
          aria-label="Document Vault"
          onKeyDown={(event) => handleKeyDown(event)}
        >
          {menuItems.map((link, index) => (
            <NavigationLink
              isSelected={activeIndex === index}
              key={link.name}
              link={link}
              level={1}
              expandedLinks={expandedLinks}
              setExpandedLinks={setExpandedLinks}
            />
          ))}
        </ul>
      </nav>
    </>
  )
}
