import { Button } from "../../atoms/Button"
import "./ForgotPasswordConfirmation.css"

const ForgotPasswordConfirmation = ({
  email,
  setShouldDisplayConfirmation,
  setErrors,
}: {
  email: string
  setShouldDisplayConfirmation: (shouldDisplay: boolean) => void
  setErrors: (errors: any) => void
}) => {
  const handleResendClick = () => {
    setShouldDisplayConfirmation(false)
    setErrors([])
  }

  return (
    <>
      <div className="forgot-password-confirmation">
        <div className="forgot-password-confirmation__content">
          <h1>We’ve sent a reset password link to your email</h1>
          <p>
            If an account exists for {email}, you'll be able to reset your
            password and log in.
          </p>
          <p>
            If you don’t receive an email, double check the address and{" "}
            <Button onClick={handleResendClick}>try again</Button>
          </p>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordConfirmation
