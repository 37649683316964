import { getFolder } from "../../api/api-client/api-handler"
import { GetGAFolderType, sendGaData } from "../../utils/gaEvents"
import { IFile, IFolder, IUser } from "../../api/api-client/api-types"
import { useState, useEffect } from "react"
import { DistributionFileNodeDto } from "legado-generated-api-client"

export const sendFilePreviewGAData = (
  file: IFile | DistributionFileNodeDto,
  folders: IFolder[],
  currentUser: IUser | undefined
) => {
  const parentFolder = getFolder({ folders, folderId: file.parentId ?? "" })
  sendGaData({
    name: "file_preview",
    file_extension: file.extension ?? "",
    is_node_owner: file.isOwner ? "Y" : "N",
    ...(parentFolder && {
      folder_name: parentFolder.name,
      folder_depth: parentFolder.level,
      folder_type: GetGAFolderType(parentFolder),
      currentUser,
    }),
  })
}

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth

export function useIsDesktop() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId: any = null
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId)
      // change width from the state object after 200 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 200)
    }
    // set resize listener
    window.addEventListener("resize", resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return width >= 1230
}

export function useWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId: any = null
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId)
      // change width from the state object after 200 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 200)
    }
    // set resize listener
    window.addEventListener("resize", resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return width
}
