const Info = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="InfoDark"
  >
    <path
      d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
      fill="#333333"
      stroke="#333333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path 
        d="M8.33301 8.33333H8.99967V13H9.66634" 
        fill="white" />
    <path
      d="M8.33301 8.33333H8.99967V13H9.66634"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.45833 5.75C9.45833 6.1412 9.1412 6.45833 8.75 6.45833C8.3588 6.45833 8.04167 6.1412 8.04167 5.75C8.04167 5.3588 8.3588 5.04167 8.75 5.04167C9.1412 5.04167 9.45833 5.3588 9.45833 5.75Z"
      fill="white"
      stroke="white"
      strokeWidth="0.0833333"
    />
  </svg>
)

export default Info

