const List = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 18H6C5.44772 18 5 18.4477 5 19V24C5 24.5523 5.44772 25 6 25H26C26.5523 25 27 24.5523 27 24V19C27 18.4477 26.5523 18 26 18Z"
      fill="none"
    />
    <path
      d="M26 7H6C5.44772 7 5 7.44772 5 8V13C5 13.5523 5.44772 14 6 14H26C26.5523 14 27 13.5523 27 13V8C27 7.44772 26.5523 7 26 7Z"
      fill="none"
    />
  </svg>
)
export default List
