import { TTheme } from "../../design-tokens/types"
import { getTypography, getTG } from "../../design-tokens/getters"

export const getTypographyVariables = (theme: TTheme) => {
  const typography = getTypography(theme)
  const baseFont = getTG({ typography, key: "base-font" })
  const headingFont = getTG({ typography, key: "heading-font" })

  return `
        --body-font-text-base: ${baseFont.css?.value || "Roboto"};
        --font-family-heading: ${headingFont.css?.value || "Roboto"};
        --font-size-base: ${baseFont.size["font-size"]?.value || "16px"};
        --line-height-base: ${baseFont.size["line-height"]?.value || "24px"};
        --letter-spacing-base: ${
          baseFont.size["letter-spacing"]?.value || "-0.01rem"
        };
    `
}
