import { TDisplaySuccessOrErrorPopup } from "./constants"

export const SET_DISPLAY_SUCCESS_OR_ERROR_MESSAGE =
  "SET_DISPLAY_SUCCESS_OR_ERROR_MESSAGE"

export const setDisplaySuccessOrErrorMessage = (
  displayMessage?: TDisplaySuccessOrErrorPopup
) => {
  return {
    type: SET_DISPLAY_SUCCESS_OR_ERROR_MESSAGE,
    displayMessage,
  }
}
