const Sort = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 22L10 26L6 22" fill="none" />
    <path d="M10 6V26" fill="none" />
    <path d="M18 10L22 6L26 10" fill="none" />
    <path d="M22 26V6.00001" fill="none" />
  </svg>
)
export default Sort
