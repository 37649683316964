const SquaresFour = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14 6H6V14H14V6Z" fill="none" />
    <path d="M26 6H18V14H26V6Z" fill="none" />
    <path d="M14 18H6V26H14V18Z" fill="none" />
    <path d="M26 18H18V26H26V18Z" fill="none" />
  </svg>
)
export default SquaresFour
