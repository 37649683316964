import { useNavigate, useParams } from "react-router-dom"
import { Button, BUTTON_SIZE, BUTTON_VARIANT } from "../Button"
import { useUserContext } from "../../../contexts/users"
import "./ProfileButton.css"
import { PAGE_PATH } from "../../../contexts/application/constants"
import { replaceParamsInPath } from "../../../utils/navigation/replaceParamsInPath"

export const ProfileButton = ({ className }: { className?: string }) => {
  const {
    userState: { currentUser },
  } = useUserContext()
  const navigate = useNavigate()
  const { workflowId, workflowVersion, caseId } = useParams()

  return (
    <div className={`account-button ${className}`}>
      <Button
        onClick={(e) => {
          if (workflowId && workflowVersion && caseId) {
            //navigate to the profile page for legal cases
            navigate(
              replaceParamsInPath({
                path: PAGE_PATH.CaseProfilePage,
                pathParameters: { workflowId, workflowVersion, caseId },
              })
            )
          } else {
            //navigate to the default profile page
            navigate("/profile")
          }
        }}
        size={BUTTON_SIZE.TINY}
        variant={BUTTON_VARIANT.SECONDARY}
        testId="profilebutton-button-profile"
        ariaLabel="Go to profile page"
      >
        {currentUser?.firstName?.slice(0, 1)}
        {currentUser?.surname?.slice(0, 1)}
      </Button>
    </div>
  )
}
