import { IFolder } from "../api-types"
import { flattenFolderTree } from "./flattenFolderTree"

export function findFoldersByIds(
  folders: IFolder[],
  folderIds: string[]
): IFolder[] {
  const flatFolders = flattenFolderTree(folders)
  return flatFolders.filter((f) => folderIds.includes(f.id))
}
