const FnzDownload = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 32 32"
    fillRule="evenodd"
    clipRule="evenodd"
    className="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0428 13.0444C10.4333 12.6538 11.0664 12.6537 11.457 13.0442L16 17.586L20.543 13.0442C20.9336 12.6537 21.5667 12.6538 21.9572 13.0444C22.3477 13.4349 22.3476 14.0681 21.957 14.4586L16.707 19.7072C16.3165 20.0976 15.6835 20.0976 15.293 19.7072L10.043 14.4586C9.65241 14.0681 9.65233 13.4349 10.0428 13.0444Z"
      stroke="none"
    />
    <path
      d="M16 4C16.5523 4 17 4.44772 17 5V18.9963C17 19.5486 16.5523 19.9963 16 19.9963C15.4477 19.9963 15 19.5486 15 18.9963V5C15 4.44772 15.4477 4 16 4Z"
      stroke="none"
    />
    <path
      d="M5 18C5.55228 18 6 18.4477 6 19V26H26V19C26 18.4477 26.4477 18 27 18C27.5523 18 28 18.4477 28 19V26C28 26.5304 27.7893 27.0391 27.4142 27.4142C27.0391 27.7893 26.5304 28 26 28H6C5.46957 28 4.96086 27.7893 4.58579 27.4142C4.21071 27.0391 4 26.5304 4 26V19C4 18.4477 4.44772 18 5 18Z"
      stroke="none"
    />
  </svg>
)
export default FnzDownload
