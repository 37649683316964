const ArrowDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 3.75V20.25" fill="none" />
    <path d="M5.25 13.5L12 20.25L18.75 13.5" fill="none" />
  </svg>
)
export default ArrowDown
