const NotificationNew = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 26C11.3171 26.5772 11.9757 27.0841 12.877 27.4446C13.7784 27.8051 14.874 28 16 28C17.1259 28 18.2216 27.8051 19.123 27.4446C20.0243 27.0841 20.6829 26.5772 21 26"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      d="M4.48687 21.4992L4.48642 21.4994L4.48605 21.4997L4.4855 21.5L4.48519 21.5002L4 21.7903V22.3575V23V24H5H27H28V23V22.3575V21.8474L27.5878 21.5485L27.5875 21.5483L27.5873 21.5481L27.587 21.5479L27.5839 21.5456L27.5645 21.5313C27.5465 21.5178 27.5184 21.4966 27.4817 21.4683C27.4083 21.4117 27.3009 21.327 27.171 21.2188C26.9097 21.0013 26.5646 20.6953 26.2231 20.3378C25.8784 19.9769 25.5583 19.5853 25.3295 19.1983C25.0952 18.8023 25 18.4844 25 18.2512V10.2714C25 8.02402 23.5033 6.19291 21.7802 4.98251C20.0434 3.7625 17.8466 3.00048 16.0003 3C14.1284 2.99952 11.9287 3.81602 10.2033 5.05443C8.49902 6.27761 7 8.10708 7 10.2714V18.2512C7 18.99 6.50036 19.7688 5.79887 20.4586C5.46842 20.7835 5.13413 21.0482 4.88108 21.232C4.75543 21.3233 4.65206 21.393 4.58221 21.4386C4.54734 21.4613 4.52099 21.478 4.50458 21.4882L4.48767 21.4987L4.48687 21.4992Z"
      stroke="var(--color-universal-secondary-b)"
      strokeWidth="2"
    />
    <rect
      x="18"
      y="4"
      width="10"
      height="10"
      rx="5"
      fill="#D00F00"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)
export default NotificationNew
