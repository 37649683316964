const Success = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 31C24.9558 31 31 24.9558 31 17.5C31 10.0442 24.9558 4 17.5 4C10.0442 4 4 10.0442 4 17.5C4 24.9558 10.0442 31 17.5 31Z"
      fill="#008616"
    />
    <path d="M11 17L16 21L24 13" stroke="white" strokeWidth="2" />
  </svg>
)
export default Success
