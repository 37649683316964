const FolderIconsHomeProperty = () => (
  <svg 
    width="64" 
    height="64" 
    viewBox="0 0 64 64" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      fillRule="evenodd" 
      clipRule="evenodd" 
      d="M32.3693 3.88516C32.4284 3.82994 32.5202 3.82995 32.5794 3.88516L46.6529 17.0185L50.3255 20.4457C50.6283 20.7283 51.1029 20.7119 51.3855 20.409C51.6681 20.1062 51.6517 19.6316 51.3489 19.349L47.6763 15.9218L33.6028 2.7885C32.9673 2.1955 31.9813 2.1955 31.3459 2.7885L24.6175 9.06737L22.2066 11.3172V7.74024C22.2066 6.4976 21.1993 5.49024 19.9566 5.49024H15.6115C14.3688 5.49024 13.3615 6.4976 13.3615 7.74024V19.5714L9.9272 22.7762L2.58206 29.6307C2.27922 29.9133 2.26282 30.3879 2.54542 30.6907C2.82803 30.9935 3.30262 31.0099 3.60545 30.7273L9.09375 25.6057V57.4688C9.09375 59.5398 10.7727 61.2188 12.8438 61.2188H22.5938C24.6648 61.2188 26.3438 59.5398 26.3438 57.4688V36.9688C26.3438 35.7261 27.3511 34.7188 28.5938 34.7188H34.4063C34.8205 34.7188 35.1563 34.383 35.1563 33.9688C35.1563 33.5545 34.8205 33.2188 34.4063 33.2188H28.5938C26.5227 33.2188 24.8438 34.8977 24.8438 36.9688V57.4688C24.8438 58.7114 23.8364 59.7188 22.5938 59.7188H12.8438C11.6011 59.7188 10.5938 58.7114 10.5938 57.4688V24.2059L10.9506 23.8729L14.6232 20.4457L14.8615 20.2233V19.8973V7.74024C14.8615 7.32602 15.1973 6.99024 15.6115 6.99024H19.9566C20.3708 6.99024 20.7066 7.32602 20.7066 7.74024V13.0429V14.7686L21.9683 13.5912L25.6409 10.164L32.3693 3.88516Z" 
      fill="#BCBCBC"
      strokeWidth="2.5"
    />
    <path 
      d="M44.72 44.0532L39.96 48.5332M41.7367 41.4366L31.164 52.0092C31.059 52.1142 31 52.2567 31 52.4052V57.4932C31 57.8025 31.2507 58.0532 31.56 58.0532H36.04C36.3493 58.0532 36.6 57.8025 36.6 57.4932V55.8132C36.6 55.5039 36.8507 55.2532 37.16 55.2532H39.12C39.4293 55.2532 39.68 55.0025 39.68 54.6932V52.7332C39.68 52.4239 39.9307 52.1732 40.24 52.1732H42.5451C42.6835 52.1732 42.8169 52.122 42.9197 52.0295L48.2003 47.277C48.3031 47.1844 48.4365 47.1332 48.5748 47.1332H52C54.2108 46.8546 56.2 46.0132 57.88 43.4932C59.56 40.9732 59.1556 36.7732 58.16 34.5332C57.1644 32.2932 54.8 30.3332 51.44 30.0532C48.08 29.7732 45.8711 30.5697 43.6 33.1332C41.7258 35.2488 41.8434 39.8573 41.9037 40.9953C41.9124 41.1595 41.853 41.3203 41.7367 41.4366ZM50.6 35.9332C51.8371 35.9332 52.84 36.9361 52.84 38.1732C52.84 39.4103 51.8371 40.4132 50.6 40.4132C49.3629 40.4132 48.36 39.4103 48.36 38.1732C48.36 36.9361 49.3629 35.9332 50.6 35.9332Z" 
      strokeWidth="2.5"
      strokeLinecap="round" 
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
)
export default FolderIconsHomeProperty
