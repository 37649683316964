import {
  DistributionClientDetailsDto,
  DistributionProjectClientListDto,
  DistributionProjectSummaryDto,
} from "legado-generated-api-client"
import { FilterType, TFilterOption } from "../../../../modules/Filter/Filter"

export const getDummyClientsPropositionGuide = (
  search?: string,
  status?: number,
  revokedStatus?: number
) => {
  const clientListDetails = search
    ? dummyClientsPropositionGuide.filter((client) =>
        client.name!.toLowerCase().includes(search.toLowerCase())
      )
    : dummyClientsPropositionGuide

  const statusFilteredclientListDetails =
    status?.toString() && status > -1
      ? clientListDetails.filter((client) => {
          const shouldGetViewed = status === 1
          return client.readStatus === (shouldGetViewed || undefined)
        })
      : clientListDetails

  const revokedStatusFilteredclientListDetails =
    !!revokedStatus && revokedStatus > 0
      ? statusFilteredclientListDetails.filter(() => {
          const shouldGetRevoked = revokedStatus === 1
          return shouldGetRevoked ? false : true
        })
      : statusFilteredclientListDetails

  const summary: DistributionProjectSummaryDto = {
    totalOfClients: 10,
    totalOfReadByProject: 8,
  }
  const dpCsummary = {
    clientListDetails: revokedStatusFilteredclientListDetails,
    summary,
  } as DistributionProjectClientListDto
  return dpCsummary
}

export const dummyClientsPropositionGuide: DistributionClientDetailsDto[] = [
  {
    clientId: "1",
    name: "Thomas Wood",
    email: "thomas.wood@fnz.com",
    readStatus: true,
    totalOfReadByClient: 2,
    readDateTime: "2023-09-02T00:30:00Z",
    clientStatus: "Active",
  },
  {
    clientId: "2",
    name: "Peter Brink",
    email: "pbrink@gmail.com",
    readStatus: true,
    readDateTime: "2023-09-05T00:30:00Z",
    clientStatus: "Active",
  },
  {
    clientId: "3",
    name: "Emma Johnson",
    email: "emma.johnson@gmail.com",
    clientStatus: "Active",
  },
  {
    clientId: "4",
    name: "Joseph Campbell",
    email: "jcampbell90@gmail.com",
    readStatus: true,
    readDateTime: "2023-09-10T00:30:00Z",
    clientStatus: "Active",
  },
  {
    clientId: "5",
    name: "Filipo Mustermann",
    email: "filipo.mustermann@fnz.com",
    readStatus: true,
    readDateTime: "2023-09-05T00:30:00Z",
    clientStatus: "Active",
  },
  {
    clientId: "6",
    name: "Vince Ng",
    email: "vince.ng@fnz.com",
    clientStatus: "Active",
  },
  {
    clientId: "7",
    name: "Delphine Artois",
    email: "delphine.artois@fnz.com",
    readStatus: true,
    readDateTime: "2023-09-10T00:30:00Z",
    clientStatus: "Active",
  },
  {
    clientId: "8",
    name: "Luisa Garcia Perez",
    email: "luisa.perez@fnz.com",
    readStatus: true,
    readDateTime: "2023-09-10T00:30:00Z",
    clientStatus: "Active",
  },
  {
    clientId: "9",
    name: "Antonio Marques Silva",
    email: "antonio.silva@fnz.com",
    readStatus: true,
    readDateTime: "2023-09-10T00:30:00Z",
    clientStatus: "Active",
  },
  {
    clientId: "10",
    name: "Ben Hackett",
    email: "ben.hackett@fnz.com",
    readStatus: true,
    readDateTime: "2023-09-02T00:30:00Z",
    clientStatus: "Active",
  },
]

export const dummyClients: DistributionClientDetailsDto[] = [
  {
    name: "John Doe",
    email: "john.doe@example.com",
    clientId: "1",
  },
  {
    name: "Jane Smith",
    email: "jane.smith@example.com",
  },
  {
    name: "Michael Johnson",
    email: "michael.johnson@example.com",
  },
  {
    name: "Emily Brown",
    email: "emily.brown@example.com",
  },
  {
    name: "William Wilson",
    email: "william.wilson@example.com",
  },
  {
    name: "Olivia Davis",
    email: "olivia.davis@example.com",
  },
  {
    name: "James Johnson",
    email: "james.johnson@example.com",
  },
  {
    name: "Emma Wilson",
    email: "emma.wilson@example.com",
  },
  {
    name: "Daniel Smith",
    email: "daniel.smith@example.com",
  },
  {
    name: "Sophia Davis",
    email: "sophia.davis@example.com",
  },
  {
    name: "Oliver Brown",
    email: "oliver.brown@example.com",
  },
  {
    name: "Ava Smith",
    email: "ava.smith@example.com",
  },
  {
    name: "Benjamin Taylor",
    email: "benjamin.taylor@example.com",
  },
  {
    name: "Mia Wilson",
    email: "mia.wilson@example.com",
  },
  {
    name: "Lucas Martin",
    email: "lucas.martin@example.com",
  },
  {
    name: "Charlotte Harris",
    email: "charlotte.harris@example.com",
  },
  {
    name: "Henry Johnson",
    email: "henry.johnson@example.com",
  },
  {
    name: "Amelia Brown",
    email: "amelia.brown@example.com",
  },
  {
    name: "Alexander Wilson",
    email: "alexander.wilson@example.com",
  },
  {
    name: "Mila Taylor",
    email: "mila.taylor@example.com",
  },
  {
    name: "Liam Davis",
    email: "liam.davis@example.com",
  },
  {
    name: "Evelyn Wilson",
    email: "evelyn.wilson@example.com",
  },
  {
    name: "Sebastian Martin",
    email: "sebastian.martin@example.com",
  },
  {
    name: "Harper Smith",
    email: "harper.smith@example.com",
  },
  {
    name: "Jackson Taylor",
    email: "jackson.taylor@example.com",
  },
  {
    name: "Luna Brown",
    email: "luna.brown@example.com",
  },
  {
    name: "Aiden Johnson",
    email: "aiden.johnson@example.com",
  },
  {
    name: "Penelope Davis",
    email: "penelope.davis@example.com",
  },
  {
    name: "Mateo Wilson",
    email: "mateo.wilson@example.com",
  },
  {
    name: "Riley Smith",
    email: "riley.smith@example.com",
  },
  {
    name: "Zoey Johnson",
    email: "zoey.johnson@example.com",
  },
  {
    name: "Carter Martin",
    email: "carter.martin@example.com",
  },
  {
    name: "Hannah Brown",
    email: "hannah.brown@example.com",
  },
  {
    name: "Samuel Wilson",
    email: "samuel.wilson@example.com",
  },
  {
    name: "Layla Taylor",
    email: "layla.taylor@example.com",
  },
  {
    name: "Luke Davis",
    email: "luke.davis@example.com",
  },
  {
    name: "Grace Wilson",
    email: "grace.wilson@example.com",
  },
  {
    name: "Henry Harris",
    email: "henry.harris@example.com",
  },
  {
    name: "Scarlett Brown",
    email: "scarlett.brown@example.com",
  },
  {
    name: "Chloe Wilson",
    email: "chloe.wilson@example.com",
  },
  {
    name: "Jack Taylor",
    email: "jack.taylor@example.com",
  },
  {
    name: "Zoe Davis",
    email: "zoe.davis@example.com",
  },
  {
    name: "Ellie Wilson",
    email: "ellie.wilson@example.com",
  },
  {
    name: "David Martin",
    email: "david.martin@example.com",
  },
  {
    name: "Sophie Smith",
    email: "sophie.smith@example.com",
  },
  {
    name: "Gabriel Taylor",
    email: "gabriel.taylor@example.com",
  },
  {
    name: "Madison Brown",
    email: "madison.brown@example.com",
  },
  {
    name: "Anthony Johnson",
    email: "anthony.johnson@example.com",
  },
  {
    name: "Arianna Davis",
    email: "arianna.davis@example.com",
  },
  {
    name: "Leo Wilson",
    email: "leo.wilson@example.com",
  },
  {
    name: "Sadie Smith",
    email: "sadie.smith@example.com",
  },
  {
    name: "Isaac Johnson",
    email: "isaac.johnson@example.com",
  },
  {
    name: "Eleanor Martin",
    email: "eleanor.martin@example.com",
  },
  {
    name: "Anna Brown",
    email: "anna.brown@example.com",
  },
  {
    name: "Nathan Wilson",
    email: "nathan.wilson@example.com",
  },
  {
    name: "Victoria Taylor",
    email: "victoria.taylor@example.com",
  },
  {
    name: "Daniel Davis",
    email: "daniel.davis@example.com",
  },
  {
    name: "Sofia Wilson",
    email: "sofia.wilson@example.com",
  },
  {
    name: "Joseph Harris",
    email: "joseph.harris@example.com",
  },
  {
    name: "Brooklyn Brown",
    email: "brooklyn.brown@example.com",
  },
  {
    name: "Levi Wilson",
    email: "levi.wilson@example.com",
  },
  {
    name: "Grace Taylor",
    email: "grace.taylor@example.com",
  },
  {
    name: "Aubrey Davis",
    email: "aubrey.davis@example.com",
  },
  {
    name: "Julian Wilson",
    email: "julian.wilson@example.com",
  },
  {
    name: "Addison Martin",
    email: "addison.martin@example.com",
  },
  {
    name: "Audrey Smith",
    email: "audrey.smith@example.com",
  },
  {
    name: "Wyatt Taylor",
    email: "wyatt.taylor@example.com",
  },
  {
    name: "Julia Brown",
    email: "julia.brown@example.com",
  },
  {
    name: "John Johnson",
    email: "john.johnson@example.com",
  },
  {
    name: "Charlie Davis",
    email: "charlie.davis@example.com",
  },
  {
    name: "Gabriella Wilson",
    email: "gabriella.wilson@example.com",
  },
  {
    name: "Lucy Smith",
    email: "lucy.smith@example.com",
  },
  {
    name: "Christopher Johnson",
    email: "christopher.johnson@example.com",
  },
  {
    name: "Stella Martin",
    email: "stella.martin@example.com",
  },
  {
    name: "Paisley Brown",
    email: "paisley.brown@example.com",
  },
  {
    name: "Andrew Wilson",
    email: "andrew.wilson@example.com",
  },
  {
    name: "Caroline Taylor",
    email: "caroline.taylor@example.com",
  },
  {
    name: "Grayson Davis",
    email: "grayson.davis@example.com",
  },
  {
    name: "Nova Wilson",
    email: "nova.wilson@example.com",
  },
  {
    name: "Asher Harris",
    email: "asher.harris@example.com",
  },
  {
    name: "Violet Brown",
    email: "violet.brown@example.com",
  },
  {
    name: "Julian Wilson",
    email: "julian.wilson@example.com",
  },
  {
    name: "Bella Taylor",
    email: "bella.taylor@example.com",
  },
  {
    name: "Elijah Davis",
    email: "elijah.davis@example.com",
  },
  {
    name: "Clara Wilson",
    email: "clara.wilson@example.com",
  },
  {
    name: "James Martin",
    email: "james.martin@example.com",
  },
  {
    name: "Lila Smith",
    email: "lila.smith@example.com",
  },
  {
    name: "Henry Taylor",
    email: "henry.taylor@example.com",
  },
  {
    name: "Sophia Brown",
    email: "sophia.brown@example.com",
  },
  {
    name: "David Johnson",
    email: "david.johnson@example.com",
  },
  {
    name: "Joseph Davis",
    email: "joseph.davis@example.com",
  },
  {
    name: "Avery Wilson",
    email: "avery.wilson@example.com",
  },
  {
    name: "Scarlett Smith",
    email: "scarlett.smith@example.com",
  },
  {
    name: "Samuel Johnson",
    email: "samuel.johnson@example.com",
  },
  {
    name: "Hazel Martin",
    email: "hazel.martin@example.com",
  },
  {
    name: "Nora Brown",
    email: "nora.brown@example.com",
  },
  {
    name: "Eli Wilson",
    email: "eli.wilson@example.com",
  },
  {
    name: "Eva Taylor",
    email: "eva.taylor@example.com",
  },
  {
    name: "Michael Davis",
    email: "michael.davis@example.com",
  },
] as DistributionClientDetailsDto[]

export const ViewStatusFilterTemplate = [
  {
    name: "Viewed",
    checked: false,
    value: "1",
    type: FilterType.STATUS,
  } as TFilterOption,
  {
    name: "Not viewed",
    checked: false,
    value: "0",
    type: FilterType.STATUS,
  } as TFilterOption,
] as TFilterOption[]

export const RevokeStatusFilterTemplate = [
  {
    name: "Both (Default)",
    checked: true,
    value: "0",
    type: FilterType.STATUS,
  } as TFilterOption,
  {
    name: "Revoked",
    checked: false,
    value: "1",
    type: FilterType.STATUS,
  } as TFilterOption,
  {
    name: "Not revoked",
    checked: false,
    value: "2",
    type: FilterType.STATUS,
  } as TFilterOption,
] as TFilterOption[]
