const WelcomeStore = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    className="reverse"
  >
    <path
      d="M42.8221 43.5H14.9092C9.99653 43.5 6.04321 39.9754 5.5 34C5 28.5 9 23 16.5 24C16.8363 24.0449 17.177 24.0185 17.5109 24.0553C21 20 24.4941 18.6412 30.2223 18.6412C37.6041 18.6412 41.965 23.3321 41.965 30.5009C41.965 30.8562 41.95 31.2125 41.92 31.5674C42.2165 31.5252 42.5176 31.5041 42.8221 31.5041C46.2286 31.5041 49 34.1944 49 37.5013C49 40.8089 46.2286 43.5 42.8221 43.5Z"
      className="custom-1"
      stroke="none"
    />
    <path
      d="M16.3721 23.196L16.9726 23.265L17.1676 22.6928C19.3821 16.1955 25.4863 11.75 32.3531 11.75C41.2013 11.75 48.4154 19.0328 48.4154 28.0011C48.4154 28.4456 48.3974 28.8912 48.3613 29.3351L48.2849 30.276L49.2187 30.1377C49.5542 30.088 49.8956 30.063 50.2417 30.063C54.0978 30.063 57.25 33.2377 57.25 37.1556C57.25 41.0745 54.0976 44.25 50.2417 44.25H15.1883C9.44079 44.25 4.75 39.5194 4.75 33.6894C4.75 27.8591 9.4408 23.1285 15.1883 23.1285C15.586 23.1285 15.9816 23.1511 16.3721 23.196Z"
      strokeWidth="1.5"
    />
    <path
      d="M31 36.1875V23.8125"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.0625 28.875L31 23.8125L25.9375 28.875"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default WelcomeStore
