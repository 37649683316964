import { TTheme } from "../../types"
import animation from "../../tokens/abrdn/vanilla/animation.json"
import breakpoints from "../../tokens/abrdn/vanilla/breakpoints.json"
import buttons from "../../tokens/abrdn/vanilla/buttons.json"
import colors from "../../tokens/abrdn/vanilla/colors.json"
import iconsizing from "../../tokens/abrdn/vanilla/iconsizing.json"
import overlay from "../../tokens/abrdn/vanilla/overlay.json"
import radius from "../../tokens/abrdn/vanilla/radius.json"
import shadows from "../../tokens/abrdn/vanilla/shadows.json"
import styling from "../../tokens/abrdn/vanilla/styling.json"
import typeface from "../../tokens/abrdn/vanilla/typeface.json"
import typography from "../../tokens/abrdn/vanilla/typography.json"

const tokens = {
  mode: "ABRDN",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  styling,
  typeface,
  typography,
} as TTheme
export default tokens
