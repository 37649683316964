import { TTheme } from "../../types"
import animation from "../../tokens/abrdn/capital/animation.json"
import breakpoints from "../../tokens/abrdn/capital/breakpoints.json"
import buttons from "../../tokens/abrdn/capital/buttons.json"
import colors from "../../tokens/abrdn/capital/colors.json"
import iconsizing from "../../tokens/abrdn/capital/iconsizing.json"
import overlay from "../../tokens/abrdn/capital/overlay.json"
import radius from "../../tokens/abrdn/capital/radius.json"
import shadows from "../../tokens/abrdn/capital/shadows.json"
import styling from "../../tokens/abrdn/capital/styling.json"
import typeface from "../../tokens/abrdn/capital/typeface.json"
import typography from "../../tokens/abrdn/capital/typography.json"

const tokens = {
  mode: "ABRDN_CAPITAL",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  styling,
  typeface,
  typography,
} as TTheme
export default tokens
