export const SET_SEARCH_LABEL = "SET_SEARCH_LABEL"
export const SET_SEARCH_EXPRESSION = "SET_SEARCH_EXPRESSION"

export const setSearchLabel = (payload: string) => {
  return {
    type: SET_SEARCH_LABEL,
    payload,
  }
}

export const setSearchExpression = (payload: string) => {
  return {
    type: SET_SEARCH_EXPRESSION,
    payload,
  }
}
