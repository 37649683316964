import {
  DocumentLibraryApi,
  FileNodeDto,
  SharedVaultDto,
} from "legado-generated-api-client"
import { BasicFile } from "../../component/modules/FileUpload/helpers"
import { sleep } from "../../utils/helpers"
import { ApiController } from "../apiController"

export class DocumentLibraryController extends ApiController {
  private apiController: ApiController
  constructor(apiController: ApiController) {
    super()
    this.apiController = apiController
  }

  private getOptions(withToken: boolean, formData: FormData = new FormData()) {
    type OptionValues = { [key: string]: any }
    type HeaderValues = { [key: string]: string }

    const headerValues: HeaderValues = {}
    if (this.apiController.getApiConnection.apiKey) {
      headerValues["ApiKey"] = this.apiController.getApiConnection.apiKey
    }

    if (withToken) {
      headerValues["Authorization"] = "Bearer " + this.apiController.getApiToken
    }

    var optionValues: OptionValues = {}
    optionValues["headers"] = headerValues
    optionValues["data"] = formData
    optionValues["Content-Type"] = "multipart/form-data"

    return optionValues
  }

  public async uploadFileToClientsVault(
    headAccount: string,
    subAccount: string | undefined = undefined,
    visibility: string,
    documentCategory: string | undefined = undefined,
    sendNotification: boolean | undefined = undefined,
    files: BasicFile[],
    secondAttempt: boolean = false
  ): Promise<FileNodeDto[]> {
    if (this.apiController.connected) {
      try {
        const documentLibraryApi = new DocumentLibraryApi({
          basePath: this.apiController.getBaseUrl,
        })

        const formData = new FormData()
        let wrongFileType = false
        files.forEach((file: any) => {
          if (file.extension === "pdf") {
            const blob = new Blob([file.binaryStr], {
              type: file.mimeType ?? undefined,
            })
            formData.append("file", blob, file.name)
          } else {
            wrongFileType = true
          }
        })

        if (wrongFileType) {
          throw new Error("Wrong file type")
        }

        const response =
          await documentLibraryApi.apiDocumentLibraryHeadaccountHeadAccountFileuploadPost(
            headAccount,
            subAccount,
            visibility,
            documentCategory,
            sendNotification,
            this.getOptions(true, formData)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not upload a file to this clients vault",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.uploadFileToClientsVault(
            headAccount,
            subAccount,
            visibility,
            documentCategory,
            sendNotification,
            files,
            true
          )
        } else {
          throw new Error("File upload error")
        }
      }
    } else {
      await sleep(1000)
      return [] as FileNodeDto[]
    }
  }

  public async changeFileVisibiltiy(
    fileId: string,
    visibility: number,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this.apiController.connected) {
      try {
        const documentLibraryApi = new DocumentLibraryApi({
          basePath: this.apiController.getBaseUrl,
        })
        await documentLibraryApi.apiDocumentLibraryFileFileIdVisibilityVisibilityPatch(
          fileId,
          visibility,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        var shouldRetry = await this.HandleError(
          error,
          "Couldn't change file visibiltiy",
          secondAttempt
        )
        if (shouldRetry)
          return await this.changeFileVisibiltiy(fileId, visibility, true)
        return false
      }
    } else {
      sleep(100)
      return true
    }
  }

  public async getDocumentCategories(): Promise<any> {
    if (this.apiController.connected) {
      try {
        const documentLibraryApi = new DocumentLibraryApi({
          basePath: this.apiController.getBaseUrl,
        })

        const response =
          await documentLibraryApi.apiDocumentLibraryDocumentcategoriesGet(
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        throw new Error("Get categories error")
      }
    }
  }

  public async getFilesForClientAccount(
    personId: string
  ): Promise<SharedVaultDto> {
    try {
      const documentLibraryApi = new DocumentLibraryApi({
        basePath: this.apiController.getBaseUrl,
      })
      const response =
        await documentLibraryApi.apiDocumentLibraryCustomerPersonHierarchyIdGet(
          personId,
          this.getOptions(true)
        )
      return response.data
    } catch (error: any) {
      let shouldRetry = await this.HandleError(
        error,
        "Could not get files for the head account",
        true
      )
      if (shouldRetry) return await this.getFilesForClientAccount(personId)
      return {
        folders: [],
        files: [],
        sections: [],
      } as SharedVaultDto
    }
  }

  public async getExternalDocumentsStatusUpdate(
    requestIds: string
  ): Promise<any> {
    if (this.apiController.connected) {
      const documentLibraryApi = new DocumentLibraryApi({
        basePath: this.apiController.getBaseUrl,
      })

      const response =
        await documentLibraryApi.apiDocumentLibraryPolldocumentstatusGet(
          requestIds,
          this.getOptions(true)
        )

      return response.data
    } else {
      await sleep(1000)
      return []
    }
  }
}
