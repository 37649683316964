import { Dispatch, SetStateAction, useState } from "react"
import { CaseDocument } from "../../api/lib/workflow/models/GetCaseDocumentResponse"
import { IFile } from "../../api/api-client/api-types"
import { findFolderByFileId } from "../../api/api-client/file/findFolderByFileId"
import { ApiController } from "../../api/apiController"
import {
  changeFileInFolder,
  changeFileInSharedWithMeFolder,
  setLoadingState,
} from "../../contexts/application/action"
import { useApplicationContext } from "../../contexts/application/context"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../contexts/toasts"
import { useUserContext } from "../../contexts/users"
import { notificationMessages } from "../../data/notificationMessages"
import { InputFieldType, validateInput } from "../../utils/forms/validateInputs"
import { GetGAFolderType, sendGaData } from "../../utils/gaEvents"
import { UnsavedProgress } from "../UnsavedProgress/UnsavedProgress"
import { Button } from "../atoms/Button"
import { TextInputWithValidation } from "../atoms/TextInput/TextInputWithValidation"
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../modules/Modal/"
import "./FileRename.css"
import { renameFileWithinClientList } from "./helpers"

export interface FileRenameProps {
  file: IFile
  onClose: () => void
  isShared?: boolean
  caseDocument?: CaseDocument
  setCaseDocument?: Dispatch<SetStateAction<CaseDocument | undefined>>
}
export interface FileRenameStaticProps {
  file: IFile
  confirmButtonDisabled?: boolean
  onClose: () => void
  onChange: (input: string) => void
  value?: string
  onConfirm?: () => void
}

export const FileRenameStatic: React.FC<FileRenameStaticProps> = ({
  file,
  confirmButtonDisabled = false,
  onClose,
  onChange,
  value = "",
  onConfirm,
}) => {
  const [unsavedProgressOpen, setUnsavedProgressOpen] = useState(false)
  const closeModal = () => {
    if (unsavedProgressOpen) {
      setUnsavedProgressOpen(false)
      onClose()
    } else {
      if (value.length > 0) {
        setUnsavedProgressOpen(true)
      } else {
        onClose()
      }
    }
  }
  return (
    <Modal
      onClose={closeModal}
      className={`file-rename-modal`}
      name="Rename file"
    >
      {unsavedProgressOpen ? (
        <UnsavedProgress
          onExit={closeModal}
          onReturn={() => setUnsavedProgressOpen(false)}
          name="Rename file"
        />
      ) : (
        <>
          <ModalHeader>{file.name}</ModalHeader>
          <ModalContent>
            <TextInputWithValidation
              value={value}
              label="Enter new file name"
              labelClassName="required"
              type={InputFieldType.TEXT}
              name="edit-filename-input"
              onChange={(input) => onChange(input)}
            />
          </ModalContent>
          <ModalFooter className="text-end">
            <Button
              className="left-button mr-4"
              variant="tertiary"
              type="reset"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              className="right-button"
              variant="primary"
              type="submit"
              onClick={onConfirm}
              isDisabled={confirmButtonDisabled}
            >
              Save changes
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

export const FileRename: React.FC<FileRenameProps> = ({
  file,
  onClose,
  isShared = false,
  caseDocument,
  setCaseDocument,
}) => {
  const {
    applicationState: {
      folders,
      sharedWithMeFolders,
      documentLibraryClientFiles,
      selectedTheme,
    },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()
  const { dispatch: toastDispatch } = useToastContext()

  const [newName, setNewName] = useState("")
  const [isValid, setValid] = useState("")

  function resetModal() {
    if (onClose) {
      setNewName("")
      onClose()
    }
  }

  function updateName(input: string) {
    const validationType =
      selectedTheme === "CO_OP"
        ? InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT
        : InputFieldType.TEXT

    setNewName(input)
    setValid(
      validateInput({
        type: validationType,
        value: input,
      })
    )
  }

  const saveName = async () => {
    const apiController = ApiController.getInstance()
    dispatch(setLoadingState(true))
    const parentFolder = findFolderByFileId(
      file.id,
      isShared ? sharedWithMeFolders! : folders
    )

    sendGaData({
      name: "File_Rename",
      is_node_owner: file.isOwner ? "Y" : "N",
      file_extension: file.extension ?? "",
      ...(parentFolder && {
        folder_depth: parentFolder.level,
        folder_name: parentFolder.name,
        folder_type: GetGAFolderType(parentFolder),
        currentUser,
      }),
    })
    const renameFileResponse = await apiController.renameFile(file.id, newName)
    dispatch(setLoadingState(false))
    if (renameFileResponse) {
      if (!isShared) {
        const updatedFile = { ...file, name: newName, fileName: newName }
        dispatch(
          changeFileInFolder({ file: updatedFile, folderId: file.parentId! })
        )

        // updating workflow case document file with a new file name
        if (setCaseDocument && caseDocument) {
          setCaseDocument({ ...caseDocument, file: updatedFile })
        }
      } else {
        documentLibraryClientFiles
          ? renameFileWithinClientList({
              documentLibraryClientFiles: documentLibraryClientFiles,
              file: file,
              newName: newName,
              dispatch,
            })
          : dispatch(
              changeFileInSharedWithMeFolder({
                file: { ...file, name: newName } as IFile,
                folderId: file.parentId!,
              })
            )
      }
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          message: notificationMessages.renameFile.SUCCESS,
          messageType: "SUCCESS",
        })
      )
      sendGaData({
        name: "File_Rename_Response",
        is_node_owner: file.isOwner ? "Y" : "N",
        file_extension: file.extension ?? "",
        ...(parentFolder && {
          folder_depth: parentFolder.level,
          folder_name: parentFolder.name,
          folder_type: GetGAFolderType(parentFolder),
        }),
        request_status: "success",
        currentUser,
      })
    } else {
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          message: notificationMessages.renameFile.ERROR,
          messageType: "ERROR",
        })
      )
      sendGaData({
        name: "File_Rename_Response",
        is_node_owner: file.isOwner ? "Y" : "N",
        file_extension: file.extension ?? "",
        ...(parentFolder && {
          folder_depth: parentFolder.level,
          folder_name: parentFolder.name,
          folder_type: GetGAFolderType(parentFolder),
        }),
        request_status: "error",
        currentUser,
      })
    }
    resetModal()
  }

  return (
    <FileRenameStatic
      file={file}
      confirmButtonDisabled={newName === "" || isValid !== ""}
      onClose={resetModal}
      value={newName}
      onChange={updateName}
      onConfirm={saveName}
    />
  )
}
