import {
  getAllSelectedValue,
  getDisabledSelectAlls,
  getTooltipMessage,
  neverReadonlyEdit,
  TRadioOptions,
} from "../../utils/shares"
import { Radio } from "../atoms/Radio"
import { Tooltip } from "../modules/Tooltip/Tooltip"
import { SelectAllPermissions } from "./SelectAllPermissions"
import "./SharingPermissions.css"

export interface SharingPermissionsProps {
  sharingOptions: TRadioOptions[]
  setSharingOptions?: (sharingOptions: TRadioOptions[]) => void
  showEditOption?: boolean
  showImportedFolderWarning?: () => void
  isAddingContactThroughWorkflowDocument?: boolean
}

export interface FileFolderSharingPermissionsProps
  extends SharingPermissionsProps {
  isFile: boolean
}

export const FileFolderSharingPermissions: React.FC<
  FileFolderSharingPermissionsProps
> = ({ isFile, sharingOptions, setSharingOptions, showEditOption = true }) => {
  const radioOptions = showEditOption
    ? neverReadonlyEdit
    : neverReadonlyEdit.filter((option) => option !== "Edit")
  return (
    <div className="radio-button-group node-sharing">
      <div className="radio-row hide-on-mobile-only">
        <div className="text-3 col-12 col-md-6">Share with:</div>
        <div className="col-12 col-md-6 radio-div">
          {radioOptions.map((option: string, i) => {
            const tooltipMessage = getTooltipMessage(option, isFile)
            return (
              <label key={i} aria-hidden className="text-3 radio-header">
                {tooltipMessage && (
                  <Tooltip id={`${option}-tooltip`} message={tooltipMessage} />
                )}
                {option}
              </label>
            )
          })}
        </div>
      </div>
      {sharingOptions.map((sharingOption) => {
        const checkedOption = sharingOption.option
        return (
          <div
            role="group"
            className="radio-row fieldset"
            key={sharingOption.contactId}
            aria-labelledby="node-sharing-legend"
          >
            <div
              id="node-sharing-legend"
              className="col-12 col-md-6 fs-m fw-500 radio-group-legend"
            >
              {sharingOption.label}
              {sharingOption.labelDescription && (
                <span className="label-description">
                  {sharingOption.labelDescription}
                </span>
              )}
            </div>
            <div
              className={`col-12 col-md-6 radio-div ${
                !showEditOption ? "no-edit" : ""
              }`}
            >
              {radioOptions.map((option: string, i) => {
                const isChecked =
                  checkedOption.includes(option) ||
                  option.includes(checkedOption)
                const tooltipMessage = getTooltipMessage(option, isFile)
                return (
                  <label key={i} id={option.trim().replaceAll(" ", "-")}>
                    <span className="radio-header text-3">
                      {tooltipMessage && (
                        <Tooltip id={option} message={tooltipMessage} />
                      )}
                      {option}
                    </span>
                    <Radio
                      ariaLabelledBy={option.trim().replaceAll(" ", "-")}
                      name={sharingOption.contactId}
                      value={option}
                      isDisabled={sharingOption.disabledOptions?.includes(
                        option
                      )}
                      isChecked={isChecked}
                      onChange={(selectOption: string) => {
                        const newSharingOptions = sharingOptions.map((o) => {
                          if (o.contactId === sharingOption.contactId) {
                            return {
                              ...o,
                              option: selectOption,
                            }
                          }
                          return o
                        })

                        if (setSharingOptions) {
                          setSharingOptions(newSharingOptions)
                        }
                      }}
                    />
                  </label>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const SharingPermissions: React.FC<SharingPermissionsProps> = ({
  sharingOptions,
  setSharingOptions,
  showImportedFolderWarning,
  isAddingContactThroughWorkflowDocument = false,
}) => {
  const allSelected = getAllSelectedValue(sharingOptions)
  const disabledSelectAlls = getDisabledSelectAlls(
    neverReadonlyEdit,
    sharingOptions
  )
  const removedOptions = sharingOptions[0]?.removedOptions

  const onSelectAll = (selectedOption: string) => {
    if (setSharingOptions)
      setSharingOptions(
        sharingOptions.map((sharingOption) => ({
          ...sharingOption,
          ...(!sharingOption.disabledOptions?.includes(selectedOption) && {
            option: selectedOption,
          }),
        }))
      )
  }
  return (
    <div className="radio-button-group contact-sharing">
      <div className="radio-row">
        <h2 className="text-1 col-12 col-md-6">{sharingOptions[0]?.group}</h2>
        <div className="col-12 col-md-6 radio-div">
          {neverReadonlyEdit.map((option: string, i) => {
            const isRemoved = removedOptions?.includes(option)
            if (isRemoved) return <></>
            const tooltipMessage = getTooltipMessage(option, false)
            return (
              <label key={i} aria-hidden="true" className="text-3">
                {tooltipMessage && (
                  <Tooltip id={`${option}-tooltip`} message={tooltipMessage} />
                )}
                {option}
              </label>
            )
          })}
        </div>
      </div>
      <SelectAllPermissions
        className="radio-row fieldset no-margin select-all"
        checkedOption={allSelected?.option}
        disabledOptions={disabledSelectAlls}
        removeOptions={removedOptions}
        hideLabel
        groupName={`${sharingOptions[0]?.group}-select-all`}
        numberOfItems={sharingOptions.length}
        onChange={onSelectAll}
        isAddingContactThroughWorkflowDocument={
          isAddingContactThroughWorkflowDocument
        }
      />
      {Object(sharingOptions).map((optionGroup: TRadioOptions) => {
        const checkedOption = optionGroup.option
        return (
          <div
            role="group"
            className="radio-row fieldset item-row"
            key={optionGroup.nodeId}
            aria-labelledby="contact-sharing-legend"
          >
            <div
              id="contact-sharing-legend"
              className="col-12 col-md-6 text-2 item-name"
            >
              {optionGroup.label}
            </div>
            <div className="col-12 col-md-6 radio-div">
              {neverReadonlyEdit.map((option: string, i) => {
                const isRemoved = optionGroup.removedOptions?.includes(option)
                if (isRemoved) return <></>
                const isDisabled = optionGroup.disabledOptions?.includes(option)
                return (
                  <label
                    key={i}
                    onClick={
                      isDisabled && showImportedFolderWarning
                        ? () => {
                            showImportedFolderWarning()
                          }
                        : undefined
                    }
                  >
                    <span className="visually-hidden">{option}</span>
                    <Radio
                      name={optionGroup.nodeId}
                      value={option}
                      isChecked={option === checkedOption}
                      isDisabled={isDisabled}
                      onChange={(selectOption: string) => {
                        if (setSharingOptions) {
                          setSharingOptions([
                            { ...optionGroup, option: selectOption },
                          ])
                        }
                      }}
                    />
                  </label>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}
