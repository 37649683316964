const FolderImport = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="FolderImport"
  >
    <mask
      id="mask0_3410_76093"
      maskUnits="userSpaceOnUse"
      x="2"
      y="5"
      width="28"
      height="22"
    >
      <path
        stroke="none"
        className="reverse"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.82322 7.82322C3.87011 7.77634 3.9337 7.75 4 7.75H10.6667C10.7208 7.75 10.7734 7.76754 10.8167 7.8L14.2833 10.4C14.5863 10.6272 14.9547 10.75 15.3333 10.75H24C24.0663 10.75 24.1299 10.7763 24.1768 10.8232C24.2237 10.8701 24.25 10.9337 24.25 11V13.25H17.3028C16.9573 13.25 16.6195 13.3523 16.3321 13.5439L13.8359 15.208C13.7948 15.2354 13.7466 15.25 13.6972 15.25H7.67703C7.32728 15.25 6.98555 15.3548 6.69593 15.5509C6.40631 15.747 6.18209 16.0253 6.0522 16.3501L3.75 22.1056V8C3.75 7.9337 3.77634 7.87011 3.82322 7.82322ZM2.50709 26.5653C2.6389 26.6803 2.81132 26.75 3 26.75H25C25.3228 26.75 25.6094 26.5434 25.7115 26.2372L29.2728 15.5534C29.3605 15.2903 29.3844 15.0102 29.3426 14.7361C29.3007 14.462 29.1944 14.2017 29.0322 13.9768C28.8701 13.7518 28.6568 13.5686 28.41 13.4422C28.1632 13.3159 27.8899 13.25 27.6126 13.25H25.75V11C25.75 10.5359 25.5656 10.0908 25.2374 9.76256C24.9092 9.43437 24.4641 9.25 24 9.25H15.3333C15.2792 9.25 15.2266 9.23246 15.1833 9.2L11.7167 6.6C11.4137 6.37281 11.0453 6.25 10.6667 6.25H4C3.53587 6.25 3.09075 6.43438 2.76256 6.76256C2.43438 7.09075 2.25 7.53587 2.25 8V25.9976V26C2.25 26.1901 2.32074 26.3637 2.43733 26.4959C2.45914 26.5207 2.48246 26.5438 2.50709 26.5653ZM25 14.75H17.3028C17.2534 14.75 17.2052 14.7646 17.1641 14.792L14.668 16.4561C14.3805 16.6477 14.0427 16.75 13.6972 16.75H7.67703C7.62707 16.75 7.57825 16.765 7.53688 16.793C7.4955 16.821 7.46347 16.8608 7.44491 16.9072L4.10777 25.25H24.4594L27.8497 15.0791C27.8623 15.0415 27.8657 15.0015 27.8597 14.9623C27.8537 14.9231 27.8385 14.886 27.8154 14.8538C27.7922 14.8217 27.7618 14.7955 27.7265 14.7775C27.6912 14.7594 27.6522 14.75 27.6126 14.75H25Z"
      />
    </mask>
    <g mask="url(#mask0_3410_76093)">
      <path d="M15 3H-1V28H15V16.5H32V3H15Z" stroke="none" className="fill" />
    </g>
    <path
      stroke="none"
      className="fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1006 17.5868C25.7102 17.1962 25.077 17.1961 24.6864 17.5866C24.2958 17.9771 24.2957 18.6102 24.6862 19.0008L27.1984 21.5138L19.4142 21.5138C18.8619 21.5138 18.4142 21.9615 18.4142 22.5138C18.4142 23.0661 18.8619 23.5138 19.4142 23.5138L27.3649 23.5138L24.6862 26.1934C24.2957 26.584 24.2958 27.2172 24.6864 27.6076C25.077 27.9981 25.7102 27.998 26.1006 27.6074L30.4025 23.3041C30.7929 22.9136 30.7929 22.2806 30.4025 21.8901L26.1006 17.5868Z"
    />
  </svg>
)

export default FolderImport
