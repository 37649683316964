import { NodeDto } from "legado-generated-api-client/models";
import { getUserId } from "./getUser";
const pinnedData = require("../staticData/getPinnedFilesAndFolders.json");

type TGetFolders = (userEmail?: string) => NodeDto[];

const isPinnedNodeDto = (folder: any) => {
  if (folder as NodeDto[]) {
    return folder as NodeDto[];
  }
};

type TObj = { [key: string]: any };

export const getPinnedFilesAndFoldersFromLocalData: TGetFolders = (userEmail) => {
  const currentUserId = getUserId(userEmail);
  const pinnedDataObj = pinnedData as TObj;
  const currentUserPinnedData = pinnedDataObj[currentUserId];
  return isPinnedNodeDto(currentUserPinnedData) || [];
};
