const FileXLXS = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99902 17V6C5.99902 5.73478 6.10438 5.48043 6.29192 5.29289C6.47945 5.10536 6.73381 5 6.99902 5H19L26 12V17"
      fill="none"
    />
    <path
      d="M3.22187 27.1604C3.16136 27.1604 3.10757 27.1403 3.06051 27.0999C3.02017 27.0529 3 27.0024 3 26.9487C3 26.9016 3.01345 26.8612 3.04034 26.8276L5.2389 23.56L3.2017 20.4337C3.18153 20.3933 3.17145 20.353 3.17145 20.3126C3.17145 20.2589 3.19162 20.2118 3.23196 20.1714C3.2723 20.1244 3.32272 20.1009 3.38323 20.1009H4.84558C4.92626 20.1009 4.99349 20.121 5.04728 20.1614C5.10107 20.2017 5.15149 20.2521 5.19856 20.3126L6.39869 22.138L7.61899 20.3126C7.70639 20.1714 7.82405 20.1009 7.97197 20.1009H9.36371C9.4175 20.1009 9.46456 20.1244 9.50491 20.1714C9.55197 20.2118 9.5755 20.2589 9.5755 20.3126C9.5755 20.3597 9.56205 20.4 9.53516 20.4337L7.48788 23.55L9.70661 26.8276C9.72678 26.8545 9.73686 26.8949 9.73686 26.9487C9.73686 27.0024 9.71669 27.0529 9.67635 27.0999C9.63601 27.1403 9.58559 27.1604 9.52508 27.1604H8.02239C7.8812 27.1604 7.76354 27.0932 7.66941 26.9587L6.33818 25.0224L5.01702 26.9587C4.94307 27.0932 4.82877 27.1604 4.67413 27.1604H3.22187Z"
      stroke="none"
    />
    <path
      d="M10.8102 27.1604C10.743 27.1604 10.6825 27.1369 10.6287 27.0898C10.5816 27.0361 10.5581 26.9755 10.5581 26.9083V20.353C10.5581 20.279 10.5816 20.2185 10.6287 20.1714C10.6825 20.1244 10.743 20.1009 10.8102 20.1009H12.1415C12.2154 20.1009 12.2759 20.1244 12.323 20.1714C12.3701 20.2185 12.3936 20.279 12.3936 20.353V25.6376H15.4494C15.5233 25.6376 15.5838 25.6645 15.6309 25.7183C15.678 25.7653 15.7015 25.8258 15.7015 25.8998V26.9083C15.7015 26.9755 15.678 27.0361 15.6309 27.0898C15.5838 27.1369 15.5233 27.1604 15.4494 27.1604H10.8102Z"
      stroke="none"
    />
    <path
      d="M16.2025 27.1604C16.142 27.1604 16.0882 27.1403 16.0412 27.0999C16.0008 27.0529 15.9807 27.0024 15.9807 26.9487C15.9807 26.9016 15.9941 26.8612 16.021 26.8276L18.2196 23.56L16.1824 20.4337C16.1622 20.3933 16.1521 20.353 16.1521 20.3126C16.1521 20.2589 16.1723 20.2118 16.2126 20.1714C16.253 20.1244 16.3034 20.1009 16.3639 20.1009H17.8262C17.9069 20.1009 17.9742 20.121 18.0279 20.1614C18.0817 20.2017 18.1322 20.2521 18.1792 20.3126L19.3793 22.138L20.5996 20.3126C20.687 20.1714 20.8047 20.1009 20.9526 20.1009H22.3444C22.3982 20.1009 22.4452 20.1244 22.4856 20.1714C22.5326 20.2118 22.5562 20.2589 22.5562 20.3126C22.5562 20.3597 22.5427 20.4 22.5158 20.4337L20.4685 23.55L22.6873 26.8276C22.7074 26.8545 22.7175 26.8949 22.7175 26.9487C22.7175 27.0024 22.6973 27.0529 22.657 27.0999C22.6167 27.1403 22.5662 27.1604 22.5057 27.1604H21.003C20.8619 27.1604 20.7442 27.0932 20.6501 26.9587L19.3188 25.0224L17.9977 26.9587C17.9237 27.0932 17.8094 27.1604 17.6548 27.1604H16.2025Z"
      stroke="none"
    />
    <path
      d="M25.9543 27.2613C25.3156 27.2613 24.7676 27.1672 24.3104 26.9789C23.8532 26.7907 23.507 26.5452 23.2717 26.2427C23.0431 25.9334 22.922 25.5972 22.9086 25.2342C22.9086 25.1804 22.9288 25.1333 22.9691 25.093C23.0162 25.0459 23.0699 25.0224 23.1305 25.0224H24.4012C24.4819 25.0224 24.5457 25.0392 24.5928 25.0728C24.6466 25.0997 24.7004 25.1434 24.7542 25.2039C24.8416 25.3787 24.9828 25.5233 25.1777 25.6376C25.3727 25.7519 25.6316 25.809 25.9543 25.809C26.3375 25.809 26.63 25.7485 26.8317 25.6275C27.0334 25.5065 27.1343 25.3418 27.1343 25.1333C27.1343 24.9854 27.0805 24.8644 26.9729 24.7703C26.872 24.6761 26.7107 24.5921 26.4888 24.5181C26.2669 24.4442 25.9375 24.3601 25.5005 24.266C24.6937 24.0979 24.0885 23.8525 23.6851 23.5298C23.2885 23.2003 23.0901 22.7331 23.0901 22.128C23.0901 21.7178 23.2044 21.3514 23.433 21.0287C23.6616 20.706 23.9877 20.4538 24.4113 20.2723C24.8348 20.0908 25.329 20 25.8938 20C26.4787 20 26.9863 20.1009 27.4166 20.3026C27.8469 20.5043 28.173 20.7597 28.3949 21.069C28.6235 21.3716 28.7445 21.6708 28.758 21.9666C28.758 22.0271 28.7378 22.0775 28.6974 22.1179C28.6571 22.1582 28.6067 22.1784 28.5462 22.1784H27.2149C27.1343 22.1784 27.067 22.1649 27.0132 22.138C26.9662 22.1112 26.9225 22.0675 26.8821 22.0069C26.8418 21.8523 26.7308 21.7212 26.5493 21.6136C26.3745 21.506 26.156 21.4523 25.8938 21.4523C25.5912 21.4523 25.3593 21.506 25.1979 21.6136C25.0365 21.7212 24.9559 21.8792 24.9559 22.0876C24.9559 22.2288 24.9996 22.3465 25.087 22.4406C25.1811 22.5347 25.329 22.6221 25.5307 22.7028C25.7391 22.7768 26.035 22.8541 26.4182 22.9348C27.0435 23.0491 27.541 23.1936 27.9108 23.3684C28.2873 23.5365 28.563 23.7584 28.7378 24.034C28.9126 24.303 29 24.6425 29 25.0526C29 25.5031 28.8689 25.8964 28.6067 26.2326C28.3512 26.5621 27.9915 26.8175 27.5276 26.9991C27.0704 27.1739 26.546 27.2613 25.9543 27.2613Z"
      stroke="none"
    />
    <path d="M19 5V12H26.001" fill="none" />
  </svg>
)
export default FileXLXS
