import { Industry } from "legado-generated-api-client/models";
const industries = require("../staticData/getIndustries.json");

type TGetIndustries = () => Industry[];

const isIndustryDtos = (industries: any) => {
  if ((industries as Industry[])?.length) {
    return industries as Industry[];
  }
};

export const getIndustriesFromLocalData: TGetIndustries = () => {
  return isIndustryDtos(industries) || [];
};
