import { TApplicationAction, IApplicationState } from "./constants"
import {
  SET_SELECTED_FOLDER,
  SET_FOLDERS,
  SET_SHARED_WITH_ME_FOLDERS,
  DELETE_FOLDER,
  RENAME_FOLDER,
  ADD_FOLDER,
  SET_SUGGESTED_FOLDERS,
  SET_PINNED_FOLDERS_FILES,
  UPDATE_PINNED_FOLDER_OR_FILE,
  RENAME_FOLDER_IN_SHARED_WITH_ME,
  DELETE_FOLDER_IN_SHARED_WITH_ME,
  ADD_FOLDER_IN_SHARED_WITH_ME,
  REPLACE_FOLDER,
  REPLACE_SHARED_WITH_ME_FOLDER,
  UPDATE_FOLDER,
} from "./action"
import {
  replaceFolder,
  updateFolder,
} from "../../api/api-client/folder/findFolderDoSomething"
import { findAndDeleteFolder } from "../../api/api-client/folder/findAndDeleteFolder"
import { findAndRenameFolder } from "../../api/api-client/folder/findAndRenameFolder"
import cloneDeep from "clone-deep"
import { addFolder } from "../../api/api-client/folder/addFolderToFolders"
import { findAndUpdateParentFolders } from "../../api/api-client/folder/findAndUpdateParentFolders"
import { getUpdatedPinnedFoldersFiles } from "../../api/api-client/folder/getUpdatedPinnedFoldersFiles"

export const folderReducer = (
  applicationState: IApplicationState,
  action: TApplicationAction
) => {
  switch (action.type) {
    case REPLACE_SHARED_WITH_ME_FOLDER:
      if (action.folder) {
        return {
          ...applicationState,
          sharedWithMeFolders: replaceFolder({
            folders: applicationState.sharedWithMeFolders!,
            newFolder: action.folder,
          }),
        }
      }
      break

    case REPLACE_FOLDER:
      if (action.folder) {
        return {
          ...applicationState,
          folders: replaceFolder({
            folders: applicationState.folders,
            newFolder: action.folder,
          }),
        }
      }
      break

    case UPDATE_FOLDER:
      if (action.folder) {
        return {
          ...applicationState,
          folders: updateFolder({
            folders: applicationState.folders,
            newFolder: action.folder,
          }),
        }
      }
      break

    case SET_SELECTED_FOLDER:
      return {
        ...applicationState,
        selectedFolderId: action.folderId,
      }

    case SET_FOLDERS:
      if (action.folders) {
        return {
          ...applicationState,
          folders: action.folders,
        }
      }
      break

    case SET_SHARED_WITH_ME_FOLDERS:
      if (action.folders) {
        return {
          ...applicationState,
          sharedWithMeFolders: action.folders,
        }
      }
      break

    case DELETE_FOLDER:
      if (action.folderId) {
        const folderId = action.folderId
        let folders = cloneDeep(applicationState.folders)
        if (!folders) {
          return
        }
        const filteredFolders = folders.filter(
          (child) => child.id !== action.folderId
        )
        if (filteredFolders.length < folders.length) {
          folders = filteredFolders
        } else {
          findAndDeleteFolder(folders, folderId)
          findAndUpdateParentFolders(folders, folderId)
        }

        return {
          ...applicationState,
          folders: folders,
        }
      }
      break

    case RENAME_FOLDER:
      const name = action.folderName
      if (!name) {
        return
      }
      const folderId = action.folderId
      if (!folderId) {
        return
      }
      const folders = cloneDeep(applicationState.folders)
      if (!folders) {
        return
      }
      findAndRenameFolder(folders, name, folderId)
      findAndUpdateParentFolders(folders, folderId)
      return {
        ...applicationState,
        folders: folders,
      }

    case ADD_FOLDER:
      if (action.newFolder) {
        const newFolders = addFolder({
          newFolder: action.newFolder,
          folders: applicationState.folders,
        })
        return {
          ...applicationState,
          folders: newFolders,
        }
      }
      break

    case SET_SUGGESTED_FOLDERS:
      if (!action.suggestedFolders) {
        return
      }

      return {
        ...applicationState,
        suggestedFolders: action.suggestedFolders,
      }

    case SET_PINNED_FOLDERS_FILES:
      const pinned = action.pinnedFoldersAndFiles
      if (!pinned) {
        return
      }

      return {
        ...applicationState,
        pinnedFoldersAndFiles: pinned,
      }

    case UPDATE_PINNED_FOLDER_OR_FILE:
      const node = action.pinnedFolderOrFile
      const pinnedFoldersFiles = cloneDeep(
        applicationState.pinnedFoldersAndFiles
      )
      if (!node || action.isPinned === undefined || !pinnedFoldersFiles) {
        return
      }
      const newPinnedFoldersAndFiles = getUpdatedPinnedFoldersFiles({
        pinnedFoldersFiles,
        nodeToUpdate: node,
        isPinned: action.isPinned,
      })
      return {
        ...applicationState,
        pinnedFoldersAndFiles: newPinnedFoldersAndFiles,
      }

    case RENAME_FOLDER_IN_SHARED_WITH_ME:
      const sharedFolderName = action.folderName
      if (!sharedFolderName) {
        return
      }
      const sharedFolderId = action.folderId
      if (!sharedFolderId) {
        return
      }
      const sharedFolders = cloneDeep(applicationState.sharedWithMeFolders)
      if (!sharedFolders) {
        return
      }
      findAndRenameFolder(sharedFolders, sharedFolderName, sharedFolderId)
      findAndUpdateParentFolders(sharedFolders, sharedFolderId)
      return {
        ...applicationState,
        sharedWithMeFolders: sharedFolders,
      }

    case DELETE_FOLDER_IN_SHARED_WITH_ME:
      const deleteSharedFolderId = action.folderId
      if (!deleteSharedFolderId) {
        return
      }
      const sharedWithMeFolders = cloneDeep(
        applicationState.sharedWithMeFolders
      )
      if (!sharedWithMeFolders) {
        return
      }
      findAndDeleteFolder(sharedWithMeFolders, deleteSharedFolderId)
      findAndUpdateParentFolders(sharedWithMeFolders, deleteSharedFolderId)
      return {
        ...applicationState,
        sharedWithMeFolders: sharedWithMeFolders,
      }

    case ADD_FOLDER_IN_SHARED_WITH_ME:
      const addToSharedFolder = action.folder
      if (!addToSharedFolder || !applicationState.sharedWithMeFolders) {
        return
      }

      const newFolders = addFolder({
        newFolder: addToSharedFolder,
        folders: applicationState.sharedWithMeFolders,
      })

      return {
        ...applicationState,
        sharedWithMeFolders: newFolders,
      }

    default:
      return
  }
}
