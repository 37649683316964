import { OrganisationDto } from "legado-generated-api-client"

export const getOrganisationResponse: OrganisationDto = {
  organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe7e5f",
  type: "NonIntermediary",
  name: "Orn - Romaguera Advisers",
  network: null,
  isSingleAdviserCompany: false,
  featurePlanId: null,
  created: new Date("2021-02-09T13:35:40.1844844"),
}
