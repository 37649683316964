import { SuggestedFolderDto } from "legado-generated-api-client/models";
const suggestedFolders = require("../staticData/getSuggestedFolders.json");

type TGetSuggestedFolders = () => SuggestedFolderDto[];

const isSuggestedFolderDtos = (folders: any) => {
  if ((folders as SuggestedFolderDto[])?.length) {
    return folders as SuggestedFolderDto[];
  }
};

export const getSuggestedFoldersFromLocalData: TGetSuggestedFolders = () => {
  return isSuggestedFolderDtos(suggestedFolders) || [];
};
