import {
  FC,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useState,
  useRef,
} from "react"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { useOutsideAlerter } from "../../../utils/outsideClickAlerter"
import "./formSelect.css"
import { ValidationErrorLabel } from "../ValidationErrorLabel/ValidationErrorLabel"
import classNames from "classnames"

export interface SelectOptionWithType {
  icon?: ReactNode
  label: string
  value: string
}

export type SelectProps = {
  id?: string
  labelClassName?: string
  label?: string
  options: SelectOptionWithType[]
  selectedValue?: string
  setSelectedValue?: (value: string) => void
  isDisabled?: boolean
  required?: boolean
  withRadios?: boolean
  description?: string
  closeOnSelect?: boolean
  placeHolder?: string
  disableScrollAnchor?: boolean
  shouldShowError?: boolean
  shouldShowErrorIcon?: boolean
  errorMessageContent?: string
}

export const Select: FC<SelectProps> = ({
  id,
  label,
  labelClassName = "",
  options,
  selectedValue,
  setSelectedValue,
  isDisabled,
  withRadios,
  description,
  closeOnSelect = false,
  placeHolder,
  disableScrollAnchor,
  shouldShowError = false,
  shouldShowErrorIcon = false,
  errorMessageContent = "Please select an option",
}) => {
  const [open, setOpen] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [activeIndex, setActiveIndex] = useState(-1)

  const scrollAnchor = useRef<HTMLDivElement>(null)

  const IconChevronUp = useGetIcon("IconChevronUp")
  const IconChevronDown = useGetIcon("IconChevronDown")
  const selectId = id ? id : options.map((o) => o.value).join("")
  useEffect(() => {
    if (!open || disableScrollAnchor) return
    scrollAnchor.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    })
  }, [open, disableScrollAnchor])

  const handleKeyDown = (event: KeyboardEvent<HTMLLabelElement>) => {
    if (!isDisabled) {
      const key = event.key
      switch (key) {
        case "Escape":
          setOpen(false)
          break
        case "Enter":
        case " ":
          if (activeIndex !== -1) {
            const selectedValue = options[activeIndex].value
            setSelectedValue && setSelectedValue(selectedValue)
          } else setActiveIndex(0)
          setOpen(!open)
          break
        case "ArrowDown":
          if (!open) {
            setOpen(true)
            if (activeIndex === -1) setActiveIndex(0)
          } else
            setActiveIndex(
              activeIndex < options.length - 1 ? activeIndex + 1 : 0
            )
          break
        case "ArrowUp":
          if (activeIndex === 0) {
            setOpen(false)
            setActiveIndex(-1)
          } else setActiveIndex(activeIndex - 1)
          break
        default:
          const search: string = searchInput.concat(event.key).toLowerCase()
          const matchingIndex = options.findIndex((option) =>
            option.label.toLowerCase().startsWith(search)
          )
          const hasMatch = matchingIndex !== -1
          setSearchInput(hasMatch ? search : "")
          if (hasMatch) {
            setActiveIndex(matchingIndex)
            if (!open) setOpen(true)
          }
      }
    }
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, setOpen)

  const formSelectOuterWrapperClassNames = classNames("selected-value-field", {
    error: shouldShowError,
  })

  return (
    <label
      htmlFor={selectId}
      onBlur={() => setOpen(false)}
      onKeyDown={handleKeyDown}
      className={`select-wrapper ${isDisabled && "disabled"}`}
      onClick={() => !isDisabled && setOpen(!open)}
    >
      {(label || description) && (
        <div>
          {label && (
            <span className={`select-label ${labelClassName}`}>{label}</span>
          )}
          {description && (
            <span className="select-label description">{description}</span>
          )}
        </div>
      )}
      {shouldShowError && (
        <ValidationErrorLabel
          id="input-error"
          errorContent={errorMessageContent}
          visible={shouldShowError}
          shouldShowWarningIcon={shouldShowErrorIcon}
        />
      )}
      <div role="menu" className="form-select">
        <div className="outer-wrapper">
          <div
            className={formSelectOuterWrapperClassNames}
            data-testid="dev-modal-dropdown-form"
            aria-controls="select-listbox"
            aria-expanded={open}
            aria-haspopup="listbox"
            aria-labelledby={selectId}
            role="combobox"
            tabIndex={isDisabled ? -1 : 0}
            onClick={() => !isDisabled && setOpen(!open)}
            ref={wrapperRef}
            id={id}
          >
            <span
              className="select-label-value"
              data-testid="dev-modal-dropdown-label"
            >
              {selectedValue && selectedValue.trim() !== ""
                ? selectedValue
                : activeIndex >= 0
                ? options[activeIndex].label
                : placeHolder}
            </span>
            <span className="open-close-svg">
              {open ? IconChevronUp : IconChevronDown}
            </span>
          </div>
        </div>
        <div
          role="listbox"
          id="select-listbox"
          aria-labelledby={selectId}
          className={`${open ? "select-list" : "visually-hidden"}`}
        >
          {options.map((option, index) => (
            <div
              key={option.value}
              data-testid={"dev-modal-user-" + option.value}
              className={`select-option ${withRadios ? "with-radios" : ""}`}
              role="option"
              aria-selected={activeIndex === index}
              onMouseDown={(e) => {
                setActiveIndex(index)
                setSelectedValue && setSelectedValue(option.value)
                e.preventDefault()
                e.stopPropagation()
                if (closeOnSelect) {
                  setOpen(false)
                }
              }}
              onClick={() => {
                setActiveIndex(index)
                setSelectedValue && setSelectedValue(option.value)
              }}
            >
              {withRadios && (
                <span aria-hidden={!open} className="fake-radio" />
              )}
              {option.label}
            </div>
          ))}
          <div ref={scrollAnchor}></div>
        </div>
      </div>
    </label>
  )
}

Select.displayName = "Select"
