const WelcomeShare = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" fill="none" stroke="none" />
    <path
      d="M38.148 17.2214L21.5885 26.2359M37.7849 42.2107L21.8517 33.5371"
      className="second-color-stroke"
      strokeWidth="3"
      strokeLinejoin="round"
    />
    <path
      d="M50.5 14.6049C50.5 17.9474 47.7247 20.7099 44.2353 20.7099V23.7099C49.3225 23.7099 53.5 19.6627 53.5 14.6049H50.5ZM44.2353 20.7099C40.7458 20.7099 37.9706 17.9474 37.9706 14.6049H34.9706C34.9706 19.6627 39.1481 23.7099 44.2353 23.7099V20.7099ZM37.9706 14.6049C37.9706 11.2625 40.7458 8.5 44.2353 8.5V5.5C39.1481 5.5 34.9706 9.54717 34.9706 14.6049H37.9706ZM44.2353 8.5C47.7247 8.5 50.5 11.2625 50.5 14.6049H53.5C53.5 9.54717 49.3225 5.5 44.2353 5.5V8.5Z"
      stroke="none"
    />
    <path
      d="M22.0294 29.8148C22.0294 33.1572 19.2542 35.9197 15.7647 35.9197V38.9197C20.8519 38.9197 25.0294 34.8726 25.0294 29.8148H22.0294ZM15.7647 35.9197C12.2753 35.9197 9.5 33.1572 9.5 29.8148H6.5C6.5 34.8726 10.6775 38.9197 15.7647 38.9197V35.9197ZM9.5 29.8148C9.5 26.4724 12.2753 23.7099 15.7647 23.7099V20.7099C10.6775 20.7099 6.5 24.757 6.5 29.8148H9.5ZM15.7647 23.7099C19.2542 23.7099 22.0294 26.4724 22.0294 29.8148H25.0294C25.0294 24.757 20.8519 20.7099 15.7647 20.7099V23.7099Z"
      stroke="none"
    />
    <path
      d="M50.5 45.0247C50.5 48.3671 47.7247 51.1296 44.2353 51.1296V54.1296C49.3225 54.1296 53.5 50.0824 53.5 45.0247H50.5ZM44.2353 51.1296C40.7458 51.1296 37.9706 48.3671 37.9706 45.0247H34.9706C34.9706 50.0824 39.1481 54.1296 44.2353 54.1296V51.1296ZM37.9706 45.0247C37.9706 41.6823 40.7458 38.9197 44.2353 38.9197V35.9197C39.1481 35.9197 34.9706 39.9669 34.9706 45.0247H37.9706ZM44.2353 38.9197C47.7247 38.9197 50.5 41.6823 50.5 45.0247H53.5C53.5 39.9669 49.3225 35.9197 44.2353 35.9197V38.9197Z"
      stroke="none"
    />
  </svg>
)

export default WelcomeShare
