const Cross = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fillRule="evenodd"
    clipRule="evenodd"
    xmlns="http://www.w3.org/2000/svg"
    id="Cross"
    data-testid="Cross"
  >
    <g clipPath="url(#clip0_32_539)">
      <path
        d="M7.27899 24.721C6.75829 24.2003 6.75829 23.3561 7.27899 22.8354L22.8353 7.27902C23.356 6.75832 24.2003 6.75832 24.721 7.27902C25.2417 7.79972 25.2417 8.64394 24.721 9.16464L9.16461 24.721C8.64391 25.2417 7.79969 25.2417 7.27899 24.721Z"
        stroke="none"
      />
      <path
        d="M7.27899 7.27902C7.79969 6.75832 8.64391 6.75832 9.16461 7.27902L24.721 22.8354C25.2417 23.3561 25.2417 24.2003 24.721 24.721C24.2003 25.2417 23.356 25.2417 22.8353 24.721L7.27899 9.16463C6.75829 8.64393 6.75829 7.79971 7.27899 7.27902Z"
        stroke="none"
      />
    </g>
    <defs>
      <clipPath id="clip0_32_539">
        <rect width="32" height="32" className="reverse" />
      </clipPath>
    </defs>
  </svg>
)
export default Cross
