import { TTheme } from "../types"
import animation from "../tokens/amadeus/animation.json"
import breakpoints from "../tokens/amadeus/breakpoints.json"
import buttons from "../tokens/amadeus/buttons.json"
import colors from "../tokens/amadeus/colors.json"
import iconsizing from "../tokens/amadeus/iconsizing.json"
import overlay from "../tokens/amadeus/overlay.json"
import radius from "../tokens/amadeus/radius.json"
import shadows from "../tokens/amadeus/shadows.json"
import typeface from "../tokens/amadeus/typeface.json"
import typography from "../tokens/amadeus/typography.json"

const tokens = {
  mode: "AMADEUS",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  typeface,
  typography,
} as TTheme
export default tokens
