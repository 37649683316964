import { FilesWithTagsDto } from "legado-generated-api-client"
import { TSearchTagResult } from "../../contexts/application/constants"

export const searchTags = ({
  expression,
  filesAndTags,
}: {
  expression: string
  filesAndTags: FilesWithTagsDto[]
}) => {
  if (filesAndTags.length === 0) return [] as TSearchTagResult[]
  const foundTags = [] as FilesWithTagsDto[]
  filesAndTags.forEach((fileAndTags) => {
    if (fileAndTags.tags) {
      fileAndTags.tags.forEach((tag) => {
        const match = tag
          .toLowerCase()
          .includes(expression.toLowerCase().trim())
        if (match) {
          foundTags.push({ fileId: fileAndTags.fileId, tags: [tag] })
        }
      })
    }
  })
  return findTagsForFiles(foundTags)
}

//Takes files with tags and arranges to have each tag with associated files
export function findTagsForFiles(
  fileswithTags: FilesWithTagsDto[]
): TSearchTagResult[] {
  const tagResults = [] as TSearchTagResult[]
  fileswithTags.forEach((foundTag) => {
    if (foundTag.tags) {
      foundTag.tags.forEach((tag) => {
        const existingTag = tagResults.find((res) => res.tagName === tag)
        if (existingTag) {
          existingTag.files.push(foundTag.fileId ?? "")
        } else {
          tagResults.push({
            tagName: tag,
            files: [foundTag.fileId ?? ""],
          })
        }
      })
    }
  })
  return tagResults
}
