import { Dispatch } from "react"
import {
  getAllFolders,
  getFilesAndFoldersPinned,
  getFoldersSharedByUser,
  getIUser,
  getIndustries,
  getNodesSharedWithContact,
  getNotifications,
  getOrgContacts,
  getProviders,
  getUserContacts,
  getUsersSharingWithCurrentUser,
} from "../../api/api-client/api-handler"
import {
  IContact,
  IFolder,
  INodeShare,
  IProviderStatus,
  IUser,
} from "../../api/api-client/api-types"
import { ApiController } from "../../api/apiController"
import { sortSections } from "../../component/FolderCard/helpers"
import { showSuccessOrErrorMessage } from "../../component/modules/NotificationPopUp/NotificationPopUp"
import {
  setAuthLoading,
  setClients,
  setContactList,
  setFlatFileList,
  setHeadAccountId,
  setPersonId,
  setIndustries,
  setLoadingState,
  setNotifications,
  setPinnedFoldersFiles,
  setProviderStatuses,
  setProviders,
  setSelectedFile,
  setSelectedUserSharingWithMe,
  setSharesAllContacts,
  setShowMenu,
  setUserFolders,
  setUsersSharingWithMe,
} from "../application/action"
import { UserModuleType } from "../application/constants"
import { IToastAction } from "../toasts/reducer"

const getUserFolders = async ({
  dispatch,
  toastDispatch,
}: {
  dispatch: (value: any) => void
  toastDispatch: Dispatch<IToastAction>
}) => {
  const apiController = ApiController.getInstance()
  const folders = await getAllFolders()
  const statuses = await apiController.getProviderAccountStatuses()
  if (statuses as IProviderStatus[]) {
    const providerStatuses = statuses as IProviderStatus[]
    dispatch(setProviderStatuses(providerStatuses as IProviderStatus[]))
  }
  if (folders) {
    const sortedSections = sortSections(folders)
    dispatch(setUserFolders(sortedSections))
  } else {
    showSuccessOrErrorMessage({
      hasError: true,
      methodName: "getFolders",
      toastDispatch,
    })
  }
}

const getPinnedFoldersAndFiles = async ({
  dispatch,
  toastDispatch,
}: {
  dispatch: Dispatch<ReturnType<typeof setPinnedFoldersFiles>>
  toastDispatch: Dispatch<IToastAction>
}) => {
  const pinnedFoldersAndFiles = await getFilesAndFoldersPinned()
  if (pinnedFoldersAndFiles as IFolder) {
    dispatch(
      setPinnedFoldersFiles({
        pinnedFoldersAndFiles: pinnedFoldersAndFiles as IFolder,
      })
    )
  } else {
    showSuccessOrErrorMessage({
      hasError: true,
      methodName: "getPinnedFoldersAndFiles",
      toastDispatch,
    })
  }
}

const getContacts = async ({
  dispatch,
}: {
  dispatch: (value: any) => void
}) => {
  let contactList = [] as IContact[]
  var userContacts = await getUserContacts()
  var orgContacts = (await getOrgContacts()) as IContact[]
  contactList = [...userContacts, ...orgContacts]
  dispatch(setContactList(contactList))
  return contactList
}

export const getContactsAccessFoldersAndFilesPermissions = async (
  dispatch: (value: any) => void,
  contacts?: IContact[]
) => {
  if (!contacts) {
    return
  }
  const shares = await Promise.all(
    contacts.map(async (contact) => {
      return await getNodesSharedWithContact({
        contactId: contact.contactId!,
      })
    })
  )

  const allShares = [] as INodeShare[]
  shares.forEach((sharesForContact) => {
    if (sharesForContact && sharesForContact[0]) {
      sharesForContact.forEach((s) => {
        if (s) {
          allShares.push(s)
        }
      })
    }
  })
  dispatch(setSharesAllContacts(allShares))
}

export const getUserNotificationsFromApi = async ({
  dispatch,
}: {
  dispatch: (value: any) => void
}) => {
  let notifications = await getNotifications()
  if (notifications && notifications.length > 0) {
    notifications = notifications
      .sort((x) => new Date(x.date).getTime())
      .reverse()
  }
  dispatch(setNotifications(notifications))
  return notifications
}

export const loadUserData = async ({
  currentUser,
  dispatch,
  toastDispatch,
}: {
  currentUser?: IUser
  dispatch: (value: any) => void
  toastDispatch: Dispatch<IToastAction>
}) => {
  const params = new URLSearchParams(window.location.search)
  const clientEmailInQueryString = params.get("client")
  const contextInQueryString = params.get("context")

  if (currentUser?.modules?.includes(UserModuleType.VAULT)) {
    await getUserFolders({ dispatch, toastDispatch })
    await getPinnedFoldersAndFiles({ dispatch, toastDispatch })
    dispatch(setLoadingState(false))
  }
  /**TODO: this call should be made within the notifications page to be refactored under
   * https://dev.azure.com/secure-the-file/Application/_workitems/edit/15407
   */
  if (currentUser?.modules?.includes(UserModuleType.NOTIFICATIONS)) {
    await getUserNotificationsFromApi({ dispatch })
    dispatch(setLoadingState(false))
  }

  if (currentUser?.modules?.includes(UserModuleType.SHARING)) {
    const contacts = await getContacts({ dispatch })
    await getContactsAccessFoldersAndFilesPermissions(dispatch, contacts)
    dispatch(setLoadingState(false))
  }

  if (
    (currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_LIST) ||
      currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_VIEW) ||
      currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_EDIT)) &&
    !currentUser.roles?.includes("Adviser")
  ) {
    const userResponse = await getUsersSharingWithCurrentUser()
    dispatch(setUsersSharingWithMe(userResponse))
    //Handles users with only one sharing user / one shared file
    if (
      userResponse.length === 1 &&
      !currentUser?.modules?.includes(UserModuleType.VAULT)
    ) {
      dispatch(setSelectedUserSharingWithMe(userResponse[0].userId))
      const res = await isSharingOneFile({ userId: userResponse[0].userId })
      res && dispatch(setSelectedFile(res.id))
    }
  }
  if (currentUser?.modules?.includes(UserModuleType.CONNECTIVITY)) {
    const p = await getProviders()
    const i = await getIndustries()
    dispatch(setProviders(p))
    dispatch(setIndustries(i))
  }

  if (
    currentUser?.modules?.includes(
      UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW
    )
  ) {
    if (clientEmailInQueryString) {
      const client = await getIUser({ email: clientEmailInQueryString })
      if (client) {
        dispatch(setClients([client]))
        dispatch(setSelectedUserSharingWithMe(client?.userId))
      }
    } else if (contextInQueryString) {
      //this is the base context value for fnz api:
      //It can be for an account ID or a person Id
      //context=account:001-0000000646 or context=015-0000000646
      const accountLabel = "account:";
      if(contextInQueryString.startsWith(accountLabel)) {
        //we need to remove the "account:" part for accounts
        const accountId = contextInQueryString.replace(accountLabel, "")
        dispatch(setHeadAccountId(accountId))        
      } else {
        dispatch(setPersonId(contextInQueryString))        
      }
      dispatch(setShowMenu(false))
    }
  }

  if (currentUser?.modules?.includes(UserModuleType.DOCUMENT_LIBRARY)) {
    const api = ApiController.getInstance()

    const flatFileList = await ApiController.getInstance().getFlatFileList({
      shouldRefreshCache: false,
    })
    dispatch(setFlatFileList(flatFileList))
  }

  if (currentUser) {
    dispatch(setAuthLoading(false))
  }
  dispatch(setLoadingState(false))
}

const isSharingOneFile = async ({ userId }: { userId: string }) => {
  const folderResponse = await getFoldersSharedByUser(userId)
  if (folderResponse.length === 1 && folderResponse[0].contents?.length === 1) {
    return folderResponse[0].contents[0]
  }
  return null
}
