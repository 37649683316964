const Secure = () => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 15.2327C11.9492 15.2327 12.7188 14.4632 12.7188 13.514C12.7188 12.5647 11.9492 11.7952 11 11.7952C10.0508 11.7952 9.28125 12.5647 9.28125 13.514C9.28125 14.4632 10.0508 15.2327 11 15.2327Z"
      stroke="#213232"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 15.2327V17.2952"
      stroke="#213232"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.875 9.04523H4.125C3.7453 9.04523 3.4375 9.35303 3.4375 9.73273V19.3577C3.4375 19.7374 3.7453 20.0452 4.125 20.0452H17.875C18.2547 20.0452 18.5625 19.7374 18.5625 19.3577V9.73273C18.5625 9.35303 18.2547 9.04523 17.875 9.04523Z"
      stroke="#213232"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.90625 9.04523V5.95148C7.90625 5.13096 8.2322 4.34406 8.81239 3.76387C9.39258 3.18367 10.1795 2.85773 11 2.85773C11.8205 2.85773 12.6074 3.18367 13.1876 3.76387C13.7678 4.34406 14.0938 5.13096 14.0938 5.95148V9.04523"
      stroke="#213232"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Secure
