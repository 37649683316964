import { NodeShareDto } from "legado-generated-api-client"

export const getContactsFromLocalDataForNodeResponse = [
  {
    nodeId: '347aaf53-b20b-45d9-b2ed-00d5a0ac7289',
    contactId: '0be6390b-df09-4e07-a503-0fb030f3979b',
    name: 'Jack Horner',
    contactType: 'User',
    canRead: false,
    canEdit: true,
    sharedAfterDeath: false,
    dateShared: new Date("2021-04-29T19:32:53.7722351"),
  },
  {
    nodeId: '347aaf53-b20b-45d9-b2ed-00d5a0ac7289',
    contactId: '1cec6046-8806-4bf3-bc9e-388f131c188d',
    name: 'Lancaster Dodd',
    contactType: 'User',
    canRead: false,
    canEdit: false,
    sharedAfterDeath: true,
    dateShared: new Date("2021-08-31T15:17:19.7722351"),
  },
  {
    nodeId: '347aaf53-b20b-45d9-b2ed-00d5a0ac7289',
    contactId: '2a98c82a-226a-4e16-8f53-d17c0a46237d',
    name: 'Larry Sportello',
    contactType: 'User',
    canRead: true,
    canEdit: false,
    sharedAfterDeath: false,
    dateShared: new Date("2021-12-09T02:18:49.7722351"),
  },
] as NodeShareDto[]
