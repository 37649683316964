const Hide = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 5.00015L26 27.0002" stroke="#48475C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.3633 19.6999C18.3821 20.5918 17.0867 21.0573 15.7622 20.9942C14.4377 20.9311 13.1925 20.3444 12.3006 19.3632C11.4086 18.3821 10.9429 17.0868 11.0059 15.7622C11.0689 14.4377 11.6555 13.1925 12.6366 12.3004" stroke="#48475C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.24931 8.57435C4.15315 11.155 2 16.0002 2 16.0002C2 16.0002 6 24.9992 16 24.9992C18.343 25.0178 20.6567 24.4782 22.7497 23.4249" stroke="#48475C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M26.0761 21.1376C28.8014 18.6967 30 16.0002 30 16.0002C30 16.0002 25.9999 6.99917 15.9999 6.99917C15.1339 6.99776 14.2691 7.06817 13.4147 7.20969" stroke="#48475C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.9409 11.0886C18.0036 11.2927 18.9715 11.8358 19.6994 12.6365C20.4274 13.4373 20.876 14.4524 20.9781 15.5297" stroke="#48475C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)
export default Hide
