const WelcomePortability = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_3909_84262"
      maskUnits="userSpaceOnUse"
      x="-2"
      y="11"
      width="51"
      height="37"
    >
      <path
        d="M26.5909 11.4205H-1.40908V47.4205H26.5909V24.9205H48.0909V11.4205H26.5909Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3909_84262)">
      <path d="M11.1277 14C10.2982 14 9.50265 14.3295 8.9161 14.9161C8.32954 15.5026 8.00002 16.2982 8.00002 17.1277V45.4283C8.00002 46.292 8.63631 47 9.5 47V45.4283V16.8388C9.5 16.0104 10.1716 15.3388 11 15.3388H21.4499C21.8051 15.3388 22.1487 15.4649 22.4197 15.6945L27.5 20L27.716 20.216C27.8983 20.3983 28.1439 20.5031 28.4016 20.5086L29 20.5215L43.5 20.5V26.4892L45 26.5V21.8192V20C45 19.4477 44.5523 19 44 19H42.5533H29L23.43 14.6255C22.8886 14.2195 22.2301 14 21.5533 14H11.1277Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1176 25.5932C15.6501 25.2075 16.2899 25 16.9464 25H48.0541C48.5497 25 49.0382 25.1183 49.4794 25.3453C49.9205 25.5722 50.3017 25.9012 50.5914 26.3052C50.8812 26.7092 51.0713 27.1766 51.146 27.6689C51.2208 28.1612 51.178 28.6643 51.0213 29.1367L45.4521 45.9255C45.2392 46.5672 44.6416 47 43.9685 47H9.56386C9.06117 47 9.00887 46.9758 8.71494 46.566C8.42101 46.1563 8.34104 45.6295 8.5 45.1503C10.8037 38.2057 11.6755 34.0936 13.9792 27.149C14.1868 26.5232 14.5851 25.9789 15.1176 25.5932ZM48 26.5H16.7329C16.2966 26.5 15.9107 26.7828 15.7793 27.1989L10 45.5H26.5H43L48 26.5Z"
      />
    </g>
    <circle
      cx="37"
      cy="36"
      r="11"
      strokeWidth="5"
      className="custom-1 stroke-white"
    />
    <path
      d="M44.875 34L31.125 34"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="reverse"
    />
    <path
      d="M36.75 28.375L31.125 34L36.75 39.625"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="reverse"
    />
    <path
      d="M38.125 46H51.875"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="reverse"
    />
    <path
      d="M46.25 51.625L51.875 46L46.25 40.375"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="reverse"
    />
  </svg>
)

export default WelcomePortability
