import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { getPreviewDocumentPath } from "../../../api/api-client/api-handler"
import { IFile } from "../../../api/api-client/api-types"
import { ApiController } from "../../../api/apiController"
import { deleteFile } from "../../../api/lib/node/node"
import { CaseDocument } from "../../../api/lib/workflow/models/GetCaseDocumentResponse"
import { CaseStageRequest } from "../../../api/lib/workflow/models/GetWorkflowCaseResponse"
import { ActionType } from "../../../contexts/application/constants"
import {
  setDisplaySuccessOrErrorMessage,
  useToastContext,
} from "../../../contexts/toasts"
import { useGetWorkflowCase } from "../../../hooks/useGetWorkflowCase"
import { useScrollToRefOnTrigger } from "../../../hooks/useScrollToRefOnTrigger"
import { isNullEmptyOrUndefined } from "../../../utils/assertions/typeAssertions"
import { containsDocumentActionType } from "../../../utils/file/containsDocumentActionType"
import ViewSDKClient from "../../PdfReader/ViewSDKClient"
import { BUTTON_VARIANT, Button } from "../../atoms/Button"
import { LoadingSpinner } from "../../atoms/LoadingSpinner/LoadingSpinner"
import { AddDocumentDetailsForUploadedFile } from "../AddDocumentDetailsForUploadedFile/AddDocumentDetailsForUploadedFile"
import { Modal, ModalContent, ModalHeader } from "././../Modal"
import { UploadFileToCaseModal } from "./components/UploadFileToCaseModal/UploadFileToCaseModal"
import { DocumentNameAndCategory } from "./components/UploadMultiPageFile/DocumentNameAndCategory/DocumentNameAndCategory"
import { DocumentPageOverview } from "./components/UploadMultiPageFile/DocumentPageOverview/DocumentPageOverview"
import { ViewUploadedDocumentsModal } from "./components/UploadedFile/ViewUploadedDocumentsModal/ViewUploadedDocumentsModal"

import "./UploadFileToCaseModalWizard.css"

export interface UploadFileToCaseModalWizardProps {
  workflowId: string
  workflowVersion: string
  caseId: string
  uploadedViaCategory?: boolean
  onClose: () => void
  requestNameFromCategory?: string
  stageIdFromCategory?: number
  requestIdFromCategory?: number
  setNewlyUploadedDocuments?: Dispatch<SetStateAction<CaseDocument[]>>
  onUploadComplete?: () => Promise<void>
}

export enum UploadWizardSteps {
  UploadFile = "uploadFile",
  AddDocumentDetails = "addDocumentDetails",
  ViewUploadedDocuments = "viewUploadedDocuments",
  DocumentPageOverview = "documentPageOverview",
  DocumentNameAndCategory = "documentNameAndCategory",
}

const stepNames = {
  uploadFile: "Upload new file", //Step 1
  addDocumentDetails: "Add document name and choose category (2)", //Step 2
  viewUploadedDocuments: "Upload new file", //Step 3
  documentPageOverview: "Do you need to add more pages for this file upload?",
  documentNameAndCategory: "Add document name and choose category",
}

export interface Option {
  name: string
  value: string
  stageId: string
  requestId: string
  selected?: boolean
}

export interface UploadedDocument {
  fileId: string | null
  name: string
  category: { stageId: string; requestId: string; name: string }
  base64ThumbnailString: string
  fileExtension: string
}

export const UploadFileToCaseModalWizard: FC<
  UploadFileToCaseModalWizardProps
> = ({
  workflowId,
  workflowVersion,
  caseId,
  uploadedViaCategory = false,
  requestNameFromCategory,
  onClose,
  stageIdFromCategory,
  requestIdFromCategory,
  onUploadComplete,
  setNewlyUploadedDocuments,
}) => {
  const { dispatch: toastDispatch } = useToastContext()

  const workflowCase = useGetWorkflowCase()
  const caseStage = workflowCase?.stages[workflowCase?.currentStage - 1]

  const [currentStep, setCurrentStep] = useState(UploadWizardSteps.UploadFile)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [requestDropdownOptions, setRequestDropdownOptions] = useState<
    Option[]
  >([])
  const [documentsToUpload, setDocumentsToUpload] = useState<CaseDocument[]>([])
  const [uploadedDocuments, setUploadedDocuments] = useState<CaseDocument[]>([])
  const [multiPageFiles, setMultiPageFiles] = useState<IFile[]>([])
  const [failedMultiPageFileNames, setFailedMultiPageFileNames] = useState<
    string[]
  >([])
  const [isUploading, setIsUploading] = useState<boolean>(false)

  const [fileSelectedForPreview, setfileSelectedForPreview] = useState<IFile>()
  const [previewLoading, setPreviewLoading] = useState<boolean>(false)
  const [filePreview, setFilePreview] = useState<string>()

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false)
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] =
    useState<boolean>(false)

  // TODO: create end to end test to test the scroll to ref
  // https://dev.azure.com/secure-the-file/Application/_boards/board/t/Legal/Stories?System.AssignedTo=_Unassigned_%2Cbrandon%40joinlegado.com%2CLukasz%40joinlegado.com&workitem=16023

  const { ref: requestRef } = useScrollToRefOnTrigger({
    trigger: !!uploadedDocuments && !isUploading,
  })

  const getUploadSuccessfulToastMessage = () => {
    if (uploadedDocuments.length === 1) {
      const fileName = uploadedDocuments[0]?.file?.name || "Your document"
      return `${fileName} has been uploaded to your documents and is awaiting review`
    } else {
      return `${uploadedDocuments.length} documents have been uploaded to your documents and are awaiting review`
    }
  }
  const onSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      onClose()
    }
    if (uploadedDocuments.length > 0) {
      toastDispatch(
        setDisplaySuccessOrErrorMessage({
          title: "Upload Successful",
          message: `${getUploadSuccessfulToastMessage()} `,
          messageType: "SUCCESS",
        })
      )
    }
  }

  const removeFile = async (id?: string) => {
    if (isNullEmptyOrUndefined(id)) return
    try {
      await deleteFile({ fileId: id! })
    } catch (err) {
      // we shouldn't need to do anything here
      // BE will handle removing unattached files that werent deleted after some time
    }
  }

  const onModalClose = () => {
    if (unsavedChanges && !showUnsavedChangesModal) {
      setShowUnsavedChangesModal(true)
    } else {
      const documentsNotAttachedToTheCaseSingleFileUpload =
        documentsToUpload.filter((document) => document.caseId === 0)

      // CaseDocument type
      if (documentsNotAttachedToTheCaseSingleFileUpload.length > 0) {
        documentsNotAttachedToTheCaseSingleFileUpload.forEach((document) => {
          removeFile(document.file?.id)
        })
      }

      // IFile type
      if (multiPageFiles.length > 0) {
        multiPageFiles.forEach((document) => {
          removeFile(document.id)
        })
      }
      if (uploadedDocuments.length > 0) {
        toastDispatch(
          setDisplaySuccessOrErrorMessage({
            title: "Upload Successful",
            message: `${getUploadSuccessfulToastMessage()} `,
            messageType: "SUCCESS",
          })
        )
      }
      onClose()
      setShowUnsavedChangesModal(false)
    }
  }

  useEffect(() => {
    if (caseStage) {
      const requestNames: Option[] = []

      caseStage.requests.forEach((request: CaseStageRequest) => {
        // ReviewUploadAndSign action type should only be actioned through action banner
        // on file details page
        const hasReviewAndPrint = containsDocumentActionType({
          documents: request.documents,
          actionType: ActionType.ReviewUploadAndSign,
        })
        if (request.allowFileUploads && !hasReviewAndPrint) {
          requestNames.push({
            stageId: caseStage.id.toString(),
            requestId: request.id.toString(),
            name: request.name ?? "",
            value: request.name + ":" + caseStage.id + ":" + request.id,
            selected: false,
          })
        }
      })
      setRequestDropdownOptions(requestNames)
    }
  }, [caseStage])

  //file preview part

  useEffect(() => {
    const getSrc = async (file: IFile) => {
      if (file.id && file.mimeType) {
        const api = ApiController.getInstance()
        const url = await api.getImageDataAsUrl(file.id, file.mimeType)
        if (url) {
          setFilePreview(url)
        }
      }
      setPreviewLoading(false)
    }

    const getPreview = async () => {
      if (!fileSelectedForPreview || !fileSelectedForPreview.id) return

      // id in this context is the file id, not the document id
      const base64Preview = await getPreviewDocumentPath(
        fileSelectedForPreview,
        true
      )
      if (base64Preview && base64Preview !== "") {
        setFilePreview(base64Preview)
      }
      setPreviewLoading(false)
    }

    if (fileSelectedForPreview) {
      setPreviewLoading(true)
      if (
        fileSelectedForPreview.mimeType &&
        fileSelectedForPreview.mimeType === "application/pdf"
      ) {
        getSrc(fileSelectedForPreview)
      } else {
        getPreview()
      }
    }

    return () => {
      setPreviewLoading(false)
      setFilePreview(undefined)
    }
  }, [fileSelectedForPreview])

  const loadPDF = (url: string) => {
    const viewSDKClient = new ViewSDKClient()
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        "pdf-preview",
        {
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: true,
          showDownloadPDF: false,
          showPrintPDF: false,
          showFullScreen: true,
        },
        url
      )
    })
  }

  if (filePreview) {
    loadPDF(filePreview)
  }

  if (showUnsavedChangesModal) {
    return (
      <Modal
        name="Unsaved changes"
        onClose={onModalClose}
        className="unsaved-changes-modal"
      >
        <ModalHeader>Are you sure you wish to leave?</ModalHeader>
        <ModalContent className="mt-5">
          <p>
            You have unfinished progress for this upload. Please complete all
            mandatory fields before leaving. This upload won't be sent to your
            case manager without a document name and Document category assigned.
          </p>
          <div className="modal-button-container mt-5">
            <Button
              variant={BUTTON_VARIANT.SECONDARY}
              onClick={() => setShowUnsavedChangesModal(false)}
            >
              Return to upload
            </Button>
            <Button variant={BUTTON_VARIANT.PRIMARY} onClick={onModalClose}>
              Leave now
            </Button>
          </div>
        </ModalContent>
      </Modal>
    )
  }

  if (fileSelectedForPreview) {
    return (
      <Modal
        className="upload-file-to-case-modal-wizard"
        name="File Preview"
        onClose={onModalClose}
      >
        <ModalHeader>{`Preview: ${fileSelectedForPreview?.name}`}</ModalHeader>
        <hr />
        <ModalContent>
          {previewLoading && (
            <div className="pdf-div-loading">
              <LoadingSpinner
                color="var(--color-universal-secondary-c)"
                size="50px"
                thickness="5px"
              />
            </div>
          )}
          {!previewLoading && filePreview && (
            <div
              id="pdf-preview"
              style={{ height: "669px" }}
              className="pdf-div"
            />
          )}
          {!previewLoading && !filePreview && (
            <p className="pdf-div-error">
              There is no preview available for this document.
            </p>
          )}
          <Button
            variant="secondary"
            onClick={() => setfileSelectedForPreview(undefined)}
            ariaLabel="Back to upload overview"
          >
            Back to upload overview
          </Button>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal
      name={stepNames[currentStep]}
      onClose={() => !isUploading && onModalClose()}
      className="upload-file-to-case-modal-wizard"
    >
      {currentStep === UploadWizardSteps.UploadFile && (
        <UploadFileToCaseModal
          uploadedViaCategory={uploadedViaCategory}
          requestNameFromCategory={requestNameFromCategory}
          workflowId={workflowId}
          workflowVersion={workflowVersion}
          caseId={caseId}
          setCurrentStep={setCurrentStep}
          setDocumentsToUpload={setDocumentsToUpload}
          setMultiPageFiles={setMultiPageFiles}
          folderId={workflowCase?.caseFolderId}
          setUnsavedChanges={setUnsavedChanges}
          setFailedMultiPageFileNames={setFailedMultiPageFileNames}
          setIsUploading={setIsUploading}
          removeFile={removeFile}
        />
      )}
      {currentStep === UploadWizardSteps.AddDocumentDetails && (
        <AddDocumentDetailsForUploadedFile
          documentsToUpload={documentsToUpload}
          setDocumentsToUpload={setDocumentsToUpload}
          uploadedDocuments={uploadedDocuments}
          setUploadedDocuments={setUploadedDocuments}
          requestDropdownOptions={requestDropdownOptions}
          uploadedViaCategory={uploadedViaCategory}
          workflowId={workflowId ? workflowId : ""}
          workflowVersion={workflowVersion ? workflowVersion : ""}
          caseId={caseId ? caseId : ""}
          setCurrentStep={setCurrentStep}
          stageIdFromCategory={stageIdFromCategory}
          requestIdFromCategory={requestIdFromCategory}
          requestNameFromCategory={requestNameFromCategory}
          updatePageFunction={onUploadComplete}
          setUnsavedChanges={setUnsavedChanges}
          setNewlyUploadedDocuments={setNewlyUploadedDocuments}
        />
      )}
      {currentStep === UploadWizardSteps.DocumentPageOverview && (
        <DocumentPageOverview
          setCurrentStep={setCurrentStep}
          files={multiPageFiles}
          failedMultiPageFileNames={failedMultiPageFileNames}
          resetMultiPageFiles={() => setFailedMultiPageFileNames([])}
          setFiles={setMultiPageFiles}
          folderId={workflowCase?.caseFolderId}
        />
      )}
      {currentStep === UploadWizardSteps.DocumentNameAndCategory && (
        <DocumentNameAndCategory
          workflowId={workflowId}
          workflowVersion={workflowVersion}
          caseId={workflowCase?.id}
          files={multiPageFiles}
          setFiles={setMultiPageFiles}
          options={requestDropdownOptions}
          setCurrentStep={setCurrentStep}
          uploadedDocuments={uploadedDocuments}
          setUploadedDocuments={setUploadedDocuments}
          folderId={workflowCase?.caseFolderId}
          uploadedViaCategory={uploadedViaCategory}
          requestNameFromCategory={requestNameFromCategory}
          requestIdFromCategory={requestIdFromCategory}
          stageIdFromCategory={stageIdFromCategory}
          setUnsavedChanges={setUnsavedChanges}
          onUploadComplete={onUploadComplete}
          setNewlyUploadedDocuments={setNewlyUploadedDocuments}
        />
      )}
      {currentStep === UploadWizardSteps.ViewUploadedDocuments && (
        <>
          <ViewUploadedDocumentsModal
            requestIdFromCategory={requestIdFromCategory}
            uploadedDocuments={uploadedDocuments}
            setCurrentStep={setCurrentStep}
            setDocumentsToUpload={setDocumentsToUpload}
            setFileSelectedForPreview={setfileSelectedForPreview}
            setUnsavedChanges={setUnsavedChanges}
          >
            <UploadFileToCaseModal
              uploadedViaCategory={uploadedViaCategory}
              workflowId={workflowId}
              workflowVersion={workflowVersion}
              caseId={caseId}
              setCurrentStep={setCurrentStep}
              setDocumentsToUpload={setDocumentsToUpload}
              setMultiPageFiles={setMultiPageFiles}
              folderId={workflowCase?.caseFolderId}
              setUnsavedChanges={setUnsavedChanges}
              setFailedMultiPageFileNames={setFailedMultiPageFileNames}
              setIsUploading={setIsUploading}
            />
          </ViewUploadedDocumentsModal>
          {/* TODO: Button component should support isBusy or isLoading prop and we should add the spinner there.  https://dev.azure.com/secure-the-file/Application/_workitems/edit/14437 */}
          {uploadedDocuments.length > 0 && (
            <div
              className="uploaded-documents__button-container"
              ref={requestRef}
            >
              <Button variant={BUTTON_VARIANT.PRIMARY} onClick={onSubmit}>
                {!isSubmitting && <label>Done</label>}
                {isSubmitting && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="mr-1">Loading...</span>
                    {/* TODO: Is there something better than loading...? https://dev.azure.com/secure-the-file/Application/_workitems/edit/15341*/}
                    <LoadingSpinner
                      size="20px"
                      thickness="2px"
                      color="var(--color-universal-secondary-e)"
                    />
                  </div>
                )}
              </Button>
            </div>
          )}
        </>
      )}
    </Modal>
  )
}
