import React from "react"
import classNames from "classnames"

import "./ValidationErrorLabel.css"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"

export interface ValidationErrorLabelProps {
  id?: string
  errorContent: string
  visible?: boolean
  shouldShowWarningIcon?: boolean
  hasMarginTop?: boolean
}

export const ValidationErrorLabel: React.FC<ValidationErrorLabelProps> = ({
  id,
  errorContent,
  visible = false,
  shouldShowWarningIcon = true,
  hasMarginTop = true,
}) => {
  const warningIcon = useGetIcon("ErrorWarning")
  const fieldErrorClassName = classNames("field-error", {
    "error-is-visible": visible,
    "field-error--with-margin-top": hasMarginTop,
  })

  return (
    <div
      className={fieldErrorClassName}
      hidden={!visible}
      id={id ? id : "input-error"}
      data-testid="input-error-label"
    >
      {shouldShowWarningIcon && warningIcon}
      <label className="text-s">{errorContent}</label>
    </div>
  )
}
