const RedPlus = () => (
  <svg
    width="35"
    height="36"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 31.09C24.9558 31.09 31 25.0458 31 17.59C31 10.1342 24.9558 4.09 17.5 4.09C10.0442 4.09 4 10.1342 4 17.59C4 25.0458 10.0442 31.09 17.5 31.09Z"
      fill="#D82927"
    />
    <path
      d="M23.0686 12.0215L11.9316 23.1585"
      stroke="#FEFEFE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.0686 23.1585L11.9316 12.0215"
      stroke="#FEFEFE"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RedPlus
