import { TTheme } from "../../design-tokens/types"
import {
  getTypefaceByTheme,
  getTypeface,
  getBaseFont,
  getFontWeights,
} from "../../design-tokens/getters"

export const getTypefaceVariables = (theme: TTheme) => {
  const typeface = getTypefaceByTheme(theme)
  const font = getBaseFont(theme)
  const gFW = getFontWeights(font)

  const getFontSize = getTypeface({ typeface, type: "font-size" })
  const getLineHeight = getTypeface({ typeface, type: "line-height" })
  const gUtilFS = getTypeface({ typeface, type: "font-size" })
  const gUtilLH = getTypeface({ typeface, type: "line-height" })
  const gUtilColor = getTypeface({ typeface, type: "color" })

  return `
        --fs-display-l: ${getFontSize("display-l") || "3.1rem" || "50px"};
        --fs-display: ${getFontSize("display") || "2.8rem" || "45px"};
        --fs-xxxl: ${getFontSize("xxxl") || "2.5rem" || "40px"};
        --fs-xxl: ${getFontSize("xxl") || "2rem" || "32px"};
        --fs-xl: ${getFontSize("xl") || "1.75rem" || "28px"};
        --fs-l: ${getFontSize("l") || "1.5rem" || "24px"};
        --fs-m: ${getFontSize("m") || "1.125rem" || "18px"};
        --fs-base: ${getFontSize("base") || "1rem" || "16px"};
        --fs-s: ${getFontSize("s") || "0.875rem" || "14px"};
        --fs-xs: ${getFontSize("xs") || "0.75rem" || "12px"};
        --lh-display-l: ${getLineHeight("display-l") || "3.6rem" || "58px"};
        --lh-display: ${getLineHeight("display") || "3.3rem" || "53px"};
        --lh-xxxl: ${getLineHeight("xxxl") || "3rem" || "48px"};
        --lh-xxl: ${getLineHeight("xxl") || "2.5rem" || "40px"};
        --lh-xl: ${getLineHeight("xl") || "2.25em" || "36px"};
        --lh-l: ${getLineHeight("l") || "2rem" || "32px"};
        --lh-m: ${getLineHeight("m") || "1.625rem" || "26px"};
        --lh-base: ${getLineHeight("base") || "1.375rem" || "22px"};
        --lh-s: ${getLineHeight("s") || "1.375rem" || "22px"};
        --lh-xs: ${getLineHeight("xs") || "1.375rem" || "22px"};
        --fw-bold: ${gFW("Bold") ?? 700};
        --fw-medium: ${gFW("Medium") ?? 500};
        --fw-regular: ${gFW("Regular") ?? 400};
        --fw-light: ${gFW("Light") ?? 300};
        --error-fs: ${gUtilFS("error-message") || "0.875rem" || "14px"};
        --error-lh: ${gUtilLH("error-message") || "1.4rem" || "22px"};
        --error-color: ${gUtilColor("error-message") ?? "#DB1620"};
        --hint-fs: ${gUtilFS("hint-message") || "0.875rem" || "14px"};
        --hint-lh: ${gUtilLH("hint-message") || "1.375rem" || "22px"};
        --hint-color: ${gUtilColor("hint-message") ?? "#606D72"};
    `
}
