const CheckboxDisabledActive = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="CheckboxDisabledActive"
    >
      <rect x="2" y="2" width="28" height="28" rx="2.5" fill="#F9F9F9" />
      <g clipPath="url(#clip0_9855_103035)">
        <path
          d="M10 17.5L14.0625 21.5625L23 11"
          stroke="#ADAEB9"
          strokeWidth="1.95"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="2" y="2" width="28" height="28" rx="2.5" stroke="#E8E8E8" />
      <defs>
        <clipPath id="clip0_9855_103035">
          <rect
            width="16"
            height="13"
            fill="white"
            transform="translate(8 9.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default CheckboxDisabledActive
