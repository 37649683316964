import { OrganisationDto } from "legado-generated-api-client"

export const getAllOrganisationResponse: OrganisationDto[] = [
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe7e5f",
    type: "Intermediary",
    name: "Orn - Romaguera Advisers",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2021-02-09T13:35:40.1844844"),
  },
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe7321",
    type: "Intermediary",
    name: "Test Company",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2022-02-05T13:35:40.1844844"),
  },
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe7135",
    type: "Intermediary",
    name: "Testing Factory Inc",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2022-01-04T13:35:40.1844844"),
  },
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe7138",
    type: "Intermediary",
    name: "Testing Solutions Ltd",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2005-01-04T13:35:40.1844844"),
  },
  {
    organisationId: "b3b5eb4c-5b22-4266-5734-08d8ccfe7135",
    type: "Intermediary",
    name: "Test ABC Architects",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2022-01-04T13:35:40.1844844"),
  },
  {
    organisationId: "a3b5eb4c-5b22-4266-5734-08d8ccfe2235",
    type: "Intermediary",
    name: "Test Resources Ltd",
    network: null,
    isSingleAdviserCompany: false,
    featurePlanId: null,
    created: new Date("2007-01-04T13:35:40.1844844"),
  },
]
