"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Legado Vault API
 * This is the Vault API endpoint definitions.   You can find the guides and further information about the Legado Vault API [here](https://apidocs.joinlegado.com/api-information/vault-api)
 *
 * OpenAPI spec version: v1.0
 * Contact: api@joinlegado.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharingApi = exports.SharingApiFactory = exports.SharingApiFp = exports.SharingApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("../base");
/**
 * SharingApi - axios parameter creator
 * @export
 */
const SharingApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Adds a new Contact for the logged in user.  If the email address is not an existing user, an invite is sent out for the new contact.  Otherwise, the user is informed they are now a Contact
         * @summary Add Contact
         * @param {SharingAddcontactBody} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingAddContactPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Sharing/add-contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a list of Adviser objects associated with the current user.
         * @summary Get Adviser List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingAdvisersGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Sharing/advisers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * For a specific contact or ifa company, return all nodes shared with them by current user
         * @summary Get Node List
         * @param {string} contactId the contact user id, or company id for an ifa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactContactIdGet: (contactId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'contactId' is not null or undefined
            if (contactId === null || contactId === undefined) {
                throw new base_1.RequiredError("contactId", "Required parameter contactId was null or undefined when calling apiSharingContactContactIdGet.");
            }
            const localVarPath = `/api/Sharing/contact/{contactId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * For a specific contact or IFA company, add+remove shared nodes
         * @summary Post Shared Node List
         * @param {string} contactId the contact user id, or company id for an ifa
         * @param {Array<NodeShareDto>} [body] the list of node share dtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactContactIdPost: (contactId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'contactId' is not null or undefined
            if (contactId === null || contactId === undefined) {
                throw new base_1.RequiredError("contactId", "Required parameter contactId was null or undefined when calling apiSharingContactContactIdPost.");
            }
            const localVarPath = `/api/Sharing/contact/{contactId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a list of contact objects associated with the current user.
         * @summary Get Contact List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactsGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Sharing/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * For a specific Contact or IFA company, get the sharing status for a node
         * @summary Get Sharing Options
         * @param {string} contactId the Contact user id, or company id for an ifa
         * @param {string} nodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdContactContactIdGet: (contactId, nodeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'contactId' is not null or undefined
            if (contactId === null || contactId === undefined) {
                throw new base_1.RequiredError("contactId", "Required parameter contactId was null or undefined when calling apiSharingNodeIdContactContactIdGet.");
            }
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError("nodeId", "Required parameter nodeId was null or undefined when calling apiSharingNodeIdContactContactIdGet.");
            }
            const localVarPath = `/api/Sharing/{nodeId}/contact/{contactId}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * For a specific Contact or IFA company, set the sharing status for a node
         * @summary Put Sharing Options
         * @param {string} contactId the Contact user id, or company id for an ifa
         * @param {string} nodeId the node id
         * @param {number} option the option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdContactContactIdPutoptionOptionPut: (contactId, nodeId, option, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'contactId' is not null or undefined
            if (contactId === null || contactId === undefined) {
                throw new base_1.RequiredError("contactId", "Required parameter contactId was null or undefined when calling apiSharingNodeIdContactContactIdPutoptionOptionPut.");
            }
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError("nodeId", "Required parameter nodeId was null or undefined when calling apiSharingNodeIdContactContactIdPutoptionOptionPut.");
            }
            // verify required parameter 'option' is not null or undefined
            if (option === null || option === undefined) {
                throw new base_1.RequiredError("option", "Required parameter option was null or undefined when calling apiSharingNodeIdContactContactIdPutoptionOptionPut.");
            }
            const localVarPath = `/api/Sharing/{nodeId}/contact/{contactId}/putoption/{option}`
                .replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)))
                .replace(`{${"option"}}`, encodeURIComponent(String(option)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * For a specific node return a nodeShareDTO for each Contact of the nodeOwner
         * @summary Get Shared With
         * @param {string} nodeId the node id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdListGet: (nodeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError("nodeId", "Required parameter nodeId was null or undefined when calling apiSharingNodeIdListGet.");
            }
            const localVarPath = `/api/Sharing/{nodeId}/list`.replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * For a specific node (folder or file), add and remove contacts
         * @summary Post Contact List For Node
         * @param {string} nodeId the node being shared
         * @param {Array<NodeShareDto>} [body] the list of node share dtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeNodeIdPost: (nodeId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'nodeId' is not null or undefined
            if (nodeId === null || nodeId === undefined) {
                throw new base_1.RequiredError("nodeId", "Required parameter nodeId was null or undefined when calling apiSharingNodeNodeIdPost.");
            }
            const localVarPath = `/api/Sharing/node/{nodeId}`.replace(`{${"nodeId"}}`, encodeURIComponent(String(nodeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Remove a contact associated with the current user
         * @summary Remove Contact
         * @param {string} contactId The contact&#x27;s user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingRemoveContactContactIdDelete: (contactId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'contactId' is not null or undefined
            if (contactId === null || contactId === undefined) {
                throw new base_1.RequiredError("contactId", "Required parameter contactId was null or undefined when calling apiSharingRemoveContactContactIdDelete.");
            }
            const localVarPath = `/api/Sharing/remove-contact/{contactId}`.replace(`{${"contactId"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * Resends an email to a pending Contact
         * @summary Resend Contact Invite
         * @param {SharingResendInviteBody} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingResendInvitePost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Sharing/resendInvite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * For a user, return all the users who list them as a contact
         * @summary Get Users Who Shared With Me List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingUsersListGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/Sharing/users/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
        /**
         * For a user, return folders + files files created by another user (sharer)
         * @summary Get Shared With Me Node List (Contact)
         * @param {string} sharerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingUsersSharerIdGet: (sharerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'sharerId' is not null or undefined
            if (sharerId === null || sharerId === undefined) {
                throw new base_1.RequiredError("sharerId", "Required parameter sharerId was null or undefined when calling apiSharingUsersSharerIdGet.");
            }
            const localVarPath = `/api/Sharing/users/{sharerId}`.replace(`{${"sharerId"}}`, encodeURIComponent(String(sharerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, "https://example.com");
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication ApiKey required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apikey")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apikey"] = localVarApiKeyValue;
            }
            // authentication ApiSecret required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === "function"
                    ? yield configuration.apiKey("apisecret")
                    : yield configuration.apiKey;
                localVarHeaderParameter["apisecret"] = localVarApiKeyValue;
            }
            // authentication Bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? yield configuration.accessToken()
                    : yield configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = new URLSearchParams(query).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.SharingApiAxiosParamCreator = SharingApiAxiosParamCreator;
/**
 * SharingApi - functional programming interface
 * @export
 */
const SharingApiFp = function (configuration) {
    return {
        /**
         * Adds a new Contact for the logged in user.  If the email address is not an existing user, an invite is sent out for the new contact.  Otherwise, the user is informed they are now a Contact
         * @summary Add Contact
         * @param {SharingAddcontactBody} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingAddContactPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingAddContactPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Returns a list of Adviser objects associated with the current user.
         * @summary Get Adviser List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingAdvisersGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingAdvisersGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * For a specific contact or ifa company, return all nodes shared with them by current user
         * @summary Get Node List
         * @param {string} contactId the contact user id, or company id for an ifa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactContactIdGet(contactId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingContactContactIdGet(contactId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * For a specific contact or IFA company, add+remove shared nodes
         * @summary Post Shared Node List
         * @param {string} contactId the contact user id, or company id for an ifa
         * @param {Array<NodeShareDto>} [body] the list of node share dtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactContactIdPost(contactId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingContactContactIdPost(contactId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Returns a list of contact objects associated with the current user.
         * @summary Get Contact List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactsGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingContactsGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * For a specific Contact or IFA company, get the sharing status for a node
         * @summary Get Sharing Options
         * @param {string} contactId the Contact user id, or company id for an ifa
         * @param {string} nodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdContactContactIdGet(contactId, nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingNodeIdContactContactIdGet(contactId, nodeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * For a specific Contact or IFA company, set the sharing status for a node
         * @summary Put Sharing Options
         * @param {string} contactId the Contact user id, or company id for an ifa
         * @param {string} nodeId the node id
         * @param {number} option the option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdContactContactIdPutoptionOptionPut(contactId, nodeId, option, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingNodeIdContactContactIdPutoptionOptionPut(contactId, nodeId, option, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * For a specific node return a nodeShareDTO for each Contact of the nodeOwner
         * @summary Get Shared With
         * @param {string} nodeId the node id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdListGet(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingNodeIdListGet(nodeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * For a specific node (folder or file), add and remove contacts
         * @summary Post Contact List For Node
         * @param {string} nodeId the node being shared
         * @param {Array<NodeShareDto>} [body] the list of node share dtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeNodeIdPost(nodeId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingNodeNodeIdPost(nodeId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Remove a contact associated with the current user
         * @summary Remove Contact
         * @param {string} contactId The contact&#x27;s user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingRemoveContactContactIdDelete(contactId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingRemoveContactContactIdDelete(contactId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * Resends an email to a pending Contact
         * @summary Resend Contact Invite
         * @param {SharingResendInviteBody} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingResendInvitePost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingResendInvitePost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * For a user, return all the users who list them as a contact
         * @summary Get Users Who Shared With Me List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingUsersListGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingUsersListGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         * For a user, return folders + files files created by another user (sharer)
         * @summary Get Shared With Me Node List (Contact)
         * @param {string} sharerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingUsersSharerIdGet(sharerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield (0, exports.SharingApiAxiosParamCreator)(configuration).apiSharingUsersSharerIdGet(sharerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
exports.SharingApiFp = SharingApiFp;
/**
 * SharingApi - factory interface
 * @export
 */
const SharingApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * Adds a new Contact for the logged in user.  If the email address is not an existing user, an invite is sent out for the new contact.  Otherwise, the user is informed they are now a Contact
         * @summary Add Contact
         * @param {SharingAddcontactBody} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingAddContactPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingAddContactPost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Returns a list of Adviser objects associated with the current user.
         * @summary Get Adviser List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingAdvisersGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingAdvisersGet(options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * For a specific contact or ifa company, return all nodes shared with them by current user
         * @summary Get Node List
         * @param {string} contactId the contact user id, or company id for an ifa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactContactIdGet(contactId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingContactContactIdGet(contactId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * For a specific contact or IFA company, add+remove shared nodes
         * @summary Post Shared Node List
         * @param {string} contactId the contact user id, or company id for an ifa
         * @param {Array<NodeShareDto>} [body] the list of node share dtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactContactIdPost(contactId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingContactContactIdPost(contactId, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Returns a list of contact objects associated with the current user.
         * @summary Get Contact List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingContactsGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingContactsGet(options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * For a specific Contact or IFA company, get the sharing status for a node
         * @summary Get Sharing Options
         * @param {string} contactId the Contact user id, or company id for an ifa
         * @param {string} nodeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdContactContactIdGet(contactId, nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingNodeIdContactContactIdGet(contactId, nodeId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * For a specific Contact or IFA company, set the sharing status for a node
         * @summary Put Sharing Options
         * @param {string} contactId the Contact user id, or company id for an ifa
         * @param {string} nodeId the node id
         * @param {number} option the option
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdContactContactIdPutoptionOptionPut(contactId, nodeId, option, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingNodeIdContactContactIdPutoptionOptionPut(contactId, nodeId, option, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * For a specific node return a nodeShareDTO for each Contact of the nodeOwner
         * @summary Get Shared With
         * @param {string} nodeId the node id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeIdListGet(nodeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingNodeIdListGet(nodeId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * For a specific node (folder or file), add and remove contacts
         * @summary Post Contact List For Node
         * @param {string} nodeId the node being shared
         * @param {Array<NodeShareDto>} [body] the list of node share dtos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingNodeNodeIdPost(nodeId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingNodeNodeIdPost(nodeId, body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Remove a contact associated with the current user
         * @summary Remove Contact
         * @param {string} contactId The contact&#x27;s user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingRemoveContactContactIdDelete(contactId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingRemoveContactContactIdDelete(contactId, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * Resends an email to a pending Contact
         * @summary Resend Contact Invite
         * @param {SharingResendInviteBody} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingResendInvitePost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingResendInvitePost(body, options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * For a user, return all the users who list them as a contact
         * @summary Get Users Who Shared With Me List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingUsersListGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingUsersListGet(options)
                    .then((request) => request(axios, basePath));
            });
        },
        /**
         * For a user, return folders + files files created by another user (sharer)
         * @summary Get Shared With Me Node List (Contact)
         * @param {string} sharerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharingUsersSharerIdGet(sharerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                return (0, exports.SharingApiFp)(configuration)
                    .apiSharingUsersSharerIdGet(sharerId, options)
                    .then((request) => request(axios, basePath));
            });
        },
    };
};
exports.SharingApiFactory = SharingApiFactory;
/**
 * SharingApi - object-oriented interface
 * @export
 * @class SharingApi
 * @extends {BaseAPI}
 */
class SharingApi extends base_1.BaseAPI {
    /**
     * Adds a new Contact for the logged in user.  If the email address is not an existing user, an invite is sent out for the new contact.  Otherwise, the user is informed they are now a Contact
     * @summary Add Contact
     * @param {SharingAddcontactBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingAddContactPost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingAddContactPost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Returns a list of Adviser objects associated with the current user.
     * @summary Get Adviser List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingAdvisersGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingAdvisersGet(options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * For a specific contact or ifa company, return all nodes shared with them by current user
     * @summary Get Node List
     * @param {string} contactId the contact user id, or company id for an ifa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingContactContactIdGet(contactId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingContactContactIdGet(contactId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * For a specific contact or IFA company, add+remove shared nodes
     * @summary Post Shared Node List
     * @param {string} contactId the contact user id, or company id for an ifa
     * @param {Array<NodeShareDto>} [body] the list of node share dtos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingContactContactIdPost(contactId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingContactContactIdPost(contactId, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Returns a list of contact objects associated with the current user.
     * @summary Get Contact List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingContactsGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingContactsGet(options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * For a specific Contact or IFA company, get the sharing status for a node
     * @summary Get Sharing Options
     * @param {string} contactId the Contact user id, or company id for an ifa
     * @param {string} nodeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingNodeIdContactContactIdGet(contactId, nodeId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingNodeIdContactContactIdGet(contactId, nodeId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * For a specific Contact or IFA company, set the sharing status for a node
     * @summary Put Sharing Options
     * @param {string} contactId the Contact user id, or company id for an ifa
     * @param {string} nodeId the node id
     * @param {number} option the option
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingNodeIdContactContactIdPutoptionOptionPut(contactId, nodeId, option, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingNodeIdContactContactIdPutoptionOptionPut(contactId, nodeId, option, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * For a specific node return a nodeShareDTO for each Contact of the nodeOwner
     * @summary Get Shared With
     * @param {string} nodeId the node id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingNodeIdListGet(nodeId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingNodeIdListGet(nodeId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * For a specific node (folder or file), add and remove contacts
     * @summary Post Contact List For Node
     * @param {string} nodeId the node being shared
     * @param {Array<NodeShareDto>} [body] the list of node share dtos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingNodeNodeIdPost(nodeId, body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingNodeNodeIdPost(nodeId, body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Remove a contact associated with the current user
     * @summary Remove Contact
     * @param {string} contactId The contact&#x27;s user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingRemoveContactContactIdDelete(contactId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingRemoveContactContactIdDelete(contactId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * Resends an email to a pending Contact
     * @summary Resend Contact Invite
     * @param {SharingResendInviteBody} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingResendInvitePost(body, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingResendInvitePost(body, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * For a user, return all the users who list them as a contact
     * @summary Get Users Who Shared With Me List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingUsersListGet(options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingUsersListGet(options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
    /**
     * For a user, return folders + files files created by another user (sharer)
     * @summary Get Shared With Me Node List (Contact)
     * @param {string} sharerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharingApi
     */
    apiSharingUsersSharerIdGet(sharerId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            return (0, exports.SharingApiFp)(this.configuration)
                .apiSharingUsersSharerIdGet(sharerId, options)
                .then((request) => request(this.axios, this.basePath));
        });
    }
}
exports.SharingApi = SharingApi;
