const NotificationWarningGrey = () => (
<svg 
width="26"
height="26" 
viewBox="0 0 26 26" 
fill="none" 
xmlns="http://www.w3.org/2000/svg">
<path 
fillRule="evenodd" 
clipRule="evenodd" 
d="M3.72144 13.0007C3.72144 7.87633 7.87559 3.72217 13 3.72217C18.1244 3.72217 22.2786 7.87633 22.2786 13.0007C22.2786 18.1252 18.1244 22.2793 13 22.2793C7.87559 22.2793 3.72144 18.1252 3.72144 13.0007ZM13 23.7793C7.04717 23.7793 2.22144 18.9536 2.22144 13.0007C2.22144 7.0479 7.04717 2.22217 13 2.22217C18.9528 2.22217 23.7786 7.0479 23.7786 13.0007C23.7786 18.9536 18.9528 23.7793 13 23.7793ZM13 7.17217C13.5523 7.17217 14 7.61988 14 8.17217V13.3722C14 13.9245 13.5523 14.3722 13 14.3722C12.4477 14.3722 12 13.9245 12 13.3722V8.17217C12 7.61988 12.4477 7.17217 13 7.17217ZM14.1143 16.715C14.1143 17.3304 13.6154 17.8293 13 17.8293C12.3846 17.8293 11.8857 17.3304 11.8857 16.715C11.8857 16.0996 12.3846 15.6007 13 15.6007C13.6154 15.6007 14.1143 16.0996 14.1143 16.715Z" 
fill="#BCBCBC"/>
</svg>

  );
  export default NotificationWarningGrey;