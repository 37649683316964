const WelcomeConnect = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.2968 39.5308L32.8973 39.5998L33.0923 39.0276C34.3631 35.2989 37.8658 32.75 41.8018 32.75C46.8732 32.75 51.0147 36.9257 51.0147 42.0747C51.0147 42.3299 51.0043 42.5857 50.9836 42.8405L50.9072 43.7814L51.841 43.6431C52.0248 43.6159 52.2122 43.6022 52.4025 43.6022C54.5157 43.6022 56.25 45.3435 56.25 47.4996C56.25 49.6564 54.5155 51.3981 52.4025 51.3981H31.6301C28.3961 51.3981 25.75 48.7348 25.75 45.4455C25.75 42.1562 28.3961 39.4928 31.6301 39.4928C31.8543 39.4928 32.077 39.5055 32.2968 39.5308Z"
      strokeWidth="1.5"
      className="custom-1 reverse"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4783 12.5777C17.7974 12.3135 17.8418 11.8407 17.5776 11.5217C17.3134 11.2027 16.8406 11.1582 16.5216 11.4224L15.9823 11.869C4.45577 21.4143 4.65041 39.031 16.0948 48.3605H8.16642C7.75221 48.3605 7.41642 48.6963 7.41642 49.1105C7.41642 49.5247 7.75221 49.8605 8.16642 49.8605H18.1101C18.5243 49.8605 18.8601 49.5247 18.8601 49.1105L18.8601 39.1668C18.8601 38.7526 18.5243 38.4168 18.1101 38.4168C17.6959 38.4168 17.3601 38.7526 17.3601 39.1668L17.3601 47.452C6.33107 38.7837 6.03921 22.0506 16.9391 13.0243L17.4783 12.5777Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.2795 27.7091C51.395 28.1069 51.8111 28.3358 52.2089 28.2203C52.6067 28.1048 52.8355 27.6887 52.72 27.2909L51.5014 23.093C49.4813 16.1339 43.948 10.7781 36.9754 8.96194L45.4876 7.42208C45.8952 7.34835 46.1658 6.95815 46.0921 6.55055C46.0183 6.14296 45.6281 5.87231 45.2205 5.94604L35.4357 7.71612C35.0281 7.78986 34.7574 8.18005 34.8312 8.58765L36.6012 18.3725C36.675 18.7801 37.0652 19.0508 37.4728 18.977C37.8804 18.9033 38.151 18.5131 38.0773 18.1055L36.6902 10.438C43.1091 12.1446 48.1975 17.092 50.0609 23.5111L51.2795 27.7091Z"
    />
  </svg>
)

export default WelcomeConnect
