const IconChevronLeft = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M22 28L10.2411 16.5653C10.1646 16.4911 10.104 16.403 10.0627 16.306C10.0213 16.209 10 16.105 10 16C10 15.895 10.0213 15.791 10.0627 15.694C10.104 15.597 10.1646 15.5089 10.2411 15.4347L22 4"
      stroke="var(--color-universal-secondary-b)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default IconChevronLeft
