const YellowAlert = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    stroke="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 31C24.9558 31 31 24.9558 31 17.5C31 10.0442 24.9558 4 17.5 4C10.0442 4 4 10.0442 4 17.5C4 24.9558 10.0442 31 17.5 31Z"
      fill="#EEA63A"
    />
    <mask id="path-3-inside-1_105_10376" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5029 11C18.5029 10.4477 18.0552 10 17.5029 10C16.9506 10 16.5029 10.4477 16.5029 11V18C16.5029 18.5523 16.9506 19 17.5029 19C18.0552 19 18.5029 18.5523 18.5029 18V11ZM19 22.5C19 23.3284 18.3284 24 17.5 24C16.6716 24 16 23.3284 16 22.5C16 21.6716 16.6716 21 17.5 21C18.3284 21 19 21.6716 19 22.5Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5029 11C18.5029 10.4477 18.0552 10 17.5029 10C16.9506 10 16.5029 10.4477 16.5029 11V18C16.5029 18.5523 16.9506 19 17.5029 19C18.0552 19 18.5029 18.5523 18.5029 18V11ZM19 22.5C19 23.3284 18.3284 24 17.5 24C16.6716 24 16 23.3284 16 22.5C16 21.6716 16.6716 21 17.5 21C18.3284 21 19 21.6716 19 22.5Z"
      fill="#3D3E46"
    />
    <path
      d="M17.5029 10.1298C17.9835 10.1298 18.3731 10.5194 18.3731 11H18.6328C18.6328 10.376 18.1269 9.87016 17.5029 9.87016V10.1298ZM16.6328 11C16.6328 10.5194 17.0224 10.1298 17.5029 10.1298V9.87016C16.8789 9.87016 16.3731 10.376 16.3731 11H16.6328ZM16.6328 18V11H16.3731V18H16.6328ZM17.5029 18.8702C17.0224 18.8702 16.6328 18.4806 16.6328 18H16.3731C16.3731 18.624 16.8789 19.1298 17.5029 19.1298V18.8702ZM18.3731 18C18.3731 18.4806 17.9835 18.8702 17.5029 18.8702V19.1298C18.1269 19.1298 18.6328 18.624 18.6328 18H18.3731ZM18.3731 11V18H18.6328V11H18.3731ZM17.5 24.1298C18.4001 24.1298 19.1298 23.4001 19.1298 22.5H18.8702C18.8702 23.2567 18.2567 23.8702 17.5 23.8702V24.1298ZM15.8702 22.5C15.8702 23.4001 16.5999 24.1298 17.5 24.1298V23.8702C16.7433 23.8702 16.1298 23.2567 16.1298 22.5H15.8702ZM17.5 20.8702C16.5999 20.8702 15.8702 21.5999 15.8702 22.5H16.1298C16.1298 21.7433 16.7433 21.1298 17.5 21.1298V20.8702ZM19.1298 22.5C19.1298 21.5999 18.4001 20.8702 17.5 20.8702V21.1298C18.2567 21.1298 18.8702 21.7433 18.8702 22.5H19.1298Z"
      fill="#3D3E46"
      mask="url(#path-3-inside-1_105_10376)"
    />
  </svg>
)
export default YellowAlert
