const CheckboxInactive = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="28" height="28" rx="2.5" fill="white" />
    <rect x="1" y="1" width="28" height="28" rx="2.5" stroke="#C1C1C1" />
  </svg>
)
export default CheckboxInactive
