import { TTheme } from "../types"
import animation from "../tokens/oneLegacy/animation.json"
import breakpoints from "../tokens/oneLegacy/breakpoints.json"
import buttons from "../tokens/oneLegacy/buttons.json"
import colors from "../tokens/oneLegacy/colors.json"
import iconsizing from "../tokens/oneLegacy/iconsizing.json"
import overlay from "../tokens/oneLegacy/overlay.json"
import radius from "../tokens/oneLegacy/radius.json"
import shadows from "../tokens/oneLegacy/shadows.json"
import typeface from "../tokens/oneLegacy/typeface.json"
import typography from "../tokens/oneLegacy/typography.json"

const tokens = {
  mode: "ONE_LEGACY",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  typeface,
  typography,
} as TTheme
export default tokens
