import { FC } from "react"
import Search from "../../../../design-tokens/icons/theme/oneX/Search"
import "./TopBarButtonsCollection.css"
import { ProfileButton } from "../../ProfileButton/ProfileButton"
import { useGetIcon } from "../../../../styled-components/GetIconLibraryInTheme"

export interface TopBarButtonsCollectionProps {
  Logo: JSX.Element
  onSearchClick?: () => void
  onHamburgerClick?: () => void
}

export const TopBarButtonsCollection: FC<TopBarButtonsCollectionProps> = ({
  Logo,
  onSearchClick,
  onHamburgerClick,
}) => {
  const HamburgerIcon = useGetIcon("Hamburger")

  return (
    <div className="top-bar-content">
      <div className="logo-container">{Logo}</div>
      <div className="top-bar-buttons-container">
        <button
          className="search-button"
          onClick={onSearchClick}
          aria-label="Search"
        >
          <Search />
        </button>
        <button
          className="hamburger-button"
          onClick={onHamburgerClick}
          aria-label="Menu"
        >
          {HamburgerIcon}
        </button>
        <ProfileButton />
      </div>
    </div>
  )
}
