import { FC } from "react"

const ErrorPage: FC = () => {
  return (
    <>
      <div className="container">
        <h1 className="mt-6">Error</h1>
        <p>It has not been possible to load the vault for this user</p>
      </div>
    </>
  )
}

export default ErrorPage
