import {
  TTheme,
  TTypography,
  TTypeface,
  TColor,
  TFont,
  TWeight,
  TTypefaceType,
} from "../design-tokens/types"
import {
  defaultAnimation,
  defaultFont,
  defaultTypography,
  defaultTypeface,
  defaultColors,
  defaultShadows,
  defaultFontWeights,
  defaultOverlays,
  defaultIconSizing,
  defaultRadius,
  defaultButtons,
  defaultBreakpoints,
} from "../styled-components/data/defaults"

export const isTheme = (theme: any): TTheme | undefined => {
  if ((theme as TTheme).typography) {
    return theme
  }
}

export const getAnimation = (theme: TTheme) => {
  if (theme.animation) {
    return theme.animation
  }
  return defaultAnimation
}

export const getBreakpoints = (theme: TTheme) => {
  return theme.breakpoints ?? defaultBreakpoints
}

export const getBaseFont = (theme: TTheme) => {
  if (theme.typography && theme.typography["base-font"]) {
    return theme.typography["base-font"]
  }
  return defaultFont
}

export const getTypography = (theme: TTheme) => {
  if (theme.typography) return theme.typography
  return defaultTypography
}

export const getTG = ({
  typography,
  key,
}: {
  typography: TTypography
  key: keyof TTypography
}) => {
  return typography[key] ?? defaultFont
}

export const getFontWeights = (font: TFont) => {
  return (fontWeight: keyof TWeight) => {
    return (
      font["weight"][fontWeight]?.value ?? defaultFontWeights[fontWeight]?.value
    )
  }
}

export const getTypefaceByTheme = (theme: TTheme) => {
  if (theme && theme.typeface) {
    return theme.typeface
  }
  return defaultTypeface
}

export const getTypeface = ({
  typeface,
  type,
}: {
  typeface: TTypeface
  type: TTypefaceType
}) => {
  return (key: keyof TTypeface) => {
    return typeface[key][type]?.value
  }
}

export const getColors = (theme: TTheme) => {
  if (theme.colors) {
    return theme.colors
  }
  return defaultColors
}

const getValue = ({
  obj,
  path,
  index,
  result,
}: {
  obj: any
  path: string[]
  index: number
  result: string[]
}) => {
  if (obj.value) {
    result.push(obj.value)
    return
  }
  const key = path[index]
  if (obj[key]) {
    getValue({ obj: obj[key], path, index: index + 1, result })
  }
}

export const getColor = ({ colors }: { colors: TColor }) => {
  return (path: string[]) => {
    const result = [] as string[]
    getValue({ obj: colors, path, index: 0, result })
    return result
  }
}

export const getShadow = (theme: TTheme) => {
  if (theme.shadows) {
    return theme.shadows
  }
  return defaultShadows
}

export const getOverlays = (theme: TTheme) => {
  return theme.overlay ?? defaultOverlays
}

export const getIconSizing = (theme: TTheme) => {
  return theme.iconsizing ?? defaultIconSizing
}

export const getRadius = (theme: TTheme) => {
  return theme.radius ?? defaultRadius
}

export const getButtons = (theme: TTheme) => {
  return theme?.buttons ?? defaultButtons
}

export const getCustomStylesheet = (theme: TTheme) => {
  return theme?.styling?.urlPathToCustomStylesheet
}
