import { TTheme } from "../../design-tokens/types"
import { getOverlays } from "../../design-tokens/getters"

export const getOverlayVariables = (theme: TTheme) => {
  const overlays = getOverlays(theme)

  return `
        --modal-background: ${
          overlays.modal_background?.value ?? "rgba(50, 50, 64, 0.6)"
        };
        --masthead-background: ${
          overlays.masthead_background?.value ?? "rgba(50, 50, 64, 0.5)"
        };

        // TODO: this is only here for backwards compatibility with the old
        // theming system. This will need removed when we migrate old
        // themes to the new theming system
        --modal-flex-layout-justify-content: space-between;
        --modal-flex-layout-direction-mobile: row;
        --modal-flex-layout-direction-tablet: row;
        --modal-flex-layout-direction-desktop: row;
    `
}
