const WelcomeOrganise = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" className="reverse" style={{stroke: "none"}} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52 32H38.1623L36.8481 35.9427C36.8018 36.0814 36.7458 36.2166 36.6804 36.3473C33.9279 41.8523 26.0721 41.8523 23.3196 36.3473C23.2542 36.2166 23.1982 36.0813 23.1519 35.9427L21.8377 32H8V46H52V32ZM7 29C5.89543 29 5 29.8954 5 31V47C5 48.1046 5.89543 49 7 49H53C54.1046 49 55 48.1046 55 47V31C55 29.8954 54.1046 29 53 29H37.4415C36.5807 29 35.8164 29.5509 35.5442 30.3675L34.002 34.994C34.0007 34.998 33.9991 35.0019 33.9972 35.0057C32.3502 38.2995 27.6498 38.2995 26.0028 35.0057C26.0009 35.0019 25.9993 34.998 25.998 34.994L24.4558 30.3675C24.1836 29.5509 23.4193 29 22.5585 29H7Z"
      stroke="none"
    />
    <path
      d="M12 29V25C12 23.8954 12.8954 23 14 23H46C47.1046 23 48 23.8954 48 25V29"
      fill="none"
      strokeWidth="3"
      className="second-color-stroke"
    />
    <path
      d="M18 21.5V19C18 17.8954 18.8954 17 20 17H40C41.1046 17 42 17.8954 42 19V21.5"
      fill="none"
      strokeWidth="3"
    />
    <path
      d="M22 15.5V14C22 12.8954 22.8954 12 24 12H36C37.1046 12 38 12.8954 38 14V15.5"
      fill="none"
      strokeWidth="3"
      className="second-color-stroke"
    />
  </svg>
)

export default WelcomeOrganise
