import { SharedVaultDto } from "legado-generated-api-client"
import { getFoldersSharedWithMeFromLocalData } from "react-app-config"

export const getFilteredFoldersFromLocalData = (
  userEmail?: string,
  contactId?: string
) => {
  const unfiliteredFolders = getFoldersSharedWithMeFromLocalData(
    userEmail ?? ""
  )
  if (!unfiliteredFolders) return [] as SharedVaultDto
  const contactShares = unfiliteredFolders.find(
    (f) => f.sharingUserId === contactId
  )
  return contactShares ?? {}
}
