const WelcomeSecure = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" fill="none" stroke="none" />
    <path
      d="M9 27.5V13.4748L30 4.14148L51 13.4748V27.5C51 40.6165 41.9925 52.8302 30 55.953C18.0075 52.8302 9 40.6165 9 27.5Z"
      fill="none"
      strokeWidth="3"
    />
    <path
      d="M20 30L26.6667 37L40 23"
      fill="none"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="second-color-stroke"
    />
  </svg>
)

export default WelcomeSecure
