import { ContactDto } from "legado-generated-api-client"
import { getUserId } from "./getUser";
const contacts = require("../staticData/getContacts.json");

type TObj = { [key: string]: any };

const isContactDtos = (contacts: any) => {
  if ((contacts as ContactDto[])?.length) {
    return contacts as ContactDto[];
  }
};

type TGetContacts = (userEmail?: string) => ContactDto[];
export const getContactsFromLocalData: TGetContacts = (userEmail) => {
  const currentUserId = getUserId(userEmail);
  const contactsObj = contacts as TObj;
  const currentUserContacts = contactsObj[currentUserId];

  return isContactDtos(currentUserContacts) || [];
};
