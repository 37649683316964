import { ActivityLogEntryDto } from "legado-generated-api-client/models";
import { isActivities } from "./getRecentChanges";
const activities = require("../staticData/getRecentActivities.json");

type TObj = { [key: string]: any };

type TGetActivityLog = (nodeId: string) => ActivityLogEntryDto[];
export const getActivityLog: TGetActivityLog = (nodeId) => {
  const activitiesObj = activities as TObj;
  const allLogsForNode = Object.values(activitiesObj).flat().filter(activity => activity.nodeId === nodeId);
  return isActivities(allLogsForNode) || [];
};
