const ShareUser = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 20C20.4183 20 24 16.4183 24 12C24 7.58172 20.4183 4 16 4C11.5817 4 8 7.58172 8 12C8 16.4183 11.5817 20 16 20Z"
      strokeWidth="2"
      strokeMiterlimit="10"
      fill="none"
    />
    <path
      d="M3.8736 26.9988C5.10305 24.8708 6.87086 23.1037 8.99945 21.8752C11.128 20.6467 13.5424 20 16.0001 20C18.4578 20 20.8722 20.6468 23.0007 21.8754C25.1293 23.1039 26.8971 24.871 28.1265 26.9991"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="16"
      y="14"
      width="16"
      height="16"
      rx="8"
      className="reverse"
      stroke="none"
    />
    <rect
      x="18"
      y="21"
      width="12"
      height="2"
      rx="1"
      stroke="none"
      className="fill"
    />
    <rect
      x="23"
      y="16"
      width="2"
      height="12"
      rx="1"
      stroke="none"
      className="fill"
    />
  </svg>
)
export default ShareUser
