const Bin = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.48571 0C8.74221 0 7.99425 0.204756 7.4107 0.613244C6.82443 1.02364 6.4 1.65276 6.4 2.4V3.2H0.8C0.358172 3.2 0 3.55817 0 4C0 4.44183 0.358172 4.8 0.8 4.8H23.2C23.6418 4.8 24 4.44183 24 4C24 3.55817 23.6418 3.2 23.2 3.2H17.6V2.4C17.6 1.65276 17.1756 1.02364 16.5893 0.613244C16.0057 0.204756 15.2578 0 14.5143 0H9.48571ZM16 3.2V2.4C16 2.29855 15.9428 2.11374 15.6718 1.92401C15.398 1.73239 14.9832 1.6 14.5143 1.6H9.48571C9.0168 1.6 8.602 1.73239 8.32824 1.92401C8.05721 2.11374 8 2.29855 8 2.4V3.2H16Z"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6 4H2.4V23.2C2.4 24.0837 3.11634 24.8 4 24.8H20C20.8837 24.8 21.6 24.0837 21.6 23.2V4ZM8 8.8C8 8.35817 7.64183 8 7.2 8C6.75817 8 6.4 8.35817 6.4 8.8V21.6C6.4 22.0418 6.75817 22.4 7.2 22.4C7.64183 22.4 8 22.0418 8 21.6V8.8ZM12.8 8.8C12.8 8.35817 12.4418 8 12 8C11.5582 8 11.2 8.35817 11.2 8.8V21.6C11.2 22.0418 11.5582 22.4 12 22.4C12.4418 22.4 12.8 22.0418 12.8 21.6V8.8ZM17.6 8.8C17.6 8.35817 17.2418 8 16.8 8C16.3582 8 16 8.35817 16 8.8V21.6C16 22.0418 16.3582 22.4 16.8 22.4C17.2418 22.4 17.6 22.0418 17.6 21.6V8.8Z"
      fill="var(--color-universal-secondary-b)"
    />
  </svg>
)
export default Bin
