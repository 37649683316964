const WhiteBell = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    stroke="white"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6.69336 13.5732C6.76788 13.8271 6.92261 14.05 7.13439 14.2085C7.34617 14.3671 7.6036 14.4527 7.86816 14.4527C8.13271 14.4527 8.39015 14.3671 8.60193 14.2085C8.81371 14.05 8.96844 13.8271 9.04296 13.5732" />
    <path d="M7.8667 2.57293V1.25293" strokeWidth="1.25" />
    <path
      d="M7.86643 2.57324C9.03338 2.57324 10.1525 3.03681 10.9777 3.86197C11.8029 4.68713 12.2664 5.80629 12.2664 6.97324C12.2664 11.1069 13.1464 11.8132 13.1464 11.8132H2.58643C2.58643 11.8132 3.46643 10.6892 3.46643 6.97324C3.46643 5.80629 3.93 4.68713 4.75516 3.86197C5.58032 3.03681 6.69947 2.57324 7.86643 2.57324V2.57324Z"
      strokeWidth="1.25"
    />
  </svg>
);
export default WhiteBell;
