const Filter = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9C3 8.44772 3.52233 8 4.16667 8L26.8333 8C27.4777 8 28 8.44772 28 9C28 9.55228 27.4777 10 26.8333 10L4.16667 10C3.52233 10 3 9.55228 3 9Z"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C8 14.4477 8.44772 14 9 14L23 14C23.5523 14 24 14.4477 24 15C24 15.5523 23.5523 16 23 16L9 16C8.44772 16 8 15.5523 8 15Z"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 21C11 20.4477 11.4477 20 12 20H20C20.5523 20 21 20.4477 21 21C21 21.5523 20.5523 22 20 22L12 22C11.4477 22 11 21.5523 11 21Z"
      fill="var(--color-universal-secondary-b)"
    />
  </svg>
)
export default Filter
