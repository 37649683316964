const ArrowUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
  >
    <path
      d="M8 17.25L8 0.75"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.75 7.5L8 0.749999L1.25 7.5"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ArrowUp
