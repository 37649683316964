import { FunctionComponent } from "react"

type IQuilterLogoSvg = {
  onClick?: () => void
}

export const QuilterLogoSvg: FunctionComponent<IQuilterLogoSvg> = ({
  onClick,
}) => (
  <svg
    onClick={onClick}
    width="99"
    height="37"
    viewBox="0 0 99 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="topBar-quilter-logo logo-svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.7358 22.0707L42.7352 10.9941L37.7397 12.7779V12.9237L37.7415 12.9248V12.9624C38.7127 13.7076 39.2677 14.893 39.2677 16.5876V23.4938C38.6088 24.7135 37.5681 25.6286 35.9035 25.6286C33.9611 25.6286 32.851 24.409 32.851 22.037L32.8493 10.9913L27.9261 12.7779V12.8928L27.9301 12.8945H27.9261V12.9624C28.7934 13.7076 29.3829 14.8594 29.3829 16.5876V22.5793C29.3829 26.103 30.9091 28.3387 33.8572 28.3387C36.6664 28.3387 38.2621 26.2382 39.4411 24.0361V28.2372L44.2965 27.1533V27.0855C43.187 26.306 42.7358 25.6623 42.7358 22.0707Z"
      fill="#0F7B3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.2388 10.9913L46.2225 12.841V13.0854C47.0527 14.0281 47.7793 15.3539 47.7793 17.5883V21.5673C47.7793 24.6041 47.4684 26.3492 46.984 28.2688V28.3387H52.0003V28.2688C51.308 26.3492 51.2388 24.5342 51.2388 21.5321V10.9913Z"
      fill="#0F7B3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1114 8.1001C50.1708 8.1001 51.0373 7.24009 51.0373 6.18869C51.0373 5.13728 50.1708 4.24512 49.1114 4.24512C48.0525 4.24512 47.1854 5.13728 47.1854 6.18869C47.1854 7.24009 48.0525 8.1001 49.1114 8.1001Z"
      fill="#0F7B3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.9128 0.390137L53.9262 2.2591V2.32972C54.7515 3.24669 55.4738 4.58683 55.4738 6.87955V23.4364C55.4738 26.0816 55.1642 27.2805 54.7174 28.2681V28.3387H59.704V28.2681C59.2225 27.3161 58.9128 26.0466 58.9128 23.4013V0.390137Z"
      fill="#0F7B3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.6825 15.2493H92.5429V10.9913L87.6301 12.841V12.9109C88.4663 13.8531 89.1981 15.1448 89.1981 17.4485V21.5673C89.1981 24.6388 89.0585 26.3492 88.3965 28.2688V28.3387H93.4489V28.2688C92.7523 26.3492 92.6825 24.5694 92.6825 21.5321V15.9477C93.4489 14.9004 94.3549 14.3771 94.9125 14.3771C95.679 14.3771 97.3513 14.9004 98.014 15.4238H98.153L98.2227 11.1658C95.9575 11.1311 94.0763 13.6088 92.6825 15.2493Z"
      fill="#0F7B3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.0111 11.6074C81.1203 11.6074 82.1782 13.1703 82.1861 16.5316H74.8421C75.2286 13.7077 76.581 11.6074 79.0111 11.6074ZM80.6845 25.4304C77.0987 25.4304 74.7081 23.1382 74.7081 18.3137V17.663H85.6697C85.8748 14.6522 83.1429 10.9913 79.1479 10.9913C75.3914 10.9913 71.2596 14.4472 71.2596 19.9558C71.2596 24.7118 74.3674 28.3387 78.9428 28.3387C82.4941 28.3387 84.8163 26.0805 85.7041 23.2747L85.499 23.1382C84.3382 24.6088 82.7671 25.4304 80.6845 25.4304Z"
      fill="#0F7B3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.7437 27.1454C67.0766 27.1454 66.3128 26.2472 66.3128 24.5893V13.0207H70.7981V11.8852H70.7918V11.8767H66.3128V7.97301H65.9657L60.9304 12.2912V12.4987H62.8056V24.6928C62.8056 27.9401 64.6463 28.8731 66.695 28.8731C68.6391 28.8731 70.1672 28.0441 71.1395 26.6966L71.0349 26.5583C70.8614 26.6617 69.9585 27.1454 68.7437 27.1454Z"
      fill="#0F7B3F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1478 31.8809C24.7323 31.9621 24.3237 32.0147 23.9731 32.0415C20.0337 32.3405 15.7242 30.9822 13.6801 28.8508C19.9866 28.4643 26.1816 22.8312 26.1816 15.746C26.1816 8.42236 19.5843 2.54972 13.056 2.54972C6.52773 2.54972 0 8.42236 0 15.746C0 22.7929 6.04213 28.4237 12.3268 28.8382C12.3411 28.8588 12.3538 28.8765 12.3676 28.896C14.2824 32.7933 18.3235 35.8663 23.072 36.0401C23.5703 36.063 24.0754 36.0418 24.5886 35.9646C24.7817 35.9355 24.9679 35.8977 25.1478 35.8514V31.8809ZM4.41005 15.746C4.41005 8.62932 7.08344 3.24035 13.056 3.24035C19.0631 3.24035 21.7715 8.62932 21.7715 15.746C21.7715 22.8964 19.0631 28.1819 13.056 28.1819C7.08344 28.1819 4.41005 22.8621 4.41005 15.746Z"
      fill="#0F7B3F"
    />
  </svg>
)

export default QuilterLogoSvg
