import { AlertMessageItem } from "../../modules/AlertMessage/AlertMessageItemList/AlertMessageItemList"
import { PasswordSetupError } from "./validatePassword"

export const getSummaryErrors = ({
  errorsPassword,
  errorConfirmPasswordMessage,
  isRequiredAssistanceError,
  isCharacterLimitError,
  isTermsAndConditionsError,
}: {
  errorsPassword: PasswordSetupError[]
  errorConfirmPasswordMessage?: string
  isRequiredAssistanceError: boolean
  isCharacterLimitError: boolean
  isTermsAndConditionsError: boolean
}): AlertMessageItem[] => {
  const summaryErrors: AlertMessageItem[] = []
  if (errorsPassword.length > 0) {
    summaryErrors.push({
      id: 0,
      message: "make sure your password meets password strength criteria",
      href: "password",
    })
  }
  if (errorConfirmPasswordMessage !== undefined) {
    summaryErrors.push({
      id: 1,
      message: "make sure your passwords match",
      href: "confirm-password",
    })
  }
  if (isRequiredAssistanceError) {
    summaryErrors.push({
      id: 2,
      message: "complete special assistance details",
      href: "additional-assistance-input",
    })
  }
  if (isCharacterLimitError) {
    summaryErrors.push({
      id: 3,
      message: "special assistance details must be under 500 characters",
      href: "additional-assistance-input",
    })
  }
  if (isTermsAndConditionsError) {
    summaryErrors.push({
      id: 4,
      message: "to create an account you must agree to the below",
      href: "termsAndConditions",
    })
  }
  return summaryErrors
}
