import { LoaderCenteredOnTop } from "../atoms/Loader"

export const LoadingFallBack = () => {
  return (
    <div className="loading-fallback">
      <div className="loading-fallback__spinner">
        <LoaderCenteredOnTop />
      </div>
    </div>
  )
}
