import { FC } from "react"
import classNames from "classnames"
import { useSearchContext, setSearchExpression } from "../../../contexts/search"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import "./SearchBarWithContext.css"

interface SearchBarWithContextProps {
  className?: string
}

export const SearchBarWithContext: FC<SearchBarWithContextProps> = ({
  className,
}) => {
  const {
    searchState: { label, searchExpression },
    dispatch,
  } = useSearchContext()
  const crossIcon = useGetIcon("Cross")
  const searchIcon = useGetIcon("Search")

  const searchInputClass = classNames("search-input", {
    "has-content": searchExpression !== "",
  })

  const searchIconContainerClass = classNames("search-icon", {
    "darker-icon": searchExpression !== "",
  })

  return (
    <div
      className={`search-bar ${className ? className : ""}`}
      data-testid="searchbarwithcontext-search"
    >
      <form role="search" onSubmit={(e) => e.preventDefault()}>
        <input
          value={searchExpression}
          className={searchInputClass}
          onChange={(e) => {
            dispatch(setSearchExpression(e.target.value))
          }}
          placeholder={`${label}...`}
          type="search"
          aria-label={label}
          id="search-bar-input-id"
        />
        {searchExpression && (
          <button
            className="crossIcon"
            onClick={() => {
              dispatch(setSearchExpression(""))
            }}
            title="Clear search"
            type="button"
          >
            {crossIcon}
          </button>
        )}
        <span className={searchIconContainerClass}>{searchIcon}</span>
      </form>
    </div>
  )
}
