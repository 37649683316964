import { DistributionProjectInfoDto } from "legado-generated-api-client"

export const getProjectResponse: DistributionProjectInfoDto = {
  id: "fa3d70e9-5d54-4103-a968-adf249235f1f",
  name: "Test Project",
  versionedFiles: [
    {
      tags: [],
      currentVersion: 1,
      files: [
        {
          version: 1,
          fileName: "Lang Cat PDF.pdf",
          extension: "PDF",
          mimeType: "application/pdf",
          sizeInBytes: 239519,
          folderName: null,
          associatedProduct: null,
          isEditable: false,
          isDeletable: false,
          isVisibilityEditable: false,
          hasThumbnail: true,
          hasPreview: true,
          canBeReviewed: false,
          hasBeenReviewed: false,
          stillNeedsMalwareScan: false,
          isExternalDocument: false,
          visibility: "ClientAndAdviser",
          deleted: false,
          isDistributedFile: true,
          customerHasRead: false,
          isImportantDocument: false,
          base64StringThumbnail: null,
          versionNumber: 0,
          hasCommenting: false,
          approved: false,
          lastCommentedByUserId: null,
          id: "dbcc44ea-fc9d-4174-b0cd-a77abbf49556",
          parentId: null,
          ownerId: "67e486e1-305c-4d87-aa47-b289d942e49f",
          createdById: "67e486e1-305c-4d87-aa47-b289d942e49f",
          isOwner: false,
          name: "Lang Cat PDF",
          dateShared: null,
          created: "2024-06-14T11:37:25Z",
          updated: null,
          createdBy: "Jensen Zale",
        },
      ],
    },
  ],
  isCsvFiltered: false,
  filterCriteria: undefined,
  isSubAccount: false,
  fileVisibility: "ClientAndAdviser",
  scheduledDateTimeClient: "0001-01-01T00:00:00Z",
  scheduledDateTimeAdviser: "0001-01-01T00:00:00Z",
  status: "FilesUploaded",
  lastModifiedBy: "Rita Zale",
  lastModifiedDate: "2024-06-14T11:37:26Z",
  sendNotification: false,
}
