export enum InputFieldType {
  NAME = "name",
  TEXT = "text",
  /** No blank spaces at the beginning or end of the value and no more than one consecutive blank space between words
   */
  LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT = "lettersNumbersSpacesDashUnderscoreDot",
  EMAIL = "email",
  SIMPLE_EMAIL = "simple_email",
  PASSWORD = "password",
  PHONE_NUMBER = "phoneNumber",
  PHONE_NUMBER_VALID_FORMAT = "phoneNumberValidFormat",
  URL = "url",
  REGISTRATION_NUMBER = "registrationNumber",
  NUMBER = "number",
}

/**
 * @param0 An object with the following properties: type, value, currentUserEmail (optional), alreadyAddedContactsEmails(optional)
 * @returns empty string if the input is valid, otherwise returns an error message based on a set of rules given by the type prop.
 */
export const validateInput = ({
  type = InputFieldType.TEXT,
  value,
  currentUserEmail = "",
  alreadyAddedContactsEmails = [],
}: {
  type?: InputFieldType
  value: string
  currentUserEmail?: string
  alreadyAddedContactsEmails?: string[]
}) => {
  let error = ""
  switch (type) {
    case InputFieldType.NAME: {
      if (value.length === 0) {
        return "Name is required"
      }
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        error = "Invalid Name"
      }
      break
    }
    case InputFieldType.NUMBER: {
      if (value.length === 0) {
        return ""
      }

      if (/\D/i.test(value)) {
        return "Invalid input, only numbers can be entered"
      }

      break
    }
    case InputFieldType.TEXT: {
      if (value.length === 0) {
        return ""
      }
      if (!/^[^!""£$%^*={}[\]<>,/@#~\\]+$/i.test(value)) {
        error = "Invalid Name"
      }
      if (value.split(".").length - 1 > 1) {
        error = "Invalid Name"
      }
      break
    }
    // This is a simple email validation that checks for the presence of an '@' symbol. This was added because COOP validates email this way.
    case InputFieldType.SIMPLE_EMAIL: {
      if (value.length === 0) {
        return "Enter an email"
      }
      if (value.indexOf("@") === -1) {
        return "Enter an email that includes an ‘@’"
      }
      break
    }
    case InputFieldType.EMAIL: {
      if (value.length === 0) {
        return "Email is required"
      }
      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9-]+\.)+[A-Za-z]+$/i.test(
          value
        )
      ) {
        error = "Email is invalid"
      } else if (
        currentUserEmail.toLowerCase().trim() === value.toLowerCase().trim()
      ) {
        error = "You can not add yourself as a contact."
      } else if (alreadyAddedContactsEmails) {
        let alreadyAdded = false
        alreadyAddedContactsEmails.forEach((email) => {
          if (alreadyAdded) return
          if (email.toLowerCase().trim() === value.toLowerCase().trim()) {
            alreadyAdded = true
          }
        })
        if (alreadyAdded) {
          error = "You have already added that email address as a contact."
        }
      }
      break
    }
    case InputFieldType.PASSWORD: {
      if (value.length === 0) {
        return "Password is required"
      }
      break
    }
    case InputFieldType.PHONE_NUMBER: {
      if (value.length === 0) {
        return "Phone Number is required"
      }
      if (
        !/^[+]?[(]?[0-9]{1,5}[)]?[-\s.]?[0-9]{1,5}[-\s.]?[0-9]{3,5}[-\s.]?[0-9]{3,5}[-\s.]?[0-9]{3,5}$/.test(
          value
        ) ||
        /[a-zA-Z]/.test(value)
      ) {
        error = "Invalid Phone Number"
      }
      break
    }
    // the same validation as on Back End, check in place required to validate the value before API call
    // when adding a new contact
    case InputFieldType.PHONE_NUMBER_VALID_FORMAT: {
      if (!/^\+?[0]{0,2}?[1-9][0-9]{7,14}$/.test(value)) {
        error = "Invalid Phone Number"
      }
      break
    }
    case InputFieldType.URL: {
      if (value.length === 0) {
        return ""
      }
      if (
        !/^(http)?[s]?(:\/\/)?(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}[.]{1}[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
          value
        )
      ) {
        error = "Invalid Link"
      }
      break
    }

    //Regex from https://gist.github.com/danielrbradley/7567269
    case InputFieldType.REGISTRATION_NUMBER: {
      if (value.length === 0) {
        return ""
      }
      if (
        !/^(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)$/i.test(
          value
        ) ||
        value.length > 8
      ) {
        error = "Invalid UK Registration Number"
      }
      break
    }
    case InputFieldType.LETTERS_NUMBERS_SPACES_DASH_UNDERSCORE_DOT: {
      if (value.length === 0) {
        return ""
      }
      if (!/^[a-zA-Z0-9\s_().-]*$/.test(value)) {
        error = "Invalid Name"
      }

      if (value.startsWith(" ") || value.endsWith(" ")) {
        error = "Invalid Name"
      }

      if (value.startsWith("-") || value.startsWith("_")) {
        error = "Invalid Name"
      }

      if (value.split(".").length - 1 > 1) {
        error = "Invalid Name"
      }

      if (value.split(".")[0].endsWith(" ")) {
        error = "Invalid Name"
      }

      if (value.split("  ").length - 1 > 0) {
        error = "Invalid Name"
      }

      break
    }
    default: {
      break
    }
  }
  return error
}
