const WelcomeConnect = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="60" height="60" fill="none" stroke="none" />
    <path
      d="M32.1997 40.3752L33.4808 40.5224L33.8968 39.3019C35.0525 35.9112 38.2361 33.6 41.8018 33.6C46.3949 33.6 50.1647 37.3862 50.1647 42.0747C50.1647 42.3069 50.1553 42.5398 50.1364 42.7716L49.9733 44.7789L51.9655 44.484C52.1075 44.4629 52.2532 44.4522 52.4025 44.4522C54.0377 44.4522 55.4 45.8043 55.4 47.4996C55.4 49.1958 54.0373 50.5481 52.4025 50.5481H31.6301C28.8742 50.5481 26.6 48.2741 26.6 45.4455C26.6 42.6168 28.8742 40.3428 31.6301 40.3428C31.8221 40.3428 32.0125 40.3537 32.1997 40.3752Z"
      fill="none"
      strokeWidth="3"
      className="second-color-stroke"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9567 13.1553C18.5947 12.627 18.6836 11.6814 18.1553 11.0433C17.6269 10.4053 16.6813 10.3164 16.0433 10.8447L15.504 11.2913C3.97811 20.8361 3.81147 38.2029 14.5612 48.0272H9.16642C8.01583 48.0272 7.08309 48.9599 7.08309 50.1105C7.08309 51.2611 8.01583 52.1938 9.16642 52.1938H19.1101C20.2607 52.1938 21.1934 51.2611 21.1934 50.1105V40.1668C21.1934 39.0162 20.2607 38.0835 19.1101 38.0835C17.9595 38.0835 17.0268 39.0162 17.0268 40.1668V46.205C7.16161 37.666 7.15639 22.0992 17.4174 13.6019L17.9567 13.1553Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.5592 27.9182C50.7902 28.7138 51.6224 29.1715 52.4179 28.9406C53.2135 28.7096 53.6713 27.8774 53.4403 27.0819L52.2217 22.8839C50.5058 16.973 46.3448 12.1779 40.9213 9.6031L45.7249 8.73413C46.8571 8.52931 47.6089 7.44543 47.4041 6.31321C47.1993 5.181 46.1154 4.42919 44.9832 4.63401L35.1983 6.40409C34.0661 6.60891 33.3143 7.69279 33.5191 8.825L35.2892 18.6099C35.494 19.7421 36.5779 20.4939 37.7101 20.2891C38.8423 20.0843 39.5941 19.0004 39.3893 17.8682L38.28 11.736C43.5957 13.7517 47.727 18.1615 49.3406 23.7202L50.5592 27.9182Z"
      stroke="none"
    />
  </svg>
)

export default WelcomeConnect
