const GreenInfo = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="connectivity-svg"
  >
    <g clipPath="url(#clip0_5625_11256)">
      <rect width="34" height="34" rx="17" fill="#DBF1ED" />
      <path
        d="M17 27.4C22.7438 27.4 27.4 22.7437 27.4 17C27.4 11.2562 22.7438 6.59998 17 6.59998C11.2562 6.59998 6.6 11.2562 6.6 17C6.6 22.7437 11.2562 27.4 17 27.4Z"
        fill="#5BA899"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4214 16.1643C15.4214 15.754 15.754 15.4214 16.1643 15.4214H17C17.4103 15.4214 17.7429 15.754 17.7429 16.1643V21.2714H17.8357C18.246 21.2714 18.5786 21.604 18.5786 22.0143C18.5786 22.4246 18.246 22.7572 17.8357 22.7572H17C16.5897 22.7572 16.2571 22.4246 16.2571 22.0143V16.9072H16.1643C15.754 16.9072 15.4214 16.5746 15.4214 16.1643Z"
        fill="white"
      />
      <path
        d="M17 13.6572C17.6154 13.6572 18.1143 13.1583 18.1143 12.5429C18.1143 11.9275 17.6154 11.4286 17 11.4286C16.3846 11.4286 15.8857 11.9275 15.8857 12.5429C15.8857 13.1583 16.3846 13.6572 17 13.6572Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5625_11256">
        <rect width="34" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GreenInfo;
