import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { IFolder } from "../../api/api-client/api-types"
import classNames from "classnames"

import { useUserContext } from "../../contexts/users"
import { useApplicationContext } from "../../contexts/application/context"
import { sendFolderSelectGaData } from "../../utils/gaEvents"
import { NavMenuNavigation, NAVMENU_KEYS } from "../NavMenuDropDown/constants"
import { TLink, UserModuleType } from "../../contexts/application/constants"
import {
  setSelectedContact,
  setSelectedFile,
  setSelectedUserSharingWithMe,
  setSelectedFolder,
} from "../../contexts/application/action"

import { NavMenuDropDownItem } from "../NavMenuDropDown/NavMenuDropDownItem"
import { ChevronUpOrDown } from "../atoms/ChevronUpOrDown"

import "../NavMenuDropDown/NavMenuDropDown.css"

export interface MenuBarItemProps {
  link: TLink
  isActive?: boolean
  menuItemIsOpen?: string
  idDesktopNav?: boolean
  accessibilityName?: string
  navigationId?: number
  navigationData?: NavMenuNavigation
  setVaultMenuOpen?: (vaultMenuOpen: boolean) => void
  setMenuItemIsOpen?: (menuItemIsOpen: string) => void
  setNavigationData?: React.Dispatch<React.SetStateAction<NavMenuNavigation>>
}

export const MenuBarItem: React.FC<MenuBarItemProps> = ({
  link,
  menuItemIsOpen,
  idDesktopNav,
  accessibilityName = "",
  navigationId = -1,
  navigationData,
  setVaultMenuOpen,
  setMenuItemIsOpen,
  setNavigationData,
}) => {
  const {
    applicationState: {
      folders,
      selectedFolderId,
      usersSharingWithMe,
      selectedTheme,
    },
    dispatch,
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const [expanded, setExpanded] = useState("")
  const [focusDirection, setFocusDirection] = useState(1)

  const isMyFilesMenu = link.href === "/my-files"
  const isSharingMenu = link.href === "/sharing"
  var sharesEnabled = usersSharingWithMe.length > 0

  const location = useLocation()
  const navigate = useNavigate()
  const isGotoPageLinkOnSamePage =
    link.href === "/" && location.pathname === link.href

  const menuItemBoxClass = classNames("menuitem-box", link.name, {
    expanded: menuItemIsOpen === link.href,
    disabled: isGotoPageLinkOnSamePage,
  })

  const menuItemWrapperClass = classNames("menuitem-wrapper", {
    disabled: isGotoPageLinkOnSamePage,
    "onex-menu-item-wrapper": selectedTheme === "ONE_X",
  })

  const menuItemClass = classNames("menuitem", {
    disabled: isGotoPageLinkOnSamePage,
  })

  const navMenuDropdownClass = classNames("nav-menu-dropdown", {
    "onex-dropdown": selectedTheme === "ONE_X",
  })

  return (
    <div
      onClick={() => {
        if (link.href === "/") {
          setMenuItemIsOpen && setMenuItemIsOpen("")
          if (location.pathname !== link.href) {
            setVaultMenuOpen && setVaultMenuOpen(false)
            navigate(link.href)
          }
          return
        }
        setMenuItemIsOpen &&
          setMenuItemIsOpen(menuItemIsOpen !== link.href ? link.href : "")
      }}
      className={menuItemBoxClass}
      role="group"
    >
      <div className={menuItemWrapperClass}>
        <div
          className={menuItemClass}
          onFocus={() => {
            setFocusDirection(1)
          }}
        >
          <span
            aria-label={accessibilityName}
            role="group"
            id={link.name.replace(/ /g, "")}
          >
            {link.name}
          </span>
          {(isMyFilesMenu || isSharingMenu) && (
            <ChevronUpOrDown
              expanded={menuItemIsOpen === link.href}
              size="s"
              setExpanded={() => {
                setMenuItemIsOpen &&
                  setMenuItemIsOpen(
                    menuItemIsOpen !== link.href ? link.href : ""
                  )
              }}
              accessibilityLabelId={link.name.replace(/ /g, "")}
              thisRefId={navigationId}
              maxNavigationId={2}
              level={0}
              navigationData={navigationData}
              setNavigationData={setNavigationData}
            />
          )}
        </div>
      </div>

      {isMyFilesMenu && menuItemIsOpen === "/my-files" && (
        <ul className={navMenuDropdownClass}>
          {folders.map((folder, i) => {
            return (
              <NavMenuDropDownItem
                key={folder.id}
                folder={folder}
                onClickFn={(folderId: string) => {
                  dispatch(setSelectedFile(undefined))
                  dispatch(setSelectedFolder(folderId))
                  sendFolderSelectGaData(folder)
                  setVaultMenuOpen && setVaultMenuOpen(false)
                  setExpanded("")
                  if (setMenuItemIsOpen) {
                    setMenuItemIsOpen("")
                  }

                  if (link.href !== location.pathname) {
                    navigate(link.href)
                  }
                }}
                selectedId={selectedFolderId}
                idDesktopNav={idDesktopNav}
                expanded={expanded}
                setExpanded={setExpanded}
                controlsId={"controls-my-files"}
                navigationLevel={0}
                navigationId={i}
                maxNavigationId={folders.length - 1}
                navigationData={navigationData}
                setNavigationData={setNavigationData}
                setTopParentIsOpen={setMenuItemIsOpen}
              />
            )
          })}
        </ul>
      )}
      {isSharingMenu && menuItemIsOpen === "/sharing" && (
        <ul
          className={navMenuDropdownClass}
          aria-label="Sharing"
          onKeyDown={(event) => {
            if (
              event.key === NAVMENU_KEYS.ARROW_DOWN ||
              event.key === NAVMENU_KEYS.TAB
            ) {
              setFocusDirection(1)
            } else if (event.key === NAVMENU_KEYS.ARROW_UP) {
              setFocusDirection(-1)
            }
          }}
        >
          {currentUser?.modules?.includes(UserModuleType.SHARING) && (
            <NavMenuDropDownItem
              folder={
                {
                  id: "nav-menu-dropdown-item-my-contacts-id",
                  name: "Contacts",
                  level: 2,
                } as IFolder
              }
              selectedId={
                location.pathname.includes("sharing")
                  ? "nav-menu-dropdown-item-my-contacts-id"
                  : ""
              }
              onClickFn={() => {
                dispatch(setSelectedContact(""))
                setMenuItemIsOpen && setMenuItemIsOpen("")
                setVaultMenuOpen && setVaultMenuOpen(false)
                navigate("/sharing")
              }}
              focusBlocked={false}
              controlsId={"controls-sharing"}
              navigationLevel={1}
              navigationId={0}
              maxNavigationId={2}
              navigationData={navigationData}
              setNavigationData={setNavigationData}
              setTopParentIsOpen={setMenuItemIsOpen}
              focusDirection={focusDirection}
            />
          )}
          {currentUser?.modules?.includes(
            UserModuleType.SHARED_WITH_ME_LIST
          ) && (
            <NavMenuDropDownItem
              folder={
                {
                  id: "nav-menu-dropdown-item-shared-with-me-id",
                  name: "Shared",
                  level: 2,
                } as IFolder
              }
              selectedId={
                location.pathname === "/sharedWithMe" || !sharesEnabled
                  ? "nav-menu-dropdown-item-shared-with-me-id"
                  : ""
              }
              onClickFn={() => {
                if (sharesEnabled) {
                  dispatch(setSelectedUserSharingWithMe(""))
                  setMenuItemIsOpen && setMenuItemIsOpen("")
                  setVaultMenuOpen && setVaultMenuOpen(false)
                  navigate("/sharedWithMe")
                }
              }}
              focusBlocked={false}
              controlsId={"controls-shared-with-me"}
              navigationLevel={1}
              navigationId={1}
              maxNavigationId={2}
              navigationData={navigationData}
              setNavigationData={setNavigationData}
              setTopParentIsOpen={setMenuItemIsOpen}
              focusDirection={focusDirection}
            />
          )}
        </ul>
      )}
    </div>
  )
}
