const Error = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 17.5C0 7.83502 7.83502 0 17.5 0C27.165 0 35 7.83502 35 17.5C35 27.165 27.165 35 17.5 35C7.83502 35 0 27.165 0 17.5Z"
      stroke="none"
      className="reverse"
    />
    <path
      d="M17.5029 15V20"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.705 6.04153L4.2824 25.8674C4.09962 26.1847 4.00323 26.5447 4.00293 26.9112C4.00263 27.2778 4.09842 27.6379 4.28068 27.9555C4.46293 28.273 4.72521 28.5367 5.04114 28.7201C5.35706 28.9035 5.71548 29 6.08033 29H28.9255C29.2904 29 29.6488 28.9035 29.9647 28.7201C30.2806 28.5367 30.5429 28.273 30.7252 27.9555C30.9074 27.6379 31.0032 27.2778 31.0029 26.9112C31.0026 26.5447 30.9062 26.1847 30.7235 25.8674L19.3009 6.04153C19.1184 5.7248 18.8562 5.46184 18.5406 5.27904C18.2251 5.09623 17.8672 5 17.5029 5C17.1387 5 16.7808 5.09623 16.4652 5.27904C16.1497 5.46184 15.8875 5.7248 15.705 6.04153V6.04153Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path d="M17.5029 24C18.0552 24 18.5029 23.5523 18.5029 23C18.5029 22.4477 18.0552 22 17.5029 22C16.9506 22 16.5029 22.4477 16.5029 23C16.5029 23.5523 16.9506 24 17.5029 24Z" />
  </svg>
)

export default Error
