const NotificationWarning = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Union"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10.4827C2.5 6.34053 5.85786 2.98267 10 2.98267C14.1421 2.98267 17.5 6.34053 17.5 10.4827C17.5 14.6248 14.1421 17.9827 10 17.9827C5.85786 17.9827 2.5 14.6248 2.5 10.4827ZM10 1.98267C5.30558 1.98267 1.5 5.78825 1.5 10.4827C1.5 15.1771 5.30558 18.9827 10 18.9827C14.6944 18.9827 18.5 15.1771 18.5 10.4827C18.5 5.78825 14.6944 1.98267 10 1.98267ZM10 6.13081C10.2761 6.13081 10.5 6.35467 10.5 6.63081V11.3716C10.5 11.6477 10.2761 11.8716 10 11.8716C9.72386 11.8716 9.5 11.6477 9.5 11.3716V6.63081C9.5 6.35467 9.72386 6.13081 10 6.13081ZM10 15.4827C10.5523 15.4827 11 15.035 11 14.4827C11 13.9304 10.5523 13.4827 10 13.4827C9.44772 13.4827 9 13.9304 9 14.4827C9 15.035 9.44772 15.4827 10 15.4827Z"
      fill="black"
      stroke="none"
    />
  </svg>
)
export default NotificationWarning
