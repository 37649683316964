import { FC, ReactNode } from "react"
import { ApplicationProvider } from "../application/context"
import { applicationReducer } from "../application/reducer"
import { emptyInitialData } from "../application/emptyInitialData"
import { UserProvider } from "../users"
import { SearchProvider } from "../search"
import { ToastProvider } from "../toasts"
import { OrganisationsProvider } from "../organisations"
import { ThumbnailProvider } from "../thumbnails"
import { AuthContextProvider } from "../auth/authContextProvider"
import { MemoryRouter, BrowserRouter as Router } from "react-router-dom"
import { PlatformContextProvider } from "../platform/platformContextProvider"

export const ProvidersWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Router>
      <PlatformContextProvider>
        <ApplicationProvider
          initialApplicationData={{
            ...emptyInitialData,
          }}
          reducer={applicationReducer}
        >
          <UserProvider>
            <OrganisationsProvider>
              <ThumbnailProvider>
                <ToastProvider>
                  {/* AuthContext provider is dependent on user, application and thumbnail
                providers, for backwards compatibility leave this below them*/}
                  <AuthContextProvider>
                    <SearchProvider>{children}</SearchProvider>
                  </AuthContextProvider>
                </ToastProvider>
              </ThumbnailProvider>
            </OrganisationsProvider>
          </UserProvider>
        </ApplicationProvider>
      </PlatformContextProvider>
    </Router>
  )
}

// We need this for Storybook to overcome the issue with autLoading.
export const ProvidersWrapperForStoryBook: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <MemoryRouter>
      <ApplicationProvider
        initialApplicationData={{
          ...emptyInitialData,
          // Since this is used for storybook, we can set authLoading to false
          // This was added as a workaround to fix the issue with flickering when starting the application and the default value is true.
          authLoading: false,
        }}
        reducer={applicationReducer}
      >
        <UserProvider>
          <OrganisationsProvider>
            <ThumbnailProvider>
              <ToastProvider>
                <AuthContextProvider>
                  <SearchProvider>{children}</SearchProvider>
                </AuthContextProvider>
              </ToastProvider>
            </ThumbnailProvider>
          </OrganisationsProvider>
        </UserProvider>
      </ApplicationProvider>
    </MemoryRouter>
  )
}
