import { ModalContent, ModalFooter, ModalHeader } from "../modules/Modal"
import { Button } from "../atoms/Button"
import { TRadioOptions } from "../../utils/shares"

import "./ReviewChangesContact.css"

export interface ReviewChangesContactProps {
    contactName?: string | null
    onBack: () => void
    onClose: () => void
    savePermissions?: () => void
    permissionGroups: {[groupname: string]: TRadioOptions[]}
}

export const SectionPermissions = ({ sectionName, permissions }: { sectionName: string, permissions: TRadioOptions[] }) => {
    const permissionGroups = permissions.reduce((sortedObject: {[key: string]: string}, curr: TRadioOptions) => {
        sortedObject[curr.option] = sortedObject[curr.option] ? sortedObject[curr.option] + ", " + curr.label : curr.label
        return sortedObject
    }, {})
    return (
        <div className="review-permissions-group">
            <h3 className="review-section-name">{sectionName}:</h3>
            {Object.keys(permissionGroups).map(key => (
                <div className="review-folders-permission" key={key}>
                    <p>{permissionGroups[key]}</p>
                    <p className="group-permission">({key})</p>
                </div>
            ))}
        </div>
    )
}

export const ReviewChangesContact = ({ 
    contactName, 
    onBack, 
    onClose, 
    permissionGroups, 
    savePermissions 
}: ReviewChangesContactProps
) => (
    <>
        <ModalHeader>Review Changes</ModalHeader>
        <ModalContent className="review-changes-modal">
            <div className="review-description modal-standard-m-top-bottom">
                <p className="review-text-m-light">
                    Please make sure all details are correct before proceeding. This can be changed at any point from a contact details page. 
                </p>
                <h2>{contactName}</h2>
            </div>
            <div className="review-edit">
                <p className="review-text-m-light">Edit individual folder permissions:</p>
                <Button
                    variant="tertiary"
                    type="button"
                    onClick={onBack}
                >
                    Edit
                </Button>
            </div>
            <div>
                {Object.keys(permissionGroups).map(groupName => (
                    <SectionPermissions key={groupName} sectionName={groupName} permissions={permissionGroups[groupName]} />
                ))}
            </div>
        </ModalContent>
        <ModalFooter className="text-end">
            <Button
                className="mr-3"
                variant="tertiary"
                type="button"
                onClick={onClose}
            >
                Cancel
            </Button>
            <Button
                onClick={() => {
                if (savePermissions) {
                    savePermissions()
                }
                }}
                variant="primary"
                type="submit"
            >
                Save
            </Button>
        </ModalFooter>
    </>
)
