const Verified = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="connectivity-svg"
  >
    <path
      d="M8.99999 15.9428C12.8344 15.9428 15.9428 12.8344 15.9428 8.99999C15.9428 5.16555 12.8344 2.05713 8.99999 2.05713C5.16555 2.05713 2.05713 5.16555 2.05713 8.99999C2.05713 12.8344 5.16555 15.9428 8.99999 15.9428Z"
      fill="#03A644"
    />
    <path
      d="M12.0857 6.94287C10.479 8.54953 9.57818 9.45032 7.97145 11.057L5.91431 9.00001"
      stroke="#FEFEFE"
      strokeWidth="1.02857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Verified;
