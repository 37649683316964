import { ApiController } from "../../api/apiController"

export const getItemLocalStorage = (key: string) => {
  const item = localStorage.getItem(key)
  if (item && item !== "undefined") {
    return JSON.parse(item)
  }
}

export const setItemLocalStorage = (key: string, value: any) => {
  if (!ApiController.getInstance().connected) return
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(error)
  }
}

export const deleteItemLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const clearLocalStorage = () => {
  localStorage.clear()
}
