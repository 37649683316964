const MessageInfo = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="34" height="34" rx="17" fill="#C5D4DD" />
    <path
      d="M17.0001 27.4C22.7439 27.4 27.4001 22.7437 27.4001 17C27.4001 11.2562 22.7439 6.59998 17.0001 6.59998C11.2563 6.59998 6.6001 11.2562 6.6001 17C6.6001 22.7437 11.2563 27.4 17.0001 27.4Z"
      fill="#195576"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4214 16.1643C15.4214 15.754 15.754 15.4214 16.1642 15.4214H17C17.4102 15.4214 17.7428 15.754 17.7428 16.1643V21.2714H17.8357C18.2459 21.2714 18.5785 21.604 18.5785 22.0143C18.5785 22.4246 18.2459 22.7572 17.8357 22.7572H17C16.5897 22.7572 16.2571 22.4246 16.2571 22.0143V16.9072H16.1642C15.754 16.9072 15.4214 16.5746 15.4214 16.1643Z"
      fill="white"
    />
    <path
      d="M17 13.6572C17.6154 13.6572 18.1142 13.1583 18.1142 12.5429C18.1142 11.9275 17.6154 11.4286 17 11.4286C16.3846 11.4286 15.8857 11.9275 15.8857 12.5429C15.8857 13.1583 16.3846 13.6572 17 13.6572Z"
      fill="white"
    />
  </svg>
)
export default MessageInfo
