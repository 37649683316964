const CheckboxDisabledInactive = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="CheckboxDisabledInactive"
    >
      <rect x="2" y="2" width="28" height="28" rx="2.5" fill="white" />
      <rect x="2" y="2" width="28" height="28" rx="2.5" stroke="#E8E8E8" />
    </svg>
  )
}
export default CheckboxDisabledInactive
