const NotificationSuccess = () => (
  <svg 
    width="26" 
    height="26" 
    viewBox="0 0 26 26" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
  <path 
    d="M21.4571 8C17.1725 12.2844 14.7703 14.6865 10.4857 18.9709L5 13.4857" 
    stroke="#03875F" 
    strokeWidth="1.84615" 
    strokeLinecap="round" 
    strokeLinejoin="round"/>
  </svg>
);
export default NotificationSuccess;
