const FolderIconsVehicles = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <g clipPath="url(#clip0_16068_2821)">
      <g clipPath="url(#clip1_16068_2821)">
        <path
          d="M10.7881 24.0254L6.13559 30.2288M10.7881 24.0254H54.2119M10.7881 24.0254L12.289 18.8559M10.7881 24.0254H6.65254C6.08154 24.0254 5.61864 23.5625 5.61864 22.9915V19.8898C5.61864 19.3188 6.08154 18.8559 6.65254 18.8559H12.289M6.13559 30.2288L5.87712 36.1737M6.13559 30.2288H11.9513C13.5929 30.2288 14.9237 31.5596 14.9237 33.2013V33.2013C14.9237 34.8429 13.5929 36.1737 11.9513 36.1737H5.87712M5.87712 42.1186H4.0678C2.92578 42.1186 2 43.0444 2 44.1864V44.7034C2 45.8454 2.92578 46.7712 4.0678 46.7712H7.68644M5.87712 42.1186V36.1737M5.87712 42.1186H24.2288M59.3814 42.1186H60.9322C62.0742 42.1186 63 43.0444 63 44.1864V44.7034C63 45.8454 62.0742 46.7712 60.9322 46.7712H57.3136M59.3814 42.1186V36.1737M59.3814 42.1186H40.7712M59.3814 30.2288L54.2119 24.0254M59.3814 30.2288H53.0487C51.4071 30.2288 50.0763 31.5596 50.0763 33.2013V33.2013C50.0763 34.8429 51.4071 36.1737 53.0487 36.1737H59.3814M59.3814 30.2288V36.1737M54.2119 24.0254L52.8778 18.8559M54.2119 24.0254H58.3475C58.9185 24.0254 59.3814 23.5625 59.3814 22.9915V19.8898C59.3814 19.3188 58.9185 18.8559 58.3475 18.8559H52.8778M40.7712 42.1186V40.0509C40.7712 38.9088 39.8454 37.9831 38.7034 37.9831H26.2966C25.1546 37.9831 24.2288 38.9088 24.2288 40.0508V42.1186M40.7712 42.1186H24.2288M12.289 18.8559L14.3583 11.7282C14.9995 9.51946 17.0229 8 19.3228 8H46.0715C48.429 8 50.4879 9.59501 51.077 11.8777L52.8778 18.8559M46.9746 46.7712V51.4237C46.9746 51.9947 47.4375 52.4576 48.0085 52.4576H56.2797C56.8507 52.4576 57.3136 51.9947 57.3136 51.4237V46.7712M46.9746 46.7712H57.3136M46.9746 46.7712H18.0254M18.0254 46.7712V51.4237C18.0254 51.9947 17.5625 52.4576 16.9915 52.4576H8.72034C8.14933 52.4576 7.68644 51.9947 7.68644 51.4237V46.7712M18.0254 46.7712H7.68644"
          strokeWidth="2.5"
          fill="none"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_16068_2821">
        <rect width="64" height="64" fill="white" />
      </clipPath>
      <clipPath id="clip1_16068_2821">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default FolderIconsVehicles
