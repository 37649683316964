const NotificationsGuide = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
  >
    <path
      d="M17.5 31.5C25.232 31.5 31.5 25.232 31.5 17.5C31.5 9.76801 25.232 3.5 17.5 3.5C9.76801 3.5 3.5 9.76801 3.5 17.5C3.5 25.232 9.76801 31.5 17.5 31.5Z"
      fill="#898D8D"
    />
    <path
      d="M16 12.625C16 12.007 16.507 11.5 17.125 11.5H17.875C18.493 11.5 19 12.007 19 12.625V13.375C19 13.993 18.493 14.5 17.875 14.5H17.125C16.507 14.5 16 13.993 16 13.375V12.625Z"
      fill="white"
    />
    <path
      d="M20.5 23.5H14.5V22H16V17.5H14.5V16H19V22H20.5V23.5Z"
      fill="white"
    />
  </svg>
)
export default NotificationsGuide
