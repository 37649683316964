import classNames from "classnames"
import { FC } from "react"

import { useApplicationContext } from "../../../contexts/application/context"
import { useGetIcon } from "../../../styled-components/GetIconLibraryInTheme"
import { ThumbnailBanner } from "./ThumbnailBanner/ThumbnailBanner"

import "./Thumbnail.css"

export enum ThumbnailSize {
  SMALL = "small", //62 x 44px
  MEDIUM1 = "medium-1", //70 x 55px
  MEDIUM2 = "medium-2", //70 x 60px
  LARGE1 = "large-1", //99 x 88px
  LARGE2 = "large-2", //100 x 70px
  LARGE3 = "large-3", //100% x 50%
  LARGE4 = "large-4", //250px x 115px
}

export enum LabelType {
  RecentlyUploaded = "Recent Upload",
  Review = "Review",
  Pending = "Pending",
  Failed = "Failed",
}

export interface ThumbnailProps {
  imageString?: string | undefined | null
  fileExtension?: string | null | undefined
  size?: ThumbnailSize
  showLabel?: boolean
  label?: LabelType
  hasDefaultPreviewContent?: boolean
}

export const Thumbnail: FC<ThumbnailProps> = ({
  imageString,
  fileExtension,
  size = ThumbnailSize.LARGE1,
  showLabel = false,
  label = LabelType.Review,
  hasDefaultPreviewContent = true,
}) => {
  const {
    applicationState: { usingStaticData },
  } = useApplicationContext()
  const DefaultImage = useGetIcon("FileThumbnail")

  const hasThumbnail = imageString && imageString.trim().length > 0

  const getFileExtension = (fileExtension: string | null | undefined) => {
    if (fileExtension) {
      return fileExtension?.trim().toUpperCase()
    } else if (usingStaticData) {
      return "PDF"
    }
    return ""
  }

  const thumbnailContainerClass = classNames("thumbnail-container", size, {
    "thumbnail-fallback": !hasThumbnail,
  })

  const getPreviewContent = ({
    hasDefaultPreviewContent,
    fileExtension,
  }: {
    hasDefaultPreviewContent: boolean
    fileExtension?: string | null
  }) => {
    if (hasDefaultPreviewContent) {
      return (
        <>
          <span className="thumbnail-icon icon-box">{DefaultImage}</span>
          <div className="file-extension" data-testid="thumbnail-icon">
            {getFileExtension(fileExtension)}
          </div>
        </>
      )
    }
    return null
  }

  return (
    <div className="thumbnail-wrapper">
      <div
        className={thumbnailContainerClass}
        aria-label="Thumbnail image of the file"
        role="img"
      >
        {hasThumbnail ? (
          <img
            src={imageString}
            alt="Document preview"
            data-testid="thumbnail-image"
          />
        ) : (
          getPreviewContent({
            hasDefaultPreviewContent,
            fileExtension,
          })
        )}
      </div>

      {showLabel && (
        <div>
          <ThumbnailBanner label={label} />
        </div>
      )}
    </div>
  )
}
