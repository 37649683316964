const ButtonOptimisedDashboard = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.599 17.5833L16.599 17.5833H16.5887H3.39937C3.36679 17.5833 3.33107 17.5701 3.30132 17.5395C3.27087 17.5082 3.2501 17.4612 3.2501 17.408V8.42037H3.25017L3.25002 8.40977C3.24962 8.38146 3.25517 8.35396 3.26566 8.32935C3.27542 8.30645 3.28892 8.28727 3.30429 8.27228L9.89066 2.46266L9.89072 2.46272L9.89914 2.45507C9.92849 2.42837 9.96246 2.41667 9.99405 2.41667C10.0256 2.41667 10.0596 2.42837 10.089 2.45507L10.0889 2.45513L10.0974 2.46266L16.6903 8.27798C16.6905 8.27814 16.6906 8.2783 16.6908 8.27845C16.7076 8.29351 16.7225 8.3134 16.7333 8.33762C16.7441 8.36215 16.7501 8.38973 16.75 8.41824V8.42037V17.408V17.408C16.75 17.4342 16.7449 17.4596 16.7356 17.4826L17.4304 17.7649L16.7356 17.4826C16.7262 17.5056 16.7131 17.525 16.698 17.5404C16.683 17.5557 16.6663 17.5665 16.6495 17.5735C16.6328 17.5804 16.6157 17.5836 16.599 17.5833Z"
      fill="none"
      strokeWidth="1.5"
    />
  </svg>
)
export default ButtonOptimisedDashboard
