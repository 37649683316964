const IconChevronDown = () => (
  <svg
    tabIndex={-1}
    className="icon-chevron-down"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 10L16.5653 21.7589C16.4911 21.8354 16.403 21.896 16.306 21.9373C16.209 21.9787 16.105 22 16 22C15.895 22 15.791 21.9787 15.694 21.9373C15.597 21.896 15.5089 21.8354 15.4347 21.7589L4 10"
      fill="none"
    />
  </svg>
)

export default IconChevronDown
