const FileThumbnail = () => (
<svg
  width="42"
  height="42"
  viewBox="0 0 42 42"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  data-testid="DefaultThumbnail"
>
  <path
    d="M7.87372 22.3125V7.875C7.87372 7.5269 8.012 7.19306 8.25814 6.94692C8.50428 6.70078 8.83812 6.5625 9.18622 6.5625H24.9375L34.125 15.75V22.3125"
    stroke="#48475C"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
  />
  <path
    d="M24.9375 6.5625V15.75H34.1263"
    stroke="#48475C"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>
)

export default FileThumbnail
