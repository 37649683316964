const FileDoc = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99902 17V6C5.99902 5.73478 6.10438 5.48043 6.29192 5.29289C6.47945 5.10536 6.73381 5 6.99902 5H19L26 12V17"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.04502 27.81C4.97169 27.81 4.90569 27.7843 4.84702 27.733C4.79569 27.6743 4.77002 27.6083 4.77002 27.535V20.385C4.77002 20.3043 4.79569 20.2383 4.84702 20.187C4.90569 20.1357 4.97169 20.11 5.04502 20.11H7.90502C8.98302 20.11 9.81902 20.3667 10.413 20.88C11.007 21.3933 11.3187 22.1377 11.348 23.113C11.3554 23.3257 11.359 23.608 11.359 23.96C11.359 24.312 11.3554 24.5907 11.348 24.796C11.3114 25.8153 11.007 26.5743 10.435 27.073C9.87035 27.5643 9.04535 27.81 7.96002 27.81H5.04502ZM7.90502 26.226C8.38902 26.226 8.74469 26.1123 8.97202 25.885C9.19935 25.6503 9.32035 25.28 9.33502 24.774C9.34969 24.5613 9.35702 24.2863 9.35702 23.949C9.35702 23.6117 9.34969 23.3403 9.33502 23.135C9.32035 22.6437 9.18835 22.2807 8.93902 22.046C8.69702 21.8113 8.33402 21.694 7.85002 21.694H6.75002V26.226H7.90502Z"
      stroke="none"
    />
    <path
      d="M15.79 27.92C14.756 27.92 13.942 27.667 13.348 27.161C12.754 26.655 12.4387 25.9107 12.402 24.928C12.3947 24.7153 12.391 24.4 12.391 23.982C12.391 23.5567 12.3947 23.2377 12.402 23.025C12.4387 22.057 12.7577 21.3127 13.359 20.792C13.9677 20.264 14.778 20 15.79 20C16.802 20 17.6124 20.264 18.221 20.792C18.8297 21.3127 19.1487 22.057 19.178 23.025C19.1927 23.4503 19.2 23.7693 19.2 23.982C19.2 24.1873 19.1927 24.5027 19.178 24.928C19.1414 25.9107 18.826 26.655 18.232 27.161C17.638 27.667 16.824 27.92 15.79 27.92ZM15.79 26.336C16.1934 26.336 16.5124 26.215 16.747 25.973C16.989 25.731 17.1174 25.3607 17.132 24.862C17.1467 24.4367 17.154 24.136 17.154 23.96C17.154 23.7693 17.1467 23.4687 17.132 23.058C17.1174 22.5593 16.989 22.189 16.747 21.947C16.505 21.705 16.186 21.584 15.79 21.584C15.394 21.584 15.075 21.705 14.833 21.947C14.5984 22.189 14.47 22.5593 14.448 23.058C14.4407 23.2633 14.437 23.564 14.437 23.96C14.437 24.3487 14.4407 24.6493 14.448 24.862C14.47 25.3607 14.5984 25.731 14.833 25.973C15.0677 26.215 15.3867 26.336 15.79 26.336Z"
      stroke="none"
    />
    <path
      d="M23.6321 27.92C22.5834 27.92 21.7621 27.667 21.1681 27.161C20.5814 26.6477 20.2698 25.907 20.2331 24.939C20.2258 24.741 20.2221 24.4183 20.2221 23.971C20.2221 23.5163 20.2258 23.1863 20.2331 22.981C20.2698 22.0277 20.5888 21.2943 21.1901 20.781C21.7914 20.2603 22.6054 20 23.6321 20C24.2774 20 24.8568 20.11 25.3701 20.33C25.8834 20.5427 26.2868 20.8507 26.5801 21.254C26.8808 21.65 27.0348 22.1157 27.0421 22.651V22.673C27.0421 22.7317 27.0164 22.783 26.9651 22.827C26.9211 22.8637 26.8698 22.882 26.8111 22.882H25.3261C25.2308 22.882 25.1574 22.8637 25.1061 22.827C25.0548 22.783 25.0108 22.706 24.9741 22.596C24.8714 22.222 24.7101 21.9617 24.4901 21.815C24.2701 21.661 23.9804 21.584 23.6211 21.584C22.7558 21.584 22.3084 22.068 22.2791 23.036C22.2718 23.234 22.2681 23.5383 22.2681 23.949C22.2681 24.3597 22.2718 24.6713 22.2791 24.884C22.3084 25.852 22.7558 26.336 23.6211 26.336C23.9804 26.336 24.2738 26.259 24.5011 26.105C24.7284 25.9437 24.8861 25.6833 24.9741 25.324C25.0034 25.214 25.0438 25.1407 25.0951 25.104C25.1464 25.06 25.2234 25.038 25.3261 25.038H26.8111C26.8771 25.038 26.9321 25.06 26.9761 25.104C27.0274 25.148 27.0494 25.203 27.0421 25.269C27.0348 25.8043 26.8808 26.2737 26.5801 26.677C26.2868 27.073 25.8834 27.381 25.3701 27.601C24.8568 27.8137 24.2774 27.92 23.6321 27.92Z"
      stroke="none"
    />
  </svg>
)
export default FileDoc
