import { IFile, IProviderStatus, IUser } from "../../api/api-client/api-types"
import {
  getThumbnailForFile,
  getPreviewDocumentPath,
  getAllFolders,
  getFileById,
  getFolderById,
  getFolderContents,
  getFoldersSharedByClient,
  getFoldersSharedByUser,
  getFlatFileListSharedByUser,
  getFlatFileById,
} from "../../api/api-client/api-handler"
import {
  addFileInFlatFileList,
  addFilesToFolder,
  addFolder,
  changeFileInFolder,
  deleteFileInFlatFileList,
  deleteFileInFolder,
  deleteFolder,
  setFlatFileList,
  setSharedWithMeFolders,
  updateFolder,
  updateProviderStatus,
} from "../../contexts/application/action"
import { setDisplaySuccessOrErrorMessage } from "../../contexts/toasts"
import { getFile } from "../../api/api-client/file/getFile"
import { notificationMessages } from "../../data/notificationMessages"
import { UserModuleType } from "../../contexts/application/constants"
import { setThumbnail } from "../../contexts/thumbnails"
import { IThumbnailAction } from "../../contexts/thumbnails/reducer"
import { Dispatch } from "react"
import { IToastAction } from "../../contexts/toasts/reducer"

//
// DataFeatures Update (Thumbnail, preview, review)
//

export const UpdateThumbnail = async (
  data: any,
  dispatch: (value: any) => void,
  thumbnailDispatch: (value: IThumbnailAction) => void
) => {
  const fileId = data["fileId"] as string
  let { file, folderId } = await GetFileAndFolderInfo(data)

  if (fileId && fileId.length > 0) {
    const thumbnail = await getThumbnailForFile({
      fileId: fileId,
      isFullSizeThumbnail: false,
    })
    if (thumbnail && thumbnail.length > 0) {
      thumbnailDispatch(setThumbnail({ id: fileId, thumbnail: thumbnail }))

      if (file) {
        file.base64StringThumbnail = thumbnail
        file.hasThumbnail = true
        file.isThumbnailLoading = false
        dispatch(
          changeFileInFolder({ file: file as IFile, folderId: folderId })
        )
      }
    }
  }
}

export const UpdatePreview = async (
  data: any,
  dispatch: (value: any) => void
) => {
  let { file, folderId } = await GetFileAndFolderInfo(data)

  if (file) {
    const preview = await getPreviewDocumentPath(file, false)
    if (preview && preview.length > 0) {
      file.preview = preview
      file.hasPreview = true
    } else {
      file.preview = undefined
      file.hasPreview = false
    }
    dispatch(changeFileInFolder({ file: file as IFile, folderId: folderId }))
  } else {
    console.error("Could not find file to update preview", 404)
  }
}

export const UpdateReviewInfo = async (
  data: any,
  dispatch: (value: any) => void
) => {
  let { file, folderId } = await GetFileAndFolderInfo(data)

  if (file) {
    dispatch(
      changeFileInFolder({
        file: { ...file, hasBeenReviewed: true },
        folderId: folderId,
      })
    )
  } else {
    console.error("Could not find file to update review", 404)
  }
}

//
// API Update (Need fresh info from API)
//

export const HandleFileAdded = async (
  data: any,
  dispatch: (value: any) => void
) => {
  const fileId = data["fileId"] as string
  if (data["isFlatFile"] === true) {
    const flatFile = await getFlatFileById(fileId)
    dispatch(addFileInFlatFileList([flatFile]))
    return
  }

  const file = await getFileById(fileId)
  dispatch(addFilesToFolder([file]))
}

export const HandleFileUpdated = async (
  data: any,
  dispatch: (value: any) => void
) => {
  const fileId = data["fileId"] as string

  if (data["isFlatFile"] === true) {
    const flatFile = await getFlatFileById(fileId)
    dispatch(deleteFileInFlatFileList(fileId))
    dispatch(addFileInFlatFileList([flatFile]))
    return
  }

  const file = await getFileById(fileId)
  dispatch(
    changeFileInFolder({ file: file, folderId: file.parentId as string })
  )
}

export const HandleFileDeleted = async (
  data: any,
  dispatch: (value: any) => void
) => {
  const fileId = data["fileId"] as string

  if (data["isFlatFile"] === true) {
    dispatch(deleteFileInFlatFileList(fileId))
    return
  }

  const file = await getFileById(fileId)
  dispatch(
    deleteFileInFolder({ file: file, folderId: file.parentId as string })
  )
}

export const HandleFolderAdded = async (
  data: any,
  dispatch: (value: any) => void
) => {
  let folderId = data["folderId"] as string
  let folder = await getFolderById(folderId)
  dispatch(addFolder({ newFolder: folder }))
}

export const HandleFolderUpdated = async (
  data: any,
  dispatch: (value: any) => void
) => {
  let folderId = data["folderId"] as string
  let folder = await getFolderById(folderId)
  dispatch(updateFolder(folder))
}

export const HandleFolderDeleted = async (
  data: any,
  dispatch: (value: any) => void
) => {
  let folderId = data["folderId"] as string
  dispatch(deleteFolder({ folderId }))
}

const GetFileAndFolderInfo = async (data: any) => {
  let folderId = data["folderId"] as string

  let fileId = data["fileId"]
  const folders = await getAllFolders()
  const file = getFile(fileId, folders) as IFile

  return { file, folderId }
}

//The api call will change to specific provider status when the backend call is set up
export const HandleStatusChange = async (
  data: any,
  dispatch: (value: any) => void,
  toastDispatch: Dispatch<IToastAction>
) => {
  if (!data["folderId"] || !data["newFolderStatus"]) return
  const updatedStatus: IProviderStatus = {
    folderId: data["folderId"],
    connectionStatus: data["newFolderStatus"],
  }
  dispatch(updateProviderStatus(updatedStatus))
  //When status is connected, we need to update the folder contents
  if (data["newFolderStatus"] === "Connected") {
    await HandleConnectedFolderUpdated(data, dispatch, toastDispatch)
  }
}

//This call is used for both provider docs being added and WB data being updated (gas usage, etc.)
export const HandleConnectedFolderUpdated = async (
  data: any,
  dispatch: (value: any) => void,
  toastDispatch: Dispatch<IToastAction>
) => {
  let folderId = data["folderId"] as string
  let folder = await getFolderById(folderId)
  let contents = await getFolderContents(folderId)
  folder.contents = contents
  dispatch(updateFolder(folder))
  toastDispatch(
    setDisplaySuccessOrErrorMessage({
      message:
        folder.name + notificationMessages.connectedFolderUpdated.SUCCESS,
      messageType: "SUCCESS",
    })
  )
}

export const HandleSharedVaultUpdated = async (
  dispatch: (value: any) => void,
  relationship: "client" | "contact",
  otherUserId: string,
  currentUser?: IUser
) => {
  //for flat file list shared with me
  if (
    currentUser?.modules?.includes(
      UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW
    )
  ) {
    const files = await getFlatFileListSharedByUser(otherUserId)
    if (files && files.length > 0) {
      dispatch(setFlatFileList(files))
    }
  } else {
    //for tree structure shared with me
    const folders =
      relationship === "contact"
        ? await getFoldersSharedByUser(otherUserId)
        : await getFoldersSharedByClient(otherUserId)
    if (
      currentUser?.modules?.includes(UserModuleType.SHARED_WITH_ME_LIST) ||
      currentUser?.modules?.includes(UserModuleType.CLIENT_LIST)
    ) {
      dispatch(setSharedWithMeFolders(folders))
    }
  }
}
