import { IFile, IFolder, INodeShare } from "../../api/api-client/api-types"

export const getSharedFoldersWithPermissionsAndSharedDate = (
  folders: IFolder[],
  contactShares: INodeShare[]
) => {
  const sharedFolders = folders.reduce(
    (sharedFolders: IFolder[], folder: IFolder) => {
      const folderShare = contactShares.find(
        (share) =>
          share.nodeId === folder.id && (share.canRead || share.canEdit)
      )
      const isImported =
        folder.folderType === "ConnectedFolder" ||
        folder.folderType === "PlatformFolder"

      const sharedContent =
        folder.contents?.reduce((array: IFile[], file: IFile) => {
          const fileShare = contactShares.find(
            (share) =>
              share.nodeId === file.id && (share.canRead || share.canEdit)
          )
          if (fileShare)
            array.push({
              ...file,
              isEditable: !isImported && fileShare.canEdit,
              dateShared: fileShare.dateShared,
            })
          return array
        }, []) ?? []

      const sharedChildren = folder.childFolders
        ? getSharedFoldersWithPermissionsAndSharedDate(
            folder.childFolders,
            contactShares
          )
        : []

      if (folder.level === 0 || folderShare || sharedContent.length > 0) {
        sharedFolders.push({
          ...folder,
          isEditable: !isImported && folderShare?.canEdit,
          dateShared: folderShare?.dateShared,
          contents: sharedContent,
          childFolders: sharedChildren,
        })
      } else {
        sharedFolders.push(...sharedChildren)
      }
      return sharedFolders
    },
    []
  )

  return sharedFolders
}
