const Search = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="header-search-bar-icon-svg"
  >
    <path
      d="M14.4998 25C20.2987 25 24.9998 20.299 24.9998 14.5C24.9998 8.70101 20.2987 4 14.4998 4C8.70077 4 3.99976 8.70101 3.99976 14.5C3.99976 20.299 8.70077 25 14.4998 25Z"
      fill="none"
    />
    <path d="M21.9241 21.925L35.9991 35.0001" fill="none" />
  </svg>
)

export default Search

