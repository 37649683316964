import { FC } from "react"
import { useGetIcon } from "../../styled-components/GetIconLibraryInTheme"
import { useNavigate, useParams } from "react-router-dom"
import { PAGE_PATH } from "../../contexts/application/constants"
import "./NotificationButton.css"
import { replaceParamsInPath } from "../../utils/navigation/replaceParamsInPath"

export type NotificationButtonProps = {
  newNotifications: number
  workflowId?: string
  workflowVersion?: string
  caseId?: string
}

export const NotificationButton: FC<NotificationButtonProps> = ({
  newNotifications,
  workflowId,
  workflowVersion,
  caseId,
}) => {
  const BellIcon = useGetIcon("NotificationsIcon")
  const navigate = useNavigate()

  return (
    <button
      onClick={(e) => {
        if (workflowId && workflowVersion && caseId) {
          //navigate to the notifications page for legal cases
          navigate(
            replaceParamsInPath({
              path: PAGE_PATH.CaseNotificationsPage,
              pathParameters: { workflowId, workflowVersion, caseId },
            })
          )
        } else {
          navigate(PAGE_PATH.NotificationsPage)
        }
      }}
      role="link"
      className={
        "notification-icon " + (newNotifications > 0 && "new-notification")
      }
      data-testid="header-notification-icon-button"
      aria-label="Navigate to Notifications"
    >
      <span className="notification-bell">{BellIcon}</span>
      <span
        className="notifications-icon-text"
        data-testid="header-search-bar-notifications-text"
      >
        Notifications
      </span>
      {newNotifications > 0 && (
        <div
          className="notifications-new"
          data-testid="header-search-bar-notifications-new"
        >
          {newNotifications.toString()}
        </div>
      )}
    </button>
  )
}
