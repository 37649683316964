import dayjs from "dayjs"
import { DistributionProjectAnalyticsInfoDto } from "legado-generated-api-client"
import { BulkDistributionVisibility } from "../contexts/application/constants"
import { LocalStorageKeys } from "../contexts/auth/localStorageKeys"

export const triggerLinkDownload = (
  fileData: Blob,
  mediaType: string,
  fileName: string
) => {
  try {
    if (!window) {
      return
    }
    let url = ""
    if (
      window?.URL?.createObjectURL &&
      typeof window.URL.createObjectURL !== undefined
    ) {
      url = window.URL.createObjectURL(fileData)
    }
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("type", mediaType)
    link.setAttribute("download", fileName)
    document.body.appendChild(link)
    link.click()
    if (
      window?.URL?.revokeObjectURL &&
      window.URL.revokeObjectURL !== undefined
    ) {
      window.URL.revokeObjectURL(url)
    }
  } catch (e) {
    console.error((e as Error).message)
  }
}

function sleep(ms: number): Promise<undefined> {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export { sleep }

export function displayDateAsSnippet(date: string) {
  dayjs(date).format("DD/MM/YY")
}

export const getVisibleDate = (
  project: DistributionProjectAnalyticsInfoDto,
  dateType: "client" | "adviser"
) => {
  if (dateType === "client") {
    return displayNullableDateAsSnippet(project.sendDateClient)
  } else {
    if (
      project.fileVisibility === BulkDistributionVisibility.ClientOnly ||
      project.fileVisibility === BulkDistributionVisibility.AdminOnly
    ) {
      return ""
    }

    return displayNullableDateAsSnippet(project.sendDateAdviser)
  }
}

export function displayNullableDateAsSnippet(date: string | null | undefined) {
  if (!date) {
    return ""
  }
  const newYear = new Date("01/01/2000")
  const targetTime = new Date(date).getTime()

  if (targetTime < newYear.getTime()) {
    return null
  }

  const newDate = dayjs(date).format("DD/MM/YY HH:mm")

  return newDate
}

export function displayFriendlyDateTime(date: Date) {
  const friendlyDate = `
${leadingZero(date.getDate())}/${leadingZero(
    date.getMonth() + 1
  )}/${leadingZero(date.getFullYear().toString().slice(2, 4))}`
  const time = new Date(date)
    .toLocaleString("en-US", { hour: "numeric", hour12: true })
    .replace(" ", "")
  return `${friendlyDate} - at ${time.toLowerCase()}`
}

export function convertDDMMYYDate(dateString: string) {
  var dateParts = dateString.split("/")
  // month is 0-based, that's why we need dataParts[1] - 1
  // + converts string to num
  var dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])
  return dateObject
}

export const displayDate = (
  currentDate: Date,
  dateInNumbers: boolean = false
) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const dateOfMonth = currentDate.getDate()
  let suffix = ""
  switch (dateOfMonth) {
    case 1:
    case 21:
    case 31:
      suffix = "st"
      break
    case 2:
    case 22:
      suffix = "nd"
      break
    case 3:
    case 23:
      suffix = "rd"
      break
    default:
      suffix = "th"
      break
  }

  return `${dateOfMonth + suffix}
  ${dateInNumbers ? currentDate.getMonth() : months[currentDate.getMonth()]}
  ${currentDate.getFullYear()}`
}

export const convertToYYYYMMDDHHMM = (date?: string | undefined | null) => {
  if (!date) return
  const asDate = new Date(date)
  return convertToYYYYMMDD(asDate) + " " + convertToHHMM(asDate)
}

export const convertToYYYYMMDD = (date: Date) => {
  //Outputs YYYY-MM-DD
  const year = date.getFullYear().toString()
  const month = (date.getMonth() + 1).toString()
  const day = date.getDate().toString()

  return year + "-" + leadingZero(month) + "-" + leadingZero(day)
}

export const convertToHHMM = (date: Date) => {
  //Outputs HH:MM
  const hrs = date.getHours().toString()
  const mins = date.getMinutes().toString()
  return leadingZero(hrs) + ":" + leadingZero(mins)
}

const leadingZero = (s: string | number) => {
  s = s.toString()
  if (s.length === 1) {
    return "0" + s
  }
  return s
}

export const castToBool = (value?: string) => {
  if (!value) {
    return false
  }

  switch (value.toLowerCase()) {
    case "true": {
      return true
    }
    default: {
      return false
    }
  }
}

/**
 * to be used with .sort method when sorting strings
 * @param name1
 * @param name2
 * @returns 0 | 1 | -1
 */
export function getStringSortPriority(name1: string, name2: string) {
  if (name1 > name2) {
    return 1
  }

  if (name1 < name2) {
    return -1
  }

  return 0
}

export const isValidEmail = (email: string) => {
  const regexp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  return regexp.test(email)
}

export const pageString = () => {
  let pString = ""
  if (window.location.pathname === "/") {
    pString = "home"
  } else if (window.location.pathname === "") {
    pString = "home"
  } else {
    pString = window.location.pathname.slice(1)
  }
  return pString
}

export const getProjectsFromUserSearch = ({
  projects,
  searchExpression,
}: {
  projects: DistributionProjectAnalyticsInfoDto[]
  searchExpression: string
}): DistributionProjectAnalyticsInfoDto[] => {
  const searchExpressionLowerCase = searchExpression.toLowerCase().trim()
  if (searchExpressionLowerCase === "") {
    return projects
  }

  return projects.filter((project) => {
    const name: string = project.name?.toLowerCase()!
    return name.includes(searchExpressionLowerCase)
  })
}

export const updateProjectDatesForStaticData = ({
  projects,
}: {
  projects: DistributionProjectAnalyticsInfoDto[]
}) => {
  const currentDate = new Date()
  const twoWeeks = 14 * 24 * 60 * 60 * 1000
  projects.forEach((project) => {
    if (project.status === "Completed") {
      const newSendDate = new Date(currentDate.getTime() - twoWeeks)
      project.sendDateAdviser = dayjs(newSendDate).format("YYYY-MM-DD HH:mm:ss")
      project.sendDateClient = dayjs(newSendDate).format("YYYY-MM-DD HH:mm:ss")
    }
    if (project.status === "Scheduled") {
      const newScheduledDate = new Date(currentDate.getTime() + twoWeeks)
      project.sendDateAdviser = dayjs(newScheduledDate).format(
        "YYYY-MM-DD HH:mm:ss"
      )
      project.sendDateClient = dayjs(newScheduledDate).format(
        "YYYY-MM-DD HH:mm:ss"
      )
    }
  })

  return projects
}

type PageNames = {
  [key: string]: string
}

const pages: PageNames = {
  BULKUPLOAD_UPLOAD_FILE: "Upload journey page 1/3",
  BULKUPLOAD_CHOOSE_CLIENTLIST: "Upload journey page 2/3",
  BULKUPLOAD_REVIEW: "Upload journey page 3/3",
  PROJECTSUMMARY: "Project Summary - Summary",
  PROJECTLIST: "Project List/Home",
  "/document-hub": "Project List/Home",
  "List view": "Project List/Home",
  "/analytics": "General Analytics",
  Summary: "Project Summary - Summary",
  Clients: "Project Summary - Clients",
  "Version history": "Project Summary - Version history",
  Analytics: "Project Summary - Analytics",
}

export const getGAPageName = (pageName: string): string => {
  return pages[pageName] || pageName
}

export const getIsCurrentStageAllowingUploads = () => {
  const isCurrentStageAllowingUploads = localStorage.getItem(
    LocalStorageKeys.IsCurrentStageAllowingUploads
  )
  return (
    isCurrentStageAllowingUploads === null ||
    isCurrentStageAllowingUploads === undefined ||
    isCurrentStageAllowingUploads === "true"
  )
}

export const isPlatformWithHeadAccounts = (
  selectedTheme: string | undefined
) => {
  return selectedTheme?.startsWith("ABRDN") ?? false
}
