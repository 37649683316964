const CheckboxActive = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="CheckboxActive"
    >
      <g filter="url(#filter0_d_32_701)">
        <rect x="1.5" y="1.5" width="29" height="29" rx="3" fill="none" />
        <g clipPath="url(#clip0_32_701)">
          <path
            d="M10 17.5L14.0625 21.5625L23 11"
            strokeWidth="1.95"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </g>
        <rect x="2" y="2" width="28" height="28" rx="2.5" fill="none" />
      </g>
      <defs>
        <filter
          id="filter0_d_32_701"
          x="1.5"
          y="1.5"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_32_701"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_32_701"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_32_701">
          <rect
            width="16"
            height="13"
            fill="white"
            transform="translate(8 9.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default CheckboxActive
