import { TDesignSystemTheme } from "../../types"
import colorTokens from "./definitions/colors.json"
import shadowTokens from "./definitions/shadows.json"
import boxStylingTokens from "./definitions/boxStyles.json"
import typographyTokens from "./definitions/typography.json"

export const definitionTokens: TDesignSystemTheme = {
  mode: "AMADEUS",
  colors: {
    "#909090": colorTokens["#909090"].value,
    "#FFFFFF": colorTokens["#FFFFFF"].value,
    "#FEFEFE": colorTokens["#FEFEFE"].value,
    "#C03D13": colorTokens["#C03D13"].value,
    "#EC805E": colorTokens["#EC805E"].value,
    "#FF7E54": colorTokens["#FF7E54"].value,
    "#B99437": colorTokens["#B99437"].value,
    "#FE602D": colorTokens["#FE602D"].value,
    "#195576": colorTokens["#195576"].value,
    "#281448": colorTokens["#281448"].value,
    "#E2E2E2": colorTokens["#E2E2E2"].value,
    "#F1F1F1": colorTokens["#F1F1F1"].value,
    "#E56666": colorTokens["#E56666"].value,
    "#B44848": colorTokens["#B44848"].value,
    "#D82927": colorTokens["#D82927"].value,
    "#B4201E": colorTokens["#B4201E"].value,
    "#E8E8E8": colorTokens["#E8E8E8"].value,
    "#91939A": colorTokens["#91939A"].value,
    "#C21D16": colorTokens["#C21D16"].value,
    "#97120D": colorTokens["#97120D"].value,
    "#00843D": colorTokens["#00843D"].value,
    "#D8D8D8": colorTokens["#D8D8D8"].value,
    "#F9F9F9": colorTokens["#F9F9F9"].value,
    "#FFDED3": colorTokens["#FFDED3"].value,
    "#FCFCFC": colorTokens["#FCFCFC"].value,
    "#FFE1DF": colorTokens["#FFE1DF"].value,
    "#3C3C3C": colorTokens["#3C3C3C"].value,
    "#D1D1D1": colorTokens["#D1D1D1"].value,
    "#C62828": colorTokens["#C62828"].value,
    "#FFD1CE": colorTokens["#FFD1CE"].value,
    "#412E5F": colorTokens["#412E5F"].value,
    "#11306C": colorTokens["#11306C"].value,
    "#D2C3F0": colorTokens["#D2C3F0"].value,
    "#03A644": colorTokens["#03A644"].value,
    "#058D3B": colorTokens["#058D3B"].value,
    "#CDEDDA": colorTokens["#CDEDDA"].value,
    "#EEA63A": colorTokens["#EEA63A"].value,
    "#DE9527": colorTokens["#DE9527"].value,
    "#FBE9CE": colorTokens["#FBE9CE"].value,
    "#FFF0EB": colorTokens["#FFF0EB"].value,
    "#FFC2AE": colorTokens["#FFC2AE"].value,
    "#FFD1C2": colorTokens["#FFD1C2"].value,
    "#F8E2DB": colorTokens["#F8E2DB"].value,
    "#FFEBED": colorTokens["#FFEBED"].value,
    "#EAF1FF": colorTokens["#EAF1FF"].value,
    "#E7FDEC": colorTokens["#E7FDEC"].value,
    "#FFF8E6": colorTokens["#FFF8E6"].value,
    "#999AA6": colorTokens["#999AA6"].value,
    "#6B6C78": colorTokens["#6B6C78"].value,
    "#FAFAFA": colorTokens["#FAFAFA"].value,
    "#FFD75D": colorTokens["#FFD75D"].value,
    "#323240": colorTokens["#323240"].value,
    "#E5EDFF": colorTokens["#E5EDFF"].value,
    "#566995": colorTokens["#566995"].value,
    "#4483AA": colorTokens["#4483AA"].value,
    "#295D7F": colorTokens["#295D7F"].value,
    "#DF1F11": colorTokens["#DF1F11"].value,
    "#A91719": colorTokens["#A91719"].value,
    "#F2FAF5": colorTokens["#F2FAF5"].value,
    "#A7CEB7": colorTokens["#A7CEB7"].value,
    "#095628": colorTokens["#095628"].value,
    "#CEF5DD": colorTokens["#CEF5DD"].value,
    "#840B0D": colorTokens["#840B0D"].value,
    "#FF9F81": colorTokens["#FF9F81"].value,
    "#F0F0F0": colorTokens["#F0F0F0"].value,
    "#FFF3F0": colorTokens["#FFF3F0"].value,
    "#181818": colorTokens["#181818"].value,
    "#323233": colorTokens["#323233"].value,
    "#F5F5F5": colorTokens["#F5F5F5"].value,
    "#FFEBE4": colorTokens["#FFEBE4"].value,
    "#BFBFBF": colorTokens["#BFBFBF"].value,
    "#FFD1CF": colorTokens["#FFD1CF"].value,
    "#E04C1C": colorTokens["#E04C1C"].value,
    "#213232": colorTokens["#213232"].value,
    "#333333": colorTokens["#333333"].value,
    "#BCBCBC": colorTokens["#BCBCBC"].value,
    "#FFD6C9": colorTokens["#FFD6C9"].value,
    "#FFA98E": colorTokens["#FFA98E"].value,
    "#E28A86": colorTokens["#E28A86"].value,
    "#F46233": colorTokens["#F46233"].value,
    "#FFEFEA": colorTokens["#FFEFEA"].value,
    "#CFD4D9": colorTokens["#CFD4D9"].value,
    "#686868": colorTokens["#686868"].value,
    "#F2AC3C": colorTokens["#F2AC3C"].value,
    "#E57982": colorTokens["#E57982"].value,
    "#FAE4E6": colorTokens["#FAE4E6"].value,
    "#749BFF": colorTokens["#749BFF"].value,
    "#DCE6FF": colorTokens["#DCE6FF"].value,
    "#A882B3": colorTokens["#A882B3"].value,
    "#E7DBEB": colorTokens["#E7DBEB"].value,
    "#F2F2F2": colorTokens["#F2F2F2"].value,
    "#666666": colorTokens["#666666"].value,
    "#F1F0F1": colorTokens["#F1F0F1"].value,
    "#FFE3E2": colorTokens["#FFE3E2"].value,
    "#F9D1D0": colorTokens["#F9D1D0"].value,
    "#A1A1A1": colorTokens["#A1A1A1"].value,
    "#8CAABA": colorTokens["#8CAABA"].value,
    "#C5D4DD": colorTokens["#C5D4DD"].value,
    "#E6F0F5": colorTokens["#E6F0F5"].value,
    "#FDE8E9": colorTokens["#FDE8E9"].value,
  },
  shadows: {
    shadowSoft: shadowTokens.shadows.soft.value,
    borders: shadowTokens.borders[0].value,
  },
  radius: {
    radius5px: boxStylingTokens.radius.small[0].value,
    radius8px: boxStylingTokens.radius.small[1].value,
    radius10px: boxStylingTokens.radius.medium[0].value,
    radius100px: boxStylingTokens.radius.large[0].value,
  },
  borders: {
    border1pxSolid: boxStylingTokens.borders[0].value,
  },

  typeface: {
    fontFamily: typographyTokens.font.fontFamily.value,

    fontSize: typographyTokens.font.fontSize.value,
  },
}
