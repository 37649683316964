import { useEffect } from "react"
import { useAuthContext } from "../../contexts/auth/authContextProvider"
import { LoadingFallBack } from "../../component/organism/LoadingFallBack"

export const LogoutPage = () => {
  const { logout } = useAuthContext()

  useEffect(() => {
    logout()
  }, [logout])

  return <LoadingFallBack />
}
