import { DistributionProjectAnalyticsInfoDto } from "legado-generated-api-client/models";
import { getUserId } from "./getUser"
const projectList = require("../staticData/getProjectList.json");

type TGetProjectList = (userEmail?: string) => DistributionProjectAnalyticsInfoDto[];


export const getProjectListFromLocalData: TGetProjectList = (userEmail) => {
  const currentUserId = userEmail ? getUserId(userEmail)  : "8cc9407f-50aa-4f2b-ba84-f5807f9c57b3"
  return projectList[currentUserId]
};
