import { NodeDto } from "legado-generated-api-client/models";
import { getUserId } from "./getUser";
const folders = require("../staticData/getFolders.json");

type TGetFolders = (userEmail?: string) => NodeDto[];

const isNodeDtos = (folders: any) => {
  if ((folders as NodeDto[])?.length) {
    return folders as NodeDto[];
  }
};

type TObj = { [key: string]: any };

export const getFoldersFromLocalData: TGetFolders = (userEmail) => {
  const currentUserId = getUserId(userEmail);
  const foldersObj = folders as TObj;
  const currentUserFolders = foldersObj[currentUserId];
  return isNodeDtos(currentUserFolders) || [];
};
