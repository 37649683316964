const FileImport = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 28H6.99902C6.73381 28 6.47945 27.8946 6.29192 27.7071C6.10438 27.5196 5.99902 27.2652 5.99902 27V5C5.99902 4.73478 6.10438 4.48043 6.29192 4.29289C6.47945 4.10536 6.73381 4 6.99902 4H19L26 11V27C26 27.1313 25.9741 27.2614 25.9239 27.3827C25.8736 27.504 25.8 27.6143 25.7071 27.7071C25.6143 27.8 25.504 27.8736 25.3827 27.9239C25.2614 27.9741 25.1313 28 25 28Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M19 4V11H26.001"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      className="fill"
      stroke="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2929 23.7071C15.6834 24.0976 16.3166 24.0976 16.7071 23.7071L20.2071 20.2071C20.5976 19.8166 20.5976 19.1834 20.2071 18.7929C19.8166 18.4024 19.1834 18.4024 18.7929 18.7929L17 20.5858V15C17 14.4477 16.5523 14 16 14C15.4477 14 15 14.4477 15 15V20.5858L13.2071 18.7929C12.8166 18.4024 12.1834 18.4024 11.7929 18.7929C11.4024 19.1834 11.4024 19.8166 11.7929 20.2071L15.2929 23.7071Z"
    />
  </svg>
)
export default FileImport
