import { getSuggestedFoldersFromLocalData } from "react-app-config"
import { IFolder } from "../../api/api-client/api-types"
import { getSuggestionsForFolders } from "../../api/api-client/folder/getNamesForSuggestions"
import { flattenFolderTree } from "../../api/api-client/folder/flattenFolderTree"
import "./CreateSubFolder.css"

/*
Imported or Current Platform = "ConnectedFolder"
   Dominic: A Current Platform folder is just an imported folder that was made by the platform the user is on. I believe the only difference is they get placed at the top of lists
Custom = "ClientCustomFolder"
Suggested = Whatever name of the suggested section/folder in camel case

Imported: only thing what can be done with those folders is sharing them
Custom: anything is possible
   Pete: Non-imported folders can be renamed, added, deleted and have anything loaded to or deleted from them
Suggested: can't be renamed, otherwise like Custom

just a remark: any folder shared allows file download and preview even if imported
 */

export const clientCustomFolder = "ClientCustomFolder"

export const getCurrentSuggestedFolders = ({
  parentFolder,
  folders,
  sharedWithMeFolders,
  isShared,
}: {
  parentFolder?: IFolder
  folders?: IFolder[]
  sharedWithMeFolders?: IFolder[]
  isShared?: boolean
}) => {
  const suggestedFolders = getSuggestedFoldersFromLocalData() as IFolder[]
  if (
    !parentFolder ||
    (isShared && (!sharedWithMeFolders || sharedWithMeFolders.length === 0)) ||
    !suggestedFolders
  )
    return []

  const tree = isShared ? sharedWithMeFolders! : folders!
  var usedFolders = flattenFolderTree(tree)

  return getSuggestionsForFolders(
    suggestedFolders,
    usedFolders.map((uF) => uF.name),
    parentFolder.name
  )
}

export function setFolderType(
  folderName: string,
  originalSuggestedNames: string[] | undefined
): string {
  const folderType =
    originalSuggestedNames && originalSuggestedNames.includes(folderName)
      ? folderName
      : clientCustomFolder
  if (!folderType) {
    return clientCustomFolder
  } else {
    return folderType
  }
}
