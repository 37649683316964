import { FunctionComponent } from "react"

type IAberdnLogoSvg = {
  onClick?: () => void
}

export const AberdnLogoSvg: FunctionComponent<IAberdnLogoSvg> = ({
  onClick,
}) => (
  <svg
    onClick={onClick}
    width="155"
    height="34"
    viewBox="0 0 155 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3207 31.1578C24.867 31.1578 24.504 31.5205 24.504 31.9739C24.504 32.4272 24.867 32.7899 25.3207 32.7899C25.7745 32.7899 26.1376 32.4272 26.1376 31.9739C26.1376 31.5205 25.7745 31.1578 25.3207 31.1578Z"
      fill="black"
    />
    <path
      d="M23.6872 31.1578C23.2334 31.1578 22.8703 31.5205 22.8703 31.9739C22.8703 32.4272 23.2334 32.7899 23.6872 32.7899C24.1409 32.7899 24.504 32.4272 24.504 31.9739C24.504 31.5205 24.1409 31.1578 23.6872 31.1578Z"
      fill="black"
    />
    <path
      d="M28.588 31.1578C28.1342 31.1578 27.7711 31.5205 27.7711 31.9739C27.7711 32.4272 28.1342 32.7899 28.588 32.7899C29.0417 32.7899 29.4047 32.4272 29.4047 31.9739C29.4047 31.5205 29.0417 31.1578 28.588 31.1578Z"
      fill="black"
    />
    <path
      d="M26.9543 31.1578C26.5006 31.1578 26.1376 31.5205 26.1376 31.9739C26.1376 32.4272 26.5006 32.7899 26.9543 32.7899C27.4081 32.7899 27.7711 32.4272 27.7711 31.9739C27.7711 31.5205 27.4081 31.1578 26.9543 31.1578Z"
      fill="black"
    />
    <path
      d="M25.3207 29.5258C24.867 29.5258 24.504 29.8884 24.504 30.3418C24.504 30.7951 24.867 31.1578 25.3207 31.1578C25.7745 31.1578 26.1376 30.7951 26.1376 30.3418C26.1376 29.8884 25.7745 29.5258 25.3207 29.5258Z"
      fill="black"
    />
    <path
      d="M23.6872 29.5258C23.2334 29.5258 22.8703 29.8884 22.8703 30.3418C22.8703 30.7951 23.2334 31.1578 23.6872 31.1578C24.1409 31.1578 24.504 30.7951 24.504 30.3418C24.504 29.8884 24.1409 29.5258 23.6872 29.5258Z"
      fill="black"
    />
    <path
      d="M28.588 29.5258C28.1342 29.5258 27.7711 29.8884 27.7711 30.3418C27.7711 30.7951 28.1342 31.1578 28.588 31.1578C29.0417 31.1578 29.4047 30.7951 29.4047 30.3418C29.4047 29.8884 29.0417 29.5258 28.588 29.5258Z"
      fill="black"
    />
    <path
      d="M26.9543 29.5258C26.5006 29.5258 26.1376 29.8884 26.1376 30.3418C26.1376 30.7951 26.5006 31.1578 26.9543 31.1578C27.4081 31.1578 27.7711 30.7951 27.7711 30.3418C27.7711 29.8884 27.4081 29.5258 26.9543 29.5258Z"
      fill="black"
    />
    <path
      d="M25.3207 27.8937C24.867 27.8937 24.504 28.2564 24.504 28.7097C24.504 29.1631 24.867 29.5258 25.3207 29.5258C25.7745 29.5258 26.1376 29.1631 26.1376 28.7097C26.1376 28.2564 25.7745 27.8937 25.3207 27.8937Z"
      fill="black"
    />
    <path
      d="M23.6872 27.8937C23.2334 27.8937 22.8703 28.2564 22.8703 28.7097C22.8703 29.1631 23.2334 29.5258 23.6872 29.5258C24.1409 29.5258 24.504 29.1631 24.504 28.7097C24.504 28.2564 24.1409 27.8937 23.6872 27.8937Z"
      fill="black"
    />
    <path
      d="M28.588 27.8937C28.1342 27.8937 27.7711 28.2564 27.7711 28.7097C27.7711 29.1631 28.1342 29.5258 28.588 29.5258C29.0417 29.5258 29.4047 29.1631 29.4047 28.7097C29.4047 28.2564 29.0417 27.8937 28.588 27.8937Z"
      fill="black"
    />
    <path
      d="M26.9543 27.8937C26.5006 27.8937 26.1376 28.2564 26.1376 28.7097C26.1376 29.1631 26.5006 29.5258 26.9543 29.5258C27.4081 29.5258 27.7711 29.1631 27.7711 28.7097C27.7711 28.2564 27.4081 27.8937 26.9543 27.8937Z"
      fill="black"
    />
    <path
      d="M25.3207 26.2617C24.867 26.2617 24.504 26.6243 24.504 27.0777C24.504 27.5311 24.867 27.8937 25.3207 27.8937C25.7745 27.8937 26.1376 27.5311 26.1376 27.0777C26.1376 26.6243 25.7745 26.2617 25.3207 26.2617Z"
      fill="black"
    />
    <path
      d="M23.6872 26.2617C23.2334 26.2617 22.8703 26.6243 22.8703 27.0777C22.8703 27.5311 23.2334 27.8937 23.6872 27.8937C24.1409 27.8937 24.504 27.5311 24.504 27.0777C24.504 26.6243 24.1409 26.2617 23.6872 26.2617Z"
      fill="black"
    />
    <path
      d="M28.588 26.2617C28.1342 26.2617 27.7711 26.6243 27.7711 27.0777C27.7711 27.5311 28.1342 27.8937 28.588 27.8937C29.0417 27.8937 29.4047 27.5311 29.4047 27.0777C29.4047 26.6243 29.0417 26.2617 28.588 26.2617Z"
      fill="black"
    />
    <path
      d="M26.9543 26.2617C26.5006 26.2617 26.1376 26.6243 26.1376 27.0777C26.1376 27.5311 26.5006 27.8937 26.9543 27.8937C27.4081 27.8937 27.7711 27.5311 27.7711 27.0777C27.7711 26.6243 27.4081 26.2617 26.9543 26.2617Z"
      fill="black"
    />
    <path
      d="M25.3207 24.6296C24.867 24.6296 24.504 24.9923 24.504 25.4456C24.504 25.899 24.867 26.2617 25.3207 26.2617C25.7745 26.2617 26.1376 25.899 26.1376 25.4456C26.1376 24.9923 25.7745 24.6296 25.3207 24.6296Z"
      fill="black"
    />
    <path
      d="M23.6872 24.6296C23.2334 24.6296 22.8703 24.9923 22.8703 25.4456C22.8703 25.899 23.2334 26.2617 23.6872 26.2617C24.1409 26.2617 24.504 25.899 24.504 25.4456C24.504 24.9923 24.1409 24.6296 23.6872 24.6296Z"
      fill="black"
    />
    <path
      d="M28.588 24.6296C28.1342 24.6296 27.7711 24.9923 27.7711 25.4456C27.7711 25.899 28.1342 26.2617 28.588 26.2617C29.0417 26.2617 29.4047 25.899 29.4047 25.4456C29.4047 24.9923 29.0417 24.6296 28.588 24.6296Z"
      fill="black"
    />
    <path
      d="M26.9543 24.6296C26.5006 24.6296 26.1376 24.9923 26.1376 25.4456C26.1376 25.899 26.5006 26.2617 26.9543 26.2617C27.4081 26.2617 27.7711 25.899 27.7711 25.4456C27.7711 24.9923 27.4081 24.6296 26.9543 24.6296Z"
      fill="black"
    />
    <path
      d="M25.3207 22.9976C24.867 22.9976 24.504 23.3603 24.504 23.8136C24.504 24.267 24.867 24.6296 25.3207 24.6296C25.7745 24.6296 26.1376 24.267 26.1376 23.8136C26.1376 23.3603 25.7745 22.9976 25.3207 22.9976Z"
      fill="black"
    />
    <path
      d="M23.6872 22.9976C23.2334 22.9976 22.8703 23.3603 22.8703 23.8136C22.8703 24.267 23.2334 24.6296 23.6872 24.6296C24.1409 24.6296 24.504 24.267 24.504 23.8136C24.504 23.3603 24.1409 22.9976 23.6872 22.9976Z"
      fill="black"
    />
    <path
      d="M28.588 22.9976C28.1342 22.9976 27.7711 23.3603 27.7711 23.8136C27.7711 24.267 28.1342 24.6296 28.588 24.6296C29.0417 24.6296 29.4047 24.267 29.4047 23.8136C29.4047 23.3603 29.0417 22.9976 28.588 22.9976Z"
      fill="black"
    />
    <path
      d="M26.9543 22.9976C26.5006 22.9976 26.1376 23.3603 26.1376 23.8136C26.1376 24.267 26.5006 24.6296 26.9543 24.6296C27.4081 24.6296 27.7711 24.267 27.7711 23.8136C27.7711 23.3603 27.4081 22.9976 26.9543 22.9976Z"
      fill="black"
    />
    <path
      d="M25.3207 21.3655C24.867 21.3655 24.504 21.7282 24.504 22.1815C24.504 22.6349 24.867 22.9976 25.3207 22.9976C25.7745 22.9976 26.1376 22.6349 26.1376 22.1815C26.1376 21.7282 25.7745 21.3655 25.3207 21.3655Z"
      fill="black"
    />
    <path
      d="M23.6872 21.3655C23.2334 21.3655 22.8703 21.7282 22.8703 22.1815C22.8703 22.6349 23.2334 22.9976 23.6872 22.9976C24.1409 22.9976 24.504 22.6349 24.504 22.1815C24.504 21.7282 24.1409 21.3655 23.6872 21.3655Z"
      fill="black"
    />
    <path
      d="M28.588 21.3655C28.1342 21.3655 27.7711 21.7282 27.7711 22.1815C27.7711 22.6349 28.1342 22.9976 28.588 22.9976C29.0417 22.9976 29.4047 22.6349 29.4047 22.1815C29.4047 21.7282 29.0417 21.3655 28.588 21.3655Z"
      fill="black"
    />
    <path
      d="M26.9543 21.3655C26.5006 21.3655 26.1376 21.7282 26.1376 22.1815C26.1376 22.6349 26.5006 22.9976 26.9543 22.9976C27.4081 22.9976 27.7711 22.6349 27.7711 22.1815C27.7711 21.7282 27.4081 21.3655 26.9543 21.3655Z"
      fill="black"
    />
    <path
      d="M25.3207 19.7334C24.867 19.7334 24.504 20.0961 24.504 20.5495C24.504 21.0028 24.867 21.3655 25.3207 21.3655C25.7745 21.3655 26.1376 21.0028 26.1376 20.5495C26.1376 20.0961 25.7745 19.7334 25.3207 19.7334Z"
      fill="black"
    />
    <path
      d="M23.6872 19.7334C23.2334 19.7334 22.8703 20.0961 22.8703 20.5495C22.8703 21.0028 23.2334 21.3655 23.6872 21.3655C24.1409 21.3655 24.504 21.0028 24.504 20.5495C24.504 20.0961 24.1409 19.7334 23.6872 19.7334Z"
      fill="black"
    />
    <path
      d="M28.588 19.7334C28.1342 19.7334 27.7711 20.0961 27.7711 20.5495C27.7711 21.0028 28.1342 21.3655 28.588 21.3655C29.0417 21.3655 29.4047 21.0028 29.4047 20.5495C29.4047 20.0961 29.0417 19.7334 28.588 19.7334Z"
      fill="black"
    />
    <path
      d="M26.9543 19.7334C26.5006 19.7334 26.1376 20.0961 26.1376 20.5495C26.1376 21.0028 26.5006 21.3655 26.9543 21.3655C27.4081 21.3655 27.7711 21.0028 27.7711 20.5495C27.7711 20.0961 27.4081 19.7334 26.9543 19.7334Z"
      fill="black"
    />
    <path
      d="M25.3207 18.1014C24.867 18.1014 24.504 18.4641 24.504 18.9174C24.504 19.3708 24.867 19.7334 25.3207 19.7334C25.7745 19.7334 26.1376 19.3708 26.1376 18.9174C26.1376 18.4641 25.7745 18.1014 25.3207 18.1014Z"
      fill="black"
    />
    <path
      d="M23.6872 18.1014C23.2334 18.1014 22.8703 18.4641 22.8703 18.9174C22.8703 19.3708 23.2334 19.7334 23.6872 19.7334C24.1409 19.7334 24.504 19.3708 24.504 18.9174C24.504 18.4641 24.1409 18.1014 23.6872 18.1014Z"
      fill="black"
    />
    <path
      d="M28.588 18.1014C28.1342 18.1014 27.7711 18.4641 27.7711 18.9174C27.7711 19.3708 28.1342 19.7334 28.588 19.7334C29.0417 19.7334 29.4047 19.3708 29.4047 18.9174C29.4047 18.4641 29.0417 18.1014 28.588 18.1014Z"
      fill="black"
    />
    <path
      d="M26.9543 18.1014C26.5006 18.1014 26.1376 18.4641 26.1376 18.9174C26.1376 19.3708 26.5006 19.7334 26.9543 19.7334C27.4081 19.7334 27.7711 19.3708 27.7711 18.9174C27.7711 18.4641 27.4081 18.1014 26.9543 18.1014Z"
      fill="black"
    />
    <path
      d="M25.3207 16.4694C24.867 16.4694 24.504 16.832 24.504 17.2854C24.504 17.7387 24.867 18.1014 25.3207 18.1014C25.7745 18.1014 26.1376 17.7387 26.1376 17.2854C26.1376 16.832 25.7745 16.4694 25.3207 16.4694Z"
      fill="black"
    />
    <path
      d="M23.6872 16.4694C23.2334 16.4694 22.8703 16.832 22.8703 17.2854C22.8703 17.7387 23.2334 18.1014 23.6872 18.1014C24.1409 18.1014 24.504 17.7387 24.504 17.2854C24.504 16.832 24.1409 16.4694 23.6872 16.4694Z"
      fill="black"
    />
    <path
      d="M28.588 16.4694C28.1342 16.4694 27.7711 16.832 27.7711 17.2854C27.7711 17.7387 28.1342 18.1014 28.588 18.1014C29.0417 18.1014 29.4047 17.7387 29.4047 17.2854C29.4047 16.832 29.0417 16.4694 28.588 16.4694Z"
      fill="black"
    />
    <path
      d="M26.9543 16.4694C26.5006 16.4694 26.1376 16.832 26.1376 17.2854C26.1376 17.7387 26.5006 18.1014 26.9543 18.1014C27.4081 18.1014 27.7711 17.7387 27.7711 17.2854C27.7711 16.832 27.4081 16.4694 26.9543 16.4694Z"
      fill="black"
    />
    <path
      d="M25.3207 14.8373C24.867 14.8373 24.504 15.2 24.504 15.6533C24.504 16.1066 24.867 16.4694 25.3207 16.4694C25.7745 16.4694 26.1376 16.1066 26.1376 15.6533C26.1376 15.2 25.7745 14.8373 25.3207 14.8373Z"
      fill="black"
    />
    <path
      d="M23.6872 14.8373C23.2334 14.8373 22.8703 15.2 22.8703 15.6533C22.8703 16.1066 23.2334 16.4694 23.6872 16.4694C24.1409 16.4694 24.504 16.1066 24.504 15.6533C24.504 15.2 24.1409 14.8373 23.6872 14.8373Z"
      fill="black"
    />
    <path
      d="M28.588 14.8373C28.1342 14.8373 27.7711 15.2 27.7711 15.6533C27.7711 16.1066 28.1342 16.4694 28.588 16.4694C29.0417 16.4694 29.4047 16.1066 29.4047 15.6533C29.4047 15.2 29.0417 14.8373 28.588 14.8373Z"
      fill="black"
    />
    <path
      d="M26.9543 14.8373C26.5006 14.8373 26.1376 15.2 26.1376 15.6533C26.1376 16.1066 26.5006 16.4694 26.9543 16.4694C27.4081 16.4694 27.7711 16.1066 27.7711 15.6533C27.7711 15.2 27.4081 14.8373 26.9543 14.8373Z"
      fill="black"
    />
    <path
      d="M25.3207 13.2053C24.867 13.2053 24.504 13.5679 24.504 14.0213C24.504 14.4746 24.867 14.8373 25.3207 14.8373C25.7745 14.8373 26.1376 14.4746 26.1376 14.0213C26.1376 13.5679 25.7745 13.2053 25.3207 13.2053Z"
      fill="black"
    />
    <path
      d="M23.6872 13.2053C23.2334 13.2053 22.8703 13.5679 22.8703 14.0213C22.8703 14.4746 23.2334 14.8373 23.6872 14.8373C24.1409 14.8373 24.504 14.4746 24.504 14.0213C24.504 13.5679 24.1409 13.2053 23.6872 13.2053Z"
      fill="black"
    />
    <path
      d="M28.588 13.2053C28.1342 13.2053 27.7711 13.5679 27.7711 14.0213C27.7711 14.4746 28.1342 14.8373 28.588 14.8373C29.0417 14.8373 29.4047 14.4746 29.4047 14.0213C29.4047 13.5679 29.0417 13.2053 28.588 13.2053Z"
      fill="black"
    />
    <path
      d="M26.9543 13.2053C26.5006 13.2053 26.1376 13.5679 26.1376 14.0213C26.1376 14.4746 26.5006 14.8373 26.9543 14.8373C27.4081 14.8373 27.7711 14.4746 27.7711 14.0213C27.7711 13.5679 27.4081 13.2053 26.9543 13.2053Z"
      fill="black"
    />
    <path
      d="M25.3207 11.5732C24.867 11.5732 24.504 11.9359 24.504 12.3892C24.504 12.8426 24.867 13.2053 25.3207 13.2053C25.7745 13.2053 26.1376 12.8426 26.1376 12.3892C26.1376 11.9359 25.7745 11.5732 25.3207 11.5732Z"
      fill="black"
    />
    <path
      d="M23.6872 11.5732C23.2334 11.5732 22.8703 11.9359 22.8703 12.3892C22.8703 12.8426 23.2334 13.2053 23.6872 13.2053C24.1409 13.2053 24.504 12.8426 24.504 12.3892C24.504 11.9359 24.1409 11.5732 23.6872 11.5732Z"
      fill="black"
    />
    <path
      d="M28.588 11.5732C28.1342 11.5732 27.7711 11.9359 27.7711 12.3892C27.7711 12.8426 28.1342 13.2053 28.588 13.2053C29.0417 13.2053 29.4047 12.8426 29.4047 12.3892C29.4047 11.9359 29.0417 11.5732 28.588 11.5732Z"
      fill="black"
    />
    <path
      d="M26.9543 11.5732C26.5006 11.5732 26.1376 11.9359 26.1376 12.3892C26.1376 12.8426 26.5006 13.2053 26.9543 13.2053C27.4081 13.2053 27.7711 12.8426 27.7711 12.3892C27.7711 11.9359 27.4081 11.5732 26.9543 11.5732Z"
      fill="black"
    />
    <path
      d="M25.3207 9.94117C24.867 9.94117 24.504 10.3038 24.504 10.7572C24.504 11.2106 24.867 11.5732 25.3207 11.5732C25.7745 11.5732 26.1376 11.2106 26.1376 10.7572C26.1376 10.3038 25.7745 9.94117 25.3207 9.94117Z"
      fill="black"
    />
    <path
      d="M23.6872 9.94117C23.2334 9.94117 22.8703 10.3038 22.8703 10.7572C22.8703 11.2106 23.2334 11.5732 23.6872 11.5732C24.1409 11.5732 24.504 11.2106 24.504 10.7572C24.504 10.3038 24.1409 9.94117 23.6872 9.94117Z"
      fill="black"
    />
    <path
      d="M28.588 9.94117C28.1342 9.94117 27.7711 10.3038 27.7711 10.7572C27.7711 11.2106 28.1342 11.5732 28.588 11.5732C29.0417 11.5732 29.4047 11.2106 29.4047 10.7572C29.4047 10.3038 29.0417 9.94117 28.588 9.94117Z"
      fill="black"
    />
    <path
      d="M26.9543 9.94117C26.5006 9.94117 26.1376 10.3038 26.1376 10.7572C26.1376 11.2106 26.5006 11.5732 26.9543 11.5732C27.4081 11.5732 27.7711 11.2106 27.7711 10.7572C27.7711 10.3038 27.4081 9.94117 26.9543 9.94117Z"
      fill="black"
    />
    <path
      d="M25.3207 8.30908C24.867 8.30908 24.504 8.67175 24.504 9.12508C24.504 9.57846 24.867 9.94117 25.3207 9.94117C25.7745 9.94117 26.1376 9.57846 26.1376 9.12508C26.1376 8.67175 25.7745 8.30908 25.3207 8.30908Z"
      fill="black"
    />
    <path
      d="M23.6872 8.30908C23.2334 8.30908 22.8703 8.67175 22.8703 9.12508C22.8703 9.57846 23.2334 9.94117 23.6872 9.94117C24.1409 9.94117 24.504 9.57846 24.504 9.12508C24.504 8.67175 24.1409 8.30908 23.6872 8.30908Z"
      fill="black"
    />
    <path
      d="M28.588 8.30908C28.1342 8.30908 27.7711 8.67175 27.7711 9.12508C27.7711 9.57846 28.1342 9.94117 28.588 9.94117C29.0417 9.94117 29.4047 9.57846 29.4047 9.12508C29.4047 8.67175 29.0417 8.30908 28.588 8.30908Z"
      fill="black"
    />
    <path
      d="M26.9543 8.30908C26.5006 8.30908 26.1376 8.67175 26.1376 9.12508C26.1376 9.57846 26.5006 9.94117 26.9543 9.94117C27.4081 9.94117 27.7711 9.57846 27.7711 9.12508C27.7711 8.67175 27.4081 8.30908 26.9543 8.30908Z"
      fill="black"
    />
    <path
      d="M25.3207 6.67703C24.867 6.67703 24.504 7.03975 24.504 7.49308C24.504 7.94646 24.867 8.30908 25.3207 8.30908C25.7745 8.30908 26.1376 7.94646 26.1376 7.49308C26.1376 7.03975 25.7745 6.67703 25.3207 6.67703Z"
      fill="black"
    />
    <path
      d="M23.6872 6.67703C23.2334 6.67703 22.8703 7.03975 22.8703 7.49308C22.8703 7.94646 23.2334 8.30908 23.6872 8.30908C24.1409 8.30908 24.504 7.94646 24.504 7.49308C24.504 7.03975 24.1409 6.67703 23.6872 6.67703Z"
      fill="black"
    />
    <path
      d="M28.588 6.67703C28.1342 6.67703 27.7711 7.03975 27.7711 7.49308C27.7711 7.94646 28.1342 8.30908 28.588 8.30908C29.0417 8.30908 29.4047 7.94646 29.4047 7.49308C29.4047 7.03975 29.0417 6.67703 28.588 6.67703Z"
      fill="black"
    />
    <path
      d="M26.9543 6.67703C26.5006 6.67703 26.1376 7.03975 26.1376 7.49308C26.1376 7.94646 26.5006 8.30908 26.9543 8.30908C27.4081 8.30908 27.7711 7.94646 27.7711 7.49308C27.7711 7.03975 27.4081 6.67703 26.9543 6.67703Z"
      fill="black"
    />
    <path
      d="M25.3207 5.04499C24.867 5.04499 24.504 5.40765 24.504 5.86103C24.504 6.31437 24.867 6.67703 25.3207 6.67703C25.7745 6.67703 26.1376 6.31437 26.1376 5.86103C26.1376 5.40765 25.7745 5.04499 25.3207 5.04499Z"
      fill="black"
    />
    <path
      d="M23.6872 5.04499C23.2334 5.04499 22.8703 5.40765 22.8703 5.86103C22.8703 6.31437 23.2334 6.67703 23.6872 6.67703C24.1409 6.67703 24.504 6.31437 24.504 5.86103C24.504 5.40765 24.1409 5.04499 23.6872 5.04499Z"
      fill="black"
    />
    <path
      d="M28.588 5.04499C28.1342 5.04499 27.7711 5.40765 27.7711 5.86103C27.7711 6.31437 28.1342 6.67703 28.588 6.67703C29.0417 6.67703 29.4047 6.31437 29.4047 5.86103C29.4047 5.40765 29.0417 5.04499 28.588 5.04499Z"
      fill="black"
    />
    <path
      d="M26.9543 5.04499C26.5006 5.04499 26.1376 5.40765 26.1376 5.86103C26.1376 6.31437 26.5006 6.67703 26.9543 6.67703C27.4081 6.67703 27.7711 6.31437 27.7711 5.86103C27.7711 5.40765 27.4081 5.04499 26.9543 5.04499Z"
      fill="black"
    />
    <path
      d="M25.3207 3.41294C24.867 3.41294 24.504 3.77561 24.504 4.22894C24.504 4.68232 24.867 5.04499 25.3207 5.04499C25.7745 5.04499 26.1376 4.68232 26.1376 4.22894C26.1376 3.77561 25.7745 3.41294 25.3207 3.41294Z"
      fill="black"
    />
    <path
      d="M23.6872 3.41294C23.2334 3.41294 22.8703 3.77561 22.8703 4.22894C22.8703 4.68232 23.2334 5.04499 23.6872 5.04499C24.1409 5.04499 24.504 4.68232 24.504 4.22894C24.504 3.77561 24.1409 3.41294 23.6872 3.41294Z"
      fill="black"
    />
    <path
      d="M28.588 3.41294C28.1342 3.41294 27.7711 3.77561 27.7711 4.22894C27.7711 4.68232 28.1342 5.04499 28.588 5.04499C29.0417 5.04499 29.4047 4.68232 29.4047 4.22894C29.4047 3.77561 29.0417 3.41294 28.588 3.41294Z"
      fill="black"
    />
    <path
      d="M26.9543 3.41294C26.5006 3.41294 26.1376 3.77561 26.1376 4.22894C26.1376 4.68232 26.5006 5.04499 26.9543 5.04499C27.4081 5.04499 27.7711 4.68232 27.7711 4.22894C27.7711 3.77561 27.4081 3.41294 26.9543 3.41294Z"
      fill="black"
    />
    <path
      d="M17.1528 31.1578C16.699 31.1578 16.336 31.5205 16.336 31.9739C16.336 32.4272 16.699 32.7899 17.1528 32.7899C17.6065 32.7899 17.9696 32.4272 17.9696 31.9739C17.9696 31.5205 17.6065 31.1578 17.1528 31.1578Z"
      fill="black"
    />
    <path
      d="M18.7863 29.5258C18.3326 29.5258 17.9696 29.8884 17.9696 30.3418C17.9696 30.7951 18.3326 31.1578 18.7863 31.1578C19.2401 31.1578 19.6032 30.7951 19.6032 30.3418C19.6032 29.8884 19.2401 29.5258 18.7863 29.5258Z"
      fill="black"
    />
    <path
      d="M17.1528 29.5258C16.699 29.5258 16.336 29.8884 16.336 30.3418C16.336 30.7951 16.699 31.1578 17.1528 31.1578C17.6065 31.1578 17.9696 30.7951 17.9696 30.3418C17.9696 29.8884 17.6065 29.5258 17.1528 29.5258Z"
      fill="black"
    />
    <path
      d="M18.7863 27.8937C18.3326 27.8937 17.9696 28.2564 17.9696 28.7097C17.9696 29.1631 18.3326 29.5258 18.7863 29.5258C19.2401 29.5258 19.6032 29.1631 19.6032 28.7097C19.6032 28.2564 19.2401 27.8937 18.7863 27.8937Z"
      fill="black"
    />
    <path
      d="M17.1528 27.8937C16.699 27.8937 16.336 28.2564 16.336 28.7097C16.336 29.1631 16.699 29.5258 17.1528 29.5258C17.6065 29.5258 17.9696 29.1631 17.9696 28.7097C17.9696 28.2564 17.6065 27.8937 17.1528 27.8937Z"
      fill="black"
    />
    <path
      d="M20.42 27.8937C19.9662 27.8937 19.6032 28.2564 19.6032 28.7097C19.6032 29.1631 19.9662 29.5258 20.42 29.5258C20.8737 29.5258 21.2367 29.1631 21.2367 28.7097C21.2367 28.2564 20.8737 27.8937 20.42 27.8937Z"
      fill="black"
    />
    <path
      d="M18.7863 26.2617C18.3326 26.2617 17.9696 26.6243 17.9696 27.0777C17.9696 27.5311 18.3326 27.8937 18.7863 27.8937C19.2401 27.8937 19.6032 27.5311 19.6032 27.0777C19.6032 26.6243 19.2401 26.2617 18.7863 26.2617Z"
      fill="black"
    />
    <path
      d="M17.1528 26.2617C16.699 26.2617 16.336 26.6243 16.336 27.0777C16.336 27.5311 16.699 27.8937 17.1528 27.8937C17.6065 27.8937 17.9696 27.5311 17.9696 27.0777C17.9696 26.6243 17.6065 26.2617 17.1528 26.2617Z"
      fill="black"
    />
    <path
      d="M22.0536 26.2617C21.5998 26.2617 21.2368 26.6243 21.2368 27.0777C21.2368 27.5311 21.5998 27.8937 22.0536 27.8937C22.5074 27.8937 22.8703 27.5311 22.8703 27.0777C22.8703 26.6243 22.5074 26.2617 22.0536 26.2617Z"
      fill="black"
    />
    <path
      d="M20.42 26.2617C19.9662 26.2617 19.6032 26.6243 19.6032 27.0777C19.6032 27.5311 19.9662 27.8937 20.42 27.8937C20.8737 27.8937 21.2368 27.5311 21.2368 27.0777C21.2368 26.6243 20.8737 26.2617 20.42 26.2617Z"
      fill="black"
    />
    <path
      d="M18.7863 8.30908C18.3326 8.30908 17.9696 8.67175 17.9696 9.12508C17.9696 9.57846 18.3326 9.94112 18.7863 9.94112C19.2401 9.94112 19.6032 9.57846 19.6032 9.12508C19.6032 8.67175 19.2401 8.30908 18.7863 8.30908Z"
      fill="black"
    />
    <path
      d="M17.1528 8.30908C16.699 8.30908 16.336 8.67175 16.336 9.12508C16.336 9.57846 16.699 9.94112 17.1528 9.94112C17.6065 9.94112 17.9696 9.57846 17.9696 9.12508C17.9696 8.67175 17.6065 8.30908 17.1528 8.30908Z"
      fill="black"
    />
    <path
      d="M22.0536 8.30908C21.5998 8.30908 21.2368 8.67175 21.2368 9.12508C21.2368 9.57846 21.5998 9.94112 22.0536 9.94112C22.5074 9.94112 22.8703 9.57846 22.8703 9.12508C22.8703 8.67175 22.5074 8.30908 22.0536 8.30908Z"
      fill="black"
    />
    <path
      d="M20.42 8.30908C19.9662 8.30908 19.6032 8.67175 19.6032 9.12508C19.6032 9.57846 19.9662 9.94112 20.42 9.94112C20.8737 9.94112 21.2368 9.57846 21.2368 9.12508C21.2368 8.67175 20.8737 8.30908 20.42 8.30908Z"
      fill="black"
    />
    <path
      d="M18.7863 6.67703C18.3326 6.67703 17.9696 7.03975 17.9696 7.49308C17.9696 7.94646 18.3326 8.30908 18.7863 8.30908C19.2401 8.30908 19.6032 7.94646 19.6032 7.49308C19.6032 7.03975 19.2401 6.67703 18.7863 6.67703Z"
      fill="black"
    />
    <path
      d="M17.1528 6.67703C16.699 6.67703 16.336 7.03975 16.336 7.49308C16.336 7.94646 16.699 8.30908 17.1528 8.30908C17.6065 8.30908 17.9696 7.94646 17.9696 7.49308C17.9696 7.03975 17.6065 6.67703 17.1528 6.67703Z"
      fill="black"
    />
    <path
      d="M22.0536 6.67703C21.5998 6.67703 21.2368 7.03975 21.2368 7.49308C21.2368 7.94646 21.5998 8.30908 22.0536 8.30908C22.5074 8.30908 22.8703 7.94646 22.8703 7.49308C22.8703 7.03975 22.5074 6.67703 22.0536 6.67703Z"
      fill="black"
    />
    <path
      d="M20.42 6.67703C19.9662 6.67703 19.6032 7.03975 19.6032 7.49308C19.6032 7.94646 19.9662 8.30908 20.42 8.30908C20.8737 8.30908 21.2368 7.94646 21.2368 7.49308C21.2368 7.03975 20.8737 6.67703 20.42 6.67703Z"
      fill="black"
    />
    <path
      d="M18.7863 5.04499C18.3326 5.04499 17.9696 5.40765 17.9696 5.86103C17.9696 6.31437 18.3326 6.67703 18.7863 6.67703C19.2401 6.67703 19.6032 6.31437 19.6032 5.86103C19.6032 5.40765 19.2401 5.04499 18.7863 5.04499Z"
      fill="black"
    />
    <path
      d="M17.1528 5.04499C16.699 5.04499 16.336 5.40765 16.336 5.86103C16.336 6.31437 16.699 6.67703 17.1528 6.67703C17.6065 6.67703 17.9696 6.31437 17.9696 5.86103C17.9696 5.40765 17.6065 5.04499 17.1528 5.04499Z"
      fill="black"
    />
    <path
      d="M22.0536 5.04499C21.5998 5.04499 21.2368 5.40765 21.2368 5.86103C21.2368 6.31437 21.5998 6.67703 22.0536 6.67703C22.5074 6.67703 22.8703 6.31437 22.8703 5.86103C22.8703 5.40765 22.5074 5.04499 22.0536 5.04499Z"
      fill="black"
    />
    <path
      d="M20.42 5.04499C19.9662 5.04499 19.6032 5.40765 19.6032 5.86103C19.6032 6.31437 19.9662 6.67703 20.42 6.67703C20.8737 6.67703 21.2368 6.31437 21.2368 5.86103C21.2368 5.40765 20.8737 5.04499 20.42 5.04499Z"
      fill="black"
    />
    <path
      d="M18.7863 3.41294C18.3326 3.41294 17.9696 3.77561 17.9696 4.22894C17.9696 4.68232 18.3326 5.04499 18.7863 5.04499C19.2401 5.04499 19.6032 4.68232 19.6032 4.22894C19.6032 3.77561 19.2401 3.41294 18.7863 3.41294Z"
      fill="black"
    />
    <path
      d="M17.1528 3.41294C16.699 3.41294 16.336 3.77561 16.336 4.22894C16.336 4.68232 16.699 5.04499 17.1528 5.04499C17.6065 5.04499 17.9696 4.68232 17.9696 4.22894C17.9696 3.77561 17.6065 3.41294 17.1528 3.41294Z"
      fill="black"
    />
    <path
      d="M22.0536 3.41294C21.5998 3.41294 21.2368 3.77561 21.2368 4.22894C21.2368 4.68232 21.5998 5.04499 22.0536 5.04499C22.5074 5.04499 22.8703 4.68232 22.8703 4.22894C22.8703 3.77561 22.5074 3.41294 22.0536 3.41294Z"
      fill="black"
    />
    <path
      d="M20.42 3.41294C19.9662 3.41294 19.6032 3.77561 19.6032 4.22894C19.6032 4.68232 19.9662 5.04499 20.42 5.04499C20.8737 5.04499 21.2368 4.68232 21.2368 4.22894C21.2368 3.77561 20.8737 3.41294 20.42 3.41294Z"
      fill="black"
    />
    <path
      d="M12.252 31.1578C11.7982 31.1578 11.4352 31.5205 11.4352 31.9739C11.4352 32.4272 11.7982 32.7899 12.252 32.7899C12.7057 32.7899 13.0688 32.4272 13.0688 31.9739C13.0688 31.5205 12.7057 31.1578 12.252 31.1578Z"
      fill="black"
    />
    <path
      d="M10.6184 31.1578C10.1646 31.1578 9.80156 31.5205 9.80156 31.9739C9.80156 32.4272 10.1646 32.7899 10.6184 32.7899C11.0721 32.7899 11.4352 32.4272 11.4352 31.9739C11.4352 31.5205 11.0721 31.1578 10.6184 31.1578Z"
      fill="black"
    />
    <path
      d="M15.5192 31.1578C15.0654 31.1578 14.7024 31.5205 14.7024 31.9739C14.7024 32.4272 15.0654 32.7899 15.5192 32.7899C15.973 32.7899 16.336 32.4272 16.336 31.9739C16.336 31.5205 15.973 31.1578 15.5192 31.1578Z"
      fill="black"
    />
    <path
      d="M13.8856 31.1578C13.4318 31.1578 13.0688 31.5205 13.0688 31.9739C13.0688 32.4272 13.4318 32.7899 13.8856 32.7899C14.3394 32.7899 14.7024 32.4272 14.7024 31.9739C14.7024 31.5205 14.3394 31.1578 13.8856 31.1578Z"
      fill="black"
    />
    <path
      d="M12.252 29.5258C11.7982 29.5258 11.4352 29.8884 11.4352 30.3418C11.4352 30.7951 11.7982 31.1578 12.252 31.1578C12.7057 31.1578 13.0688 30.7951 13.0688 30.3418C13.0688 29.8884 12.7057 29.5258 12.252 29.5258Z"
      fill="black"
    />
    <path
      d="M10.6184 29.5258C10.1646 29.5258 9.80156 29.8884 9.80156 30.3418C9.80156 30.7951 10.1646 31.1578 10.6184 31.1578C11.0721 31.1578 11.4352 30.7951 11.4352 30.3418C11.4352 29.8884 11.0721 29.5258 10.6184 29.5258Z"
      fill="black"
    />
    <path
      d="M15.5192 29.5258C15.0654 29.5258 14.7024 29.8884 14.7024 30.3418C14.7024 30.7951 15.0654 31.1578 15.5192 31.1578C15.973 31.1578 16.336 30.7951 16.336 30.3418C16.336 29.8884 15.973 29.5258 15.5192 29.5258Z"
      fill="black"
    />
    <path
      d="M13.8856 29.5258C13.4318 29.5258 13.0688 29.8884 13.0688 30.3418C13.0688 30.7951 13.4318 31.1578 13.8856 31.1578C14.3394 31.1578 14.7024 30.7951 14.7024 30.3418C14.7024 29.8884 14.3394 29.5258 13.8856 29.5258Z"
      fill="black"
    />
    <path
      d="M12.252 27.8937C11.7982 27.8937 11.4352 28.2564 11.4352 28.7097C11.4352 29.1631 11.7982 29.5258 12.252 29.5258C12.7057 29.5258 13.0688 29.1631 13.0688 28.7097C13.0688 28.2564 12.7057 27.8937 12.252 27.8937Z"
      fill="black"
    />
    <path
      d="M10.6184 27.8937C10.1646 27.8937 9.80156 28.2564 9.80156 28.7097C9.80156 29.1631 10.1646 29.5258 10.6184 29.5258C11.0721 29.5258 11.4352 29.1631 11.4352 28.7097C11.4352 28.2564 11.0721 27.8937 10.6184 27.8937Z"
      fill="black"
    />
    <path
      d="M15.5192 27.8937C15.0654 27.8937 14.7024 28.2564 14.7024 28.7097C14.7024 29.1631 15.0654 29.5258 15.5192 29.5258C15.973 29.5258 16.336 29.1631 16.336 28.7097C16.336 28.2564 15.973 27.8937 15.5192 27.8937Z"
      fill="black"
    />
    <path
      d="M13.8856 27.8937C13.4318 27.8937 13.0688 28.2564 13.0688 28.7097C13.0688 29.1631 13.4318 29.5258 13.8856 29.5258C14.3394 29.5258 14.7024 29.1631 14.7024 28.7097C14.7024 28.2564 14.3394 27.8937 13.8856 27.8937Z"
      fill="black"
    />
    <path
      d="M12.252 26.2617C11.7982 26.2617 11.4352 26.6243 11.4352 27.0777C11.4352 27.5311 11.7982 27.8937 12.252 27.8937C12.7057 27.8937 13.0688 27.5311 13.0688 27.0777C13.0688 26.6243 12.7057 26.2617 12.252 26.2617Z"
      fill="black"
    />
    <path
      d="M10.6184 26.2617C10.1646 26.2617 9.80156 26.6243 9.80156 27.0777C9.80156 27.5311 10.1646 27.8937 10.6184 27.8937C11.0721 27.8937 11.4352 27.5311 11.4352 27.0777C11.4352 26.6243 11.0721 26.2617 10.6184 26.2617Z"
      fill="black"
    />
    <path
      d="M15.5192 26.2617C15.0654 26.2617 14.7024 26.6243 14.7024 27.0777C14.7024 27.5311 15.0654 27.8937 15.5192 27.8937C15.973 27.8937 16.336 27.5311 16.336 27.0777C16.336 26.6243 15.973 26.2617 15.5192 26.2617Z"
      fill="black"
    />
    <path
      d="M13.8856 26.2617C13.4318 26.2617 13.0688 26.6243 13.0688 27.0777C13.0688 27.5311 13.4318 27.8937 13.8856 27.8937C14.3394 27.8937 14.7024 27.5311 14.7024 27.0777C14.7024 26.6243 14.3394 26.2617 13.8856 26.2617Z"
      fill="black"
    />
    <path
      d="M12.252 8.30908C11.7982 8.30908 11.4352 8.67175 11.4352 9.12508C11.4352 9.57846 11.7982 9.94112 12.252 9.94112C12.7057 9.94112 13.0688 9.57846 13.0688 9.12508C13.0688 8.67175 12.7057 8.30908 12.252 8.30908Z"
      fill="black"
    />
    <path
      d="M10.6184 8.30908C10.1646 8.30908 9.80156 8.67175 9.80156 9.12508C9.80156 9.57846 10.1646 9.94112 10.6184 9.94112C11.0721 9.94112 11.4352 9.57846 11.4352 9.12508C11.4352 8.67175 11.0721 8.30908 10.6184 8.30908Z"
      fill="black"
    />
    <path
      d="M15.5192 8.30908C15.0654 8.30908 14.7024 8.67175 14.7024 9.12508C14.7024 9.57846 15.0654 9.94112 15.5192 9.94112C15.973 9.94112 16.336 9.57846 16.336 9.12508C16.336 8.67175 15.973 8.30908 15.5192 8.30908Z"
      fill="black"
    />
    <path
      d="M13.8856 8.30908C13.4318 8.30908 13.0688 8.67175 13.0688 9.12508C13.0688 9.57846 13.4318 9.94112 13.8856 9.94112C14.3394 9.94112 14.7024 9.57846 14.7024 9.12508C14.7024 8.67175 14.3394 8.30908 13.8856 8.30908Z"
      fill="black"
    />
    <path
      d="M12.252 6.67703C11.7982 6.67703 11.4352 7.03975 11.4352 7.49308C11.4352 7.94646 11.7982 8.30908 12.252 8.30908C12.7057 8.30908 13.0688 7.94646 13.0688 7.49308C13.0688 7.03975 12.7057 6.67703 12.252 6.67703Z"
      fill="black"
    />
    <path
      d="M10.6184 6.67703C10.1646 6.67703 9.80156 7.03975 9.80156 7.49308C9.80156 7.94646 10.1646 8.30908 10.6184 8.30908C11.0721 8.30908 11.4352 7.94646 11.4352 7.49308C11.4352 7.03975 11.0721 6.67703 10.6184 6.67703Z"
      fill="black"
    />
    <path
      d="M15.5192 6.67703C15.0654 6.67703 14.7024 7.03975 14.7024 7.49308C14.7024 7.94646 15.0654 8.30908 15.5192 8.30908C15.973 8.30908 16.336 7.94646 16.336 7.49308C16.336 7.03975 15.973 6.67703 15.5192 6.67703Z"
      fill="black"
    />
    <path
      d="M13.8856 6.67703C13.4318 6.67703 13.0688 7.03975 13.0688 7.49308C13.0688 7.94646 13.4318 8.30908 13.8856 8.30908C14.3394 8.30908 14.7024 7.94646 14.7024 7.49308C14.7024 7.03975 14.3394 6.67703 13.8856 6.67703Z"
      fill="black"
    />
    <path
      d="M12.252 5.04499C11.7982 5.04499 11.4352 5.40765 11.4352 5.86103C11.4352 6.31437 11.7982 6.67703 12.252 6.67703C12.7057 6.67703 13.0688 6.31437 13.0688 5.86103C13.0688 5.40765 12.7057 5.04499 12.252 5.04499Z"
      fill="black"
    />
    <path
      d="M10.6184 5.04499C10.1646 5.04499 9.80156 5.40765 9.80156 5.86103C9.80156 6.31437 10.1646 6.67703 10.6184 6.67703C11.0721 6.67703 11.4352 6.31437 11.4352 5.86103C11.4352 5.40765 11.0721 5.04499 10.6184 5.04499Z"
      fill="black"
    />
    <path
      d="M15.5192 5.04499C15.0654 5.04499 14.7024 5.40765 14.7024 5.86103C14.7024 6.31437 15.0654 6.67703 15.5192 6.67703C15.973 6.67703 16.336 6.31437 16.336 5.86103C16.336 5.40765 15.973 5.04499 15.5192 5.04499Z"
      fill="black"
    />
    <path
      d="M13.8856 5.04499C13.4318 5.04499 13.0688 5.40765 13.0688 5.86103C13.0688 6.31437 13.4318 6.67703 13.8856 6.67703C14.3394 6.67703 14.7024 6.31437 14.7024 5.86103C14.7024 5.40765 14.3394 5.04499 13.8856 5.04499Z"
      fill="black"
    />
    <path
      d="M12.252 3.41294C11.7982 3.41294 11.4352 3.77561 11.4352 4.22894C11.4352 4.68232 11.7982 5.04499 12.252 5.04499C12.7057 5.04499 13.0688 4.68232 13.0688 4.22894C13.0688 3.77561 12.7057 3.41294 12.252 3.41294Z"
      fill="black"
    />
    <path
      d="M10.6184 3.41294C10.1646 3.41294 9.80156 3.77561 9.80156 4.22894C9.80156 4.68232 10.1646 5.04499 10.6184 5.04499C11.0721 5.04499 11.4352 4.68232 11.4352 4.22894C11.4352 3.77561 11.0721 3.41294 10.6184 3.41294Z"
      fill="black"
    />
    <path
      d="M15.5192 3.41294C15.0654 3.41294 14.7024 3.77561 14.7024 4.22894C14.7024 4.68232 15.0654 5.04499 15.5192 5.04499C15.973 5.04499 16.336 4.68232 16.336 4.22894C16.336 3.77561 15.973 3.41294 15.5192 3.41294Z"
      fill="black"
    />
    <path
      d="M13.8856 3.41294C13.4318 3.41294 13.0688 3.77561 13.0688 4.22894C13.0688 4.68232 13.4318 5.04499 13.8856 5.04499C14.3394 5.04499 14.7024 4.68232 14.7024 4.22894C14.7024 3.77561 14.3394 3.41294 13.8856 3.41294Z"
      fill="black"
    />
    <path
      d="M5.71761 31.1578C5.26382 31.1578 4.90078 31.5205 4.90078 31.9739C4.90078 32.4272 5.26382 32.7899 5.71761 32.7899C6.17136 32.7899 6.53439 32.4272 6.53439 31.9739C6.53439 31.5205 6.17136 31.1578 5.71761 31.1578Z"
      fill="black"
    />
    <path
      d="M8.98478 31.1578C8.53099 31.1578 8.168 31.5205 8.168 31.9739C8.168 32.4272 8.53099 32.7899 8.98478 32.7899C9.43858 32.7899 9.80156 32.4272 9.80156 31.9739C9.80156 31.5205 9.43858 31.1578 8.98478 31.1578Z"
      fill="black"
    />
    <path
      d="M7.35117 31.1578C6.89743 31.1578 6.53439 31.5205 6.53439 31.9739C6.53439 32.4272 6.89743 32.7899 7.35117 32.7899C7.80497 32.7899 8.168 32.4272 8.168 31.9739C8.168 31.5205 7.80497 31.1578 7.35117 31.1578Z"
      fill="black"
    />
    <path
      d="M5.71761 29.5258C5.26382 29.5258 4.90078 29.8884 4.90078 30.3418C4.90078 30.7951 5.26382 31.1578 5.71761 31.1578C6.17136 31.1578 6.53439 30.7951 6.53439 30.3418C6.53439 29.8884 6.17136 29.5258 5.71761 29.5258Z"
      fill="black"
    />
    <path
      d="M4.08399 29.5258C3.6302 29.5258 3.26716 29.8884 3.26716 30.3418C3.26716 30.7951 3.6302 31.1578 4.08399 31.1578C4.53774 31.1578 4.90078 30.7951 4.90078 30.3418C4.90078 29.8884 4.53774 29.5258 4.08399 29.5258Z"
      fill="black"
    />
    <path
      d="M8.98478 29.5258C8.53099 29.5258 8.168 29.8884 8.168 30.3418C8.168 30.7951 8.53099 31.1578 8.98478 31.1578C9.43858 31.1578 9.80156 30.7951 9.80156 30.3418C9.80156 29.8884 9.43858 29.5258 8.98478 29.5258Z"
      fill="black"
    />
    <path
      d="M7.35117 29.5258C6.89743 29.5258 6.53439 29.8884 6.53439 30.3418C6.53439 30.7951 6.89743 31.1578 7.35117 31.1578C7.80497 31.1578 8.168 30.7951 8.168 30.3418C8.168 29.8884 7.80497 29.5258 7.35117 29.5258Z"
      fill="black"
    />
    <path
      d="M5.71761 27.8937C5.26382 27.8937 4.90078 28.2564 4.90078 28.7097C4.90078 29.1631 5.26382 29.5258 5.71761 29.5258C6.17136 29.5258 6.53439 29.1631 6.53439 28.7097C6.53439 28.2564 6.17136 27.8937 5.71761 27.8937Z"
      fill="black"
    />
    <path
      d="M4.08399 27.8937C3.6302 27.8937 3.26716 28.2564 3.26716 28.7097C3.26716 29.1631 3.6302 29.5258 4.08399 29.5258C4.53774 29.5258 4.90078 29.1631 4.90078 28.7097C4.90078 28.2564 4.53774 27.8937 4.08399 27.8937Z"
      fill="black"
    />
    <path
      d="M8.98478 27.8937C8.53099 27.8937 8.168 28.2564 8.168 28.7097C8.168 29.1631 8.53099 29.5258 8.98478 29.5258C9.43858 29.5258 9.80156 29.1631 9.80156 28.7097C9.80156 28.2564 9.43858 27.8937 8.98478 27.8937Z"
      fill="black"
    />
    <path
      d="M7.35117 27.8937C6.89743 27.8937 6.53439 28.2564 6.53439 28.7097C6.53439 29.1631 6.89743 29.5258 7.35117 29.5258C7.80497 29.5258 8.168 29.1631 8.168 28.7097C8.168 28.2564 7.80497 27.8937 7.35117 27.8937Z"
      fill="black"
    />
    <path
      d="M5.71761 26.2617C5.26382 26.2617 4.90078 26.6243 4.90078 27.0777C4.90078 27.5311 5.26382 27.8937 5.71761 27.8937C6.17136 27.8937 6.53439 27.5311 6.53439 27.0777C6.53439 26.6243 6.17136 26.2617 5.71761 26.2617Z"
      fill="black"
    />
    <path
      d="M4.08399 26.2617C3.6302 26.2617 3.26716 26.6243 3.26716 27.0777C3.26716 27.5311 3.6302 27.8937 4.08399 27.8937C4.53774 27.8937 4.90078 27.5311 4.90078 27.0777C4.90078 26.6243 4.53774 26.2617 4.08399 26.2617Z"
      fill="black"
    />
    <path
      d="M8.98478 26.2617C8.53099 26.2617 8.168 26.6243 8.168 27.0777C8.168 27.5311 8.53099 27.8937 8.98478 27.8937C9.43858 27.8937 9.80156 27.5311 9.80156 27.0777C9.80156 26.6243 9.43858 26.2617 8.98478 26.2617Z"
      fill="black"
    />
    <path
      d="M7.35117 26.2617C6.89743 26.2617 6.53439 26.6243 6.53439 27.0777C6.53439 27.5311 6.89743 27.8937 7.35117 27.8937C7.80497 27.8937 8.168 27.5311 8.168 27.0777C8.168 26.6243 7.80497 26.2617 7.35117 26.2617Z"
      fill="black"
    />
    <path
      d="M5.71761 24.6296C5.26382 24.6296 4.90078 24.9923 4.90078 25.4456C4.90078 25.899 5.26382 26.2617 5.71761 26.2617C6.17136 26.2617 6.53439 25.899 6.53439 25.4456C6.53439 24.9923 6.17136 24.6296 5.71761 24.6296Z"
      fill="black"
    />
    <path
      d="M4.08399 24.6296C3.6302 24.6296 3.26716 24.9923 3.26716 25.4456C3.26716 25.899 3.6302 26.2617 4.08399 26.2617C4.53774 26.2617 4.90078 25.899 4.90078 25.4456C4.90078 24.9923 4.53774 24.6296 4.08399 24.6296Z"
      fill="black"
    />
    <path
      d="M5.71761 22.9976C5.26382 22.9976 4.90078 23.3603 4.90078 23.8136C4.90078 24.267 5.26382 24.6296 5.71761 24.6296C6.17136 24.6296 6.53439 24.267 6.53439 23.8136C6.53439 23.3603 6.17136 22.9976 5.71761 22.9976Z"
      fill="black"
    />
    <path
      d="M4.08399 22.9976C3.6302 22.9976 3.26716 23.3603 3.26716 23.8136C3.26716 24.267 3.6302 24.6296 4.08399 24.6296C4.53774 24.6296 4.90078 24.267 4.90078 23.8136C4.90078 23.3603 4.53774 22.9976 4.08399 22.9976Z"
      fill="black"
    />
    <path
      d="M5.71761 21.3655C5.26382 21.3655 4.90078 21.7282 4.90078 22.1815C4.90078 22.6349 5.26382 22.9976 5.71761 22.9976C6.17136 22.9976 6.53439 22.6349 6.53439 22.1815C6.53439 21.7282 6.17136 21.3655 5.71761 21.3655Z"
      fill="black"
    />
    <path
      d="M4.08399 21.3655C3.6302 21.3655 3.26716 21.7282 3.26716 22.1815C3.26716 22.6349 3.6302 22.9976 4.08399 22.9976C4.53774 22.9976 4.90078 22.6349 4.90078 22.1815C4.90078 21.7282 4.53774 21.3655 4.08399 21.3655Z"
      fill="black"
    />
    <path
      d="M5.71761 19.7334C5.26382 19.7334 4.90078 20.0961 4.90078 20.5495C4.90078 21.0028 5.26382 21.3655 5.71761 21.3655C6.17136 21.3655 6.53439 21.0028 6.53439 20.5495C6.53439 20.0961 6.17136 19.7334 5.71761 19.7334Z"
      fill="black"
    />
    <path
      d="M4.08399 19.7334C3.6302 19.7334 3.26716 20.0961 3.26716 20.5495C3.26716 21.0028 3.6302 21.3655 4.08399 21.3655C4.53774 21.3655 4.90078 21.0028 4.90078 20.5495C4.90078 20.0961 4.53774 19.7334 4.08399 19.7334Z"
      fill="black"
    />
    <path
      d="M5.71761 18.1014C5.26382 18.1014 4.90078 18.4641 4.90078 18.9174C4.90078 19.3708 5.26382 19.7334 5.71761 19.7334C6.17136 19.7334 6.53439 19.3708 6.53439 18.9174C6.53439 18.4641 6.17136 18.1014 5.71761 18.1014Z"
      fill="black"
    />
    <path
      d="M4.08399 18.1014C3.6302 18.1014 3.26716 18.4641 3.26716 18.9174C3.26716 19.3708 3.6302 19.7334 4.08399 19.7334C4.53774 19.7334 4.90078 19.3708 4.90078 18.9174C4.90078 18.4641 4.53774 18.1014 4.08399 18.1014Z"
      fill="black"
    />
    <path
      d="M5.71761 16.4694C5.26382 16.4694 4.90078 16.832 4.90078 17.2854C4.90078 17.7387 5.26382 18.1014 5.71761 18.1014C6.17136 18.1014 6.53439 17.7387 6.53439 17.2854C6.53439 16.832 6.17136 16.4694 5.71761 16.4694Z"
      fill="black"
    />
    <path
      d="M4.08399 16.4694C3.6302 16.4694 3.26716 16.832 3.26716 17.2854C3.26716 17.7387 3.6302 18.1014 4.08399 18.1014C4.53774 18.1014 4.90078 17.7387 4.90078 17.2854C4.90078 16.832 4.53774 16.4694 4.08399 16.4694Z"
      fill="black"
    />
    <path
      d="M5.71761 14.8373C5.26382 14.8373 4.90078 15.2 4.90078 15.6533C4.90078 16.1066 5.26382 16.4694 5.71761 16.4694C6.17136 16.4694 6.53439 16.1066 6.53439 15.6533C6.53439 15.2 6.17136 14.8373 5.71761 14.8373Z"
      fill="black"
    />
    <path
      d="M4.08399 14.8373C3.6302 14.8373 3.26716 15.2 3.26716 15.6533C3.26716 16.1066 3.6302 16.4694 4.08399 16.4694C4.53774 16.4694 4.90078 16.1066 4.90078 15.6533C4.90078 15.2 4.53774 14.8373 4.08399 14.8373Z"
      fill="black"
    />
    <path
      d="M5.71761 13.2053C5.26382 13.2053 4.90078 13.5679 4.90078 14.0213C4.90078 14.4746 5.26382 14.8373 5.71761 14.8373C6.17136 14.8373 6.53439 14.4746 6.53439 14.0213C6.53439 13.5679 6.17136 13.2053 5.71761 13.2053Z"
      fill="black"
    />
    <path
      d="M4.08399 13.2053C3.6302 13.2053 3.26716 13.5679 3.26716 14.0213C3.26716 14.4746 3.6302 14.8373 4.08399 14.8373C4.53774 14.8373 4.90078 14.4746 4.90078 14.0213C4.90078 13.5679 4.53774 13.2053 4.08399 13.2053Z"
      fill="black"
    />
    <path
      d="M5.71761 11.5732C5.26382 11.5732 4.90078 11.9359 4.90078 12.3892C4.90078 12.8426 5.26382 13.2053 5.71761 13.2053C6.17136 13.2053 6.53439 12.8426 6.53439 12.3892C6.53439 11.9359 6.17136 11.5732 5.71761 11.5732Z"
      fill="black"
    />
    <path
      d="M4.08399 11.5732C3.6302 11.5732 3.26716 11.9359 3.26716 12.3892C3.26716 12.8426 3.6302 13.2053 4.08399 13.2053C4.53774 13.2053 4.90078 12.8426 4.90078 12.3892C4.90078 11.9359 4.53774 11.5732 4.08399 11.5732Z"
      fill="black"
    />
    <path
      d="M5.71761 9.94117C5.26382 9.94117 4.90078 10.3038 4.90078 10.7572C4.90078 11.2106 5.26382 11.5732 5.71761 11.5732C6.17136 11.5732 6.53439 11.2106 6.53439 10.7572C6.53439 10.3038 6.17136 9.94117 5.71761 9.94117Z"
      fill="black"
    />
    <path
      d="M4.08399 9.94117C3.6302 9.94117 3.26716 10.3038 3.26716 10.7572C3.26716 11.2106 3.6302 11.5732 4.08399 11.5732C4.53774 11.5732 4.90078 11.2106 4.90078 10.7572C4.90078 10.3038 4.53774 9.94117 4.08399 9.94117Z"
      fill="black"
    />
    <path
      d="M5.71761 8.30908C5.26382 8.30908 4.90078 8.67175 4.90078 9.12508C4.90078 9.57846 5.26382 9.94117 5.71761 9.94117C6.17136 9.94117 6.53439 9.57846 6.53439 9.12508C6.53439 8.67175 6.17136 8.30908 5.71761 8.30908Z"
      fill="black"
    />
    <path
      d="M8.98478 8.30908C8.53099 8.30908 8.168 8.67175 8.168 9.12508C8.168 9.57846 8.53099 9.94112 8.98478 9.94112C9.43858 9.94112 9.80156 9.57846 9.80156 9.12508C9.80156 8.67175 9.43858 8.30908 8.98478 8.30908Z"
      fill="black"
    />
    <path
      d="M7.35117 8.30908C6.89743 8.30908 6.53439 8.67175 6.53439 9.12508C6.53439 9.57846 6.89743 9.94112 7.35117 9.94112C7.80497 9.94112 8.168 9.57846 8.168 9.12508C8.168 8.67175 7.80497 8.30908 7.35117 8.30908Z"
      fill="black"
    />
    <path
      d="M8.98478 6.67703C8.53099 6.67703 8.168 7.03975 8.168 7.49308C8.168 7.94646 8.53099 8.30908 8.98478 8.30908C9.43858 8.30908 9.80156 7.94646 9.80156 7.49308C9.80156 7.03975 9.43858 6.67703 8.98478 6.67703Z"
      fill="black"
    />
    <path
      d="M7.35117 6.67703C6.89743 6.67703 6.53439 7.03975 6.53439 7.49308C6.53439 7.94646 6.89743 8.30908 7.35117 8.30908C7.80497 8.30908 8.168 7.94646 8.168 7.49308C8.168 7.03975 7.80497 6.67703 7.35117 6.67703Z"
      fill="black"
    />
    <path
      d="M8.98478 5.04499C8.53099 5.04499 8.168 5.40765 8.168 5.86103C8.168 6.31437 8.53099 6.67703 8.98478 6.67703C9.43858 6.67703 9.80156 6.31437 9.80156 5.86103C9.80156 5.40765 9.43858 5.04499 8.98478 5.04499Z"
      fill="black"
    />
    <path
      d="M2.4504 27.8937C1.99662 27.8937 1.6336 28.2564 1.6336 28.7097C1.6336 29.1631 1.99662 29.5258 2.4504 29.5258C2.90417 29.5258 3.26716 29.1631 3.26716 28.7097C3.26716 28.2564 2.90417 27.8937 2.4504 27.8937Z"
      fill="black"
    />
    <path
      d="M2.4504 26.2617C1.99662 26.2617 1.6336 26.6243 1.6336 27.0777C1.6336 27.5311 1.99662 27.8937 2.4504 27.8937C2.90417 27.8937 3.26716 27.5311 3.26716 27.0777C3.26716 26.6243 2.90417 26.2617 2.4504 26.2617Z"
      fill="black"
    />
    <path
      d="M0.8168 26.2617C0.363021 26.2617 0 26.6243 0 27.0777C0 27.5311 0.363021 27.8937 0.8168 27.8937C1.27057 27.8937 1.6336 27.5311 1.6336 27.0777C1.6336 26.6243 1.27057 26.2617 0.8168 26.2617Z"
      fill="black"
    />
    <path
      d="M2.4504 24.6296C1.99662 24.6296 1.6336 24.9923 1.6336 25.4456C1.6336 25.899 1.99662 26.2617 2.4504 26.2617C2.90417 26.2617 3.26716 25.899 3.26716 25.4456C3.26716 24.9923 2.90417 24.6296 2.4504 24.6296Z"
      fill="black"
    />
    <path
      d="M0.8168 24.6296C0.363021 24.6296 0 24.9923 0 25.4456C0 25.899 0.363021 26.2617 0.8168 26.2617C1.27057 26.2617 1.6336 25.899 1.6336 25.4456C1.6336 24.9923 1.27057 24.6296 0.8168 24.6296Z"
      fill="black"
    />
    <path
      d="M2.4504 22.9976C1.99662 22.9976 1.6336 23.3603 1.6336 23.8136C1.6336 24.267 1.99662 24.6296 2.4504 24.6296C2.90417 24.6296 3.26716 24.267 3.26716 23.8136C3.26716 23.3603 2.90417 22.9976 2.4504 22.9976Z"
      fill="black"
    />
    <path
      d="M0.8168 22.9976C0.363021 22.9976 0 23.3603 0 23.8136C0 24.267 0.363021 24.6296 0.8168 24.6296C1.27057 24.6296 1.6336 24.267 1.6336 23.8136C1.6336 23.3603 1.27057 22.9976 0.8168 22.9976Z"
      fill="black"
    />
    <path
      d="M2.4504 21.3655C1.99662 21.3655 1.6336 21.7282 1.6336 22.1815C1.6336 22.6349 1.99662 22.9976 2.4504 22.9976C2.90417 22.9976 3.26716 22.6349 3.26716 22.1815C3.26716 21.7282 2.90417 21.3655 2.4504 21.3655Z"
      fill="black"
    />
    <path
      d="M0.8168 21.3655C0.363021 21.3655 0 21.7282 0 22.1815C0 22.6349 0.363021 22.9976 0.8168 22.9976C1.27057 22.9976 1.6336 22.6349 1.6336 22.1815C1.6336 21.7282 1.27057 21.3655 0.8168 21.3655Z"
      fill="black"
    />
    <path
      d="M2.4504 19.7334C1.99662 19.7334 1.6336 20.0961 1.6336 20.5495C1.6336 21.0028 1.99662 21.3655 2.4504 21.3655C2.90417 21.3655 3.26716 21.0028 3.26716 20.5495C3.26716 20.0961 2.90417 19.7334 2.4504 19.7334Z"
      fill="black"
    />
    <path
      d="M0.8168 19.7334C0.363021 19.7334 0 20.0961 0 20.5495C0 21.0028 0.363021 21.3655 0.8168 21.3655C1.27057 21.3655 1.6336 21.0028 1.6336 20.5495C1.6336 20.0961 1.27057 19.7334 0.8168 19.7334Z"
      fill="black"
    />
    <path
      d="M2.4504 18.1014C1.99662 18.1014 1.6336 18.4641 1.6336 18.9174C1.6336 19.3708 1.99662 19.7334 2.4504 19.7334C2.90417 19.7334 3.26716 19.3708 3.26716 18.9174C3.26716 18.4641 2.90417 18.1014 2.4504 18.1014Z"
      fill="black"
    />
    <path
      d="M0.8168 18.1014C0.363021 18.1014 0 18.4641 0 18.9174C0 19.3708 0.363021 19.7334 0.8168 19.7334C1.27057 19.7334 1.6336 19.3708 1.6336 18.9174C1.6336 18.4641 1.27057 18.1014 0.8168 18.1014Z"
      fill="black"
    />
    <path
      d="M2.4504 16.4694C1.99662 16.4694 1.6336 16.832 1.6336 17.2854C1.6336 17.7387 1.99662 18.1014 2.4504 18.1014C2.90417 18.1014 3.26716 17.7387 3.26716 17.2854C3.26716 16.832 2.90417 16.4694 2.4504 16.4694Z"
      fill="black"
    />
    <path
      d="M0.8168 16.4694C0.363021 16.4694 0 16.832 0 17.2854C0 17.7387 0.363021 18.1014 0.8168 18.1014C1.27057 18.1014 1.6336 17.7387 1.6336 17.2854C1.6336 16.832 1.27057 16.4694 0.8168 16.4694Z"
      fill="black"
    />
    <path
      d="M2.4504 14.8373C1.99662 14.8373 1.6336 15.2 1.6336 15.6533C1.6336 16.1066 1.99662 16.4694 2.4504 16.4694C2.90417 16.4694 3.26716 16.1066 3.26716 15.6533C3.26716 15.2 2.90417 14.8373 2.4504 14.8373Z"
      fill="black"
    />
    <path
      d="M0.8168 14.8373C0.363021 14.8373 0 15.2 0 15.6533C0 16.1066 0.363021 16.4694 0.8168 16.4694C1.27057 16.4694 1.6336 16.1066 1.6336 15.6533C1.6336 15.2 1.27057 14.8373 0.8168 14.8373Z"
      fill="black"
    />
    <path
      d="M2.4504 13.2053C1.99662 13.2053 1.6336 13.5679 1.6336 14.0213C1.6336 14.4746 1.99662 14.8373 2.4504 14.8373C2.90417 14.8373 3.26716 14.4746 3.26716 14.0213C3.26716 13.5679 2.90417 13.2053 2.4504 13.2053Z"
      fill="black"
    />
    <path
      d="M0.8168 13.2053C0.363021 13.2053 0 13.5679 0 14.0213C0 14.4746 0.363021 14.8373 0.8168 14.8373C1.27057 14.8373 1.6336 14.4746 1.6336 14.0213C1.6336 13.5679 1.27057 13.2053 0.8168 13.2053Z"
      fill="black"
    />
    <path
      d="M2.4504 11.5732C1.99662 11.5732 1.6336 11.9359 1.6336 12.3892C1.6336 12.8426 1.99662 13.2053 2.4504 13.2053C2.90417 13.2053 3.26716 12.8426 3.26716 12.3892C3.26716 11.9359 2.90417 11.5732 2.4504 11.5732Z"
      fill="black"
    />
    <path
      d="M76.2074 33.207C74.6828 33.207 73.2575 32.8987 71.9329 32.2912C70.6078 31.6837 69.5186 30.8224 68.6837 29.7162V32.7899H63.9825V0.103516H68.7287V13.3866C69.5641 12.3439 70.6349 11.5097 71.9329 10.8842C73.2308 10.2585 74.6372 9.94117 76.1618 9.94117C77.623 9.94117 79.0299 10.2132 80.3733 10.7481C81.7159 11.2831 82.9048 12.0628 83.9306 13.0783C84.9563 14.0938 85.7821 15.3179 86.3903 16.7504C86.9982 18.1831 87.3067 19.7878 87.3067 21.5831C87.3067 23.3784 86.989 24.9832 86.3632 26.4158C85.7365 27.8484 84.9108 29.0633 83.8759 30.0607C82.8506 31.0581 81.6708 31.8379 80.3365 32.3909C79.0207 32.9259 77.6409 33.207 76.2074 33.207ZM75.4906 28.8186C76.4795 28.8186 77.3963 28.6282 78.2671 28.2564C79.1297 27.8847 79.8828 27.3678 80.5274 26.715C81.1715 26.0622 81.6708 25.2825 82.0248 24.3848C82.3788 23.4872 82.5605 22.5352 82.5605 21.5197C82.5605 20.5042 82.3788 19.5612 82.0248 18.6817C81.6708 17.8022 81.1623 17.0315 80.5274 16.3787C79.8828 15.7259 79.1297 15.2091 78.2671 14.8373C77.4055 14.4655 76.4795 14.2752 75.4906 14.2752C74.474 14.2752 73.5388 14.4655 72.6952 14.8555C71.8423 15.2454 71.1067 15.7621 70.4809 16.424C69.8547 17.0768 69.3641 17.8476 69.0014 18.727C68.6474 19.6065 68.4657 20.5404 68.4657 21.5197C68.4657 22.5352 68.6474 23.4781 69.0014 24.3576C69.3554 25.2371 69.8547 26.0078 70.4809 26.6606C71.1067 27.3134 71.8423 27.8393 72.6952 28.2292C73.5388 28.6282 74.474 28.8186 75.4906 28.8186Z"
      fill="black"
    />
    <path
      d="M111.965 32.3728C110.64 31.8197 109.46 31.049 108.426 30.0426C107.4 29.0452 106.565 27.8303 105.939 26.3977C105.312 24.9651 104.995 23.3602 104.995 21.565C104.995 19.7697 105.303 18.1649 105.912 16.7323C106.52 15.2997 107.346 14.0757 108.371 13.0602C109.396 12.0447 110.585 11.274 111.928 10.73C113.272 10.195 114.679 9.92306 116.14 9.92306C117.664 9.92306 119.071 10.2404 120.369 10.866C121.667 11.4916 122.737 12.3258 123.572 13.3685V0.103516H128.319V32.7899H123.618V29.7162C122.783 30.8224 121.703 31.6746 120.369 32.2912C119.044 32.8987 117.619 33.207 116.094 33.207C114.669 33.207 113.29 32.9259 111.965 32.3728ZM119.616 28.2383C120.469 27.8484 121.204 27.3316 121.83 26.6697C122.456 26.0168 122.947 25.2462 123.309 24.3667C123.663 23.4872 123.845 22.5442 123.845 21.5288C123.845 20.5404 123.663 19.6156 123.309 18.7361C122.955 17.8566 122.456 17.0859 121.83 16.4331C121.204 15.7802 120.469 15.2544 119.616 14.8645C118.763 14.4746 117.837 14.2843 116.82 14.2843C115.831 14.2843 114.914 14.4746 114.043 14.8464C113.181 15.2181 112.428 15.7349 111.783 16.3877C111.139 17.0406 110.64 17.8113 110.286 18.6908C109.932 19.5703 109.75 20.5223 109.75 21.5288C109.75 22.5442 109.932 23.4963 110.286 24.3939C110.64 25.2915 111.148 26.0622 111.783 26.7241C112.428 27.3769 113.181 27.8937 114.043 28.2655C114.905 28.6372 115.831 28.8277 116.82 28.8277C117.837 28.8185 118.772 28.6282 119.616 28.2383Z"
      fill="black"
    />
    <path
      d="M90.5738 9.94117H95.366V13.3866C95.9646 12.2804 96.7724 11.4281 97.789 10.8388C98.8055 10.2404 100.057 9.94117 101.555 9.94117H104.196V14.5109H100.838C98.8685 14.5109 97.4713 15.0459 96.6455 16.1248C95.8198 17.1947 95.411 18.8993 95.411 21.2295L95.4386 32.799H90.5738V9.94117Z"
      fill="black"
    />
    <path
      d="M143.166 14.8917C146.815 14.8917 149.783 17.8566 149.783 21.5015V32.7808H154.738V21.5015C154.738 15.1184 149.556 9.94117 143.166 9.94117C136.777 9.94117 131.595 15.1184 131.595 21.5015V32.7899H136.551V21.5015C136.551 17.8566 139.518 14.8917 143.166 14.8917Z"
      fill="black"
    />
    <path
      d="M59.817 16.9862C59.2179 15.5717 58.4009 14.3386 57.3756 13.2869C56.3411 12.2442 55.1246 11.4191 53.7182 10.8207C52.3113 10.2222 50.8051 9.92306 49.1894 9.92306C47.5738 9.92306 46.0676 10.2222 44.6607 10.8207C43.2541 11.4191 42.0198 12.2442 40.9578 13.2869C39.8959 14.3296 39.0702 15.5627 38.4712 16.9862C37.8722 18.4097 37.5727 19.9329 37.5727 21.5831C37.5727 23.2243 37.8722 24.7565 38.4712 26.1801C39.0702 27.6036 39.8871 28.8277 40.9126 29.8522C41.9472 30.8858 43.1542 31.6928 44.5428 32.2912C45.9311 32.8896 47.4382 33.1889 49.0533 33.1889C50.5508 33.1889 51.8851 32.8987 53.0648 32.3184C54.2446 31.7381 55.2244 30.9221 56.0051 29.8794V32.799H60.7154V21.5831C60.7154 19.9329 60.4161 18.4097 59.817 16.9862ZM55.4965 24.3848C55.143 25.2825 54.6349 26.0531 53.9995 26.715C53.3549 27.3678 52.6018 27.8847 51.7398 28.2564C50.8777 28.6282 49.9517 28.8186 48.9623 28.8186C47.9457 28.8186 47.0115 28.6282 46.1674 28.2383C45.3142 27.8484 44.5789 27.3316 43.9528 26.6697C43.3266 26.0169 42.8363 25.2462 42.4736 24.3667C42.1196 23.4872 41.938 22.5443 41.938 21.5288C41.938 20.5404 42.1196 19.6156 42.4736 18.7361C42.8275 17.8566 43.3266 17.0859 43.9528 16.4331C44.5789 15.7803 45.3142 15.2544 46.1674 14.8645C47.0202 14.4746 47.9457 14.2843 48.9623 14.2843C49.9517 14.2843 50.8685 14.4746 51.7398 14.8464C52.6018 15.2181 53.3549 15.735 53.9995 16.3877C54.6437 17.0406 55.143 17.8112 55.4965 18.6908C55.8506 19.5703 56.0322 20.5223 56.0322 21.5288C56.0322 22.5352 55.8506 23.4872 55.4965 24.3848Z"
      fill="black"
    />
  </svg>
)

export default AberdnLogoSvg
