export const Pin = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 21L6 26" fill="none" />
    <path
      d="M19.2929 3.70711L11.5 11.5C11.5 11.5 8.03202 9.766 4.32518 12.7563C4.21572 12.8444 4.12602 12.9546 4.06191 13.0796C3.99781 13.2047 3.96073 13.3418 3.95308 13.4821C3.94544 13.6225 3.9674 13.7628 4.01755 13.8941C4.06769 14.0254 4.14489 14.1446 4.24413 14.2441L17.7312 27.7312C17.8322 27.8324 17.9538 27.9108 18.0877 27.961C18.2216 28.0113 18.3647 28.0322 18.5074 28.0223C18.6501 28.0124 18.789 27.9721 18.9147 27.9039C19.0405 27.8358 19.1501 27.7414 19.2362 27.6272C20.2854 26.2327 21.9332 23.3664 20.5 20.5L28.2929 12.7071C28.4805 12.5196 28.5858 12.2652 28.5858 12C28.5858 11.7348 28.4805 11.4804 28.2929 11.2929L20.7071 3.70711C20.5196 3.51957 20.2652 3.41421 20 3.41421C19.7348 3.41421 19.4805 3.51957 19.2929 3.70711V3.70711Z"
      fill="none"
    />
  </svg>
)

export default Pin
