import { PasswordValidationRuleReference } from "../../../api/lib/auth/models/ValidatePasswordResponse"
import { PasswordErrorKey } from "./validatePassword"

/**
 *
 * @param validationResult This is the enum key that is used in the back end.
 * @returns The enum key that is used in the front end.
 */
export const getPasswordErrorKey = (
  validationResult: PasswordValidationRuleReference
) => {
  switch (validationResult) {
    case "TooShort":
      return PasswordErrorKey.TOO_SHORT
    case "NoCommonWords":
      return PasswordErrorKey.COMMON_WORD
    case "NoRecurringValues":
      return PasswordErrorKey.RECURRING_VALUES
    case "NoSequences":
      return PasswordErrorKey.SEQUENCES
    case "NoKeyboardPatterns":
      return PasswordErrorKey.KEYBOARD_PATTERNS
    case "IncludesUppercase":
      return PasswordErrorKey.NO_UPPERCASE
    case "IncludesLowercase":
      return PasswordErrorKey.NO_LOWERCASE
    case "IncludesNumbers":
      return PasswordErrorKey.NO_NUMBER
    case "NotBreached":
      return PasswordErrorKey.PREVIOUSLY_BREACHED
    case "NotUsed":
      return PasswordErrorKey.PREVIOUSLY_USED
    default:
      return PasswordErrorKey.UNKNOWN_ERROR
  }
}
