import { IUser } from "../../api/api-client/api-types"

export const searchClients = ({
  expression,
  clients,
  wasOnUser,
}: {
  expression: string
  clients?: IUser[] | undefined
  wasOnUser: boolean
}) => {
  if (!wasOnUser) {
    if (clients?.length === 0) return [] as IUser[]

    const foundClients = [] as IUser[]

    clients?.forEach((client) => {
      const fullName = `${client.firstName} ${client.surname}`
      const firstName = client.firstName?.toLowerCase()
      const surname = client.surname?.toLowerCase()

      if (
        fullName.toLowerCase().includes(expression.toLowerCase()) &&
        (firstName?.startsWith(expression.toLowerCase()) ||
          surname?.startsWith(expression.toLowerCase()))
      ) {
        foundClients.push(client)
      }
    })

    return foundClients
  }

  return []
}
