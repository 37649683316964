import { ReactElement } from "react"

import FolderIconsFinances from "./FolderIconsFinances"
import FolderIconsHomeProperty from "../oneX/FolderIconsHomeProperty"
import FolderIconsLegacyPlanning from "./FolderIconsLegacyPlanning"
import FolderIconsLegal from "./FolderIconsLegal"
import FolderIconsPersonalFamily from "./FolderIconsPersonalFamily"
import FolderIconsPropertyVehicles from "./FolderIconsPropertyVehicles"
import FolderIconsSavingsInvestments from "./FolderIconsSavingsInvestments"
import FolderIconsVehicles from "../oneX/FolderIconsVehicles"

export const GetIcon = {
  FolderIconsFinances: <FolderIconsFinances />,
  FolderIconsHomeProperty: <FolderIconsHomeProperty />,
  FolderIconsLegacyPlanning: <FolderIconsLegacyPlanning />,
  FolderIconsLegal: <FolderIconsLegal />,
  FolderIconsPersonalFamily: <FolderIconsPersonalFamily />,
  FolderIconsPropertyVehicles: <FolderIconsPropertyVehicles />,
  FolderIconsSavingsInvestments: <FolderIconsSavingsInvestments />,
  FolderIconsVehicles: <FolderIconsVehicles />,
} as { [key: string]: ReactElement }

export default GetIcon
