import { createContext, FC, useReducer, Dispatch, useContext } from "react"
import { userReducer, initialState, IToastState, IToastAction } from "./reducer"

export const ToastContext = createContext(
  {} as {
    toastState: IToastState
    dispatch: Dispatch<IToastAction>
  }
)

export const ToastProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [toastState, dispatch] = useReducer(userReducer, initialState)

  const value = { toastState, dispatch }
  return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
}

export const useToastContext = () => useContext(ToastContext)

export * from "./actions"
export * from "./constants"
