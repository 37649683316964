export const PoweredByLegado = () => {
  return (
    <svg
      width="175"
      height="25"
      viewBox="0 0 175 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M113.918 15.281L113.03 19.19H110.416C109.016 19.19 106.177 19.165 105.289 19.165C104.375 19.165 101.177 19.216 100.644 19.216V18.861L101.482 18.709C102.65 18.509 102.882 17.922 102.882 15.109V6.909C102.882 4.091 102.682 3.482 101.482 3.309L100.644 3.157V2.79199C101.152 2.79199 103.462 2.84299 104.35 2.84299C105.238 2.84299 107.878 2.79199 108.335 2.79199V3.147L107.472 3.299C105.949 3.553 105.672 3.90799 105.672 6.89899V15.555C105.672 18.093 106.053 18.555 108.21 18.555H108.997C110.952 18.555 111.688 18.149 112.779 16.372L113.439 15.281H113.918Z"
        fill="#767585"
      />
      <path
        d="M117.143 12.4143V12.5923C117.219 15.8663 118.996 17.8723 121.382 17.8723C121.988 17.8763 122.588 17.7491 123.141 17.4993C123.693 17.2495 124.185 16.8831 124.582 16.4253L124.882 16.6533C124.359 17.4918 123.634 18.1863 122.774 18.6739C121.915 19.1614 120.947 19.4266 119.958 19.4453C116.912 19.4453 114.653 17.3643 114.653 13.8103C114.612 13.023 114.726 12.2354 114.99 11.4925C115.254 10.7496 115.661 10.0659 116.189 9.48074C116.718 8.89555 117.356 8.42027 118.068 8.08215C118.78 7.74402 119.552 7.54968 120.339 7.51025C123.439 7.51025 124.908 9.23626 124.984 12.4103L117.143 12.4143ZM122.169 11.7543C122.093 9.34326 121.23 8.12426 119.859 8.12426C118.311 8.12426 117.295 9.46926 117.168 11.7543H122.169Z"
        fill="#767585"
      />
      <path
        d="M137.627 9.34226H135.139C135.484 9.87828 135.661 10.5051 135.647 11.1423C135.647 13.6553 133.21 15.0513 130.799 15.0513C130.287 15.0481 129.776 14.9884 129.276 14.8733C128.565 15.3553 128.388 15.6603 128.388 15.9903C128.388 16.3453 128.616 16.5233 129.835 16.5993L133.592 16.8533C135.851 17.0313 136.992 17.8433 136.992 19.3413C136.992 22.3113 133.992 24.3413 130.316 24.3413C127.727 24.3413 125.798 23.4023 125.798 21.6003C125.798 20.6103 126.509 19.7003 127.981 18.6563C127.067 18.4563 126.509 17.8943 126.509 17.1843C126.509 16.3973 127.347 15.5343 128.768 14.7223C128.057 14.5368 127.427 14.1235 126.973 13.5456C126.519 12.9677 126.267 12.257 126.255 11.5223C126.255 9.06026 128.616 7.56226 131.155 7.56226C132.446 7.48826 133.713 7.92588 134.683 8.78026L137.683 7.71426V9.33926L137.627 9.34226ZM132.322 19.0423C131.535 18.9663 130.063 18.8643 128.667 18.8143C128.408 19.0361 128.201 19.3125 128.06 19.6236C127.92 19.9347 127.85 20.273 127.855 20.6143C127.855 22.3653 129.455 23.5583 131.688 23.5583C133.871 23.5583 135.622 22.2383 135.622 20.6903C135.622 19.6753 134.911 19.2183 132.322 19.0403V19.0423ZM130.922 8.02626C129.374 8.02626 128.764 9.32126 128.764 11.1263C128.764 13.3603 129.373 14.5783 130.998 14.5783C132.546 14.5783 133.13 13.2833 133.13 11.4783C133.108 9.24026 132.498 8.02226 130.925 8.02226L130.922 8.02626Z"
        fill="#767585"
      />
      <path
        d="M148.516 18.8865C147.84 19.1649 147.115 19.303 146.384 19.2924C145.886 19.3278 145.393 19.1715 145.006 18.8555C144.62 18.5395 144.368 18.0877 144.303 17.5925C143.834 18.1276 143.262 18.5628 142.621 18.8721C141.98 19.1813 141.283 19.3583 140.572 19.3925C140.221 19.4207 139.868 19.3749 139.536 19.2581C139.204 19.1413 138.9 18.9561 138.644 18.7144C138.388 18.4727 138.186 18.1799 138.05 17.855C137.914 17.5302 137.848 17.1804 137.856 16.8285C137.856 15.2545 138.821 14.4935 141.486 13.5795C142.349 13.3005 143.364 12.9955 144.278 12.7924V11.2715C144.278 9.13945 143.542 8.37745 142.273 8.37745C141.911 8.37393 141.553 8.46126 141.232 8.63145C140.953 10.8145 140.547 11.5755 139.658 11.5755C139.495 11.5921 139.33 11.5731 139.175 11.5198C139.02 11.4665 138.879 11.3801 138.76 11.2666C138.642 11.1531 138.55 11.0153 138.49 10.8626C138.43 10.7099 138.404 10.5461 138.414 10.3825C138.414 9.39245 140.673 7.56445 143.389 7.56445C146.003 7.56445 146.943 8.68145 146.943 11.1185V17.2654C146.943 18.2554 147.197 18.5854 147.831 18.5854C148.026 18.5883 148.221 18.5799 148.415 18.5605L148.516 18.8865ZM144.277 13.3525C143.51 13.5514 142.756 13.7971 142.018 14.0885C140.927 14.5965 140.318 15.2304 140.318 16.1694C140.304 16.4176 140.343 16.666 140.433 16.8978C140.523 17.1295 140.661 17.3392 140.84 17.5126C141.018 17.686 141.231 17.8191 141.465 17.9027C141.699 17.9864 141.949 18.0187 142.196 17.9975C142.967 17.9632 143.699 17.6472 144.252 17.1095V13.3525H144.277Z"
        fill="#767585"
      />
      <path
        d="M161.715 19.1902C160.725 19.1902 158.644 19.1902 157.654 19.2412L157.454 17.7412C157.023 18.2494 156.487 18.6579 155.883 18.9385C155.279 19.219 154.621 19.365 153.954 19.3662C151.187 19.3662 149.283 17.1322 149.283 14.1122C149.283 10.0512 151.898 7.66521 155.451 7.66521C156.117 7.6568 156.781 7.7239 157.431 7.86522V5.20122C157.431 3.95722 157.177 3.55122 156.289 3.12022L155.705 2.84122V2.58722L159.792 1.69922L160.092 1.89922V15.9662C160.092 18.3012 160.292 18.6062 161.285 18.7582L161.691 18.8342V19.1892L161.715 19.1902ZM157.425 11.5502C157.425 9.31622 156.41 8.25021 154.887 8.25021C153.034 8.25021 151.841 9.75022 151.841 12.5652C151.841 15.7652 153.313 17.8962 155.496 17.8962C156.185 17.9151 156.858 17.6902 157.396 17.2612V11.5502H157.425Z"
        fill="#767585"
      />
      <path
        d="M168.391 19.444C165.066 19.444 162.604 17.337 162.604 13.403C162.575 12.6282 162.704 11.8555 162.985 11.1327C163.266 10.4099 163.692 9.75234 164.236 9.20052C164.781 8.64869 165.433 8.21434 166.152 7.92427C166.871 7.6342 167.642 7.49457 168.417 7.51399C171.717 7.51399 174.204 9.62099 174.204 13.555C174.232 14.3293 174.1 15.1009 173.819 15.8227C173.537 16.5445 173.112 17.2012 172.567 17.7526C172.023 18.304 171.372 18.7384 170.654 19.0292C169.936 19.3201 169.166 19.4612 168.391 19.444ZM168.091 8.09799C166.466 8.09799 165.527 9.34198 165.527 12.718C165.527 16.424 166.72 18.835 168.7 18.835C170.35 18.835 171.264 17.591 171.264 14.215C171.26 10.514 170.092 8.09799 168.087 8.09799H168.091Z"
        fill="#767585"
      />
      <path
        d="M84.3722 15.84C81.4246 15.8366 78.5987 14.6641 76.5144 12.5799C74.4301 10.4956 73.2577 7.66967 73.2542 4.72205C73.2579 3.62963 73.4206 2.54358 73.7372 1.49805C72.1074 2.42769 70.7336 3.74717 69.739 5.33825C68.7445 6.92933 68.1602 8.7423 68.0385 10.6147C67.9169 12.4871 68.2616 14.3604 69.0419 16.0669C69.8223 17.7733 71.0137 19.2594 72.5096 20.3921C74.0054 21.5249 75.759 22.2688 77.613 22.5573C79.4671 22.8457 81.3637 22.6697 83.133 22.045C84.9023 21.4202 86.489 20.3663 87.7508 18.9776C89.0127 17.589 89.9103 15.9089 90.3632 14.088C88.5712 15.225 86.4944 15.8323 84.3722 15.84Z"
        fill="#767585"
      />
      <path
        d="M84.3722 11.27C85.5547 11.2678 86.7146 10.9458 87.729 10.3381C88.7434 9.7304 89.5745 8.85961 90.1342 7.818C89.4153 5.61661 88.0403 3.68777 86.1937 2.29032C84.347 0.892878 82.1171 0.0936873 79.8032 0C79.1734 0.610821 78.6717 1.34109 78.3274 2.14809C77.9832 2.9551 77.8032 3.82264 77.7982 4.7C77.804 6.44138 78.4986 8.10972 79.7304 9.34069C80.9621 10.5717 82.6308 11.2653 84.3722 11.27Z"
        fill="#767585"
      />
      <path
        d="M2.11523 13.5459V16.8857H0.990234V8.35449H4.13672C5.07031 8.35449 5.80078 8.59277 6.32812 9.06934C6.85938 9.5459 7.125 10.1768 7.125 10.9619C7.125 11.79 6.86523 12.4287 6.3457 12.8779C5.83008 13.3232 5.08984 13.5459 4.125 13.5459H2.11523ZM2.11523 12.626H4.13672C4.73828 12.626 5.19922 12.4854 5.51953 12.2041C5.83984 11.9189 6 11.5088 6 10.9736C6 10.4658 5.83984 10.0596 5.51953 9.75488C5.19922 9.4502 4.75977 9.29199 4.20117 9.28027H2.11523V12.626Z"
        fill="#767585"
      />
      <path
        d="M7.90148 13.6572C7.90148 13.0361 8.02258 12.4775 8.26477 11.9814C8.51086 11.4854 8.8507 11.1025 9.2843 10.833C9.7218 10.5635 10.2198 10.4287 10.7784 10.4287C11.6417 10.4287 12.339 10.7275 12.8702 11.3252C13.4054 11.9229 13.673 12.7178 13.673 13.71V13.7861C13.673 14.4033 13.5538 14.958 13.3155 15.4502C13.0812 15.9385 12.7433 16.3193 12.3019 16.5928C11.8644 16.8662 11.3605 17.0029 10.7902 17.0029C9.93078 17.0029 9.23352 16.7041 8.69836 16.1064C8.16711 15.5088 7.90148 14.7178 7.90148 13.7334V13.6572ZM8.99133 13.7861C8.99133 14.4893 9.15344 15.0537 9.47766 15.4795C9.80578 15.9053 10.2433 16.1182 10.7902 16.1182C11.3409 16.1182 11.7784 15.9033 12.1027 15.4736C12.4269 15.04 12.589 14.4346 12.589 13.6572C12.589 12.9619 12.423 12.3994 12.0909 11.9697C11.7628 11.5361 11.3253 11.3193 10.7784 11.3193C10.2433 11.3193 9.81164 11.5322 9.48352 11.958C9.15539 12.3838 8.99133 12.9932 8.99133 13.7861Z"
        fill="#767585"
      />
      <path
        d="M20.5256 15.3916L21.7444 10.5459H22.8284L20.9827 16.8857H20.1038L18.5627 12.0811L17.0627 16.8857H16.1838L14.344 10.5459H15.4221L16.6702 15.292L18.1467 10.5459H19.0198L20.5256 15.3916Z"
        fill="#767585"
      />
      <path
        d="M26.4466 17.0029C25.5873 17.0029 24.888 16.7217 24.349 16.1592C23.8099 15.5928 23.5404 14.8369 23.5404 13.8916V13.6924C23.5404 13.0635 23.6595 12.5029 23.8978 12.0107C24.14 11.5146 24.4759 11.1279 24.9056 10.8506C25.3392 10.5693 25.808 10.4287 26.3119 10.4287C27.1361 10.4287 27.7767 10.7002 28.2337 11.2432C28.6908 11.7861 28.9193 12.5635 28.9193 13.5752V14.0264H24.6244C24.64 14.6514 24.8216 15.1572 25.1693 15.5439C25.5209 15.9268 25.9662 16.1182 26.5052 16.1182C26.888 16.1182 27.2123 16.04 27.4779 15.8838C27.7435 15.7275 27.9759 15.5205 28.1752 15.2627L28.8373 15.7783C28.306 16.5947 27.5091 17.0029 26.4466 17.0029ZM26.3119 11.3193C25.8744 11.3193 25.5072 11.4795 25.2103 11.7998C24.9134 12.1162 24.7298 12.5615 24.6595 13.1357H27.8353V13.0537C27.8041 12.5029 27.6556 12.0771 27.39 11.7764C27.1244 11.4717 26.765 11.3193 26.3119 11.3193Z"
        fill="#767585"
      />
      <path
        d="M33.1235 11.5186C32.9595 11.4912 32.7817 11.4775 32.5903 11.4775C31.8794 11.4775 31.397 11.7803 31.143 12.3857V16.8857H30.0591V10.5459H31.1138L31.1313 11.2783C31.4868 10.7119 31.9907 10.4287 32.643 10.4287C32.854 10.4287 33.0141 10.4561 33.1235 10.5107V11.5186Z"
        fill="#767585"
      />
      <path
        d="M36.5191 17.0029C35.6598 17.0029 34.9605 16.7217 34.4215 16.1592C33.8824 15.5928 33.6129 14.8369 33.6129 13.8916V13.6924C33.6129 13.0635 33.732 12.5029 33.9703 12.0107C34.2125 11.5146 34.5484 11.1279 34.9781 10.8506C35.4117 10.5693 35.8805 10.4287 36.3844 10.4287C37.2086 10.4287 37.8492 10.7002 38.3063 11.2432C38.7633 11.7861 38.9918 12.5635 38.9918 13.5752V14.0264H34.6969C34.7125 14.6514 34.8941 15.1572 35.2418 15.5439C35.5934 15.9268 36.0387 16.1182 36.5777 16.1182C36.9605 16.1182 37.2848 16.04 37.5504 15.8838C37.816 15.7275 38.0484 15.5205 38.2477 15.2627L38.9098 15.7783C38.3785 16.5947 37.5816 17.0029 36.5191 17.0029ZM36.3844 11.3193C35.9469 11.3193 35.5797 11.4795 35.2828 11.7998C34.9859 12.1162 34.8023 12.5615 34.732 13.1357H37.9078V13.0537C37.8766 12.5029 37.7281 12.0771 37.4625 11.7764C37.1969 11.4717 36.8375 11.3193 36.3844 11.3193Z"
        fill="#767585"
      />
      <path
        d="M39.8679 13.6631C39.8679 12.6904 40.0984 11.9092 40.5593 11.3193C41.0202 10.7256 41.6237 10.4287 42.3698 10.4287C43.112 10.4287 43.6999 10.6826 44.1335 11.1904V7.88574H45.2175V16.8857H44.2214L44.1687 16.2061C43.7351 16.7373 43.1316 17.0029 42.3581 17.0029C41.6237 17.0029 41.0241 16.7021 40.5593 16.1006C40.0984 15.499 39.8679 14.7139 39.8679 13.7451V13.6631ZM40.9519 13.7861C40.9519 14.5049 41.1003 15.0674 41.3972 15.4736C41.6941 15.8799 42.1042 16.083 42.6277 16.083C43.3152 16.083 43.8171 15.7744 44.1335 15.1572V12.2451C43.8093 11.6475 43.3112 11.3486 42.6394 11.3486C42.1081 11.3486 41.6941 11.5537 41.3972 11.9639C41.1003 12.374 40.9519 12.9814 40.9519 13.7861Z"
        fill="#767585"
      />
      <path
        d="M55.0088 13.7861C55.0088 14.7549 54.7861 15.5342 54.3408 16.124C53.8955 16.71 53.2978 17.0029 52.5478 17.0029C51.747 17.0029 51.1279 16.7197 50.6904 16.1533L50.6377 16.8857H49.6416V7.88574H50.7255V11.2432C51.163 10.7002 51.7666 10.4287 52.5361 10.4287C53.3056 10.4287 53.9091 10.7197 54.3466 11.3018C54.788 11.8838 55.0088 12.6807 55.0088 13.6924V13.7861ZM53.9248 13.6631C53.9248 12.9248 53.7822 12.3545 53.497 11.9521C53.2119 11.5498 52.8017 11.3486 52.2666 11.3486C51.5517 11.3486 51.038 11.6807 50.7255 12.3447V15.0869C51.0576 15.751 51.5752 16.083 52.2783 16.083C52.7978 16.083 53.2021 15.8818 53.4912 15.4795C53.7802 15.0771 53.9248 14.4717 53.9248 13.6631Z"
        fill="#767585"
      />
      <path
        d="M58.2637 15.2979L59.7403 10.5459H60.9005L58.3516 17.8643C57.9571 18.9189 57.3302 19.4463 56.4708 19.4463L56.2657 19.4287L55.8614 19.3525V18.4736L56.1544 18.4971C56.5216 18.4971 56.8067 18.4229 57.0098 18.2744C57.2169 18.126 57.3868 17.8545 57.5196 17.46L57.7598 16.8154L55.4981 10.5459H56.6817L58.2637 15.2979Z"
        fill="#767585"
      />
    </svg>
  )
}
