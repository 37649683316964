import { TTheme } from '../types'
import animation from '../tokens/scottishWidows/animation.json'
import breakpoints from "../tokens/scottishWidows/breakpoints.json"
import buttons from "../tokens/scottishWidows/buttons.json"
import colors from '../tokens/scottishWidows/colors.json'
import iconsizing from '../tokens/scottishWidows/iconsizing.json'
import overlay from '../tokens/scottishWidows/overlay.json'
import radius from '../tokens/scottishWidows/radius.json'
import shadows from '../tokens/scottishWidows/shadows.json'
import typeface from '../tokens/scottishWidows/typeface.json'
import typography from '../tokens/scottishWidows/typography.json'

const tokens = {
  mode: 'SCOTTISH_WIDOWS',
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  typeface,
  typography,
} as TTheme
export default tokens
