import { FC, MouseEvent } from "react"
import { useGetIcon } from "../../styled-components/GetIconLibraryInTheme"
import { pageString } from "../../utils/helpers"
import classNames from "classnames"

type TDirection = "UP" | "DOWN" | "RIGHT" | "LEFT"

export const ButtonChevronUpDownRightOrLeft: FC<{
  direction: TDirection
  onClick: (event: MouseEvent) => void
  size?: "l" | "m"
  disabled?: boolean
  title: string
  role?: string //if the button is acting as a link pass role="link" together with href
  href?: string
  className?: string
}> = ({ direction, onClick, size, disabled, title, role, href, className }) => {
  const getIconName = () => {
    switch (direction) {
      case "UP":
        return "IconChevronUp"
      case "DOWN":
        return "IconChevronDown"
      case "RIGHT":
        return "IconChevronRight"
      default:
        return "IconChevronLeft"
    }
  }

  const icon = useGetIcon(getIconName())

  const buttonClass = classNames("click-me icon-box", className, {
    "icon-l": size === "l",
    "icon-m": size === "m",
    disabled: disabled,
  })

  return (
    <button
      data-testid={pageString() + "-sections-chevron-" + title + "-button"}
      className={buttonClass}
      title={title}
      onClick={(event) => {
        event.stopPropagation()
        if (onClick && !disabled) {
          onClick(event)
        }
      }}
      disabled={disabled}
      role={role}
      data-href={href}
    >
      {icon}
    </button>
  )
}
