import { FC } from "react"
import { IFile } from "../../../../../../api/api-client/api-types"
import { CaseDocument } from "../../../../../../api/lib/workflow/models/GetCaseDocumentResponse"
import { useGetIcon } from "../../../../../../styled-components/GetIconLibraryInTheme"
import { UploadWizardSteps } from "../../../UploadFileToCaseModalWizard"
import { ViewUploadedDocument } from "../ViewUploadedDocument/ViewUploadedDocument"
import "./ViewUploadedDocumentsModal.css"

export interface ViewUploadedDocumentsModalProps {
  requestIdFromCategory?: number
  uploadedDocuments: CaseDocument[]
  setCurrentStep: (step: UploadWizardSteps) => void
  setDocumentsToUpload: (documents: CaseDocument[]) => void
  setFileSelectedForPreview: (file: IFile) => void
  setUnsavedChanges?: (unsavedChanges: boolean) => void
  children: JSX.Element
}

export const ViewUploadedDocumentsModal: FC<
  ViewUploadedDocumentsModalProps
> = ({
  requestIdFromCategory,
  uploadedDocuments,
  setCurrentStep,
  setDocumentsToUpload,
  setFileSelectedForPreview,
  setUnsavedChanges,
  children,
}) => {
  const infoIcon = useGetIcon("Info")

  return (
    <>
      {children}
      {uploadedDocuments.length > 0 && (
        <>
          <hr className="upload-documents-divider" />
          <h2 className="mb-5">{infoIcon} Uploaded documents</h2>
          <div className="documents-uploaded">
            {uploadedDocuments.map((uploadedDocument) => (
              <div key={uploadedDocument.file?.id}>
                <ViewUploadedDocument
                  name={
                    uploadedDocument.name !== null ? uploadedDocument.name : ""
                  }
                  category={{
                    stageId: uploadedDocument.stageId.toString(),
                    requestId: uploadedDocument.requestId.toString(),
                    name:
                      uploadedDocument.requestName !== null
                        ? uploadedDocument.requestName
                        : "",
                  }}
                  setCurrentStep={setCurrentStep}
                  file={uploadedDocument.file!}
                  setFileSelectedForPreview={setFileSelectedForPreview}
                  setDocumentsToUpload={setDocumentsToUpload}
                  document={uploadedDocument}
                  setUnsavedChanges={setUnsavedChanges}
                  requestIdFromCategory={requestIdFromCategory}
                />
                <hr style={{ margin: "40px 0px" }} />
              </div>
            ))}
          </div>
        </>
      )}
    </>
  )
}
