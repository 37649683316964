import { Provider } from "legado-generated-api-client/models";
const providers = require("../staticData/getProviders.json");

type TGetProviders = () => Provider[];

const isProviderDtos = (providers: any) => {
  if ((providers as Provider[])?.length) {
    return providers as Provider[];
  }
};

export const getProvidersFromLocalData: TGetProviders = () => {
  return isProviderDtos(providers) || [];
};
