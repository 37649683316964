import { TDesignSystemTheme } from "../../types"
import colorTokens from "./definitions/colors.json"
import shadowTokens from "./definitions/shadows.json"
import boxStylingTokens from "./definitions/boxStyles.json"
import typographyTokens from "./definitions/typography.json"
import typeface from "./definitions/typeface.json"

export const definitionTokens: TDesignSystemTheme = {
  mode: "CO_OP",
  colors: {
    "#F3F9FD": colorTokens["#F3F9FD"].value,
    "#EEF3FC": colorTokens["#EEF3FC"].value,
    "#EAF1FF": colorTokens["#EAF1FF"].value,
    "#EBFFFF": colorTokens["#EBFFFF"].value,
    "#EAF5DA": colorTokens["#EAF5DA"].value,
    "#FFD6C9": colorTokens["#FFD6C9"].value,
    "#FEE7C1": colorTokens["#FEE7C1"].value,
    "#232323": colorTokens["#232323"].value,
    "#FBE9CE": colorTokens["#FBE9CE"].value,
    "#F8EEC7": colorTokens["#F8EEC7"].value,
    "#FFDED3": colorTokens["#FFDED3"].value,
    "#E04C1C": colorTokens["#E04C1C"].value,
    "#DD4717": colorTokens["#DD4717"].value,
    "#FA825C": colorTokens["#FA825C"].value,
    "#FF7E54": colorTokens["#FF7E54"].value,
    "#EC805E": colorTokens["#EC805E"].value,
    "#FD7838": colorTokens["#FD7838"].value,
    "#749BFF": colorTokens["#749BFF"].value,
    "#519AF5": colorTokens["#519AF5"].value,
    "#D82927": colorTokens["#D82927"].value,
    "#DF1F11": colorTokens["#DF1F11"].value,
    "#00729A": colorTokens["#00729A"].value,
    "#BF7C18": colorTokens["#BF7C18"].value,
    "#ADAEB9": colorTokens["#ADAEB9"].value,
    "#999AA6": colorTokens["#999AA6"].value,
    "#A882B3": colorTokens["#A882B3"].value,
    "#50811B": colorTokens["#50811B"].value,
    "#3A6002": colorTokens["#3A6002"].value,
    "#8D44D8": colorTokens["#8D44D8"].value,
    "#244C8F": colorTokens["#244C8F"].value,
    "#1F4590": colorTokens["#1F4590"].value,
    "#3D3E46": colorTokens["#3D3E46"].value,
    "#323240": colorTokens["#323240"].value,
    "#333333": colorTokens["#333333"].value,
    "#003E55": colorTokens["#003E55"].value,
    "#282828": colorTokens["#282828"].value,
    "#213232": colorTokens["#213232"].value,
    "#00394E": colorTokens["#00394E"].value,
    "#00B1E7": colorTokens["#00B1E7"].value,
    "#6B6C78": colorTokens["#6B6C78"].value,
    "#6E6E6E": colorTokens["#6E6E6E"].value,
    "#C62828": colorTokens["#C62828"].value,
    "#B4201E": colorTokens["#B4201E"].value,
    "#C03D13": colorTokens["#C03D13"].value,
    "#03A644": colorTokens["#03A644"].value,
    "#058D3B": colorTokens["#058D3B"].value,
    "#E2E2E2": colorTokens["#E2E2E2"].value,
    "#D5F3E1": colorTokens["#D5F3E1"].value,
    "#CDEDDA": colorTokens["#CDEDDA"].value,
    "#D8D8D8": colorTokens["#D8D8D8"].value,
    "#F9F9F9": colorTokens["#F9F9F9"].value,
    "#EEA63A": colorTokens["#EEA63A"].value,
    "#DE9527": colorTokens["#DE9527"].value,
    "#909090": colorTokens["#909090"].value,
    "#E7FDEC": colorTokens["#E7FDEC"].value,
    "#FFD75D": colorTokens["#FFD75D"].value,
    "#D7F1ED": colorTokens["#D7F1ED"].value,
    "#E7DBEB": colorTokens["#E7DBEB"].value,
    "#EDC3FF": colorTokens["#EDC3FF"].value,
    "#E5EDFF": colorTokens["#E5EDFF"].value,
    "#DCE6FF": colorTokens["#DCE6FF"].value,
    "#E8E8E8": colorTokens["#E8E8E8"].value,
    "#CFE3FF": colorTokens["#CFE3FF"].value,
    "#F9EDFC": colorTokens["#F9EDFC"].value,
    "#FFFBCD": colorTokens["#FFFBCD"].value,
    "#E57982": colorTokens["#E57982"].value,
    "#CFF9F9": colorTokens["#CFF9F9"].value,
    "#CCF2F7": colorTokens["#CCF2F7"].value,
    "#C7D0E3": colorTokens["#C7D0E3"].value,
    "#D7E3C6": colorTokens["#D7E3C6"].value,
    "#CFD4D9": colorTokens["#CFD4D9"].value,
    "#FFC2AE": colorTokens["#FFC2AE"].value,
    "#FFA98E": colorTokens["#FFA98E"].value,
    "#FF9F81": colorTokens["#FF9F81"].value,
    "#27AAAB": colorTokens["#27AAAB"].value,
    "#91939A": colorTokens["#91939A"].value,
    "#FFEFEA": colorTokens["#FFEFEA"].value,
    "#FDE8E9": colorTokens["#FDE8E9"].value,
    "#FFEBE4": colorTokens["#FFEBE4"].value,
    "#F8E9E9": colorTokens["#F8E9E9"].value,
    "#6FC7B6": colorTokens["#6FC7B6"].value,
    "#11306C": colorTokens["#11306C"].value,
    "#F7FCFB": colorTokens["#F7FCFB"].value,
    "#FCF1F1": colorTokens["#FCF1F1"].value,
    "#FAE4E6": colorTokens["#FAE4E6"].value,
    "#F8E2DB": colorTokens["#F8E2DB"].value,
    "#FFF8E6": colorTokens["#FFF8E6"].value,
    "#FFD1C2": colorTokens["#FFD1C2"].value,
    "#FFD1CE": colorTokens["#FFD1CE"].value,
    "#F1C9C9": colorTokens["#F1C9C9"].value,
    "#E20F14": colorTokens["#E20F14"].value,
    "#F8D156": colorTokens["#F8D156"].value,
    "#F2AC3C": colorTokens["#F2AC3C"].value,
    "#169F50": colorTokens["#169F50"].value,
    "#FFF0EB": colorTokens["#FFF0EB"].value,
    "#C4C4C4": colorTokens["#C4C4C4"].value,
    "#BFBFBF": colorTokens["#BFBFBF"].value,
    "#BCBCBC": colorTokens["#BCBCBC"].value,
    "#566995": colorTokens["#566995"].value,
    "#FCFCFC": colorTokens["#FCFCFC"].value,
    "#FAFAFA": colorTokens["#FAFAFA"].value,
    "#FAFBFB": colorTokens["#FAFBFB"].value,
    "#4483AA": colorTokens["#4483AA"].value,
    "#0F8482": colorTokens["#0F8482"].value,
    "#005878": colorTokens["#005878"].value,
    "#0B6361": colorTokens["#0B6361"].value,
    "#046062": colorTokens["#046062"].value,
    "#FE602D": colorTokens["#FE602D"].value,
    "#F46233": colorTokens["#F46233"].value,
    "#F25A29": colorTokens["#F25A29"].value,
    "#FEFEFE": colorTokens["#FEFEFE"].value,
    "#FFFFFF": colorTokens["#FFFFFF"].value,
    "#FBFFFC": colorTokens["#FBFFFC"].value,
    "#F5F5F5": colorTokens["#F5F5F5"].value,
    "#F2F4F7": colorTokens["#F2F4F7"].value,
    "#F2FAF5": colorTokens["#F2FAF5"].value,
    "#F0F0F0": colorTokens["#F0F0F0"].value,
    "#E6E6E6": colorTokens["#E6E6E6"].value,
  },
  shadows: {
    shadowSoft: shadowTokens.shadows.soft.value,
    borders: shadowTokens.borders[0].value,
  },
  radius: {
    radius5px: boxStylingTokens.radius.small[0].value,
    radius8px: boxStylingTokens.radius.small[1].value,
    radius10px: boxStylingTokens.radius.medium[0].value,
    radius100px: boxStylingTokens.radius.large[0].value,
  },
  borders: {
    border1pxSolid: boxStylingTokens.borders[0].value,
  },

  typeface: {
    fontFamily: typographyTokens.font.fontFamily.value,

    fontSize: typographyTokens.font.fontSize.value,

    fontSizeExtraSmall: typeface.xs.fontSize,
    fontSizeSmall: typeface.s.fontSize,
    fontSizeMedium: typeface.m.fontSize,
    fontSizeLarge: typeface.l.fontSize,
    fontSizeExtraLarge: typeface.xl.fontSize,
    fontSizeExtraExtraLarge: typeface.xxl.fontSize,
    fontSizeExtraExtraExtraLarge: typeface.xxxl.fontSize,
    fontSizeDisplay: typeface.display.fontSize,
    fontSizeDisplayLarge: typeface.displayL.fontSize,
    fontSizeDisplayExtraLarge: typeface.displayXL.fontSize,

    lineHeightExtraSmall: typeface.xs.lineHeight,
    lineHeightSmall: typeface.s.lineHeight,
    lineHeightMedium: typeface.m.lineHeight,
    lineHeightLarge: typeface.l.lineHeight,
    lineHeightExtraLarge: typeface.xl.lineHeight,
    lineHeightExtraExtraLarge: typeface.xxl.lineHeight,
    lineHeightExtraExtraExtraLarge: typeface.xxxl.lineHeight,
    lineHeightDisplay: typeface.display.lineHeight,
    lineHeightDisplayLarge: typeface.displayL.lineHeight,
    lineHeightDisplayExtraLarge: typeface.displayXL.lineHeight,
  },
}
