import { Dispatch, SetStateAction } from "react"
/**
 *
 * @param selectedPageForStitching
 * @param direction
 * @param setOrderedPages
 * @returns
 * This function moves the selected page up or down in the list of pages.
 */
export const moveElement = (
  selectedPageForStitching: HTMLElement,
  direction: number,
  setOrderedPages: Dispatch<SetStateAction<string[]>>
) => {
  const parentContainer = selectedPageForStitching.parentNode as HTMLElement
  const allPagesWithinTheParent = Array.from(
    parentContainer.children
  ) as HTMLElement[]
  const indexOfSelectedPage = allPagesWithinTheParent.indexOf(
    selectedPageForStitching
  )
  const newIndex = indexOfSelectedPage + direction

  if (newIndex < 0 || newIndex >= allPagesWithinTheParent.length) return
  // Apply smooth transition
  allPagesWithinTheParent.forEach((item, idx) => {
    item.style.transform = `translateY(${(idx - indexOfSelectedPage) * 15}%)`
    if (idx === indexOfSelectedPage) {
      item.style.fontWeight = "bold"
    } else {
      item.style.opacity = "0.5"
    }
  })

  setTimeout(() => {
    parentContainer.removeChild(selectedPageForStitching)
    if (direction === 1) {
      parentContainer.insertBefore(
        selectedPageForStitching,
        allPagesWithinTheParent[newIndex].nextSibling
      )
    } else {
      parentContainer.insertBefore(
        selectedPageForStitching,
        allPagesWithinTheParent[newIndex]
      )
    }
    allPagesWithinTheParent.forEach((item) => {
      item.style.transform = ""
    })
    const newOrderOrderedPagesId: string[] = []
    const allPagesForStitching =
      parentContainer.querySelectorAll(".display-document")
    allPagesForStitching.forEach((div) => {
      // get id value from div
      const id = div.id
      if (id) {
        newOrderOrderedPagesId.push(id)
      }
    })
    setOrderedPages(newOrderOrderedPagesId)
    setTimeout(() => {
      allPagesWithinTheParent.forEach((item) => {
        item.style.opacity = ""
        item.style.fontWeight = ""
      })
    }, 300)
  }, 500)
}
