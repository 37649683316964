export enum PasswordErrorKey {
  TOO_SHORT = "Be at least 8 characters long",
  NO_SPACE_AT_START_OR_END = "Do not allow spaces at the start or end of the password",
  // This must come from the BE. Although we check some patterns here, the BE should have a more comprehensive list.
  COMMON_WORD = "Do not allow common words",
  RECURRING_VALUES = "Do not allow 3 or more recurring values (e.g. Password111)",
  SEQUENCES = "Do not allow sequences, abc 123 etc.",
  // This must come from the BE. Although we check some patterns here, the BE should have a more comprehensive list.
  KEYBOARD_PATTERNS = "Do not allow keyboard patterns",
  NO_UPPERCASE = "Password must include at least one uppercase letter",
  NO_LOWERCASE = "Password must include at least one lowercase letter",
  NO_NUMBER = "Password must include at least one number",
  // This must come from the BE.
  PREVIOUSLY_BREACHED = "Do not allow previously breached passwords",
  PREVIOUSLY_USED = "Do not allow previously used passwords",
  UNKNOWN_ERROR = "Unknown error",
}
export type PasswordSetupError = {
  passwordErrorKey: PasswordErrorKey
  message: string
}

/**
 * @param password string to be validated
 * @returns array of error messages
 * Empty array means no errors
 */
export const validatePasswordSetup = (
  password: string
): PasswordSetupError[] => {
  const errors: PasswordSetupError[] = []
  // Check if password is at least 8 characters long
  if (password.length < 8) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.TOO_SHORT,
      message: "Password must be at least 8 characters long",
    })
  }

  // Check if password contains common words
  const commonWords = ["password", "123456", "qwerty", "admin"] // Add more common words if needed
  if (commonWords.some((word) => password.toLowerCase().includes(word))) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.COMMON_WORD,
      message: "Password cannot contain common words",
    })
  }

  // Check if password contains 3 or more recurring values
  if (/(.)\1{2,}/.test(password)) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.RECURRING_VALUES,
      message: "Password cannot contain 3 or more recurring values",
    })
  }

  // Check if password contains sequences
  if (/(abc|bcd|cde|123|234|345)/i.test(password)) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.SEQUENCES,
      message: "Password cannot contain sequences",
    })
  }

  // Check if password contains keyboard patterns
  const keyboardPatterns = ["qwerty", "asdfgh", "zxcvbn"] // Add more keyboard patterns if needed
  if (
    keyboardPatterns.some((pattern) => password.toLowerCase().includes(pattern))
  ) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.KEYBOARD_PATTERNS,
      message: "Password cannot contain keyboard patterns",
    })
  }

  // Check if password includes at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.NO_UPPERCASE,
      message: "Password must include at least one uppercase letter",
    })
  }

  // Check if password includes at least one lowercase letter
  if (!/[a-z]/.test(password)) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.NO_LOWERCASE,
      message: "Password must include at least one lowercase letter",
    })
  }

  // Check if password includes at least one number
  if (!/\d/.test(password)) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.NO_NUMBER,
      message: "Password must include at least one number",
    })
  }
  // Check if password contains spaces at the start or end
  if (/^\s+|\s+$/.test(password)) {
    errors.push({
      passwordErrorKey: PasswordErrorKey.NO_SPACE_AT_START_OR_END,
      message: "Password must not start or end with spaces",
    })
  }

  return errors
}
