import React from "react"
import { v4 as uuidv4 } from "uuid"
import "./TextArea.css"
import classNames from "classnames"

export interface TextAreaInputProps {
  className?: string
  id?: string
  /**
   * Toggles whether the component is disabled or not (preventing user interaction).
   */
  isDisabled?: boolean
  /**
   * Optional text footnote to display below the component.
   */
  footnote?: string
  /**
   * HTML `name` attribute to apply to the component.
   */
  name: string
  placeholder?: string
  /**
   * Label required for accessibility, can be hidden with showLabel (still available for screen readers)
   */
  label: string
  showLabel?: boolean
  /**
   * Optional HTML `value` attribute to apply to the component.
   */
  span?: string
  /**
   * Optional HTML `value` attribute to apply to the component.
   */
  value: string
  onChange: (newValue: string) => void
  /**
   * Determines if the input is required or not
   */
  isRequired?: boolean
  /**
   * Determines if the input has an error or not
   */
  hasError?: boolean
  /**
   * Optional error label to display above the component.
   */
  errorLabel?: string
}

export const TextArea: React.FC<TextAreaInputProps> = ({
  className = "",
  isDisabled = false,
  footnote,
  id = uuidv4(),
  label,
  span,
  name,
  placeholder = "",
  showLabel = true,
  value,
  onChange,
  isRequired = false,
  hasError = false,
  errorLabel,
}) => {
  const labelClassNames = classNames("label", {
    required: isRequired,
    "visually-hidden": !showLabel,
  })

  const inputWrapperClassNames = classNames("input-wrapper", {
    error: hasError,
  })

  return (
    <div className={`text-area ${className}`} data-testid="textarea-container">
      <label
        className={labelClassNames}
        data-testid="textarea-label"
        htmlFor={id}
      >
        {label}
        <span className="fw-300">{span}</span>
      </label>
      {hasError && errorLabel && (
        <label className="error-label" data-testid="textarea-error-label">
          {errorLabel}
        </label>
      )}
      <div className="outer-wrapper">
        <div className={inputWrapperClassNames}>
          <textarea
            className="input-box"
            data-testid="textarea-input"
            disabled={isDisabled}
            id={id}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value)
              }
            }}
          />
        </div>
      </div>
      {footnote && <small data-testid="textarea-footnote">{footnote}</small>}
    </div>
  )
}

TextArea.displayName = "TextArea"
