const PdfNew = () => (
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1231 4.23291C12.1482 4.23291 9.72656 6.66775 9.72656 9.65661V51.309C9.72656 54.2979 12.1482 56.7327 15.1231 56.7327H46.8233C49.7982 56.7327 52.2198 54.298 52.2198 51.309V17.0591C52.2198 17.0591 52.3047 16.0562 51.8714 15.0803C51.4652 14.1655 50.8032 13.5288 50.8032 13.5288C50.8016 13.5266 50.7999 13.5243 50.7983 13.5221L42.9333 5.77111C42.929 5.76668 42.9247 5.76231 42.9202 5.75798C42.9202 5.75798 42.2488 5.11555 41.2534 4.68638C40.0813 4.18107 38.8733 4.23441 38.8733 4.23441L38.8913 4.23273L15.1231 4.23291Z"
      fill="#FF2116"
    />
    <path
      d="M15.1272 6.01495H38.8954C38.9014 6.01501 38.9074 6.01501 38.9134 6.01495C38.9134 6.01495 39.8639 6.02421 40.5591 6.32394C41.2282 6.61242 41.7026 7.04911 41.7057 7.05204C41.7058 7.05205 41.709 7.0554 41.709 7.0554L49.5543 14.7883C49.5543 14.7883 50.027 15.2897 50.2561 15.8057C50.441 16.2221 50.4524 16.9841 50.4524 16.9841C50.4516 16.9967 50.451 17.0092 50.4507 17.0219V51.3095C50.4507 53.3425 48.8508 54.9516 46.8274 54.9516H15.1272C13.1038 54.9516 11.5039 53.3426 11.5039 51.3095V9.65712C11.5039 7.62404 13.1038 6.01502 15.1272 6.01502V6.01495Z"
      fill="#EEEEEE"
    />
    <path
      d="M20.336 35.49C19.116 34.2642 20.436 32.5798 24.0182 30.7911L26.2722 29.6656L27.1503 27.7353C27.6333 26.6736 28.3541 24.9412 28.7521 23.8856L29.4758 21.9663L28.9771 20.5459C28.3639 18.7994 28.1452 16.1749 28.5343 15.2311C29.0613 13.9528 30.7875 14.084 31.4709 15.4542C32.0046 16.5244 31.9501 18.4625 31.3174 20.9067L30.7987 22.9105L31.2556 23.6898C31.5069 24.1183 32.2408 25.136 32.8867 25.9512L34.1005 27.4682L35.6108 27.2702C40.4091 26.6411 42.0525 27.7103 42.0525 29.2433C42.0525 31.178 38.2849 31.3377 35.121 29.1051C34.4091 28.6027 33.9203 28.1037 33.9203 28.1037C33.9203 28.1037 31.9382 28.5091 30.9624 28.7734C29.9551 29.0462 29.4525 29.217 27.977 29.7174C27.977 29.7174 27.4593 30.4728 27.1219 31.0219C25.8668 33.065 24.4013 34.7582 23.3547 35.3744C22.183 36.0644 20.9545 36.1114 20.336 35.49ZM22.2506 34.8027C22.9364 34.3769 24.3245 32.7274 25.2854 31.1965L25.6744 30.5767L23.903 31.4715C21.1672 32.8536 19.9156 34.1562 20.5664 34.9441C20.932 35.3867 21.3694 35.35 22.2506 34.8027ZM40.0215 29.791C40.6922 29.3189 40.5949 28.3677 39.8365 27.9841C39.2463 27.6856 38.7707 27.6243 37.2375 27.6471C36.2954 27.7116 34.7804 27.9024 34.5236 27.9604C34.5236 27.9604 35.3559 28.5383 35.7254 28.7507C36.2173 29.033 37.4126 29.557 38.2853 29.8254C39.1462 30.0901 39.6442 30.0622 40.0215 29.791V29.791ZM32.876 26.8071C32.4701 26.3783 31.7798 25.4835 31.3421 24.8185C30.7696 24.0641 30.4825 23.5319 30.4825 23.5319C30.4825 23.5319 30.0641 24.8842 29.7208 25.6979L28.6498 28.3577L28.3393 28.961C28.3393 28.961 29.9901 28.4171 30.83 28.1967C31.7196 27.9632 33.5251 27.6065 33.5251 27.6065L32.876 26.8071ZM30.5734 17.529C30.6769 16.6559 30.7208 15.7842 30.4417 15.3446C29.6673 14.494 28.7326 15.2033 28.8909 17.2255C28.9442 17.9057 29.1124 19.0686 29.3373 19.7854L29.7461 21.0885L30.0339 20.107C30.1922 19.5672 30.4349 18.4071 30.5734 17.529Z"
      fill="#FF2116"
    />
    <path
      d="M22.4121 41.1688H24.3929C25.0258 41.1688 25.5395 41.2296 25.9341 41.3513C26.3287 41.469 26.6608 41.734 26.9303 42.1462C27.1999 42.5544 27.3347 43.047 27.3347 43.624C27.3347 44.1539 27.2253 44.6093 27.0065 44.99C26.7877 45.3708 26.4928 45.6456 26.1216 45.8144C25.7544 45.9831 25.1879 46.0675 24.4222 46.0675H23.7365V49.2058H22.4121V41.1688ZM23.7365 42.1992V45.0018H24.3929C24.9789 45.0018 25.3832 44.8919 25.6059 44.6721C25.8325 44.4523 25.9458 44.0951 25.9458 43.6005C25.9458 43.2315 25.8716 42.9332 25.7231 42.7055C25.5747 42.4739 25.4106 42.3326 25.2309 42.2816C25.0551 42.2266 24.7757 42.1991 24.3929 42.1991L23.7365 42.1992Z"
      fill="#323240"
    />
    <path
      d="M28.3496 41.1688H30.1487C31.0199 41.1688 31.7153 41.3238 32.2349 41.6339C32.7584 41.944 33.153 42.4052 33.4187 43.0176C33.6883 43.6299 33.823 44.309 33.823 45.0548C33.823 45.8399 33.7019 46.5405 33.4597 47.1568C33.2214 47.7692 32.8542 48.2637 32.358 48.6406C31.8657 49.0174 31.1625 49.2058 30.2483 49.2058H28.3496V41.1688ZM29.674 42.2345V48.1401H30.2249C30.9945 48.1401 31.5532 47.8732 31.9009 47.3393C32.2486 46.8016 32.4225 46.0852 32.4225 45.1902C32.4225 43.2197 31.6899 42.2345 30.2249 42.2345H29.674Z"
      fill="#323240"
    />
    <path
      d="M35.0879 41.1688H39.5299V42.2345H36.4123V44.6368H38.9087V45.7025H36.4123V49.2058H35.0879V41.1688Z"
      fill="#323240"
    />
  </svg>
)
export default PdfNew
