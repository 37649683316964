import { IFolder } from "../../api/api-client/api-types"
import { deleteItemLocalStorage } from "./localStorage"

export const deleteThumbnailsInFolderTree = (folder: IFolder) => {
  if (folder.contents) {
    folder.contents.forEach((file) => deleteItemLocalStorage(file.id))
  }
  if (folder.childFolders) {
    folder.childFolders.forEach((childFolder) =>
      deleteThumbnailsInFolderTree(childFolder)
    )
  }
}
