const Info = () => (
  <svg 
    width="26" 
    height="26" 
    viewBox="0 0 26 26" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    data-testid="Info">
    <path 
    fillRule="evenodd" 
    clipRule="evenodd" 
    d="M13 3.72143C7.87559 3.72143 3.72143 7.87559 3.72143 13C3.72143 18.1244 7.87559 22.2786 13 22.2786C18.1244 22.2786 22.2786 18.1244 22.2786 13C22.2786 7.87559 18.1244 3.72143 13 3.72143ZM2.22143 13C2.22143 7.04716 7.04716 2.22143 13 2.22143C18.9528 2.22143 23.7786 7.04716 23.7786 13C23.7786 18.9528 18.9528 23.7786 13 23.7786C7.04716 23.7786 2.22143 18.9528 2.22143 13ZM13 11.1357C13.4142 11.1357 13.75 11.4715 13.75 11.8857V18.2C13.75 18.6142 13.4142 18.95 13 18.95C12.5858 18.95 12.25 18.6142 12.25 18.2V11.8857C12.25 11.4715 12.5858 11.1357 13 11.1357ZM14.1143 8.54286C14.1143 9.15826 13.6154 9.65714 13 9.65714C12.3846 9.65714 11.8857 9.15826 11.8857 8.54286C11.8857 7.92745 12.3846 7.42857 13 7.42857C13.6154 7.42857 14.1143 7.92745 14.1143 8.54286Z" 
    fill="#48475C"/>
  </svg>
)

export default Info
