export const NotificationsIcon = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.61 22.06L29.25 30.66C29.3437 30.7667 29.4591 30.8522 29.5884 30.9108C29.7177 30.9694 29.858 30.9998 30 31C30.1203 31.002 30.2397 30.9782 30.35 30.93C30.5398 30.8591 30.7036 30.7322 30.8197 30.5661C30.9357 30.4001 30.9986 30.2026 31 30V2.00002C31.0008 1.86841 30.9755 1.73795 30.9258 1.61611C30.876 1.49427 30.8027 1.38346 30.71 1.29002C30.6166 1.19734 30.5057 1.12401 30.3839 1.07425C30.2621 1.02448 30.1316 0.999256 30 1.00002H2C1.73478 1.00002 1.48043 1.10537 1.29289 1.29291C1.10536 1.48045 1 1.7348 1 2.00002V21.06C1 21.3252 1.10536 21.5796 1.29289 21.7671C1.48043 21.9547 1.73478 22.06 2 22.06H21.61ZM3 3.00002H29V27.37L22.81 20.37C22.7154 20.2652 22.5996 20.1816 22.4704 20.1247C22.3411 20.0678 22.2012 20.0389 22.06 20.04H3V3.00002Z"
          fill="#282828"
        />
      </svg>
    </>
  )
}
