import React from "react"
import { AnchorType } from "../../../api/api-client/common/Link"

const Link: React.FC<AnchorType> = ({
  children,
  className = "",
  href,
  ...rest
}) => (
  <a
    className={`rn-link ${className}`}
    href={href}
    data-testid="link"
    {...rest}
  >
    {children}
  </a>
)

export default Link

Link.displayName = "Link"
