const Attachment = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9993 10L10.5858 20.5864C10.2107 20.9615 10 21.4702 10 22.0006C10 22.531 10.2107 23.0398 10.5858 23.4148C10.9609 23.7899 11.4696 24.0006 12 24.0006C12.5304 24.0006 13.0391 23.7899 13.4142 23.4148L25.8277 10.8284C26.1991 10.457 26.4938 10.016 26.6948 9.53073C26.8958 9.04543 26.9993 8.52529 26.9993 8C26.9993 7.47471 26.8958 6.95457 26.6948 6.46927C26.4938 5.98396 26.1991 5.54301 25.8277 5.17157C25.4563 4.80014 25.0153 4.5055 24.53 4.30448C24.0447 4.10346 23.5246 4 22.9993 4C22.474 4 21.9538 4.10346 21.4685 4.30448C20.9832 4.5055 20.5423 4.80014 20.1708 5.17157L7.75736 17.758C6.63214 18.8832 6 20.4093 6 22.0006C6 23.5919 6.63214 25.118 7.75736 26.2433C8.88258 27.3685 10.4087 28.0006 12 28.0006C13.5913 28.0006 15.1174 27.3685 16.2426 26.2433L26.4993 16"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Attachment
