import { ChildOrganisationDto } from "legado-generated-api-client"

export const getAllChildOrganisationResponse: ChildOrganisationDto[] = [
  {
    id: "8802af8b-bc05-490f-bcb6-0e97be77fdb1",
    name: "OnPoint Investments",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "02da51cf-ba2b-4aa5-80e9-11e3f6e8d621",
    name: "Clubhouse",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "19ec47dd-6590-44b7-8fa1-16c34b7cbc4b",
    name: "Wealth Status",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "932abdd3-88cf-4da8-876c-1dbee0482acb",
    name: "Money Valley",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "ca1dcd93-a642-476b-8c02-1f16c463eded",
    name: "Genuine Advice",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "ad13dd06-15c4-49f3-a557-24674de624f0",
    name: "Small Town Savings",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "3fbc7669-45fc-4b77-a580-25b85ded3c1e",
    name: "Speedway Investments",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "6f82803b-538f-4234-8b57-282c06042a7e",
    name: "Rat Pack Brokers",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "771ffc2c-95f1-4add-8129-292c882b90a1",
    name: "Tribe",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "c7c4d474-1a2f-49d9-8769-2d1074943dfc",
    name: "Quest Invest",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "047a90dd-41eb-47f7-8b20-30c62b134148",
    name: "Iconic Investments",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "5af0a60e-ce47-4b96-9cb7-31eb5171f424",
    name: "Centurion",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "1b64cee7-6cb2-4ec9-82ed-3378d0a8daee",
    name: "Top Picks",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "86d047d1-2232-44dc-b62f-38b3ded28e9e",
    name: "All Seasons",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "93d1dbb0-2cef-46b4-81f5-39a13feec5dd",
    name: "Cash Cowboys",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "983d3128-d7b3-4bc7-a660-454db640f6db",
    name: "Freelance Financial Advice",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "f982310f-bcb5-42b1-8a9d-50e788382484",
    name: "Remarkable Advice",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "d3f5572d-570b-481d-a35f-5d1c2b4f33b3",
    name: "Invest Lab",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "931051c0-3f96-44b2-8661-6c28cfe1be16",
    name: "Time Team",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "2b353f11-218b-435e-bc5b-6d96adfb5731",
    name: "Crystal Ball",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "d478ee68-3725-41c8-9b79-7741ebdd776d",
    name: "Unlimited Funds",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "e486b3be-2571-4ed9-9632-7a34024a58da",
    name: "Light Touch Investments",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "32a49bc5-f87e-43e7-8f7d-7aeabfd7cf80",
    name: "GreenStar",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "27bac5fd-ee2d-4b5c-bcd8-7c118f25c701",
    name: "Sigma Partners",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "1de2f7b5-5c1b-48ff-bf85-82b3087a336e",
    name: "Comfort Investments",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "cf0fc8db-1f3a-4fe6-bac9-83a229be1dd6",
    name: "North Partners",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "dc27adf8-73c0-436d-b034-864899cd6a0e",
    name: "Gator",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "db48777b-fd63-4fd9-9d44-89ce8bb77dbf",
    name: "GreenTree Partners",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "04a8dc2b-d424-45e0-93e3-962738993a8f",
    name: "Finest Investments",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "3fba2d75-bd66-4045-9f6a-976dec6b5ebe",
    name: "Wealth Heroes",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "27fe64d9-c701-493a-bc83-98a824bca0e2",
    name: "Cortex",
    activatedUserCount: 0,
    pendingUserCount: 3,
  },
  {
    id: "19f430cd-c784-4a23-918b-9b1d3537d27d",
    name: "Hands On Investments",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "cbbb282d-ff7a-465c-9073-9e271fed22ac",
    name: "Minority Rules",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "a382f79f-5d11-46d2-beb7-9e8857b2f261",
    name: "Money Tree",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "b5d2d27c-458e-4a66-a042-ab68027ae2ac",
    name: "Fast Finance",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "c961dada-a91d-42ac-9550-ab95e62e6cb9",
    name: "Encompassing Wealth",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "bb7531a7-53ee-465b-bfae-b78f0f3ee7c2",
    name: "Investment Geeks",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "d609efd4-46e2-4378-b111-bb63db98bd50",
    name: "Avalon Brothers",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "f653e12d-b55f-4072-8cd2-be03347300a0",
    name: "Rapid Profits",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "d72bb308-812d-46a8-828a-c4f8c892da52",
    name: "Bliss Invest",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "7a80ba1c-4de7-4a70-bd14-cd55f9817a87",
    name: "Highline Money",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "d6baa987-220f-488b-91ef-ce0a8ae0b568",
    name: "Square Finances",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "bdad1fd6-c387-4c1d-b259-e001e4b332ff",
    name: "Pegasus",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "a651fb7d-d906-4aa1-a026-e0c2065a6c5c",
    name: "Affinity",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "cd246dd5-27c4-466f-b441-e0c97b1a2a96",
    name: "Jumbotron Finance",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "63ceb0e2-e624-4ef3-8546-e310f596a3d7",
    name: "Customized Investments",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "1045d996-35ff-4073-81b8-e7000af84745",
    name: "Wealth Pathway",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
  {
    id: "e05c04c0-1b40-4d6d-ac4e-fe059b4119a3",
    name: "FinanceFuel",
    activatedUserCount: 0,
    pendingUserCount: 0,
  },
]
