/**
 * From BE:
        None = 0,
        // Token is passed in
        Token = 1,
        // Login Via Email
        Passwordless = 2,
        // User Password Login
        Password = 3,
        // Password or Passwordless
        Mixed = 4,
        // External SSO scheme
        SSO = 5
 */
export enum loginTypes {
  // Used to authenticate users with a Password login
  PASSWORD = "Password",
  // Used to authenticate users with a Passwordless login
  PASSWORDLESS = "Passwordless",
  // Used to authenticate users with both Password and Passwordless login
  MIXED = "Mixed",
  NONE = "None",
  SSO = "SSO",
  TOKEN = "Token",
}
