const Filter = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.26056 6H26.7395C26.9332 6 27.1227 6.05627 27.2851 6.16198C27.4474 6.26769 27.5756 6.41828 27.654 6.59545C27.7323 6.77262 27.7576 6.96874 27.7266 7.15997C27.6956 7.35121 27.6097 7.52932 27.4794 7.67267L19.2601 16.7139C19.0927 16.898 19 17.1378 19 17.3866V24.4648C19 24.6294 18.9594 24.7915 18.8817 24.9367C18.804 25.0818 18.6917 25.2055 18.5547 25.2969L14.5547 27.9635C14.4041 28.0639 14.2291 28.1216 14.0483 28.1303C13.8675 28.1391 13.6877 28.0986 13.5282 28.0132C13.3686 27.9278 13.2352 27.8006 13.1421 27.6454C13.0491 27.4901 13 27.3125 13 27.1315V17.3866C13 17.1378 12.9073 16.898 12.7399 16.7139L4.52062 7.67267C4.3903 7.52932 4.30443 7.35121 4.27344 7.15997C4.24245 6.96874 4.26767 6.77262 4.34605 6.59545C4.42442 6.41828 4.55257 6.26769 4.71492 6.16198C4.87727 6.05627 5.06683 6 5.26056 6V6Z"
      fill="none"
    />
  </svg>
)
export default Filter
