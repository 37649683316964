interface ButtonDetailsType {
  [key: string]: {
    text: {
      default: string
      submitting: string
    }
    className: string
  }
}

export const submitButtonDetails: ButtonDetailsType = {
  setupPassword: {
    text: { default: "Create my account", submitting: "Creating account..." },
    className: "register-or-reset__create-button",
  },
  resetPassword: {
    text: {
      default: "Reset password and sign in",
      submitting: "Resetting password...",
    },
    className: "register-or-reset__reset-button",
  },
}
