const FnzUpload = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.75 10.2486L16 5L21.25 10.2486" fill="none" />
      <path d="M16 19V5.00368" fill="none" />
      <path
        d="M27 19V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V19"
        fill="none"
      />
    </svg>
  )
}
export default FnzUpload
