import { TApplicationAction, IApplicationState } from "./constants"
import {
  SET_API_CONNECTION,
  SET_LOADING_STATE,
  SET_SELECTED_USER_SHARING_WITH_ME,
  SET_USERS_SHARING_WITH_ME,
  SET_SEARCH_RESULT,
  SET_SEARCHED_TAG,
  SET_FIRST_VISIT,
  SET_SELECTED_THEME,
  SET_USING_STATIC_DATA,
  SET_UPLOADING_STATE,
  SET_MODAL_OPEN,
  SET_API_KEY,
  SET_HOST_URL,
  SET_NOTIFICATIONS,
  SET_SHOW_CONNECTIVITY_BANNER,
  SET_SELECTED_INDUSTRY,
  SET_SELECTED_PROVIDER,
  SET_OPEN_FOLDER_ID,
  SET_PROVIDER_STATUSES,
  SET_GLOBAL_FILTER_OPTIONS,
  SET_FILES_WITH_TAGS,
  UPDATE_PROVIDER_STATUS,
  SET_CLIENTS,
  SET_PROVIDERS,
  SET_INDUSTRIES,
  SET_PROJECTS,
  SET_SELECTED_PROJECT,
  SET_PROJECT_ANALYTICS,
  SET_FLAT_FILE_LIST,
  SET_CLIENTS_FILES,
  SET_SHOW_MENU,
  SET_ANALYTICS_ROUTE,
  DELETE_FILE_IN_FLAT_FILE_LIST,
  SET_HEAD_ACCOUNT_ID,
  SET_PERSON_ID,
  SET_TIMEOUT_STATE,
  SET_HIDDEN_ONE_X_HEADER,
  ADD_FILES_IN_FLAT_FILE_LIST,
  SET_AUTH_LOADING,
  SET_SHOW_FOOTER,
} from "./action"
import { folderReducer } from "./folderReducer"
import { fileReducer } from "./fileReducer"
import { contactReducer } from "./contactReducer"
import { searchFoldersAndFiles } from "../../utils/search/searchFoldersAndFiles"

export const applicationReducer = (
  applicationState: IApplicationState,
  action: TApplicationAction
) => {
  const appStateUpdatedByFolderReducer = folderReducer(applicationState, action)
  if (appStateUpdatedByFolderReducer) {
    return appStateUpdatedByFolderReducer
  }
  const appStateUpdatedByFileReducer = fileReducer(applicationState, action)
  if (appStateUpdatedByFileReducer) {
    if (appStateUpdatedByFileReducer.searchResult) {
      const newSearchResult = searchFoldersAndFiles({
        expression: appStateUpdatedByFileReducer.searchResult.expression,
        folders: appStateUpdatedByFileReducer.folders,
      })
      return {
        ...appStateUpdatedByFileReducer,
        searchResult: newSearchResult,
      }
    }
    return appStateUpdatedByFileReducer
  }
  const appStateUpdatedByContactReducer = contactReducer(
    applicationState,
    action
  )
  if (appStateUpdatedByContactReducer) {
    return appStateUpdatedByContactReducer
  }

  switch (action.type) {
    case SET_SEARCH_RESULT:
      return {
        ...applicationState,
        searchResult: action.newResult,
      }
    case SET_AUTH_LOADING:
      return {
        ...applicationState,
        authLoading: action.authLoading,
      }
    case SET_SEARCHED_TAG:
      return {
        ...applicationState,
        searchedTag: action.searchedTag,
      }
    case SET_LOADING_STATE:
      return {
        ...applicationState,
        isLoading: action.isLoading,
      }
    case SET_UPLOADING_STATE:
      return {
        ...applicationState,
        isUploading: action.isUploading,
      }

    case SET_SELECTED_USER_SHARING_WITH_ME:
      if (action.userId || action.userId === "") {
        return {
          ...applicationState,
          selectedSharingWithMeUserId: action.userId,
        }
      }
      break
    case SET_USERS_SHARING_WITH_ME:
      if (action.usersSharingWithMe) {
        return {
          ...applicationState,
          usersSharingWithMe: action.usersSharingWithMe,
        }
      }
      break

    case SET_API_CONNECTION:
      if (action.apiConnection) {
        return {
          ...applicationState,
          apiConnection: action.apiConnection,
        }
      }
      break

    case SET_SELECTED_THEME:
      if (action.selectedTheme) {
        return {
          ...applicationState,
          selectedTheme: action.selectedTheme,
        }
      }
      break
    case SET_FIRST_VISIT:
      return {
        ...applicationState,
        firstVisit: action.firstVisit,
      }

    case SET_HIDDEN_ONE_X_HEADER:
      return {
        ...applicationState,
        hideHeader: action.hideHeader,
      }
    case SET_USING_STATIC_DATA:
      return {
        ...applicationState,
        usingStaticData: action.usingStaticData,
      }
    case SET_MODAL_OPEN:
      return {
        ...applicationState,
        modalOpen: action.modalOpen,
      }
    case SET_API_KEY:
      return {
        ...applicationState,
        apiKey: action.apiKey,
      }
    case SET_HOST_URL:
      return {
        ...applicationState,
        hostUrl: action.hostUrl,
      }
    case SET_NOTIFICATIONS:
      return {
        ...applicationState,
        notifications: action.notifications,
      }
    case SET_SHOW_CONNECTIVITY_BANNER:
      return {
        ...applicationState,
        showConnectivityBanner: action.showConnectivityBanner,
      }
    case SET_INDUSTRIES:
      return {
        ...applicationState,
        industries: action.industries,
      }
    case SET_PROVIDERS:
      return {
        ...applicationState,
        providers: action.providers,
      }
    case SET_SELECTED_INDUSTRY:
      return {
        ...applicationState,
        selectedIndustry: action.selectedIndustry,
      }
    case SET_SELECTED_PROVIDER:
      return {
        ...applicationState,
        selectedProvider: action.selectedProvider,
      }
    case SET_OPEN_FOLDER_ID:
      return {
        ...applicationState,
        savedOpenFolderId: action.savedOpenFolderId,
      }
    case SET_PROVIDER_STATUSES:
      return {
        ...applicationState,
        providerStatuses: action.providerStatuses,
      }
    case UPDATE_PROVIDER_STATUS:
      if (action.updatedStatus.folderId) {
        const folderId = action.updatedStatus.folderId
        const newStatus = action.updatedStatus.connectionStatus
        const newProviderStatuses = applicationState.providerStatuses.map(
          (status) => {
            if (status.folderId === folderId) {
              status.connectionStatus = newStatus
              status.isRead = false
            }
            return status
          }
        )
        return {
          ...applicationState,
          providerStatuses: newProviderStatuses,
        }
      }
      break

    case SET_GLOBAL_FILTER_OPTIONS:
      return {
        ...applicationState,
        globalFilterOptions: action.globalFilterOptions,
      }
    case SET_FILES_WITH_TAGS:
      return {
        ...applicationState,
        filesWithTags: action.filesWithTags,
      }
    case SET_CLIENTS:
      return {
        ...applicationState,
        clients: action.clients,
      }
    /* Communications */

    case SET_SHOW_MENU:
      return {
        ...applicationState,
        showMenu: action.showMenu,
      }

    case SET_SHOW_FOOTER:
      return {
        ...applicationState,
        showFooter: action.showFooter,
      }

    case SET_PROJECTS:
      return {
        ...applicationState,
        projects: action.projects,
      }
    case SET_SELECTED_PROJECT:
      return {
        ...applicationState,
        selectedProject: action.selectedProject,
      }
    case SET_PROJECT_ANALYTICS:
      return {
        ...applicationState,
        projectAnalytics: action.projectAnalytics,
      }
    case SET_FLAT_FILE_LIST:
      return {
        ...applicationState,
        flatFileList: action.flatFileList,
      }
    case SET_CLIENTS_FILES:
      return {
        ...applicationState,
        documentLibraryClientFiles: action.documentLibraryClientFiles,
      }
    case DELETE_FILE_IN_FLAT_FILE_LIST:
      return {
        ...applicationState,
        flatFileList: applicationState.flatFileList.filter(
          (file) => file.id !== action.fileId
        ),
      }
    case ADD_FILES_IN_FLAT_FILE_LIST:
      return {
        ...applicationState,
        flatFileList: [
          ...applicationState.flatFileList,
          ...action.flatFileList,
        ],
      }
    case SET_ANALYTICS_ROUTE:
      return {
        ...applicationState,
        analyticsRoute: action.analyticsRoute,
      }
    case SET_HEAD_ACCOUNT_ID:
      return {
        ...applicationState,
        headAccountId: action.headAccountId,
      }
    case SET_PERSON_ID:
      return {
        ...applicationState,
        personId: action.personId,
      }
    case SET_TIMEOUT_STATE:
      return {
        ...applicationState,
        timeoutState: action.timeoutState,
      }
    default:
      return applicationState
  }
}
