import { FunctionComponent } from "react"
import { useApplicationContext } from "../../contexts/application/context"
import { BarclaysTopBar } from "../../design-tokens/imgs/barclays/barclaysTopBar"
import { BarclaysTopBarMobile } from "../../design-tokens/imgs/barclays/barclaysTopBarMobile"
import { BarclaysTopBarTablet } from "../../design-tokens/imgs/barclays/barclaysTopBarTablet"
import { useGetIcon } from "../../styled-components/GetIconLibraryInTheme"
import { displayDate } from "../../utils/helpers"
import { MoneyhubMenuMobile } from "../../design-tokens/imgs/moneyhub/moneyhubMenuMobile"
import { MoneyhubMenuTablet } from "../../design-tokens/imgs/moneyhub/moneyhubMenuTablet"
import { MoneyhubMenuDesktop } from "../../design-tokens/imgs/moneyhub/moneyhubMenuDesktop"
import { QuilterLogoSvg } from "../../design-tokens/imgs/Quilter/QuilterLogoSvg"
import { useNavigate } from "react-router-dom"
import { useUserContext } from "../../contexts/users"
import "./TopBarOneX.css"
import "./TopBarBarclays.css"
import "./TopBarMoneyhub.css"
import { UserModuleType } from "../../contexts/application/constants"
// TODO: Restructure TopBar component - https://dev.azure.com/secure-the-file/Application/_workitems/edit/13688
type ITopBarOneX = {
  hideHeader?: boolean
}
export const TopBarOneX: FunctionComponent<ITopBarOneX> = ({ hideHeader }) => {
  const {
    applicationState: { usersSharingWithMe, selectedSharingWithMeUserId },
  } = useApplicationContext()
  const {
    userState: { currentUser },
  } = useUserContext()

  const Logo = useGetIcon("Logo")
  const documentHubLogo = useGetIcon("AppIcon")

  const hasDocumentHuBorLibraryAccess = () => {
    const communicationsModules = [
      UserModuleType.DOCUMENT_HUB,
      UserModuleType.DOCUMENTS_LIBRARY_ADVISER_VIEW,
      UserModuleType.DOCUMENT_LIBRARY,
    ]

    return communicationsModules.some((module) =>
      currentUser?.modules?.includes(module)
    )
  }

  const currentSharingUser = usersSharingWithMe.find(
    (user) => user.userId === selectedSharingWithMeUserId
  )

  return (
    <>
      {hideHeader ? null : hasDocumentHuBorLibraryAccess() ? (
        <div className="top-bar">
          <div className="container inner">
            <div className="top-bar-top">
              <div className="legado-hub-header">
                <div className="legado-hub-logo">{documentHubLogo}</div>
                <div className="legado-hub-text">
                  Legado Document{" "}
                  {`${
                    currentUser?.modules?.includes(UserModuleType.DOCUMENT_HUB)
                      ? "Hub"
                      : "Library"
                  }`}
                </div>
                <div className="legado-hub-text-demo"> (Demo header)</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="top-bar">
          <div className="top-bar-top">
            <div className="container inner">
              <div>
                <div className="logo">{Logo}</div>
              </div>
              <div className="menu">Menu</div>
            </div>
          </div>
          <div className="top-bar-bottom">
            <div className="container inner">
              <div>
                {!selectedSharingWithMeUserId ? (
                  <div className="large">
                    {`${
                      currentUser ? `${currentUser.firstName}'s` : "James's"
                    } Personal Vault`}
                  </div>
                ) : (
                  <div className="large">
                    {`${
                      currentSharingUser
                        ? `${currentSharingUser.firstName}'s`
                        : "James's"
                    } Personal Vault`}
                  </div>
                )}
                <div>Customer ID 7123</div>
              </div>
              <div>
                <div className="large">£250,000</div>
                <div>Total wealth as at {displayDate(new Date())}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const TopBarBarclays = () => {
  return (
    <>
      <div className="hide-on-tablet-and-down">
        <img
          src={BarclaysTopBar.img}
          alt={"BarclaysTopBar-Desktop"}
          className="barclays-top-desktop"
        ></img>
      </div>
      <div>
        <div className="hide-on-desktop-only hide-on-mobile-only">
          <img
            src={BarclaysTopBarTablet.img}
            alt={"BarclaysTopBar-Tablet"}
            className="top-bar-barclays-tablet"
          ></img>
        </div>
      </div>

      <div className="hide-on-tablet-and-up">
        <img src={BarclaysTopBarMobile.img} alt={"BarclaysTopBar-Mobile"}></img>
      </div>
    </>
  )
}

export const TopBarMoneyhub = () => {
  return (
    <div className="topBar-moneyhub">
      <img
        src={MoneyhubMenuDesktop.img}
        alt="MoneyHub-topBar-desktop"
        className="top-bar-moneyhub-desktop hide-on-tablet-and-down"
      ></img>
      <img
        src={MoneyhubMenuTablet.img}
        alt="MoneyHub-topBar-tablet"
        className="top-bar-moneyhub-tablet hide-on-desktop-only hide-on-mobile-only"
      ></img>
      <img
        src={MoneyhubMenuMobile.img}
        alt="MoneyHub-topBar-mobile"
        className="top-bar-moneyhub-mobile hide-on-tablet-and-up"
      ></img>
    </div>
  )
}

type ITopBarQuilter = {
  title: string
  subtitle?: string
}

export const TopBarQuilter: FunctionComponent<ITopBarQuilter> = ({
  title,
  subtitle,
}) => {
  const {
    applicationState: { headAccountId },
  } = useApplicationContext()
  const navigate = useNavigate()
  return (
    <div className="topBar-quilter-border">
      <div className="topBar-quilter container">
        {headAccountId ? (
          <QuilterLogoSvg />
        ) : (
          <a
            href={"/"}
            onClick={(e) => {
              e.preventDefault()
              navigate("/")
            }}
          >
            <QuilterLogoSvg />
          </a>
        )}
        <div className="topBar-quilter-headings">
          <h1 className="topBar-quilter-title">{title}</h1>
          {subtitle && <p className="topBar-quilter-subtitle">{subtitle}</p>}
        </div>
      </div>
    </div>
  )
}
