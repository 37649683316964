import { IFile, IFolder } from "../api-types"

export const getUpdatedPinnedFoldersFiles = ({
  pinnedFoldersFiles,
  nodeToUpdate,
  isPinned,
}: {
  pinnedFoldersFiles?: IFolder
  nodeToUpdate?: IFile | IFolder
  isPinned?: boolean
}) => {
  if (!pinnedFoldersFiles || !nodeToUpdate || isPinned === undefined) return

  const newPinnedFoldersAndFiles = { ...pinnedFoldersFiles }

  const isFolder = (nodeToUpdate as IFolder).level !== undefined

  if (isFolder) {
    const hasFolder = newPinnedFoldersAndFiles.childFolders?.find(
      (folder) => folder.id === nodeToUpdate.id
    )
    if (isPinned && !hasFolder) {
      //childFolders and contents of a pinned folder are irrelevant as it's stored in a flat structure (and they are not pinned when parent is)
      const newFolder = {
        ...(nodeToUpdate as IFolder),
        childFolders: [],
        contents: [],
      }
      newPinnedFoldersAndFiles.childFolders = [
        ...(newPinnedFoldersAndFiles.childFolders ?? []),
        newFolder,
      ]
    } else {
      newPinnedFoldersAndFiles.childFolders = [
        ...(newPinnedFoldersAndFiles.childFolders ?? []),
      ].filter((folder) => folder.id !== nodeToUpdate.id)
    }
  } else {
    const hasFile = newPinnedFoldersAndFiles.contents?.find(
      (file) => file.id === nodeToUpdate.id
    )
    if (isPinned && !hasFile) {
      newPinnedFoldersAndFiles.contents = [
        ...(newPinnedFoldersAndFiles.contents ?? []),
        nodeToUpdate as IFile,
      ]
    } else {
      newPinnedFoldersAndFiles.contents = [
        ...(newPinnedFoldersAndFiles.contents ?? []),
      ].filter((file) => file.id !== nodeToUpdate.id)
    }
  }
  return newPinnedFoldersAndFiles
}
