import {
  DistributionClientDetailsDto,
  DistributionProjectAnalyticsInfoDto,
  DistributionProjectClientListDto,
  DistributionProjectSummaryDto,
} from "legado-generated-api-client"

export const getPaginatedDummyClients = (start?: number, count?: number) => {
  const paginatedClients = dummyClients.clients.slice(
    start,
    start ?? 0 + (count ?? 10)
  ) as DistributionClientDetailsDto
  const summary: DistributionProjectSummaryDto = {
    totalOfClients: dummyClients.clients.length,
    totalOfReadByProject: dummyClients.clients.length / 2,
  }
  const dpCsummary = {
    paginatedClients,
    summary,
  } as DistributionProjectClientListDto
  return dpCsummary
}

export const dummyClients = {
  clientCount: 10,
  clients: [
    {
      userId: 1,
      firstName: "Thomas",
      surname: "Wood",
      email: "thomas.wood@fnz.com",
      externalReference: "FN1234568",
    },
    {
      userId: 2,
      firstName: "Peter",
      surname: "Brink",
      email: "pbrink@gmail.com",
      externalReference: "FN1234668",
    },
    {
      userId: 3,
      firstName: "Emma",
      surname: "Johnson",
      email: "emma.johnson@gmail.com",
      externalReference: "FN1234700",
    },
    {
      userId: 4,
      firstName: "Joseph",
      surname: "Campbell",
      email: " jcampbell90@gmail.com",
      externalReference: "FN1234558",
    },
    {
      userId: 5,
      firstName: "Filipo",
      surname: "Mustermann",
      email: "filipo.mustermann@fnz.com",
      externalReference: "FN1234801",
    },
    {
      userId: 6,
      firstName: "Vince",
      surname: "Ng",
      email: "vince.ng@fnz.com",
      externalReference: "FN1234901",
    },
    {
      userId: 7,
      firstName: "Delphine",
      surname: "Artois",
      email: "delphine.artois@fnz.com",
      externalReference: "FN1234901",
    },
    {
      userId: 8,
      firstName: "Luisa",
      surname: "Garcia Perez",
      email: "luisa.perez@fnz.com",
      externalReference: "FN1235901",
    },
    {
      userId: 9,
      firstName: "Antonio",
      surname: "Marques Silva",
      email: "antonio.silva@fnz.com",
      externalReference: "FN1236901",
    },
    {
      userId: 10,
      firstName: "Ben",
      surname: "Hackett",
      email: "ben.hackett@fnz.com",
      externalReference: "FN1234568",
    },
  ],
}

export const mockProjectTestData: DistributionProjectAnalyticsInfoDto[] = [
  {
    id: "8bff441e-6720-4a8b-8e8f-0ff4af5a0031",
    name: "a",
    status: "ClientCriteriaInfoUploaded",
    sendDateAdviser: "0001-01-01T00:00:00Z",
    sendDateClient: "0001-01-01T00:00:00Z",
    sizeOfDistribution: 3,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-30T08:49:51Z",
    downloads: 0,
  },
  {
    id: "ab6d5780-9893-4dda-85ca-35ab24e4d9a1",
    name: "bug",
    status: "ClientCriteriaInfoUploaded",
    sendDateAdviser: "0001-01-01T00:00:00Z",
    sendDateClient: "0001-01-01T00:00:00Z",
    sizeOfDistribution: 9,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-29T15:12:33Z",
    downloads: 0,
  },
  {
    id: "f5632d48-274d-4c21-9167-166f704e382b",
    name: "schedule 2 only feature",
    status: "Scheduled",
    sendDateAdviser: "2024-01-07T16:00:00Z",
    sendDateClient: "2023-12-31T16:00:00Z",
    sizeOfDistribution: 9,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-28T15:45:40Z",
    downloads: 0,
  },
  {
    id: "cd61630f-dad9-4c2a-b436-fba5208296c3",
    name: "schedule 2 only feature",
    status: "Scheduled",
    sendDateAdviser: "2024-01-07T16:00:00Z",
    sendDateClient: "2023-12-31T16:00:00Z",
    sizeOfDistribution: 9,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-28T15:41:28Z",
    downloads: 0,
  },
  {
    id: "9e9af7f2-f959-4f35-b2c6-30e5bdb67898",
    name: "schedule 2 only feature",
    status: "Scheduled",
    sendDateAdviser: "2024-01-07T16:00:00Z",
    sendDateClient: "2023-12-31T16:00:00Z",
    sizeOfDistribution: 9,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-28T15:40:23Z",
    downloads: 0,
  },
  {
    id: "2dba6b10-5453-4ff5-aa36-f1931111979d",
    name: "schedule 2 only feature",
    status: "Scheduled",
    sendDateAdviser: "2024-01-07T16:00:00Z",
    sendDateClient: "2023-12-31T16:00:00Z",
    sizeOfDistribution: 5,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-28T15:37:37Z",
    downloads: 0,
  },
  {
    id: "8a403fd2-5ae7-4260-a1ae-9547b7ffd869",
    name: "distributon test",
    status: "Completed",
    sendDateAdviser: "2023-11-28T11:15:00Z",
    sendDateClient: "2023-11-28T11:15:00Z",
    sizeOfDistribution: 14,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "1",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-28T10:53:13Z",
    downloads: 0,
  },
  {
    id: "884ce4be-0237-431d-9bcc-e8b51db3952b",
    name: "asd",
    status: "Completed",
    sendDateAdviser: "2023-11-28T10:00:00Z",
    sendDateClient: "2023-11-28T10:00:00Z",
    sizeOfDistribution: 14,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "1",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-27T14:43:27Z",
    downloads: 0,
  },
  {
    id: "b7107771-be78-4d97-aaa3-918a5ac40c3b",
    name: "schedule 2 only feature",
    status: "Completed",
    sendDateAdviser: "2023-11-28T15:00:00Z",
    sendDateClient: "2023-11-28T15:00:00Z",
    sizeOfDistribution: 14,
    totalAmountOfFiles: 1,
    readCount: 0,
    version: "1",
    fileVisibility: "ClientAndAdviser",
    isSubAccount: false,
    createdDate: "2023-11-27T14:40:03Z",
    downloads: 0,
  },
]
