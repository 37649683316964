import { UserDto } from "legado-generated-api-client"
import { baseUrl, httpClient } from "../httpClient"

export const getUser = async ({
  email,
}: {
  email: string
}): Promise<UserDto> => {
  const url = new URL(`${baseUrl}/api/User/Get`)
  url.searchParams.append("email", email)
  const response = await httpClient(url.toString(), {
    method: "GET",
  })

  return response.data
}
