import { LocalStorageKeys } from "../../contexts/auth/localStorageKeys"

export const openDocusignUrl = (url?: string) => {
  const appType = localStorage.getItem(LocalStorageKeys.AppType) ?? "web"
  if (url && appType === "web") {
    window.open(url, "_blank")?.focus()
  } else if (url && (appType === "ios" || appType === "android")) {
    // This logic is used to send the docusign url to the parent window, should be useful for native apps (i.e. money hub app)
    window.parent.postMessage(
      {
        docusignUrl: url,
        eventType: "documentToBeSigned",
      },
      "*"
    )
  }
}
