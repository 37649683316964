export class ApiConnection {
  apiKey!: string | undefined
  /**
   * This apiUsername looks like is for testing purposes or for static data, but also when setting credentials for a user
   * and when getting a user details from BE (getUser endpoint).
   */
  apiUsername!: string | undefined
  /**
   * At the moment is only used for SSO (FNZ for example). example code: B66099F420DE25B70643673E717A9460380F8AAA1B1D19ACDEAFDFB7F7C4A3E7
   * it is string or null because is used in the local storage.
   */
  refreshTokenCode: string | null
  connected: boolean = false
  failed: boolean = false
  /**
   * Indicates that the request was for primary user route (for FNZ Integration)
   * We check this by looking at the current route and seeing if it is the document library page
   */
  isPrimaryUser: boolean = false

  constructor(apiKey: string | undefined, userName: string | undefined) {
    this.apiKey = apiKey
    this.apiUsername = userName
    this.refreshTokenCode = null
  }
}
