const Upload = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 14.75C25 15.0275 24.97 15.2975 24.913 15.5585C26.668 15.8705 28 17.405 28 19.25C28 21.3215 26.3215 23 24.25 23H19H13H10C6.6865 23 4 20.3135 4 17C4 13.6865 6.6865 11 10 11C10.5835 11 11.1475 11.084 11.68 11.2385C12.226 9.3665 13.9525 8 16 8C18.0055 8 19.705 9.3125 20.2855 11.126C20.593 11.0435 20.917 11 21.25 11C23.3215 11 25 12.6785 25 14.75Z"
      fill="var(--color-universal-secondary-b)"
    />
    <path
      d="M18.5 16.2778V19.9444H14.0556V16.2778H12L16.2778 12L20.5556 16.2778H18.5Z"
      fill="white"
    />
  </svg>
)
export default Upload
