import { NodeDto } from "legado-generated-api-client/models";
import { getUserId } from "./getUser";
import { FlatFileNodeDto } from "legado-generated-api-client";

const flatFiles = require("../staticData/getFlatFileList.json");

type TGetFlatFileList = (userEmail?: string) => FlatFileNodeDto[];

const isNodeDtos = (files: any) => {
  if ((files as FlatFileNodeDto[])?.length) {
    return files as FlatFileNodeDto[];
  }
};

type TObj = { [key: string]: any };

export const getFlatFileListFromLocalData: TGetFlatFileList = (userEmail) => {
  const currentUserId = getUserId(userEmail);
  const flatFileListObj = flatFiles as TObj;
  const currentUserflatFileList = flatFileListObj[currentUserId];
  return isNodeDtos(currentUserflatFileList) || [];
};
