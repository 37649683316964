import { CoopCompanyName } from "../../../../utils/consts/consts"

import "./TermsAndConditionsText.css"

export const TermsAndConditionsText = ({
  isTermsAndConditionsError,
  htmlForLabel,
}: {
  isTermsAndConditionsError: boolean
  htmlForLabel: string
}) => {
  return (
    <div className="terms-and-conditions-text">
      <label htmlFor={htmlForLabel}>
        {`By registering with the ${CoopCompanyName}, I am agreeing to the `}
        <a
          href="https://www.coop.co.uk/terms?_ga=2.69924691.295103734.1708101311-77035936.1708101311"
          rel="noreferrer"
          target="_blank"
        >
          terms and conditions
        </a>{" "}
        and{" "}
        <a
          href="https://www.co-oplegalservices.co.uk/privacy-notice/"
          rel="noreferrer"
          target="_blank"
        >
          privacy policy
        </a>
        .
      </label>
      {isTermsAndConditionsError && (
        <label className="terms-and-conditions-text-error">
          To create an account you must agree to the above.
        </label>
      )}
    </div>
  )
}
