import { SET_THUMBNAIL } from "./actions"

export type ThumbnailContextItem = {
  id: string
  thumbnail: string
}

export interface IThumbnailState {
  thumbnails?: ThumbnailContextItem[]
}

export interface IThumbnailAction {
  thumbnailItem: ThumbnailContextItem
  type: string
}

export const initialState = {
  thumbnails: [],
}

export const thumbnailReducer = (
  thumbnailState: IThumbnailState,
  action: IThumbnailAction
) => {
  switch (action.type) {
    case SET_THUMBNAIL:
      const existingThumbnail = thumbnailState.thumbnails?.find(
        (x) => x.id === action.thumbnailItem.id
      )
      if (existingThumbnail) {
        existingThumbnail.thumbnail = action.thumbnailItem.thumbnail
      } else {
        const newThumbnailState = thumbnailState.thumbnails
          ? [...thumbnailState.thumbnails]
          : []
        newThumbnailState.push(action.thumbnailItem)
        return {
          ...thumbnailState,
          thumbnails: newThumbnailState,
        }
      }
      return thumbnailState
    default:
      return thumbnailState
  }
}
