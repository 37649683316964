const Folders = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 14V25.1111C25 25.3469 24.9063 25.573 24.7397 25.7397C24.573 25.9063 24.3469 26 24.1111 26H5C4.73478 26 4.48043 25.8946 4.29289 25.7071C4.10536 25.5196 4 25.2652 4 25V11C4 10.7348 4.10536 10.4804 4.29289 10.2929C4.48043 10.1054 4.73478 10 5 10H10.6667C10.883 10 11.0936 10.0702 11.2667 10.2L14.7333 12.8C14.9064 12.9298 15.117 13 15.3333 13H24C24.2652 13 24.5196 13.1054 24.7071 13.2929C24.8946 13.4804 25 13.7348 25 14Z"
      fill="none"
    />
    <path
      d="M25 14V25.1111C25 25.3469 24.9063 25.573 24.7397 25.7397C24.573 25.9063 24.3469 26 24.1111 26H5C4.73478 26 4.48043 25.8946 4.29289 25.7071C4.10536 25.5196 4 25.2652 4 25V11C4 10.7348 4.10536 10.4804 4.29289 10.2929C4.48043 10.1054 4.73478 10 5 10H10.6667C10.883 10 11.0936 10.0702 11.2667 10.2L14.7333 12.8C14.9064 12.9298 15.117 13 15.3333 13H24C24.2652 13 24.5196 13.1054 24.7071 13.2929C24.8946 13.4804 25 13.7348 25 14Z"
      fill="none"
    />
    <path
      d="M8 10V7C8 6.73478 8.10536 6.48043 8.29289 6.29289C8.48043 6.10536 8.73478 6 9 6H14.6667C14.883 6 15.0936 6.07018 15.2667 6.2L18.7333 8.8C18.9064 8.92982 19.117 9 19.3333 9H28C28.2652 9 28.5196 9.10536 28.7071 9.29289C28.8946 9.48043 29 9.73478 29 10V21.1111C29 21.2278 28.977 21.3434 28.9323 21.4513C28.8877 21.5591 28.8222 21.6571 28.7397 21.7397C28.6571 21.8222 28.5591 21.8877 28.4513 21.9323C28.3434 21.977 28.2278 22 28.1111 22H25"
      fill="none"
    />
  </svg>
)
export default Folders
