import axios from "axios"
import { LocalStorageKeys } from "../../contexts/auth/localStorageKeys"
import { ApiController } from "../apiController"

export const baseUrl = process.env.REACT_APP_API_URL

const apiKeyFromUrl =
  new URLSearchParams(window.location.search).get("apikey") ?? ""

// We use process.env.REACT_APP_APIKEY only for convenience to test localhost and to don't have to pass the apikey in the url, otherwise would be difficult to test the app on localhost.
// however there is no a workaround for this page: http://localhost:3000/login?apikey=FRe0aWZ7I3MgeZlJB6N1Ag== or the unprotected routes.
const apiKey = apiKeyFromUrl || (process.env.REACT_APP_APIKEY ?? "")

export const httpClient = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
    apiKey,
  },
})

const authorizationHeaders = (token: string | null, apiKey: string | null) => ({
  ...(token && {
    Authorization: `Bearer ${token}`,
  }),
  ...(apiKey && {
    apiKey,
  }),
})

httpClient.interceptors.request.use((config) => {
  let token = localStorage.getItem(LocalStorageKeys.Token)
  let apiKey = localStorage.getItem(LocalStorageKeys.ApiKey)
  if (!token && !apiKey) {
    const api = ApiController.getInstance()
    token = api.getApiToken ?? ""
    apiKey = api.getApiConnection?.apiKey ?? ""
  }

  if (!token && !apiKey) {
    return config
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...authorizationHeaders(token, apiKey),
    },
  }
})
