import { FilesWithTagsDto } from "legado-generated-api-client/models";
import { getUserId } from "./getUser"

const filesWithTags = require("../staticData/getFilesWithTags.json");

type TGetFilesWithTags = (userEmail?: string) => FilesWithTagsDto[];

export const getFilesWithTagsFromLocalData: TGetFilesWithTags = (userEmail) => {
    const currentUserId = getUserId(userEmail);
    //const foldersObj = folders as TObj;
    const currentUserFilesAndTags = filesWithTags[currentUserId];
    return currentUserFilesAndTags as FilesWithTagsDto[];
    }