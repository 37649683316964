const NotificationsInfo = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5 17.5C31.5 25.232 25.232 31.5 17.5 31.5C9.76801 31.5 3.5 25.232 3.5 17.5C3.5 9.76801 9.76801 3.5 17.5 3.5C25.232 3.5 31.5 9.76801 31.5 17.5ZM17.125 11.5C16.507 11.5 16 12.007 16 12.625V13.375C16 13.993 16.507 14.5 17.125 14.5H17.875C18.493 14.5 19 13.993 19 13.375V12.625C19 12.007 18.493 11.5 17.875 11.5H17.125ZM14.5 22V23.5H20.5V22H19V16H14.5V17.5H16V22H14.5Z"
      fill="var(--color-universal-secondary-b)"
    />
  </svg>
)
export default NotificationsInfo
