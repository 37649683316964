import { KeyboardEvent, useState } from "react"
import { INavLink } from "../component/LeftNavigationBar/LeftNavigationBar"

export const useKeyboardNavList = ({
  navItems,
  closeMenu,
}: {
  navItems?: INavLink[]
  closeMenu?: () => void
}) => {
  const [activeIndex, setActiveIndex] = useState(-1)

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    event.stopPropagation()
    if (!navItems) return
    if (event.key === "Tab") {
      setActiveIndex(-1)
      return
    }
    const currentIndex = navItems.findIndex(
      (navItem) => navItem.name === (event.target as HTMLElement).id
    )
    if (
      currentIndex !== -1 &&
      (event.key === "ArrowUp" || event.key === "ArrowDown")
    ) {
      event.preventDefault()
      if (event.key === "ArrowUp") {
        if (currentIndex === 0) {
          setActiveIndex(-1)
          if (closeMenu) closeMenu()
        } else {
          setActiveIndex(currentIndex - 1)
        }
      } else {
        setActiveIndex(
          currentIndex === navItems.length - 1 ? 0 : currentIndex + 1
        )
      }
    }
  }

  return {
    activeIndex,
    handleKeyDown,
    setActiveIndex,
  }
}
