import { TTheme } from "../../types"
import animation from "../../tokens/abrdn/sheridan/animation.json"
import breakpoints from "../../tokens/abrdn/sheridan/breakpoints.json"
import buttons from "../../tokens/abrdn/sheridan/buttons.json"
import colors from "../../tokens/abrdn/sheridan/colors.json"
import iconsizing from "../../tokens/abrdn/sheridan/iconsizing.json"
import overlay from "../../tokens/abrdn/sheridan/overlay.json"
import radius from "../../tokens/abrdn/sheridan/radius.json"
import shadows from "../../tokens/abrdn/sheridan/shadows.json"
import styling from "../../tokens/abrdn/sheridan/styling.json"
import typeface from "../../tokens/abrdn/sheridan/typeface.json"
import typography from "../../tokens/abrdn/sheridan/typography.json"

const tokens = {
  mode: "ABRDN_SHERIDAN",
  animation,
  breakpoints,
  buttons,
  colors,
  iconsizing,
  overlay,
  radius,
  shadows,
  styling,
  typeface,
  typography,
} as TTheme
export default tokens
