import { Button } from "../atoms/Button"
import { ModalFooter, ModalHeader } from "../modules/Modal"

export interface UnsavedProgressProps {
  onExit: () => void
  onReturn: () => void
  name: string
}

export const UnsavedProgress: React.FC<UnsavedProgressProps> = ({
  onExit,
  onReturn,
  name,
}) => {
  return (
    <>
      <ModalHeader>Exit {name}</ModalHeader>
      <ModalFooter className="unsaved-progress">
        <div className="unsaved-progress-buttons">
          <Button
            className="left-button mr-3"
            variant="tertiary"
            type="reset"
            onClick={onExit}
          >
            Exit without saving
          </Button>
          <Button
            className="right-button"
            variant="primary"
            type="submit"
            onClick={onReturn}
          >
            Return
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}
