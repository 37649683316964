const Search = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.9701 13.7271C21.3763 9.05906 17.5802 5.06573 13.1103 5.00079C8.58064 4.93498 4.61833 8.99666 5.02938 13.7271C5.40673 18.0697 8.95206 21.059 13.1103 20.9991C15.2668 20.9681 17.2296 20.1377 18.6584 18.7337C19.9415 17.4729 20.7942 15.7495 20.9701 13.7271Z"
      stroke="var(--color-universal-secondary-b)"
    />
    <path
      d="M18.7055 19.047L26.2906 27.5728"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
    />
  </svg>
)
export default Search
