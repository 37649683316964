const Pencil = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    data-testid="Pencil"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0534 2.61774C14.4015 2.61538 14.7466 2.68201 15.0687 2.81377C15.3909 2.94554 15.6837 3.13982 15.9304 3.38541L15.5776 3.73972L15.9318 3.38679C16.1774 3.63335 16.3718 3.92616 16.5036 4.2483C16.6355 4.57043 16.7021 4.9155 16.6998 5.26356C16.6974 5.61162 16.6261 5.95575 16.4899 6.27607C16.3541 6.59543 16.1566 6.8848 15.9087 7.12756L15.6795 7.35666L15.6677 7.36887L15.6555 7.38068L7.42001 15.6162C7.41607 15.6204 7.41204 15.6246 7.40792 15.6287C7.39805 15.6386 7.38787 15.648 7.3774 15.6569C7.31912 15.7072 7.24987 15.7435 7.17498 15.7627L3.12423 16.8016C2.95307 16.8455 2.77144 16.7958 2.64648 16.6709C2.52152 16.5459 2.47179 16.3643 2.51567 16.1931L3.55402 12.1424C3.57627 12.0556 3.62145 11.9763 3.68481 11.913L12.1892 3.40862C12.432 3.16072 12.7215 2.96322 13.0409 2.8275C13.3613 2.69139 13.7054 2.62009 14.0534 2.61774ZM7.05353 14.5685L14.6069 7.01512L12.3019 4.71012L4.74651 12.2655L7.05353 14.5685ZM4.30546 13.2382L6.08122 15.0108L3.69413 15.6231L4.30546 13.2382ZM15.5696 5.88484C15.5048 6.03727 15.4173 6.17868 15.3104 6.30438L13.0127 4.00671C13.1383 3.89992 13.2797 3.81258 13.432 3.74786C13.6307 3.66341 13.8443 3.61918 14.0602 3.61771C14.2762 3.61625 14.4903 3.65759 14.6902 3.73935C14.8897 3.82097 15.0712 3.94126 15.224 4.09329C15.3761 4.24609 15.4965 4.42749 15.5781 4.62703C15.6599 4.82685 15.7012 5.04089 15.6998 5.25679C15.6983 5.47268 15.6541 5.68615 15.5696 5.88484Z"
    />
  </svg>
)

export default Pencil