import { themesAvailableOn3TierSystem } from "../../design-system/utils/constants"
import { getColor, getColors } from "../../design-tokens/getters"
import { TTheme } from "../../design-tokens/types"

export const getColorVariables = (theme: TTheme) => {
  const isThemeOn3TierSystem = themesAvailableOn3TierSystem.find(
    (themeItem) => themeItem === theme.mode
  )
  if (!isThemeOn3TierSystem) {
    const colors = getColors(theme)
    const gC = getColor({ colors })

    // these variables are not used in the codebase in any CSS file,
    // therefore not transferred to the new theming system
    // in the 3 tier system these variables would have a different name
    // following the naming convention of the 3 tier system
    const toneB = gC(["tone", "b"])
    const toneC = gC(["tone", "c"])
    const toneD = gC(["tone", "d"])
    const toneB25 = gC(["tone", "b-25"])
    const toneC25 = gC(["tone", "c-25"])
    const toneD25 = gC(["tone", "d-25"])

    ////////////////////////////////////////////////////////////////////
    // not used in the codebase, not in CO-OP, not transferred - start
    const backgroundNavbarHover = gC(["background", "navbar", "hover"])
    // Abrdn theme
    const backgroundPageAdviser = gC(["background", "page-adviser"])
    const backgroundFooterAdviser = gC(["background", "footer-adviser"])

    ///////////////////////////////////////////////////////////////////////

    // not present in the design tokens CO-OP, present in the codebase - start
    const backgroundNavbarActive = gC(["background", "navbar", "active"])
    const actionLogoBase = gC(["actions", "logo", "base"])
    const actionLogoHover = gC(["actions", "logo", "hover"])
    const secondaryI = gC(["secondary", "i"])
    const secondaryJ = gC(["secondary", "j"])

    //////////////////////////////////////////////////////////////////////////
    const primaryBase = gC(["primary", "base"])

    const neutralBlack = gC(["neutral", "black"])
    const neutralWhite = gC(["neutral", "white"])
    const neutralGrey = gC(["neutral", "grey"])

    const primaryBackground = gC(["primary", "background"])
    const primaryGrey = gC(["primary", "grey"])
    const primaryBlack = gC(["primary", "black"])

    const secondaryA = gC(["secondary", "a"])
    const secondaryB = gC(["secondary", "b"])
    const secondaryC = gC(["secondary", "c"])
    const secondaryD = gC(["secondary", "d"])
    const secondaryE = gC(["secondary", "e"])
    const secondaryF = gC(["secondary", "f"])
    const secondaryG = gC(["secondary", "g"])
    const secondaryH = gC(["secondary", "h"])

    const toneA = gC(["tone", "a"])
    const toneA25 = gC(["tone", "a-25"])

    const actionSearchBackground = gC(["actions", "search", "background"])
    const actionSearchHover = gC(["actions", "search", "hover"])
    const actionSearchHoverOutline = gC(["actions", "search", "outline-hover"])
    const actionSearchOutline = gC(["actions", "search", "outline"])
    const actionSearchOutlineFocus = gC(["actions", "search", "outline-focus"])
    const actionSearchContent = gC(["actions", "search", "content"])
    const actionSearchFocus = gC(["actions", "search", "focus"])

    const actionLinkBase = gC(["actions", "link", "base"])
    const actionLinkHover = gC(["actions", "link", "hover"])
    const actionLinkLine = gC(["actions", "link", "line"])
    const actionDisabled = gC(["actions", "disabled", "base"])
    const actionDisabledText = gC(["actions", "disabled", "text"])
    const actionBreadcrumbBase = gC(["actions", "breadcrumb", "base"])
    const actionBreadcrumbHover = gC(["actions", "breadcrumb", "hover"])

    const actionFormfieldBaseBacground = gC([
      "actions",
      "formfield",
      "base",
      "background",
    ])
    const actionFormfieldBaseBorder = gC([
      "actions",
      "formfield",
      "base",
      "border",
    ])
    const actionFormfieldBaseColor = gC([
      "actions",
      "formfield",
      "base",
      "color",
    ])

    const actionFormfieldHover = gC([
      "actions",
      "formfield",
      "hover",
      "background",
    ])
    const actionFormfieldOutlineHover = gC([
      "actions",
      "formfield",
      "hover",
      "outline",
    ])
    const actionFormfieldFocusBorder = gC([
      "actions",
      "formfield",
      "focus",
      "border",
    ])
    const actionFormfieldFocusColor = gC([
      "actions",
      "formfield",
      "focus",
      "color",
    ])
    const actionFormfieldLabelContent = gC([
      "actions",
      "formfield",
      "label",
      "content",
    ])

    const actionTagBaseBackground = gC(["actions", "tag", "base", "background"])
    const actionTagBaseOutline = gC(["actions", "tag", "base", "outline"])
    const actionTagBaseText = gC(["actions", "tag", "base", "text"])
    const actionTagHoverBackground = gC(["actions", "tag", "base", "hover"])
    const actionTagHoverText = gC(["actions", "tag", "hover", "text"])
    const actionTagFocusBackground = gC([
      "actions",
      "tag",
      "focus",
      "background",
    ])
    const actionTagFocusOutline = gC(["actions", "tag", "focus", "outline"])
    const actionTagFocusText = gC(["actions", "tag", "focus", "text"])

    const actionMenuContentFocus = gC(["actions", "menu", "content", "focus"])

    const hoverBase = gC(["hover", "base"])
    const hoverSecondary = gC(["hover", "secondary"])
    const hoverRemove = gC(["hover", "remove"])
    const hoverOutline = gC(["hover", "outline"])

    const functionalSuccessBase = gC(["functional", "success", "base"])
    const functionalSuccessHover = gC(["functional", "success", "hover"])
    const functionalSuccessShade = gC(["functional", "success", "shade"])
    const functionalWarningBase = gC(["functional", "warning", "base"])
    const functionalWarningHover = gC(["functional", "warning", "hover"])
    const functionalWarningShade = gC(["functional", "warning", "shade"])
    const functionalDangerBase = gC(["functional", "danger", "base"])
    const functionalDangerHover = gC(["functional", "danger", "hover"])
    const functionalDangerShade = gC(["functional", "danger", "shade"])
    const functionalMessageBase = gC(["functional", "message", "base"])
    const functionalMessageHover = gC(["functional", "message", "hover"])
    const functionalMessageShade = gC(["functional", "message", "shade"])

    const backgroundNavbarBase = gC(["background", "navbar", "base"])
    const backgroundNavbarExpand = gC(["background", "navbar", "expand"])
    const backgroundPage = gC(["background", "page"])

    const backgroundDividerBase = gC(["background", "divider", "base"])
    const backgroundDividerLighter = gC(["background", "divider", "lighter"])
    const backgroundModal = gC(["background", "modal"])
    const backgroundDragAndDrop = gC(["background", "drag-and-drop"])
    const backgroundFooter = gC(["background", "footer"])
    const backgroundTableLabel = gC(["background", "table", "label"])
    const backgroundAccordionSectionActive = gC([
      "background",
      "accordion-section-active",
    ])
    const backgroundCard = gC(["background", "card"])

    const outlineCardLighter = gC(["outlines", "card", "lighter"])
    const outlineCardDarker = gC(["outlines", "card", "darker"])

    const inlineBackgroundMessage = gC([
      "inlineMessageBackgrounds",
      "inline-background-message",
    ])
    const inlineBackgroundWarning = gC([
      "inlineMessageBackgrounds",
      "inline-background-warning",
    ])
    const inlineBackgroundError = gC([
      "inlineMessageBackgrounds",
      "inline-background-error",
    ])
    const inlineBackgroundSuccess = gC([
      "inlineMessageBackgrounds",
      "inline-background-success",
    ])

    const lozengeDefaultBase = gC(["lozenge", "default", "base"])
    const lozengeDefaultText = gC(["lozenge", "default", "text"])
    const lozengeDefaultOutline = gC(["lozenge", "default", "outline"])
    const lozengePendingBase = gC(["lozenge", "pending", "base"])
    const lozengePendingText = gC(["lozenge", "pending", "text"])
    const lozengePendingOutline = gC(["lozenge", "pending", "outline"])
    const lozengeNegativeBase = gC(["lozenge", "negative", "base"])
    const lozengeNegativeText = gC(["lozenge", "negative", "text"])
    const lozengeNegativeOutline = gC(["lozenge", "negative", "outline"])
    const lozengeRevokeBase = gC(["lozenge", "revoke", "base"])
    const lozengeRevokeText = gC(["lozenge", "revoke", "text"])
    const lozengeRevokeOutline = gC(["lozenge", "revoke", "outline"])
    const lozengeInfoBase = gC(["lozenge", "info", "base"])
    const lozengeInfoText = gC(["lozenge", "info", "text"])
    const lozengeInfoOutline = gC(["lozenge", "info", "outline"])
    const lozengePositiveBase = gC(["lozenge", "positive", "base"])
    const lozengePositiveText = gC(["lozenge", "positive", "text"])
    const lozengePositiveOutline = gC(["lozenge", "positive", "outline"])
    const lozengeEmphasizedBase = gC(["lozenge", "emphasized", "base"])
    const lozengeEmphasizedText = gC(["lozenge", "emphasized", "text"])
    const lozengeEmphasizedOutline = gC(["lozenge", "emphasized", "outline"])
    const lozengeMultipleBase = gC(["lozenge", "multiple", "base"])
    const lozengeMultipleText = gC(["lozenge", "multiple", "text"])
    const lozengeMultipleOutline = gC(["lozenge", "multiple", "outline"])

    const paginationBackgroundBase = gC(["pagination", "background-base"])
    const paginationBackgroundFocus = gC(["pagination", "background-focus"])
    const paginationOutline = gC(["pagination", "outline"])
    const paginationText = gC(["pagination", "text"])
    const paginationChevron = gC(["pagination", "chevron"])
    const paginationTextFocus = gC(["pagination", "text-focus"])

    const profileButtonColorText = gC(["background", "page"])
    const profileButtonColorActionBackground = gC(["primary", "base"])
    const profileButtonActionOutline = gC(["outlines", "card", "lighter"])

    const resultsChevron = gC(["pagination", "results", "results-chevron"])
    const resultsBackgroundChevron = gC([
      "pagination",
      "results-background-chevron",
    ])
    const resultsBackgroundBase = gC([
      "pagination",
      "results",
      "results-background-base",
    ])
    const resultsOutline = gC(["pagination", "results", "results-outline"])

    const tabBarTabTextFocus = gC(["tabBar", "tab-text-focus"])
    const tabBarTabLineFocus = gC(["tabBar", "tab-line-focus"])
    const tabBarTabTextInactive = gC(["tabBar", "tab-text-inactive"])
    const tabBarTabLineInactive = gC(["tabBar", "tab-line-inactive"])

    const toggleInactive = gC(["toggle", "inactive"])
    const toggleFocus = gC(["toggle", "focus"])

    return `
        --color-universal-natural-black: ${neutralBlack[0] ?? "#161616"};
        --color-universal-natural-white: ${neutralWhite[0] ?? "#FFFFFF"};
        --color-universal-natural-grey: ${neutralGrey[0] ?? "#F9F9F9"};

        --color-universal-primary-base: ${primaryBase[0] ?? "#A9F5DA"};
        --color-universal-primary-black: ${primaryBlack[0] ?? "#161616"};
        --color-universal-primary-grey: ${primaryGrey[0] ?? "#F9F9F9"};
        --color-universal-primary-background: ${
          primaryBackground[0] ?? "#FFFFFF"
        };


        --color-universal-secondary-a: ${secondaryA[0] ?? "#323240"};
        --color-universal-secondary-b: ${secondaryB[0] ?? "#48475C"};
        --color-universal-secondary-c: ${secondaryC[0] ?? "#ADAEB9"};
        --color-universal-secondary-d: ${secondaryD[0] ?? "#E8E8E8"};
        --color-universal-secondary-e: ${secondaryE[0] ?? "#F5F8FF"};
        --color-universal-secondary-f: ${secondaryF[0] ?? "#E5EDFF"};
        --color-universal-secondary-g: ${secondaryG[0] ?? "#B8CFB7"};
        --color-universal-secondary-h: ${secondaryH[0] ?? "#749BFF"};
        --color-secondary-i: ${secondaryI[0] ?? "#333333"};
        --color-secondary-j: ${secondaryJ[0] ?? "#D3292E"};

        --color-universal-tone-a: ${toneA[0] ?? "#323240"};
        --color-tone-b: ${toneB[0] ?? "#323240"};
        --color-tone-c: ${toneC[0] ?? "#323240"};
        --color-tone-d: ${toneD[0] ?? "#323240"};
        --color-universal-tone-a-25: ${toneA25[0] ?? "#323240"};
        --color-tone-b-25: ${toneB25[0] ?? "#323240"};
        --color-tone-c-25: ${toneC25[0] ?? "#323240"};
        --color-tone-d-25: ${toneD25[0] ?? "#323240"};

        --search-color-interaction-background-primary: ${
          actionSearchBackground[0] ?? "#F5F8FF"
        };
        --search-color-interaction-background-primary-hover: ${
          actionSearchHover[0] ?? "#DEE7FF"
        };
        --search-color-interaction-border-primary-hover :${
          actionSearchHoverOutline[0] ?? "#6FC7B6"
        };

        --search-color-interaction-border-primary: ${
          actionSearchOutline[0] ?? "#E5EDFF"
        };
        --search-color-interaction-border-primary-focus: ${
          actionSearchOutlineFocus[0] ?? "#E5EDFF"
        };
        --search-color-interaction-text-primary: ${
          actionSearchContent[0] ?? "#323240"
        };
     
        --search-color-interaction-text-primary-focus: ${
          actionSearchFocus[0] ?? "#48475C"
        };

        --link-color-action-text-primary: ${actionLinkBase[0] ?? "#48475C"};
        
        --link-color-action-text-primary-hover: ${
          actionLinkHover[0] ?? "#323240"
        };
        --color-action-logo-link-base: ${actionLogoBase[0] ?? "#767585"};
        --color-action-logo-link-hover: ${actionLogoHover[0] ?? "#48475C"};
        --link-color-action-text-line: ${actionLinkLine[0] ?? "#159847"};
        --component-color-action-background-disabled: ${
          actionDisabled[0] ?? "#E8E8E8"
        };
        --component-color-action-border-disabled: ${
          actionDisabled[0] ?? "#E8E8E8"
        };
        --button-color-action-background-disabled: ${
          actionDisabled[0] ?? "#E8E8E8"
        };
        --button-color-action-text-disabled: ${
          actionDisabledText[0] ?? "#ADAEB9"
        };
        --component-color-action-text-disabled: ${
          actionDisabledText[0] ?? "#ADAEB9"
        };

        --breadcrumb-color-interaction-text-primary: ${
          actionBreadcrumbBase[0] ?? "#A2BCFF"
        };
        --breadcrumb-color-interaction-text-primary-hover: ${
          actionBreadcrumbHover[0] ?? "#749BFF"
        };
        --form-color-interaction-background-primary: ${
          actionFormfieldBaseBacground[0] ?? "#FFFFFF"
        };
        --form-color-interaction-border-primary: ${
          actionFormfieldBaseBorder[0] ?? "#9E9FA6"
        };
        --form-color-interaction-text-primary: ${
          actionFormfieldBaseColor[0] ?? "#E8E8E8"
        };

        --form-color-interaction-background-primary-hover: ${
          actionFormfieldHover[0] ?? "#F5F8FF"
        };
        --form-color-interaction-border-primary-hover: ${
          actionFormfieldOutlineHover[0] ?? "#6FC7B6"
        };

        --form-color-interaction-border-primary-focus: ${
          actionFormfieldFocusBorder[0] ?? "#F5F8FF"
        };
        --form-color-interaction-text-primary-focus: ${
          actionFormfieldFocusColor[0] ?? "#48475C"
        };
        --form-color-interaction-text-label-primary: ${
          actionFormfieldLabelContent[0] ?? "#333333"
        };

        --tag-color-interaction-background-primary: ${
          actionTagBaseBackground[0] ?? "#7A8AA1"
        };
        --tag-color-interaction-border-primary: ${
          actionTagBaseOutline[0] ?? "#7A8AA1"
        };
        --tag-color-interaction-text-primary: ${
          actionTagBaseText[0] ?? "#FFFFFF"
        };
        --tag-color-interaction-background-primary-hover: ${
          actionTagHoverBackground[0] ?? "#7A8AA1"
        };
        --tag-color-interaction-text-primary-hover: ${
          actionTagHoverText[0] ?? "#FFFFFF"
        };
        --tag-color-interaction-background-primary-focus: ${
          actionTagFocusBackground[0] ?? "#7A8AA1"
        };
        --tag-color-interaction-border-focus: ${
          actionTagFocusOutline[0] ?? "#7A8AA1"
        };
        --tag-color-interaction-text-primary-focus: ${
          actionTagFocusText[0] ?? "#FFFFFF"
        };


        --color-action-menu-content-focus: ${
          actionMenuContentFocus[0] ?? "#F2FAF5"
        };

        --component-color-interaction-background-primary-hover: ${
          hoverBase[0] ?? "#F5F8FF"
        };
        --component-color-interaction-background-secondary-hover: ${
          hoverSecondary[0] ?? "#DEE7FF"
        };

        --notification-color-background-new: ${hoverSecondary[0] ?? "#DEE7FF"};
        
        --component-color-interaction-background-remove-hover: ${
          hoverRemove[0] ?? "#FDE8E9"
        };
        --component-color-interaction-border-hover: ${
          hoverOutline[0] ?? "#FDE8E9"
        };

        --color-functional-success-base: ${
          functionalSuccessBase[0] ?? "#03A675"
        };
        --component-color-feedback-success-hover: ${
          functionalSuccessHover[0] ?? "#058D3B"
        };
        --component-color-feedback-success-shade: ${
          functionalSuccessShade[0] ?? "#BD2624"
        };
        --component-color-feedback-warning: ${
          functionalWarningBase[0] ?? "#FFD964"
        };
        --component-color-feedback-warning-hover: ${
          functionalWarningHover[0] ?? "#F6D261"
        };
        --component-color-feedback-warning-shade: ${
          functionalWarningShade[0] ?? "#BD2624"
        };
        --component-color-feedback-danger: ${
          functionalDangerBase[0] ?? "#BD2624"
        };
        --component-color-feedback-danger-hover: ${
          functionalDangerHover[0] ?? "#B4201E"
        };
        --component-color-feedback-danger-shade: ${
          functionalDangerShade[0] ?? "#BD2624"
        };
        --component-color-feedback-message: ${
          functionalMessageBase[0] ?? "#BD2624"
        };
        --component-color-feedback-message-hover: ${
          functionalMessageHover[0] ?? "#BD2624"
        };
        --component-color-feedback-message-shade: ${
          functionalMessageShade[0] ?? "#BD2624"
        };

        --navbar-color-interaction-background-primary: ${
          backgroundNavbarBase[0] ?? "#FFFFFF"
        };
        --navbar-color-interaction-background-expand: ${
          backgroundNavbarExpand[0] ?? "#FFFFFF"
        };
        --color-background-navbar-hover: ${
          backgroundNavbarHover[0] ?? "#0F6B32"
        };
        --navbar-color-interaction-background-active: ${
          backgroundNavbarActive[0] ?? "#48475C"
        };
        --page-color-context-background-primary: ${
          backgroundPage[0] ?? "#FFFFFF"
        };
        --page-color-context-background-primary-adviser: ${
          backgroundPageAdviser[0] ?? "#EBEBEE"
        };

        --divider-color-context-background-primary: ${
          backgroundDividerBase[0] ?? "#E8E8E8"
        };
        --divider-color-context-background-lighter: ${
          backgroundDividerLighter[0] ?? "#F5F5F5"
        };
        --modal-color-context-background-primary: ${
          backgroundModal[0] ?? "#FFFFFF"
        };
        --footer-color-context-background-primary: ${
          backgroundFooter[0] ?? "#FFFFF"
        };
        --dragAndDrop-interaction-color-background: ${
          backgroundDragAndDrop[0] ?? "#FFFFFF"
        };
        --accordion-context-color-background-section-active: ${
          backgroundAccordionSectionActive[0] ?? neutralWhite[0]
        };

        --footer-color-context-background-primary: ${
          backgroundFooter[0] ?? "#FFFFF"
        };
        --color-background-footer-adviser: ${
          backgroundFooterAdviser[0] ?? "#EBEBEE"
        };


        --table-color-context-background-label: ${
          backgroundTableLabel[0] ?? "#F5F8FF"
        };
        --card-color-context-background-primary: ${
          backgroundCard[0] ?? "#FFFFF"
        };

        --card-color-context-border-lighter: ${
          outlineCardLighter[0] ?? "#E8E8E8"
        };
        --card-color-context-border-darker: ${
          outlineCardDarker[0] ?? "#ADAEB9"
        };

        --alert-message-color-feedback-background-message: ${
          inlineBackgroundMessage[0] ?? "#ADAEB9"
        };
        --alert-message-color-feedback-background-warning: ${
          inlineBackgroundWarning[0] ?? "#ADAEB9"
        };
        --alert-message-color-feedback-background-warning: ${
          inlineBackgroundError[0] ?? "#ADAEB9"
        };
        --alert-message-color-feedback-background-success: ${
          inlineBackgroundSuccess[0] ?? "#ADAEB9"
        };

        --lozenge-color-feedback-background-primary: ${
          lozengeDefaultBase[0] ?? "#F5F5F5"
        };
        --lozenge-color-feedback-text-primary: ${
          lozengeDefaultText[0] ?? "#333333"
        };
        --lozenge-color-feedback-border-primary: ${
          lozengeDefaultOutline[0] ?? "#E8E8E8"
        };
        --lozenge-color-feedback-background-pending: ${
          lozengePendingBase[0] ?? "#F2FAF5"
        };
        --color-lozenge-pending-text: ${lozengePendingText[0] ?? "#333333"};
        --lozenge-color-feedback-border-pending: ${
          lozengePendingOutline[0] ?? "#E8E8E8"
        };
        --lozenge-color-feedback-background-negative: ${
          lozengeNegativeBase[0] ?? "#DF1F11"
        };
        --lozenge-color-feedback-text-negative: ${
          lozengeNegativeText[0] ?? "#FFF"
        };
        --lozenge-color-feedback-border-negative: ${
          lozengeNegativeOutline[0] ?? "#DF1F11"
        };
        --lozenge-color-feedback-background-revoke: ${
          lozengeRevokeBase[0] ?? "#DF1F11"
        };
        --lozenge-color-feedback-text-revoke: ${lozengeRevokeText[0] ?? "#FFF"};
        --lozenge-color-feedback-border-revoke: ${
          lozengeRevokeOutline[0] ?? "#FFF"
        };
        --lozenge-color-feedback-background-info: ${
          lozengeInfoBase[0] ?? "#E5EDFF"
        };
        --lozenge-color-feedback-text-info: ${lozengeInfoText[0] ?? "#4483AA"};
        --lozenge-color-feedback-border-info: ${
          lozengeInfoOutline[0] ?? "#E5EDFF"
        };
        --lozenge-color-feedback-background-positive: ${
          lozengePositiveBase[0] ?? "#169F50"
        };
        --lozenge-color-feedback-text-positive: ${
          lozengePositiveText[0] ?? "#FFFF"
        };
        --lozenge-color-feedback-border-positive: ${
          lozengePositiveOutline[0] ?? "#169F50"
        };
        --lozenge-color-feedback-background-emphasized: ${
          lozengeEmphasizedBase[0] ?? "#E8BC64"
        };
        --lozenge-color-feedback-text-emphasized: ${
          lozengeEmphasizedText[0] ?? "#333333"
        };
        --lozenge-color-feedback-border-emphasized: ${
          lozengeEmphasizedOutline[0] ?? "#E8BC64"
        };
        --lozenge-color-feedback-background-multiple: ${
          lozengeMultipleBase[0] ?? "#4483AA"
        };
        --lozenge-color-feedback-text-multiple: ${
          lozengeMultipleText[0] ?? "#FFFFFF"
        };
        --lozenge-color-feedback-border-multiple: ${
          lozengeMultipleOutline[0] ?? "#4483AA"
        };

        --pagination-color-interaction-background-primary: ${
          paginationBackgroundBase[0] ?? "#FFFFFF"
        };
        --pagination-color-interaction-background-primary-focus: ${
          paginationBackgroundFocus[0] ?? "#FFFFFF"
        };
        --pagination-color-interaction-border-primary: ${
          paginationOutline[0] ?? "#E8E8E8"
        };
        --pagination-color-interaction-text-primary: ${
          paginationText[0] ?? "#333333"
        };
        --pagination-color-interaction-chevron: ${
          paginationChevron[0] ?? "#333333"
        };
        --pagination-color-interaction-text-primary-focus: ${
          paginationTextFocus[0] ?? "#333333"
        };

        --profileButton-color-text: ${profileButtonColorText[0] ?? "#FCFEFF"};
        --profileButton-color-action-background: ${
          profileButtonColorActionBackground[0] ?? "#225CEB"
        };
        --profileButton-action-outline: ${
          profileButtonActionOutline[0] ?? "3px solid #E4E7EB"
        };
        --profileButton-action-outline-offset: 0px;
        
        --results-color-interaction-chevron: ${resultsChevron[0] ?? "#48475C"};
        --results-color-interaction-background-chevron: ${
          resultsBackgroundChevron[0] ?? "#FFFFFF"
        };
        --results-color-interaction-background-primary: ${
          resultsBackgroundBase[0] ?? "#FFFFFF"
        };
        --results-color-interaction-border-primary: ${
          resultsOutline[0] ?? "#DEDFE3"
        };

        --tabBar-color-interaction-text-focus ${
          tabBarTabTextFocus[0] ?? "#323240"
        };
        --tabBar-color-interaction-tabLine-focus ${
          tabBarTabLineFocus[0] ?? "#323240"
        };
        --tabBar-color-interaction-text-inactive ${
          tabBarTabTextInactive[0] ?? "#ADAEB9"
        };
        --tabBar-color-interaction-tabLine-inactive ${
          tabBarTabLineInactive[0] ?? "#ADAEB9"
        };

        --color-toggle-inactive ${toggleInactive[0] ?? "#ADAEB9"};
        --color-toggle-focus ${toggleFocus[0] ?? "#ADAEB9"};



    `
  }
}
