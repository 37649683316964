import { IFolder } from "../api-types"

export function findAndRenameFolder(
  folders: IFolder[],
  newName: string,
  id: string
) {
  folders.forEach((mappedFolder: IFolder) => {
    if (mappedFolder.childFolders) {
      findAndRenameFolder(mappedFolder.childFolders, newName, id)
    }
    if (mappedFolder.id === id) {
      mappedFolder.name = newName
    }
  })
}
