const ArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
  >
    <path
      d="M8 0.75V17.25"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.25 10.5L8 17.25L14.75 10.5"
      stroke="var(--color-universal-secondary-b)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default ArrowDown
