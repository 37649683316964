import {
  NotificationDto,
  PubSubNotificationDto,
} from "legado-generated-api-client"
import { setDisplaySuccessOrErrorMessage } from "../../contexts/toasts"
import { getNotificationContent } from "../../api/api-client/api-handler"
import { getUserNotificationsFromApi } from "../../contexts/users/loaduserData"
import { Dispatch } from "react"
import { IToastAction } from "../../contexts/toasts/reducer"
import { IUser } from "../../api/api-client/api-types"

export const HandleNotificationMessage = async (
  data: any,
  dispatch: (value: any) => void,
  toastDispatch: Dispatch<IToastAction>,
  theme: string,
  currentUser?: IUser
) => {
  const dto = GetDtoFromNotificationData(data)
  const notificationContent = await getNotificationContent(dto)
  const notificationMessage = GetNotificationMessage(notificationContent, theme)

  toastDispatch(
    setDisplaySuccessOrErrorMessage({
      title: notificationContent.title ?? "",
      message: notificationMessage,
      messageType: "NOTIFICATION",
    })
  )
  getUserNotificationsFromApi({ dispatch })
}

const GetDtoFromNotificationData = (data: any): PubSubNotificationDto => {
  const dto: PubSubNotificationDto = {
    data: data["data"],
    id: data["id"],
    userId: data["userId"],
    notificationId: data["notificationId"] as number,
  }

  return dto
}

const GetNotificationMessage = (
  content: NotificationDto,
  theme: string
): JSX.Element => {
  //Coop theme splits the title and body in its own toast component
  //So we just need to return the body here
  if (theme === "CO_OP") {
    return <div>{content.body}</div>
  }

  return (
    <div>
      <div>
        <b>{content.title}</b>
      </div>
      <div>{content.body}</div>
    </div>
  )
}
