import { ReactElement, useEffect, useRef } from "react"
import { IFolder } from "../../api/api-client/api-types"
import { ChevronUpOrDown } from "../atoms/ChevronUpOrDown"
import { NavMenuNavigation } from "./constants"
import { keyPressed, manipulateNavigationData } from "./helpers"

export interface NavMenuDropDownItemProps {
  folder: IFolder
  onClickFn?: (folderId: string) => void
  selectedId?: string
  idDesktopNav?: boolean
  expanded?: string
  setExpanded?: (expanded: string) => void
  focusBlocked?: boolean
  controlsId?: string

  //keyboard navigation related
  navigationLevel?: number
  navigationId?: number
  maxNavigationId?: number | undefined
  navigationData?: NavMenuNavigation
  setNavigationData?: React.Dispatch<React.SetStateAction<NavMenuNavigation>>
  setTopParentIsOpen?: ((menuItemIsOpen: string) => void) | undefined
  focusDirection?: number
}

export const NavMenuDropDownItem: React.FC<NavMenuDropDownItemProps> = ({
  folder,
  onClickFn,
  selectedId,
  idDesktopNav,
  expanded,
  setExpanded,
  focusBlocked = false,
  controlsId,

  //keyboard navigation related
  navigationLevel = -1,
  navigationId = -1,
  maxNavigationId,
  navigationData,
  setNavigationData,
  setTopParentIsOpen,
  focusDirection,
}) => {
  const childFolders = [] as ReactElement[]
  const maxDisplayedLevel = idDesktopNav ? 1 : 0
  if (
    folder.level < maxDisplayedLevel &&
    folder.childFolders &&
    folder.childFolders.length > 0
  ) {
    const maxFocusId = folder.childFolders?.length - 1
    folder?.childFolders?.forEach((childFolder, index) => {
      childFolders.push(
        <NavMenuDropDownItem
          key={childFolder.id}
          folder={childFolder}
          onClickFn={onClickFn}
          selectedId={selectedId}
          focusBlocked={false}
          setExpanded={setExpanded}
          navigationLevel={2}
          navigationId={index}
          maxNavigationId={maxFocusId}
          navigationData={navigationData}
          setNavigationData={setNavigationData}
          setTopParentIsOpen={setTopParentIsOpen}
        />
      )
    })
  }

  const itemIsDisabled = false //No longer used, changed to bring back disabled navigation features

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    switch (navigationLevel) {
      case 1: {
        if (
          navigationData?.secondLevel.IsSelected &&
          navigationData.secondLevel.FocusId === navigationId &&
          !focusBlocked
        ) {
          divRef.current?.focus()
        }
        break
      }
      case 2: {
        if (
          navigationData?.thirdLevel.IsSelected &&
          navigationData.thirdLevel.FocusId === navigationId &&
          !focusBlocked
        ) {
          divRef.current?.focus()
        }
        break
      }
    }
  }, [navigationData, focusBlocked, navigationLevel, navigationId])

  return (
    <li
      className={`nav-menu-item level${folder.level} ${
        expanded === folder.id ? "expanded" : "shrunk"
      }${itemIsDisabled ? " disabled" : ""}`}
      key={controlsId}
    >
      <div
        className="dropDownText"
        data-testid="dropDownText"
        onClick={(e) => {
          e.stopPropagation()

          if (childFolders.length > 0) {
            setExpanded && setExpanded(expanded === folder.id ? " " : folder.id)
            return
          }

          if (onClickFn) {
            onClickFn(folder.id)
          }
        }}
      >
        <div
          ref={divRef}
          tabIndex={!focusBlocked ? 0 : -1}
          data-href={window.location.href + "my-files/" + folder.id}
          role="link"
          className="click-me"
          onKeyDown={(event) => {
            keyPressed(
              event,
              folder.id,
              navigationData,
              setNavigationData,
              onClickFn,
              setExpanded,
              setTopParentIsOpen
            )
          }}
          onFocus={() => {
            if (setNavigationData && navigationData) {
              manipulateNavigationData(
                navigationData,
                setNavigationData,
                navigationLevel,
                navigationId,
                maxNavigationId,
                itemIsDisabled,
                focusDirection,
                setTopParentIsOpen
              )
            }
          }}
          aria-current={!focusBlocked ? "page" : "false"}
          id={folder.name.replace(/ /g, "")}
        >
          {folder.name}
        </div>
        {childFolders.length > 0 && (
          <ChevronUpOrDown
            expanded={expanded === folder.id}
            controlsId={controlsId ?? ""}
            accessibilityLabelId={folder.name.replace(/ /g, "")}
            thisRefId={navigationId}
            maxNavigationId={maxNavigationId}
            level={1}
            navigationData={navigationData}
            setNavigationData={setNavigationData}
            folderId={folder.id}
            setExpandFolder={setExpanded}
            setTopParentIsOpen={setTopParentIsOpen}
          />
        )}
      </div>
      {expanded === folder.id && (
        <div className="children">
          <ul
            className={expanded === folder.id ? "open" : "hidden"}
            id={controlsId ?? ""}
          >
            {childFolders.length > 0 && childFolders}
          </ul>
        </div>
      )}
    </li>
  )
}
