import { FC } from "react"
import classNames from "classnames"
import "./Lozenge.css"

export interface LozengeProps {
  label: string
  className?: string
}

export const Lozenge: FC<LozengeProps> = ({ className, label }) => {
  const lozengeClass = classNames("lozenge", { [`${className}`]: className })
  return <span className={lozengeClass}>{label}</span>
}
