import { getUserId } from "./getUser";
import { FileImportantRecentDto } from "legado-generated-api-client";
const importantDocumentss = require("../staticData/getImportantDocuments.json");

type TGetImportantDocumentsList = (
  userEmail?: string
) => FileImportantRecentDto[];

const isNodeDtos = (files: any) => {
  if ((files as FileImportantRecentDto[])?.length) {
    return files as FileImportantRecentDto[];
  }
};

type TObj = { [key: string]: any };

export const getImportantDocumentsListFromLocalData: TGetImportantDocumentsList =
  (userEmail) => {
    const currentUserId = getUserId(userEmail);
    const importantDocumentsListObj = importantDocumentss as TObj;
    const currentUserimportantDocumentsList =
      importantDocumentsListObj[currentUserId];
    return isNodeDtos(currentUserimportantDocumentsList) || [];
  };
