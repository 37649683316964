const RadioInactive = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2Z"
      fill="white"
      stroke="#C1C1C1"
      strokeWidth="2"
    />
  </svg>
)
export default RadioInactive
