const NotificationDelete = () => (
    <svg 
        width="35" 
        height="36" 
        viewBox="0 0 35 36" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 31.0898C24.9558 31.0898 31 25.0457 31 17.5898C31 10.134 24.9558 4.08984 17.5 4.08984C10.0442 4.08984 4 10.134 4 17.5898C4 25.0457 10.0442 31.0898 17.5 31.0898Z" 
        fill="#D82927"/>
    <path d="M23.0686 12.0215L11.9316 23.1584" 
        stroke="#FEFEFE" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    <path d="M23.0686 23.1584L11.9316 12.0215" 
        stroke="#FEFEFE" 
        strokeWidth="1.5" 
        strokeLinecap="round" 
        strokeLinejoin="round"/>
    </svg> 
    )
    export default NotificationDelete